import './SubtleLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export const SubtleLabel: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { children, className } = props
    const bem = new BEM('SubtleLabel')

    return <span className={bem.getClassName(className)}>{children}</span>
}
