import './Center.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class Center extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Center')

    public render() {
        const { children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div>{children}</div>
            </div>
        )
    }
}
