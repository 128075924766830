import React from 'react'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'
import { LinkedTaskItemType } from '~/graphql/types/Task'

interface Props {
    onChange: (type: LinkedTaskItemType | null) => void
    defaultValue?: LinkedTaskItemType
}

interface State {}

export class LinkedTaskTypeFilter extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)

    private options: SelectOption[] = Object.values(LinkedTaskItemType).map(type => ({
        label: this.loc(t => t.LinkedTaskTypeFilter.type[type]),
        value: type,
    }))

    public render() {
        const { onChange } = this.props

        return (
            <Select
                name={'linkedTaskType'}
                isFilter={true}
                clearable={true}
                onChange={(option: SelectOption) => {
                    if (!option) {
                        onChange(null)
                    } else {
                        onChange(option.value as LinkedTaskItemType)
                    }
                }}
                options={this.options}
                defaultValue={this.getDefaultValue()}
            />
        )
    }

    private getDefaultValue() {
        const { defaultValue } = this.props

        if (!defaultValue) {
            return undefined
        }

        const defaultOption = this.options.find(option => option.value === defaultValue)

        return defaultOption ? [defaultOption] : undefined
    }
}
