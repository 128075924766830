import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Bold } from '~/components/Core/Typography/Bold'
import {
    DepartmentType,
    AllDepartmentsDocument,
    EditDepartmentCustomerFrameworksMutation,
    EditDepartmentCustomerFrameworksMutationVariables,
    CustomerFrameworkInputType,
    AllDepartmentsQueryVariables,
    AllDepartmentsQuery,
} from '~/generated/graphql'
import { DepartmentsTableContainer } from '~/components/Domain/Department/DepartmentsTableContainer/DepartmentsTableContainer'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { EditDepartmentCustomerFrameworks } from '~/components/Domain/Department/DepartmentMutations/EditDepartmentCustomerFrameworks'
import { MutationFn, Query } from 'react-apollo'
import { ColumnItemCheckedStateEnum } from '~/components/Domain/CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableContainer'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerFrameworkWithFramework } from '../CustomerFramework/CustomerFrameworkOverviewView'
import { DepartmentsOverviewActionButtons } from '~/components/Domain/Department/DepartmentsOverviewActionButtons'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {
    editable: boolean
    isPristine: boolean
}

class DepartmentsOverviewComponent extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        editable: false,
        isPristine: true,
    }

    private containerRef = React.createRef<DepartmentsTableContainer>()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview)

    public render() {
        return (
            <Query<AllDepartmentsQuery, AllDepartmentsQueryVariables>
                query={AllDepartmentsDocument}
                variables={{ customerSlug: this.context.customer.slug }}
            >
                {({ data, loading: queryLoading }) => {
                    if (queryLoading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data) {
                        return null
                    }

                    const allDepartments = data.allDepartments as DepartmentType[]
                    const customerFrameworks = data.customerFrameworks as CustomerFrameworkWithFramework[]

                    return (
                        <EditDepartmentCustomerFrameworks>
                            {(mutate, { loading }) => (
                                <>
                                    <PageHeader
                                        title={this.loc(t => t.title)}
                                        breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                                        actionComponent={this.renderEditActions(
                                            mutate,
                                            loading,
                                            allDepartments,
                                            customerFrameworks
                                        )}
                                        actionButtons={
                                            !this.state.editable && (
                                                <DepartmentsOverviewActionButtons
                                                    departments={allDepartments}
                                                    customerFrameworks={customerFrameworks}
                                                    onEdit={this.handleOnEditClick}
                                                />
                                            )
                                        }
                                    />
                                    <Paragraph>
                                        {this.loc(t => t.subTitle)}
                                        <Bold inline={true}>{this.loc(t => t.legalFramework)}</Bold>
                                    </Paragraph>
                                    <DepartmentsTableContainer
                                        departments={allDepartments}
                                        customerFrameworks={customerFrameworks}
                                        editable={this.state.editable}
                                        onCheckboxChange={this.handleOnCheckboxChange}
                                        ref={this.containerRef}
                                    />
                                </>
                            )}
                        </EditDepartmentCustomerFrameworks>
                    )
                }}
            </Query>
        )
    }

    private renderEditActions(
        mutate: MutationFn<EditDepartmentCustomerFrameworksMutation, EditDepartmentCustomerFrameworksMutationVariables>,
        loading: boolean,
        departments: DepartmentType[],
        customerFrameworks: CustomerFrameworkWithFramework[]
    ) {
        if (!this.state.editable) {
            return null
        }

        const isAllowedToEdit = permissions.isAllowedToEditDepartments()
        if (!isAllowedToEdit) {
            return null
        }

        return (
            <Row alignRight={true}>
                <Button
                    type={ButtonType.tertiary}
                    onClick={() => this.setState({ editable: false })}
                    disabled={loading}
                >
                    {localize.translate(t => t.Core.DrawerModal.cancel)}
                </Button>
                <Button
                    onClick={() => this.handleSubmit(mutate, departments, customerFrameworks)}
                    disabled={this.state.isPristine}
                    loading={loading}
                >
                    {localize.translate(t => t.Core.DrawerModal.confirm)}
                </Button>
            </Row>
        )
    }

    private handleOnCheckboxChange = (isPristine: boolean) => {
        if (!this.containerRef.current) {
            return
        }

        this.setState({ isPristine })
    }

    private async handleSubmit(
        mutate: MutationFn<EditDepartmentCustomerFrameworksMutation, EditDepartmentCustomerFrameworksMutationVariables>,
        departments: DepartmentType[],
        customerFrameworks: CustomerFrameworkWithFramework[]
    ) {
        if (!this.containerRef.current?.state) {
            return
        }

        const fields: CustomerFrameworkInputType[] = this.getSubmitFields(departments, customerFrameworks)

        const response = await mutate({
            variables: { customerId: this.context.customer.id, fields },
        })

        if (response && response.data?.editDepartmentCustomerFrameworks) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.setState({ editable: false })
        }
    }

    private handleOnEditClick = () => {
        this.setState({ editable: true })
    }

    private getSubmitFields(departments: DepartmentType[], customerFrameworks: CustomerFrameworkWithFramework[]) {
        const { columnItems } = this.containerRef.current!.state
        const checkedColumnItems = columnItems.filter(
            ({ checkedState }) => checkedState === ColumnItemCheckedStateEnum.checked
        )

        const fields: CustomerFrameworkInputType[] = departments.map((department, index) => {
            const newDepartmentCustomerFrameworks = customerFrameworks.map(customerFramework => {
                const columnItemsForCustomerFramework = checkedColumnItems
                    .filter(item => item.customerFrameworkId === customerFramework.id && item.xIndex === index)
                    .map(({ rowItemId }) => rowItemId)

                return { customerFrameworkId: customerFramework.id, topicIds: columnItemsForCustomerFramework }
            })

            return { departmentId: department.id, customerFrameworks: newDepartmentCustomerFrameworks }
        })

        return fields
    }
}

export const DepartmentsOverview = withRouter(DepartmentsOverviewComponent)
