import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { EmployeeSelectOption, EmployeeSelect } from '~/components/Domain/Customer/EmployeeSelect'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlsDocument,
    CreateControlDocument,
    CreateControlMutation,
    CreateControlMutationVariables,
    ControlTypeType,
    ControlVisibility,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'
import { getResponsibleEmployeeData } from '~/utils/controls'
import { ControlTypeSelect } from '../ControlTypeSelect'
import { DocumentFields } from '../DocumentFields'
import { Control } from '../ControlSelectTable'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import subDays from 'date-fns/sub_days'
import addDays from 'date-fns/add_days'
import { ControlVisibilitySelect } from '../ControlVisibilitySelect'
import { ControlVisibleToDepartmentsSelect } from '../ControlVisibleToDepartmentsSelect'

interface Props {
    title: string
    requestClose?: () => void
    onSubmit?: (control: Control) => void
}

interface State {
    startDate: Date | null
    revisionDate: Date | null
    selectedControlVisibility: ControlVisibility
}

interface FormValues {
    name: string
    type: SelectOption
    file: File | null
    startDate: Date
    revisionDate: Date
    responsibleEmployees: EmployeeSelectOption[]
    visibility: SelectOption
    visibleToDepartments: SelectOption[]
}

export class AddLinkedControlModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        startDate: null,
        revisionDate: null,
        selectedControlVisibility: ControlVisibility.ownerDepartment,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.AddLinkedControlModal)

    public render() {
        const { startDate, revisionDate, selectedControlVisibility } = this.state
        const { title, requestClose } = this.props

        return (
            <GQLMutation<CreateControlMutation, CreateControlMutationVariables>
                mutation={CreateControlDocument}
                refetchQueries={[
                    { query: ControlsDocument, variables: { departmentId: this.context.activeDepartmentId } },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form onSubmit={state => this.onSubmit(mutate, state as FormValues)} isCompact={true}>
                        <ErrorMessage path={`createControl`} />
                        <Modal
                            title={title}
                            requestClose={requestClose}
                            confirmButtonLabel={localize.translate(t => t.Generic.save)}
                            submitForm={true}
                            loading={loading}
                        >
                            <Field label={this.loc(t => t.fields.titleLabel)} forInput={'name'}>
                                <Form.Input
                                    name={'name'}
                                    placeholder={this.loc(t => t.fields.titlePlaceholder)}
                                    autoComplete={false}
                                />
                            </Field>
                            <Field label={this.loc(t => t.fields.controlTypeLabel)} forInput={'type'}>
                                <ControlTypeSelect name={'type'} />
                            </Field>
                            <Field label={this.loc(t => t.fields.startDate)} forInput={'startDate'}>
                                <Form.DatePicker
                                    name={'startDate'}
                                    onChange={(startDate: Date | null) => this.setState({ startDate })}
                                    maxDate={revisionDate ? subDays(revisionDate, 1) : undefined}
                                />
                            </Field>
                            <Field label={this.loc(t => t.fields.revisionDate)} forInput={'revisionDate'}>
                                <Form.DatePicker
                                    name={'revisionDate'}
                                    onChange={(revisionDate: Date | null) => this.setState({ revisionDate })}
                                    minDate={startDate ? addDays(startDate, 1) : undefined}
                                    clearable={true}
                                    repeatable={true}
                                />
                            </Field>
                            <Field
                                label={this.loc(t => t.fields.responsibleEmployees)}
                                forInput={'responsibleEmployees'}
                            >
                                <EmployeeSelect clearable={true} creatable={true} name={'responsibleEmployees'} />
                            </Field>
                            <DocumentFields />
                            <Field
                                label={localize.translate(
                                    t => t.Customer.Compliance.Controls.ControlFormFields.visibilityLabel
                                )}
                                forInput={'visibility'}
                            >
                                <ControlVisibilitySelect
                                    name={'visibility'}
                                    defaultVisibility={ControlVisibility.ownerDepartment}
                                    onChange={(option: SelectOption) => {
                                        this.setState({
                                            selectedControlVisibility: option.value as ControlVisibility,
                                        })
                                    }}
                                />
                            </Field>
                            {selectedControlVisibility === ControlVisibility.selectionOfDepartments && (
                                <Field
                                    label={localize.translate(
                                        t => t.Customer.Compliance.Controls.ControlFormFields.visibleToDepartmentsLabel
                                    )}
                                    forInput={'visibleToDepartments'}
                                    isNested={true}
                                >
                                    <ControlVisibleToDepartmentsSelect
                                        name={'visibleToDepartments'}
                                        defaultDepartments={[]}
                                    />
                                </Field>
                            )}
                        </Modal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private onSubmit = async (
        mutate: MutationFn<CreateControlMutation, CreateControlMutationVariables>,
        formState: FormValues
    ) => {
        const { requestClose, onSubmit } = this.props

        let visibility: ControlVisibility = ControlVisibility.ownerDepartment
        let visibleToDepartmentIds: number[] | undefined = undefined

        if (formState.visibility) {
            visibility = formState.visibility.value as ControlVisibility
        }

        if (visibility === ControlVisibility.selectionOfDepartments) {
            visibleToDepartmentIds = formState.visibleToDepartments
                ? formState.visibleToDepartments.map((option: SelectOption) => option.value as number)
                : []
        }

        const { responsibleEmployeeIds, responsibleShadowEmployeeIds, responsibleEmployees } =
            getResponsibleEmployeeData(formState.responsibleEmployees)

        const document = formState.file ? { file: formState.file } : null

        const result = await mutate({
            variables: {
                departmentId: this.context.activeDepartmentId,
                fields: {
                    name: formState.name,
                    type: formState.type ? (formState.type.value as ControlTypeType) : null,
                    startDate: formState.startDate,
                    revisionDate: formState.revisionDate,
                    document,
                    visibility,
                    visibleToDepartmentIds,
                    responsibleEmployeeIds: responsibleEmployeeIds,
                    responsibleShadowEmployeeIds: responsibleShadowEmployeeIds,
                    responsibleEmployees: responsibleEmployees,
                },
            },
        })

        if (onSubmit && result && result.data && result.data.createControl && result.data.createControl.id) {
            // TODO: when all typing is refactored to generated then this should become the generated type, for now it's fixed this way to prevent rabbitholes
            onSubmit(result.data.createControl as any as Control)
            if (requestClose) {
                requestClose()
            }
        }
    }
}
