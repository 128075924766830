import React from 'react'
import { TasksQueryFilters } from '../../Tasks/TasksQuery/TaskQuery'
import { TasksInfiniteScrollQuery } from '../../Tasks/TasksQuery/TasksInfiniteScrollQuery'
import { AllTasksTable } from '../../Tasks/AllTasks/AllTasksTable'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { Section } from '~/components/Core/Layout/Section'
import { groupBy } from 'lodash-es'
import { getQuarter, getYear } from 'date-fns'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    filters?: Omit<TasksQueryFilters, 'forMonitoring'>
    setRefetchFunction?: (refetchFunction: () => void) => void
    className?: ClassValue
}

interface State {
    sort: {
        [field: string]: SortDirection
    }
}

export class MonitoringTasksTableContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        sort: {
            dueAt: 'ASC',
        },
    }

    private bem = new BEM('MonitoringTasksTableContainer')

    public render() {
        const { filters, setRefetchFunction, className } = this.props
        const { sort } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <TasksInfiniteScrollQuery filters={{ forMonitoring: true, ...filters }} sort={sort}>
                    {({ data, loading, loadingMore, refetch }) => {
                        if (setRefetchFunction) {
                            setRefetchFunction(refetch)
                        }

                        const tasks = data && data.nodes ? data.nodes : []

                        if (loading) {
                            return <Spinner />
                        }

                        if (!tasks.length) {
                            return <NoResults />
                        }

                        const groupedByQuarter = groupBy(tasks, task => {
                            if (!task.dueAt) {
                                return null
                            }
                            const date = new Date(task.dueAt)
                            return `Q${getQuarter(date)} ${getYear(date)}`
                        })

                        return (
                            <>
                                {Object.entries(groupedByQuarter).map(([label, tasksForQuarter], index) => {
                                    const firstTable = index === 0
                                    return (
                                        <Section title={label} key={`${index}-${label}`}>
                                            <AllTasksTable
                                                tasks={tasksForQuarter}
                                                sortableHeaders={firstTable ? ['dueAt', 'startAt'] : []}
                                                loadingMore={loadingMore}
                                                refetchTasks={refetch}
                                                onSortDirectionChange={
                                                    firstTable ? this.handleOnSortDirectionChange : undefined
                                                }
                                                defaultSortDirection={
                                                    firstTable ? { field: 'dueAt', direction: sort.dueAt } : undefined
                                                }
                                            />
                                        </Section>
                                    )
                                })}
                            </>
                        )
                    }}
                </TasksInfiniteScrollQuery>
            </div>
        )
    }

    private handleOnSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({
            sort: {
                [field]: direction,
            },
        })
    }
}
