import './ModalTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title: React.ReactNode
    subtle?: boolean
}

interface State {}

export class ModalTitle extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ModalTitle', () => ({
        'is-subtle': this.props.subtle,
    }))

    public render() {
        const { title, className } = this.props

        return <h3 className={this.bem.getClassName(className)}>{title}</h3>
    }
}
