import './MetaCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title: string
}

interface State {}

export class MetaCard extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('MetaCard')

    public render() {
        const { title, children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <h5 className={this.bem.getElement('title')}>{title}</h5>
                {children && <div className={this.bem.getElement('children')}>{children}</div>}
            </div>
        )
    }
}
