import React from 'react'

import { LawAndRegulationOverviewView } from './LawAndRegulationOverviewView'
import { AppNotFoundView } from '../../AppNotFoundView'
import { LawAndRegulationDetailView } from './LawAndRegulationDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LawAndRegulationArticleDetailView } from '~/views/Customer/lawAndRegulation/LawAndRegulationArticleDetailView'
import { Route, Routes } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'
import { LawAndRegulationRedirectView } from './LawAndRegulationRedirectView'
import { withRouter, RouteComponentProps } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

class LawAndRegulationViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route
                    path={newRoutes.customer(this.context.customer.slug).lawAndRegulation.redirect()}
                    element={<LawAndRegulationRedirectView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).lawAndRegulation.detail.singleArticle()}
                    element={<LawAndRegulationArticleDetailView />}
                />
                <Route path={''} element={<LawAndRegulationOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).lawAndRegulation.detail.view()}
                    element={<LawAndRegulationDetailView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const LawAndRegulationView = withRouter(LawAndRegulationViewComponent)
