import './Avatar.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    uri?: string
    label: string
    size?: AvatarSize
}

export enum AvatarSize {
    medium = 'medium',
    large = 'large',
}

interface State {}

export class Avatar extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Avatar', () => ({
        [`is-${this.props.size}`]: !!this.props.size,
    }))

    public render() {
        const { className, uri, label } = this.props
        const firstLetter = label.slice(0, 1)

        return (
            <div className={this.bem.getClassName(className)}>
                {uri ? (
                    <div
                        style={{ backgroundImage: `url("${uri}")` }}
                        title={label}
                        className={this.bem.getElement('image')}
                    />
                ) : (
                    <span className={this.bem.getElement('letter')}>{firstLetter}</span>
                )}
            </div>
        )
    }
}
