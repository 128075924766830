import './TombstoneInner.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    width?: string | number
    height?: string | number
    isRound?: boolean
    className?: ClassValue
}

export class TombstoneInner extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('TombstoneInner', () => ({
        'is-round': this.props.isRound,
    }))

    public render() {
        const { className, width, height } = this.props

        return <div className={this.bem.getClassName(className)} style={{ width, height }} />
    }
}
