import './NewsSourcesListItem.scss'

import React from 'react'
import { NewsGroupType } from '~/generated/graphql'
import { localize } from '~/bootstrap'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ExpandableTableListItem } from '~/components/Core/DataDisplay/TableList/ExpandableTableListItem'
import { Column } from '~/components/Core/Layout/Column'
import { NewsSourcesGrid } from '~/components/Domain/Settings/NewsSources/NewsSourcesGrid'

interface State {}

interface Props {
    newsGroup: NewsGroupType
}

export class NewsSourcesListItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('NewsSourcesListItem')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.NewsSourcesOverview)

    public render() {
        const { newsGroup } = this.props

        const totalSourceCount = newsGroup.sources.length
        const enabledSourceCount = newsGroup.sources.filter(source => source.isEnabledByCustomer).length
        const className = enabledSourceCount === 0 ? 'group-text-disabled' : 'group-text-enabled'

        return (
            <ExpandableTableListItem
                className={this.bem.getClassName()}
                key={`group-${newsGroup.id}`}
                renderExpanded={this.renderSources}
            >
                <NewsSourcesGrid isTwoGrid={true}>
                    <Column className={this.bem.getElement(className)}>{newsGroup.name}</Column>
                    <Column className={this.bem.getElement('group-info-text')}>
                        {this.loc(t => t.enabledCount, { enabledSourceCount, totalSourceCount })}
                    </Column>
                </NewsSourcesGrid>
            </ExpandableTableListItem>
        )
    }

    private renderSources = () => {
        const { newsGroup } = this.props

        const sources = newsGroup.sources.map(source => {
            const { sourceInfoText, className } = this.getTextAndClassName(source.isEnabledByCustomer)

            return (
                <NewsSourcesGrid
                    key={`source-${source.id}`}
                    isTwoGrid={true}
                    className={this.bem.getElement('source-item-grid')}
                >
                    <Column className={this.bem.getElement(className)}>{source.name}</Column>
                    <Column className={this.bem.getElement('source-item-info')}>{sourceInfoText}</Column>
                </NewsSourcesGrid>
            )
        })

        return sources
    }

    private getTextAndClassName(isEnabledByCustomer: boolean) {
        if (isEnabledByCustomer) {
            const sourceInfoText = this.loc(t => t.enabled)
            const className = 'source-item-enabled'

            return { sourceInfoText, className }
        }

        const sourceInfoText = this.loc(t => t.disabled)
        const className = 'source-item-disabled'

        return { sourceInfoText, className }
    }
}
