import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, SortOption, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { MonitoringReportsQuery, MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'
import './MonitoringReportSelectTable.scss'

interface Props {
    className?: ClassValue
    search?: string
    onMonitoringReportSelect: (monitoringReport: MonitoringReportsQueryType) => void
    isMonitoringReportAdded: (monitoringReport: MonitoringReportsQueryType) => boolean
    onCreateMonitoringReport?: (monitoringReport: MonitoringReportsQueryType) => void
}

interface State {
    sort: SortOption
}

export class MonitoringReportSelectTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public bem = new BEM('MonitoringReportSelectTable')

    public state: State = {
        sort: { field: 'name', direction: 'DESC' },
    }

    public render() {
        const { search } = this.props
        const { sort } = this.state

        return (
            <MonitoringReportsQuery searchString={search} sortDirection={sort}>
                {({ data, loading, loadingMore, canFetchMore, hasPaginated }) => {
                    const tableData = data
                        ? data.nodes.map(node => ({
                              id: node.id,
                              columns: {
                                  name: (
                                      <TableLink
                                          to={routes
                                              .customer(this.context.customer.slug)
                                              .monitoring.reports.detail.view(node.id)}
                                      >
                                          {node.name}
                                      </TableLink>
                                  ),
                                  createdAt: (
                                      <DateFormat date={new Date(node.assessedAt)} readable={true} noWeekday={true} />
                                  ),
                              },
                          }))
                        : []

                    const headers: ColumnOptions[] = [
                        {
                            field: 'name',
                            headerLabel: localize.translate(t => t.Monitoring.attributes.name),
                            sortable: true,
                        },
                        {
                            field: 'createdAt',
                            headerLabel: localize.translate(t => t.Monitoring.attributes.createdAt),
                            sortable: true,
                            noAutoWidth: true,
                        },
                    ]

                    return (
                        <>
                            <Table
                                loading={loading}
                                loadingMore={loadingMore}
                                endReached={!canFetchMore && hasPaginated}
                                columns={headers}
                                data={tableData}
                                onSortDirectionChange={(field, direction) =>
                                    this.setState({ sort: { field, direction } })
                                }
                                rowAction={({ row: { id } }) =>
                                    this.renderMonitoringReportSelectButton(
                                        data && data.nodes.find(monitoringReport => monitoringReport.id === id)
                                    )
                                }
                                className={this.bem.getClassName()}
                            />
                        </>
                    )
                }}
            </MonitoringReportsQuery>
        )
    }

    private renderMonitoringReportSelectButton(monitoringReport?: MonitoringReportsQueryType | null) {
        const { onMonitoringReportSelect, isMonitoringReportAdded } = this.props

        if (!monitoringReport) {
            return null
        }

        if (isMonitoringReportAdded(monitoringReport)) {
            return (
                <Paragraph subtle={true}>
                    {localize.translate(t => t.Monitoring.SelectMonitoringReportTable.monitoringReportAlreadyAdded)}
                </Paragraph>
            )
        }

        return (
            <Button
                type={ButtonType.actionLink}
                icon={IconType.addCircle}
                className={this.bem.getElement('select-monitoring-report-button')}
                onClick={() => onMonitoringReportSelect(monitoringReport)}
            >
                {localize.translate(t => t.Monitoring.SelectMonitoringReportTable.selectMonitoringReport)}
            </Button>
        )
    }
}
