import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringReportsOverviewView } from './MonitoringReportsOverviewView'
import { MonitoringReportDetailView } from './MonitoringReportsDetailView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { MonitoringRapportEditView } from './MonitoringReportEditView'
import { newRoutes } from '~/views/newRoutes'
import { Routes, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

class MonitoringReportsViewComponent extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<MonitoringReportsOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).monitoring.reports.detail.view()}
                    element={<MonitoringReportDetailView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).monitoring.reports.detail.edit()}
                    element={<MonitoringRapportEditView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const MonitoringReportsView = withRouter(MonitoringReportsViewComponent)
