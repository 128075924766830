import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn, Query } from 'react-apollo'
import { notification, localize } from '~/bootstrap'
import { Control, CONTROL_DETAIL_QUERY } from './ControlDetail/ControlQuery'
import { DocumentFields } from './DocumentFields'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    requestClose: () => void
    controlName: string
    controlId: number
    documentId: number
}

interface State {}

const GET_CONTROL_QUERY = gql`
    query control($id: Int!, $controlDocumentId: Int) {
        control(id: $id) {
            id
            name
            type
            description
            startDate
            revisionDate
            createdAt
            updatedAt
            responsibleEmployees {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            responsibleShadowEmployees {
                id
                name
            }
            controlDocuments(controlDocumentId: $controlDocumentId) {
                id
                archivedAt
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
        }
    }
`

const UNARCHIVE_MUTATION = gql`
    mutation unarchiveControlDocument($documentId: Int!) {
        unarchiveControlDocument(documentId: $documentId)
    }
`

interface UnarchiveMutationVariables {
    documentId: number
}

interface UnarchiveMutationResponse {
    unarchiveControlDocument: boolean
}

const DELETE_MUTATION = gql`
    mutation deleteControlDocument($documentId: Int!) {
        deleteControlDocument(documentId: $documentId)
    }
`

interface DeleteControlDocumentVariables {
    documentId: number
}

interface DeleteControlDocumentResponse {
    deleteControlDocument: boolean
}

interface Response {
    control: Control
}

export class ArchivedControlDocumentModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.EditView)

    public render() {
        const { requestClose, controlId, documentId } = this.props

        return (
            <GQLMutation<DeleteControlDocumentResponse, DeleteControlDocumentVariables>
                mutation={DELETE_MUTATION}
                refetchQueries={[
                    {
                        query: CONTROL_DETAIL_QUERY,
                        variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                    },
                ]}
                variables={{ documentId }}
            >
                {deleteControlDocumentMutation => (
                    <GQLMutation<UnarchiveMutationResponse, UnarchiveMutationVariables>
                        mutation={UNARCHIVE_MUTATION}
                        refetchQueries={[
                            {
                                query: CONTROL_DETAIL_QUERY,
                                variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                            },
                        ]}
                    >
                        {unarchiveControlDocumenMutation => (
                            <Query<Response>
                                query={GET_CONTROL_QUERY}
                                variables={{ id: controlId, controlDocumentId: documentId }}
                            >
                                {({ data, loading }) => {
                                    if (!data || !data.control || !data.control.controlDocuments?.length) {
                                        return null
                                    }

                                    const existingDocumentData = data.control.controlDocuments[0]

                                    return (
                                        <Modal
                                            title={localize.translate(
                                                t =>
                                                    t.Customer.Compliance.ControlDocument.ArchivedControlDocumentModal
                                                        .title
                                            )}
                                            requestClose={requestClose}
                                            submitForm={true}
                                            loading={loading}
                                            actions={requestClose => (
                                                <Column>
                                                    <Row spaceBetween={true}>
                                                        <Row>
                                                            <ConfirmModal
                                                                onConfirm={async () => {
                                                                    const response =
                                                                        await deleteControlDocumentMutation()

                                                                    if (
                                                                        response &&
                                                                        response.data &&
                                                                        response.data.deleteControlDocument
                                                                    ) {
                                                                        if (requestClose) {
                                                                            requestClose()
                                                                        }
                                                                        notification.success(
                                                                            localize.translate(
                                                                                t => t.Generic.successfullyDeleted
                                                                            )
                                                                        )
                                                                    }
                                                                }}
                                                                loading={loading}
                                                                title={this.loc(t => t.deleteModalTitle)}
                                                                confirmButtonLabel={localize.translate(
                                                                    t => t.Core.Modal.removeDefinitely
                                                                )}
                                                                renderContent={() => (
                                                                    <Column bigSpacing={true}>
                                                                        <ErrorMessage path={'deleteControl'} />
                                                                        <Paragraph>
                                                                            {this.loc(t => t.deleteModalMessage)}
                                                                        </Paragraph>
                                                                    </Column>
                                                                )}
                                                            >
                                                                {openModal => (
                                                                    <Button
                                                                        type={ButtonType.secondary}
                                                                        danger={true}
                                                                        onClick={openModal}
                                                                    >
                                                                        {localize.translate(t => t.Core.Modal.delete)}
                                                                    </Button>
                                                                )}
                                                            </ConfirmModal>
                                                        </Row>
                                                        <Row alignRight={true}>
                                                            <Button type={ButtonType.tertiary} onClick={requestClose}>
                                                                {localize.translate(t => t.Core.Modal.cancel)}
                                                            </Button>
                                                            <Button
                                                                onClick={this.handleArchive(
                                                                    unarchiveControlDocumenMutation
                                                                )}
                                                            >
                                                                {localize.translate(
                                                                    t =>
                                                                        t.Customer.Compliance.ControlDocument
                                                                            .ArchivedControlDocumentModal.unarchive
                                                                )}
                                                            </Button>
                                                        </Row>
                                                    </Row>
                                                </Column>
                                            )}
                                        >
                                            <ErrorMessage path={'editControlDocument'} />
                                            <Form>
                                                <DocumentFields
                                                    disabled={true}
                                                    document={existingDocumentData}
                                                    onChange={value => this.setState({ repeats: value })}
                                                />
                                            </Form>
                                        </Modal>
                                    )
                                }}
                            </Query>
                        )}
                    </GQLMutation>
                )}
            </GQLMutation>
        )
    }

    private handleArchive = (mutate: MutationFn<UnarchiveMutationResponse, UnarchiveMutationVariables>) => async () => {
        const { requestClose, documentId } = this.props
        const response = await mutate({
            variables: {
                documentId,
            },
        })

        if (response && response.data && response.data.unarchiveControlDocument) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            requestClose()
        }
    }
}
