export const newRoutes = {
    index: '/*',
    cover: {
        index: 'auth/*',
        login: 'login',
        loginWithSSO: 'login-with-sso',
        saml: 'saml',
        redirect: 'redirect',
        forgotPassword: 'forgot-password',
        setPassword: 'set-password/:token',
        registerUserLegacy: 'invite/:token',
        registerUser: (token: string = ':token', email: string = ':email', language: string = ':language') =>
            `invite/${token}/${email}/${language}`,
        confirmAccount: 'confirm/:token',
        userMergeRequest: 'confirmMerge/:token',
    },
    legacyRedirect: (legacyDocumentKey: string = ':legacyDocumentKey') => `document/detail/${legacyDocumentKey}`,
    lawAndRegulationRedirect: (identifier: string = ':identifier') => `/law-and-regulation-redirect/${identifier}`,
    customer: (customer: string = ':customer') => {
        return {
            index: `${customer}/*`,
            switch: `/${customer}/switch`,
            dashboard: {
                index: `dashboard`,
            },
            settings: {
                index: `settings/*`,
                employees: {
                    index: `employees`,
                    detail: (id: string | number = ':id') => `employees/${id}`,
                    edit: (id: string | number = ':id') => `employees/${id}/edit`,
                },
                frameworks: {
                    index: `frameworks`,
                    //         view: (id: string | number = ':id') => `/${customer}/settings/frameworks/${id}`,
                    //         edit: (id: string | number = ':id') => `/${customer}/settings/frameworks/${id}/edit`,
                },
                departments: {
                    index: `departments`,
                },
                saml: {
                    index: `saml`,
                    //         edit: `/${customer}/settings/saml/edit`,
                },
                compliance: {
                    index: `compliance`,
                    edit: `compliance/edit`,
                },
                news: {
                    index: `news`,
                    edit: `news/edit`,
                },
                taskTemplates: {
                    index: `templates`,
                },
            },
            kitchensink: {
                index: `kitchensink`,
            },
            styleguide: {
                index: `styleguide`,
            },
            legalFramework: {
                index: `legal-framework/*`,
                themes: {
                    //         index: `/${customer}/legal-framework`,
                    view: (id: string | number = ':id') => `themes/${id}`,
                    viewApplicable: (id: string | number = ':id') => `themes/${id}/edit`,
                },
                topic: {
                    view: (id: string | number = ':id') => `topic/${id}`,
                    designAndEffectiveness: (id: string | number = ':id') => `topic/${id}/design-and-effectiveness`,
                },
            },
            radar: {
                index: `radar/*`,
                detail: {
                    //         index: `/${customer}/radar`,
                    view: (id: string | number = ':id') => `${id}`,
                },
            },
            monitoring: {
                index: `monitoring/*`,
                agenda: `agenda`,
                reports: {
                    index: `reports/*`,
                    detail: {
                        view: (id: string | number = ':id') => `${id}`,
                        edit: (id: string | number = ':id') => `${id}/edit`,
                    },
                },
            },
            consultations: {
                index: `consultations/*`,
                detail: {
                    //         index: `/${customer}/consultations`,
                    view: (id: string | number = ':id') => `${id}`,
                },
            },
            search: {
                index: `search`,
                //     query: (query: string) => `/${customer}/search?search=${encodeURIComponent(query.trim())}`,
            },
            lawAndRegulationRedirect: (id: number | string = ':id') => `law-redirect/${id}`,
            lawAndRegulation: {
                index: `law-and-regulation/*`,
                redirect: (identifier: string = ':identifier') => `redirect/${identifier}`,
                detail: {
                    singleArticle: (articleId: string | number = ':articleId') => `article/${articleId}`,
                    view: (abstractId: string | number = ':abstractId') => `${abstractId}/*`,
                    article: (articleId: string | number = ':articleId') => `article/${articleId}`,
                },
            },
            news: {
                index: `news/*`,
                detail: {
                    view: (id: string | number = ':id') => `item/${id}`,
                },
            },
            risks: {
                index: `risks/*`,
                heatmap: {
                    index: `heatmap`,
                    // view: (id: string | number = ':id') => `/${customer}/risks/${id}`,
                },
                assessments: {
                    index: `assessments/*`,
                    view: (id: string | number = ':id') => `${id}`,
                    properties: (id: string | number = ':id') => `${id}/properties`,
                    log: (id: string | number = ':id') => `${id}/log`,
                    edit: (id: string | number = ':id') => `${id}/properties/edit`,
                },
            },
            compliance: {
                index: `compliance/*`,
                controls: {
                    index: `controls/*`,
                    view: (id: string | number = ':id') => `${id}`,
                    edit: (id: string | number = ':id') => `${id}/edit`,
                },
            },
            // overviewView: {
            //     index: `/${customer}/overview-view`,
            // },
            tasks: {
                index: `tasks/*`,
                overview: `overview/*`,
                view: (id: string | number = ':id') => `overview?/${id}`,
            },
            inbox: {
                index: `inbox/*`,
                detail: {
                    view: (id: string | number = ':id') => `${id}/`,
                },
                internalAlert: {
                    view: (id: string | number = ':id') => `internal-alert/${id}`,
                },
            },
            signaling: {
                index: `signaling/*`,
                detail: {
                    view: (id: string | number = ':id') => `${id}`,
                },
            },
            control: {
                index: `control/*`,
                taskControl: {
                    index: `task-control/*`,
                    view: (id: string | number = ':id') => `task-control?/${id}`,
                },
                inboxControl: `inbox-control`,
            },
        }
    },
    consultant: {
        index: 'consultant/*',
        dashboard: {
            index: 'dashboard',
        },
        customerGroups: {
            index: 'customer-groups/*',
            create: 'create',
            detail: (id: number | string = ':id') => ({
                view: `${id}`,
                edit: `${id}/edit`,
                newCustomer: (name: string = ':name') => `${id}/${name}/new-customer`,
            }),
        },
        customers: {
            index: 'customers/*',
            view: (id: number | string = ':id') => `${id}/*`,
            create: 'create',
            profile: (id: number | string = ':id') => `profiles`,
            edit: (id: number | string = ':id') => `profiles/edit`,
            consultants: (id: number | string = ':id') => `consultants`,
        },
        customerfeeds: {
            index: 'customerfeeds/*',
            view: (id: number | string = ':id') => `${id}`,
        },
        organization: {
            index: 'organization/*',
            employees: {
                index: 'employees',
                view: (id: string | number = ':id') => `employees/${id}`,
                edit: (id: string | number = ':id') => `employees/${id}/edit`,
            },
            profile: {
                index: 'profile',
                edit: 'profile/edit',
            },
        },
        taskTemplates: {
            index: 'task-templates',
        },
    },
}
