import './NewsSourcesLabel.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { NewsItemSourceType } from '~/generated/graphql'

interface Props {
    subtle?: boolean
    sources?: Pick<NewsItemSourceType, 'id' | 'name'>[] | null
}

export class NewsSourcesLabel extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('NewsSourcesLabel')

    public render() {
        const { sources, subtle } = this.props

        if (!sources?.length) {
            return null
        }

        if (sources.length === 1) {
            return (
                <Paragraph subtle={subtle} className={this.bem.getClassName()}>
                    {sources[0].name}
                </Paragraph>
            )
        }

        return (
            <Tooltip className={this.bem.getClassName()} content={this.renderContent()}>
                <Paragraph subtle={subtle} bold={subtle}>
                    {localize.translate(t => t.News.Attributes.sources, { count: sources.length })}
                </Paragraph>
            </Tooltip>
        )
    }

    private renderContent() {
        return (
            <Column className={this.bem.getElement('content')} smallSpacing={true}>
                <Paragraph bold={true} small={true}>
                    {localize.translate(t => t.Entities.Sources)}
                </Paragraph>
                {this.props.sources?.map(s => (
                    <Paragraph small={true} key={s.id}>
                        {s.name}
                    </Paragraph>
                ))}
            </Column>
        )
    }
}
