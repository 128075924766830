import './Icon.scss'

import React from 'react'

import coreIcons from './icons.json'
import frameworkIcons from './framework-icons.json'

import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { CustomerFrameworkIconEnumType } from '~/generated/graphql'

interface Props {
    className?: string
    type: IconType | CustomerFrameworkIconEnumType
    onClick?: () => void
    count?: number
    subtler?: boolean // darker
    subtle?: boolean // normal
    verySubtle?: boolean // lighter
    large?: boolean
    small?: boolean
}

const icons = { ...coreIcons, ...frameworkIcons }

export class Icon extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('Icon', () => ({
        [this.props.type as string]: true,
        subtler: this.props.subtler,
        subtle: this.props.subtle,
        verySubtle: this.props.verySubtle,
        large: this.props.large,
        small: this.props.small,
    }))

    public render() {
        const { className, onClick, type, count } = this.props

        return (
            <i
                className={this.bem.getClassName(className)}
                dangerouslySetInnerHTML={{
                    __html: !count || count <= 0 ? icons[type] : this.renderCount(count),
                }}
                onClick={() => onClick && onClick()}
            />
        )
    }

    private renderCount(count: number) {
        const { type } = this.props
        const formattedCount = count >= 100 ? '99+' : count
        return `${icons[type]} <div style="width: ${count.toString().length * 8}px;" class=${this.bem.getElement(
            'count'
        )}>${formattedCount}</div>`
    }
}
