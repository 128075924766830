import React from 'react'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'

const LIST_RISK_TYPES = gql`
    query taskTags($skip: Int, $take: Int, $filters: TaskTagFiltersInputType) {
        taskTags(skip: $skip, take: $take, filters: $filters) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`

interface TaskTag {
    id: number
    name: string
}

interface State {
    search: string | null
}

interface Props {
    name: string
    defaultValue?: SelectOption<number>[]
    value?: SelectOption[]
    isFilter?: boolean
    onChange?: (option: SelectOption<number>[] | null, name: string) => void
}

export class TaskTagsSelect extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: '',
    }

    public render() {
        const { name, defaultValue, onChange, isFilter, value } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<TaskTag> query={LIST_RISK_TYPES} variables={{ filters: { search } }}>
                {({ data, loading, loadingMore, fetchMore }) => {
                    const options =
                        data && data.nodes.length ? data.nodes.map(node => ({ label: node.name, value: node.id })) : []

                    return (
                        <Form.Select
                            name={name || 'taskTags'}
                            searchable={true}
                            options={options}
                            creatable={isFilter ? false : true}
                            isFilter={isFilter}
                            onEndReached={fetchMore}
                            onSearch={value => this.setState({ search: value })}
                            loading={loading || loadingMore}
                            value={value}
                            defaultValue={defaultValue}
                            clearable={isFilter ? true : false}
                            multi={true}
                            onChange={
                                onChange
                                    ? (options: SelectOption<number>[]) => {
                                          onChange(options, name || 'taskTags')
                                      }
                                    : undefined
                            }
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }
}
