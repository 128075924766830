import React from 'react'
import addYears from 'date-fns/add_years'
import getYear from 'date-fns/get_year'

import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { localize } from '~/bootstrap'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Row } from '~/components/Core/Layout/Row'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { RadarDisplay } from '~/views/Customer/Radar/RadarDisplay'
import {
    RadarItemsDocument,
    RadarItemsQueryVariables,
    CustomerFrameworkFieldsFragment,
    RadarPhaseEnumType,
} from '~/generated/graphql'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { RadarPhaseSelect } from '~/components/Domain/Radar/RadarPhaseSelect'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps {
    className?: ClassValue
}

export enum Impact {
    Low = 'LOW',
    Average = 'AVERAGE',
    High = 'HIGH',
    Highest = 'HIGHEST',
}

export interface RadarItemNode {
    id: number
    name: string
    expectedDate: string
    impact: Impact
    lastEditorialChangeAt: string
    phase: {
        id: number
        order: number
        name: string
    }
    customerFrameworks: CustomerFrameworkFieldsFragment[]
    following: boolean
}

export interface RadarItemsGroupedByPeriod {
    [period: string]: RadarItemNode[]
}

interface Filters {
    isRange?: boolean
    selectedYear?: number
    phase?: SelectOption<RadarPhaseEnumType> | null
    following?: SelectOption
}

class RadarOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RadarOverviewView')

    private loc = localize.namespaceTranslate(t => t.Customer.RadarView)

    public render() {
        const { className } = this.props
        const rangeOptions = [
            {
                label: `${getYear(new Date())}-${getYear(addYears(new Date(), 3))}`,
                value: 'range',
            },
            {
                label: `${getYear(new Date())}`,
                value: getYear(new Date()),
            },
            {
                label: `${getYear(addYears(new Date(), 1))}`,
                value: getYear(addYears(new Date(), 1)),
            },
            {
                label: `${getYear(addYears(new Date(), 2))}`,
                value: getYear(addYears(new Date(), 2)),
            },
            {
                label: `${getYear(addYears(new Date(), 3))}`,
                value: getYear(addYears(new Date(), 3)),
            },
        ]

        return (
            <Page className={this.bem.getClassName(className)}>
                <ParamManager<Filters>
                    defaultState={{
                        isRange: true,
                        following: {
                            label: this.loc(t => t.filters.following),
                            value: 'following',
                        },
                    }}
                >
                    {({ paramState, setParamState }) => {
                        const filterActive = !!paramState.phase || !!paramState.selectedYear || !paramState.following
                        const followingInDepartment =
                            paramState.following && paramState.following.value === 'following'
                                ? this.context.activeDepartmentId
                                : undefined

                        return (
                            <>
                                <PageHeader
                                    breadCrumbs={[
                                        breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
                                    ]}
                                    title={this.loc(t => t.title)}
                                    actionComponent={
                                        <Row>
                                            <FilterDropdown hasActiveFilter={filterActive}>
                                                <FilterOption
                                                    label={this.loc(t => t.filters.expectedIn)}
                                                    forInputName="range"
                                                >
                                                    <Select
                                                        name={'range'}
                                                        placeholder={this.loc(t => t.RadarOverviewView.selectPeriod)}
                                                        onChange={option =>
                                                            this.handleSelectChange(option, setParamState)
                                                        }
                                                        clearable={false}
                                                        searchable={false}
                                                        defaultValue={this.getRangeDefaultValue(
                                                            rangeOptions,
                                                            paramState
                                                        )}
                                                        options={rangeOptions}
                                                    />
                                                </FilterOption>
                                                <FilterOption
                                                    label={this.loc(t => t.filters.phase)}
                                                    forInputName="phase"
                                                >
                                                    <RadarPhaseSelect
                                                        name={'phase'}
                                                        selectedOption={paramState.phase}
                                                        onChange={option => {
                                                            if (!option) {
                                                                setParamState({
                                                                    phase: undefined,
                                                                })
                                                            }

                                                            setParamState({
                                                                phase: option,
                                                            })
                                                        }}
                                                    />
                                                </FilterOption>
                                                <FilterOption
                                                    label={this.loc(t => t.filters.followingStatus)}
                                                    forInputName="followingStatus"
                                                >
                                                    <Select
                                                        name="following"
                                                        clearable={false}
                                                        searchable={false}
                                                        defaultValue={paramState.following && [paramState.following]}
                                                        onChange={option => {
                                                            this.handleFollowingSelectChange(option, setParamState)
                                                        }}
                                                        options={[
                                                            {
                                                                label: this.loc(
                                                                    t => t.filtersFollowingStatusOptions.following
                                                                ),
                                                                value: 'following',
                                                            },
                                                            {
                                                                label: this.loc(
                                                                    t => t.filtersFollowingStatusOptions.all
                                                                ),
                                                                value: 'all',
                                                            },
                                                        ]}
                                                    />
                                                </FilterOption>
                                            </FilterDropdown>
                                        </Row>
                                    }
                                />

                                <Column>
                                    <PaginatableQuery<RadarItemNode, RadarItemsQueryVariables>
                                        query={RadarItemsDocument}
                                        startingTake={30}
                                        take={15}
                                        variables={{
                                            filters: {
                                                customerFrameworkIds: this.context.activeProfiles,
                                                departmentIds: [this.context.activeDepartmentId],
                                                phase: paramState.phase ? paramState.phase.value : undefined,
                                                followingInDepartment,
                                            },
                                            customerSlug: this.context.customer.slug,
                                            departmentId: this.context.activeDepartmentId,
                                        }}
                                    >
                                        {({ data, loading, fetchMore, loadingMore }) => {
                                            const items = data && data.nodes ? data.nodes : []

                                            return (
                                                <RadarDisplay
                                                    items={items}
                                                    hasMore={data && data.hasNextPage ? true : false}
                                                    loading={loading}
                                                    loadingMore={loadingMore}
                                                    isRange={paramState.isRange}
                                                    selectedYear={paramState.selectedYear}
                                                    loadMore={fetchMore}
                                                />
                                            )
                                        }}
                                    </PaginatableQuery>
                                </Column>
                            </>
                        )
                    }}
                </ParamManager>
            </Page>
        )
    }

    private getRangeDefaultValue(options: SelectOption[], paramState: Filters) {
        let defaultOption: SelectOption[] | undefined

        if (paramState.isRange) {
            defaultOption = [
                {
                    label: `${getYear(new Date())}-${getYear(addYears(new Date(), 3))}`,
                    value: 'range',
                },
            ]
        }

        if (paramState.selectedYear) {
            const selectedYearOption = options.find(option => option.value === paramState.selectedYear)

            defaultOption = selectedYearOption && [selectedYearOption]
        }

        return defaultOption
    }

    private handleSelectChange(
        option: SelectOption | SelectOption[] | null,
        setParamState: (newState: Filters) => void
    ) {
        if (!option) {
            return
        }

        option = Array.isArray(option) ? option[0] : option

        if (option.value === 'range') {
            setParamState({
                isRange: true,
                selectedYear: undefined,
            })
        } else {
            setParamState({
                isRange: false,
                selectedYear: option.value as number,
            })
        }
    }

    private handleFollowingSelectChange(
        option: SelectOption | SelectOption[] | null,
        setParamState: (newState: Filters) => void
    ) {
        if (!option) {
            return
        }

        option = Array.isArray(option) ? option[0] : option

        setParamState({
            following: option,
        })
    }
}

export const RadarOverviewView = withRouter(RadarOverviewViewComponent)
