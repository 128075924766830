import './ViewTaskModalSubTasksAndLogs.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TaskLogsContainer } from '../TaskLogsContainer'
import { BEM } from '~/services/BEMService'
import { CreateSubTaskModalContainer } from './CreateSubTaskModalContainer'
import { TaskDetailFields } from '~/graphql/types/Task'
import { SubTasksTableContainer } from './SubTasksTableContainer'

interface Props {
    task: TaskDetailFields
    isSubTask: boolean
    onChangeTask?: () => void
}

interface State {
    showLogs: boolean
}

export class ViewTaskModalSubTasksAndLogs extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        showLogs: this.props.isSubTask,
    }

    private refetchLogs: () => void
    private loc = localize.namespaceTranslate(t => t.Customer.Task.viewTaskModal)
    private bem = new BEM('ViewTaskModalSubTasksAndLogs')

    public render() {
        return (
            <Column bigSpacing={true} className={this.bem.getClassName()}>
                {this.renderHeader()}
                {this.renderContent()}
            </Column>
        )
    }

    private renderHeader() {
        return (
            <Row spaceBetween={true}>
                {this.renderTabButtons()}
                {this.renderCreateSubTaskButton()}
            </Row>
        )
    }

    private renderContent() {
        const { showLogs } = this.state
        const { task, onChangeTask, isSubTask } = this.props

        if (showLogs) {
            return <TaskLogsContainer setRefetch={this.setRefetchLogs} taskId={task.id} isSubTask={isSubTask} />
        }

        const { subTasks } = task
        if (!subTasks || !subTasks.length) {
            return this.renderNoSubTaskMessage()
        }

        return <SubTasksTableContainer subTasks={subTasks} onChangeSubTask={onChangeTask} />
    }

    private renderTabButtons() {
        const { showLogs } = this.state
        const { isSubTask } = this.props

        return (
            <Row>
                {!isSubTask && (
                    <Button type={ButtonType.noStyling} onClick={() => this.handleOnTabClick({ showLogs: false })}>
                        <SectionTitle bold={true} underline={!showLogs} subtle={showLogs}>
                            {this.loc(t => t.subTasks)}
                        </SectionTitle>
                    </Button>
                )}
                <Button type={ButtonType.noStyling} onClick={() => this.handleOnTabClick({ showLogs: true })}>
                    <SectionTitle bold={true} underline={showLogs} subtle={!showLogs}>
                        {this.loc(t => t.log)}
                    </SectionTitle>
                </Button>
            </Row>
        )
    }

    private renderCreateSubTaskButton() {
        const { isSubTask, task, onChangeTask } = this.props

        if (isSubTask) {
            return
        }

        const hasNoPermission = !permissions.canCreateTasks(this.context.activeDepartmentId)

        return (
            <ModalManager
                render={openModal => (
                    <Button
                        type={ButtonType.actionLink}
                        disabled={hasNoPermission}
                        icon={IconType.addCircle}
                        onClick={openModal}
                    >
                        {this.loc(t => t.newSubTask)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <CreateSubTaskModalContainer
                        linkedItem={task.linkedItem || undefined}
                        parentTaskName={task.name}
                        parentTaskId={task.id}
                        requestClose={closeModal}
                        onCreate={() => {
                            if (onChangeTask) {
                                onChangeTask()
                            }

                            if (this.refetchLogs) {
                                this.refetchLogs()
                            }
                        }}
                    />
                )}
            />
        )
    }

    private renderNoSubTaskMessage() {
        return (
            <Row fullWidth={true} alignCenter={true} className={this.bem.getElement('no-sub-task-message')}>
                <SectionTitle bold={true} subtle={true}>
                    {localize.translate(t => t.Customer.Task.viewTaskModal.noSubTasksMessage)}
                </SectionTitle>
            </Row>
        )
    }

    private handleOnTabClick({ showLogs }: { showLogs: boolean }) {
        const { isSubTask } = this.props

        if (isSubTask) {
            return
        }

        if (this.state.showLogs === showLogs) {
            return
        }

        this.setState({ showLogs })
    }

    private setRefetchLogs = (refetch: () => void) => {
        this.refetchLogs = refetch
    }
}
