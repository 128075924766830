import './CustomerFrameworkProfileModal.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Modal, ModalProps } from '~/components/Core/Feedback/Modal/Modal'
import { localize, notification, permissions } from '~/bootstrap'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { RadioItem } from '~/components/Domain/CustomerFramework/CustomerFrameworkRadio'
import {
    FrameworkIcon,
    frameworkIconColorOrder,
} from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { GQLMutation } from '~/graphql/Mutation'
import {
    CustomerFrameworkIconEnumType,
    CustomerFrameworkColorEnumType,
    DeleteCustomerFrameworkMutationVariables,
    DeleteCustomerFrameworkMutation,
} from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { DeleteCustomerFramework } from './CustomerFrameworkMutations/DeleteCustomerFramework'
import { ButtonType } from '~/components/Core/Button/Button'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Bold } from '~/components/Core/Typography/Bold'

interface Props extends Omit<Omit<ModalProps, 'title'>, 'confirmButtonLabel'> {
    customerFramework: CustomerFramework
    frameworks: CustomerFramework[]
}

interface CustomerFramework {
    id: number
    name: string
    color: CustomerFrameworkColorEnumType
    icon: CustomerFrameworkIconEnumType
}

interface State {
    selectedColor?: CustomerFrameworkColorEnumType
}

interface ProfileMutationResponse {
    editCustomerFramework: {
        id: number
        fields: {
            name: string
            color: CustomerFrameworkColorEnumType
            icon: CustomerFrameworkIconEnumType
        }
    }
}

interface ProfileMutationVariables {
    id: number
    customerSlug?: string
    fields: {
        name?: string
        color: CustomerFrameworkColorEnumType
        icon: CustomerFrameworkIconEnumType
    }
}

const EDIT_CUSTOMER_FRAMEWORK_MUTATION = gql`
    mutation editCustomerFramework($id: Int!, $fields: CustomerFrameworkFieldsInputType!, $customerSlug: String) {
        editCustomerFramework(id: $id, fields: $fields, customerSlug: $customerSlug) {
            id
            color
            icon
            name
        }
    }
`

export class CustomerFrameworkProfileModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public customerFrameworks = this.props.frameworks

    public state: State = {
        selectedColor: this.getAdditionalAvailableColors(2)[0].color,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks)
    private bem = new BEM('CustomerFrameworkProfileModal')

    public render() {
        const { className, ...restProps } = this.props
        const { selectedColor } = this.state

        const getAvailableIcons = () => {
            const icons = Object.values(CustomerFrameworkIconEnumType)

            const filtered = [
                this.props.customerFramework.icon,
                ...icons.filter(icon => !this.customerFrameworks.some(framework => framework.icon === icon)),
            ].map(icon => ({
                color: selectedColor,
                icon: icon,
            }))

            return filtered
        }

        const availableColors = this.getAdditionalAvailableColors(1)
        const availableIcons = getAvailableIcons()

        return (
            <DeleteCustomerFramework>
                {deleteMutationFn => (
                    <GQLMutation<ProfileMutationResponse, ProfileMutationVariables>
                        mutation={EDIT_CUSTOMER_FRAMEWORK_MUTATION}
                    >
                        {(mutate, { loading }) => (
                            <Form
                                onSubmit={this.handleSubmit(mutate)}
                                defaultState={{ color: selectedColor, icon: availableIcons[0].icon }}
                                extraFieldSpacing={true}
                            >
                                <Modal
                                    {...restProps}
                                    submitForm={true}
                                    title={this.loc(t => t.editProfileNameAndIcon)}
                                    confirmButtonLabel={localize.translate(t => t.Generic.save)}
                                    className={this.bem.getClassName(className)}
                                    loading={loading}
                                    onDelete={
                                        permissions.isConsultantUser()
                                            ? () => this.handleDelete(deleteMutationFn)
                                            : undefined
                                    }
                                    renderOnDeleteContent={() => this.renderOnDeleteContent()}
                                    onDeleteTitle={this.loc(t => t.deleteConfirmModal.title)}
                                    onDeleteConfirmButtonType={ButtonType.delete}
                                    onDeleteConfirmButtonLabel={this.loc(t => t.deleteConfirmModal.confirmButtonLabel)}
                                    onDeleteClassName={this.bem.getElement('delete-confirm-modal')}
                                >
                                    <ErrorMessage path={'editCustomerFramework'} />
                                    <Column bigSpacing={true} className={this.bem.getElement('field-container')}>
                                        <Field
                                            label={localize.translate(t => t.Settings.Attributes.name)}
                                            forInput={'name'}
                                        >
                                            <Form.Input
                                                name={'name'}
                                                placeholder={this.loc(t => t.profileName)}
                                                autoComplete={false}
                                                defaultValue={this.props.customerFramework.name}
                                            />
                                        </Field>
                                        <Field
                                            label={localize.translate(t => t.Settings.Attributes.color)}
                                            forInput={'color'}
                                        >
                                            <Form.CustomerFrameworkRadio
                                                name={'color'}
                                                onChange={this.handleRadioChange}
                                                optionType={'color'}
                                                defaultOption={availableColors[0]}
                                                options={availableColors}
                                            />
                                        </Field>
                                        <Field
                                            label={localize.translate(t => t.Settings.Attributes.icon)}
                                            forInput={'icon'}
                                        >
                                            <Form.CustomerFrameworkRadio
                                                name={'icon'}
                                                onChange={this.handleRadioChange}
                                                optionType={'icon'}
                                                defaultOption={availableIcons[0]}
                                                options={availableIcons}
                                            />
                                        </Field>
                                    </Column>
                                </Modal>
                            </Form>
                        )}
                    </GQLMutation>
                )}
            </DeleteCustomerFramework>
        )
    }

    private getAdditionalAvailableColors(amountOfColors: number) {
        const availableColors = []
        const colors = frameworkIconColorOrder.filter(
            orderColor => !this.customerFrameworks.some(({ color }) => color === orderColor)
        )

        for (let i = 0; i < amountOfColors; i++) {
            const colorIndex = i % colors.length
            availableColors.push({
                color: colors[colorIndex],
                icon: undefined,
            })
        }

        availableColors.unshift({
            color: this.props.customerFramework.color,
            icon: undefined,
        })

        return availableColors
    }

    private async handleDelete(
        mutate: MutationFn<DeleteCustomerFrameworkMutation, DeleteCustomerFrameworkMutationVariables>
    ) {
        const { customerFramework, requestClose } = this.props
        const response = await mutate({ variables: { id: customerFramework.id } })

        if (response && response.data?.deleteCustomerFramework) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            if (requestClose) {
                requestClose()
            }
        }
    }

    private handleRadioChange = (option: RadioItem, name: string) => {
        if (name === 'color') {
            this.setState({
                selectedColor: option.color as CustomerFrameworkColorEnumType,
            })
        }
    }

    private renderOnDeleteContent() {
        const { customerFramework } = this.props
        const isInactive = this.context.activeProfiles.includes(customerFramework.id) === false

        return (
            <Paragraph>
                {this.loc(t => t.deleteConfirmModal.contentPart1)}
                <FrameworkIcon
                    frameworkType={customerFramework.icon}
                    color={customerFramework.color}
                    isInactive={isInactive}
                    className={this.bem.getElement('delete-confirm-modal-framework-icon')}
                />
                <Bold inline={true}>{customerFramework.name}</Bold>
                {this.loc(t => t.deleteConfirmModal.contentPart2)}
            </Paragraph>
        )
    }

    private handleSubmit =
        (mutate: MutationFn<ProfileMutationResponse, ProfileMutationVariables>) => async (formState: FormState) => {
            const { name } = formState
            const { icon } = formState.icon
            const { color } = formState.color
            const { customerFramework, requestClose } = this.props

            const response = await mutate({
                variables: {
                    id: customerFramework.id,
                    customerSlug: this.context.customer.slug,
                    fields: {
                        icon,
                        name,
                        color,
                    },
                },
            })

            if (response && response.data && response.data.editCustomerFramework) {
                if (requestClose) {
                    requestClose()
                    notification.success(this.loc(t => t.succes))
                }
            }
        }
}
