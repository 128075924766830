import './SearchField.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import debounce from 'lodash-es/debounce'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'

interface Props {
    className?: ClassValue
    value: string
    onSearch: (searchString: string) => void
}

interface State {
    value: string
}

export class SearchField extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        value: this.props.value,
    }

    private bem = new BEM('SearchField')

    public render() {
        const { className } = this.props
        const { value } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <Icon type={IconType.search} className={this.bem.getElement('search')} />
                <input
                    className={this.bem.getElement('input')}
                    name={'search'}
                    value={value}
                    onChange={this.handleOnChange}
                    autoFocus={true}
                />
                <Button
                    className={this.bem.getElement('clear-button')}
                    icon={IconType.close}
                    type={ButtonType.noStyling}
                    onClick={() => this.setValue('')}
                />
            </div>
        )
    }

    public setSearchValue(value: string) {
        this.setState({
            value,
        })
    }

    // tslint:disable-next-line:member-ordering
    private onSearch = debounce((value: string) => {
        const { onSearch } = this.props
        onSearch(value.trim())
    }, 200)

    private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        this.setValue(value)
    }

    private setValue(value: string) {
        this.setState(
            {
                value,
            },
            () => this.onSearch(value)
        )
    }
}
