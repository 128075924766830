import './Column.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    bigSpacing?: boolean
    extraBigSpacing?: boolean
    smallSpacing?: boolean
    extraSmallSpacing?: boolean
    doubleSpacing?: boolean
    bigBottomSpacing?: boolean
    noSpacing?: boolean
    style?: React.CSSProperties
}

interface State {}

export class Column extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Column', () => ({
        'has-big-spacing': !!this.props.bigSpacing,
        'has-extra-big-spacing': !!this.props.extraBigSpacing,
        'has-small-spacing': !!this.props.smallSpacing,
        'has-extra-small-spacing': !!this.props.extraSmallSpacing,
        'has-double-spacing': !!this.props.doubleSpacing,
        'has-big-bottom-spacing': !!this.props.bigBottomSpacing,
        'has-no-spacing': this.props.noSpacing,
    }))

    public render() {
        const { style, children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)} style={style}>
                {children}
            </div>
        )
    }
}
