import './MonitoringReportsOverviewView.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { MonitoringReportsContainer } from '~/components/Domain/Monitoring/MonitoringReportsTable/MonitoringReportsContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { BEM } from '~/services/BEMService'
import { MonitoringReportsQuery } from '~/components/Domain/Monitoring/MonitoringReportsTable/MonitoringReportsQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { MonitoringReportsStatusSelect } from '~/components/Domain/Monitoring/MonitoringReportsStatusSelect'
import MonitoringReportsCreateButtonContainer from '~/components/Domain/Monitoring/MonitoringReportsCreateForm/MonitoringReportsCreateButtonContainer'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface Filters {
    searchString?: string
    archived?: boolean
}

class MonitoringReportsOverviewViewComponent extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportsOverviewView')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringOverviewView)

    public render() {
        return (
            <ParamManager<Filters> defaultState={{}}>
                {({ paramState, setParamState }) => {
                    const { searchString, archived } = paramState

                    return (
                        <Page contained={true} className={this.bem.getClassName()}>
                            <PageHeader
                                title={this.loc(t => t.title)}
                                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).monitoring.index]}
                                actionComponent={this.renderActionComponent(paramState, setParamState)}
                            />
                            <MonitoringReportsQuery searchString={searchString} archived={archived}>
                                {({ data, loading, loadingMore, refetch }) => {
                                    if (loading) {
                                        return <Spinner />
                                    }

                                    return (
                                        <MonitoringReportsContainer
                                            className={this.bem.getElement('table-container')}
                                            monitoringReports={data?.nodes || []}
                                            loadingMore={loadingMore}
                                            refetch={refetch}
                                            hideBulkAction={paramState.archived}
                                        />
                                    )
                                }}
                            </MonitoringReportsQuery>
                        </Page>
                    )
                }}
            </ParamManager>
        )
    }

    private renderActionComponent(paramState: Filters, setParamState: SetParamStateFn<Filters>) {
        return (
            <Row>
                <Search
                    placeholder={this.loc(t => t.searchPlaceholder)}
                    onChange={search => setParamState({ searchString: search || undefined })}
                    value={paramState.searchString}
                />
                {this.renderFilters(paramState, setParamState)}
                <MonitoringReportsCreateButtonContainer />
            </Row>
        )
    }

    private renderFilters(paramState: Filters, setParamState: SetParamStateFn<Filters>) {
        const { archived, searchString } = paramState

        return (
            <FilterDropdown hasActiveFilter={!!paramState.archived}>
                <FilterOption label={this.loc(t => t.StatusSelect.status)} forInputName="status">
                    <MonitoringReportsStatusSelect
                        defaultValue={archived}
                        onChange={archived => setParamState({ searchString, archived })}
                    />
                </FilterOption>
            </FilterDropdown>
        )
    }
}

export const MonitoringReportsOverviewView = withRouter(MonitoringReportsOverviewViewComponent)
