import './ComponentTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Link } from 'react-router-dom'

interface Props {
    title: React.ReactNode
    className?: ClassValue
    bold?: boolean
    to?: string
    disabled?: boolean
    subtle?: boolean
}

interface State {}

export class ComponentTitle extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ComponentTitle', () => ({
        'is-to': !!this.props.to,
        'is-bold': this.props.bold,
        'is-disabled': this.props.disabled,
        'is-subtle': this.props.subtle,
    }))

    public render() {
        const { to, title, className } = this.props

        if (to) {
            return (
                <Link to={to} className={this.bem.getClassName(className)}>
                    {title}
                </Link>
            )
        }

        return <h3 className={this.bem.getClassName(className)}>{title}</h3>
    }
}
