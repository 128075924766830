import './ExpandableTableListItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TableListItem } from '~/components/Core/DataDisplay/TableList/TableListItem'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    renderExpanded: () => React.ReactNode
    onExpandedStateChange?: (isExpanded: boolean) => void
    isExpanded?: boolean
}

interface State {
    isExpanded: boolean
}

export class ExpandableTableListItem extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        isExpanded: this.props.isExpanded ?? false,
    }

    private bem = new BEM('ExpandableTableListItem', () => ({
        'is-expanded': this.state.isExpanded,
    }))

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.isExpanded !== this.props.isExpanded) {
            this.setState({ isExpanded: this.props.isExpanded ?? false })
        }
    }

    public render() {
        const { children, className, renderExpanded } = this.props
        const { isExpanded } = this.state

        return (
            <TableListItem className={this.bem.getClassName(className)}>
                <Row className={this.bem.getElement('inner')} spaceBetween={true}>
                    <div style={{ flex: 1 }}>{children}</div>
                    <Button
                        type={ButtonType.noStyling}
                        onClick={this.handleOnToggleClick}
                        className={this.bem.getElement('button')}
                    >
                        <Icon type={isExpanded ? IconType.arrowUp : IconType.arrowDown} />
                    </Button>
                </Row>

                {isExpanded && <ul className={this.bem.getElement('children')}>{renderExpanded()}</ul>}
            </TableListItem>
        )
    }

    private handleOnToggleClick = () => {
        const { onExpandedStateChange } = this.props
        const { isExpanded } = this.state
        const newState = !isExpanded

        this.setState({ isExpanded: newState }, () => {
            if (onExpandedStateChange) {
                onExpandedStateChange(newState)
            }
        })
    }
}
