import React from 'react'
import { FileInput } from '~/components/Core/DataEntry/FileInput/FileInput'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    name: string
    onChange: (files: File[]) => void
    defaultFiles?: File[]
}

interface State {
    files: File[]
}

export class RepeatableFileInput extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        files: this.props.defaultFiles || [],
    }

    public render() {
        return (
            <Column smallSpacing={true} key={this.state.files.length}>
                {this.renderFileInputs()}
                <FileInput name={this.props.name} onChange={file => this.handleChange(file)} />
            </Column>
        )
    }

    private renderFileInputs() {
        const { name } = this.props
        const { files } = this.state

        return files.map((file, i) => (
            <FileInput
                key={`file-${file.name}-${i}`}
                name={name}
                onClear={() => this.handleClear(i)}
                onChange={file => this.handleChange(file)}
                defaultFile={{ filename: file.name }}
            />
        ))
    }

    private handleClear(index: number) {
        const { files } = this.state
        const { onChange } = this.props

        const filteredFiles = [...files]
        filteredFiles.splice(index, 1)
        this.setState({ files: filteredFiles })

        onChange(filteredFiles)
    }

    private handleChange(file: File | null) {
        if (!file) {
            return
        }

        const { files } = this.state
        const { onChange } = this.props

        const updatedFiles = [...files, file]

        this.setState({ files: updatedFiles })

        onChange(updatedFiles)
    }
}
