import React from 'react'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import gql from 'graphql-tag'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'

interface Props {
    name?: string
    onChange: (option: SelectOption<string> | null) => void
    selectedOptions?: SelectOption<string>[] | null
    placeholder?: string
    isFilter?: boolean
}

interface State {
    search: string | null
}

const QUERY = gql`
    query topics($skip: Int, $take: Int, $filters: TopicFilters, $sort: TopicSort) {
        topics(filters: $filters, skip: $skip, take: $take, sort: $sort) {
            hasNextPage
            nodes {
                id
                name
                theme {
                    id
                    name
                }
            }
        }
    }
`

interface Topic {
    id: number
    name: string
    theme: {
        id: number
        name: string
    }
}

interface SelectOptionGroup {
    label: string
    options: {
        value: number
        label: string
    }[]
}

export class TopicSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: '',
    }

    public render() {
        const { name, onChange, selectedOptions, placeholder, isFilter } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<Topic>
                query={QUERY}
                variables={{
                    filters: {
                        search: search,
                        departmentId: this.context.activeDepartmentId,
                    },
                    sort: {
                        theme: 'ASC',
                    },
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key="loading" name={'loading'} loading={true} />
                    }

                    const topicsGroupedByTheme = this.groupTopicsByTheme(data.nodes)

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name || 'topic'}
                            defaultValue={selectedOptions || undefined}
                            searchable={true}
                            options={topicsGroupedByTheme}
                            onEndReached={fetchMore}
                            clearable={true}
                            loading={loading || loadingMore}
                            onChange={(option: SelectOption<string>) => {
                                onChange(option)
                            }}
                            onSearch={query => this.setState({ search: query })}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private groupTopicsByTheme(data: Topic[]): SelectOptionGroup[] {
        const grouped: SelectOptionGroup[] = []

        for (const topic of data) {
            let group = grouped.find(group => group.label === topic.theme.name)

            if (!group) {
                group = {
                    label: topic.theme.name,
                    options: [],
                }
                grouped.push(group)
            }

            group.options.push({ value: topic.id, label: topic.name })
        }

        return grouped
    }
}
