import './RelevantModalAddTopic.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { DepartmentType, LegalFrameworkCuratedByEnum } from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { ThemeSelect } from '~/components/Domain/Themes/ThemeSelect/ThemeSelect'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { CustomerFrameworkSelect } from '~/components/Domain/CustomerFramework/CustomerFrameworkSelect/CustomerFrameworkSelect'
import { TopicsQuery, Topic } from '~/components/Domain/Topic/TopicsQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Table, RowData } from '~/components/Core/DataDisplay/Table/Table'
import { Center } from '~/components/Core/Layout/Center'
import { TopicPreviewModal } from '~/components/Domain/Topic/TopicPreviewModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'
import { isNumber, uniqBy } from 'lodash-es'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    setModalState: (
        newModalContentState: { [key: string]: any },
        callBack?: (nextModalContentState: { [key: string]: any }) => void
    ) => void
    defaultTopics?: Topic[]
    defaultDepartments?: DepartmentType[]
    selectedTopics?: Topic[]
    selectedDeparments?: DepartmentType[]
}

interface State {
    search?: string | null
    themeSelectedOption?: SelectOption | null
    customerFrameworkSelectedOption?: SelectOption | null
}

export class RelevantModalAddTopic extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: undefined,
        themeSelectedOption: undefined,
        customerFrameworkSelectedOption: undefined,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModalAddTopic)
    private bem = new BEM('RelevantModalAddTopic')

    public render() {
        const { className } = this.props
        const { themeSelectedOption, customerFrameworkSelectedOption, search } = this.state
        return (
            <div className={this.bem.getClassName(className)}>
                <Column>
                    <Row className={this.bem.getElement('filters')} smallSpacing={true}>
                        <Search onChange={value => this.setState({ search: value })} />
                        <FilterDropdown center={true} hasActiveFilter={this.isFilterActive()}>
                            <FilterOption label={this.loc(t => t.filters.profiles)} forInputName="customerFramework">
                                <CustomerFrameworkSelect
                                    name={'customerFramework'}
                                    onChange={option => this.setState({ customerFrameworkSelectedOption: option })}
                                    isFilter={true}
                                    externallyCuratedOnly
                                    selectedOptions={
                                        customerFrameworkSelectedOption ? [customerFrameworkSelectedOption] : undefined
                                    }
                                />
                            </FilterOption>
                            <FilterOption label={this.loc(t => t.filters.theme)} forInputName="theme">
                                <ThemeSelect
                                    name={'theme'}
                                    onChange={option => this.setState({ themeSelectedOption: option })}
                                    isFilter={true}
                                    selectedOptions={themeSelectedOption ? [themeSelectedOption] : undefined}
                                    externallyCuratedOnly
                                    customerFrameworkIds={
                                        customerFrameworkSelectedOption &&
                                        isNumber(customerFrameworkSelectedOption.value)
                                            ? [customerFrameworkSelectedOption.value]
                                            : undefined
                                    }
                                />
                            </FilterOption>
                        </FilterDropdown>
                    </Row>
                    {/* Wrap the query to limit it's height but still trigger the infinite scroll */}
                    <div className={this.bem.getElement('query-wrapper')}>
                        <TopicsQuery
                            forModal
                            forCustomer
                            filters={{
                                search,
                                curatedBy: LegalFrameworkCuratedByEnum.external,
                                themeIds: themeSelectedOption ? [themeSelectedOption.value as number] : undefined,
                                customerFrameworkIds: customerFrameworkSelectedOption
                                    ? [customerFrameworkSelectedOption.value as number]
                                    : undefined,
                            }}
                        >
                            {({ data, loading, loadingMore }) => {
                                if (loading) {
                                    return (
                                        <Center className={this.bem.getElement('spinner-wrapper')}>
                                            <Spinner delayed={true} />
                                        </Center>
                                    )
                                }

                                const topics = data?.nodes || []

                                const tableData: RowData[] = this.getTableData(topics)

                                return (
                                    <Table
                                        className={this.bem.getElement('table')}
                                        columns={[
                                            { field: 'topic', headerLabel: localize.translate(t => t.Entities.Topic) },
                                            { field: 'theme', headerLabel: localize.translate(t => t.Entities.Theme) },
                                            {
                                                field: 'customerFrameworks',
                                                headerLabel: this.loc(t => t.filters.profiles),
                                            },
                                        ]}
                                        data={tableData}
                                        loading={loading}
                                        loadingMore={loadingMore}
                                    />
                                )
                            }}
                        </TopicsQuery>
                    </div>
                </Column>
            </div>
        )
    }

    private isFilterActive() {
        const { themeSelectedOption: themeId, customerFrameworkSelectedOption: customerFrameworkId } = this.state

        if (themeId || customerFrameworkId) {
            return true
        }

        return false
    }

    private getTableData(topics: Topic[]) {
        const { selectedTopics = [], defaultTopics = [] } = this.props

        return topics.map(topic => {
            const topicIsDefault = defaultTopics.some(t => t.id === topic.id)
            const topicIsSelected = selectedTopics.some(t => t.id === topic.id)

            return {
                id: topic.id,
                columns: {
                    topic: (
                        <Row>
                            <Checkbox
                                className={this.bem.getElement('checkbox')}
                                large
                                checked={topicIsSelected || topicIsDefault}
                                disabled={topicIsDefault}
                                name={'check'}
                                onChange={checked => {
                                    if (checked) {
                                        this.handleAddTopic(topic)
                                    } else {
                                        this.handleRemoveTopic(topic)
                                    }
                                }}
                            />
                            <TopicPreviewModal topicId={topic.id}>
                                {openModal => (
                                    <>
                                        <Button
                                            type={ButtonType.noStyling}
                                            onClick={openModal}
                                            className={this.bem.getElement('preview')}
                                        >
                                            <Tooltip
                                                message={topic.name || ''}
                                                className={this.bem.getElement('tool-tip')}
                                            >
                                                <Row>
                                                    <Paragraph truncateEllipsis>{topic.name}</Paragraph>
                                                    <InlineTextIcon type={IconType.eye} />
                                                </Row>
                                            </Tooltip>
                                        </Button>
                                    </>
                                )}
                            </TopicPreviewModal>
                        </Row>
                    ),
                    theme: (
                        <Tooltip message={topic.theme.name || ''} className={this.bem.getElement('tool-tip')}>
                            <Paragraph truncateEllipsis>{topic.theme.name}</Paragraph>
                        </Tooltip>
                    ),
                    customerFrameworks: (
                        <CustomerFrameworkIconList showAllActive customerFrameworks={topic.customerFrameworks} />
                    ),
                },
            }
        })
    }

    private handleAddTopic(topic: Topic) {
        const { setModalState, selectedDeparments = [], defaultDepartments = [] } = this.props
        const { selectedTopics = [] } = this.props

        const newSelectedTopicsState = selectedTopics.length ? [...selectedTopics, topic] : [topic]
        const allDepartments = uniqBy([...selectedDeparments, ...defaultDepartments], 'id')

        // If an extra department is included in this topic, you can remove it from there
        const allSelectedDepartments = allDepartments.filter(department => {
            const topicHasDepartment = topic.departments?.find(topicDepartment => topicDepartment.id === department.id)

            return !topicHasDepartment
        })

        setModalState({
            selectedTopics: newSelectedTopicsState,
            extraDepartments: allSelectedDepartments,
        })
    }

    private handleRemoveTopic(topic: Topic) {
        const { setModalState } = this.props
        const { selectedTopics = [] } = this.props

        const filteredTopics = selectedTopics.filter(top => top.id !== topic.id)

        setModalState({
            selectedTopics: [...filteredTopics],
        })
    }
}
