import React from 'react'
import { Mutation, MutationFn } from 'react-apollo'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import {
    CastVoteDocument,
    CastVoteMutation,
    CastVoteMutationVariables,
    GeneratedSummaryStatusEnum,
    GeneratedSummaryType,
    GeneratedSummaryUserVoteEnum,
} from '~/generated/graphql'
import { AiSummaryVoteButton } from './AiSummaryVoteButton'

interface Props {
    generatedSummary: Pick<GeneratedSummaryType, 'id' | 'userVote' | 'status'>
}

interface State {
    showSuccessMessage: boolean
}

type MutationFN = MutationFn<CastVoteMutation, CastVoteMutationVariables>

export class AiSummaryVoteContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        showSuccessMessage: false,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.AiSummary.AiSummaryVoteContainer)

    public render() {
        const { status } = this.props.generatedSummary

        if (status !== GeneratedSummaryStatusEnum.done) {
            return null
        }

        return (
            <Row>
                {this.renderLabel()}
                <Mutation<CastVoteMutation, CastVoteMutationVariables> mutation={CastVoteDocument}>
                    {(mutate, { loading }) => this.renderButtons(mutate, loading)}
                </Mutation>
            </Row>
        )
    }

    private renderLabel() {
        const { userVote } = this.props.generatedSummary

        let label: string = ''

        if (this.state.showSuccessMessage) {
            label = this.loc(t => t.successMessage)
        } else if (!userVote?.vote) {
            label = this.loc(t => t.initialLabel)
        }

        if (!label) {
            return null
        }

        return <Paragraph>{label}</Paragraph>
    }

    private renderButtons(mutate: MutationFN, loading: boolean) {
        const { userVote } = this.props.generatedSummary
        const disabled = !!this.state.showSuccessMessage

        if (userVote?.vote) {
            return (
                <AiSummaryVoteButton
                    loading={loading}
                    type={userVote.vote}
                    onClick={() => this.handleSubmit(mutate)}
                    active={true}
                    disabled={disabled}
                />
            )
        }

        return (
            <Row>
                <AiSummaryVoteButton
                    loading={loading}
                    type={GeneratedSummaryUserVoteEnum.upvote}
                    onClick={() => this.handleSubmit(mutate, GeneratedSummaryUserVoteEnum.upvote)}
                    disabled={disabled}
                />
                <AiSummaryVoteButton
                    loading={loading}
                    type={GeneratedSummaryUserVoteEnum.downvote}
                    onClick={() => this.handleSubmit(mutate, GeneratedSummaryUserVoteEnum.downvote)}
                    disabled={disabled}
                />
            </Row>
        )
    }

    private async handleSubmit(mutate: MutationFN, vote: GeneratedSummaryUserVoteEnum | null = null) {
        const { id } = this.props.generatedSummary

        const res = await mutate({ variables: { generatedSummaryId: id, vote } })

        if (res && res.data?.castGeneratedSummaryVote.vote) {
            this.setState({ showSuccessMessage: true }, () =>
                setTimeout(() => this.setState({ showSuccessMessage: false }), 3 * 1000)
            )
        }
    }
}
