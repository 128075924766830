import 'react-calendar/dist/Calendar.css'
import './DatePicker.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import Calendar from 'react-calendar'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { localize } from '~/bootstrap'
import isToday from 'date-fns/is_today'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Range } from 'react-calendar/dist/cjs/shared/types'

interface Props {
    className?: ClassValue
    selectRange?: boolean
    name: string
    minDate?: Date
    maxDate?: Date
    defaultValue?: Date | [Date, Date] | null
    placeholder?: string
    clearable?: boolean
    placeholderRangeFrom?: string
    placeholderRangeTo?: string
    onChange?: (selectedDate: Value, name: string) => void
    repeatable?: boolean
    onRepeat?: (repeats: boolean) => void
    defaultRepeatableCheckboxValue?: boolean
    disabled?: boolean
    closeOnSelect?: boolean
}

interface State {
    isShown: boolean
    selectedDate: Value
}

type Value = Date | Range<Date | null> | null

export class DatePicker extends React.PureComponent<Props, State> {
    public state: State = {
        isShown: false,
        selectedDate: this.props.defaultValue ? this.props.defaultValue : null,
    }

    private loc = localize.namespaceTranslate(t => t.Core.DatePicker)
    private bem = new BEM('DatePicker', () => ({
        'has-today-value': this.isTodaySelected(),
        'is-disabled': this.props.disabled,
    }))

    private datePickerRef = React.createRef<HTMLDivElement>()

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.isShown !== this.state.isShown && this.state.isShown) {
            this.datePickerRef.current?.focus()
        }
    }

    public render() {
        const { className, selectRange, minDate, maxDate, clearable } = this.props
        const { isShown, selectedDate } = this.state
        const value = selectedDate ? selectedDate : !selectRange ? new Date() : undefined

        return (
            <div
                className={this.bem.getClassName(className)}
                ref={this.datePickerRef}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onBlur={this.handleOnBlur}
            >
                {this.renderInput()}
                {isShown && (
                    <div className={this.bem.getElement('calendar-container')}>
                        <Calendar
                            value={value}
                            selectRange={selectRange}
                            minDate={minDate}
                            maxDate={maxDate}
                            locale={localize.getCurrentLocale()}
                            className={this.bem.getElement('calendar')}
                            onChange={this.handleOnCalendarChange}
                        />
                        {clearable && (
                            <Button
                                type={ButtonType.secondary}
                                onClick={() => {
                                    this.handleOnCalendarChange(null)
                                    this.closeCalendar()
                                }}
                                className={this.bem.getElement('clear-button')}
                            >
                                {this.loc(t => t.clearButton)}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        )
    }

    private renderInput() {
        const {
            name,
            selectRange,
            placeholder,
            placeholderRangeFrom,
            placeholderRangeTo,
            onRepeat,
            repeatable,
            disabled,
            defaultRepeatableCheckboxValue,
        } = this.props

        if (!selectRange) {
            return (
                <Input
                    icon={IconType.calendar}
                    name={name}
                    onFocus={this.handleOnFocus}
                    placeholder={placeholder ? placeholder : this.loc(t => t.placeholder)}
                    className={this.bem.getElement('input')}
                    value={this.getValueForInput()}
                    disabled={disabled}
                    suffix={
                        repeatable && onRepeat ? (
                            <Checkbox
                                defaultChecked={defaultRepeatableCheckboxValue}
                                name={'date-picker-repeats'}
                                label={this.loc(t => t.repeat)}
                                className={this.bem.getElement('repeatable')}
                                onChange={checked => onRepeat(checked)}
                                disabled={disabled}
                            />
                        ) : undefined
                    }
                    onBlur={value => {
                        this.setValue(value)
                    }}
                />
            )
        }

        return (
            <Row>
                <Input
                    icon={IconType.calendar}
                    name={name}
                    onFocus={this.handleOnFocus}
                    placeholder={placeholderRangeFrom ? placeholderRangeFrom : this.loc(t => t.placeholderFrom)}
                    className={this.bem.getElement('input')}
                    value={this.getValueForInput(0)}
                    onBlur={value => {
                        this.setValue(value, 0)
                    }}
                />
                {/*tslint:disable-next-line:jsx-use-translation-function*/}
                <div>-</div>
                <Input
                    icon={IconType.calendar}
                    name={name}
                    onFocus={this.handleOnFocus}
                    placeholder={placeholderRangeTo ? placeholderRangeTo : this.loc(t => t.placeholderTo)}
                    className={this.bem.getElement('input')}
                    value={this.getValueForInput(1)}
                    onBlur={value => {
                        this.setValue(value, 1)
                    }}
                />
            </Row>
        )
    }

    private handleOnFocus = () => {
        this.setState({
            isShown: true,
        })
    }

    private getValueForInput(index?: number) {
        const { selectedDate } = this.state

        if (!selectedDate) {
            return undefined
        }

        if (Array.isArray(selectedDate)) {
            if (index === undefined || !selectedDate[index]) {
                return undefined
            }
            return localize.dateFormat(selectedDate[index]!)
        }

        return localize.dateFormat(selectedDate)
    }

    private handleOnCalendarChange = (selectedDate: Value) => {
        const { onChange, closeOnSelect } = this.props

        this.setState({ selectedDate, isShown: !closeOnSelect }, () => {
            if (onChange) {
                onChange(this.state.selectedDate, this.props.name)
            }
        })
    }

    private setValue(value: string | null, index?: number) {
        if (!value) {
            this.setState({ selectedDate: null })
            if (this.props.onChange) {
                this.props.onChange(null, this.props.name)
            }
            return
        }

        // 03-04-2020 -> 2020-04-03, otherwise its US format
        const reversed = value.split('-').reverse().join('-')

        const date = new Date(reversed)

        if (date && date.toString() !== 'Invalid Date') {
            if (index === undefined) {
                this.setState({
                    selectedDate: date,
                })
                return
            }

            const dates: Value = this.state.selectedDate ? this.state.selectedDate : [null, null]
            dates[index] = date

            this.setState({
                selectedDate: dates,
            })
        }
    }

    private handleOnBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        event.stopPropagation()
        event.preventDefault()

        if (!this.datePickerRef.current) {
            return
        }

        const { relatedTarget } = event

        // We don't want to close the date picker if a click is on the input element,
        // the click is inside the calendar container or calendar itself.
        if (
            relatedTarget?.closest(`.react-calendar__tile`) ||
            relatedTarget?.closest(`.${this.bem.getElement('calendar-container')}`) ||
            relatedTarget?.closest(`.${this.bem.getElement('input')}`)
        ) {
            // Only prevent closing if the click was inside the current component.
            // This is done so clicking another datepicker component the current one will close.
            if (this.datePickerRef.current.contains(relatedTarget) || relatedTarget.closest(`.react-calendar__tile`)) {
                return
            }
        }

        this.closeCalendar()
    }

    private closeCalendar() {
        this.setState({
            isShown: false,
        })
    }

    private isTodaySelected() {
        const { selectedDate } = this.state

        if (!selectedDate) {
            return false
        }

        if (Array.isArray(selectedDate)) {
            return !!selectedDate.find(date => date && isToday(date))
        }

        return isToday(selectedDate)
    }
}
