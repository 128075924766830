import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringAgendaView } from './MonitoringAgenda/MonitoringAgendaView'
import { MonitoringReportsView } from './MonitoringRapports/MonitoringReportsView'
import { newRoutes } from '~/views/newRoutes'
import { Routes, Route, Navigate } from 'react-router-dom'
import { routes } from '~/views/routes'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class MonitoringViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route
                    path="*"
                    element={<Navigate replace to={routes.customer(this.context.customer.slug).monitoring.agenda} />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).monitoring.agenda}
                    element={<MonitoringAgendaView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).monitoring.reports.index}
                    element={<MonitoringReportsView />}
                />
            </Routes>
        )
    }
}

export const MonitoringView = withRouter(MonitoringViewComponent)
