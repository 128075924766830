import './ColorSchemeCard.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    colorName: string
    colorVariable?: string
    colorHex: string
    invertTextColor?: boolean
    mainColor?: boolean
}

interface State {}

export class ColorSchemeCard extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ColorSchemeCard', () => ({
        'has-light-background': this.props.invertTextColor,
        'main-color': this.props.mainColor,
    }))

    public render() {
        const { className, colorName, colorVariable, colorHex } = this.props

        return (
            <div className={this.bem.getClassName(className)} style={{ background: colorHex }}>
                <div className={this.bem.getElement('color-name')}>{colorName}</div>
                <div className={this.bem.getElement('meta-data')}>
                    <div className={this.bem.getElement('color-variable')}>{colorVariable}</div>
                    <div className={this.bem.getElement('color-hex')}>{colorHex}</div>
                </div>
            </div>
        )
    }
}
