import React from 'react'
import { FileInput } from '~/components/Core/DataEntry/FileInput/FileInput'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Column } from '~/components/Core/Layout/Column'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    defaultValue?: string
    name: string
    onChange?: (value: string | null, name: string) => void
    className?: ClassValue
}

interface State {
    inputValue?: string
    error?: string
}

export class SAMLCertificateInput extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        inputValue: this.props.defaultValue,
        error: undefined,
    }

    private bem = new BEM('SAMLCertificateInput')

    public componentDidUpdate() {
        this.triggerOnChange()
    }

    public render() {
        const { name, className } = this.props
        const { inputValue, error } = this.state

        return (
            <Column smallSpacing={true} className={this.bem.getClassName(className)}>
                <FileInput
                    name="pemFile"
                    hideMaxFileSizeMessage={true}
                    onChange={this.handleOnFileChange}
                    onClear={this.handleOnFileClear}
                />
                {error && <ErrorMessage message={error} />}
                <Input
                    type={'textarea'}
                    name={name}
                    value={inputValue}
                    onChange={this.handleOnChange}
                    placeholder={`-----BEGIN CERTIFICATE-----
MIIDXTCCAkWgAwIBAgIJALmVVuDWu4NY...
-----END CERTIFICATE-----`}
                />
            </Column>
        )
    }

    private handleOnFileChange = (file: File) => {
        if (file) {
            const reader = new FileReader()

            reader.onload = event => {
                const reader = event.target

                if (!reader || reader.readyState !== 2) {
                    this.setState({
                        inputValue: undefined,
                        error: 'Failed to get the certificate from the file. Please try again or manually copy the text in the box below.',
                    })
                    return
                }

                const certString = typeof reader.result === 'string' ? reader.result.trim() : undefined

                if (!certString) {
                    this.setState({
                        inputValue: undefined,
                        error: 'Failed to get the certificate from the file. Please try again or manually copy the text in the box below.',
                    })
                    return
                }

                this.setState({
                    error: undefined,
                    inputValue: certString,
                })
            }

            reader.readAsText(file)
        }
    }

    private handleOnFileClear = () => {
        this.setState({
            error: undefined,
            inputValue: undefined,
        })
    }

    private handleOnChange = (value: string) => {
        this.setState({
            error: undefined,
            inputValue: value,
        })
    }

    private triggerOnChange = () => {
        const { onChange, name } = this.props
        const value = this.state.inputValue

        if (onChange) {
            onChange(value ? value : null, name)
        }
    }
}
