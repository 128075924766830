import './Tombstone.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    width?: string | number
    height?: string | number
    className?: ClassValue
}

export class Tombstone extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('Tombstone')

    public render() {
        const { className, width, height, children } = this.props

        return (
            <div className={this.bem.getClassName(className)} style={{ width, height }}>
                {children}
            </div>
        )
    }
}
