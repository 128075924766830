import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize, notification, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { SelectionBox } from '~/components/Core/Feedback/SelectionBox/SelectionBox'
import { ArchiveMonitoringReports, ArchiveMonitoringReportsMutationFN } from '../ArchiveMonitoringReports'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    monitoringReportIds: number[]
    onClearSelection: () => void
    onSubmit: () => void
}

interface State {
    archivedReason: string | null
}

export class BulkMonitoringReportsActionContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringReportsArchiveModal)

    public render() {
        const { monitoringReportIds, onClearSelection } = this.props
        const doesNotHavePermission = !permissions.canEditMonitoringReport()

        if (doesNotHavePermission) {
            return null
        }

        return (
            <ArchiveMonitoringReports>
                {(mutate, { loading }) => (
                    <ConfirmModal
                        title={this.loc(t => t.monitoringReportModalTitle)}
                        confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                        loading={loading}
                        renderContent={() => (
                            <>
                                <ErrorMessage path="archiveMonitoringReports" />
                                <Column>
                                    <Paragraph>
                                        {this.loc(t => t.monitoringReportArchiveMessagePart1)}
                                        <strong>{monitoringReportIds.length}</strong>
                                        {this.loc(t => t.monitoringReportArchiveMultipleMessagePart2)}
                                    </Paragraph>
                                    <Field label={this.loc(t => t.monitoringReportReasonInputLabel)} compact={true}>
                                        <Input
                                            type="textarea"
                                            placeholder={this.loc(t => t.monitoringReportReasonInputPlaceholder)}
                                            name="reason"
                                            onChange={archivedReason => this.setState({ archivedReason })}
                                        />
                                    </Field>
                                </Column>
                            </>
                        )}
                        onConfirm={close => this.handleSubmit(close, mutate)}
                    >
                        {openModal => (
                            <SelectionBox
                                selectedAmount={monitoringReportIds.length}
                                onClose={onClearSelection}
                                buttons={[
                                    {
                                        icon: IconType.archive,
                                        label: localize.translate(t => t.Generic.archive),
                                        onClick: openModal,
                                    },
                                ]}
                                isOpen={monitoringReportIds.length > 0}
                            />
                        )}
                    </ConfirmModal>
                )}
            </ArchiveMonitoringReports>
        )
    }

    private async handleSubmit(close: () => void, mutate: ArchiveMonitoringReportsMutationFN) {
        const { archivedReason } = this.state
        const { monitoringReportIds, onSubmit } = this.props

        const response = await mutate({
            variables: {
                ids: monitoringReportIds,
                reason: archivedReason,
            },
        })

        if (response && response.data && response.data.archiveMonitoringReports) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            close()
            onSubmit()
        }
    }
}
