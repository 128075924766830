import './TranslationInProgressLabel.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Row } from '../../Layout/Row'
import { Paragraph } from '../../Typography/Paragraph'
import { InlineTextIcon } from '../../Icon/InlineTextIcon/InlineTextIcon'
import { IconType } from '../../Icon/IconType'

interface Props {}

export class TranslationInProgressLabel extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('TranslationInProgressLabel')

    public render() {
        return (
            <Row className={this.bem.getClassName()}>
                {/* tslint:disable-next-line:jsx-use-translation-function */}
                <Paragraph className={this.bem.getElement('text')}>Translating content with</Paragraph>
                <InlineTextIcon type={IconType.deeplLogo} className={this.bem.getElement('logo')} />
            </Row>
        )
    }
}
