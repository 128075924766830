import './TaskControlRowTombstone.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tombstone } from '~/components/Core/Feedback/Tombstone/Tombstone'
import { TombstoneInner } from '~/components/Core/Feedback/Tombstone/TombstoneInner'

interface Props {
    className?: ClassValue
    isEven: boolean
}

export class TaskControlRowTombstone extends React.PureComponent<React.PropsWithChildren<Props>, {}> {
    private bem = new BEM('TaskControlRowTombstone', () => ({
        even: this.props.isEven,
    }))

    public render() {
        const { className, isEven } = this.props

        return (
            <Tombstone width={'100%'} className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('name')}>
                    <TombstoneInner
                        isRound={true}
                        width={16}
                        height={16}
                        className={this.bem.getElement('name-icon')}
                    />
                    <TombstoneInner width={132} height={11} />
                </div>
                <div className={this.bem.getElement('tags')}>
                    <TombstoneInner width={44} height={24} />
                </div>
                <div className={this.bem.getElement('linked-item')}>
                    <TombstoneInner
                        isRound={true}
                        width={12}
                        height={12}
                        className={this.bem.getElement('linked-item-icon')}
                    />
                    <TombstoneInner width={132} height={11} />
                </div>
                <div className={this.bem.getElement('employees')}>
                    <TombstoneInner width={22} height={22} isRound={true} className={this.bem.getElement('employee')} />
                    {!isEven && (
                        <TombstoneInner
                            width={22}
                            height={22}
                            isRound={true}
                            className={this.bem.getElement('employee')}
                        />
                    )}
                </div>
                <div className={this.bem.getElement('created-at')}>
                    <TombstoneInner width={108} height={11} />
                </div>
                <div className={this.bem.getElement('priority')}>
                    <TombstoneInner
                        isRound={true}
                        width={12}
                        height={12}
                        className={this.bem.getElement('priority-element')}
                    />
                </div>
            </Tombstone>
        )
    }
}
