import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'
import {
    RequestSamlRedirectUrlForEmailDocument,
    RequestSamlRedirectUrlForEmailMutation,
    RequestSamlRedirectUrlForEmailMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '../Mutation'

interface MutationProps {
    children?: (mutateFn: RequestSAMLRedirectURLForEmailMutationFN, options: MutationResult) => React.ReactNode
}

export type RequestSAMLRedirectURLForEmailMutationFN = MutationFn<
    RequestSamlRedirectUrlForEmailMutation,
    RequestSamlRedirectUrlForEmailMutationVariables
>

export const RequestSAMLRedirectURLForEmail: React.FunctionComponent<MutationProps> = ({ children }) => (
    <GQLMutation<RequestSamlRedirectUrlForEmailMutation, RequestSamlRedirectUrlForEmailMutationVariables>
        mutation={RequestSamlRedirectUrlForEmailDocument}
    >
        {(mutate, options) => (
            <ErrorProvider error={options.error}>{children && children(mutate, options)}</ErrorProvider>
        )}
    </GQLMutation>
)
