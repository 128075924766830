import './CheckboxList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Checkbox } from './Checkbox'

interface Props {
    className?: ClassValue
    disabled?: boolean
    items: CheckboxListItem[]
}

interface CheckboxListItem {
    label: React.ReactNode
    name: string
    defaultChecked?: boolean
    onChange?: (checked: boolean, name: string) => void
    invalid?: boolean
}

interface State {}

export class CheckboxList extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('CheckboxList', () => ({
        disabled: this.props.disabled,
    }))

    public render() {
        const { items, className, disabled } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {items.map((item, index) => (
                    <Checkbox
                        disabled={disabled}
                        key={`${index}-checkboxlist-item`}
                        className={this.bem.getElement('checkbox', () => ({ invalid: item.invalid }))}
                        name={item.name}
                        defaultChecked={item.defaultChecked}
                        onChange={(checked, name) => this.handleOnChange(checked, name, item)}
                        label={item.label}
                    />
                ))}
            </div>
        )
    }

    private handleOnChange = (checked: boolean, name: string, item: CheckboxListItem) => {
        const { disabled } = this.props

        if (disabled || !item.onChange) {
            return
        }

        return item.onChange(checked, name)
    }
}
