import './Footer.scss'
import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { localize } from '~/bootstrap'
import { NavLink } from 'react-router-dom'

interface Props {
    className?: ClassValue
}

interface State {}

export class Footer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Footer')

    private loc = localize.namespaceTranslate(t => t.Footer.Attributes)

    public render() {
        const { className } = this.props

        return (
            <footer className={this.bem.getClassName(className)}>
                <NavLink to={routes.index} className={this.bem.getElement('link')}>
                    <div className={this.bem.getElement('logo-container')}>{this.loc(t => t.ruler)}</div>
                </NavLink>
                <nav>
                    <ul className={this.bem.getElement('link-container')}>
                        <li className={this.bem.getElement('link-list')}>
                            <a
                                href={'https://www.ruler.nl/app/uploads/2019/03/CHAR030_Ruler_PrivacyStatement.pdf'}
                                target="_blank"
                                className={this.bem.getElement('link')}
                            >
                                {this.loc(t => t.privacy)}
                            </a>
                        </li>
                        <li className={this.bem.getElement('link-list')}>
                            <a
                                href={'https://www.ruler.nl/app/uploads/2019/03/CHAR030_Ruler_AlgemeneVoorwaarden.pdf'}
                                target="_blank"
                                className={this.bem.getElement('link')}
                            >
                                {this.loc(t => t.termsAndConditions)}
                            </a>
                        </li>
                    </ul>
                </nav>
            </footer>
        )
    }
}
