import './TopicDetailViewAssessmentStatus.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import {
    DepartmentTopicDataMetadataType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
    TopicType,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { TopicAssessmentIcon } from './TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { TopicDesignAndEffectivenessSummaryTooltip } from './TopicDesignAndEffectiveness/TopicDesignAndEffectivenessSummaryTooltip'

interface Props {
    topic: TopicType
    onChange: () => void
    onAssessmentClick: () => void
}

export class TopicDetailViewAssessmentStatus extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicDetailViewAssessmentStatus')
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailView)
    private topicDnELoc = localize.namespaceTranslate(
        t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable
    )

    public render() {
        if (!permissions.canViewTopicDnE()) {
            return null
        }

        const { nonApplicable } = this.props.topic

        return <div className={this.bem.getClassName()}>{!nonApplicable?.id && this.renderTopicAssessmentData()}</div>
    }

    private renderTopicAssessmentData() {
        const { departmentTopicData } = this.props.topic

        // used incase either design or effectiveness metadata is null
        const fallBackDate =
            departmentTopicData?.topicAssessmentDesignMetadata?.lastUpdatedAt ||
            departmentTopicData?.topicAssessmentEffectivenessMetadata?.lastUpdatedAt

        return (
            <Row noSpacing={true} className={this.bem.getElement('assessments-container')}>
                {this.renderAssessmentData(
                    TopicAssessmentDesignOrEffectiveNessType.design,
                    departmentTopicData?.topicAssessmentDesignMetadata,
                    fallBackDate
                )}
                {this.renderAssessmentData(
                    TopicAssessmentDesignOrEffectiveNessType.effectiveness,
                    departmentTopicData?.topicAssessmentEffectivenessMetadata,
                    fallBackDate
                )}
            </Row>
        )
    }

    private renderAssessmentData(
        type: TopicAssessmentDesignOrEffectiveNessType,
        assessmentData?: DepartmentTopicDataMetadataType | null,
        fallBackDate?: Date
    ) {
        const { onAssessmentClick } = this.props
        const date = assessmentData?.lastUpdatedAt || fallBackDate
        const formattedDate = date
            ? localize.dateFormat(new Date(date), { noWeekday: true, shortYear: true })
            : undefined
        const grade = assessmentData?.grade || TopicAssessmentGradeType.assessmentNotGiven

        const data = assessmentData
            ? assessmentData
            : ({ lastUpdatedAt: fallBackDate } as DepartmentTopicDataMetadataType)

        const title = this.topicDnELoc(t =>
            type === TopicAssessmentDesignOrEffectiveNessType.design ? t.design : t.effectiveness
        )
        const noDataLabel = this.loc(t =>
            type === TopicAssessmentDesignOrEffectiveNessType.design ? t.noDesign : t.noEffectiveness
        )

        return (
            <TopicDesignAndEffectivenessSummaryTooltip data={data} title={title}>
                <Button
                    onClick={onAssessmentClick}
                    type={ButtonType.noStyling}
                    className={this.bem.getElement('assessment-button')}
                >
                    <Row className={this.bem.getElement('assessment')}>
                        <TopicAssessmentIcon status={grade} />
                        <Column extraSmallSpacing={true} className={this.bem.getElement('info-column')}>
                            <Paragraph bold={true}>{title}</Paragraph>
                            <Paragraph subtle={true}>{formattedDate || noDataLabel}</Paragraph>
                        </Column>
                    </Row>
                </Button>
            </TopicDesignAndEffectivenessSummaryTooltip>
        )
    }
}
