import './MultiDepthExpandableTableHeader.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface HeaderItem {
    id: number
    render: () => React.ReactNode
}

interface Props {
    headerItems: HeaderItem[]
    className?: ClassValue
}

export class MultiDepthExpandableTableHeader extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('MultiDepthExpandableTableHeader')

    public render() {
        const { headerItems, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {headerItems.map(item => (
                    <div key={item.id}>{item.render()}</div>
                ))}
            </div>
        )
    }
}
