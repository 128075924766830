import './NewsSourcesGrid.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isTwoGrid?: boolean
}

interface State {}

export class NewsSourcesGrid extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('NewsSourcesGrid')

    public render() {
        const { children, className, isTwoGrid } = this.props

        const gridStyle = {
            gridTemplateColumns: isTwoGrid ? '60% 40%' : undefined,
        }

        return (
            <div className={this.bem.getClassName(className)} style={gridStyle}>
                {children}
            </div>
        )
    }
}
