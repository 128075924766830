import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'

const UNFOLLOW_RADAR_ITEM_MUTATION = gql`
    mutation unfollowRadarItem($radarItemId: Int!, $departmentId: Int!) {
        unfollowRadarItem(radarItemId: $radarItemId, departmentId: $departmentId) {
            id
            following(departmentId: $departmentId)
        }
    }
`

export interface UnfollowRadarItemMutationResponse {
    unfollowRadarItem: { id: string }
}

export interface UnfollowRadarItemVariables {
    radarItemId: number
    departmentId: number
}

interface Props {
    children: (
        mutation: MutationFn<UnfollowRadarItemMutationResponse, UnfollowRadarItemVariables>,
        options: MutationResult
    ) => JSX.Element
    radarItemId: number
    departmentId: number
}

export const UnfollowRadarItemMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<UnfollowRadarItemMutationResponse, UnfollowRadarItemVariables> mutation={UNFOLLOW_RADAR_ITEM_MUTATION}>
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
