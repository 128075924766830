import React from 'react'
import { localize, notification } from '~/bootstrap'
import { DepartmentType, NewsItemType } from '~/generated/graphql'
import { MultiStepModal, ModalStep } from '~/components/Core/Feedback/Modal/MultiStepModal'
import { Topic } from '~/components/Domain/Topic/TopicsQuery'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { RelevantModalOverview } from '~/views/Customer/Signaling/RelevantModal/RelevantModalOverview'
import { RelevantModalAddTopic } from '~/views/Customer/Signaling/RelevantModal/RelevantModalAddTopic'
import { RelevantModalAddDepartment } from '~/views/Customer/Signaling/RelevantModal/RelevantModalAddDepartment'
import { EditPublishedCustomerNews, EditPublishedCustomerNewsMutationFN } from './EditPublishedCustomerNews'

interface Props {
    newsItem: NewsItemType
    closeModal: () => void
    onMarkRelevant?: (variables?: any) => void
    defaultTopics?: Topic[]
    defaultDepartments?: DepartmentType[]
}

interface State {
    selectedTopics: Topic[]
    extraDepartments: DepartmentType[]
    note: string
}

export class EditPublishedCustomerNewsModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        selectedTopics: [],
        extraDepartments: [],
        note: this.props.newsItem.internalAlert?.note || '',
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModal)

    public render() {
        const { closeModal } = this.props

        return (
            <EditPublishedCustomerNews>
                {(mutate, { loading }) => {
                    const steps = this.getModalSteps(mutate, loading)

                    return <MultiStepModal hideCloseButton requestClose={closeModal} steps={steps} />
                }}
            </EditPublishedCustomerNews>
        )
    }

    private getModalSteps(mutate: EditPublishedCustomerNewsMutationFN, loading: boolean): ModalStep[] {
        const { newsItem, defaultDepartments, defaultTopics } = this.props
        const { selectedTopics, extraDepartments, note } = this.state

        return [
            {
                name: 'overview',
                title: newsItem.title,
                content: (setActiveIndex: (index: number) => void) => (
                    <RelevantModalOverview
                        defaultTopics={defaultTopics}
                        defaultDepartments={defaultDepartments}
                        topics={selectedTopics}
                        departments={extraDepartments}
                        newsItem={newsItem}
                        setModalState={this.setRelevantModalState}
                        setActiveIndex={setActiveIndex}
                        selectedTopics={selectedTopics}
                        defaultNote={note}
                    />
                ),
                actions: () => requestClose => this.renderModalActions(mutate, loading, requestClose),
            },
            {
                name: 'addTopic',
                title: this.loc(t => t.addTopicTitle),
                content: () => (
                    <RelevantModalAddTopic
                        defaultTopics={defaultTopics}
                        defaultDepartments={defaultDepartments}
                        selectedTopics={selectedTopics}
                        selectedDeparments={extraDepartments}
                        setModalState={this.setRelevantModalState}
                    />
                ),
            },
            {
                name: 'addDepartment',
                title: this.loc(t => t.addDepartmentTitle),
                content: () => (
                    <RelevantModalAddDepartment
                        defaultTopics={defaultTopics}
                        defaultDepartments={defaultDepartments}
                        selectedTopics={selectedTopics}
                        selectedDeparments={extraDepartments}
                        setModalState={this.setRelevantModalState}
                    />
                ),
            },
        ]
    }

    private renderModalActions(
        mutate: EditPublishedCustomerNewsMutationFN,
        loading: boolean,
        requestClose?: () => void
    ) {
        return (
            <Row alignRight={true}>
                <Button type={ButtonType.tertiary} onClick={requestClose} disabled={loading}>
                    {localize.translate(t => t.Core.Modal.cancel)}
                </Button>
                <Button
                    onClick={() => this.handleLinkRelevant(mutate)}
                    disabled={this.shouldSubmitBeDisabled()}
                    loading={loading}
                    icon={IconType.paperPlane}
                >
                    {this.loc(t => t.submit)}
                </Button>
            </Row>
        )
    }

    private shouldSubmitBeDisabled() {
        const { extraDepartments, selectedTopics, note } = this.state
        const defaultNote = this.props.newsItem.internalAlert?.note

        if (!extraDepartments.length && !selectedTopics.length && note === defaultNote) {
            return true
        }

        return false
    }

    private setRelevantModalState = (
        newModalContentState: State,
        callBack?: (nextModalContentState: State) => void
    ) => {
        const newState = { ...this.state, ...newModalContentState }
        this.setState(newState, () => callBack && callBack(this.state))
    }

    private handleLinkRelevant = async (mutate: EditPublishedCustomerNewsMutationFN) => {
        const { closeModal, newsItem, onMarkRelevant, defaultDepartments, defaultTopics } = this.props
        const { selectedTopics, extraDepartments, note } = this.state

        const topics = selectedTopics.filter(t => !defaultTopics?.some(dt => dt.id === t.id))
        const departments = extraDepartments.filter(d => !defaultDepartments?.some(dd => dd.id === d.id))

        const response = await mutate({
            variables: {
                id: newsItem.internalAlert!.id,
                extraTopicIds: topics.map(t => t.id),
                extraDepartmentIds: departments.map(d => d.id),
                note,
            },
        })

        if (response && response.data?.editInternalAlert) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (onMarkRelevant) {
                onMarkRelevant()
            }

            if (closeModal) {
                closeModal()
            }
        }
    }
}
