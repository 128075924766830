import './PageActions.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    primaryComponent: JSX.Element
    secondaryComponent?: JSX.Element
}

export const PageActions: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { primaryComponent, secondaryComponent, className } = props

    const bem = new BEM<Props>('PageActions', () => ({
        'has-secondary': !!props.secondaryComponent,
    }))

    return (
        <div className={bem.getClassName(className)}>
            <div className={bem.getElement('primary')}>{primaryComponent}</div>
            {secondaryComponent && <div className={bem.getElement('secondary')}>{secondaryComponent}</div>}
        </div>
    )
}
