import './StyleguideComponentTable.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    loading?: boolean
    headers: HeaderOptions[]
    data: RowData[]
}

interface HeaderOptions {
    field: string
    label: string | null | JSX.Element
}

interface RowData {
    id: string | number
    label: string
    rows: Field
}

interface Field {
    [fieldName: string]: string | number | JSX.Element | null | any
}

interface State {}

export class StyleguideComponentTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('StyleguideComponentTable')

    public render() {
        return this.renderTable()
    }

    private renderTable() {
        const { loading, className } = this.props

        if (loading) {
            return (
                <div className={this.bem.getElement('loading')}>
                    <Spinner delayed={true} />
                </div>
            )
        }

        return (
            <table className={this.bem.getClassName(className)}>
                <thead className={this.bem.getElement('head')}>
                    <tr className={this.bem.getElement('row')}>{this.renderHeaders()}</tr>
                </thead>
                <tbody className={this.bem.getElement('body')}>{this.renderRows()}</tbody>
            </table>
        )
    }

    private renderHeaders() {
        const { headers } = this.props

        return (
            <React.Fragment>
                <th className={this.bem.getElement('header')} />
                {headers.map(header => {
                    return (
                        <th className={this.bem.getElement('header')} key={`field-${header.field}`}>
                            <span>{header.label}</span>
                        </th>
                    )
                })}
            </React.Fragment>
        )
    }

    private renderRows() {
        const { data, loading, headers } = this.props

        if (!data && !loading) {
            return (
                <tr className={this.bem.getElement('row')}>
                    <td className={this.bem.getElement('cell')} colSpan={headers.length}>
                        {localize.translate(t => t.Core.Table.noDataFound)}
                    </td>
                </tr>
            )
        }

        if (!data.length && !loading) {
            return (
                <tr className={this.bem.getElement('row')}>
                    <td className={this.bem.getElement('cell')} colSpan={headers.length}>
                        {localize.translate(t => t.Core.Table.noDataFound)}
                    </td>
                </tr>
            )
        }

        return data.map((rowData, rowIndex) => {
            return (
                <React.Fragment key={`${rowIndex}-${rowData}`}>
                    <tr className={this.bem.getElement('row')}>
                        <th className={this.bem.getElement('header')}>{rowData.label}</th>
                        {Object.keys(rowData.rows)
                            .filter(field => headers.find(h => h.field === field))
                            .map((rowField, index) => {
                                return (
                                    <td className={this.bem.getElement('cell')} key={`${rowData.id}-${index}`}>
                                        {rowData.rows[rowField]}
                                    </td>
                                )
                            })}
                    </tr>
                </React.Fragment>
            )
        })
    }
}
