import './ViewTaskModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { TaskMetaData } from './TaskMetaData'
import { UserProfileList } from '~/components/Core/DataDisplay/UserProfileList/UserProfileList'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { RevisionDateFormat } from '../Compliance/Controls/RevisionDateFormat'
import { TaskDetailFields } from '~/graphql/types/Task'
import { localize, permissions } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { LinkedTaskCard } from './LinkedTaskCard'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { EditTaskModalContainer } from './EditTaskModalContainer'
import { TaskCompleteModal } from './Motivation/TaskCompleteModal'
import { TaskReopenModal } from './Motivation/TaskReopenModal'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { PriorityIndicator } from './PriorityIcon/PriorityIndicator'
import { TaskTags } from './TaskTags/TaskTags'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { EmployeePermissionEnum } from '~/generated/graphql'
import { ReviewSubTasksTable } from './ReviewSubTasksTable'
import { Column } from '~/components/Core/Layout/Column'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { ViewTaskModalSubTasksAndLogs } from './SubTasks/ViewTaskModalSubTasksAndLogs'
import { ViewTaskModalContainer } from './ViewTaskModalContainer'
import { ArchiveTaskButtonContainer } from './ArchiveTaskButtonContainer'
import { LinkedInboxItemsTable } from '../ControlViews/InboxControl/LinkedInboxItemsTable'

interface ModalLabelsAndClassNames {
    completeButtonIcon: IconType
    reopenButtonLabel: string
    completeButtonLabel: string
    buttonIconElement: string
}

interface Props {
    className?: ClassValue
    task?: TaskDetailFields
    loading?: boolean
    onCompleteOrReopen?: () => void
    onChangeTask?: () => void
    onArchiveTask?: () => void
    requestClose: () => void
    showDepartment?: boolean
}

interface State {}

export class ViewTaskModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ViewTaskModal')
    private loc = localize.namespaceTranslate(t => t.Customer.Task.viewTaskModal)

    public render() {
        const { className, requestClose, onChangeTask, onCompleteOrReopen, task } = this.props

        const assignedEmployeeIds = task ? task.employees.map(({ id }) => id) : []
        const hasPermission = permissions.canEditTasks(this.context.activeDepartmentId, assignedEmployeeIds)
        const hasNoActionProps = !onCompleteOrReopen && !onChangeTask
        const shouldRenderActions = hasPermission && !hasNoActionProps

        return (
            <Modal
                requestClose={requestClose}
                className={this.bem.getClassName(className)}
                title={this.getModalTitle()}
                // If there are no on-props provided, don't render a footer at all
                actions={shouldRenderActions ? this.renderModalActions : undefined}
                hideButtons={!shouldRenderActions}
            >
                {this.renderModalContent()}
            </Modal>
        )
    }

    private getModalTitle() {
        const { task } = this.props

        if (!task) {
            return localize.translate(t => t.Entities.Task, 1)
        }

        if (task.isSubTask) {
            return this.loc(t => t.subTask)
        }

        if (task.isReviewTask) {
            return this.loc(t => t.modalTitle)
        }

        return localize.translate(t => t.Entities.Task, 1)
    }

    private renderModalActions = (closeModal: () => void) => {
        const { task, onCompleteOrReopen } = this.props

        if (!task) {
            return <React.Fragment />
        }

        const labelsAndClassNames = this.getModalLabelsAndClassNames(task)
        const shouldRenderCompletedButton = task.completedAt && onCompleteOrReopen

        return (
            <Row spaceBetween={true}>
                {this.renderArchiveButton(task, closeModal)}

                <Row alignRight={true}>
                    {this.renderEditButton(task)}
                    {shouldRenderCompletedButton
                        ? this.renderCompletedTaskButton(task, labelsAndClassNames)
                        : this.renderOpenTaskButton(task, labelsAndClassNames)}
                </Row>
            </Row>
        )
    }

    private getModalLabelsAndClassNames(task: TaskDetailFields): ModalLabelsAndClassNames {
        let completeButtonIcon = IconType.taskReopen
        let reopenButtonLabel = this.loc(t => t.reopenTask)
        let completeButtonLabel = this.loc(t => t.completeTask)
        let buttonIconElement = ''

        if (task.isReviewTask) {
            completeButtonIcon = IconType.controlTask
            reopenButtonLabel = this.loc(t => t.reopenReviewTask)
            completeButtonLabel = this.loc(t => t.completeReviewTask)
            buttonIconElement = this.bem.getElement('review-button-icon')
        }

        return { completeButtonIcon, reopenButtonLabel, completeButtonLabel, buttonIconElement }
    }

    private renderCompletedTaskButton(task: TaskDetailFields, labels: ModalLabelsAndClassNames) {
        const { onCompleteOrReopen } = this.props
        const { completeButtonIcon, buttonIconElement, reopenButtonLabel } = labels

        return (
            <ModalManager
                render={requestOpen => (
                    <Button
                        submit={true}
                        rounded={true}
                        type={ButtonType.secondary}
                        icon={completeButtonIcon}
                        onClick={requestOpen}
                        className={buttonIconElement}
                    >
                        {reopenButtonLabel}
                    </Button>
                )}
                renderModal={requestClose => (
                    <TaskReopenModal task={task} onReopen={onCompleteOrReopen} requestClose={requestClose} />
                )}
            />
        )
    }

    private renderArchiveButton(task: TaskDetailFields, closeModal: () => void) {
        const { onArchiveTask } = this.props

        // If this is not a review task, we dont need to check for this permission, so we return true if its not
        const hasPermissionToEdit = task.isReviewTask
            ? permissions.hasPermission(EmployeePermissionEnum.canSetControlOperations)
            : true

        return (
            <ArchiveTaskButtonContainer
                task={task}
                isDisabled={!hasPermissionToEdit}
                onArchive={() => {
                    if (onArchiveTask) {
                        onArchiveTask()
                    }
                    closeModal()
                }}
            />
        )
    }

    private renderOpenTaskButton(task: TaskDetailFields, labels: ModalLabelsAndClassNames) {
        const { onCompleteOrReopen } = this.props
        const { buttonIconElement, completeButtonIcon, completeButtonLabel } = labels

        return (
            <ModalManager
                render={requestOpen => (
                    <Button
                        submit={true}
                        rounded={true}
                        className={buttonIconElement}
                        icon={completeButtonIcon}
                        onClick={requestOpen}
                    >
                        {completeButtonLabel}
                    </Button>
                )}
                renderModal={requestClose => (
                    <TaskCompleteModal task={task} requestClose={requestClose} onComplete={onCompleteOrReopen} />
                )}
            />
        )
    }

    private renderEditButton(task: TaskDetailFields) {
        const { onChangeTask } = this.props

        if (task.completedAt || !onChangeTask) {
            return null
        }

        // If this is not a review task, we dont need to check for this permission, so we return true if its not
        const hasPermissionToEdit = task.isReviewTask ? permissions.canEditTaskControls() : true

        return (
            <>
                {
                    <ModalManager
                        render={requestOpen => (
                            <Button type={ButtonType.secondary} onClick={requestOpen} disabled={!hasPermissionToEdit}>
                                {localize.translate(t => t.Generic.edit)}
                            </Button>
                        )}
                        renderModal={requestClose => (
                            <EditTaskModalContainer
                                taskId={task.id}
                                onEdit={onChangeTask}
                                requestClose={requestClose}
                            />
                        )}
                    />
                }
            </>
        )
    }

    private renderModalContent() {
        const { loading, task } = this.props

        if (loading) {
            return <Spinner delayed={true} />
        }

        if (!task) {
            return <NoResults />
        }

        return (
            <>
                {this.renderTaskHeader(task)}
                <Column bigSpacing={true} className={this.bem.getElement('metadata')}>
                    <Row>{this.renderNameAndDescription(task)}</Row>
                    <Row className={this.bem.getElement('date-priority-row')}>
                        {this.renderStartAt(task)}
                        {this.renderDueAt(task)}
                        {this.renderPriority(task)}
                        {this.renderEmployees(task)}
                    </Row>
                    <Row>{this.renderTags(task)}</Row>
                </Column>
                <ViewTaskModalSubTasksAndLogs
                    task={task}
                    isSubTask={task.isSubTask}
                    onChangeTask={this.handleOnTaskChange}
                />
            </>
        )
    }

    private renderTaskHeader(task: TaskDetailFields) {
        if (task.isReviewTask) {
            if (task.linkedAlertsToReview.length) {
                return <LinkedInboxItemsTable linkedAlerts={task.linkedAlertsToReview} />
            }

            return <ReviewSubTasksTable linkedTasks={task.linkedTasksToReview} />
        }

        return <LinkedTaskCard linkedItem={task.linkedItem} showDepartment={task.department} />
    }

    private renderNameAndDescription(task: TaskDetailFields) {
        return (
            <Column extraSmallSpacing={true}>
                {task.isSubTask && this.renderParentTaskLink(task)}
                <Row smallSpacing={true} className={this.bem.getElement('title-row')}>
                    {task.isSubTask && <Icon type={IconType.parentTask} />}
                    <ComponentTitle title={task.name} disabled={!!task.completedAt} />
                    {task.repeats && (
                        <Tooltip message={localize.translate(t => t.Customer.Task.frequency[task.repeats!])}>
                            <Icon type={IconType.repeat} className={this.bem.getElement('repeat-icon')} />
                        </Tooltip>
                    )}
                </Row>
                {task.description && (
                    <Paragraph preserveLine={true} className={this.bem.getElement('description')}>
                        {task.description}
                    </Paragraph>
                )}
            </Column>
        )
    }

    private renderEmployees(task: TaskDetailFields) {
        return (
            <TaskMetaData title={this.loc(t => t.employees)}>
                <UserProfileList className={this.bem.getElement('profile-list')}>
                    {task.employees.map(({ user }, index) => (
                        <UserProfileIcon
                            small={true}
                            key={`${index}-${user.id}`}
                            fullName={user.profile.fullName}
                            uri={user.profile.avatar}
                            email={user.email}
                            phone={user.profile.phoneNumber}
                            className={this.bem.getElement('profile-icon')}
                        />
                    ))}
                </UserProfileList>
            </TaskMetaData>
        )
    }

    private renderStartAt(task: TaskDetailFields) {
        if (!task.startAt) {
            return
        }

        return (
            <TaskMetaData title={this.loc(t => t.startAt)}>
                {task.startAt && (
                    <Paragraph>
                        <DateFormat date={new Date(task.startAt)} noWeekday={true} readable={true} />
                    </Paragraph>
                )}
            </TaskMetaData>
        )
    }

    private renderDueAt(task: TaskDetailFields) {
        if (!task.dueAt) {
            return
        }

        return (
            <TaskMetaData title={this.loc(t => t.dueAt)}>
                {task.dueAt && (
                    <Paragraph>
                        <RevisionDateFormat date={new Date(task.dueAt)} />
                    </Paragraph>
                )}
            </TaskMetaData>
        )
    }

    private renderTags(task: TaskDetailFields) {
        if (!task.tags || !task.tags.length) {
            return
        }

        return (
            <TaskMetaData title={this.loc(t => t.tags)}>
                <TaskTags tags={task.tags} />
            </TaskMetaData>
        )
    }

    private handleOnTaskChange = () => {
        const { onArchiveTask, onChangeTask, onCompleteOrReopen } = this.props

        if (onArchiveTask) {
            onArchiveTask()
        }
        if (onChangeTask) {
            onChangeTask()
        }
        if (onCompleteOrReopen) {
            onCompleteOrReopen()
        }
    }

    private renderPriority(task: TaskDetailFields) {
        return (
            <TaskMetaData title={this.loc(t => t.priority)}>
                <PriorityIndicator priority={task.priority} />
            </TaskMetaData>
        )
    }

    private renderParentTaskLink({ parentTask }: TaskDetailFields) {
        if (!parentTask) {
            return
        }

        const { onArchiveTask, onChangeTask, onCompleteOrReopen } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Row>
                        <Tooltip message={this.loc(t => t.parentTaskTooltip)} direction={ToolTipDirection.down}>
                            <Button type={ButtonType.actionLink} onClick={openModal}>
                                {parentTask.name}
                            </Button>
                        </Tooltip>
                    </Row>
                )}
                renderModal={closeModal => (
                    <ViewTaskModalContainer
                        taskId={parentTask.id}
                        requestClose={closeModal}
                        onArchiveTask={onArchiveTask}
                        onChangeTask={onChangeTask}
                        onCompleteOrReopen={onCompleteOrReopen}
                    />
                )}
            />
        )
    }
}
