import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerGroupSelectDocument, CustomerGroupSelectQueryVariables, CustomerGroupType } from '~/generated/graphql'

interface Props {
    name: string
    defaultCustomerGroup?: CustomerGroupType
    onChange?: (selectedOption: SelectOption | null) => void
}

interface State {
    search: string | null
}

export class CustomerGroupSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: null,
    }

    public render() {
        const { name, defaultCustomerGroup } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<CustomerGroupType, CustomerGroupSelectQueryVariables>
                variables={{ search }}
                query={CustomerGroupSelectDocument}
                path="customerGroups"
            >
                {({ data, loading, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const options = this.getOptions(data.nodes)
                    const defaultValue = defaultCustomerGroup
                        ? [{ label: defaultCustomerGroup.name, value: defaultCustomerGroup.id }]
                        : undefined

                    return (
                        <Form.Select
                            name={name}
                            loading={loading}
                            defaultValue={defaultValue}
                            options={options}
                            onSearch={search => this.setState({ search })}
                            onChange={this.handleOnChange}
                            onEndReached={fetchMore}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private getOptions(customerGroups: CustomerGroupType[]) {
        const customerGroupOptions = customerGroups.map(customerGroup => ({
            label: customerGroup.name,
            value: customerGroup.id,
        }))

        const noCustomerGroupOption = {
            label: localize.translate(t => t.Settings.Attributes.noCustomerGroup),
            value: null,
        }

        const options = [noCustomerGroupOption, ...customerGroupOptions]

        const { defaultCustomerGroup } = this.props
        if (!defaultCustomerGroup) {
            return options
        }

        const notReturnedFromQuery = !customerGroups.some(({ id }) => id === defaultCustomerGroup.id)
        if (notReturnedFromQuery) {
            options.push({ label: defaultCustomerGroup.name, value: defaultCustomerGroup.id })
            return options
        }

        return options
    }

    private handleOnChange = (option: SelectOption | null) => {
        const { onChange } = this.props

        if (!onChange) {
            return
        }

        if (!option?.value) {
            onChange(null)
        }

        onChange(option)
    }
}
