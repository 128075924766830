import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { InboxOverviewView } from './InboxOverviewView'
import { InboxDetailContainer } from './InboxDetailContainer'
import { InboxInternalAlertDetailView } from './InboxInternalAlertDetailView'
import { Route, Routes } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class InboxViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<InboxOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).inbox.detail.view()}
                    element={<InboxDetailContainer />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).inbox.internalAlert.view()}
                    element={<InboxInternalAlertDetailView />}
                />
            </Routes>
        )
    }
}

export const InboxView = withRouter(InboxViewComponent)
