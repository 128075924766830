import React from 'react'
import { AssessmentsOverviewView } from './AssessmentsOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentsDetailView } from './AssessmentsDetailView'
import { AssessmentsPropertiesView } from './AssessmentsPropertiesDetailView'
import { AssessmentsEditView } from './AssessmentsEditView'
import { AssessmentsLogView } from './AssessmentsLogView'
import { Routes, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}

class AssessmentsViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public render() {
        return (
            <Routes>
                <Route path={''} element={<AssessmentsOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.assessments.view()}
                    element={<AssessmentsDetailView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.assessments.properties()}
                    element={<AssessmentsPropertiesView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.assessments.log()}
                    element={<AssessmentsLogView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.assessments.edit()}
                    element={<AssessmentsEditView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const AssessmentsView = withRouter(AssessmentsViewComponent)
