import React from 'react'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { localize } from '~/bootstrap'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { uniqBy } from 'lodash-es'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

const LIST_NEWS_GROUPS = gql`
    query newsGroups($skip: Int, $take: Int, $sort: GroupSort, $filters: GroupFilters) {
        newsGroups(skip: $skip, take: $take, sort: $sort, filters: $filters) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`

interface NewsGroup {
    id: number
    name: string
}

interface Props {
    onChange: (option: SelectOption) => void
    placeholder?: string
    defaultValue?: SelectOption[]
    isFilter?: boolean
    name?: string
    customerEnabledGroupsOnly?: boolean
}

export class NewsGroupSelect extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { onChange, placeholder, defaultValue, isFilter, name, customerEnabledGroupsOnly } = this.props
        const filters = customerEnabledGroupsOnly ? { customerId: this.context.customer.id } : undefined

        return (
            <PaginatableQuery<NewsGroup> query={LIST_NEWS_GROUPS} variables={{ sort: { name: 'ASC' }, filters }}>
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    let sources: SelectOption[] = data
                        ? [
                              {
                                  label: localize.translate(t => t.Customer.News.NewsOverviewView.showAll),
                                  value: null,
                              },
                              ...data.nodes.map(source => ({ label: source.name, value: source.id })),
                          ]
                        : []

                    if (defaultValue) {
                        sources = uniqBy([...sources, ...defaultValue], 'value')
                    }

                    return (
                        <Form.Select
                            name={name || 'source'}
                            defaultValue={defaultValue || [sources[0]]}
                            searchable={false}
                            options={sources}
                            onEndReached={fetchMore}
                            placeholder={placeholder}
                            clearable={false}
                            loading={loading || loadingMore}
                            onChange={onChange}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }
}
