import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ReleaseUpdateModal } from './ReleaseUpdateModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ReleaseUpdateButton } from './ReleaseUpdateButton'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { GQLMutation } from '~/graphql/Mutation'
import gql from 'graphql-tag'
import { MutationFn } from 'react-apollo'
import { apolloClient } from '~/services/ApolloService'

interface Props {
    className?: ClassValue
}

interface State {
    updates: Update[]
    seenUpdates: Set<number>
}

interface Update {
    update: number
    title: string
    content: string
    media: Media[]
    version: string
}

interface Media {
    url: string
    type: string
}

export const UPDATE_SEEN_VERSION = gql`
    mutation ($version: String, $update: Int) {
        setLastAppUpdateSeen(version: $version, update: $update) {
            id
            lastAppUpdateSeen {
                date
                version
                update
                skip
            }
        }
    }
`

export interface UpdateSeenMutationVariables {
    version: string
    update: number
}

export interface UpdateSeenMutationResponse {
    setLastAppUpdateSeen: boolean
}

export class ReleaseUpdateModalManager extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = UserContext
    public context: UserContextValue

    public state: State = {
        updates: [],
        seenUpdates: new Set(),
    }

    private modalRef = React.createRef<ModalManager>()
    private bem = new BEM('ReleaseUpdateModalManager')
    private timer: number

    public componentDidMount() {
        this.timer = window.setTimeout(() => {
            this.setUpdateNotes()
        }, 1500)
    }

    public componentWillUnmount() {
        window.clearTimeout(this.timer)
    }

    public render() {
        const { className } = this.props
        const { updates, seenUpdates } = this.state
        const amountLeftToSee = updates.length - seenUpdates.size

        return (
            <GQLMutation<UpdateSeenMutationResponse, UpdateSeenMutationVariables> mutation={UPDATE_SEEN_VERSION}>
                {mutate => {
                    return (
                        <div className={this.bem.getClassName(className)}>
                            <ModalManager
                                dismissableByOverlay={false}
                                ref={this.modalRef}
                                render={openModal =>
                                    amountLeftToSee > 0 ? (
                                        <ReleaseUpdateButton
                                            onClick={openModal}
                                            count={amountLeftToSee}
                                            onClose={this.handleSubmit(mutate)}
                                        />
                                    ) : null
                                }
                                renderModal={closeModal => (
                                    <ReleaseUpdateModal
                                        steps={updates}
                                        onClose={closeModal}
                                        onStepSeen={this.handleOnStepSeen(mutate)}
                                    />
                                )}
                            />
                        </div>
                    )
                }}
            </GQLMutation>
        )
    }

    private async setUpdateNotes() {
        try {
            const updates: Update[] = await fetch('/new-releases/NewReleases.json').then(response => response.json())

            if (updates) {
                const sortedUpdates = updates.sort((a, b) => a.update - b.update)
                const lastUpdate = this.context.user!.lastAppUpdateSeen

                if (lastUpdate && lastUpdate.skip) {
                    await apolloClient.mutate({
                        mutation: UPDATE_SEEN_VERSION,
                        variables: {
                            update: sortedUpdates[sortedUpdates.length - 1].update,
                            version: sortedUpdates[sortedUpdates.length - 1].version,
                        },
                    })
                    return
                }

                const lastUpdateIndex = lastUpdate
                    ? sortedUpdates.findIndex(update => update.update === lastUpdate.update)
                    : -1
                const updatesToShow = sortedUpdates.slice(lastUpdateIndex + 1)

                this.setState(
                    {
                        updates: updatesToShow,
                    },
                    () => {
                        if (this.modalRef.current && updatesToShow.length > 0) {
                            this.modalRef.current.toggleModal()
                        }
                    }
                )
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to get release notes', error)
        }
    }

    private handleOnStepSeen =
        (mutate: MutationFn<UpdateSeenMutationResponse, UpdateSeenMutationVariables>) => async (seenStep: number) => {
            const { updates, seenUpdates } = this.state
            seenUpdates.add(seenStep)

            await mutate({
                variables: {
                    update: updates[seenStep].update,
                    version: updates[seenStep].version,
                },
            })

            this.setState({
                seenUpdates,
            })
        }

    private handleSubmit =
        (mutate: MutationFn<UpdateSeenMutationResponse, UpdateSeenMutationVariables>) => async () => {
            const { updates } = this.state
            const updateSeen = this.state.updates[updates.length - 1]
            const seenUpdates = updateSeen.update
            const lastUpdateVersion = updateSeen.version

            const response = await mutate({
                variables: {
                    update: seenUpdates,
                    version: lastUpdateVersion,
                },
            })

            if (response && response.data && response.data.setLastAppUpdateSeen) {
                this.setState({
                    updates: [],
                })
            }
        }
}
