import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import { localize, permissions } from '~/bootstrap'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Button } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { AddAssessmentModal } from '~/components/Domain/Assessement/AddAssessmentModal'
import { AssessmentDetailList } from '~/components/Domain/Compliance/Assessments/AssessmentDetailList'
import { breadcrumbs } from '~/views/breadcrumbs'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps {}

interface Filters {
    search: string | null
}

class AssessmentsOverviewViewComponeent extends React.PureComponent<React.PropsWithChildren<Props>, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.Overview)
    public render() {
        return (
            <ParamManager<Filters>
                defaultState={{
                    search: '',
                }}
            >
                {({ paramState, setParamState }) => {
                    const { search } = paramState

                    return (
                        <Page>
                            <PageHeader
                                title={this.loc(t => t.title)}
                                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).risks.index]}
                                actionComponent={
                                    <Row>
                                        <Search
                                            defaultValue={search}
                                            placeholder={this.loc(t => t.searchPlaceholder)}
                                            onChange={search => setParamState({ search })}
                                        />
                                        {permissions.canUpdateComplianceAndDocuments(
                                            this.context.activeDepartmentId
                                        ) && (
                                            <ModalManager
                                                render={openModal => (
                                                    <Button icon={IconType.add} onClick={openModal} rounded={true}>
                                                        {this.loc(t => t.newAssessment)}
                                                    </Button>
                                                )}
                                                renderModal={requestClose => (
                                                    <AddAssessmentModal requestClose={requestClose} />
                                                )}
                                            />
                                        )}
                                    </Row>
                                }
                            />
                            <AssessmentDetailList search={search} />
                        </Page>
                    )
                }}
            </ParamManager>
        )
    }
}

export const AssessmentsOverviewView = withRouter(AssessmentsOverviewViewComponeent)
