import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import { routes } from '~/views/routes'
import { OrganizationEmployeesOverviewView } from './OrganizationEmployeesOverviewView'
import { OrganizationEmployeesDetailView } from './OrganizationEmployeesDetailView'
import { OrganizationEmployeesEditView } from './OrganizationEmployeesEditView'
import { OrganizationProfileDetailView } from './OrganizationProfileDetailView'
import { OrganizationProfileEditView } from './OrganizationProfileEditView'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route, Navigate } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

class OrganizationViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = UserContext
    public context: UserContextValue

    public render() {
        const { user, consultant } = this.context

        if (!user || !consultant) {
            return null
        }

        return (
            <Page>
                <Routes>
                    <Route
                        element={<OrganizationEmployeesOverviewView />}
                        path={newRoutes.consultant.organization.employees.index}
                    />
                    <Route
                        element={<OrganizationEmployeesEditView />}
                        path={newRoutes.consultant.organization.employees.edit()}
                    />
                    <Route
                        element={<OrganizationEmployeesDetailView />}
                        path={newRoutes.consultant.organization.employees.view()}
                    />
                    <Route
                        element={<OrganizationProfileDetailView />}
                        path={newRoutes.consultant.organization.profile.index}
                    />
                    <Route
                        element={<OrganizationProfileEditView />}
                        path={newRoutes.consultant.organization.profile.edit}
                    />
                    <Route
                        path="*"
                        element={<Navigate replace to={routes.consultant.organization.employees.index} />}
                    />
                </Routes>
            </Page>
        )
    }
}

export const OrganizationView = withRouter(OrganizationViewComponent)
