import './TaskModalSubTaskSelection.scss'

import React from 'react'
import { CheckboxList } from '~/components/Core/DataEntry/Form/CheckboxList'
import { BEM } from '~/services/BEMService'
import { CustomerTaskTemplateType } from '~/generated/graphql'
import { Truncate } from '~/components/Core/Text/Truncate'
import { Column } from '~/components/Core/Layout/Column'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { localize } from '~/bootstrap'

interface Props {
    onSelectionChange: (selectedIds: number[]) => void
    subTasks: CustomerTaskTemplateType[]
}

export class TaskModalSubTaskSelection extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('TaskModalSubTaskSelection')
    private selectedSubTaskIds = this.props.subTasks.map(({ id }) => id)
    private loc = localize.namespaceTranslate(t => t.Customer.Task.TaskModal.SubTaskSelection)

    public render() {
        return (
            <Column bigSpacing={true} className={this.bem.getClassName()}>
                <SectionTitle bold={true}>{this.loc(t => t.title)}</SectionTitle>
                <Column smallSpacing={true}>
                    <Field forInput="subTasks" label={this.loc(t => t.label)} />
                    <CheckboxList className={this.bem.getElement('list')} items={this.getItems()} />
                </Column>
            </Column>
        )
    }

    private getItems() {
        const { subTasks } = this.props

        return subTasks.map(({ id, name }) => ({
            label: <Truncate>{name}</Truncate>,
            name,
            defaultChecked: true,
            onChange: this.handleSelectionChange(id),
        }))
    }

    private handleSelectionChange = (id: number) => (checked: boolean, name: string) => {
        const { onSelectionChange } = this.props

        if (checked) {
            this.selectedSubTaskIds.push(id)
            onSelectionChange(this.selectedSubTaskIds)

            return
        }

        this.selectedSubTaskIds = this.selectedSubTaskIds.filter(selectedId => selectedId !== id)
        onSelectionChange(this.selectedSubTaskIds)
    }
}
