import React from 'react'
import { localize } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Section } from '~/components/Core/Layout/Section'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { AllTasksParams } from '~/views/Customer/Tasks/AllTasksView'
import { GroupedTasksContainer } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksContainer'
import { OverDueGroupedTasksContainer } from '~/views/Customer/Tasks/GroupedTasks/OverDueGroupedTasksContainer'
import { OverdueGroupedTasksQuery } from '~/views/Customer/Tasks/GroupedTasks/OverdueGroupedTasksQuery'

interface Props {
    paramState: Omit<AllTasksParams, 'tableView'>
    setRefetchFunction: (refetch: () => void) => void
    refetch: () => void
    className?: ClassValue
}

export class AllTasksGroupViewContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private bem = new BEM('AllTasksGroupViewContainer')

    public render() {
        const { className } = this.props
        const mappedFilters = this.getMappedFilters()

        return (
            <div className={this.bem.getClassName(className)}>
                <OverdueGroupedTasksQuery filters={mappedFilters}>
                    {({ hasOverdueTasks, loading }) => {
                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        if (hasOverdueTasks) {
                            return this.renderWithOverdueTasks()
                        }

                        return <GroupedTasksContainer filters={mappedFilters} />
                    }}
                </OverdueGroupedTasksQuery>
            </div>
        )
    }

    private renderWithOverdueTasks() {
        const mappedFilters = this.getMappedFilters()

        return (
            <>
                <Section title={this.loc(t => t.overdueTitle)}>
                    <OverDueGroupedTasksContainer filters={mappedFilters} />
                </Section>
                <Section title={this.loc(t => t.futureTitle)}>
                    <GroupedTasksContainer filters={mappedFilters} />
                </Section>
            </>
        )
    }

    private getMappedFilters() {
        const { paramState } = this.props
        const mappedFilters = {
            ...paramState,
            tagIds: paramState.tagIds?.map(tag => tag.value),
        }

        return mappedFilters
    }
}
