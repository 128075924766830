import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CreateCustomerGroupView } from './CreateCustomerGroupView'
import { CreateNewCustomerInGroupView } from './CreateNewCustomerInGroupView'
import { CustomerGroupDetailView } from './CustomerGroupDetailView'
import { CustomerGroupEditView } from './CustomerGroupEditView'
import { CustomerGroupsOverviewView } from './CustomerGroupsOverviewView'
import { Routes, Route } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

export class CustomerGroupsView extends React.PureComponent {
    public render() {
        const basePath = newRoutes.consultant.customerGroups

        return (
            <Routes>
                <Route path={basePath.create} element={<CreateCustomerGroupView />} />
                <Route path={basePath.detail().newCustomer()} element={<CreateNewCustomerInGroupView />} />
                <Route path={basePath.detail().edit} element={<CustomerGroupEditView />} />
                <Route path={basePath.detail().view} element={<CustomerGroupDetailView />} />
                <Route path={''} element={<CustomerGroupsOverviewView />} />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}
