import React from 'react'
import { ResponsiveGrid } from '~/components/Core/Layout/ResponsiveGrid'
import { InfoWidget } from '../Widgets/InfoWidget/InfoWidget'
import { WidgetContainer } from '../Widgets/components/WidgetContainer'
import { BEM } from '~/services/BEMService'
import { gridBreakpoints, gridColumns } from '../CreateWidgetWidget/widgets'

interface Props {}

export class EmptyWidgetState extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('EmptyWidgetState')

    public render() {
        return (
            <ResponsiveGrid
                items={this.getItems()}
                rowHeight={1}
                breakpoints={gridBreakpoints}
                columns={gridColumns}
                className={this.bem.getClassName()}
            />
        )
    }

    private getItems() {
        const infoItem = this.getInfoItem()
        const randomItems = Array.from(new Array(10)).map(this.getRandomItem)

        return [infoItem, ...randomItems]
    }

    private getInfoItem() {
        return {
            id: 100,
            x: { lg: 0, md: 0, sm: 0, xs: 0 },
            y: { lg: 0, md: 0, sm: 0, xs: 0 },
            w: 1,
            h: 340,
            render: () => <InfoWidget />,
        }
    }

    private getRandomItem = (_: any, i: number) => {
        const randomHeight = this.getRandomInt(500)

        return {
            id: i,
            x: {
                lg: this.getRandomInt(i),
                md: this.getRandomInt(i),
                sm: this.getRandomInt(i),
                xs: this.getRandomInt(i),
            },
            y: {
                lg: this.getRandomInt(i * 500),
                md: this.getRandomInt(i * 500),
                sm: this.getRandomInt(i * 500),
                xs: this.getRandomInt(i * 500),
            },
            w: 1,
            h: randomHeight < 192 ? 192 : randomHeight,
            render: () => <WidgetContainer isEmpty={true} />,
        }
    }

    private getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max))
    }
}
