import './ConsultationLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { matchPath } from 'react-router'

interface Consultation {
    id: number
    name: string
}

interface Props {
    className?: ClassValue
    consultation: Consultation
    iconOnly?: boolean
}

interface State {}

export class ConsultationLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ConsultationLabel')

    public render() {
        const { className, consultation, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).consultations.detail.view(consultation.id)
        const match = matchPath(
            {
                path: link,
                caseSensitive: false,
            },
            window.location.pathname
        )

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                iconType={IconType.consultation}
                to={match ? undefined : link}
            >
                {iconOnly ? null : consultation.name}
            </IconLabel>
        )
    }
}
