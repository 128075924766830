import './RelevantModalAddDepartment.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { DepartmentType } from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { CustomerFrameworkSelect } from '~/components/Domain/CustomerFramework/CustomerFrameworkSelect/CustomerFrameworkSelect'
import { Topic } from '~/components/Domain/Topic/TopicsQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Table, RowData } from '~/components/Core/DataDisplay/Table/Table'
import { Center } from '~/components/Core/Layout/Center'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { DepartmentsQuery, Department } from '~/components/Domain/Department/DepartmentsQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'

interface Props {
    className?: ClassValue
    setModalState: (
        newModalContentState: { [key: string]: any },
        callBack?: (nextModalContentState: { [key: string]: any }) => void
    ) => void
    defaultTopics?: Topic[]
    defaultDepartments?: DepartmentType[]
    selectedTopics?: Topic[]
    selectedDeparments?: DepartmentType[]
}

interface State {
    search?: string | null
    customerFrameworkId?: SelectOption | null
}

export class RelevantModalAddDepartment extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: undefined,
        customerFrameworkId: undefined,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModalAddDepartment)
    private bem = new BEM('RelevantModalAddDepartment')

    public render() {
        const { className } = this.props
        const { customerFrameworkId, search } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <Column>
                    <Row className={this.bem.getElement('filters')} smallSpacing={true}>
                        <Search onChange={value => this.setState({ search: value })} />
                        <FilterDropdown center={true} hasActiveFilter={this.isFilterActive()}>
                            <FilterOption label={this.loc(t => t.filters.profiles)} forInputName="customerFramework">
                                <CustomerFrameworkSelect
                                    name={'customerFramework'}
                                    onChange={option => this.setState({ customerFrameworkId: option })}
                                    isFilter={true}
                                    selectedOptions={customerFrameworkId ? [customerFrameworkId] : undefined}
                                    externallyCuratedOnly={true}
                                />
                            </FilterOption>
                        </FilterDropdown>
                    </Row>
                    {/* Wrap the query to limit it's height but still trigger the infinite scroll */}
                    <div className={this.bem.getElement('query-wrapper')}>
                        <DepartmentsQuery
                            forModal={true}
                            filters={{
                                search,
                                includeIfHasExternalTopic: true,
                                customerFrameworkIds: customerFrameworkId
                                    ? [customerFrameworkId.value as number]
                                    : undefined,
                            }}
                        >
                            {({ data, loading, loadingMore }) => {
                                if (loading) {
                                    return (
                                        <Center className={this.bem.getElement('spinner-wrapper')}>
                                            <Spinner delayed={true} />
                                        </Center>
                                    )
                                }

                                const departments = data?.nodes || []

                                const tableData: RowData[] = this.getTableData(departments)

                                return (
                                    <Table
                                        className={this.bem.getElement('table')}
                                        columns={[
                                            {
                                                field: 'department',
                                                headerLabel: localize.translate(t => t.Entities.Department),
                                            },
                                            {
                                                field: 'customerFrameworks',
                                                headerLabel: this.loc(t => t.filters.profiles),
                                            },
                                        ]}
                                        data={tableData}
                                        loading={loading}
                                        loadingMore={loadingMore}
                                    />
                                )
                            }}
                        </DepartmentsQuery>
                    </div>
                </Column>
            </div>
        )
    }

    private isFilterActive() {
        const { customerFrameworkId } = this.state

        return !!customerFrameworkId
    }

    private getTableData(departments: Department[]) {
        const { selectedDeparments = [], defaultDepartments = [] } = this.props

        return departments.map(department => {
            const departmentIsDefault = defaultDepartments.some(t => t.id === department.id)
            const departmentIsSelected = selectedDeparments.some(t => t.id === department.id)

            const customerFrameworks = department.departmentFrameworks.map(departmentFramework => ({
                id: departmentFramework.customerFramework.idn, // 'idn' seems to be the actual customerFramework id here (instead of .id)
                name: departmentFramework.customerFramework.name,
                icon: departmentFramework.customerFramework.icon,
                color: departmentFramework.customerFramework.color,
            }))

            return {
                id: department.id,
                columns: {
                    department: (
                        <Row>
                            <Checkbox
                                className={this.bem.getElement('checkbox')}
                                large
                                checked={departmentIsSelected || departmentIsDefault}
                                disabled={departmentIsDefault}
                                name={'check'}
                                onChange={checked => {
                                    if (checked) {
                                        this.handleAddDepartment(department)
                                    } else {
                                        this.handleRemoveDepartment(department)
                                    }
                                }}
                            />

                            <p>{department.name}</p>
                        </Row>
                    ),
                    customerFrameworks: (
                        <CustomerFrameworkIconList customerFrameworks={customerFrameworks} showAllActive={true} />
                    ),
                },
            }
        })
    }

    private handleAddDepartment(department: Department) {
        const { setModalState, selectedDeparments = [] } = this.props

        setModalState({
            extraDepartments: [...selectedDeparments, department],
        })
    }

    private handleRemoveDepartment(department: Department) {
        const { setModalState, selectedDeparments = [] } = this.props
        const filteredDepartment = selectedDeparments.filter(dept => dept.id !== department.id)

        setModalState({
            extraDepartments: [...filteredDepartment],
        })
    }
}
