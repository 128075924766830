import './EditWidgetModal.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import { WidgetKey, widgets } from '../CreateWidgetWidget/widgets'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { isArray, isNumber } from 'lodash-es'
import { EditWidget } from '../Mutations/EditWidget'
import { MutationFn } from 'react-apollo'
import {
    DashboardWidgetLayoutTypeEnum,
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables,
    EditWidgetMutation,
    EditWidgetMutationVariables,
} from '~/generated/graphql'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { DeleteWidgets } from '../Mutations/DeleteWidgets'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    closeModal: () => void
    widget: Widget
    layout: DashboardWidgetLayoutTypeEnum | undefined
    className?: ClassValue
}

interface Widget {
    id: number
    customName?: string
    departmentIds?: number[]
    forAllDepartments?: boolean
    widgetKey: WidgetKey
}

interface State {
    selectedDepartments: SelectOption<DepartmentOptionValueType>[]
}

type DepartmentOptionValueType = { allDepartments: boolean } | number

enum FormFields {
    name = 'NAME',
    departments = 'DEPARTMENTS',
}

export class EditWidgetModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedDepartments: this.getDefaultDepartmentOption(),
    }

    private widgetType = widgets.find(({ widgetKey }) => widgetKey === this.props.widget.widgetKey)
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.WidgetModal)
    private bem = new BEM('EditWidgetModal')

    public render() {
        const { closeModal, className } = this.props

        return (
            <EditWidget>
                {(editMutate, { loading: editLoading }) => (
                    <DeleteWidgets>
                        {(deleteMutate, { loading: deleteLoading }) => {
                            const loading = editLoading || deleteLoading

                            return (
                                <Form onSubmit={state => this.handleSubmit(state, editMutate)}>
                                    <Modal
                                        className={this.bem.getClassName(className)}
                                        title={this.loc(t => t.editTitle)}
                                        requestClose={closeModal}
                                        loading={loading}
                                        actions={() => this.renderActions(deleteMutate, loading)}
                                    >
                                        {this.renderModalContent()}
                                    </Modal>
                                </Form>
                            )
                        }}
                    </DeleteWidgets>
                )}
            </EditWidget>
        )
    }

    private renderActions(
        deleteMutate: MutationFn<DeleteWidgetMutation, DeleteWidgetMutationVariables>,
        loading: boolean
    ) {
        const { closeModal } = this.props
        const deleteLabel = localize.translate(t => t.Generic.delete)
        const cancelLabel = localize.translate(t => t.Generic.cancel)
        const confirmLabel = localize.translate(t => t.Generic.save)

        return (
            <Row spaceBetween={true} className={this.bem.getElement('action-container')}>
                <Button
                    disabled={loading}
                    loading={loading}
                    type={ButtonType.delete}
                    onClick={() => this.handleDelete(deleteMutate)}
                >
                    {deleteLabel}
                </Button>
                <Row className={this.bem.getElement('right-buttons')}>
                    <Button disabled={loading} type={ButtonType.tertiary} onClick={closeModal}>
                        {cancelLabel}
                    </Button>
                    <Button disabled={loading} loading={loading} submit={true}>
                        {confirmLabel}
                    </Button>
                </Row>
            </Row>
        )
    }

    private async handleDelete(mutate: MutationFn<DeleteWidgetMutation, DeleteWidgetMutationVariables>) {
        const { layout, widget, closeModal } = this.props
        const { id } = widget

        const response = await mutate({
            variables: { widgetIds: [id], layout: layout || DashboardWidgetLayoutTypeEnum.threecolumn },
        })

        if (response && response.data?.deleteWidgets) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            closeModal()
        }
    }

    private renderModalContent() {
        if (!this.widgetType) {
            return
        }

        const { widgetKey, imageSource } = this.widgetType

        const subTitle = this.loc(t => t.SubTitles[widgetKey])
        const description = this.loc(t => t.Descriptions[widgetKey])

        return (
            <Column bigSpacing={true} className={this.bem.getElement('container')}>
                <div className={this.bem.getElement('section-container')}>
                    <div className={this.bem.getElement('image-container')}>
                        <img className={this.bem.getElement('image')} src={imageSource} />
                    </div>
                    <Column>
                        <SectionTitle>{subTitle}</SectionTitle>
                        <Paragraph>{description}</Paragraph>
                    </Column>
                </div>
                <Column>
                    {this.renderNameInput()}
                    {this.renderDepartmentSelect()}
                </Column>
            </Column>
        )
    }

    private renderNameInput() {
        if (!this.widgetType) {
            return
        }

        const { widgetKey } = this.widgetType
        const { widget } = this.props
        const { customName } = widget

        const nameInputLabel = this.loc(t => t.nameInputLabel)
        const defaultName = customName
            ? customName
            : localize.translate(t => t.Customer.DashboardView.CreateWidgetWidget[widgetKey])

        return (
            <Column extraSmallSpacing={true}>
                <Paragraph>{nameInputLabel}</Paragraph>
                <Form.Input defaultValue={defaultName} name={FormFields.name} />
            </Column>
        )
    }

    private renderDepartmentSelect() {
        if (!this.widgetType) {
            return
        }

        if (this.widgetType.hasNoDepartmentContext === true) {
            return
        }

        const departmentInputLabel = this.loc(t => t.departmentInputLabel)
        const departmentOptions = this.getDepartmentOptions()

        const hasAllDepartmentsSelected = this.hasAllDepartmentsSelected()

        return (
            <Column extraSmallSpacing={true}>
                <Paragraph>{departmentInputLabel}</Paragraph>
                <Form.Select
                    options={departmentOptions}
                    name={FormFields.departments}
                    multi={!hasAllDepartmentsSelected}
                    clearable={!hasAllDepartmentsSelected}
                    onChange={this.handleOnDepartmentChange}
                    value={this.state.selectedDepartments}
                />
            </Column>
        )
    }

    private getDepartmentOptions() {
        const defaultOption = this.getDefaultDepartmentOption()

        const departments = this.context.employee?.departments
        if (!departments || !departments.length) {
            return defaultOption
        }

        const departmentOptions = departments.map(({ id, name }) => ({ label: name, value: id }))

        return [...defaultOption, ...departmentOptions]
    }

    private getDefaultDepartmentOption() {
        const { widget } = this.props
        const { forAllDepartments, departmentIds } = widget

        if (forAllDepartments) {
            return EditWidgetModal.allDepartmentsOption
        }

        const departments = this.context.employee?.departments
        if (!departmentIds?.length || !departments?.length) {
            return []
        }

        const defaultDepartments = departments.filter(({ id }) => departmentIds.includes(id))
        return defaultDepartments.map(({ id, name }) => ({ label: name, value: id }))
    }

    private hasAllDepartmentsSelected() {
        const { selectedDepartments } = this.state

        const hasAllDepartmentsSelected = selectedDepartments.some(
            ({ value }) => !isNumber(value) && value.allDepartments === true
        )
        if (hasAllDepartmentsSelected) {
            return true
        }

        return false
    }

    private handleSubmit = async (
        formState: FormState,
        mutate: MutationFn<EditWidgetMutation, EditWidgetMutationVariables>
    ) => {
        const { widget, closeModal } = this.props
        const { id } = widget

        const { departmentIds, hasAllDepartmentsSelected } = this.getDepartmentMetadata()

        const response = await mutate({
            variables: {
                id,
                metadata: {
                    name: formState[FormFields.name],
                    departmentIds,
                    allDepartments: hasAllDepartmentsSelected,
                },
            },
        })

        if (response && response.data?.editWidget) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            closeModal()
        }
    }

    private handleOnDepartmentChange = (
        selectedOptions: SelectOption<DepartmentOptionValueType> | SelectOption<DepartmentOptionValueType>[]
    ) => {
        if (!selectedOptions) {
            this.setState({ selectedDepartments: [] })
            return
        }

        if (!isArray(selectedOptions)) {
            this.setState({ selectedDepartments: [selectedOptions] })
            return
        }

        if (!selectedOptions.length) {
            this.setState({ selectedDepartments: [] })
            return
        }

        const hasAllDepartmentsSelected = selectedOptions.some(
            ({ value }) => !isNumber(value) && value.allDepartments === true
        )
        if (hasAllDepartmentsSelected) {
            this.setState({ selectedDepartments: EditWidgetModal.allDepartmentsOption })
            return
        }

        this.setState({ selectedDepartments: selectedOptions })
    }

    private getDepartmentMetadata() {
        const hasNoDepartmentContext = !!this.widgetType?.hasNoDepartmentContext
        const { selectedDepartments } = this.state

        let departmentIds: number[] | undefined
        let hasAllDepartmentsSelected: boolean | undefined

        if (!hasNoDepartmentContext) {
            hasAllDepartmentsSelected = this.hasAllDepartmentsSelected()

            if (!hasAllDepartmentsSelected) {
                departmentIds = []

                for (const { value } of selectedDepartments) {
                    if (isNumber(value)) {
                        departmentIds.push(value)
                    }
                }
            }
        }

        return { departmentIds, hasAllDepartmentsSelected }
    }

    private static get allDepartmentsOption() {
        const defaultLabel = localize.translate(t => t.Customer.DashboardView.WidgetModal.allDepartments)
        const defaultOption = [{ label: defaultLabel, value: { allDepartments: true } }]

        return defaultOption
    }
}
