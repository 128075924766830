import React from 'react'
import { localize } from '~/bootstrap'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

import { CustomerFrameworksBlockList } from '../CustomerFramework/CustomerFrameworksBlockList'
import { ThemesDetailCardTopicType } from '../Themes/ThemesDetailCardList/ThemesDetailCardListQuery'
import { PageQuery } from '../PageQuery/PageQuery'
import {
    TopicControlMeasureType,
    TopicDesignAndEffectivenessSummaryDocument,
    TopicDesignAndEffectivenessSummaryQuery,
    TopicDesignAndEffectivenessSummaryQueryVariables,
    TopicNoteType,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicDesignAndEffectivenessTableSummary } from './TopicDesignAndEffectiveness/TopicDesignAndEffectivenessTableSummary'
import { shuffle } from 'lodash-es'

interface Props {
    requestClose: () => void
    topic: ThemesDetailCardTopicType
}

type QueryData = TopicDesignAndEffectivenessSummaryQuery['topic']

export class TopicOverviewSummaryModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicOverviewSummaryModal)

    public render() {
        const { requestClose, topic } = this.props

        return (
            <Modal hideButtons modalSize={ModalSize.small} title={topic.name} requestClose={requestClose}>
                <Column bigBottomSpacing>
                    <Column bigSpacing>
                        {this.renderFrameworksSection()}
                        {this.renderAssessmentsSection()}
                    </Column>
                </Column>
            </Modal>
        )
    }

    private renderFrameworksSection() {
        return (
            <Column>
                <Paragraph>{this.loc(t => t.description)}</Paragraph>
                <CustomerFrameworksBlockList customerFrameworks={this.props.topic.customerFrameworks} />
            </Column>
        )
    }

    private renderAssessmentsSection() {
        const id = this.props.topic.id
        const departmentId = this.context.activeDepartmentId
        const customerSlug = this.context.customer.slug

        return (
            <PageQuery<QueryData, TopicDesignAndEffectivenessSummaryQueryVariables>
                query={TopicDesignAndEffectivenessSummaryDocument}
                variables={{ id, customerSlug, departmentId }}
            >
                {topic => {
                    const controls = topic?.topicControlMeasures || []
                    const notes = topic?.topicNotes || []
                    const items = shuffle([...controls, ...notes])

                    return (
                        <TopicDesignAndEffectivenessTableSummary
                            items={items as (TopicNoteType | TopicControlMeasureType)[]}
                            topicId={id}
                        />
                    )
                }}
            </PageQuery>
        )
    }
}
