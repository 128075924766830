import React from 'react'
import { localize } from '~/bootstrap'
import { SubtleLabel } from '~/components/Core/Typography/SubtleLabel'
import { BEM, ClassValue } from '~/services/BEMService'
import './NormArticleCount.scss'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    total?: number
    count: number
    showIcon?: boolean
}

interface State {}

export class NormArticleCount extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('NormArticleCount', () => ({
        none: !this.props.total,
    }))

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.TopicCard)

    public render() {
        const { total, count, className, showIcon } = this.props

        if (!total) {
            return (
                <div className={this.bem.getClassName(className)}>
                    {showIcon && <Icon type={IconType.file} />}
                    <SubtleLabel>{this.loc(t => t.noArticles)}</SubtleLabel>
                </div>
            )
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {showIcon && <Icon type={IconType.file} />}
                <SubtleLabel>
                    {this.loc(t => t.articlesFilled, {
                        currentArticles: count,
                        totalArticles: total,
                    })}
                </SubtleLabel>
            </div>
        )
    }
}
