import './Page.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    className?: ClassValue
    smallerPadding?: boolean
    contained?: boolean
    forOverview?: boolean
}

interface State {}

export class Page extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Page', () => ({
        'smaller-padding': this.props.smallerPadding,
        contained: this.props.contained,
        forOverview: this.props.forOverview,
    }))

    public render() {
        const { children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Column bigSpacing={true}>{children}</Column>
            </div>
        )
    }
}
