import React from 'react'

import { OperationVariables, Query } from 'react-apollo'
import { getMergerByPath } from '~/utils/mergeByPath'
import { DocumentNode } from 'graphql'
import get from 'lodash-es/get'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import { ApolloQueryResult } from 'apollo-client'

export interface PaginatableOptions<TData = any, TVariables = any> {
    data: PaginationResult<TData> | null
    loadingMore: boolean
    loading: boolean
    canFetchMore: boolean
    hasPaginated: boolean
    fetchMore: () => void
    refetch: (variables?: TVariables) => Promise<ApolloQueryResult<QueryData<TData>>>
}

interface Props<TData = any, TVariables = any> {
    query: DocumentNode
    variables?: Record<string, any>
    children: (options: PaginatableOptions<TData, TVariables>) => JSX.Element | null
    path?: string
    take?: number
    startingTake?: number
}

export interface PaginationResult<TData = any> {
    totalCount: number
    hasNextPage: boolean
    nodes: TData[]
}

interface QueryData<TData = any> {
    [query: string]: PaginationResult<TData>
}

export class PaginatableQuery<TData = any, TVariables extends OperationVariables = any> extends React.PureComponent<
    Props<TData, TVariables>
> {
    private skip: number = 0

    private take: number = this.props.take ? this.props.take : 10
    private startingTake: number = this.props.startingTake || this.take

    private canFetchMore: boolean = true
    private fetchingMore: boolean = false
    private hasPaginated: boolean = false

    private queryName: string | undefined = get(this.props.query, 'definitions.0.name.value')

    public componentDidMount() {
        if (!this.queryName && !this.props.path) {
            throw new Error('Cannot get query name from query, please specify path property to define the query name')
        }
    }

    public componentDidUpdate() {
        // When the component updates it means the props variable were, for instance when filters or sorting changes on
        // a query. When that happens we want to make sure we reset the pagination.
        this.resetPagination()
    }

    public render() {
        const { variables, query, children, path } = this.props

        const queryName = path ? path : (this.queryName as string)

        return (
            <Query<QueryData<TData>>
                query={query}
                notifyOnNetworkStatusChange={true}
                variables={{
                    ...variables,
                    skip: 0,
                    take: this.startingTake,
                }}
            >
                {({ loading, data, fetchMore, refetch }) => {
                    if (!data && !loading) {
                        return <Paragraph>{localize.translate(t => t.Errors.failedToLoad)}</Paragraph>
                    }

                    const arrayOfData = data && data[queryName] ? data[queryName] : null

                    if (arrayOfData && arrayOfData.hasNextPage === undefined) {
                        throw new Error('hasNextPage is undefined please add this to the paginated query')
                    }

                    this.canFetchMore = !!(arrayOfData && arrayOfData.hasNextPage)

                    return children({
                        data: arrayOfData,
                        loading: !this.fetchingMore && loading,
                        loadingMore: this.fetchingMore && loading,
                        canFetchMore: this.canFetchMore,
                        hasPaginated: this.hasPaginated,
                        refetch: refetch,
                        fetchMore: () => {
                            if (!this.canFetchMore) {
                                return
                            }

                            if (this.skip === 0) {
                                this.skip = this.startingTake
                            } else {
                                this.skip = this.skip + this.take
                            }

                            this.fetchingMore = true
                            this.hasPaginated = true

                            fetchMore({
                                variables: {
                                    skip: this.skip,
                                    take: this.take,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                        return prev
                                    }

                                    return getMergerByPath(`${queryName}.nodes`)(prev, fetchMoreResult)
                                },
                            })
                        },
                    })
                }}
            </Query>
        )
    }

    private resetPagination() {
        this.skip = 0
        this.canFetchMore = true
        this.fetchingMore = false
    }
}
