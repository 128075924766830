import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import get from 'lodash-es/get'
import { SectionList } from '~/components/Domain/LawAndRegulation/SectionList'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    abstractLawId: number
    defaultActiveArticle?: number
    onLoadFirstArticle?: (section: Section) => void
}

interface State {}

interface Section {
    id: number
    name: string
    articleId: number | null
    parentId: number | null
    order: number
}

interface Law {
    id: number
    sections: Section[]
}

interface QueryResponse {
    law?: Law
}

const LAW_WITH_SECTIONS_QUERY = gql`
    query lawWithSections($abstractLawId: Int!) {
        law(abstractLawId: $abstractLawId) {
            id
            sections {
                id
                name
                articleId
                parentId
                order
            }
        }
    }
`

export class LawSections extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { abstractLawId, defaultActiveArticle, onLoadFirstArticle } = this.props

        return (
            <Query<QueryResponse> query={LAW_WITH_SECTIONS_QUERY} variables={{ abstractLawId }}>
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    const sections: Section[] = get(data, 'law.sections')

                    if (!sections || sections.length === 0) {
                        return null
                    }

                    const defaultActive = sections.find(section => section.articleId === defaultActiveArticle)
                    const expandTo = defaultActive ? defaultActive.id : undefined

                    return (
                        <SectionList
                            sections={sections}
                            expandTo={expandTo}
                            getRouteTo={articleId =>
                                routes
                                    .customer(this.context.customer.slug)
                                    .lawAndRegulation.detail.article(abstractLawId, articleId)
                            }
                            onLoadFirstArticle={onLoadFirstArticle}
                        />
                    )
                }}
            </Query>
        )
    }
}
