import './TextAttention.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    show: boolean
}

export const TextAttention: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { children, className } = props
    const bem = new BEM('TextAttention', () => ({
        show: props.show,
    }))

    return <div className={bem.getClassName(className)}>{children}</div>
}
