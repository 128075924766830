import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { SignalingOverviewView } from './SignalingOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SignalingDetailView } from './SignalingDetailView'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Route, Routes } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class SignalingViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<SignalingOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).signaling.detail.view()}
                    element={<SignalingDetailView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const SignalingView = withRouter(SignalingViewComponent)
