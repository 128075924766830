import React from 'react'
import gql from 'graphql-tag'
import { PaginatableQuery, PaginationResult } from '~/components/Core/Pagination/PaginatableQuery'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { RiskType } from './EditRiskModal/EditRiskModal'
import { uniqBy } from 'lodash-es'

const LIST_RISK_TYPES = gql`
    query riskTypes($skip: Int, $take: Int, $filters: RiskTypesFilters) {
        riskTypes(skip: $skip, take: $take, filters: $filters) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`
interface State {
    search: string | null
}
interface Props {
    clearable?: boolean
    creatable?: boolean
    name?: string
    defaultValue?: SelectOption[]
    placeholder?: string
    disabled?: boolean
    onChange?: (option: SelectOption[]) => void
    onlyUsedInDepartment?: number
    multi?: boolean
}

export class RiskTypeSelect extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: '',
    }

    public render() {
        const {
            clearable,
            onlyUsedInDepartment,
            creatable,
            name,
            defaultValue,
            disabled,
            onChange,
            multi,
            placeholder,
        } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<RiskType>
                query={LIST_RISK_TYPES}
                variables={{ filters: { name: search, usedInDepartmentId: onlyUsedInDepartment } }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    const sources = this.mapSources(data)

                    return (
                        <Form.Select
                            name={name || 'riskType'}
                            searchable={true}
                            options={sources}
                            placeholder={placeholder}
                            creatable={creatable}
                            onEndReached={fetchMore}
                            clearable={clearable}
                            onSearch={value => this.setState({ search: value })}
                            loading={loading || loadingMore}
                            defaultValue={defaultValue}
                            isFilter={true}
                            disabled={disabled}
                            multi={multi}
                            onChange={
                                onChange
                                    ? (option: SelectOption[]) => {
                                          onChange(option)
                                      }
                                    : undefined
                            }
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private mapSources(data: PaginationResult<RiskType> | null) {
        const { defaultValue } = this.props

        if (!data || !data.nodes.length) {
            return []
        }

        if (!defaultValue) {
            return data.nodes.map(source => ({ label: source.name, value: source.id }))
        }

        return uniqBy(
            [...data.nodes.map(source => ({ label: source.name, value: source.id })), ...defaultValue],
            'value'
        )
    }
}
