import './AssessmentDetailList.scss'

import React from 'react'
import gql from 'graphql-tag'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { localize } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'

import { BEM, ClassValue } from '~/services/BEMService'

import { routes } from '~/views/routes'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { AssessmentDetailCard } from './AssessmentDetailCard'

interface Props {
    className?: ClassValue
    search?: string | null
}

const LIST_ASSESSMENTS_QUERY = gql`
    query assessments($filters: AssessmentsFilters, $take: Int, $skip: Int) {
        assessments(take: $take, skip: $skip, filters: $filters) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                description
                riskCount
                normCount
                controlCount
            }
        }
    }
`

export interface Assessment {
    id: number
    name: string
    description: string
    riskCount: number
    normCount: number
    controlCount: number
}

export class AssessmentDetailList extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('AssessmentDetailList')

    public render() {
        const { search, className } = this.props

        return (
            <InfiniteScrollQuery<Assessment>
                query={LIST_ASSESSMENTS_QUERY}
                variables={{
                    filters: {
                        name: search,
                        departmentId: this.context.activeDepartmentId,
                    },
                }}
            >
                {({ data, loading, loadingMore, canFetchMore, hasPaginated }) => {
                    const assessmentsData = data ? data.nodes : []

                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!loading && !assessmentsData.length) {
                        return (
                            <NoResults
                                label={
                                    search
                                        ? localize.translate(t => t.Generic.search.noResultsFor, {
                                              searchQuery: search,
                                          })
                                        : localize.translate(t => t.Customer.Compliance.Assessments.Overview.empty)
                                }
                            />
                        )
                    }

                    return (
                        <TableList className={this.bem.getClassName(className)} columns={3}>
                            {assessmentsData.map(assessment => (
                                <AssessmentDetailCard
                                    key={assessment.id}
                                    assessment={assessment}
                                    to={routes
                                        .customer(this.context.customer.slug)
                                        .risks.assessments.view(assessment.id)}
                                />
                            ))}
                        </TableList>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }
}
