import './CustomerFrameworksOptionsButton.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { OptionsDropdown } from '~/components/Core/OptionsDropdown/OptionsDropdown'
import {
    OptionsDropdownOption,
    OptionsDropdownOptionStyle,
} from '~/components/Core/OptionsDropdown/OptionsDropdownOption'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CreateCustomerFrameworkMultiStepModal } from './CreateCustomerFrameworkModal/CreateCustomerFrameworkMultiStepModal'
import { BEM } from '~/services/BEMService'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'

interface Props {
    handleOnEditClick: () => void
    customerFrameworks: CustomerFrameworkWithFramework[]
}

export class CustomerFrameworksOptionsButton extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks)
    private bem = new BEM('CustomerFrameworksOptionsButton')

    public render() {
        const { handleOnEditClick, customerFrameworks } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <OptionsDropdown className={this.bem.getClassName()}>
                        <OptionsDropdownOption
                            icon={IconType.settings}
                            label={this.loc(t => t.edit)}
                            onClick={() => handleOnEditClick()}
                        />
                        <OptionsDropdownOption
                            icon={IconType.add}
                            label={this.loc(t => t.addProfile)}
                            style={OptionsDropdownOptionStyle.primary}
                            newSection={true}
                            onClick={() => openModal()}
                            className={this.bem.getElement('new-option')}
                        />
                    </OptionsDropdown>
                )}
                renderModal={closeModal => (
                    <CreateCustomerFrameworkMultiStepModal
                        customerFrameworks={customerFrameworks}
                        closeModal={closeModal}
                    />
                )}
            />
        )
    }
}
