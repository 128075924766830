import './RiskGraphGrid.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export class RiskGraphGrid extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('RiskGraphGrid')

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('columns')}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className={this.bem.getElement('rows')}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        )
    }
}
