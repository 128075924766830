import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Center } from '~/components/Core/Layout/Center'
import { routes } from '../routes'
import { UserContextValue, UserContext } from '~/components/Providers/UserProvider'
import { Navigate } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{ legacyDocumentKey: string }> {}
interface State {}

const LEGACY_REDIRECT_QUERY = gql`
    query legacyRedirect($legacyDocumentKey: String!) {
        legacyRedirect(legacyDocumentKey: $legacyDocumentKey) {
            id
            type
        }
    }
`
interface LegacyRedirect {
    id: number
    type: string
}
interface LegacyRedirectQueryResponse {
    legacyRedirect?: LegacyRedirect
}

interface LegacyRedirectQueryVariables {
    legacyDocumentKey: string
}

class LegacyRedirectViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = UserContext
    public context: UserContextValue

    public render() {
        const { legacyDocumentKey } = this.props.match.params
        return (
            <Query<LegacyRedirectQueryResponse, LegacyRedirectQueryVariables>
                query={LEGACY_REDIRECT_QUERY}
                variables={{ legacyDocumentKey }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Center>
                                <Spinner delayed={true} />
                            </Center>
                        )
                    }

                    if (!data || !data.legacyRedirect) {
                        return <NoResults />
                    }

                    const route = this.getRoute(data.legacyRedirect)

                    if (!route) {
                        return <NoResults />
                    }

                    return <Navigate replace to={route} />
                }}
            </Query>
        )
    }

    private getRoute(data: LegacyRedirect) {
        const customerSlug = this.context.employee!.customer.slug

        if (data.type === 'Radar') {
            return routes.customer(customerSlug).radar.detail.view(data.id)
        }

        if (data.type === 'News') {
            return routes.customer(customerSlug).news.detail.view(data.id)
        }

        if (data.type === 'LawArticle') {
            return routes.customer(customerSlug).lawAndRegulationRedirect(data.id)
        }

        return null
    }
}

export const LegacyRedirectView = withRouter(LegacyRedirectViewComponent)
