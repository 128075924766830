import './AssessMonitoringReportTopicItemButton.scss'
import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Popper, PopperContentItem } from '~/components/Core/Popper/Popper'
import {
    MonitoringReportTopicAssessmentGrade,
    MonitoringReportType,
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { TopicAssessmentIcon } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { TopicAssessmentLabel } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentLabel'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { AssessMonitoringReportTopicModal } from './AssessMonitoringReportTopicModal'

interface Props {
    topicAssessmentId?: number
    assessmentType: TopicAssessmentDesignOrEffectiveNessType
    monitoringReportTopicId: number
    monitoringReport: Pick<MonitoringReportType, 'id' | 'name'>
    departmentId: number
    renderLabel: () => React.ReactNode
    topicId: number
    linkedItemId: number
    linkedItemType: TopicAssessmentDeLinkedItemType
    onAssess?: () => void
}

type OpenModalFn = (grade: MonitoringReportTopicAssessmentGrade) => void

export class AssessMonitoringReportTopicItemButton extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('AssessMonitoringReportTopicItemButton')
    private ref = React.createRef<Popper>()

    public render() {
        return (
            <ModalManager<MonitoringReportTopicAssessmentGrade>
                render={openModal => (
                    <Popper
                        ref={this.ref}
                        className={this.bem.getClassName()}
                        contents={this.getAssessmentOptions(openModal)}
                        position="top"
                        item={
                            <Button className={this.bem.getElement('button')} type={ButtonType.subtleIcon}>
                                <TopicAssessmentIcon />
                            </Button>
                        }
                    />
                )}
                renderModal={(closeModal, grade) => (
                    <AssessMonitoringReportTopicModal
                        requestClose={closeModal}
                        topicAssessmentId={this.props.topicAssessmentId}
                        assessmentType={this.props.assessmentType}
                        monitoringReportTopicId={this.props.monitoringReportTopicId}
                        linkedItemId={this.props.linkedItemId}
                        linkedItemType={this.props.linkedItemType}
                        departmentId={this.props.departmentId}
                        topicId={this.props.topicId}
                        renderLabel={this.props.renderLabel}
                        onAssess={this.props.onAssess}
                        grade={grade}
                        monitoringReport={this.props.monitoringReport}
                    />
                )}
            />
        )
    }

    private getAssessmentOptions(openModal: OpenModalFn): PopperContentItem[] {
        return [
            { item: this.renderAssessmentOption(openModal, MonitoringReportTopicAssessmentGrade.assessmentSatisfies) },
            {
                item: this.renderAssessmentOption(
                    openModal,
                    MonitoringReportTopicAssessmentGrade.assessmentAlmostSatisfies
                ),
            },
            {
                item: this.renderAssessmentOption(
                    openModal,
                    MonitoringReportTopicAssessmentGrade.assessmentNotSatisfies
                ),
            },
        ]
    }

    private renderAssessmentOption(openModal: OpenModalFn, grade: MonitoringReportTopicAssessmentGrade) {
        return (
            <Button
                className={this.bem.getElement('option')}
                type={ButtonType.tertiary}
                onClick={() => {
                    this.ref.current?.setState({ forceClose: true })
                    openModal(grade)
                }}
            >
                <TopicAssessmentLabel status={grade} />
            </Button>
        )
    }
}
