import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringSelectDocument, MonitoringSelectQueryVariables } from '~/generated/graphql'

interface Props {
    name: string
    defaultValue?: SelectOption
    disabled?: boolean
}

interface QueryResult {
    id: number
    name: string
}

export class MonitoringSelect extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { name, defaultValue, disabled } = this.props
        const placeholder = localize.translate(
            t => t.Customer.LegalFrameworkView.TopicAssessmentModal.monitoringReportPlaceholder
        )

        if (disabled) {
            if (!defaultValue) {
                return null
            }

            const options = this.getOptions([])
            return <Form.Select name={name} options={options} defaultValue={[defaultValue]} disabled />
        }

        return (
            <PaginatableQuery<QueryResult, MonitoringSelectQueryVariables>
                query={MonitoringSelectDocument}
                path="monitoringReports"
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key="loading-select" name={'loading'} loading={true} />
                    }

                    const options = this.getOptions(data.nodes)

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name}
                            options={options}
                            defaultValue={defaultValue ? [defaultValue] : undefined}
                            onEndReached={fetchMore}
                            clearable={true}
                            loading={loading || loadingMore}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    private getOptions(data: QueryResult[]) {
        const { defaultValue } = this.props
        const options = data.map(d => ({ label: d.name, value: d.id }))

        if (!defaultValue) {
            return options
        }

        const isInList = options.some(o => o.value === defaultValue.value)
        if (isInList) {
            return options
        }

        return [defaultValue, ...options]
    }
}
