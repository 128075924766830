import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerFeedsListView } from './CustomerFeedsListView'
import { CustomerFeedDetailView } from './CustomerFeedDetailView'
import { Routes, Route } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

export class CustomerFeedsView extends React.PureComponent {
    public render() {
        return (
            <Routes>
                <Route path={''} element={<CustomerFeedsListView />} />
                <Route path={newRoutes.consultant.customerfeeds.view()} element={<CustomerFeedDetailView />} />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}
