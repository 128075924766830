import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    MarkTopicAsNonApplicableFormMutation,
    MarkTopicAsNonApplicableFormMutationFN,
} from './MarkTopicAsNonApplicableFormMutation'

interface Props {
    topicId: number
    onSave: () => void
    requestClose: () => void
}

export class MarkTopicAsNonApplicableModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.MarkTopicAsNonApplicableModal)

    public render() {
        const { requestClose } = this.props

        return (
            <MarkTopicAsNonApplicableFormMutation>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            title={this.loc(t => t.title)}
                            requestClose={requestClose}
                            confirmButtonLabel={this.loc(t => t.confirmButtonLabel)}
                            submitForm={true}
                            loading={loading}
                        >
                            {this.renderModalContent()}
                        </Modal>
                    </Form>
                )}
            </MarkTopicAsNonApplicableFormMutation>
        )
    }

    private handleSubmit = (mutate: MarkTopicAsNonApplicableFormMutationFN) => async (formState: FormState) => {
        const { topicId, onSave, requestClose } = this.props

        const response = await mutate({
            variables: { markedAsApplicable: [], markedAsNonApplicable: [{ topicId, reason: formState.description }] },
        })

        if (response && response.data) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            onSave()
            requestClose()
        }
    }

    private renderModalContent() {
        return (
            <Column>
                <Paragraph>{this.loc(t => t.description)}</Paragraph>
                <Field forInput="markedAsNonApplicable" />
                <Paragraph bold={true}>{localize.translate(t => t.Generic.explanation)}</Paragraph>
                <Form.Input
                    name="description"
                    type="textarea"
                    placeholder={`${localize.translate(t => t.Generic.explanation)}...`}
                />
            </Column>
        )
    }
}
