import './CustomerFeedFormFields.scss'

import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { CustomerSelect } from '~/components/Domain/Customer/CustomerSelect'
import { CustomerFeedTypeSelectField } from './CustomerFeedTypeSelectField'
import { CustomerFeedFrequencySelect } from './CustomerFeedFrequencySelect'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'
import { CustomerFeedStatus } from './CustomerFeedStatus'
import { localize } from '~/bootstrap'
import { BEM } from '~/services/BEMService'
import {
    CustomerSubscribedToFeedType,
    CustomerSubscribedToFeedTypeFrequencyTypeEnumType,
    CustomerSubscribedToFeedTypeTypesEnumType,
} from '~/generated/graphql'
import { getHours, getMinutes } from 'date-fns'

export enum CustomerFeedField {
    status = 'status',
    customer = 'customer',
    type = 'type',
    frequency = 'frequency',
    time = 'time',
    recipients = 'recipients',
}

export enum CustomerFeedStatusOption {
    enabled = 'enabled',
    disabled = 'disabled',
}

export interface CustomerFeedForm {
    status?: { value: CustomerFeedStatusOption }
    customer?: { value: number }
    frequency?: string
    frequencyType?: { value: CustomerSubscribedToFeedTypeFrequencyTypeEnumType }
    recipientEmails?: string[]
    startDate?: Date
    time?: { hours: number; minutes: number }
    type?: { value: CustomerSubscribedToFeedTypeTypesEnumType }
}

interface Props {
    defaultValues?: CustomerSubscribedToFeedType
    disabledFields?: CustomerFeedField[]
}

export class CustomerFeedFormFields extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.properties)
    private bem = new BEM('CustomerFeedFormFields')

    private statusOptions = [
        { value: CustomerFeedStatusOption.enabled, label: <CustomerFeedStatus isActive={true} /> },
        { value: CustomerFeedStatusOption.disabled, label: <CustomerFeedStatus isActive={false} /> },
    ]

    public render() {
        return (
            <Column smallSpacing={true}>
                {this.renderStatusField()}
                {this.renderCustomerField()}
                {this.renderTypeField()}
                {this.renderFrequencyField()}
                {this.renderTimeField()}
                {this.renderRecipientsField()}
            </Column>
        )
    }

    private renderStatusField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.status)
        const defaultOption = defaultValues?.isActive === false ? this.statusOptions[1] : this.statusOptions[0]

        return (
            <Field label={this.loc(t => t.status)} forInput="status">
                <Form.Select
                    disabled={isDisabled}
                    name="status"
                    defaultValue={[defaultOption]}
                    options={this.statusOptions}
                />
            </Field>
        )
    }

    private renderCustomerField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.customer)
        const placeholder = localize.translate(t => t.Consultant.CustomerFeeds.placeholders.customer)

        return (
            <Field label={this.loc(t => t.customer)} forInput="customer">
                <CustomerSelect
                    disabled={isDisabled}
                    name="customer"
                    placeholder={placeholder}
                    defaultCustomer={defaultValues?.customer}
                />
            </Field>
        )
    }

    private renderTypeField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.type)
        const defaultSettings = defaultValues?.settings ? JSON.parse(defaultValues.settings) : undefined

        return (
            <CustomerFeedTypeSelectField
                disabled={isDisabled}
                defaultType={defaultValues?.type}
                defaultSettings={defaultSettings}
            />
        )
    }

    private renderFrequencyField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.frequency)

        return (
            <Field label={this.loc(t => t.frequency)} forInput="frequency">
                <CustomerFeedFrequencySelect
                    disabled={isDisabled}
                    defaultFrequency={defaultValues?.frequency}
                    defaultFrequencyType={defaultValues?.frequencyType}
                    defaultStartDate={defaultValues?.startDate}
                />
            </Field>
        )
    }

    private renderTimeField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.time)

        const defaultStartAt = defaultValues ? new Date(defaultValues.startDate) : undefined
        const defaultValue = defaultStartAt
            ? { hours: getHours(defaultStartAt), minutes: getMinutes(defaultStartAt) }
            : undefined

        return (
            <Field label={this.loc(t => t.time)} forInput="time">
                <Row>
                    <Paragraph bold={true} verySubtle={true} className={this.bem.getElement('time-on')}>
                        {this.loc(t => t.on)}
                    </Paragraph>
                    <Form.TimeInput name="time" disabled={isDisabled} defaultValue={defaultValue} />
                    <Paragraph bold={true} verySubtle={true}>
                        {this.loc(t => t.hour)}
                    </Paragraph>
                </Row>
            </Field>
        )
    }

    private renderRecipientsField() {
        const { disabledFields, defaultValues } = this.props
        const isDisabled = disabledFields?.includes(CustomerFeedField.recipients)

        return (
            <Field forInput="recipientEmails" label={this.loc(t => t.recipients)}>
                <Form.MultiEmailInput
                    name="recipientEmails"
                    disabled={isDisabled}
                    defaultValue={defaultValues?.recipientEmails}
                />
            </Field>
        )
    }
}
