import './TopicAssessmentIcon.scss'

import React from 'react'
import { Icon } from '~/components/Core/Icon/Icon'
import { BEM } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import {
    TopicAssessmentGradeType,
    DepartmentTopicDataGradeTypeEnum,
    MonitoringReportTopicAssessmentGrade,
} from '~/generated/graphql'

interface Props {
    status?: TopicAssessmentGradeType | DepartmentTopicDataGradeTypeEnum | MonitoringReportTopicAssessmentGrade
}

export class TopicAssessmentIcon extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('TopicAssessmentIcon', () => ({
        [`is-${this.props.status}`]: !!this.props.status,
        [DepartmentTopicDataGradeTypeEnum.assessmentNotGiven]: !this.props.status,
    }))

    public render() {
        const { status } = this.props
        const type = status ? IconType[status] : IconType.assessmentNotGiven

        return <Icon className={this.bem.getClassName()} type={type} />
    }
}
