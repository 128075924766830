import './CustomerGreeting.scss'

import React from 'react'

import { Row } from '~/components/Core/Layout/Row'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import DashboardMainBandIllustration from '~/views/Customer/Dashboard/DashboardMainBandIllustration.png'
import { Column } from '~/components/Core/Layout/Column'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { getGreetingForTimeOfDay } from '~/utils/greetUser'
import { localize } from '~/bootstrap'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    newMessageCount?: number
}

interface State {}

export class CustomerGreeting extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerGreeting')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView)

    public render() {
        return (
            <Row wrap={true} className={this.bem.getElement('greeting-container')}>
                <figure className={this.bem.getElement('figure')}>
                    <img src={DashboardMainBandIllustration} alt={this.loc(t => t.mainIllustrationAlt)} />
                </figure>
                <Column smallSpacing={true}>
                    <PageTitle
                        className={this.bem.getElement('greeting')}
                        documentTitle={'Ruler'}
                        title={getGreetingForTimeOfDay()}
                    />
                    {this.renderSubtext()}
                </Column>
            </Row>
        )
    }

    private renderSubtext() {
        const { newMessageCount } = this.props

        const text =
            newMessageCount !== undefined
                ? this.loc(t => t.newMessageText, { smart_count: newMessageCount })
                : this.context.customer.name

        return (
            <Paragraph subtle bold large>
                {text}
            </Paragraph>
        )
    }
}
