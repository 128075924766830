import React from 'react'
import { InboxItemQuery, InboxAlertFilters, InboxAlert } from './InboxItemQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { localize, permissions } from '~/bootstrap'
import { ZeroInbox } from '~/components/Chrome/ZeroInbox/ZeroInbox'
import { InboxItem } from './InboxItem'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BulkAlertActionContainer } from '~/components/Domain/Inbox/BulkAlertActionContainer'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'

interface Props {
    filters: InboxAlertFilters
}

interface State {
    toBeArchivedIds: number[]
    isCheckboxActive: boolean
}

export class InboxItemContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public refetch: () => void
    public state: State = {
        toBeArchivedIds: [],
        isCheckboxActive: false,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)
    private showActions = permissions.canArchiveInboxItems(this.context.activeDepartmentId)

    public render() {
        const { filters } = this.props
        const { toBeArchivedIds } = this.state
        return (
            <>
                <BulkAlertActionContainer
                    alertIds={toBeArchivedIds}
                    onClearSelection={() => this.setState({ toBeArchivedIds: [], isCheckboxActive: false })}
                />
                <InboxItemQuery filters={filters}>
                    {({ data, loading, loadingMore, refetch }) => {
                        const items = data ? data.nodes : []
                        this.refetch = refetch

                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        if (this.shouldRenderEmptyState(items)) {
                            return <NoResults />
                        }

                        if (this.shouldRenderZeroInboxState(items)) {
                            return (
                                <ZeroInbox
                                    title={this.loc(t => t.everythingIsDone)}
                                    label={this.loc(t => t.checkEmail)}
                                />
                            )
                        }

                        return (
                            <ItemListSpacer>
                                {items
                                    .filter(item => {
                                        if (
                                            !filters.isArchivedFromInboxForDepartment &&
                                            !filters.hasTasksForDepartment
                                        ) {
                                            return item.inboxStatus ? !item.inboxStatus.archivedAt : true
                                        }

                                        return true
                                    })
                                    .map((item, index) => (
                                        <li key={item.id}>
                                            <InboxItem
                                                isCheckboxActive={this.state.isCheckboxActive}
                                                isFirstItem={index === 0}
                                                handleOnCardCheck={
                                                    this.showActions ? this.handleOnInboxItemCheck(item) : undefined
                                                }
                                                checked={toBeArchivedIds.includes(item.id)}
                                                inboxItem={item}
                                                refetch={refetch}
                                                infoActionIconOnly={
                                                    filters.hasTasksForDepartment ||
                                                    filters.isArchivedFromInboxForDepartment
                                                }
                                            />
                                        </li>
                                    ))}
                                {loadingMore && (
                                    <li>
                                        <Spinner />
                                    </li>
                                )}
                            </ItemListSpacer>
                        )
                    }}
                </InboxItemQuery>
            </>
        )
    }

    private handleOnInboxItemCheck = (item: InboxAlert) => (checked: boolean) => {
        const { toBeArchivedIds } = this.state

        if (checked) {
            this.setState({
                toBeArchivedIds: [...toBeArchivedIds, item.id],
                isCheckboxActive: true,
            })
            return
        }

        const filteredArchivedIds = toBeArchivedIds.filter(id => id !== item.id)
        const isCheckboxActive = filteredArchivedIds.length > 0

        this.setState({
            toBeArchivedIds: toBeArchivedIds.filter(id => id !== item.id),
            isCheckboxActive,
        })
    }

    private shouldRenderEmptyState(items: InboxAlert[]) {
        const { filters } = this.props
        const { search, isArchivedFromInboxForDepartment, hasTasksForDepartment, newsGroupIds } = filters

        if (items.length === 0) {
            if (search) {
                return true
            }

            if (isArchivedFromInboxForDepartment) {
                return true
            }

            if (hasTasksForDepartment) {
                return true
            }

            if (newsGroupIds && newsGroupIds.length > 0) {
                return true
            }
        }

        return false
    }

    private shouldRenderZeroInboxState(items: InboxAlert[]) {
        const { search, isArchivedFromInboxForDepartment, hasTasksForDepartment, newsGroupIds } = this.props.filters

        const hasResults = items.length > 0

        const hasFilteredOnNewsgroup = newsGroupIds && newsGroupIds.length > 0
        const hasNoFiltersActive =
            !search && !hasFilteredOnNewsgroup && !isArchivedFromInboxForDepartment && !hasTasksForDepartment

        if (!hasResults && hasNoFiltersActive) {
            return true
        }

        return false
    }
}
