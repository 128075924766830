import './TopicDesignAndEffectivenessTable.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { BEM } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { TopicAssessmentIconContainer } from './TopicAssessmentIconContainer'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import {
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicControlMeasureType,
    TopicNoteType,
} from '~/generated/graphql'
import { RemoveTopicNoteModal } from './modals/RemoveTopicNoteModal'
import { isNumber } from 'lodash'
import { TopicDesignAndEffectivenessLinkedArticlesModalContainer } from './modals/TopicDesignAndEffectivenessLinkedArticlesModalContainer'
import { RemoveTopicControlModal } from './modals/RemoveTopicControlModal'
import { Row } from '~/components/Core/Layout/Row'
import { TopicDesignAndEffectivenessLogsModal } from './modals/TopicDesignAndEffectivenessLogsModal'

interface TopicDesignAndEffectivenessTableRowItem {
    nameLabel: React.ReactNode
    linkedItem: TopicNoteType | TopicControlMeasureType
}

interface Props {
    topicId: number
    rowItems: TopicDesignAndEffectivenessTableRowItem[]
    onChange?: () => void
}

export class TopicDesignAndEffectivenessTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicDesignAndEffectivenessTable')
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)

    private columns: ColumnOptions[] = [
        {
            field: 'name',
            headerLabel: this.loc(t => t.measures),
        },
        { field: 'articles', headerLabel: this.loc(t => t.articlesColumn) },
        { field: 'design', headerLabel: this.loc(t => t.design), align: 'center' },
        { field: 'effectiveness', headerLabel: this.loc(t => t.effectiveness), align: 'center' },
        { field: 'log', headerLabel: this.loc(t => t.log) },
        { field: 'remove', headerLabel: '' },
    ]

    public render() {
        return <Table className={this.bem.getClassName()} columns={this.columns} data={this.getTableData()} />
    }

    private getTableData(): RowData[] {
        const { rowItems } = this.props

        return rowItems.map(rowItem => {
            const type =
                rowItem.linkedItem.__typename === 'TopicNoteType'
                    ? TopicAssessmentDeLinkedItemType.note
                    : TopicAssessmentDeLinkedItemType.controlmeasure
            return {
                id: rowItem.linkedItem.id,
                columns: {
                    name: rowItem.nameLabel,
                    articles: this.renderArticleCount(rowItem.linkedItem, type),
                    design: this.renderAssessmentIcon(rowItem, TopicAssessmentDesignOrEffectiveNessType.design, type),
                    effectiveness: this.renderAssessmentIcon(
                        rowItem,
                        TopicAssessmentDesignOrEffectiveNessType.effectiveness,
                        type
                    ),
                    log: this.renderLogIcon(rowItem.linkedItem.id, type),
                    remove: this.renderRemoveButton(rowItem.linkedItem, type),
                },
            }
        })
    }

    private renderAssessmentIcon(
        rowItem: TopicDesignAndEffectivenessTableRowItem,
        dneType: TopicAssessmentDesignOrEffectiveNessType,
        type: TopicAssessmentDeLinkedItemType
    ) {
        const { topicId, onChange } = this.props
        const { nameLabel, linkedItem } = rowItem
        const { assessments } = linkedItem

        const assessment = assessments?.find(a => a.topicAssessmentDesignOrEffectiveNessType === dneType)
        const hasLinkedControls =
            'linkedParentTopicControlMeasures' in linkedItem
                ? !!linkedItem.linkedChildTopicControlMeasures?.length ||
                  !!linkedItem.linkedParentTopicControlMeasures?.length ||
                  !!linkedItem.linkedNestedChildTopicControlMeasures?.length ||
                  !!linkedItem.linkedNestedParentTopicControlMeasures?.length
                : undefined

        return (
            <Row alignCenter={true}>
                <TopicAssessmentIconContainer
                    linkedItem={linkedItem}
                    linkedItemLabel={nameLabel}
                    linkedItemType={type}
                    topicId={topicId}
                    assessmentType={dneType}
                    assessment={assessment}
                    hasLinkedControls={hasLinkedControls}
                    onChange={onChange}
                />
            </Row>
        )
    }

    private renderArticleCount(
        linkedItem: TopicNoteType | TopicControlMeasureType,
        type: TopicAssessmentDeLinkedItemType
    ) {
        const { topicId, onChange } = this.props
        const { id, articles, articlesAreAll } = linkedItem

        const selectedArticleIds = articles.map(a => a.id)
        const controlType = 'control' in linkedItem ? linkedItem.control.type : undefined
        const expiredArticleCount = articles.filter(a => a.expired?.expiredAt).length

        return (
            <TopicDesignAndEffectivenessLinkedArticlesModalContainer
                itemId={id}
                itemType={type}
                controlType={controlType}
                topicId={topicId}
                selectedIds={selectedArticleIds}
                linkedAll={articlesAreAll}
                onChange={onChange}
                expiredArticleCount={expiredArticleCount}
            />
        )
    }

    private renderLogIcon(linkedItemId: number, type: TopicAssessmentDeLinkedItemType) {
        return (
            <ModalManager
                render={openModal => (
                    <Button
                        icon={IconType.clock}
                        onClick={openModal}
                        type={ButtonType.subtle}
                        className={this.bem.getElement('log-icon')}
                    />
                )}
                renderModal={closeModal => (
                    <TopicDesignAndEffectivenessLogsModal
                        linkedItemId={linkedItemId}
                        linkedItemType={type}
                        requestClose={closeModal}
                    />
                )}
            />
        )
    }

    private renderRemoveButton(
        linkedItem: TopicNoteType | TopicControlMeasureType,
        type: TopicAssessmentDeLinkedItemType
    ) {
        const notAllowed = !permissions.canEditTopicDnE(this.context.activeDepartmentId)
        if (notAllowed) {
            return null
        }

        const { topicId, onChange } = this.props
        const { id } = linkedItem
        const controlId = 'control' in linkedItem ? linkedItem.control.id : undefined

        if (type === TopicAssessmentDeLinkedItemType.controlmeasure && !isNumber(controlId)) {
            return null
        }

        const tooltipMessage =
            type === TopicAssessmentDeLinkedItemType.controlmeasure
                ? this.loc(t => t.removeControl)
                : this.loc(t => t.removeAssessment)

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip message={tooltipMessage}>
                        <Button
                            className={this.bem.getElement('remove-button')}
                            type={ButtonType.actionLink}
                            icon={IconType.close}
                            onClick={openModal}
                        />
                    </Tooltip>
                )}
                renderModal={closeModal =>
                    type === TopicAssessmentDeLinkedItemType.controlmeasure ? (
                        <RemoveTopicControlModal
                            onRemove={onChange}
                            requestClose={closeModal}
                            topicId={topicId}
                            controlId={controlId!}
                        />
                    ) : (
                        <RemoveTopicNoteModal
                            onRemove={onChange}
                            requestClose={closeModal}
                            topicId={topicId}
                            assessmentId={id}
                        />
                    )
                }
            />
        )
    }
}
