import './MultiCustomerEmployeeEmailChangeModal.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'
import { Radio } from '~/components/Core/DataEntry/Form/Radio'

interface Props {
    requestClose: () => void
    onSubmit: (applyToAllUsers: boolean) => void
}

interface State {
    applyToAllUsers: boolean
}

export class MultiCustomerEmployeeEmailChangeModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        applyToAllUsers: true,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.MultiCustomerEmployeeEmailChangeModal)
    private bem = new BEM('MultiCustomerEmployeeEmailChangeModal')

    public render() {
        const { requestClose, onSubmit } = this.props
        const { applyToAllUsers } = this.state

        return (
            <Modal
                title={this.loc(t => t.title)}
                confirmButtonLabel={this.loc(t => t.confirm)}
                className={this.bem.getClassName()}
                requestClose={requestClose}
                onAction={() => onSubmit(applyToAllUsers)}
                modalSize={ModalSize.small}
            >
                <Column>
                    <Paragraph>{this.loc(t => t.description)}</Paragraph>
                    <Column extraSmallSpacing={true}>
                        <Radio
                            name="emailChanged"
                            onChange={this.handleChange}
                            value={true}
                            label={this.loc(t => t.applyToAll)}
                            defaultChecked={this.state.applyToAllUsers}
                            className={this.bem.getElement('radio', () => ({
                                checked: applyToAllUsers,
                            }))}
                        />
                        <Radio
                            name="emailChanged"
                            value={false}
                            onChange={this.handleChange}
                            label={this.loc(t => t.applyToCurrent)}
                            defaultChecked={!this.state.applyToAllUsers}
                            className={this.bem.getElement('radio', () => ({
                                checked: !applyToAllUsers,
                            }))}
                        />
                    </Column>
                </Column>
            </Modal>
        )
    }

    private handleChange = (checked: boolean, value: string) => {
        this.setState({ applyToAllUsers: checked })
    }
}
