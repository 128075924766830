import './ThemesDetailItemsContainer.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { ThemesDetailCardTopicType } from './ThemesDetailCardListQuery'
import { CardList } from '~/components/Core/DataDisplay/CardList/CardList'
import { ThemesDetailCard } from './ThemesDetailCard'
import { permissions } from '~/bootstrap'

interface Props {
    topics: ThemesDetailCardTopicType[]
    onPin: (topicId: number) => void
    onUnpin: () => void
}

export class ThemesDetailItemsContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ThemesDetailItemsContainer')

    public render() {
        const { topics } = this.props

        const notAllowedToPin = !permissions.isCustomerUser()

        return (
            <div className={this.bem.getClassName()}>
                <CardList className={this.bem.getElement('grid-container')} columns={3}>
                    {topics.map(topic => (
                        <ThemesDetailCard
                            key={`topic-${topic.id}`}
                            topic={topic}
                            togglePinned={() => this.handlePinToggle(topic.id, topic.isPinned)}
                            isPinned={false}
                            notAllowedToPin={notAllowedToPin}
                        />
                    ))}
                </CardList>
            </div>
        )
    }

    private handlePinToggle(topicId: number, isPinned: boolean) {
        const { onPin, onUnpin } = this.props

        if (!isPinned) {
            onPin(topicId)
            return
        }

        onUnpin()
    }
}
