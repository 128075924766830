import './DepartmentsTableRowContainer.scss'

import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { MultiDepthTableRowItem } from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTable'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { IconType } from '~/components/Core/Icon/IconType'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerFrameworkColorEnumType, CustomerFrameworkIconEnumType, DepartmentType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { FrameworkIcon } from '../../Customer/Framework/FrameworkIcon/FrameworkIcon'
import { ColumnItemCheckedStateEnum } from '../../CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableContainer'
import { TopicPreviewModal } from '../../Topic/TopicPreviewModal'
import { DepartmentTableRowWarningText, RowWarningState } from '../DepartmentTableRowWarningText'
import { DepartmentsTableColumnItem } from './DepartmentsTableContainer'

interface Props {
    allRowItems: DepartmentFrameworkRowItem[]
    rowItem: DepartmentFrameworkRowItem
    maxDepth: number
    editable: boolean
    currentPage: number
    pageItemCount: number
    rowItemsWithNewWarnings: RowItemWithNewWarning[]
    departments: DepartmentType[]
    columnItems: DepartmentsTableColumnItem[]
    onMouseEvent: (rowItemId: string, isHidden: boolean, bem: BEM<object>, isLeaving?: boolean) => void
    onCheckboxChange: (rowItem: DepartmentFrameworkRowItem, index: number, checked: boolean) => void
    onIgnoreSubmit: () => void
    icon?: CustomerFrameworkIconEnumType
    color?: CustomerFrameworkColorEnumType
}

export interface RowItemWithNewWarning {
    uniqId: string
    parentUniqId: string
    yIndex: number
    isExpanded?: boolean
    newItemCount: number
    elementRef: React.RefObject<HTMLDivElement>
}

export interface DepartmentFrameworkRowItem extends MultiDepthTableRowItem {
    uniqId: string
    parentUniqId: string
    customerFrameworkId: number
    children?: DepartmentFrameworkRowItem[]
    isNew?: boolean
    addedAt?: Date | null
}

export class DepartmentsTableRowContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('DepartmentsTableRowContainer')

    public render() {
        const { rowItem, icon, color, maxDepth, editable, onIgnoreSubmit } = this.props
        const { children, yIndex, id, customerFrameworkId, name, addedAt } = rowItem

        const isEmptyMidParent = !children?.length && yIndex > 0 && yIndex !== 2

        const checkboxStates = isEmptyMidParent ? [] : this.getRowCheckboxStates(rowItem)
        const warningState = this.getRowWarningState(rowItem)
        const elementRef = this.getElementRefAndUpdateWidget(rowItem, warningState)

        return (
            <div ref={elementRef} className={this.bem.getClassName()}>
                <Row
                    spaceBetween={true}
                    className={this.bem.getElement('row', () => ({
                        'empty-mid-parent': isEmptyMidParent,
                        'is-max-depth': yIndex === 2,
                    }))}
                >
                    {yIndex === 2 ? (
                        <TopicPreviewModal topicId={id}>
                            {openModal => (
                                <Button type={ButtonType.noStyling} onClick={openModal}>
                                    <Tooltip message={name}>
                                        <div className={this.bem.getElement('label-container')}>
                                            <Paragraph className={this.bem.getElement('label')}>
                                                {this.renderWarning(warningState)}
                                                {name}
                                            </Paragraph>
                                        </div>
                                    </Tooltip>
                                    <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                                </Button>
                            )}
                        </TopicPreviewModal>
                    ) : (
                        <Tooltip message={name}>
                            <div className={this.bem.getElement('label-container')}>
                                <Paragraph className={this.bem.getElement('label')} bold={true}>
                                    {this.renderWarning(warningState)}
                                    {icon && color && (
                                        <FrameworkIcon frameworkType={icon} color={color} isPaleSaturated={true} />
                                    )}
                                    {name}
                                </Paragraph>
                            </div>
                        </Tooltip>
                    )}
                    <div className={this.bem.getElement('checkbox-list', () => ({ [`is-level-${yIndex}`]: true }))}>
                        {checkboxStates.map((checkboxState, index) =>
                            this.renderCheckbox(rowItem, checkboxState, index)
                        )}
                    </div>
                </Row>
                {yIndex === maxDepth && (
                    <DepartmentTableRowWarningText
                        warningState={warningState}
                        addedAt={addedAt}
                        customerFrameworkId={customerFrameworkId}
                        topicId={id}
                        showIgnoreButton={editable}
                        onSubmit={() => onIgnoreSubmit()}
                    />
                )}
            </div>
        )
    }

    private getRowCheckboxStates(rowItem: DepartmentFrameworkRowItem): ColumnItemCheckedStateEnum[] {
        const { currentPage, pageItemCount } = this.props
        const { customerFrameworkId } = rowItem

        const range = this.getCurrentRange()
        let index = (currentPage - 1) * pageItemCount
        const checkboxStates: ColumnItemCheckedStateEnum[] = []

        for (; index < range; index++) {
            let columns: DepartmentsTableColumnItem[]

            if (rowItem.children) {
                columns = this.getCellColumns(index, [rowItem.id], rowItem.yIndex + 1, customerFrameworkId)
            } else {
                columns = this.getColumnState(index, rowItem.id, customerFrameworkId)
            }

            let checkedCount: number = 0
            let uncheckedCount: number = 0
            let indeterminateCount: number = 0

            columns.forEach(({ checkedState }) => {
                switch (checkedState) {
                    case ColumnItemCheckedStateEnum.checked:
                        checkedCount++
                        break
                    case ColumnItemCheckedStateEnum.unChecked:
                        uncheckedCount++
                        break
                    case ColumnItemCheckedStateEnum.indeterminate:
                        indeterminateCount++
                        break
                    default:
                        break
                }
            })

            if (checkedCount && !uncheckedCount && !indeterminateCount) {
                checkboxStates.push(ColumnItemCheckedStateEnum.checked)
                continue
            }

            if (!checkedCount && uncheckedCount && !indeterminateCount) {
                checkboxStates.push(ColumnItemCheckedStateEnum.unChecked)
                continue
            }

            checkboxStates.push(ColumnItemCheckedStateEnum.indeterminate)
        }

        return checkboxStates
    }

    private getRowWarningState(rowItem: DepartmentFrameworkRowItem): RowWarningState {
        const { yIndex, children } = rowItem

        if (yIndex === 2) {
            return this.getRowWarningStatesFromBottomLevel(rowItem)
        }

        if (children?.length) {
            if (yIndex === 1) {
                return this.getRowWarningStatesFromMidLevel(children)
            }

            if (yIndex === 0) {
                return this.getRowWarningStatesFromTopLevel(children)
            }
        }

        // row should not show any warnings as it has no children
        return { shouldShowNewWarning: false, shouldShowUncheckedWarning: false }
    }

    private getElementRefAndUpdateWidget(rowItem: DepartmentFrameworkRowItem, warningState: RowWarningState) {
        let elementRef: React.RefObject<HTMLDivElement> | undefined = undefined
        const { children, yIndex, uniqId, parentUniqId } = rowItem
        const { rowItemsWithNewWarnings } = this.props

        if (warningState.shouldShowNewWarning) {
            elementRef = React.createRef<HTMLDivElement>()
            const existingItem = rowItemsWithNewWarnings.find(item => item.uniqId === uniqId)

            if (!existingItem) {
                rowItemsWithNewWarnings.push({
                    uniqId,
                    yIndex,
                    parentUniqId,
                    isExpanded: children?.length ? false : undefined,
                    newItemCount: yIndex !== 2 ? this.getChildrenNewItemCount(rowItem) : 1,
                    elementRef,
                })
            }

            if (existingItem) {
                elementRef = existingItem.elementRef
            }

            return elementRef
        }

        return elementRef
    }

    private renderWarning(warningState: RowWarningState) {
        const { shouldShowNewWarning, shouldShowUncheckedWarning } = warningState

        if (!shouldShowNewWarning && !shouldShowUncheckedWarning) {
            return null
        }

        if (shouldShowNewWarning) {
            return <AttentionIcon roundIcon={true} />
        }

        return <AttentionIcon />
    }

    private renderCheckbox = (
        rowItem: DepartmentFrameworkRowItem,
        checkboxState: ColumnItemCheckedStateEnum,
        index: number
    ) => {
        const { editable, onMouseEvent, onCheckboxChange } = this.props
        const isDisabled = !editable

        const isChecked = checkboxState === ColumnItemCheckedStateEnum.checked
        const isIndeterminate = checkboxState === ColumnItemCheckedStateEnum.indeterminate
        const isUnchecked = checkboxState === ColumnItemCheckedStateEnum.unChecked
        const shouldHideCheckbox = isDisabled && isUnchecked

        const { customerFrameworkId, id, yIndex } = rowItem
        const checkboxId = `${customerFrameworkId}-${id}-${index}-${yIndex}`

        return (
            <div
                key={`${name}-column-${index}`}
                id={checkboxId}
                className={this.bem.getElement('checkbox-container')}
                onMouseEnter={() => onMouseEvent(checkboxId, shouldHideCheckbox, this.bem)}
                onMouseLeave={() => onMouseEvent(checkboxId, shouldHideCheckbox, this.bem, true)}
            >
                <Checkbox
                    className={this.bem.getElement('checkbox', () => ({
                        'is-hidden': shouldHideCheckbox,
                        [`is-level-${yIndex}`]: true,
                    }))}
                    name={name}
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    disabled={isDisabled}
                    large={true}
                    onChange={(checked, name) => onCheckboxChange(rowItem, index, checked)}
                    animationDisabled={true}
                />
            </div>
        )
    }

    private getCurrentRange() {
        const { departments, currentPage, pageItemCount } = this.props

        const totalRange = departments.length
        const range = currentPage * pageItemCount

        return range > totalRange ? totalRange : range
    }

    private getCellColumns(
        xIndex: number,
        parentIds: number[],
        yIndex: number,
        customerFrameworkId: number,
        acc: DepartmentsTableColumnItem[] = []
    ): DepartmentsTableColumnItem[] {
        const { columnItems, maxDepth, allRowItems } = this.props

        const isOnMiddleYIndex = yIndex > 0 && yIndex < maxDepth
        let columns: DepartmentsTableColumnItem[]

        if (isOnMiddleYIndex) {
            const rowItems = allRowItems.filter(rowItem => parentIds.includes(rowItem.id))
            const childRowItems: DepartmentFrameworkRowItem[] = []
            rowItems.forEach(({ children }) => {
                if (children?.length) {
                    childRowItems.push(...children)
                }
            })

            const newParentIds = childRowItems.map(item => item.id)
            return this.getCellColumns(xIndex, newParentIds, yIndex + 1, customerFrameworkId, acc)
        } else {
            columns = columnItems.filter(
                item =>
                    parentIds.includes(item.parentId) &&
                    item.xIndex === xIndex &&
                    item.yIndex === yIndex &&
                    item.customerFrameworkId === customerFrameworkId
            )
        }

        if (!columns.length) {
            return acc
        }

        const newAcc = [...acc, ...columns]
        const newParentIds = columns.map(column => column.rowItemId)

        return this.getCellColumns(xIndex, newParentIds, yIndex + 1, customerFrameworkId, newAcc)
    }

    private getColumnState(xIndex: number, rowItemId: number, customerFrameworkId: number) {
        const { columnItems } = this.props

        const column = columnItems.find(
            item =>
                item.xIndex === xIndex &&
                item.rowItemId === rowItemId &&
                item.customerFrameworkId === customerFrameworkId
        )

        if (!column) {
            return []
        }

        return [column]
    }

    private getRowWarningStatesFromBottomLevel(rowItem: DepartmentFrameworkRowItem) {
        const { columnItems } = this.props
        const { customerFrameworkId, id, isNew } = rowItem

        if (isNew) {
            return { shouldShowNewWarning: true, shouldShowUncheckedWarning: false }
        }

        let shouldShowUncheckedWarning = false
        let hasCheckedItem = false
        let hasUnCheckedItem = false

        const checkboxStates: ColumnItemCheckedStateEnum[] = []
        for (const item of columnItems) {
            if (item.rowItemId === id && item.customerFrameworkId === customerFrameworkId) {
                checkboxStates.push(item.checkedState)
            }
        }

        checkboxStates.forEach(checkboxState => {
            if (checkboxState === ColumnItemCheckedStateEnum.checked) {
                hasCheckedItem = true
                return
            }

            if (checkboxState === ColumnItemCheckedStateEnum.unChecked) {
                hasUnCheckedItem = true
                return
            }
        })

        shouldShowUncheckedWarning = hasUnCheckedItem && !hasCheckedItem

        return { shouldShowNewWarning: false, shouldShowUncheckedWarning }
    }

    private getRowWarningStatesFromMidLevel(children: DepartmentFrameworkRowItem[]) {
        let shouldShowNewWarning: boolean = false
        let shouldShowUncheckedWarning: boolean = false
        const uncheckedRowWarnings: boolean[] = []

        for (const child of children) {
            const childWarningState = this.getRowWarningStatesFromBottomLevel(child)

            if (childWarningState.shouldShowNewWarning) {
                shouldShowNewWarning = true
                break
            }

            uncheckedRowWarnings.push(childWarningState.shouldShowUncheckedWarning)
        }

        if (shouldShowNewWarning) {
            return { shouldShowNewWarning, shouldShowUncheckedWarning }
        }

        shouldShowUncheckedWarning = !!uncheckedRowWarnings.find(shouldShowWarning => shouldShowWarning === true)

        return { shouldShowNewWarning, shouldShowUncheckedWarning }
    }

    private getRowWarningStatesFromTopLevel(children: DepartmentFrameworkRowItem[]) {
        let shouldShowNewWarning: boolean = false
        let shouldShowUncheckedWarning: boolean = false
        const uncheckedRowWarnings: boolean[] = []

        mainLoop: for (const childRow of children) {
            if (childRow.children?.length) {
                for (const child of childRow.children) {
                    const childWarningState = this.getRowWarningStatesFromBottomLevel(child)

                    if (childWarningState.shouldShowNewWarning) {
                        shouldShowNewWarning = true
                        break mainLoop
                    }

                    uncheckedRowWarnings.push(childWarningState.shouldShowUncheckedWarning)
                }
            }
        }

        if (shouldShowNewWarning) {
            return { shouldShowNewWarning, shouldShowUncheckedWarning }
        }

        shouldShowUncheckedWarning = !!uncheckedRowWarnings.find(shouldShowWarning => shouldShowWarning === true)

        return { shouldShowNewWarning, shouldShowUncheckedWarning }
    }

    private getChildrenNewItemCount(rowItem: DepartmentFrameworkRowItem) {
        const { yIndex, children } = rowItem

        // sanity check: a customerFramework or a theme without children shouldnt show new warning
        if (!children?.length) {
            return 0
        }

        let newTopicRowItemCount = 0

        if (yIndex === 1) {
            for (const child of children) {
                if (child.isNew) {
                    newTopicRowItemCount++
                }
            }

            return newTopicRowItemCount
        }

        // if yIndex === 0
        for (const themeRow of children) {
            if (!themeRow.children?.length) {
                continue
            }

            for (const topicRow of themeRow.children) {
                if (topicRow.isNew) {
                    newTopicRowItemCount++
                }
            }
        }

        return newTopicRowItemCount
    }
}
