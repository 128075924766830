import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import {
    MonitoringReportTopicAssessmentGrade,
    MonitoringReportType,
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentDocumentType,
    TopicAssessmentQuery,
    TopicControlMeasureArticlesQuery,
    TopicNoteArticlesQuery,
} from '~/generated/graphql'
import { TopicAssessmentFields } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentFields'
import { TopicAssessment } from '../../Topic/TopicDesignAndEffectiveness/queries/TopicAssessment'
import { TopicControlMeasureArticles } from '../../Topic/TopicDesignAndEffectiveness/queries/TopicControlMeasureArticles'
import { TopicNoteArticles } from '../../Topic/TopicDesignAndEffectiveness/queries/TopicNoteArticles'
import {
    CreateMonitoringReportTopicAssessment,
    CreateMonitoringReportTopicAssessmentFN,
} from './CreateMonitoringReportTopicAssessment'
import { readAndSanitizeMultipleFiles } from '~/utils/fileSanitizer'

interface Props {
    requestClose: () => void
    onAssess?: () => void
    topicAssessmentId?: number
    topicId: number
    assessmentType: TopicAssessmentDesignOrEffectiveNessType
    monitoringReportTopicId: number
    monitoringReport: Pick<MonitoringReportType, 'id' | 'name'>
    departmentId: number
    renderLabel: () => React.ReactNode
    linkedItemId: number
    linkedItemType: TopicAssessmentDeLinkedItemType
    grade: MonitoringReportTopicAssessmentGrade
}

type ArticleData = TopicControlMeasureArticlesQuery['topicControlMeasure'] | TopicNoteArticlesQuery['topicNote']

interface State {
    files?: File[]
    validFileErrorMessage: string | null
}

export class AssessMonitoringReportTopicModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        validFileErrorMessage: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicAssessmentModal)
    private defaultDocuments?: Pick<TopicAssessmentDocumentType, 'id' | 'file'>[] | null

    public render() {
        const { topicAssessmentId } = this.props

        if (!topicAssessmentId) {
            return this.renderModal()
        }

        const { departmentId, linkedItemId, linkedItemType } = this.props

        return (
            <TopicAssessment
                id={topicAssessmentId}
                linkedItemId={linkedItemId}
                linkedItemType={linkedItemType}
                departmentId={departmentId}
            >
                {({ data, loading }) => {
                    this.defaultDocuments = data?.topicAssessment?.topicAssessmentDocuments || null
                    return this.renderModal(data?.topicAssessment, loading)
                }}
            </TopicAssessment>
        )
    }

    private renderModal(data?: TopicAssessmentQuery['topicAssessment'], loading?: boolean) {
        const { requestClose, assessmentType, linkedItemType } = this.props
        const { validFileErrorMessage } = this.state

        const title =
            assessmentType === TopicAssessmentDesignOrEffectiveNessType.design
                ? this.loc(t => t.designTitle)
                : this.loc(t => t.effectivenessTitle)

        return (
            <CreateMonitoringReportTopicAssessment>
                {(mutate, { loading: mutateLoading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            submitForm
                            confirmButtonLabel={this.loc(t => t.confirmLabel)}
                            loading={loading || mutateLoading}
                            requestClose={requestClose}
                            title={title}
                        >
                            <ErrorMessage path="createMonitoringReportTopicAssessment" />

                            {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}

                            {linkedItemType === TopicAssessmentDeLinkedItemType.note
                                ? this.renderWithNoteArticles(data)
                                : this.renderWithControlMeasureArticles(data)}
                        </Modal>
                    </Form>
                )}
            </CreateMonitoringReportTopicAssessment>
        )
    }

    private renderWithNoteArticles(data?: TopicAssessmentQuery['topicAssessment']) {
        const { linkedItemId, departmentId } = this.props

        return (
            <TopicNoteArticles id={linkedItemId} departmentId={departmentId}>
                {({ data: noteArticlesData, loading }) => this.renderFields(loading, noteArticlesData?.topicNote, data)}
            </TopicNoteArticles>
        )
    }

    private renderWithControlMeasureArticles(data?: TopicAssessmentQuery['topicAssessment']) {
        const { linkedItemId, departmentId } = this.props

        return (
            <TopicControlMeasureArticles id={linkedItemId} departmentId={departmentId}>
                {({ data: controlMeasureArticlesData, loading }) =>
                    this.renderFields(loading, controlMeasureArticlesData?.topicControlMeasure, data)
                }
            </TopicControlMeasureArticles>
        )
    }

    private renderFields(loading: boolean, articleData?: ArticleData, data?: TopicAssessmentQuery['topicAssessment']) {
        const { topicId, renderLabel, grade, monitoringReport } = this.props

        if (loading) {
            return <Spinner />
        }

        return (
            <TopicAssessmentFields
                topicId={topicId}
                selectedArticleIds={articleData?.articles.map(a => a.id) || []}
                allArticles={articleData?.articlesAreAll}
                label={renderLabel()}
                onChangeFiles={files => this.setState({ files })}
                hideNotAssessedOption
                isEditing
                defaultValues={{
                    ...(data || {}),
                    grade,
                    monitoringReport,
                }}
            />
        )
    }

    private handleSubmit = (mutate: CreateMonitoringReportTopicAssessmentFN) => async (formState: FormState) => {
        const { grade, requestClose, monitoringReportTopicId, linkedItemId, linkedItemType, assessmentType, onAssess } =
            this.props
        const { newFiles, existingTopicAssessmentDocumentIds } = this.getDocumentsToCreateWith()

        const isValidFile = newFiles ? await readAndSanitizeMultipleFiles(newFiles as any) : true

        if (isValidFile) {
            const response = await mutate({
                variables: {
                    monitoringReportTopicId,
                    linkedItemId,
                    linkedItemType,
                    designOrEffectiveness: assessmentType,
                    fields: {
                        description: formState.description,
                        documents: newFiles,
                        existingTopicAssessmentDocumentIds,
                        grade: formState.grade?.value || grade,
                    },
                },
            })

            if (response && response.data?.createMonitoringReportTopicAssessment) {
                notification.success(localize.translate(t => t.Generic.successfullyCreated))
                onAssess?.()
                requestClose()
            }
        } else {
            this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
        }
    }

    private getDocumentsToCreateWith() {
        const { files } = this.state

        if (files === undefined) {
            // No files have been added or removed
            return { existingTopicAssessmentDocumentIds: this.defaultDocuments?.map(d => d.id) }
        }

        if (!this.defaultDocuments) {
            return { newFiles: files }
        }

        const existingTopicAssessmentDocumentIds: number[] = []
        for (const { id, file } of this.defaultDocuments) {
            // Known edge case/bug:
            //      If a file with the same name that's in default documents is added
            //      and the original file is removed, the original will not be removed
            //
            // Because this is unlikely to happen, and the fix is not trivial,
            // this case will not be handled at the moment (mvp)
            const found = files.some(f => f.name === file?.name)
            if (found) {
                existingTopicAssessmentDocumentIds.push(id)
            }
        }

        const newFiles: File[] = []
        for (const file of files) {
            // Known edge case/bug:
            //      The above issue will cause new files to be treated as existing,
            //      and prevent them from being added
            //
            // Because this is unlikely to happen, and the fix is not trivial,
            // this case will not be handled at the moment (mvp)
            const isNew = !this.defaultDocuments.some(t => t.file?.name === file.name)
            if (isNew) {
                newFiles.push(file)
            }
        }

        return { newFiles, existingTopicAssessmentDocumentIds }
    }
}
