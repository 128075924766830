import './CoverView.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { CoverLoginView } from '~/views/Cover/CoverLoginView'
import { CoverSamlView } from '~/views/Cover/CoverSamlView'
import { CoverNotFoundView } from '~/views/Cover/CoverNotFoundView'
import { ForgotPasswordView } from '~/views/Cover/ForgotPasswordView'
import { routes } from '~/views/routes'
import { SetPasswordView } from '~/views/Cover/SetPasswordView'
import { ConfirmAccountView } from '~/views/Cover/ConfirmAccountView'
import { RedirectView } from '~/views/Cover/RedirectView'
import { RegisterUser } from '~/views/Cover/RegisterUser'
import { Footer } from '~/components/Chrome/Footer/Footer'
import { ConfirmMergeUser } from './ConfirmMergeUser'
import { CoverSSOLoginView } from './CoverSSOLoginView'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route, Navigate } from 'react-router-dom'
import { newRoutes } from '../newRoutes'

interface Props extends RouteComponentProps<{ token?: string }> {}

class CoverViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('CoverView')

    public render() {
        const { token } = this.props.match.params

        return (
            <div className={this.bem.getClassName()}>
                <Routes>
                    <Route path={newRoutes.cover.login} element={<CoverLoginView />} />
                    <Route path={newRoutes.cover.loginWithSSO} element={<CoverSSOLoginView />} />
                    <Route path={newRoutes.cover.saml} element={<CoverSamlView />} />
                    <Route path={newRoutes.cover.forgotPassword} element={<ForgotPasswordView />} />
                    <Route path={newRoutes.cover.setPassword} element={<SetPasswordView />} />
                    <Route path={newRoutes.cover.confirmAccount} element={<ConfirmAccountView />} />
                    <Route path={newRoutes.cover.registerUser()} element={<RegisterUser />} />
                    <Route path={newRoutes.cover.userMergeRequest} element={<ConfirmMergeUser />} />
                    <Route path={newRoutes.cover.redirect} element={<RedirectView />} />
                    <Route path={'*'} element={<Navigate replace to={routes.cover.login} />} />
                    <Route
                        path={newRoutes.cover.registerUserLegacy}
                        element={<Navigate replace to={routes.cover.registerUser(token, '-')} />}
                    />
                    <Route element={<CoverNotFoundView />} />
                </Routes>
                <Footer className={this.bem.getElement('footer')} />
            </div>
        )
    }
}

export const CoverView = withRouter(CoverViewComponent)
