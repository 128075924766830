import React from 'react'
import { localize, notification } from '~/bootstrap'
import { RelevantModalOverview } from './RelevantModalOverview'
import {
    DepartmentType,
    NewsItemType,
    CreateInternalAlertMutationVariables,
    CreateInternalAlertMutation,
    CreateInternalAlertDocument,
} from '~/generated/graphql'
import { RelevantModalAddTopic } from './RelevantModalAddTopic'
import { MultiStepModal, ModalStep } from '~/components/Core/Feedback/Modal/MultiStepModal'
import { Topic } from '~/components/Domain/Topic/TopicsQuery'
import { RelevantModalAddDepartment } from './RelevantModalAddDepartment'
import { flatten, uniqBy } from 'lodash-es'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    newsItem: NewsItemType
    closeModal: () => void
    onMarkRelevant?: (variables?: any) => void
}

interface State {
    selectedTopics: Topic[]
    extraDepartments: DepartmentType[]
    note: string
}

export class RelevantModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        selectedTopics: this.props.newsItem.suggestedTopics as Topic[],
        extraDepartments: [],
        note: '',
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Signaling.RelevantModal)

    public render() {
        const { closeModal, newsItem } = this.props
        const { selectedTopics, extraDepartments, note } = this.state

        return (
            <GQLMutation<CreateInternalAlertMutation, CreateInternalAlertMutationVariables>
                mutation={CreateInternalAlertDocument}
            >
                {(mutate, { loading: submitLoading }) => {
                    const steps: ModalStep[] = [
                        {
                            name: 'overview',
                            title: newsItem.title,
                            content: (setActiveIndex: (index: number) => void) => (
                                <RelevantModalOverview
                                    topics={selectedTopics}
                                    departments={extraDepartments}
                                    defaultNote={note}
                                    newsItem={newsItem}
                                    setModalState={this.setRelevantModalState}
                                    setActiveIndex={setActiveIndex}
                                    selectedTopics={this.state.selectedTopics}
                                />
                            ),
                            actions: () => requestClose => this.renderModalActions(mutate, submitLoading, requestClose),
                        },
                        {
                            name: 'addTopic',
                            title: this.loc(t => t.addTopicTitle),
                            content: () => (
                                <RelevantModalAddTopic
                                    selectedTopics={selectedTopics}
                                    selectedDeparments={extraDepartments}
                                    setModalState={this.setRelevantModalState}
                                />
                            ),
                        },
                        {
                            name: 'addDepartment',
                            title: this.loc(t => t.addDepartmentTitle),
                            content: () => (
                                <RelevantModalAddDepartment
                                    selectedTopics={selectedTopics}
                                    selectedDeparments={extraDepartments}
                                    setModalState={this.setRelevantModalState}
                                />
                            ),
                        },
                    ]

                    return <MultiStepModal requestClose={closeModal} steps={steps} hideCloseButton={true} />
                }}
            </GQLMutation>
        )
    }

    private renderModalActions(
        mutate: MutationFn<CreateInternalAlertMutation, CreateInternalAlertMutationVariables>,
        loading: boolean,
        requestClose?: () => void
    ) {
        return (
            <Row alignRight={true}>
                <Button type={ButtonType.tertiary} onClick={requestClose} disabled={loading}>
                    {localize.translate(t => t.Core.Modal.cancel)}
                </Button>
                <Button
                    onClick={() => this.handleLinkRelevant(mutate)}
                    disabled={this.shouldSubmitBeDisabled()}
                    loading={loading}
                    icon={IconType.paperPlane}
                >
                    {this.loc(t => t.submit)}
                </Button>
            </Row>
        )
    }

    private shouldSubmitBeDisabled() {
        const { extraDepartments, selectedTopics } = this.state

        if (!extraDepartments?.length && !selectedTopics?.length) {
            return true
        }

        return false
    }

    private setRelevantModalState = (
        newModalContentState: State,
        callBack?: (nextModalContentState: State) => void
    ) => {
        this.setState(
            prevState => ({
                ...prevState,
                ...newModalContentState,
            }),
            () => {
                if (callBack) {
                    callBack(this.state)
                }
            }
        )
    }

    private handleLinkRelevant = async (
        mutate: MutationFn<CreateInternalAlertMutation, CreateInternalAlertMutationVariables>
    ) => {
        const { closeModal, newsItem, onMarkRelevant } = this.props
        const { selectedTopics, extraDepartments, note } = this.state

        let topicIds: number[] = []
        let topicDepartments: { id: number; name: string }[] = []
        let allSelectedDepartments: { id: number; name: string }[] = uniqBy([...extraDepartments], 'id')

        if (selectedTopics && selectedTopics.length) {
            topicIds = selectedTopics.map(topic => topic.id)
            topicDepartments = flatten(selectedTopics.map(topic => topic.departments || []))
            allSelectedDepartments = uniqBy([...topicDepartments, ...extraDepartments], 'id')
        }

        const departmentIds = allSelectedDepartments.map(department => department.id)

        const response = await mutate({
            variables: {
                newsId: newsItem.id,
                topicIds: topicIds,
                departmentIds: departmentIds,
                note: note || null,
            },
        })

        if (response && response.data && response.data.createInternalAlert) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))

            if (onMarkRelevant) {
                onMarkRelevant()
            }

            if (closeModal) {
                closeModal()
            }
        }
    }
}
