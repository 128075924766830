import './Stepper.scss'

import React, { createRef } from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Tooltip } from '../../Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    min?: number
    max?: number
    minLimitReachedMessage?: string
    maxLimitReachedMessage?: string
    limitReachedMessage?: string
    defaultValue?: number
    name: string
    onChange?: (value: number | null, name: string) => void
}

interface State {
    value: number
    minimumReached: boolean
    maximumReached: boolean
}

export class Stepper extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        value: this.props.defaultValue || this.props.min || 0,
        minimumReached:
            this.props.defaultValue !== undefined ? this.props.defaultValue === this.props.min : !!this.props.min,
        maximumReached: false,
    }

    private bem = new BEM('Stepper')

    private inputRef = createRef<HTMLInputElement>()

    public render() {
        const { className, min, max, name } = this.props
        const { value } = this.state

        const formattedValue = localize.numberFormat(value)

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderDecrementButton()}
                <div className={this.bem.getElement('input-container')}>
                    <span aria-hidden="true" className={this.bem.getElement('input-display')}>
                        {formattedValue}
                    </span>
                    <input
                        style={{
                            maxWidth: `${formattedValue.length + 4}ch`,
                        }}
                        className={this.bem.getElement('input')}
                        type="number"
                        onChange={this.handleChange}
                        onBlur={this.handleChange}
                        value={value}
                        name={name}
                        min={min}
                        max={max}
                        ref={this.inputRef}
                    />
                </div>
                {this.renderIncrementButton()}
            </div>
        )
    }

    private renderDecrementButton() {
        const {
            limitReachedMessage = localize.translate(t => t.Consultant.CreateCustomerView.limitAlreadyReached),
            minLimitReachedMessage,
        } = this.props
        const { minimumReached } = this.state

        const decrementButton = (
            <button
                className={this.bem.getElement('button', () => ({
                    'is-inactive': minimumReached,
                }))}
                type={'button'}
                onClick={this.decrementValue}
            >
                {/* tslint:disable-next-line:jsx-use-translation-function */}-
            </button>
        )

        const treshholdMessage = minLimitReachedMessage ? minLimitReachedMessage : limitReachedMessage

        if (minimumReached && treshholdMessage) {
            return <Tooltip content={treshholdMessage}>{decrementButton}</Tooltip>
        }

        return decrementButton
    }

    private renderIncrementButton() {
        const {
            limitReachedMessage = localize.translate(t => t.Consultant.CreateCustomerView.limitAlreadyReached),
            maxLimitReachedMessage,
        } = this.props
        const { maximumReached } = this.state

        const incrementButton = (
            <button
                className={this.bem.getElement('button', () => ({
                    'is-inactive': maximumReached,
                }))}
                type={'button'}
                onClick={this.incrementValue}
            >
                {/* tslint:disable-next-line:jsx-use-translation-function */}+
            </button>
        )

        const treshholdMessage = maxLimitReachedMessage ? maxLimitReachedMessage : limitReachedMessage

        if (maximumReached && treshholdMessage) {
            return <Tooltip content={treshholdMessage}>{incrementButton}</Tooltip>
        }

        return incrementButton
    }

    private getValue(value: string) {
        value = value.trim()
        const parsedValue = parseInt(value, 10)

        if (isNaN(parsedValue)) {
            return 0
        }

        return parsedValue
    }

    private handleChange = () => {
        if (!this.inputRef.current) {
            return
        }

        const value = this.getValue(this.inputRef.current.value)

        this.setValue(value)
    }

    private decrementValue = () => {
        if (!this.inputRef.current) {
            return
        }

        const value = this.getValue(this.inputRef.current.value)

        this.setValue(value - 1)
    }

    private incrementValue = () => {
        if (!this.inputRef.current) {
            return
        }

        const value = this.getValue(this.inputRef.current.value)
        this.setValue(value + 1)
    }

    private setValue(value: number) {
        const { onChange, name, min, max } = this.props

        // Ensure number does not go above the max or below the min value
        value = Math.min(max || Number.MAX_SAFE_INTEGER, Math.max(min || Number.MIN_SAFE_INTEGER, value))

        if (value === this.state.value) {
            return
        }

        if (min && max) {
            if (value > min && value < max) {
                this.setState({ minimumReached: false, maximumReached: false })
            }
        }

        if (min === value) {
            this.setState({ minimumReached: true, maximumReached: false })
        }

        if (max === value) {
            this.setState({ minimumReached: false, maximumReached: true })
        }

        this.setState(
            {
                value,
            },
            () => onChange && onChange(value, name)
        )
    }
}
