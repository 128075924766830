import './MainNavigation.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Logo } from '../../Logo/Logo'
import { NavLink, matchPath } from 'react-router-dom'
import { SubMenu } from '../SubMenu/SubMenu'
import { routes } from '~/views/routes'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ReleaseNotesManager } from '~/components/Domain/ReleaseNotes/ReleaseNotesManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, userClient } from '~/bootstrap'
import { animations, animationTiming } from '~/animations'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { NotificationBubble } from '~/components/Core/DataDisplay/NotificationBubble/NotificationBubble'
import { OnboardingStepProps, OnboardingStep } from '~/components/Domain/Onboarding/OnboardingStep'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { LanguageSwitchModal } from '../LanguageSwitchModal/LanguageSwitchModal'

interface Props {
    className?: ClassValue
    navigation: NavigationItem[]
    settingsNavigation?: NavigationItem
    hasBanner?: boolean
    onLinkClick?: () => void
}

interface State {
    activeRouteIndex: number
    activeRoute?: NavigationItem
    activeSubMenu?: {
        route: string
        label: string
    }[]
}

export interface NavigationItem {
    route?: string
    label: string | JSX.Element
    icon?: JSX.Element
    simpleNotification?: boolean
    notificationAmount?: number
    onboarding?: OnboardingStepProps
    disabled?: boolean
    children?: {
        route: string
        label: string
        disabled?: boolean
        hidden?: boolean
    }[]
}

export class MainNavigation extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        activeRouteIndex: -1,
        activeSubMenu: undefined,
        activeRoute: undefined,
    }

    private bem = new BEM('MainNavigation', () => ({
        'has-sidebar': this.state.activeSubMenu ? this.state.activeSubMenu.length > 0 : false,
        hasBanner: this.props.hasBanner,
    }))

    public componentDidMount() {
        const activeRouteIndex = this.props.navigation.findIndex(item => {
            if (item.route) {
                return window.location.pathname.includes(item.route)
            }

            if (item.children) {
                return !!item.children.find(({ route }) => window.location.pathname.includes(route))
            }

            return false
        })

        this.setActiveRouteIndex(activeRouteIndex)

        if (
            this.props.settingsNavigation &&
            this.props.settingsNavigation.route &&
            window.location.pathname.includes(this.props.settingsNavigation.route)
        ) {
            this.openSettings()
        }
    }

    public render() {
        const { className, navigation, onLinkClick } = this.props
        const { activeSubMenu, activeRoute } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <NavLink
                    to={routes.index}
                    onClick={() => this.setActiveRouteIndex(-1)}
                    className={this.bem.getElement('header')}
                >
                    <Logo className={this.bem.getElement('logo')} iconOnly={!!activeSubMenu} />
                </NavLink>

                <div className={this.bem.getElement('click-area')} onClick={this.handleOnClickAreaClick} />

                <ul className={this.bem.getElement('navigation')}>
                    {navigation.map((item, index) => {
                        if (item.onboarding) {
                            return (
                                <OnboardingStep {...item.onboarding} key={`${item.label}-${index}`}>
                                    {ref => (
                                        <li className={this.bem.getElement('navigation-item')} ref={ref}>
                                            {this.renderMainLink(item, index)}
                                        </li>
                                    )}
                                </OnboardingStep>
                            )
                        }

                        return (
                            <li className={this.bem.getElement('navigation-item')} key={`${item.label}-${index}`}>
                                {this.renderMainLink(item, index)}
                            </li>
                        )
                    })}
                </ul>

                <div className={this.bem.getElement('footer')}>
                    {this.renderLanguageButton()}
                    {this.renderHelp()}
                    {this.renderSettings()}
                    {this.renderCustomerSwitch()}
                    {this.renderLogout()}
                    {this.renderReleaseNotes()}
                </div>

                {/* SUB MENU */}
                <TransitionGroup component={null}>
                    {activeRoute && activeSubMenu && activeSubMenu.length > 0 && (
                        <CSSTransition
                            appear={true}
                            in={true}
                            unmountOnExit={true}
                            timeout={animationTiming.menuTiming}
                            classNames={animations.menuSlideIn}
                        >
                            <SubMenu
                                title={activeRoute && activeRoute.label}
                                navigation={activeSubMenu}
                                onNavigateBack={this.handleNavigateBack}
                                onLinkClick={onLinkClick}
                            />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        )
    }

    private renderMainLink(item: NavigationItem, index: number) {
        const { onLinkClick } = this.props

        if (item.disabled) {
            return
        }

        if (item.route) {
            return (
                <NavLink
                    className={this.bem.getElement('navigation-link', () => {
                        const matches =
                            item.route &&
                            matchPath({ path: item.route, caseSensitive: false, end: false }, window.location.pathname)

                        return {
                            active:
                                !!matches &&
                                (this.state.activeRouteIndex === -1 || this.state.activeRouteIndex === index),
                        }
                    })}
                    to={item.route}
                    onClick={() => {
                        this.setActiveRouteIndex(index)

                        if (onLinkClick) {
                            onLinkClick()
                        }
                    }}
                >
                    <span className={this.bem.getElement('navigation-icon')}>{item.icon}</span>
                    <span className={this.bem.getElement('link-content')}>{item.label}</span>
                    {this.renderItemNotification(item)}
                </NavLink>
            )
        }

        return (
            <button
                type="button"
                className={this.bem.getElement('navigation-link', () => {
                    const matches =
                        item.route &&
                        matchPath(
                            {
                                path: item.route,
                                end: false,
                            },
                            window.location.pathname
                        )

                    return {
                        'is-parent-active': !!matches,
                        active: index === this.state.activeRouteIndex,
                    }
                })}
                onClick={() => this.setActiveRouteIndex(index)}
            >
                <span className={this.bem.getElement('navigation-icon')}>{item.icon}</span>
                <span className={this.bem.getElement('link-content')}>{item.label}</span>
                {this.renderItemNotification(item)}
            </button>
        )
    }

    private setActiveRouteIndex(activeRouteIndex: number) {
        const { navigation } = this.props
        const activeRoute = navigation[activeRouteIndex]
        const activeSubMenu = activeRoute ? activeRoute.children : undefined

        this.setState({
            activeRouteIndex,
            activeRoute: navigation[activeRouteIndex],
            activeSubMenu,
        })
    }

    private renderLanguageButton() {
        return (
            <ModalManager
                render={openModal => (
                    <button
                        type="button"
                        className={this.bem.getElement('navigation-link')}
                        onClick={() => openModal()}
                    >
                        {this.renderCurrentLanguageIconAndText()}
                    </button>
                )}
                renderModal={closeModal => <LanguageSwitchModal closeModal={closeModal} />}
            />
        )
    }

    private renderHelp() {
        return (
            <button
                type="button"
                className={this.bem.getElement('navigation-link', () => ({
                    help: true,
                }))}
                onClick={this.openHelp}
            >
                <span className={this.bem.getElement('navigation-icon', () => ({ help: true }))}>
                    <Icon type={IconType.help} />
                </span>
                <span className={this.bem.getElement('link-content')}>
                    {localize.translate(t => t.User.CurrentUser.Help)}
                </span>
            </button>
        )
    }

    private renderItemNotification(item: NavigationItem) {
        const { notificationAmount, simpleNotification } = item

        if (notificationAmount) {
            return <NotificationBubble amount={notificationAmount} className={this.bem.getElement('notification')} />
        }

        if (simpleNotification) {
            return <span className={this.bem.getElement('simple-notification')} />
        }

        return null
    }

    private renderSettings() {
        const { settingsNavigation, navigation } = this.props

        if (!settingsNavigation) {
            return null
        }

        return (
            <button
                type="button"
                className={this.bem.getElement('navigation-link', () => {
                    const matches = matchPath(
                        {
                            path: routes.customer(this.context.customer.slug).settings.index,
                            caseSensitive: false,
                            end: false,
                        },
                        window.location.pathname
                    )

                    return {
                        'is-parent-active': !!matches,
                        active: this.state.activeRouteIndex === navigation.length + 1,
                        manage: true,
                    }
                })}
                onClick={this.openSettings}
            >
                <span className={this.bem.getElement('navigation-icon')}>
                    <Icon type={IconType.manage} />
                </span>
                <span className={this.bem.getElement('link-content')}>
                    {localize.translate(t => t.Customer.routes[routes.customer().settings.index])}
                </span>
            </button>
        )
    }

    private renderCustomerSwitch() {
        if (!this.context?.hasCustomers) {
            return
        }

        const { slug } = this.context.customer

        return (
            <NavLink className={this.bem.getElement('navigation-link')} to={routes.customer(slug).switch}>
                <span className={this.bem.getElement('navigation-icon')}>
                    <Icon type={IconType.switch} />
                </span>
                <span className={this.bem.getElement('link-content')}>
                    {localize.translate(t => t.User.CurrentUser.switch)}
                </span>
            </NavLink>
        )
    }

    private renderLogout() {
        return (
            <button
                type="button"
                className={this.bem.getElement('navigation-link', () => ({ logout: true }))}
                onClick={() => userClient.logout()}
            >
                <span className={this.bem.getElement('navigation-icon', () => ({ logout: true }))}>
                    <Icon type={IconType.logOut} />
                </span>
                <span className={this.bem.getElement('link-content')}>
                    {localize.translate(t => t.User.CurrentUser.SignOut)}
                </span>
            </button>
        )
    }

    private renderReleaseNotes() {
        return (
            <ReleaseNotesManager
                render={(open, version) => (
                    <Button type={ButtonType.noStyling} onClick={open} className={this.bem.getElement('version')}>
                        <span className={this.bem.getElement('version-text')}>
                            {localize.translate(t => t.Generic.version)}
                        </span>
                        {version}
                    </Button>
                )}
            />
        )
    }

    private openSettings = () => {
        const { navigation, settingsNavigation } = this.props

        if (!settingsNavigation) {
            return
        }

        this.setState({
            activeRouteIndex: navigation.length + 1,
            activeRoute: settingsNavigation,
            activeSubMenu: settingsNavigation.children,
        })
    }

    private renderCurrentLanguageIconAndText() {
        const shouldRenderEnglishVersion = userClient.getCurrentUserLanguage() === 'en'

        const languageIcon = shouldRenderEnglishVersion ? IconType.en : IconType.nl

        return (
            <>
                <span className={this.bem.getElement('navigation-icon')}>
                    <Icon type={languageIcon} />
                </span>
                <span className={this.bem.getElement('link-content')}>
                    {localize.translate(t => t.User.CurrentUser.language)}
                </span>
            </>
        )
    }

    private openHelp = () => {
        window.open('https://ruler.nl/help', '_blank')
    }

    private handleNavigateBack = () => {
        this.closeSidebar()
    }

    private handleOnClickAreaClick = () => {
        if (this.state.activeSubMenu) {
            this.closeSidebar()
        }
    }

    private closeSidebar() {
        this.setState({
            activeSubMenu: undefined,
            activeRoute: undefined,
        })
    }
}
