import './MultiDepthExpandableTable.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { ExpandableTableListItem } from '~/components/Core/DataDisplay/TableList/ExpandableTableListItem'
import { TableListItem } from '../TableList/TableListItem'
import { Column } from '../../Layout/Column'

export interface MultiDepthTableRowItem {
    id: number
    name: string
    yIndex: number
    render?: () => React.ReactNode
    children?: MultiDepthTableRowItem[]
    onExpandedStateChange?: (isExpanded: boolean) => void
}

interface Props {
    rowItems: MultiDepthTableRowItem[]
    className?: ClassValue
    maxDepth: number
    expandedRowsIds?: number[]
}

export class MultiDepthExpandableTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('MultiDepthExpandableTable')

    public render() {
        const { rowItems, className } = this.props

        return (
            <Column className={this.bem.getClassName(className)}>
                {rowItems.map(rowItem => (
                    <div key={`${rowItem.id}`} id={`${rowItem.id}`}>
                        {this.renderTableRowItem(rowItem)}
                    </div>
                ))}
            </Column>
        )
    }

    public scrollToRow(id: number) {
        const row = document.getElementById(`${id}`)

        if (row) {
            row.scrollIntoView({ behavior: 'smooth' })
        }
    }

    private renderTableRowItem(rowItem: MultiDepthTableRowItem) {
        if (rowItem.children?.length) {
            const renderExpanded = () => rowItem.children!.map(child => this.renderTableRowItem(child))

            return (
                <ExpandableTableListItem
                    onExpandedStateChange={rowItem.onExpandedStateChange}
                    className={this.bem.getElement('list-item', () => ({
                        'is-sub-parent': rowItem.yIndex > 0,
                    }))}
                    key={`row-${rowItem.id}-${rowItem.name}`}
                    renderExpanded={renderExpanded}
                    isExpanded={this.props.expandedRowsIds?.includes(rowItem.id)}
                >
                    {rowItem.render && rowItem.render()}
                </ExpandableTableListItem>
            )
        }

        const { maxDepth } = this.props

        return (
            <TableListItem
                key={`row-${rowItem.id}-${rowItem.name}`}
                className={this.bem.getElement('table-list-item', () => ({
                    'is-parent': rowItem.yIndex < maxDepth,
                    'is-sub-parent': rowItem.yIndex > 0 && rowItem.yIndex < maxDepth,
                }))}
            >
                {rowItem.render && rowItem.render()}
            </TableListItem>
        )
    }
}
