import React from 'react'
import gql from 'graphql-tag'
import { Mutation, MutationFn, MutationResult } from 'react-apollo'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'

export interface RequestSAMLRedirectURLMutationResponse {
    requestSAMLRedirectURL: {
        redirectURL: string
    }
}

export interface RequestSAMLRedirectURLMutationVariables {
    email: string | null
}

export const requestSAMLRedirectURLMutation = gql`
    mutation requestSAMLRedirectURL($email: String) {
        requestSAMLRedirectURL(organizationNameOrEmail: $email) {
            redirectURL
        }
    }
`

interface MutationProps {
    children?: (
        mutateFn: MutationFn<RequestSAMLRedirectURLMutationResponse, RequestSAMLRedirectURLMutationVariables>,
        result: MutationResult<RequestSAMLRedirectURLMutationResponse>
    ) => React.ReactNode
}

export type RequestSAMLRedirectURLMutationFn = MutationFn<
    RequestSAMLRedirectURLMutationResponse,
    RequestSAMLRedirectURLMutationVariables
>

export const RequestSAMLRedirectURLMutation: React.FunctionComponent<MutationProps> = ({ children }) => (
    <Mutation<any> mutation={requestSAMLRedirectURLMutation}>
        {(requestSAMLRedirectURL, state) => (
            <ErrorProvider error={state.error}>{children && children(requestSAMLRedirectURL, state)}</ErrorProvider>
        )}
    </Mutation>
)
