import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { RadarOverviewView } from './RadarOverviewView'
import { RadarDetailView } from './RadarDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Routes, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}

class RadarViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<RadarOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).radar.detail.view()}
                    element={<RadarDetailView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const RadarView = withRouter(RadarViewComponent)
