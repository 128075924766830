import './TopicAssessmentIconContainer.scss'

import React from 'react'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CreateTopicAssessmentModal } from './modals/TopicAssessment/CreateTopicAssessmentModal'
import { TopicAssessmentModal } from './modals/TopicAssessment/TopicAssessmentModal'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize, permissions } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { TopicAssessmentIcon } from './modals/TopicAssessment/TopicAssessmentIcon'
import { TopicAssessmentForLinkedControlsModal } from './modals/TopicAssessment/TopicAssessmentForLinkedControlsModal'
import {
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
    TopicAssessmentIconContainerAssessmentFragment,
    TopicAssessmentIconContainerControlFragment,
    TopicAssessmentIconContainerNoteFragment,
    TopicAssessmentType,
    TopicControlMeasureType,
} from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Markdown } from '~/components/Core/Text/Markdown'
import { TopicAssessment } from './queries/TopicAssessment'
import { Popper } from '~/components/Core/Popper/Popper'
import { TopicAssessmentLabel } from './modals/TopicAssessment/TopicAssessmentLabel'

interface Props {
    linkedItem: TopicAssessmentIconContainerNoteFragment | TopicAssessmentIconContainerControlFragment
    linkedItemLabel: React.ReactNode
    linkedItemType: TopicAssessmentDeLinkedItemType
    topicId: number
    assessmentType: TopicAssessmentDesignOrEffectiveNessType
    assessment?: TopicAssessmentIconContainerAssessmentFragment
    hasLinkedControls?: boolean
    onChange?: () => void
}

interface State {
    currentGrade: TopicAssessmentGradeType
    initialGrade: TopicAssessmentGradeType
}

type OpenModalFn = (grade: TopicAssessmentGradeType) => void

export class TopicAssessmentIconContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        currentGrade: this.props.assessment?.grade || TopicAssessmentGradeType.assessmentNotGiven,
        initialGrade: this.props.assessment?.grade || TopicAssessmentGradeType.assessmentNotGiven,
    }

    private ref = React.createRef<Popper>()
    private notAllowed = !permissions.canEditTopicDnE(this.context.activeDepartmentId)
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicAssessmentModal)
    private bem = new BEM('TopicAssessmentIconContainer')

    public render() {
        const { topicId, linkedItem, assessmentType, hasLinkedControls } = this.props
        const controlName = 'control' in linkedItem ? linkedItem.control.name : undefined

        if (hasLinkedControls && controlName) {
            return (
                <div className={this.bem.getClassName()}>
                    <ModalManager
                        render={openModal => (
                            <ModalManager<TopicAssessmentGradeType>
                                render={this.renderButton}
                                renderModal={(closeModal, grade) => this.renderModal(closeModal, grade, openModal)}
                            />
                        )}
                        renderModal={closeModal => (
                            <TopicAssessmentForLinkedControlsModal
                                topicId={topicId}
                                topicControlMeasure={linkedItem as TopicControlMeasureType}
                                topicAssessmentType={assessmentType}
                                grade={this.state.currentGrade}
                                previousGrade={this.state.initialGrade}
                                requestClose={closeModal}
                            />
                        )}
                    />
                </div>
            )
        }

        return (
            <div className={this.bem.getClassName()}>
                <ModalManager<TopicAssessmentGradeType> render={this.renderButton} renderModal={this.renderModal} />
            </div>
        )
    }

    private renderButton = (openModal: () => void) => {
        const { assessment } = this.props

        if (!assessment) {
            return this.renderButtonWithPopper(openModal)
        }

        return (
            <Tooltip
                content={this.renderTooltipContent()}
                direction={ToolTipDirection.down}
                className={this.bem.getElement('tooltip')}
            >
                <Button
                    disabled={this.notAllowed}
                    type={ButtonType.noStyling}
                    onClick={openModal}
                    className={this.bem.getElement('button')}
                >
                    <TopicAssessmentIcon status={assessment.grade || undefined} />
                </Button>
            </Tooltip>
        )
    }

    private renderModal = (
        closeModal: () => void,
        grade: TopicAssessmentGradeType,
        openLinkedControlModal?: () => void
    ) => {
        const { assessmentType, assessment, linkedItemLabel, linkedItem, topicId, linkedItemType } = this.props
        const { id, articles, articlesAreAll } = linkedItem
        const selectedArticleIds = articles.map(a => a.id)

        if (!assessment) {
            return (
                <CreateTopicAssessmentModal
                    parentId={id}
                    parentType={linkedItemType}
                    type={assessmentType}
                    closeModal={closeModal}
                    label={linkedItemLabel}
                    topicId={topicId}
                    selectedArticleIds={selectedArticleIds}
                    allArticles={articlesAreAll}
                    onSubmit={this.handleGradeChange(openLinkedControlModal)}
                    defaultGrade={grade}
                />
            )
        }

        return (
            <TopicAssessment
                id={assessment.id}
                linkedItemId={linkedItem.id}
                linkedItemType={linkedItemType}
                departmentId={this.context.activeDepartmentId}
            >
                {({ data, loading }) => (
                    <TopicAssessmentModal
                        parentId={id}
                        type={assessmentType}
                        closeModal={closeModal}
                        label={linkedItemLabel}
                        topicId={topicId}
                        selectedArticleIds={selectedArticleIds}
                        allArticles={articlesAreAll}
                        onSubmit={this.handleGradeChange(openLinkedControlModal)}
                        defaultValues={data?.topicAssessment as TopicAssessmentType}
                        loading={loading}
                    />
                )}
            </TopicAssessment>
        )
    }

    private renderTooltipContent() {
        const { assessment } = this.props
        if (!assessment) {
            return
        }

        const { updatedAt } = assessment
        const date = localize.dateFormat(new Date(updatedAt), { noWeekday: true })

        return (
            <Column bigSpacing={true} className={this.bem.getElement('tooltip-content')}>
                {this.renderTooltipHeader()}
                {this.renderTooltipMonitoringReport()}
                {this.renderTooltipDescription()}
                <Paragraph subtle={true} small={true}>
                    {this.loc(t => t.lastUpdatedAt, { date })}
                </Paragraph>
            </Column>
        )
    }

    private renderButtonWithPopper(openModal: () => void) {
        const options = [
            TopicAssessmentGradeType.assessmentSatisfies,
            TopicAssessmentGradeType.assessmentAlmostSatisfies,
            TopicAssessmentGradeType.assessmentNotSatisfies,
        ].map(grade => ({ item: this.renderAssessmentOption(openModal, grade) }))

        return (
            <Popper
                ref={this.ref}
                className={this.bem.getElement('popper')}
                contents={options}
                position="top"
                disabled={this.notAllowed}
                item={
                    <Button type={ButtonType.noStyling} className={this.bem.getElement('button')}>
                        <TopicAssessmentIcon />
                    </Button>
                }
            />
        )
    }

    private renderAssessmentOption(openModal: OpenModalFn, grade: TopicAssessmentGradeType) {
        return (
            <Button
                className={this.bem.getElement('option')}
                type={ButtonType.tertiary}
                onClick={() => {
                    this.ref.current?.setState({ forceClose: true })
                    openModal(grade)
                }}
            >
                <TopicAssessmentLabel status={grade} />
            </Button>
        )
    }

    private handleGradeChange = (openLinkedControlModal?: () => void) => (grade?: TopicAssessmentGradeType) => {
        if (!grade || !openLinkedControlModal) {
            return this.props.onChange?.()
        }

        this.setState({ currentGrade: grade }, () => {
            openLinkedControlModal()
        })
    }

    private renderTooltipHeader() {
        const { assessment, assessmentType } = this.props
        const documentCount = assessment?.topicAssessmentDocuments?.length

        const title =
            assessmentType === TopicAssessmentDesignOrEffectiveNessType.design
                ? this.loc(t => t.designTitle)
                : this.loc(t => t.effectivenessTitle)

        return (
            <Column smallSpacing={true}>
                <Row spaceBetween={true}>
                    <Paragraph bold={true} small={true}>
                        {title}
                    </Paragraph>
                    {documentCount ? (
                        <Row extraSmallSpacing={true}>
                            <Icon type={IconType.attachment} />
                            <Paragraph bold={true} small={true}>
                                {localize.translate(t => t.Generic.document, {
                                    smart_count: documentCount,
                                })}
                            </Paragraph>
                        </Row>
                    ) : null}
                </Row>
                <Row smallSpacing={true}>
                    <TopicAssessmentIcon status={assessment?.grade || undefined} />
                    <Paragraph bold={true} small={true}>
                        {this.getStatusText()}
                    </Paragraph>
                </Row>
            </Column>
        )
    }

    private renderTooltipMonitoringReport() {
        const monitoringReportTitle = this.props.assessment?.monitoringReport?.name

        if (!monitoringReportTitle) {
            return
        }

        return (
            <Column smallSpacing={true}>
                <Paragraph small={true} bold={true}>
                    {localize.translate(t => t.Entities.Monitoring)}
                </Paragraph>
                <Paragraph small={true}>{monitoringReportTitle}</Paragraph>
            </Column>
        )
    }

    private renderTooltipDescription() {
        const { assessment } = this.props

        if (!assessment?.description) {
            return
        }

        // this truncates 2nd row half way
        const description =
            assessment.description.length > 80 ? `${assessment.description.slice(0, 60)}...` : assessment.description

        return (
            <Column smallSpacing={true}>
                <Paragraph bold={true} small={true}>
                    {localize.translate(t => t.Generic.explanation)}
                </Paragraph>
                <Markdown paragraphLike={true} source={description} className={this.bem.getElement('description')} />
            </Column>
        )
    }

    private getStatusText() {
        const status = this.props.assessment?.grade

        switch (status) {
            case TopicAssessmentGradeType.assessmentSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.COMPLIANT)
            case TopicAssessmentGradeType.assessmentAlmostSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.ALMOST_COMPLIANT)
            case TopicAssessmentGradeType.assessmentNotSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_COMPLIANT)
            default:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_ASSESSED)
        }
    }
}
