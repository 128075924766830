import './PermissionsCheckboxList.scss'

import React from 'react'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { isEqual } from 'lodash-es'
import { EmployeePermissionEnum } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    name: string
    onChange?: (data: PermissionsConfigType[]) => void
    defaultPermissions: EmployeePermissionEnum[]
    disabled: boolean
    hidePermissions?: EmployeePermissionEnum[]
    showLabels?: boolean
    noUnderline?: boolean
}

interface State {
    permissionsConfig: PermissionsConfigType[]
}

export interface PermissionsConfigType {
    name: EmployeePermissionEnum
    active: boolean
}

export class PermissionCheckboxList extends React.Component<React.PropsWithChildren<Props>, State> {
    private static allPermissions = [
        EmployeePermissionEnum.canArchiveInboxItems,
        EmployeePermissionEnum.canDeleteCompliance,
        EmployeePermissionEnum.canMonitorSignaling,
        EmployeePermissionEnum.canSetControlOperations,
        EmployeePermissionEnum.canAccessMonitoringControls,
    ]

    public filteredPermissions = PermissionCheckboxList.filterPermissions(this.props.hidePermissions)
    public state: State = {
        permissionsConfig: this.getDefaultValue(),
    }

    private bem = new BEM('PermissionCheckboxList', () => ({
        disabled: this.props.disabled,
        noUnderline: this.props.noUnderline,
    }))
    private loc = localize.namespaceTranslate(t => t.User.Permissions)
    private locAbbr = localize.namespaceTranslate(t => t.User.PermissionsAbbr)

    public componentDidMount() {
        const { onChange } = this.props

        if (onChange) {
            onChange(this.state.permissionsConfig)
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.defaultPermissions, this.props.defaultPermissions)) {
            const defaultValues = this.getDefaultValue()

            this.setState({ permissionsConfig: defaultValues })

            const { onChange } = this.props
            if (onChange) {
                onChange(defaultValues)
            }
        }
    }

    public render() {
        const { disabled, showLabels } = this.props
        const data = this.getCheckboxListData()

        return (
            <div className={this.bem.getClassName()}>
                {data &&
                    data.map((item, index) => (
                        <React.Fragment key={`${index}-checkboxlist-item`}>
                            <Row>
                                <Checkbox
                                    disabled={disabled}
                                    className={this.bem.getElement('checkbox')}
                                    name={item.name}
                                    checked={this.getCheckboxState(item.name)}
                                    onChange={item.onChange}
                                    label={item.label}
                                />
                                {showLabels && (
                                    <LabelTag
                                        subtle={true}
                                        label={item.labelAbbr}
                                        className={this.bem.getElement('label')}
                                    />
                                )}
                            </Row>
                        </React.Fragment>
                    ))}
            </div>
        )
    }

    private getCheckboxListData() {
        return this.filteredPermissions.map((permissionItem: EmployeePermissionEnum) => ({
            label: this.loc(t => t[permissionItem]) || '',
            labelAbbr: this.locAbbr(t => t[permissionItem]) || '',
            name: permissionItem,
            onChange: (checked: boolean) => this.onCheckboxChange(checked, permissionItem),
        }))
    }

    private getDefaultValue() {
        const { defaultPermissions } = this.props

        return this.filteredPermissions.map((permission: EmployeePermissionEnum) => ({
            name: permission,
            active: !!defaultPermissions.find(defaultPermission => permission === defaultPermission),
        }))
    }

    private onCheckboxChange = (checked: boolean, permission: EmployeePermissionEnum) => {
        const { permissionsConfig } = this.state
        const { onChange } = this.props

        const newPermissions = permissionsConfig.map(permissionConfig => {
            if (permissionConfig.name === permission) {
                return { ...permissionConfig, active: checked }
            }
            return permissionConfig
        })

        this.setState(
            {
                permissionsConfig: newPermissions,
            },
            () => {
                if (onChange) {
                    onChange(newPermissions)
                }
            }
        )
    }

    private getCheckboxState(name: EmployeePermissionEnum) {
        const permission = this.state.permissionsConfig.find(config => config.name === name)

        if (!permission) {
            return false
        }

        return permission.active
    }

    private static filterPermissions(hidePermissions: EmployeePermissionEnum[] | undefined) {
        if (hidePermissions) {
            return PermissionCheckboxList.allPermissions.filter(permission => !hidePermissions.includes(permission))
        }

        return PermissionCheckboxList.allPermissions
    }
}
