import React from 'react'
import gql from 'graphql-tag'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'

const UNARCHIVE_INBOX_NEWS_ITEMS = gql`
    mutation unarchiveAlertOnInbox($departmentId: Int!, $alertId: Int!, $customerSlug: String) {
        unarchiveAlertOnInbox(departmentId: $departmentId, alertId: $alertId) {
            id
            name
            publishedAt
            firstTask(departmentId: $departmentId) {
                id
                name
                createdAt
                createdBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                            avatar
                        }
                    }
                }
            }
            inboxStatus(departmentId: $departmentId) {
                id
                archivedReason
                archivedAt
                archivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
                unarchivedAt
                unarchivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
            }
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFragment}
`

export interface UnarchiveInboxNewsItemMutationResponse {
    unarchiveAlertOnInbox: {
        id: string
        name: string
    }
}

export interface UnarchiveInboxNewsItemVariables {
    departmentId: number
    alertId: number
    customerSlug: string
}

export type UnarchiveAlertMutateFN = MutationFn<UnarchiveInboxNewsItemMutationResponse, UnarchiveInboxNewsItemVariables>

interface Props {
    children: (
        mutation: MutationFn<UnarchiveInboxNewsItemMutationResponse, UnarchiveInboxNewsItemVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const UnarchiveAlertMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<UnarchiveInboxNewsItemMutationResponse, UnarchiveInboxNewsItemVariables>
        mutation={UNARCHIVE_INBOX_NEWS_ITEMS}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
