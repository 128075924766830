import './Tooltip.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { animations } from '~/animations'
import { default as ReactTooltip } from 'react-tooltip-lite'

interface Props {
    className?: ClassValue
    content?: React.ReactNode
    title?: string
    message?: string
    children?: JSX.Element
    disabled?: boolean
    distance?: number
    direction?: ToolTipDirection
    openOnClick?: boolean
}

export enum ToolTipDirection {
    up = 'up',
    upStart = 'up-start',
    upEnd = 'up-end',
    down = 'down',
    downStart = 'down-start',
    downEnd = 'down-end',
    left = 'left',
    leftStart = 'left-start',
    leftEnd = 'left-end',
    right = 'right',
    rightStart = 'right-start',
    rightEnd = 'right-end',
}

export class Tooltip extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('Tooltip')

    public render() {
        const { children, disabled, className, distance, direction, openOnClick } = this.props

        if (disabled) {
            return children
        }

        const tooltipContent = this.getTootltipContent()
        const eventProps = openOnClick ? { eventOn: 'onClick' } : { eventOff: 'onClick' }

        return (
            <TransitionGroup component={null}>
                <CSSTransition classNames={animations.fadeIn} timeout={225} in={true}>
                    <ReactTooltip
                        distance={distance || 10}
                        arrow={false}
                        className={this.bem.getClassName(className)}
                        content={tooltipContent}
                        direction={direction}
                        {...eventProps}
                    >
                        <>{children}</>
                    </ReactTooltip>
                </CSSTransition>
            </TransitionGroup>
        )
    }

    private getTootltipContent() {
        const { content, title, message } = this.props

        if (content) {
            return content
        }

        return (
            <div className={this.bem.getElement('content')}>
                {title && <h4 className={this.bem.getElement('title')}>{title}</h4>}
                {message && <p className={this.bem.getElement('message')}>{message}</p>}
            </div>
        )
    }
}
