import './ControlsWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { localize } from '~/bootstrap'
import { Bold } from '~/components/Core/Typography/Bold'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import {
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    DashboardControlWidgetFragmentFragment,
    DashboardDepartmentSpecificWidgetMetadataType,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { routes } from '~/views/routes'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

enum TranslationKey {
    outdated = 'outdated',
    active = 'active',
}

export class ControlsWidget extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ControlsWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.ControlsWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.controls,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.controls,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const { oldCount, activeCount } = this.getCounts()
        const controlPageLink = routes.customer(this.context.customer.slug).compliance.controls.index
        const controlPageWithFilterLink = `${controlPageLink}?search=&controlEffectivity%5Blabel%5D=Laag&controlEffectivity%5Bvalue%5D=LOW`

        return (
            <Row>
                {this.renderColumn(oldCount, TranslationKey.outdated, controlPageLink)}
                {this.renderColumn(activeCount, TranslationKey.active, controlPageWithFilterLink)}
            </Row>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getCounts() {
        const { content } = this.props
        if (!content) {
            return { oldCount: 0, activeCount: 0 }
        }

        return content as DashboardControlWidgetFragmentFragment
    }

    private renderColumn(count: number, translationKey: TranslationKey, link: string) {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const text = this.loc(t => t[translationKey])
        const isDanger = translationKey === TranslationKey.outdated

        return (
            <WidgetInfoLink departmentSpecificMetadata={metadata} to={link}>
                <Column smallSpacing={true} className={this.bem.getElement('container', () => ({ isDanger }))}>
                    <Bold className={this.bem.getElement('count')}>{count}</Bold>
                    <Paragraph subtle={true} bold={true} className={this.bem.getElement('text')}>
                        {text}
                    </Paragraph>
                </Column>
            </WidgetInfoLink>
        )
    }
}
