import React from 'react'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { BEM, ClassValue } from '~/services/BEMService'
import './LinkedMonitoringReportCard.scss'
import { MonitoringReportLabel } from '../MonitoringReportLabel'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'

interface Props {
    className?: ClassValue
    rightComponent?: React.ReactNode
    faded?: boolean
    onClick?: () => void
    monitoringReport: MonitoringReportsQueryType
}

interface State {}

export class LinkedMonitoringReportCard extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('LinkedMonitoringReportCard', () => ({
        'is-faded': !!this.props.faded,
    }))

    public render() {
        const { onClick, className, rightComponent, monitoringReport } = this.props

        return (
            <Card className={this.bem.getClassName(className)} onClick={onClick}>
                <MonitoringReportLabel
                    className={this.bem.getElement('label')}
                    monitoringReport={monitoringReport}
                    hideLink={true}
                />
                {rightComponent}
            </Card>
        )
    }
}
