import './LinkedItemsTable.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { TableList } from '~/components/Core/DataDisplay/TableList/TableList'
import { LinkedItemCard, LinkedItemData } from './LinkedItemCard'

interface Props {
    title: (isOpen: boolean) => string
    noItemsTitle: string
    tableItems: LinkedItemData[]
    className?: ClassValue
    wide?: boolean
}

interface State {
    isOpen: boolean
}

export class LinkedItemsTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        isOpen: false,
    }

    private noItems = !this.props.tableItems.length
    private bem = new BEM('LinkedItemsTable', () => ({ noItems: this.noItems }))

    public render() {
        const { className, title, noItemsTitle } = this.props
        const { isOpen } = this.state

        const arrowIcon = isOpen ? IconType.arrowUp : IconType.arrowDown

        return (
            <div className={this.bem.getClassName(className)}>
                <div
                    onClick={() => !this.noItems && this.setState({ isOpen: !this.state.isOpen })}
                    className={this.bem.getElement('header')}
                >
                    <Paragraph bold={true}>{this.noItems ? noItemsTitle : title(isOpen)}</Paragraph>
                    <Icon className={this.bem.getElement('arrow')} type={arrowIcon} />
                </div>

                {isOpen && this.renderTable()}
            </div>
        )
    }

    private renderTable() {
        const { wide, tableItems } = this.props

        return (
            <TableList columns={wide ? 4 : 2} className={this.bem.getElement('list')}>
                {tableItems.map((i, n) => (
                    <LinkedItemCard key={n} itemData={i} />
                ))}
            </TableList>
        )
    }
}
