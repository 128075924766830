import './CustomerDomainItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Avatar, AvatarSize } from '~/components/Core/DataDisplay/Avatar/Avatar'
import { TextLink } from '~/components/Core/Text/TextLink'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    name: string
    suffix: string
    uri: string | null
    to: string
    smallAvatar?: boolean
}

interface State {}

export class CustomerAvatar extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('CustomerDomainItem')

    public render() {
        const { className, name, suffix, uri, to, smallAvatar } = this.props

        return (
            <TextLink
                className={this.bem.getElement('link')}
                to={{
                    pathname: to,
                    state: {
                        lastConsultantRoute: window.location.pathname,
                    },
                }}
            >
                <div className={this.bem.getClassName(className)}>
                    <Avatar
                        className={this.bem.getElement('avatar')}
                        label={name}
                        uri={uri || undefined}
                        size={smallAvatar ? undefined : AvatarSize.large}
                    />
                    <h3 className={this.bem.getElement('name')}>{name}</h3>
                    <Paragraph className={this.bem.getElement('suffix')}>{suffix}</Paragraph>
                </div>
            </TextLink>
        )
    }
}
