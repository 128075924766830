import './CustomerFeedsTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { CustomerSubscribedToFeedType, CustomerSubscribedToFeedTypeFrequencyTypeEnumType } from '~/generated/graphql'
import { routes } from '~/views/routes'
import { CustomerFeedStatus } from './CustomerFeedStatus'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    feeds: CustomerSubscribedToFeedType[]
}

export class CustomerFeedsTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds)
    private bem = new BEM('CustomerFeedsTable')

    private tableColumns = [
        { field: 'customerName', headerLabel: this.loc(t => t.properties.customer) },
        { field: 'feedType', headerLabel: this.loc(t => t.properties.feedType) },
        { field: 'amountOfExports', headerLabel: this.loc(t => t.properties.amountOfExports) },
        { field: 'frequency', headerLabel: this.loc(t => t.properties.frequency) },
        { field: 'status', headerLabel: this.loc(t => t.properties.status) },
    ]

    public render() {
        const tableData = this.getTableData()

        return <Table className={this.bem.getClassName()} columns={this.tableColumns} data={tableData} />
    }

    private getTableData() {
        const { feeds } = this.props

        return feeds.map(({ id, customer, type, exports, frequency, frequencyType, isActive }) => ({
            id,
            columns: {
                customerName: this.getCustomerName(id, customer),
                feedType: this.loc(t => t.feedTypes[type]),
                amountOfExports: exports?.totalCount || 0,
                frequency: this.getFrequencyLabel(frequency, frequencyType),
                status: <CustomerFeedStatus isActive={isActive} />,
            },
        }))
    }

    private getCustomerName(id: number, customer: CustomerSubscribedToFeedType['customer']) {
        return (
            <Row extraSmallSpacing={true}>
                <TableLink to={routes.consultant.customerfeeds.view(id)}>{customer.name}</TableLink>
                {customer.deletedAt && (
                    <Paragraph subtle={true}>{localize.translate(t => t.Generic.deleted)}</Paragraph>
                )}
            </Row>
        )
    }

    private getFrequencyLabel = (
        frequency: number,
        frequencyType: CustomerSubscribedToFeedTypeFrequencyTypeEnumType
    ): string => {
        const type = this.loc(t => t.frequencyType[frequencyType], { smart_count: frequency })

        return this.loc(t => t.frequency, {
            smart_count: frequency,
            type,
        })
    }
}
