import React from 'react'
import { PageContainer } from '~/components/Core/Layout/PageContainer'
import { routes } from '~/views/routes'
import { DashboardView } from '../Consultant/Dashboard/DashboardView'
import { AppNotFoundView } from '../AppNotFoundView'
import { AppLayout } from '~/components/Core/Layout/AppLayout'
import { OrganizationView } from './Organization/OrganizationView'
import { CustomersView } from './Customers/CustomersView'
import { errorClient, sessionClient, notification } from '~/bootstrap'
import { ErrorType } from '~/services/ErrorService'
import { apolloClient } from '~/services/ApolloService'
import { SessionManager } from '../SessionManager'
import { ConsultantNavigation } from '~/components/Chrome/Navigation/ConsultantNavigation'
import { CustomerFeedsView } from './CustomerFeeds/CustomerFeedsView'
import { CustomerGroupsView } from './CustomerGroups/CustomerGroupsView'
import { ConsultantTaskTemplatesOverview } from './ConsultantTaskTemplates/ConsultantTaskTemplatesOverview'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route, Navigate } from 'react-router-dom'
import { newRoutes } from '../newRoutes'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

class ConsultantViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public componentDidMount() {
        errorClient.subscribeToType(ErrorType.authentication, this.handleAuthenticationError)
    }

    public componentWillUnmount() {
        errorClient.unsubscribeToType(ErrorType.authentication, this.handleAuthenticationError)
    }

    public handleAuthenticationError = () => {
        sessionClient.removeSession()
        apolloClient.resetStore().then(() => {
            notification.info('Sessie verlopen')
            this.props.history.push(routes.cover.login)
        })
    }

    public render() {
        return (
            <SessionManager isConsultant>
                <AppLayout isConsultantLayout={true}>
                    <ConsultantNavigation />
                    <PageContainer>
                        <Routes>
                            <Route path={newRoutes.consultant.dashboard.index} element={<DashboardView />} />
                            <Route path={newRoutes.consultant.customerGroups.index} element={<CustomerGroupsView />} />
                            <Route path={newRoutes.consultant.customers.index} element={<CustomersView />} />
                            <Route path={newRoutes.consultant.customerfeeds.index} element={<CustomerFeedsView />} />
                            <Route path={newRoutes.consultant.organization.index} element={<OrganizationView />} />
                            <Route
                                path={newRoutes.consultant.taskTemplates.index}
                                element={<ConsultantTaskTemplatesOverview />}
                            />
                            <Route path="*" element={<Navigate replace to={routes.consultant.dashboard.index} />} />
                            <Route element={<AppNotFoundView />} />
                        </Routes>
                    </PageContainer>
                </AppLayout>
            </SessionManager>
        )
    }
}

export const ConsultantView = withRouter(ConsultantViewComponent)
