import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { ControlDocument } from '../ControlDetail/ControlQuery'
import { DocumentFields } from '../DocumentFields'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    requestClose: () => void
    controlName: string
    controlId: number
    existingDocumentData: ControlDocument
    archiveLoading?: boolean
    deleteLoading?: boolean
    editLoading?: boolean
    onEdit: (formState: FormState) => void
    onDelete: (closeModal: Function) => void
    onArchive: () => void
}

interface State {
    repeats: boolean
}

export class EditControlDocumentModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        repeats: false,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.EditView)

    public render() {
        const { requestClose, editLoading, onEdit, existingDocumentData } = this.props

        return (
            <Form isCompact={true} onSubmit={onEdit}>
                <Modal
                    title={localize.translate(
                        t => t.Customer.Compliance.ControlDocument.EditControlDocumentModal.title
                    )}
                    requestClose={requestClose}
                    submitForm={true}
                    loading={editLoading}
                    actions={requestClose => this.renderActionButtons(requestClose)}
                >
                    <ErrorMessage path={'editControlDocument'} />
                    <DocumentFields
                        document={existingDocumentData}
                        onChange={value => this.setState({ repeats: value })}
                    />
                </Modal>
            </Form>
        )
    }

    private renderConfirmModal() {
        const { requestClose, onDelete, deleteLoading } = this.props

        return (
            <ConfirmModal
                onConfirm={async closeModal => {
                    onDelete(() => {
                        closeModal()
                        requestClose()
                    })
                }}
                loading={deleteLoading}
                title={this.loc(t => t.deleteModalTitle)}
                confirmButtonLabel={localize.translate(t => t.Core.Modal.removeDefinitely)}
                renderContent={() => (
                    <Column bigSpacing={true}>
                        <ErrorMessage path={'deleteControl'} />
                        <Paragraph>{this.loc(t => t.deleteModalMessage)}</Paragraph>
                    </Column>
                )}
            >
                {openModal => (
                    <Button type={ButtonType.secondary} danger={true} onClick={openModal}>
                        {localize.translate(t => t.Core.Modal.delete)}
                    </Button>
                )}
            </ConfirmModal>
        )
    }

    private renderActionButtons(requestClose?: () => void) {
        const { onArchive, archiveLoading } = this.props

        return (
            <Row spaceBetween={true}>
                <Row>
                    {this.renderConfirmModal()}
                    <Button type={ButtonType.secondary} onClick={onArchive} loading={archiveLoading}>
                        {localize.translate(
                            t => t.Customer.Compliance.ControlDocument.EditControlDocumentModal.archive
                        )}
                    </Button>
                </Row>

                <Row alignRight={true}>
                    <Button type={ButtonType.tertiary} onClick={requestClose}>
                        {localize.translate(t => t.Core.Modal.cancel)}
                    </Button>
                    <Button submit={true}>{localize.translate(t => t.Generic.save)}</Button>
                </Row>
            </Row>
        )
    }
}
