import React from 'react'
import { AllTasksTable } from '~/views/Customer/Tasks/AllTasks/AllTasksTable'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { LinkedTaskItem, getTypenameFromLinkedItemType } from '~/graphql/types/Task'
import { TasksPaginatableQuery } from '~/views/Customer/Tasks/TasksQuery/TasksPaginatableQuery'

interface Props {
    linkedItem: LinkedTaskItem
    completedOnly?: boolean
    refetch?: () => void
}

interface State {
    sort?: {
        [key: string]: SortDirection
    }
}

export class TasksForLinkedItemTableContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        sort: {
            dueAt: 'DESC',
        },
    }

    public render() {
        const { sort } = this.state
        const { linkedItem, completedOnly } = this.props

        const { linkedItemId: itemId, linkedItemType: itemType } = getTypenameFromLinkedItemType(linkedItem)
        const forLinkedItem = itemId && itemType ? { itemId, itemType } : undefined

        return (
            <TasksPaginatableQuery sort={sort} filters={{ forLinkedItem, completedOnly }}>
                {({ data, loading, loadingMore, canFetchMore, fetchMore, refetch }) => {
                    const tasks = data ? data.nodes : []
                    const amount = data && data.totalCount ? data.totalCount - data.nodes.length : 0
                    const buttonLabel = localize.translate(t => t.Customer.Planning.Tasks.showAmountMoreTasks, {
                        amount,
                    })

                    return (
                        <>
                            <AllTasksTable
                                tasks={tasks}
                                loading={loading}
                                loadingMore={loadingMore}
                                refetchTasks={refetch}
                                onTaskStatusChange={this.props.refetch}
                                onSortDirectionChange={this.handleSortChange}
                                defaultSortDirection={{ field: 'dueAt', direction: 'DESC' }}
                                noLinkedItemIcon={true}
                            />
                            {!loadingMore && canFetchMore && (
                                <Row alignCenter={true}>
                                    <Button icon={IconType.arrowDown} type={ButtonType.actionLink} onClick={fetchMore}>
                                        {buttonLabel}
                                    </Button>
                                </Row>
                            )}
                        </>
                    )
                }}
            </TasksPaginatableQuery>
        )
    }

    private handleSortChange = (field: string, newDirection: SortDirection) => {
        this.setState({
            sort: {
                [field]: newDirection,
            },
        })
    }
}
