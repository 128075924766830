import './FrameworkIconList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    align?: AlignIconList
}

export enum AlignIconList {
    left = 'left',
    right = 'right',
    none = 'none',
}

interface State {}

export class FrameworkIconList extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static defaultProps: Partial<Props> = {
        align: AlignIconList.left,
    }

    private bem = new BEM('FrameworkIconList', () => ({
        [`align-${this.props.align}`]: !!this.props.align,
    }))

    public render() {
        const { children, className } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
