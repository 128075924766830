import './CardRow.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { NavLink } from 'react-router-dom'

interface Props {
    className?: ClassValue
    to?: string
}

interface State {}

export class CardRow extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('CardRow', () => ({
        'is-link': !!this.props.to,
    }))

    public render() {
        const { children, to, className } = this.props

        if (to) {
            return (
                <NavLink to={to} className={this.bem.getClassName(className)}>
                    {children}
                </NavLink>
            )
        }

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
