import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlsOverviewView } from './ControlsOverviewView'
import { ControlsDetailView } from './ControlsDetailView'
import { ControlsEditView } from './ControlsEditView'
import { Routes, Route } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

export const ControlsView = withRouter(
    class ControlsView extends React.PureComponent<React.PropsWithChildren<Props>> {
        public static contextType = CustomerContext
        public context: CustomerContextValue

        public render() {
            return (
                <Routes>
                    <Route path={''} element={<ControlsOverviewView />} />
                    <Route
                        path={newRoutes.customer(this.context.customer.slug).compliance.controls.view()}
                        element={<ControlsDetailView />}
                    />
                    <Route
                        path={newRoutes.customer(this.context.customer.slug).compliance.controls.edit()}
                        element={<ControlsEditView />}
                    />
                    <Route element={<AppNotFoundView />} />
                </Routes>
            )
        }
    }
)
