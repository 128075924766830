import './TopicDesignAndEffectivenessLogsModal.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Timeline, TimelineEventType } from '~/components/Core/DataDisplay/Timeline/Timeline'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    EventLogType,
    TopicAssessmentDeLinkedItemType,
    TopicLogsDocument,
    TopicLogsQueryVariables,
} from '~/generated/graphql'
import { TopicControlMeasureEvent } from '../TopicDesignAndEffectivenessEvent/TopicControlMeasureEvent'
import { TopicNoteEvent } from '../TopicDesignAndEffectivenessEvent/TopicNoteEvent'
import { BEM } from '~/services/BEMService'

interface Props {
    requestClose: () => void
    linkedItemId: number
    linkedItemType: TopicAssessmentDeLinkedItemType
}

export class TopicDesignAndEffectivenessLogsModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    private bem = new BEM('TopicDesignAndEffectivenessLogsModal')

    public render() {
        const { requestClose, linkedItemId, linkedItemType } = this.props
        const title = localize.translate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable.log)

        return (
            <Modal title={title} requestClose={requestClose} hideButtons={true} className={this.bem.getClassName()}>
                <PageQuery<EventLogType[], TopicLogsQueryVariables>
                    query={TopicLogsDocument}
                    variables={{
                        linkedItemType,
                        linkedItemId,
                        departmentId: this.context.activeDepartmentId,
                    }}
                >
                    {data => (
                        <div className={this.bem.getElement('padding')}>
                            <Timeline events={this.getTimelineEvents(data)} />
                        </div>
                    )}
                </PageQuery>
            </Modal>
        )
    }

    private getTimelineEvents(events: EventLogType[]): TimelineEventType[] {
        const { linkedItemType } = this.props
        const RenderComponent =
            linkedItemType === TopicAssessmentDeLinkedItemType.controlmeasure
                ? TopicControlMeasureEvent
                : TopicNoteEvent

        const deletedFilePaths = this.getDeletedFilePaths(events)

        return events.map(event => ({
            date: event.occuredOn,
            content: () =>
                React.createElement(RenderComponent, {
                    event,
                    deletedFilePaths,
                }),
        }))
    }

    private getDeletedFilePaths(events: EventLogType[]): string[] {
        return events
            .filter(
                e => e.metaData?.removedTopicAssessmentDocumentFiles?.length || e.metaData?.removedDocumentFiles?.length
            )
            .flatMap(e => e.metaData?.removedTopicAssessmentDocumentFiles || e.metaData?.removedDocumentFiles || [])
            .map(e => e.path)
    }
}
