import './TopicDesignAndEffectivenessLinkedArticlesModal.scss'

import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { ControlTypeType, TopicAssessmentDeLinkedItemType, TopicLinkedItemsQuery } from '~/generated/graphql'
import { GroupedItem } from '~/graphql/types/LinkedItem'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { LinkList } from '~/components/Core/Layout/LinkList'
import { getNameForType, transformItemForLinkList } from '~/utils/linkedItemNaming'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Icon } from '~/components/Core/Icon/Icon'
import { getIconTypeForControlType } from '~/utils/controls'
import { IconType } from '~/components/Core/Icon/IconType'
import { SetTopicDnEArticles, SetTopicDnEArticlesMutationFN } from '../mutations/SetTopicDnEArticles'
import { isEqual } from 'lodash'
import { ExpiredArticleWarning } from '~/components/Domain/LawAndRegulation/ExpiredArticleWarning'

interface Props {
    data: TopicLinkedItemsQuery
    closeModal: () => void
    loading: boolean
    type: TopicAssessmentDeLinkedItemType
    typeId: number
    controlType?: ControlTypeType
    defaultArticleIds: number[]
    allArticles?: boolean
    onChange?: () => void
}

interface State {
    isEditing: boolean
    selectedIds: number[]
}

export class TopicDesignAndEffectivenessLinkedArticlesModal extends React.PureComponent<
    React.PropsWithChildren<Props>,
    State
> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        isEditing: false,
        selectedIds: this.getDefaultSelectedIds(),
    }

    private bem = new BEM('TopicDesignAndEffectivenessLinkedArticlesModal')
    private loc = localize.namespaceTranslate(
        t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessLinkedArticlesModal
    )

    public render() {
        const { closeModal } = this.props

        return (
            <Modal
                className={this.bem.getClassName()}
                requestClose={closeModal}
                title={this.loc(t => t.title)}
                actions={this.renderEditButton}
            >
                <Column bigSpacing={true}>
                    {this.renderHeaderLabel()}
                    {this.renderSelectAll()}
                    {this.renderLinkedItems()}
                </Column>
            </Modal>
        )
    }

    private getDefaultSelectedIds() {
        const { allArticles, defaultArticleIds, data } = this.props

        if (allArticles && data.topic?.linkedItems) {
            const ids: number[] = []
            for (const { items } of data.topic.linkedItems) {
                if (items?.length) {
                    ids.push(...items?.map(i => i.id))
                }
            }

            return ids
        }

        return defaultArticleIds
    }

    private renderEditButton = () => {
        const isNotPermitted = !permissions.canEditTopicDnE(this.context.activeDepartmentId)
        if (isNotPermitted) {
            return <></>
        }

        const { data, loading, defaultArticleIds } = this.props
        const { isEditing, selectedIds } = this.state
        if (isEditing) {
            const isPristine = isEqual(selectedIds, defaultArticleIds)

            return (
                <SetTopicDnEArticles>
                    {(mutate, { loading: mutateLoading }) => (
                        <Row alignRight={true}>
                            <Button
                                type={ButtonType.tertiary}
                                onClick={() => this.setState({ isEditing: false })}
                                disabled={mutateLoading || loading}
                            >
                                {localize.translate(t => t.Core.Modal.cancel)}
                            </Button>
                            <Button
                                loading={mutateLoading || loading}
                                disabled={mutateLoading || loading || isPristine}
                                onClick={this.handleSubmit(mutate)}
                            >
                                {localize.translate(t => t.Generic.save)}
                            </Button>
                        </Row>
                    )}
                </SetTopicDnEArticles>
            )
        }

        const isDisabled = !data.topic?.linkedItems?.length || loading

        return (
            <Row alignRight={true}>
                <Button disabled={isDisabled} onClick={() => this.setState({ isEditing: true })}>
                    {this.loc(t => t.select)}
                </Button>
            </Row>
        )
    }

    private renderHeaderLabel() {
        const { type, controlType } = this.props

        let iconType = IconType.assessmentScribble
        let text = localize.translate(t => t.Entities.Note)
        if (type === TopicAssessmentDeLinkedItemType.controlmeasure && controlType) {
            iconType = getIconTypeForControlType(controlType)
            text = localize.translate(t => t.Control.type[controlType])
        }

        return (
            <Row smallSpacing={true} fullWidth={true} className={this.bem.getElement('icon-container')}>
                <Icon type={iconType} className={this.bem.getElement('icon')} />
                <Paragraph bold={true}>{text}</Paragraph>
            </Row>
        )
    }

    private renderSelectAll() {
        const { selectedIds, isEditing } = this.state
        if (!isEditing) {
            return
        }

        const { data } = this.props

        const allIds: number[] = []
        data.topic?.linkedItems?.forEach(l => l.items?.forEach(i => allIds.push(i.id)))

        const hasAllChecked = selectedIds.length === allIds.length

        return (
            <Row className={this.bem.getElement('select-all-container')}>
                <Checkbox
                    name="selectWholeTopic"
                    checked={hasAllChecked}
                    onChange={checked => this.setState({ ...this.state, selectedIds: checked ? allIds : [] })}
                />
                <Paragraph bold={true}>{this.loc(t => t.selectAll, { smart_count: allIds.length })}</Paragraph>
            </Row>
        )
    }

    private renderLinkedItems() {
        const linkedItems = this.getLinkedItems()

        return (
            <div className={this.bem.getElement('container')}>
                {linkedItems.map((group, index) => (
                    <LinkList
                        className={this.bem.getElement('link-list')}
                        key={group.type + index}
                        showCheckboxes={this.state.isEditing}
                        title={getNameForType(group)}
                        renderExpiredStatus={(_, expired) => (
                            <ExpiredArticleWarning
                                expired={expired}
                                className={this.bem.getElement('expired', () => ({ isEditing: this.state.isEditing }))}
                            />
                        )}
                        items={group.items.map(item =>
                            transformItemForLinkList(
                                group,
                                item,
                                this.context.customer.slug,
                                this.getItemCheckbox(item)
                            )
                        )}
                    />
                ))}
            </div>
        )
    }

    private handleSubmit = (mutate: SetTopicDnEArticlesMutationFN) => async () => {
        const { selectedIds } = this.state
        const { data, typeId, type, closeModal, onChange } = this.props

        const response = await mutate({
            variables: {
                topicId: data.topic!.id,
                linkedItemId: typeId,
                linkedItemType: type,
                lawArticleIds: selectedIds,
                departmentId: this.context.activeDepartmentId,
            },
        })

        if (response && response.data?.setArticlesForTopicControlMeasureOrNote) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))

            if (onChange) {
                onChange()
            }

            closeModal()
        }
    }

    private getItemCheckbox(item: any) {
        const { selectedIds } = this.state
        const isChecked = selectedIds.includes(item.id)

        return (
            <Checkbox
                name="linkedArticle"
                onChange={checked => this.handleCheckboxChange(checked, item.id)}
                checked={isChecked}
            />
        )
    }

    private handleCheckboxChange(checked: boolean, id: number) {
        const { selectedIds } = this.state
        const isAlreadySelected = selectedIds.includes(id)

        const checkedNewId = checked && !isAlreadySelected
        if (checkedNewId) {
            this.setState({ selectedIds: [...selectedIds, id] })
            return
        }

        const uncheckedId = !checked && isAlreadySelected
        if (uncheckedId) {
            this.setState({ selectedIds: selectedIds.filter(selectedId => selectedId !== id) })
        }
    }

    private getLinkedItems() {
        const { data, defaultArticleIds, allArticles } = this.props
        if (!data.topic?.linkedItems) {
            return []
        }

        const { isEditing } = this.state
        if (isEditing || allArticles) {
            return data.topic.linkedItems as GroupedItem[]
        }

        return data.topic.linkedItems.map(linkedItem => ({
            ...linkedItem,
            items: linkedItem.items?.filter(i => defaultArticleIds.includes(i.id)),
        })) as GroupedItem[]
    }
}
