import './EmployeeDetailEditView.scss'

import React from 'react'
import { Mutation, MutationFn, OperationVariables, Query } from 'react-apollo'
import { localize, notification, permissions, userClient } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerRoleSelect } from '~/components/Domain/Customer/CustomerRoleSelect'
import { EmployeeDigestFrequencySelect } from '~/components/Domain/Customer/EmployeeDigestFrequencySelect'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { PermissionsConfigType } from '~/components/Domain/Settings/Permissions/PermissionsCheckboxList'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ErrorProvider } from '~/components/Providers/ErrorProvider'
import {
    CheckEmailExistsInCustomerGroupDocument,
    CheckEmailExistsInCustomerGroupQuery,
    CheckEmailExistsInCustomerGroupQueryVariables,
    EditEmployeeMutationDocument,
    EditEmployeeMutationMutation,
    EmployeeDocument,
    EmployeePermissionEnum,
    EmployeeRole,
    EmployeeType,
    UserType,
} from '~/generated/graphql'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RadioGroup } from '~/components/Core/DataEntry/Form/RadioGroup'
import { isString, xor } from 'lodash-es'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { MultiCustomerEmployeeEmailChangeModal } from '~/components/Domain/Settings/Employees/MultiCustomerEmployeeEmailChangeModal'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RulerEmployeeWarning } from '~/components/Domain/Settings/Employees/RulerEmployeeWarning'
import { BEM } from '~/services/BEMService'
import { EmployeeNewTaskNotificationEmail } from '~/components/Domain/Customer/EmployeeNewTaskNotificationEmail'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Column } from '~/components/Core/Layout/Column'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {
    selectedRole: EmployeeRole | null
    selectedPermissions: EmployeePermissionEnum[] | null
    editedEmail: string | null
    showLogoutWarning: boolean
}

class EmployeeDetailEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        selectedRole: null,
        selectedPermissions: null,
        editedEmail: null,
        showLogoutWarning: false,
    }

    private bem = new BEM('EmployeeDetailEditView')
    private employeeDetailLoc = localize.namespaceTranslate(t => t.Customer.Settings.SettingsEmployeeDetailView)
    private loc = localize.namespaceTranslate(t => t.User.attributes)
    private buttonLoc = localize.namespaceTranslate(t => t.Generic)

    private permissionsToHide = this.context.customer.editorialAccess
        ? []
        : [EmployeePermissionEnum.canMonitorSignaling]

    private formRef = React.createRef<Form>()
    private applyToAllUsers: boolean

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<EmployeeType>
                query={EmployeeDocument}
                variables={{ id, customerSlug: this.context.customer.slug }}
            >
                {employee => {
                    const user = employee?.user

                    if (!user) {
                        return null
                    }

                    return (
                        <Column extraBigSpacing className={this.bem.getClassName()}>
                            {this.renderPageHeader(user)}
                            {this.renderForm(employee)}
                        </Column>
                    )
                }}
            </PageQuery>
        )
    }

    private renderPageHeader(user: UserType) {
        const { profile, id } = user
        const { fullName } = profile

        const title = permissions.isCurrentUser(id)
            ? localize.translate(t => t.Customer.Settings.SettingsEmployeeDetailView.myDetails)
            : fullName || ''

        return (
            <PageHeader
                documentTitle={'Gebruiker bewerken'}
                title={title}
                breadCrumbs={[
                    breadcrumbs.customer(this.context.customer.slug).settings.index,
                    breadcrumbs.customer(this.context.customer.slug).settings.employees.index,
                ]}
            />
        )
    }

    private renderForm(employee: EmployeeType) {
        return (
            <Mutation<EditEmployeeMutationMutation> mutation={EditEmployeeMutationDocument}>
                {(mutate, { loading, error }) => (
                    <ErrorProvider error={error}>
                        <Form ref={this.formRef} onSubmit={this.handleFormSubmit(mutate, employee)}>
                            {this.renderFields(employee)}
                            {this.renderActionButtons(loading, employee)}
                        </Form>
                    </ErrorProvider>
                )}
            </Mutation>
        )
    }

    private renderFields(employee: EmployeeType) {
        const { user, role, newTaskNotificationEmail } = employee
        const { ssoLoginEnabled, isCustomerConsultant } = user
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <FieldSet noMarginOnLastChild={true}>
                <ErrorMessage path={'editEmployee'} />
                {this.renderPersonalDetails(employee)}
                <Field forInput={'role'} label={this.loc(t => t.roles)}>
                    <CustomerRoleSelect
                        onChange={(option: SelectOption) => {
                            this.setState({
                                selectedRole: option.value as EmployeeRole,
                            })
                        }}
                        name={'role'}
                        disabled={!permissions.allowedToEditCustomerRoles(parseInt(id, 10))}
                        defaultValue={role}
                    />
                </Field>
                {this.renderPermissionCheckboxList(employee)}
                {this.renderOperationCheckboxList(employee)}
                {(this.context.customer.ssoLoginEnabled || this.context.customer.customerGroup?.ssoLoginEnabled) && (
                    <Field label={this.loc(t => t.ssoLoginEnabled)}>
                        <RadioGroup>
                            <Form.Radio
                                name={'ssoLoginEnabled'}
                                value={true}
                                label={this.loc(t => t.ssoLoginEnabledYes)}
                                defaultChecked={ssoLoginEnabled}
                                disabled={isCustomerConsultant}
                            />
                            <Form.Radio
                                name={'ssoLoginEnabled'}
                                value={false}
                                defaultChecked={!ssoLoginEnabled}
                                label={this.loc(t => t.ssoLoginEnabledNo)}
                                disabled={isCustomerConsultant}
                            />
                        </RadioGroup>
                    </Field>
                )}
                <SectionTitle>{this.loc(t => t.emailFrequency)}</SectionTitle>
                <Field forInput={'newTaskNotificationEmail'} label={this.loc(t => t.newTaskNotificationEmailSetting)}>
                    <EmployeeNewTaskNotificationEmail
                        name={'newTaskNotificationEmail'}
                        disabled={!permissions.isCurrentUser(user.id)}
                        defaultValue={newTaskNotificationEmail}
                    />
                </Field>
                {this.renderDigestOptions(employee)}
            </FieldSet>
        )
    }

    private renderActionButtons(loading: boolean, employee: EmployeeType) {
        const { id } = this.props.match.params

        return (
            <Row smallSpacing={true} alignRight={true}>
                <Button
                    to={routes.customer(this.context.customer.slug).settings.employees.detail(id)}
                    type={ButtonType.secondary}
                >
                    {this.buttonLoc(t => t.cancel)}
                </Button>
                {this.renderEditButton(loading, employee)}
            </Row>
        )
    }

    private renderPersonalDetails(employee: EmployeeType) {
        const { id, profile, isCustomerConsultant } = employee.user
        const { firstName, lastName } = profile

        const notAllowedToEdit =
            isCustomerConsultant && !permissions.isConsultantUser() && !permissions.isCurrentUser(id)

        if (notAllowedToEdit) {
            return (
                <>
                    <Field forInput={'firstName'} label={this.loc(t => t.firstName)}>
                        <Paragraph>{firstName}</Paragraph>
                    </Field>
                    <Field forInput={'lastName'} label={this.loc(t => t.lastName)}>
                        <Paragraph>{lastName}</Paragraph>
                    </Field>
                    {this.renderEmailField(employee, notAllowedToEdit)}
                </>
            )
        }

        return (
            <>
                <Field forInput={'firstName'} label={this.loc(t => t.firstName)}>
                    <Form.Input defaultValue={firstName} name={'firstName'} />
                </Field>

                <Field forInput={'lastName'} label={this.loc(t => t.lastName)}>
                    <Form.Input defaultValue={lastName} name={'lastName'} />
                </Field>
                {this.renderEmailField(employee)}
            </>
        )
    }

    private renderEmailField(employee: EmployeeType, notAllowedToEdit?: boolean) {
        const { email, hasActiveMergeRequest, isCustomerConsultant } = employee.user

        if (notAllowedToEdit) {
            return (
                <Field forInput={'email'} label={this.loc(t => t.email)}>
                    <Paragraph className={this.bem.getElement('email-text')}>{email}</Paragraph>
                    {this.renderActiveMergeRequestWarning(hasActiveMergeRequest)}
                    {isCustomerConsultant && <RulerEmployeeWarning />}
                </Field>
            )
        }

        const { editedEmail, showLogoutWarning } = this.state
        const { customerGroup } = this.context.customer
        const { id } = this.props.match.params

        const editingEmployeeId = isString(id) ? parseInt(id, 10) : id
        const isEditingSelf = editingEmployeeId === this.context.employee?.id

        return (
            <Query<CheckEmailExistsInCustomerGroupQuery, CheckEmailExistsInCustomerGroupQueryVariables>
                query={CheckEmailExistsInCustomerGroupDocument}
                variables={{ forEmployeeId: parseInt(id!, 10), email: editedEmail?.trim() || '' }}
            >
                {({ data, loading }) => {
                    if (!loading && customerGroup && isEditingSelf) {
                        if (data?.checkEmailExistsInCustomerGroup && !showLogoutWarning) {
                            this.setState({ ...this.state, showLogoutWarning: true })
                        }

                        if (!data?.checkEmailExistsInCustomerGroup && showLogoutWarning) {
                            this.setState({ ...this.state, showLogoutWarning: false })
                        }
                    }

                    return (
                        <>
                            <Field
                                forInput={'email'}
                                label={this.loc(t => t.email)}
                                className={this.bem.getElement('email-input')}
                            >
                                <Form.Input
                                    defaultValue={email}
                                    name={'email'}
                                    onChange={input => this.setState({ ...this.state, editedEmail: input || '' })}
                                />
                                {this.renderActiveMergeRequestWarning(hasActiveMergeRequest)}
                                {isCustomerConsultant && <RulerEmployeeWarning />}
                            </Field>
                        </>
                    )
                }}
            </Query>
        )
    }

    private renderPermissionCheckboxList(employee: EmployeeType) {
        if (this.shouldHidePermissions(employee)) {
            return null
        }

        return (
            <Field forInput={'permissions'} label={this.loc(t => t.permissions)}>
                <Form.PermissionCheckboxList
                    name={'permissions'}
                    defaultPermissions={this.getDefaultPermissions(employee) || []}
                    disabled={this.shouldDisablePermissions(employee)}
                    hidePermissions={this.permissionsToHide}
                    onChange={this.onChangePermissions}
                />
            </Field>
        )
    }

    private renderDigestOptions(employee: EmployeeType) {
        const {
            alertDigestFrequency,
            upcomingTasksDigestFrequency,
            myCompletedTasksDigestFrequency,
            standardTasksDigestFrequency,
        } = employee
        const disabled = !permissions.isCurrentUser(employee.user.id)

        return (
            <>
                <Field forInput="alertDigestFrequency" label={this.loc(t => t.alertDigestFrequency)}>
                    <EmployeeDigestFrequencySelect
                        name="alertDigestFrequency"
                        disabled={disabled}
                        defaultValue={alertDigestFrequency}
                    />
                </Field>
                <Field
                    tooltip={localize.translate(t => t.User.attributes.standardTasksTooltip)}
                    forInput="standardTasksDigestFrequency"
                    label={this.loc(t => t.standardTasksDigestFrequency)}
                >
                    <EmployeeDigestFrequencySelect
                        name={'standardTasksDigestFrequency'}
                        disabled={disabled}
                        defaultValue={standardTasksDigestFrequency}
                    />
                </Field>
                <Field
                    forInput="upcomingTasksDigestFrequency"
                    tooltip={localize.translate(t => t.User.attributes.upcomingTasksTooltip)}
                    label={this.loc(t => t.upcomingTasksDigestFrequency)}
                >
                    <EmployeeDigestFrequencySelect
                        name={'upcomingTasksDigestFrequency'}
                        disabled={disabled}
                        defaultValue={upcomingTasksDigestFrequency}
                    />
                </Field>
                <Field
                    forInput="myCompletedTasksDigestFrequency"
                    label={this.loc(t => t.myCompletedTasksDigestFrequency)}
                    tooltip={localize.translate(t => t.User.attributes.myCompletedTasksTooltip)}
                >
                    <EmployeeDigestFrequencySelect
                        name={'myCompletedTasksDigestFrequency'}
                        disabled={disabled}
                        defaultValue={myCompletedTasksDigestFrequency}
                    />
                </Field>
            </>
        )
    }

    private renderOperationCheckboxList(employee: EmployeeType) {
        if (!this.state.selectedPermissions) {
            return null
        }

        const defaultOperations = this.getDefaultOperations(employee)
        const defaultSelectedOperations = employee.operations?.filter(operation =>
            defaultOperations.includes(operation)
        )

        return (
            <Field
                forInput={'operations'}
                tooltip={localize.translate(t => t.User.attributes.operationsTooltip)}
                label={localize.translate(t => t.User.attributes.operations)}
            >
                <Form.OperationsCheckboxList
                    name="operations"
                    defaultOperations={this.getDefaultOperations(employee)}
                    defaultSelectedOperations={defaultSelectedOperations || []}
                />
            </Field>
        )
    }

    private handleFormSubmit =
        (mutate: MutationFn<EditEmployeeMutationMutation, OperationVariables>, employee: EmployeeType) =>
        async (formState: FormState) => {
            const id = parseInt(this.props.match.params.id!, 10)
            const { selectedPermissions, showLogoutWarning } = this.state

            const role = formState.role ? formState.role.value : undefined
            const newTaskNotificationEmail = formState.hasOwnProperty('newTaskNotificationEmail')
                ? formState.newTaskNotificationEmail
                : undefined
            const standardTasksDigestFrequency = formState.standardTasksDigestFrequency?.value || undefined
            const upcomingTasksDigestFrequency = formState.upcomingTasksDigestFrequency?.value || undefined
            const myCompletedTasksDigestFrequency = formState.myCompletedTasksDigestFrequency?.value || undefined
            const alertDigestFrequency = formState.alertDigestFrequency?.value || undefined
            const operations = selectedPermissions ? formState.operations : undefined

            const permissions = this.getPermissionsToSubmit(formState, employee)

            const response = await mutate({
                variables: {
                    customerSlug: this.context.customer.slug,
                    id,
                    fields: {
                        ...formState,
                        operations,
                        role,
                        alertDigestFrequency,
                        standardTasksDigestFrequency,
                        newTaskNotificationEmail,
                        upcomingTasksDigestFrequency,
                        myCompletedTasksDigestFrequency,
                        permissions,
                        applyToAllUsers: this.applyToAllUsers,
                    },
                },
            })

            if (response && response.data && response.data.editEmployee) {
                notification.success(localize.translate(t => t.User.userChangeSuccess))

                if (showLogoutWarning) {
                    await userClient.logout()
                    return
                }

                this.context.refetchUser()
                this.props.history.push(routes.customer(this.context.customer.slug).settings.employees.detail(id))
            }
        }

    private renderEditButton(loading: boolean, employee: EmployeeType) {
        const { editedEmail, showLogoutWarning } = this.state

        const hasEditedEmail = editedEmail && editedEmail !== employee.user.email
        const hasMultiEmployees = employee.userEmployees?.length && employee.userEmployees.length > 1
        const shouldConfirmEmailChange = hasEditedEmail && hasMultiEmployees

        if (!showLogoutWarning && !shouldConfirmEmailChange) {
            return (
                <Button submit={true} loading={loading}>
                    {this.buttonLoc(t => t.save)}
                </Button>
            )
        }

        if (showLogoutWarning && !shouldConfirmEmailChange) {
            return (
                <ConfirmModal
                    title={this.employeeDetailLoc(t => t.logoutWarningTitle)}
                    message={this.employeeDetailLoc(t => t.logoutWarningDescription, {
                        replacerEmail: editedEmail || '',
                    })}
                    onConfirm={closeModal => {
                        this.formRef.current?.triggerSubmit()
                        closeModal()
                    }}
                    confirmButtonLabel={localize.translate(
                        t => t.Customer.Settings.SettingsEmployeeDetailView.logoutWarningConfirmLabel
                    )}
                >
                    {openModal => (
                        <Button onClick={openModal} loading={loading}>
                            {this.buttonLoc(t => t.save)}
                        </Button>
                    )}
                </ConfirmModal>
            )
        }

        if (showLogoutWarning && shouldConfirmEmailChange) {
            return (
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} loading={loading}>
                            {this.buttonLoc(t => t.save)}
                        </Button>
                    )}
                    renderModal={closeModal => (
                        <ConfirmModal
                            title={this.employeeDetailLoc(t => t.logoutWarningTitle)}
                            message={this.employeeDetailLoc(t => t.logoutWarningDescription, {
                                replacerEmail: editedEmail || '',
                            })}
                            onCancel={closeModal}
                            onConfirm={() => {
                                this.formRef.current?.triggerSubmit()
                                closeModal()
                            }}
                        >
                            {openModal => (
                                <MultiCustomerEmployeeEmailChangeModal
                                    requestClose={closeModal}
                                    onSubmit={applyToAllUsers => {
                                        this.applyToAllUsers = applyToAllUsers
                                        openModal()
                                    }}
                                />
                            )}
                        </ConfirmModal>
                    )}
                />
            )
        }

        // !showLogoutWarning && shouldConfirmEmailChange
        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} loading={loading}>
                        {this.buttonLoc(t => t.save)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <MultiCustomerEmployeeEmailChangeModal
                        requestClose={closeModal}
                        onSubmit={applyToAllUsers => {
                            this.applyToAllUsers = applyToAllUsers
                            this.formRef.current?.triggerSubmit()
                        }}
                    />
                )}
            />
        )
    }

    private shouldHidePermissions(employee: EmployeeType) {
        const hasDefaultRoleSelected = !this.state.selectedRole || employee.role === this.state.selectedRole

        if (hasDefaultRoleSelected) {
            if (employee.role === EmployeeRole.customerReader) {
                return true
            }

            if (employee.role === EmployeeRole.customerReaderWithCompliance) {
                return true
            }

            return false
        }

        if (this.state.selectedRole === EmployeeRole.customerReader) {
            return true
        }

        if (this.state.selectedRole === EmployeeRole.customerReaderWithCompliance) {
            return true
        }

        if (this.state.selectedRole === EmployeeRole.customerPlanner) {
            return true
        }

        return false
    }

    private renderActiveMergeRequestWarning(hasActiveMergeRequest: boolean) {
        if (!hasActiveMergeRequest) {
            return
        }

        return <Attention attentionText={localize.translate(t => t.User.pendingMergeWarning)} />
    }

    private shouldDisablePermissions(employee: EmployeeType) {
        const hasDefaultRoleSelected = !this.state.selectedRole || employee.role === this.state.selectedRole

        if (hasDefaultRoleSelected) {
            return !employee.canEditPermissions
        }

        if (this.state.selectedRole === EmployeeRole.customerAdministrator) {
            return true
        }

        if (this.state.selectedRole === EmployeeRole.customerReader) {
            return true
        }

        if (this.state.selectedRole === EmployeeRole.customerReaderWithCompliance) {
            return true
        }

        return false
    }

    private onChangePermissions = (data: PermissionsConfigType[]) => {
        const selectedPermissions = data.filter(d => d.active).map(d => d.name)

        this.setState({ ...this.state, selectedPermissions })
    }

    private getDefaultPermissions(employee: EmployeeType) {
        const hasDefaultRoleSelected = this.state.selectedRole && employee.role.includes(this.state.selectedRole)

        if (hasDefaultRoleSelected || !this.state.selectedRole) {
            return employee.permissions
        }

        const allPermissions = Object.values(EmployeePermissionEnum).filter(permission => {
            return !this.permissionsToHide.includes(permission)
        })

        if (this.state.selectedRole === EmployeeRole.customerAdministrator) {
            return allPermissions
        }

        if (this.state.selectedRole === EmployeeRole.customerReader) {
            return []
        }

        if (this.state.selectedRole === EmployeeRole.customerReaderWithCompliance) {
            return []
        }

        return allPermissions
    }

    private getPermissionsToSubmit(formState: FormState, employee: EmployeeType) {
        const selectedPermissions: EmployeePermissionEnum[] = []
        formState.permissions?.forEach((permission: PermissionsConfigType) => {
            if (permission.active) {
                selectedPermissions.push(permission.name)
            }
            return
        }, [])

        const defaultPermissions = this.getDefaultPermissions(employee)
        const hasNotChanged =
            defaultPermissions?.length === selectedPermissions.length &&
            xor(defaultPermissions, selectedPermissions).length === 0

        if (hasNotChanged) {
            return
        }

        return selectedPermissions
    }

    private getDefaultOperations(employee: EmployeeType) {
        const role = this.state.selectedRole || employee.role
        const perms = this.state.selectedPermissions || employee.permissions || []

        return permissions.getAvailableOperations(role, perms)
    }
}

export const EmployeeDetailEditView = withRouter(EmployeeDetailEditViewComponent)
