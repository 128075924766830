import './FileInput.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '../../Icon/Icon'
import { IconType } from '../../Icon/IconType'
import { Button, ButtonType } from '../../Button/Button'
import { localize } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'

interface UploadedFile {
    id?: number
    filename: string
}

interface Props {
    className?: ClassValue
    name: string
    placeholder?: string
    mime?: string
    onChange?: (value: File | null, name: string) => void
    defaultFile?: UploadedFile | null
    disabled?: boolean
    onClear?: (value: string, name: string) => void
    hideMaxFileSizeMessage?: boolean
}

interface State {
    file?: UploadedFile | null
    hideAnimation: boolean
    fileIsTooLarge: boolean
}

export class FileInput extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        file: this.props.defaultFile,
        hideAnimation: !!this.props.defaultFile,
        fileIsTooLarge: false,
    }

    public loc = localize.namespaceTranslate(t => t.Core.FileInput)

    private bem = new BEM('FileInput', () => ({
        'no-animation': this.state.hideAnimation,
        'is-disabled': this.props.disabled,
    }))

    private fileInput = React.createRef<HTMLInputElement>()

    private maxMegabytes = 30

    public render() {
        const { className, placeholder, hideMaxFileSizeMessage } = this.props
        const { file, fileIsTooLarge } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderFiles()}
                {this.renderFileInput()}

                {!file && (
                    <Column smallSpacing={true}>
                        <button
                            type="button"
                            className={this.bem.getElement('add-file-button')}
                            onClick={this.handleAddFile}
                        >
                            <Icon type={IconType.attachment} className={this.bem.getElement('add-file-button-icon')} />
                            {placeholder ? placeholder : this.loc(t => t.selectFile)}
                        </button>

                        {!hideMaxFileSizeMessage && !fileIsTooLarge && (
                            <Paragraph subtle={true} small={true}>
                                {this.loc(t => t.maxFileSize)}
                            </Paragraph>
                        )}

                        {!hideMaxFileSizeMessage && fileIsTooLarge && (
                            <Paragraph className={this.bem.getElement('error')}>
                                {this.loc(t => t.overMaxFileSize)}
                            </Paragraph>
                        )}
                    </Column>
                )}
            </div>
        )
    }

    private renderFiles = () => {
        const { file } = this.state
        const { disabled } = this.props

        if (!file) {
            return null
        }

        return (
            <div className={this.bem.getElement('uploaded-file')}>
                <Icon type={IconType.attachment} className={this.bem.getElement('file-icon')} />
                <h5 className={this.bem.getElement('file-name')}>{file.filename}</h5>
                {!disabled && (
                    <Button
                        icon={IconType.close}
                        type={ButtonType.noStyling}
                        className={this.bem.getElement('remove-file')}
                        onClick={() => this.handleClearFileInput()}
                    />
                )}
            </div>
        )
    }

    private renderFileInput() {
        const { mime, disabled } = this.props

        return (
            <input
                type="file"
                accept={mime}
                onChange={this.handleFileInputChange}
                className={this.bem.getElement('file-input')}
                ref={this.fileInput}
                disabled={disabled}
            />
        )
    }

    private handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputfile = e.target.files ? e.target.files[0] : null
        const { onChange, name } = this.props

        if (inputfile && this.isFileIsLargerThanMaxLimit(inputfile)) {
            this.setState({ fileIsTooLarge: true })
            return
        }

        if (inputfile) {
            const file: UploadedFile = {
                filename: inputfile.name,
            }

            this.setState(
                {
                    file,
                    fileIsTooLarge: false,
                },
                () => onChange && onChange(inputfile, name)
            )
        }
    }

    private handleAddFile = () => {
        const fileInput = this.fileInput

        if (fileInput.current) {
            fileInput.current.click()
        }
    }

    private isFileIsLargerThanMaxLimit(inputfile: File) {
        const maxBytes = this.maxMegabytes * 1000 * 1000
        return Math.abs(Number(inputfile.size)) > maxBytes
    }

    private handleClearFileInput() {
        const { onChange, name, onClear } = this.props

        if (onClear && this.state.file) {
            onClear(this.state.file.filename, name)
        }

        if (this.fileInput.current) {
            this.fileInput.current.value = ''
        }

        this.setState(
            {
                file: undefined,
                hideAnimation: false,
                fileIsTooLarge: false,
            },
            () => onChange && onChange(null, name)
        )
    }
}
