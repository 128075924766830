import gql from 'graphql-tag'
import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { ProminentIconButton } from '~/components/Core/Button/ProminentIconButton'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { StickyFooter } from '~/components/Core/Layout/StickyFooter'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { ConsultantSelect } from '~/components/Domain/Consultants/ConsultantSelect'
import { GQLMutation } from '~/graphql/Mutation'
import { ClassValue } from '~/services/BEMService'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{ id: string }> {
    className?: ClassValue
}

interface State {}

export interface Consultant {
    id: number
    organization: {
        id: number
        name: string
    }
    user: {
        id: number
        lastLoginAt: string
        profile: {
            id: number
            fullName: string
        }
    }
}

const CONSULTANTS_QUERY = gql`
    query consultants($skip: Int, $take: Int, $filters: ConsultantsFiltersType) {
        consultants(skip: $skip, take: $take, filters: $filters) {
            hasNextPage
            nodes {
                id
                organization {
                    id
                    name
                }
                user {
                    id
                    lastLoginAt
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

const LINK_CONSULTANT_TO_CUSTOMER_MUTATION = gql`
    mutation linkConsultantToCustomer($customerId: Int!, $consultantIds: [Int!]!) {
        linkConsultantToCustomer(consultantIds: $consultantIds, customerId: $customerId)
    }
`

const UNLINK_CONSULTANT_FROM_CUSTOMER_MUTATION = gql`
    mutation unlinkConsultantFromCustomer($customerId: Int!, $consultantIds: [Int!]!) {
        unlinkConsultantFromCustomer(consultantIds: $consultantIds, customerId: $customerId)
    }
`

class ConsultantOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.Consultant.ConsultantOverviewView)

    private formNames = {
        consultantSelect: 'consultantIds',
    }

    public render() {
        const id = parseInt(this.props.match.params.id, 10)

        return (
            <InfiniteScrollQuery<Consultant>
                query={CONSULTANTS_QUERY}
                variables={{ filters: { linkedCustomerIds: [id] } }}
            >
                {({ data, loading, loadingMore, refetch }) => {
                    const consultants = data ? data.nodes : []
                    return (
                        <>
                            <PageActions
                                primaryComponent={<PageTitle title={this.loc(t => t.title)} />}
                                secondaryComponent={
                                    <MediaQuery breakpoint={Screensize.tablet}>
                                        {this.renderButtonToModal(refetch)}
                                    </MediaQuery>
                                }
                            />

                            {this.renderConsultantsTable(consultants, loading, loadingMore, refetch)}

                            <MediaQuery breakpoint={Screensize.mobile}>
                                <StickyFooter>{this.renderButtonToModal(refetch)}</StickyFooter>
                            </MediaQuery>
                        </>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private transformConsultantDataToTableData = (consultant: Consultant) => {
        return {
            id: consultant.id,
            columns: {
                name: (
                    <TableLink to={routes.consultant.organization.employees.view(consultant.id)}>
                        {consultant.user.profile.fullName}
                    </TableLink>
                ),
                organization: consultant.organization.name,
            },
        }
    }

    private renderButtonToModal(refetch: Function) {
        return (
            <ModalManager
                render={openmodal => (
                    <Button onClick={openmodal} icon={IconType.plus}>
                        {this.loc(t => t.linkConsultant)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <GQLMutation mutation={LINK_CONSULTANT_TO_CUSTOMER_MUTATION}>
                        {(mutate, { loading }) => (
                            <Form onSubmit={this.handleOnConsultantLinkSubmit(mutate, closeModal, refetch)}>
                                <Modal
                                    loading={loading}
                                    submitForm={true}
                                    title={this.loc(t => t.linkConsultant)}
                                    requestClose={closeModal}
                                >
                                    <ErrorMessage path={'linkConsultantToCustomer'} />
                                    <Field
                                        label={this.loc(t => t.chooseAConsultant)}
                                        forInput={this.formNames.consultantSelect}
                                    >
                                        <ConsultantSelect multi={true} name={this.formNames.consultantSelect} />
                                    </Field>
                                </Modal>
                            </Form>
                        )}
                    </GQLMutation>
                )}
            />
        )
    }

    private renderUnlinkButton(id: number, refetch: Function) {
        const customerId = parseInt(this.props.match.params.id, 10)

        return (
            <GQLMutation
                mutation={UNLINK_CONSULTANT_FROM_CUSTOMER_MUTATION}
                variables={{ customerId, consultantIds: [id] }}
            >
                {(mutate, { loading }) => (
                    <>
                        <ErrorMessage path={'unlinkConsultantFromCustomer'} showAsNotification={true} />
                        <ConfirmModal
                            loading={loading}
                            onConfirm={closeModal => this.handleOnConsultantUnlinkSubmit(mutate, closeModal, refetch)}
                        >
                            {open => <ProminentIconButton icon={IconType.trash} onClick={open} />}
                        </ConfirmModal>
                    </>
                )}
            </GQLMutation>
        )
    }

    private renderConsultantsTable(
        consultants: Consultant[],
        loading: boolean,
        loadingMore: boolean,
        refetch: Function
    ) {
        const transformedConsultantsData = consultants.map(this.transformConsultantDataToTableData)
        return (
            <Table
                loading={loading}
                loadingMore={loadingMore}
                columns={[
                    { field: 'name', headerLabel: this.loc(t => t.name) },
                    { field: 'organization', headerLabel: this.loc(t => t.organization) },
                ]}
                rowAction={({ row: { id } }) => this.renderUnlinkButton(id as number, refetch)}
                data={transformedConsultantsData}
            />
        )
    }

    private handleOnConsultantLinkSubmit =
        (mutate: MutationFn, closeModal: Function, refetch: Function) => async (formState: FormState) => {
            const consultantIds = formState.consultantIds
                ? formState.consultantIds.map(({ value }: { value: number }) => value)
                : []

            const customerId = parseInt(this.props.match.params.id, 10)

            const response = await mutate({
                variables: {
                    consultantIds,
                    customerId,
                },
            })

            if (response && response.data && response.data.linkConsultantToCustomer) {
                notification.success('Consultant(s) gekoppeld')
                closeModal()
                refetch()
            }
        }

    private handleOnConsultantUnlinkSubmit = async (mutate: MutationFn, closeModal: Function, refetch: Function) => {
        const response = await mutate()

        if (response && response.data && response.data.unlinkConsultantFromCustomer) {
            notification.success('Consultant(s) ontkoppeld')
            closeModal()
            refetch()
        }
    }
}

export const ConsultantOverviewView = withRouter(ConsultantOverviewViewComponent)
