import './NoRadarItems.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
}

interface State {}

export class NoRadarItems extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('NoRadarItems')

    public render() {
        const { children, className } = this.props

        return <Paragraph className={this.bem.getClassName(className)}>{children}</Paragraph>
    }
}
