import './ZeroInbox.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Center } from '~/components/Core/Layout/Center'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import ZeroInboxIllustration from './ZeroInboxIllustration.svg'

interface Props {
    className?: ClassValue
    image?: string
    title: string
    label: string
}

interface State {}

export class ZeroInbox extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ZeroInbox')

    private loc = localize.namespaceTranslate(t => t.Customer.PageQuery)

    public render() {
        const { className, image, title, label } = this.props

        return (
            <Center>
                <div className={this.bem.getClassName(className)}>
                    <img src={image || ZeroInboxIllustration} alt={this.loc(t => t.notFoundImgAlt)} />
                    {title && <h4 className={this.bem.getElement('title')}>{title}</h4>}
                    <Column smallSpacing={true}>
                        <Paragraph className={this.bem.getElement('text')}>
                            {!label ? this.loc(t => t.notFoundDescription) : label}
                        </Paragraph>
                    </Column>
                </div>
            </Center>
        )
    }
}
