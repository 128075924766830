export const routes = {
    index: '/',
    cover: {
        index: '/auth',
        login: '/auth/login',
        loginWithSSO: '/auth/login-with-sso',
        saml: '/auth/saml',
        redirect: '/auth/redirect',
        forgotPassword: '/auth/forgot-password',
        setPassword: '/auth/set-password/:token',
        registerUserLegacy: '/auth/invite/:token',
        registerUser: (token: string = ':token', email: string = ':email', language: string = ':language') =>
            `/auth/invite/${token}/${email}/${language}`,
        confirmAccount: '/auth/confirm/:token',
        userMergeRequest: '/auth/confirmMerge/:token',
    },
    legacyRedirect: (legacyDocumentKey: string = ':legacyDocumentKey') => `/document/detail/${legacyDocumentKey}`,
    lawAndRegulationRedirect: (identifier: string = ':identifier') => `/law-and-regulation-redirect/${identifier}`,
    customer: (customer: string = ':customer') => {
        return {
            index: `/${customer}`,
            switch: `/${customer}/switch`,
            dashboard: {
                index: `/${customer}/dashboard`,
            },
            settings: {
                index: `/${customer}/settings`,
                employees: {
                    index: `/${customer}/settings/employees`,
                    detail: (id: string | number = ':id') => `/${customer}/settings/employees/${id}`,
                    edit: (id: string | number = ':id') => `/${customer}/settings/employees/${id}/edit`,
                },
                frameworks: {
                    index: `/${customer}/settings/frameworks`,
                    view: (id: string | number = ':id') => `/${customer}/settings/frameworks/${id}`,
                    edit: (id: string | number = ':id') => `/${customer}/settings/frameworks/${id}/edit`,
                },
                departments: {
                    index: `/${customer}/settings/departments`,
                },
                saml: {
                    index: `/${customer}/settings/saml`,
                    edit: `/${customer}/settings/saml/edit`,
                },
                compliance: {
                    index: `/${customer}/settings/compliance`,
                    edit: `/${customer}/settings/compliance/edit`,
                },
                news: {
                    index: `/${customer}/settings/news`,
                    edit: `/${customer}/settings/news/edit`,
                },
                taskTemplates: {
                    index: `/${customer}/settings/templates`,
                },
            },
            kitchensink: {
                index: `/${customer}/kitchensink`,
            },
            styleguide: {
                index: `/${customer}/styleguide`,
            },
            legalFramework: {
                index: `/${customer}/legal-framework`,
                themes: {
                    index: `/${customer}/legal-framework`,
                    view: (id: string | number = ':id') => `/${customer}/legal-framework/themes/${id}`,
                    viewApplicable: (id: string | number = ':id') => `/${customer}/legal-framework/themes/${id}/edit`,
                },
                topic: {
                    view: (id: string | number = ':id') => `/${customer}/legal-framework/topic/${id}`,
                    designAndEffectiveness: (id: string | number = ':id') =>
                        `/${customer}/legal-framework/topic/${id}/design-and-effectiveness`,
                },
            },
            radar: {
                index: `/${customer}/radar`,
                detail: {
                    index: `/${customer}/radar`,
                    view: (id: string | number = ':id') => `/${customer}/radar/${id}`,
                },
            },
            monitoring: {
                index: `/${customer}/monitoring`,
                agenda: `/${customer}/monitoring/agenda`,
                reports: {
                    index: `/${customer}/monitoring/reports`,
                    detail: {
                        view: (id: string | number = ':id') => `/${customer}/monitoring/reports/${id}`,
                        edit: (id: string | number = ':id') => `/${customer}/monitoring/reports/${id}/edit`,
                    },
                },
            },
            consultations: {
                index: `/${customer}/consultations`,
                detail: {
                    index: `/${customer}/consultations`,
                    view: (id: string | number = ':id') => `/${customer}/consultations/${id}`,
                },
            },
            search: {
                index: `/${customer}/search`,
                query: (query: string) => `/${customer}/search?search=${encodeURIComponent(query.trim())}`,
            },
            lawAndRegulationRedirect: (id: number | string = ':id') => `/${customer}/law-redirect/${id}`,
            lawAndRegulation: {
                index: `/${customer}/law-and-regulation`,
                redirect: (identifier: string = ':identifier') =>
                    `/${customer}/law-and-regulation/redirect/${identifier}`,
                detail: {
                    singleArticle: (articleId: string | number = ':articleId') =>
                        `/${customer}/law-and-regulation/article/${articleId}`,
                    view: (abstractId: string | number = ':abstractId') =>
                        `/${customer}/law-and-regulation/${abstractId}`,
                    article: (abstractId: string | number = ':abstractId', articleId: string | number = ':articleId') =>
                        `/${customer}/law-and-regulation/${abstractId}/article/${articleId}`,
                },
            },
            news: {
                index: `/${customer}/news`,
                detail: {
                    view: (id: string | number = ':id') => `/${customer}/news/item/${id}`,
                },
            },
            risks: {
                index: `/${customer}/risks`,
                heatmap: {
                    index: `/${customer}/risks/heatmap`,
                    view: (id: string | number = ':id') => `/${customer}/risks/${id}`,
                },
                assessments: {
                    index: `/${customer}/risks/assessments`,
                    view: (id: string | number = ':id') => `/${customer}/risks/assessments/${id}`,
                    properties: (id: string | number = ':id') => `/${customer}/risks/assessments/${id}/properties`,
                    log: (id: string | number = ':id') => `/${customer}/risks/assessments/${id}/log`,
                    edit: (id: string | number = ':id') => `/${customer}/risks/assessments/${id}/properties/edit`,
                },
            },
            compliance: {
                index: `/${customer}/compliance`,
                controls: {
                    index: `/${customer}/compliance/controls`,
                    view: (id: string | number = ':id') => `/${customer}/compliance/controls/${id}`,
                    edit: (id: string | number = ':id') => `/${customer}/compliance/controls/${id}/edit`,
                },
            },
            overviewView: {
                index: `/${customer}/overview-view`,
            },
            tasks: {
                index: `/${customer}/tasks`,
                overview: `/${customer}/tasks/overview`,
                view: (id: string | number = ':id') => `/${customer}/tasks/overview/${id}`,
            },
            inbox: {
                index: `/${customer}/inbox`,
                detail: {
                    view: (id: string | number = ':id') => `/${customer}/inbox/${id}/`,
                },
                internalAlert: {
                    view: (id: string | number = ':id') => `/${customer}/inbox/internal-alert/${id}`,
                },
            },
            signaling: {
                index: `/${customer}/signaling`,
                detail: {
                    view: (id: string | number = ':id') => `/${customer}/signaling/${id}`,
                },
            },
            control: {
                index: `/${customer}/control`,
                taskControl: {
                    index: `/${customer}/control/task-control`,
                    view: (id: string | number = ':id') => `/${customer}/control/task-control/${id}`,
                },
                inboxControl: `/${customer}/control/inbox-control`,
            },
        }
    },
    consultant: {
        index: '/consultant',
        dashboard: {
            index: '/consultant/dashboard',
        },
        customerGroups: {
            index: '/consultant/customer-groups',
            create: '/consultant/customer-groups/create',
            detail: (id: number | string = ':id') => ({
                view: `/consultant/customer-groups/${id}`,
                edit: `/consultant/customer-groups/${id}/edit`,
                newCustomer: (name: string = ':name') => `/consultant/customer-groups/${id}/${name}/new-customer`,
            }),
        },
        customers: {
            index: '/consultant/customers',
            view: (id: number | string = ':id') => `/consultant/customers/${id}`,
            create: '/consultant/customers/create',
            profile: (id: number | string = ':id') => `/consultant/customers/${id}/profiles`,
            edit: (id: number | string = ':id') => `/consultant/customers/${id}/profiles/edit`,
            consultants: (id: number | string = ':id') => `/consultant/customers/${id}/consultants`,
        },
        customerfeeds: {
            index: '/consultant/customerfeeds',
            view: (id: number | string = ':id') => `/consultant/customerfeeds/${id}`,
        },
        organization: {
            index: '/consultant/organization',
            employees: {
                index: '/consultant/organization/employees',
                view: (id: string | number = ':id') => `/consultant/organization/employees/${id}`,
                edit: (id: string | number = ':id') => `/consultant/organization/employees/${id}/edit`,
            },
            profile: {
                index: '/consultant/organization/profile',
                edit: '/consultant/organization/profile/edit',
            },
        },
        taskTemplates: {
            index: '/consultant/task-templates',
        },
    },
}
