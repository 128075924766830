import './MonitoringReportWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import {
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardMonitoringReportWidgetFragmentFragment,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
} from '~/generated/graphql'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

interface MonitoringReport {
    id: number
    name: string
    createdAt: Date
    overdueTaskCount: number
}

export class MonitoringReportWidget extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.MonitoringReport)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.monitoringReport,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const monitoringReports = this.getMonitoringReportData()

        return (
            <Column>{monitoringReports.map(monitoringReport => this.renderMonitoringReport(monitoringReport))}</Column>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getMonitoringReportData() {
        const { content } = this.props

        if (!content) {
            return []
        }

        const { monitoringReports } = content as DashboardMonitoringReportWidgetFragmentFragment
        if (!monitoringReports || !monitoringReports.length) {
            return []
        }

        // cannot handle more than 3 rows (more of a sanity check as the BE wont/shouldnt return more than 3)
        if (monitoringReports.length > 3) {
            return monitoringReports.slice(0, 3)
        }

        return monitoringReports
    }

    private renderMonitoringReport(monitoringReport: MonitoringReport) {
        const { id, name, createdAt, overdueTaskCount } = monitoringReport

        const isOverdue = overdueTaskCount > 0
        const containerClassName = this.bem.getElement('row', () => ({ 'is-overdue': isOverdue }))

        return (
            <Row key={`monitoring-report-row-${id}`} className={containerClassName}>
                <Column>
                    <Paragraph bold={true} className={this.bem.getElement('name')}>
                        {name}
                    </Paragraph>
                    {this.renderOverdueCount(overdueTaskCount)}
                </Column>
                {this.renderDate(createdAt)}
            </Row>
        )
    }

    private renderOverdueCount(overdueCount: number) {
        if (overdueCount <= 0) {
            return
        }

        return (
            <Paragraph className={this.bem.getElement('overdue-count')}>
                {this.loc(t => t.overdue, { overdueCount })}
            </Paragraph>
        )
    }

    private renderDate(date: Date) {
        const createdLocalDate = localize.dateFormat(new Date(date), { readable: true, noWeekday: true })

        return (
            <Paragraph subtle={true} className={this.bem.getElement('date')}>
                {createdLocalDate}
            </Paragraph>
        )
    }
}
