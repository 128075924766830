import React from 'react'
import qs, { defaultDecoder } from 'qs'

export type SetParamStateFn<TData> = (newState: NewParamState<TData>) => void

interface Props<TData> {
    defaultState: TData
    children: (rememberedState: ParamManagerOptions<TData>) => React.ReactNode
}

interface ParamManagerOptions<TData> {
    paramState: TData
    setParamState: SetParamStateFn<TData>
}

interface State<TData> {
    rememberedState: TData
}

export type NewParamState<TData> = {
    [P in keyof TData]?: TData[P]
}

export class ParamManager<TData> extends React.Component<Props<TData>, State<TData>> {
    public state: State<TData> = {
        rememberedState: this.getDefaultState(),
    }

    public render() {
        const { children } = this.props
        const { rememberedState } = this.state

        return children({ paramState: rememberedState, setParamState: this.setParamState })
    }

    private getDefaultState() {
        if (!location.search) {
            return this.props.defaultState
        }

        return qs.parse(location.search, {
            ignoreQueryPrefix: true,
            decoder: (str: string, decoder: defaultDecoder, charset: string) => this.decoder(str, charset),
        }) as unknown as TData
    }

    private decoder(str: string, charset: string) {
        // Use a custom decorder so keywords will be parsed
        const strWithoutPlus = str.replace(/\+/g, ' ')

        if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
        }

        // Check for number
        if (/^(\d+|\d*\.\d+)$/.test(str)) {
            return parseFloat(str)
        }

        // Check if string is a reserved keyword
        const keywords = {
            true: true,
            false: false,
            null: null,
            undefined,
        }
        if (str in keywords) {
            return keywords[str]
        }

        // utf-8
        try {
            return decodeURIComponent(strWithoutPlus)
        } catch (e) {
            return strWithoutPlus
        }
    }

    private setParamState = (newState: NewParamState<TData>) => {
        const mergedNewState = Object.assign(this.state.rememberedState!, newState)

        this.setState({ rememberedState: mergedNewState }, () => {
            this.updatePathName()
        })
    }

    private updatePathName() {
        const stringifiedState = qs.stringify(this.state.rememberedState, {
            skipNulls: true,
        })
        const stringifiedQueryString = stringifiedState ? `?${stringifiedState}` : ''

        history.replaceState({}, '', stringifiedQueryString)
    }
}
