import React from 'react'

import { ModalManager } from '../../Core/Feedback/Modal/ModalManager'
import { OnboardingIntroModal } from './OnboardingIntroModal'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { Mutation, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { userClient } from '~/bootstrap'
import { roleGroups } from '~/services/UserService'
import { OnboardingOverlay, Clip } from './OnboardingOverlay'
import { OnboardingStepInstructions, OnboardingInstructionPosition } from './OnboardingStepInstructions'
import { routes } from '~/views/routes'
import { History } from '../../../utils/withRouter'

interface Props {
    render?: (requestOpen: () => void) => JSX.Element
    history: History
}

interface State {
    clip: Clip
    currentStepIndex: number | undefined
}

export interface OnboardingStepItem {
    order: number
    title: string
    description: string
    position: OnboardingInstructionPosition
    domNode: Element
}

export interface OnboardingContextValue {
    steps: OnboardingStepItem[]
    addStep: (step: OnboardingStepItem) => void
    removeStep: (step: OnboardingStepItem) => void
}

export const OnboardingContext = React.createContext<OnboardingContextValue | undefined>(undefined)

const COMPLETE_EMPLOYEE_ONBOARDING_MUTATION = gql`
    mutation completeEmployeeOnboarding {
        completeEmployeeOnboarding {
            id
            onboardedAt
        }
    }
`
export class OnboardingManager extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = UserContext
    public context: UserContextValue

    public state: State = {
        clip: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        currentStepIndex: undefined,
    }

    private resizeActive: boolean = false

    private modalManagerRef = React.createRef<ModalManager>()
    private contextValue: OnboardingContextValue = {
        steps: [],
        addStep: step => this.contextValue.steps.push(step),
        removeStep: step => {
            this.contextValue.steps = this.contextValue.steps.filter(s => s !== step)
        },
    }

    public async componentDidMount() {
        const { user, employee } = this.context

        if (!user || !employee) {
            return
        }

        // Only employees are required to be onboarded
        if (!userClient.hasRoles(roleGroups.customer)) {
            return
        }

        // If onboardedAt is set, the user is already onboarded so we don't want to continue here
        if (employee.onboardedAt) {
            return
        }

        this.toggleModal()
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    public render() {
        const { render, children } = this.props
        const { currentStepIndex, clip } = this.state

        return (
            <OnboardingContext.Provider value={this.contextValue}>
                <Mutation<any> mutation={COMPLETE_EMPLOYEE_ONBOARDING_MUTATION}>
                    {(mutateCompleteEmployeeOnboarding, { loading: completeEmployeeOnboardingLoading }) => (
                        <>
                            <ModalManager
                                render={render}
                                dismissableByOverlay={false}
                                ref={this.modalManagerRef}
                                renderModal={close => (
                                    <OnboardingIntroModal
                                        onNext={this.startOnboarding(close)}
                                        onSkip={this.handleOnSkip(close, mutateCompleteEmployeeOnboarding)}
                                        onSkipLoading={completeEmployeeOnboardingLoading}
                                    />
                                )}
                            />
                            {children}
                            {currentStepIndex !== undefined && <OnboardingOverlay clip={clip} />}
                            {currentStepIndex !== undefined && (
                                <OnboardingStepInstructions
                                    clip={clip}
                                    step={this.contextValue.steps[currentStepIndex]}
                                    currentStep={currentStepIndex + 1}
                                    totalSteps={this.contextValue.steps.length}
                                    handleOnPrev={this.handlePrev}
                                    handleOnNext={this.handleNext}
                                    handleOnFinish={() => this.finishOnboarding(mutateCompleteEmployeeOnboarding)}
                                    finishLoading={completeEmployeeOnboardingLoading}
                                />
                            )}
                        </>
                    )}
                </Mutation>
            </OnboardingContext.Provider>
        )
    }

    private startOnboarding = (closeModal: Function) => () => {
        closeModal()

        const start = () => {
            this.contextValue.steps.sort((a, b) => a.order - b.order)
            this.setStepClip(0)

            if (!this.resizeActive) {
                this.resizeActive = true
                window.addEventListener('resize', this.handleResize)
            }
        }

        if (window.location.pathname.includes('dashboard')) {
            // This ensures the user can't scroll during onboarding
            requestAnimationFrame(() => {
                document.body.style.overflow = 'hidden'
            })
            start()
            return
        }

        this.props.history.push(routes.index)
        window.setTimeout(start, 1000)
    }

    private finishOnboarding = async (mutateCompleteEmployeeOnboarding: MutationFn) => {
        await mutateCompleteEmployeeOnboarding()
        this.stopOnboarding()
    }

    private stopOnboarding = () => {
        document.body.style.overflow = ''
        window.removeEventListener('resize', this.handleResize)
        this.resizeActive = false

        this.setState({
            currentStepIndex: undefined,
        })
    }

    private setStepClip = (index: number) => {
        if (!this.contextValue.steps[index]) {
            this.stopOnboarding()
            return
        }

        const domRect = this.contextValue.steps[index].domNode.getBoundingClientRect()

        const clip = {
            x: domRect.left,
            y: domRect.top,
            height: domRect.height,
            width: domRect.width,
        }

        this.setState({
            clip,
            currentStepIndex: index,
        })
    }

    private handleNext = () => {
        if (this.state.currentStepIndex === undefined) {
            return
        }

        this.setStepClip(this.state.currentStepIndex + 1)
    }

    private handlePrev = () => {
        if (this.state.currentStepIndex === undefined) {
            return
        }

        if (this.state.currentStepIndex === 0) {
            this.setState(
                {
                    currentStepIndex: undefined,
                },
                () => {
                    this.toggleModal()
                }
            )
            return
        }

        this.setStepClip(this.state.currentStepIndex - 1)
    }

    private handleOnSkip = (closeModal: Function, mutate: MutationFn) => async () => {
        await mutate()
        closeModal()
        window.removeEventListener('resize', this.handleResize)
        this.resizeActive = false
    }

    private toggleModal() {
        if (!this.modalManagerRef.current) {
            return
        }

        this.modalManagerRef.current.toggleModal()
    }

    private handleResize = () => {
        window.requestAnimationFrame(() => {
            if (this.state.currentStepIndex === undefined) {
                return
            }

            this.setStepClip(this.state.currentStepIndex)
        })
    }
}
