import './MobileListItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from './Row'

interface Props {
    className?: ClassValue
    title: string | JSX.Element
    meta: [Meta, Meta][]
}

type Meta = string | (JSX.Element | undefined)

interface State {}

export class MobileListItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('MobileListItem')

    public render() {
        const { title, meta, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <h4 className={this.bem.getElement('title')}>{title}</h4>
                {meta.map(([left, right], index) => {
                    return (
                        <Row className={this.bem.getElement('row')} spaceBetween={true} key={`row-${index}`}>
                            <div>{left}</div>
                            <div>{right}</div>
                        </Row>
                    )
                })}
            </div>
        )
    }
}
