import './ToggleTag.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isActive: boolean
    onToggle: (isActive: boolean) => void
    label: string
}

interface State {}

export class ToggleTag extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ToggleTag', () => ({
        'is-toggled': this.props.isActive,
    }))

    public render() {
        const { className, label } = this.props

        return (
            <button onClick={this.handleOnClick} type={'button'} className={this.bem.getClassName(className)}>
                {label}
            </button>
        )
    }

    private handleOnClick = () => {
        const { onToggle, isActive } = this.props

        onToggle(!isActive)
    }
}
