import './TopicDetailActionButtons.scss'
import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { BEM } from '~/services/BEMService'
import { ItemCustomerInfoSummary } from '../Customer/ItemCustomerInfoSummary'
import { CreateTaskActionButton } from '../Task/CreateTaskActionButton'
import {
    MarkTopicAsNonApplicableFormMutation,
    MarkTopicAsNonApplicableFormMutationFN,
} from '../Themes/TopicNonApplicableForm/MarkTopicAsNonApplicableFormMutation'
import { MarkTopicAsNonApplicableModal } from '../Themes/TopicNonApplicableForm/MarkTopicAsNonApplicableModal'

interface Props {
    topic: Topic
    refetch: () => void
}

type Topic = Pick<TopicType, 'id' | '__typename' | 'name' | 'departments' | 'customerFrameworks' | 'nonApplicable'>

export class TopicDetailActionButtons extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailView)
    private applicabilityTooltipBem = new BEM('MarkApplicableTooltip')
    private bem = new BEM('TopicDetailActionButtons')

    public render() {
        const { topic, refetch } = this.props
        const { departments, customerFrameworks, nonApplicable } = this.props.topic

        return (
            <Row className={this.bem.getClassName()}>
                <Guard condition={permissions.canChangeNonApplicableTopics()}>
                    {nonApplicable?.id ? this.renderMarkApplicableButton() : this.renderMarkNonApplicableButton()}
                </Guard>
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <CreateTaskActionButton refetch={refetch} linkedItem={topic as LinkedTaskItem} />
                </Guard>
                <ActionButton
                    tooltipContent={
                        <ItemCustomerInfoSummary item={{ departments: departments || [], customerFrameworks }} />
                    }
                    icon={IconType.info}
                />
            </Row>
        )
    }

    private renderMarkApplicableButton() {
        return (
            <MarkTopicAsNonApplicableFormMutation>
                {(mutate, { loading }) => (
                    <ActionButton
                        icon={IconType.undo}
                        tooltipContent={this.renderMarkApplicableTooltip()}
                        loading={loading}
                        onClick={this.handleMarkApplicable(mutate)}
                    />
                )}
            </MarkTopicAsNonApplicableFormMutation>
        )
    }

    private renderMarkNonApplicableButton() {
        const { topic, refetch } = this.props

        return (
            <ModalManager
                renderModal={closeModal => (
                    <MarkTopicAsNonApplicableModal onSave={refetch} topicId={topic.id} requestClose={closeModal} />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.ignoreControlTask}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(
                                    t => t.Customer.Compliance.MarkTopicAsNonApplicableModal.confirmButtonLabel
                                )}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderMarkApplicableTooltip() {
        const { nonApplicable } = this.props.topic

        const description = nonApplicable!.reason || localize.translate(t => t.Settings.Attributes.noDescription)
        const date = localize.dateFormat(new Date(nonApplicable!.createdAt), { noWeekday: true, readable: true })

        return (
            <Column smallSpacing className={this.applicabilityTooltipBem.getClassName()}>
                <Paragraph small emphasis>
                    {this.loc(t => t.topicMarkedNonApplicable)}
                </Paragraph>
                <Column noSpacing>
                    {this.renderApplicabilityInfo(description)}
                    {this.renderApplicabilityInfo(date)}
                </Column>
            </Column>
        )
    }

    private handleMarkApplicable = (mutate: MarkTopicAsNonApplicableFormMutationFN) => async () => {
        const { refetch, topic } = this.props

        const response = await mutate({ variables: { markedAsApplicable: [topic.id], markedAsNonApplicable: [] } })

        if (response && response.data) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            refetch()
        }
    }

    private renderApplicabilityInfo(info: string) {
        return (
            <Row>
                <Paragraph wrap small>
                    {info}
                </Paragraph>
            </Row>
        )
    }
}
