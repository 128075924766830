import './ControlTypeCheckboxes.scss'
import React from 'react'
import { ControlTypeType } from '~/generated/graphql'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { getIconTypeForControlType } from '~/utils/controls'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    onChange?: (types: ControlTypeType[]) => void
    defaultSelectedControlTypes?: ControlTypeType[]
    allowedControlTypes?: ControlTypeType[]
    filterableControlTypes?: ControlTypeType[]
}

interface State {
    selectedControlTypes: ControlTypeType[]
}

export class ControlTypeCheckboxes extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        selectedControlTypes: this.props.defaultSelectedControlTypes || [],
    }

    private bem = new BEM('ControlTypeCheckboxes')

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.getFilterableControlTypes().map((option, i) => (
                    <React.Fragment key={i}>{this.renderCheckbox(option)}</React.Fragment>
                ))}
            </div>
        )
    }

    private renderCheckbox(type: ControlTypeType) {
        const { defaultSelectedControlTypes, allowedControlTypes, onChange } = this.props

        const defaultChecked = defaultSelectedControlTypes?.includes(type) || false
        const isDisabled = allowedControlTypes ? !allowedControlTypes.includes(type) : false

        return (
            <Checkbox
                name={type}
                large={true}
                defaultChecked={defaultChecked}
                label={this.renderLabel(type)}
                disabled={isDisabled}
                onChange={(checked, name: ControlTypeType) => {
                    this.setState(
                        prevState => {
                            if (checked) {
                                return {
                                    selectedControlTypes: [...prevState.selectedControlTypes, name],
                                }
                            } else {
                                return {
                                    selectedControlTypes: prevState.selectedControlTypes.filter(type => type !== name),
                                }
                            }
                        },
                        () => {
                            if (onChange) {
                                onChange(this.state.selectedControlTypes)
                            }
                        }
                    )
                }}
            />
        )
    }

    private renderLabel(type: ControlTypeType): React.ReactNode {
        const label = localize.translate(t => t.Control.type[type])

        return (
            <>
                <Icon type={getIconTypeForControlType(type)} />
                {label}
            </>
        )
    }

    private getFilterableControlTypes(): ControlTypeType[] {
        const { filterableControlTypes } = this.props

        if (filterableControlTypes && filterableControlTypes.length > 0) {
            return filterableControlTypes
        }

        return Object.values(ControlTypeType)
    }
}
