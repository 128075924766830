import React from 'react'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { MonitoringReportTopicAssessmentGrade, TopicAssessmentGradeType } from '~/generated/graphql'
import { TopicAssessmentIcon } from './TopicAssessmentIcon'

interface Props {
    status: TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade
}

export class TopicAssessmentLabel extends React.PureComponent<React.PropsWithChildren<Props>> {
    public render() {
        const { status } = this.props

        return (
            <Row smallSpacing={true}>
                <TopicAssessmentIcon status={status} />
                <Paragraph>{this.getOptionText(status)}</Paragraph>
            </Row>
        )
    }

    private getOptionText(status: TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade) {
        switch (status) {
            case TopicAssessmentGradeType.assessmentSatisfies:
            case MonitoringReportTopicAssessmentGrade.assessmentSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.COMPLIANT)
            case TopicAssessmentGradeType.assessmentAlmostSatisfies:
            case MonitoringReportTopicAssessmentGrade.assessmentAlmostSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.ALMOST_COMPLIANT)
            case TopicAssessmentGradeType.assessmentNotSatisfies:
            case MonitoringReportTopicAssessmentGrade.assessmentNotSatisfies:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_COMPLIANT)
            default:
                return localize.translate(t => t.Generic.AssessmentNoteStatus.NOT_ASSESSED)
        }
    }
}
