import './MatrixList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { MatrixItem } from '../Matrix'

interface Props {
    className?: ClassValue
    title?: string
    items?: {
        name: string
        id: number
        content: React.ReactNode
    }[]
    renderEmptyState?: () => React.ReactNode
    renderAddButton?: () => React.ReactNode
    onItemHover?: (item: MatrixItem) => void
    onItemLeave?: (item: MatrixItem) => void
    activeItemIds?: number[]
}

interface State {}

export class MatrixList extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('MatrixList', () => ({
        'is-hovered': !!this.props.activeItemIds,
    }))

    public render() {
        const { className, title, renderAddButton } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div>
                    {title && <h3 className={this.bem.getElement('title')}>{title}</h3>}
                    {this.renderItems()}
                </div>
                {renderAddButton && renderAddButton()}
            </div>
        )
    }

    public renderItems() {
        const { items, renderEmptyState, activeItemIds } = this.props

        if (!items || !items.length) {
            return renderEmptyState ? renderEmptyState() : null
        }

        return (
            <ul className={this.bem.getElement('items')}>
                {items.map((item, i) => {
                    const isActive = activeItemIds ? activeItemIds.includes(item.id) : false
                    return (
                        <li
                            onMouseEnter={() => this.handleItemHover(item)}
                            onMouseLeave={() => this.handleItemLeave(item)}
                            key={`relevant-card-${item.name}-${i}`}
                            className={this.bem.getElement('item-wrapper', () => ({
                                active: isActive,
                            }))}
                        >
                            {item.content}
                        </li>
                    )
                })}
            </ul>
        )
    }

    private handleItemHover(item: MatrixItem) {
        const { onItemHover } = this.props

        if (onItemHover) {
            onItemHover(item)
        }
    }

    private handleItemLeave(item: MatrixItem) {
        const { onItemLeave } = this.props

        if (onItemLeave) {
            onItemLeave(item)
        }
    }
}
