import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { localize, notification } from '~/bootstrap'
import gql from 'graphql-tag'
import { Organization } from './OrganizationProfileDetailView'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { Consultant } from './OrganizationEmployeesOverviewView'
import { ConsultantSelect } from '~/components/Domain/Consultants/ConsultantSelect'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { routes } from '~/views/routes'
import { GQLMutation } from '~/graphql/Mutation'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { MutationFn } from 'react-apollo'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

const GET_ORGANIZATION_QUERY = gql`
    query organization {
        organization {
            id
            name
            address {
                addressLine
                postalCode
                city
            }
            email
            phoneNumber
            contact {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

const EDIT_ORGANIZATION_MUTATION = gql`
    mutation editOrganization($fields: OrganizationFieldsInputType!) {
        editOrganization(fields: $fields) {
            id
            name
            address {
                addressLine
                postalCode
                city
            }
            email
            phoneNumber
            contact {
                id
                user {
                    id
                    email
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

class OrganizationProfileEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Organization)

    public render() {
        return (
            <Column>
                <PageHeader
                    title={this.loc(t => t.changeOrganizationDetails)}
                    breadCrumbs={[breadcrumbs.consultant().organization.index]}
                />

                <PageQuery<Organization> query={GET_ORGANIZATION_QUERY}>
                    {organization => {
                        return (
                            <GQLMutation mutation={EDIT_ORGANIZATION_MUTATION}>
                                {(mutate, { loading }) => (
                                    <Form id={'editOrganization'} onSubmit={this.handleOnSubmit(mutate)}>
                                        <ErrorMessage path={'editOrganization'} />
                                        <FieldSet>
                                            <Field label={this.loc(t => t.attributes.name)} forInput={'name'}>
                                                <Form.Input
                                                    name={'name'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.name)}
                                                    autoComplete={false}
                                                    defaultValue={organization.name}
                                                />
                                            </Field>
                                            <Field
                                                label={this.loc(t => t.attributes.street)}
                                                forInput={'address.addressLine'}
                                            >
                                                <Form.Input
                                                    name={'address.addressLine'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.street)}
                                                    autoComplete={false}
                                                    defaultValue={
                                                        organization.address && organization.address.addressLine
                                                    }
                                                />
                                            </Field>
                                            <Field
                                                label={this.loc(t => t.attributes.postalCode)}
                                                forInput={'address.postalCode'}
                                            >
                                                <Form.Input
                                                    name={'address.postalCode'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.postalCode)}
                                                    autoComplete={false}
                                                    defaultValue={
                                                        organization.address && organization.address.postalCode
                                                    }
                                                />
                                            </Field>
                                            <Field label={this.loc(t => t.attributes.city)} forInput={'address.city'}>
                                                <Form.Input
                                                    name={'address.city'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.city)}
                                                    autoComplete={false}
                                                    defaultValue={organization.address && organization.address.city}
                                                />
                                            </Field>
                                            <Field
                                                label={this.loc(t => t.attributes.phoneNumber)}
                                                forInput={'phoneNumber'}
                                            >
                                                <Form.Input
                                                    name={'phoneNumber'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.phoneNumber)}
                                                    autoComplete={false}
                                                    defaultValue={organization.phoneNumber}
                                                />
                                            </Field>
                                            <Field label={this.loc(t => t.attributes.email)} forInput={'email'}>
                                                <Form.Input
                                                    name={'email'}
                                                    type={'text'}
                                                    placeholder={this.loc(t => t.attributes.email)}
                                                    autoComplete={false}
                                                    defaultValue={organization.email}
                                                />
                                            </Field>
                                        </FieldSet>

                                        {this.getContactPerson(organization.contact)}

                                        <FieldSet>
                                            <Row smallSpacing={true} alignRight={true}>
                                                <Button
                                                    to={routes.consultant.organization.profile.index}
                                                    type={ButtonType.secondary}
                                                >
                                                    {localize.translate(t => t.Generic.cancel)}
                                                </Button>
                                                <Button submit={true}>{localize.translate(t => t.Generic.save)}</Button>
                                            </Row>
                                        </FieldSet>
                                    </Form>
                                )}
                            </GQLMutation>
                        )
                    }}
                </PageQuery>
            </Column>
        )
    }

    private getContactPerson(contactPerson: Consultant | undefined) {
        return (
            <FieldSet>
                <Field label={this.loc(t => t.attributes.contactPerson)}>
                    <ConsultantSelect
                        name={'contactId'}
                        placeholder={this.loc(t => t.attributes.ContactPersonSelectPlaceholder)}
                        defaultValue={
                            contactPerson && [
                                {
                                    value: contactPerson.user.profile.fullName,
                                    label: contactPerson.user.profile.fullName,
                                },
                            ]
                        }
                    />
                </Field>
            </FieldSet>
        )
    }

    private handleOnSubmit = (mutate: MutationFn) => async (formState: FormState) => {
        const contactId = formState && formState.contactId ? formState.contactId.value : undefined

        const response = await mutate({
            variables: {
                fields: { ...formState, contactId },
            },
        })

        if (response && response.data && response.data.editOrganization) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.props.history.push(routes.consultant.organization.profile.index)
        }
    }
}

export const OrganizationProfileEditView = withRouter(OrganizationProfileEditViewComponent)
