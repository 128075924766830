import './ExpiredArticleInfoBlock.scss'

import React from 'react'
import { Row } from '~/components/Core/Layout/Row'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize, permissions } from '~/bootstrap'
import { differenceInCalendarDays } from 'date-fns'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { BEM } from '~/services/BEMService'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    expiredAt: Date
    willBeDeletedAt?: Date
    onDeleteClick?: () => void
    onDeleteLoading?: boolean
}

const bem = new BEM('ExpiredArticleInfoBlock')

export class ExpiredArticleInfoBlock extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { expiredAt, willBeDeletedAt, onDeleteClick, onDeleteLoading } = this.props

        return (
            <Row className={bem.getClassName()} spaceBetween={true}>
                <Row smallSpacing={true}>
                    <Icon type={IconType.attention} className={bem.getElement('icon')} />
                    <Paragraph className={bem.getElement('expired-at')}>
                        {localize.translate(t => t.Customer.Compliance.TopicCard.expiredAt, {
                            date: localize.dateFormat(new Date(expiredAt), {
                                noWeekday: true,
                                readable: true,
                            }),
                        })}
                    </Paragraph>
                </Row>
                {willBeDeletedAt && (
                    <Row smallSpacing={true}>
                        <Paragraph subtle={true} className={bem.getElement('delete-at')}>
                            {localize.translate(t => t.Customer.Compliance.TopicCard.willBeDeletedAt, {
                                smart_count: differenceInCalendarDays(new Date(willBeDeletedAt), new Date()),
                            })}
                        </Paragraph>
                        {onDeleteClick && (
                            <Guard condition={permissions.canDeleteArticle(this.context.activeDepartmentId)}>
                                <Button
                                    type={ButtonType.noStyling}
                                    onClick={onDeleteClick}
                                    loading={onDeleteLoading}
                                    className={bem.getElement('delete-button')}
                                >
                                    {localize.translate(t => t.Generic.delete)}
                                </Button>
                            </Guard>
                        )}
                    </Row>
                )}
            </Row>
        )
    }
}
