import './CustomerFeedStatus.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    isActive: boolean
}

export class CustomerFeedStatus extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds)

    private bem = new BEM('CustomerFeedStatus', () => ({
        enabled: this.props.isActive,
    }))

    public render() {
        const { className, isActive } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('indicator')}>{this.renderIndicatorIcon()}</div>
                <div className={this.bem.getElement('label')}>
                    {isActive ? this.loc(t => t.statuses.enabled) : this.loc(t => t.statuses.disabled)}
                </div>
            </div>
        )
    }

    private renderIndicatorIcon = (): React.ReactNode => {
        const { isActive } = this.props

        if (isActive) {
            return <Icon type={IconType.customerFeedStatusEnabled} />
        } else {
            return <Icon type={IconType.customerFeedStatusDisabled} />
        }
    }
}
