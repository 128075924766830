import './SAMLSettingsFormFields.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    entityId: string
    ascURL: string
    logoutURL: string
    ssoLoginURL?: string | null
    ssoLogoutURL?: string | null
    ssoCertificate?: string | null
}

export class SAMLSettingsFormFields extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.SAMLSettingsDetailView)
    private bem = new BEM('SAMLSettingsFormFields')

    public render() {
        return (
            <Column bigSpacing={true} className={this.bem.getClassName()}>
                <Column bigSpacing={true}>
                    <ComponentTitle title={this.loc(t => t.informationTitle)} bold={true} />
                    {this.renderInformationFields()}
                </Column>
                <Column bigSpacing={true}>
                    <ComponentTitle title={this.loc(t => t.settingsTitle)} bold={true} />
                    {this.renderSettingFields()}
                </Column>
            </Column>
        )
    }

    private renderInformationFields() {
        const { entityId, ascURL, logoutURL } = this.props

        const entityLabel = this.loc(t => t.EntityId)
        const ascLabel = this.loc(t => t.ascUrl)
        const logoutLabel = this.loc(t => t.logoutUrl)

        return (
            <Column bigSpacing={true}>
                <Column smallSpacing={true}>
                    {this.renderInformationURLInput(entityId, 'entityId', entityLabel)}
                    {this.renderInformationURLInput(ascURL, 'ascUrl', ascLabel)}
                    {this.renderInformationURLInput(logoutURL, 'callbackURL', logoutLabel)}
                </Column>
                <Field label={this.loc(t => t.nameId)}>
                    <Paragraph>{this.loc(t => t.nameIdDescription)}</Paragraph>
                </Field>
            </Column>
        )
    }

    private renderSettingFields() {
        const { ssoCertificate, ssoLoginURL, ssoLogoutURL } = this.props

        const loginLabel = this.loc(t => t.loginUrl)
        const logoutLabel = this.loc(t => t.logoutUrl)

        return (
            <FieldSet>
                {this.renderSettingsURLInput('ssoLoginURL', loginLabel, ssoLoginURL)}
                {this.renderSettingsURLInput('ssoLogoutURL', logoutLabel, ssoLogoutURL)}
                <Field label={this.loc(t => t.certificate)} forInput="ssoCertificate">
                    <Column smallSpacing={true}>
                        <Form.SAMLCertificateInput
                            className={this.bem.getElement('certificate-input')}
                            name="ssoCertificate"
                            defaultValue={ssoCertificate || undefined}
                        />
                    </Column>
                </Field>
            </FieldSet>
        )
    }

    private renderInformationURLInput(value: string, name: string, label: string) {
        return (
            <Field label={label} className={this.bem.getElement('information-url-field')}>
                <Row smallSpacing={true}>
                    <Input
                        name={name}
                        readonly={true}
                        value={value}
                        disabled={true}
                        className={this.bem.getElement('information-url-input')}
                    />
                    <Button
                        type={ButtonType.secondary}
                        copyValueOnClick={value}
                        className={this.bem.getElement('copy-button')}
                    >
                        {localize.translate(t => t.Generic.copy)}
                    </Button>
                </Row>
            </Field>
        )
    }

    private renderSettingsURLInput(name: string, label: string, value?: string | null) {
        return (
            <Field label={label} forInput={name}>
                <Form.Input name={name} defaultValue={value} />
            </Field>
        )
    }
}
