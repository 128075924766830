import './MultiEmailInput.scss'

import React from 'react'
import isEmail from 'validator/lib/isEmail'

import { localize } from '~/bootstrap'
import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '../../Button/Button'
import { IconType } from '../../Icon/IconType'
import { Column } from '../../Layout/Column'

interface Props {
    name: string
    placeholder?: string
    className?: ClassValue
    defaultValue?: string[]
    disabled?: boolean
    onChange?: (emails: string[]) => void
}

interface State {
    inputValue: string[]
}

export class MultiEmailInput extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        inputValue: this.props.defaultValue || [],
    }

    private loc = localize.namespaceTranslate(t => t.Core.Form.MultiEmailInput)
    private inputRef = React.createRef<HTMLInputElement>()
    private bem = new BEM('MultiEmailInput', () => ({
        isDisabled: this.props.disabled,
    }))

    public render() {
        const { className, name, placeholder, disabled } = this.props

        return (
            <Column className={this.bem.getClassName(className)}>
                <input
                    className={this.bem.getElement('input')}
                    onChange={e => this.setState({ inputValue: [e.target.value] })}
                    onBlur={e => this.handleOnBlur(e.target.value)}
                    name={name}
                    placeholder={placeholder || 'john@example.org'}
                    value={this.state.inputValue}
                    ref={this.inputRef}
                    disabled={disabled}
                />
                {!disabled && (
                    <div>
                        <Button type={ButtonType.actionLink} icon={IconType.addCircle} onClick={this.handleAddEmail}>
                            {this.loc(t => t.addEmailAddress)}
                        </Button>
                    </div>
                )}
            </Column>
        )
    }

    private handleOnBlur(value: string) {
        if (!value.length) {
            return
        }

        const { onChange } = this.props
        const validEmails = this.getValidEmails(value)

        this.setState({ inputValue: validEmails }, () => onChange && onChange(validEmails))
    }

    private handleAddEmail = () => {
        const inputRef = this.inputRef.current
        const { inputValue } = this.state

        if (!inputRef || !inputRef.value || !inputValue.length) {
            return
        }

        // this will remove excess "," in cases where user repeatedly presses the add button
        const validEmails = this.getValidEmails(inputRef.value)

        this.setState({ inputValue: [...validEmails, ''] }, () => inputRef.focus())
    }

    private getValidEmails(value: string) {
        const emails = value.split(',')

        const validEmails: string[] = []
        for (const email of emails) {
            const trimmedEmail = email.trim()

            if (isEmail(trimmedEmail)) {
                validEmails.push(trimmedEmail)
            }
        }

        return validEmails || []
    }
}
