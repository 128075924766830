import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { NewsOverviewView } from './NewsOverviewView'
import { NewsDetailView } from './NewsDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Routes, Route } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'
import { withRouter, RouteComponentProps } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

class NewsViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<NewsOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).news.detail.view()}
                    element={<NewsDetailView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const NewsView = withRouter(NewsViewComponent)
