import './ArticleTitle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title: string
    suffix?: JSX.Element
    subtle?: boolean
}

interface State {}

export class ArticleTitle extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ArticleTitle', () => ({
        'has-suffix': !!this.props.suffix,
        subtle: this.props.subtle,
    }))

    public componentDidMount() {
        document.title = this.props.title
    }

    public render() {
        const { title, className, suffix } = this.props

        if (suffix) {
            return (
                <h1 className={this.bem.getClassName(className)}>
                    {title}
                    <span className={this.bem.getElement('suffix')}>{suffix}</span>
                </h1>
            )
        }

        return <h1 className={this.bem.getClassName(className)}>{title}</h1>
    }
}
