import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { SelectOption, Select } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    onChange?: (sort: { [key: string]: string }) => void
    name?: string
    defaultValue?: { [key: string]: string }
}

interface State {}

export class NormsSort extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('NormsSort')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Norms.NormSort)

    private options = [
        {
            label: this.loc(t => t.nameASC),
            value: 'name-ASC',
        },
        {
            label: this.loc(t => t.nameDESC),
            value: 'name-DESC',
        },
        {
            label: this.loc(t => t.assessmentCoverageDESC),
            value: 'assessmentCoverage-DESC',
        },
        {
            label: this.loc(t => t.assessmentCoverageASC),
            value: 'assessmentCoverage-ASC',
        },
    ]

    public render() {
        const { className, name } = this.props

        return (
            <Select
                className={this.bem.getClassName(className)}
                name={name || 'sort'}
                searchable={false}
                clearable={false}
                onChange={option => this.handleSort(option)}
                defaultValue={this.getDefaultValue()}
                options={this.options}
            />
        )
    }

    private handleSort(option: SelectOption | SelectOption[] | null) {
        const { onChange } = this.props

        if (!option || !onChange) {
            return
        }

        option = Array.isArray(option) ? option[0] : option

        const value = option.value && option.value.toString()

        if (!value) {
            return
        }

        const name = value.split('-')[0]
        const direction = value.split('-')[1]

        onChange({ [name]: direction })
    }

    private getDefaultValue() {
        const { defaultValue } = this.props

        const fallBackDefault = [
            {
                label: this.loc(t => t.nameASC),
                value: 'name-ASC',
            },
        ]

        if (!defaultValue) {
            return fallBackDefault
        }

        const name = Object.getOwnPropertyNames(defaultValue)[0]
        const value = defaultValue[name]

        if (!name || !value) {
            return fallBackDefault
        }

        const customDefault = this.options.find(option => option.value === `${name}-${value}`)

        if (customDefault) {
            return [customDefault]
        } else {
            return fallBackDefault
        }
    }
}
