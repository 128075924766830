import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
} from '~/generated/graphql'
import { CreateTopicAssessment, CreateTopicAssessmentMutationFN } from '../../mutations/CreateTopicAssessment'
import { TopicAssessmentFields } from './TopicAssessmentFields'
import { readAndSanitizeMultipleFiles } from '~/utils/fileSanitizer'

interface Props {
    topicId: number
    selectedArticleIds: number[]
    allArticles?: boolean
    parentId: number
    parentType: TopicAssessmentDeLinkedItemType
    type: TopicAssessmentDesignOrEffectiveNessType
    label: React.ReactNode
    closeModal: () => void
    onSubmit?: (grade: TopicAssessmentGradeType) => void
    defaultGrade?: TopicAssessmentGradeType
}

interface State {
    files: File[]
    validFileErrorMessage: string | null
}

export class CreateTopicAssessmentModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        files: [],
        validFileErrorMessage: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicAssessmentModal)

    public render() {
        const { closeModal, topicId, selectedArticleIds, allArticles, type, label, defaultGrade } = this.props
        const title =
            type === TopicAssessmentDesignOrEffectiveNessType.design
                ? this.loc(t => t.designTitle)
                : this.loc(t => t.effectivenessTitle)
        const { validFileErrorMessage } = this.state

        return (
            <CreateTopicAssessment>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            requestClose={closeModal}
                            confirmButtonLabel={this.loc(t => t.confirmLabel)}
                            title={title}
                            submitForm={true}
                            disabled={!permissions.isCustomerUser()}
                            loading={loading}
                        >
                            {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}
                            <ErrorMessage path="createTopicAssessment" />
                            <TopicAssessmentFields
                                topicId={topicId}
                                selectedArticleIds={selectedArticleIds}
                                allArticles={allArticles}
                                label={label}
                                onChangeFiles={files => this.setState({ ...this.state, files })}
                                isEditing={true}
                                defaultValues={{ grade: defaultGrade }}
                            />
                        </Modal>
                    </Form>
                )}
            </CreateTopicAssessment>
        )
    }

    private handleSubmit = (mutate: CreateTopicAssessmentMutationFN) => async (formState: FormState) => {
        const { onSubmit, topicId, parentId, parentType, type, closeModal, defaultGrade } = this.props
        const { activeDepartmentId } = this.context
        const { files } = this.state
        const { description } = formState
        const grade = formState.grade?.value || defaultGrade

        const isValidFile = files ? await readAndSanitizeMultipleFiles(files) : true
        if (isValidFile) {
            const response = await mutate({
                variables: {
                    topicId,
                    departmentId: activeDepartmentId,
                    linkedItemId: parentId,
                    linkedItemType: parentType,
                    designOrEffectiveness: type,
                    documents: files,
                    description,
                    grade,
                },
            })

            if (response && response.data?.createTopicAssessment) {
                notification.success(localize.translate(t => t.Generic.successfullyEdited))

                if (onSubmit) {
                    onSubmit(grade)
                }

                closeModal()
            }
        } else {
            this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
        }
    }
}
