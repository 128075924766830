import React from 'react'

import { ClassValue } from '~/services/BEMService'
import { ControlDocument } from '../Controls/ControlDetail/ControlQuery'
import { ArchivedControlDocumentModal } from './ArchivedControlDocumentModal'
import { EditControlDocumentModalContainer } from './EditcontrolDocumentModal/EditControlDocumentModalContainer'

interface Props {
    className?: ClassValue
    control: {
        id: number
        name: string
    }
    controlDocument: ControlDocument
    requestClose: () => void
}

interface State {
    isArchived?: boolean
}

export class ControlDocumentModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        isArchived: !!this.props.controlDocument.archivedAt,
    }

    public render() {
        const { isArchived } = this.state
        const { controlDocument, control, requestClose } = this.props

        if (isArchived) {
            return (
                <ArchivedControlDocumentModal
                    controlName={control.name}
                    controlId={control.id}
                    documentId={controlDocument.id}
                    requestClose={requestClose}
                />
            )
        }

        return (
            <EditControlDocumentModalContainer
                controlName={control.name}
                controlId={control.id}
                documentId={controlDocument.id}
                requestClose={requestClose}
            />
        )
    }
}
