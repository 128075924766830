import './RiskSettingsIndicatorLabel.scss'

import React from 'react'
import { Bold } from '~/components/Core/Typography/Bold'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { BEM } from '~/services/BEMService'
import { RiskSettingsType } from '~/components/Domain/Settings/Control/ControlRiskFields/RiskSettingsFieldsContainer'

interface Props {
    title: string
    riskType: RiskSettingsType
}

export class RiskSettingsIndicatorLabel extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('RiskSettingsIndicatorLabel')

    public render() {
        const { title } = this.props

        return (
            <div className={this.bem.getClassName()}>
                <Bold>{title}</Bold>
                <div className={this.bem.getElement('icon')}>
                    <Icon type={this.getIcon()} />
                </div>
            </div>
        )
    }

    private getIcon() {
        const config = {
            [RiskSettingsType.highChance]: IconType.riskHighChange,
            [RiskSettingsType.lowChance]: IconType.riskLowChange,
            [RiskSettingsType.highImpact]: IconType.riskHighImpact,
            [RiskSettingsType.lowImpact]: IconType.riskLowImpact,
        }
        return config[this.props.riskType]
    }
}
