import './FrameworkCard.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerFrameworkIconEnumType, CustomerFrameworkColorEnumType } from '~/generated/graphql'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    title: string
    description?: string
    isSelected?: boolean
    alreadySelected?: any
    onClick?: () => void
    icon?: CustomerFrameworkIconEnumType
    color?: CustomerFrameworkColorEnumType
    isCuratedInternally?: boolean
}

export class FrameworkCard extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('FrameworkCard', () => ({
        'is-selected': this.props.alreadySelected || this.props.isSelected,
        'is-disabled': this.props.alreadySelected,
        'has-icon': !!this.props.icon && !!this.props.color,
    }))

    public render() {
        const { className, title, description, isSelected, alreadySelected, icon, color } = this.props
        const shouldRenderCheck = alreadySelected || isSelected

        return (
            <Button
                type={ButtonType.noStyling}
                className={this.bem.getClassName(className)}
                onClick={this.handleOnClick}
            >
                <Card disabled={alreadySelected} selected={isSelected}>
                    <Row spaceBetween={true}>
                        <>
                            {icon && color && (
                                <FrameworkIcon
                                    className={this.bem.getElement('icon')}
                                    frameworkType={icon}
                                    color={color}
                                />
                            )}
                            <h4 className={this.bem.getElement('title')}>
                                {title}
                                {this.renderCuratedByRuler()}
                            </h4>
                        </>
                        {description && <p className={this.bem.getElement('description')}>{description}</p>}
                        {shouldRenderCheck && (
                            <div className={this.bem.getElement('check-icon-container')}>
                                <Icon type={IconType.ok} className={this.bem.getElement('check-icon')} />
                            </div>
                        )}
                    </Row>
                </Card>
            </Button>
        )
    }

    private renderCuratedByRuler() {
        const { isCuratedInternally } = this.props

        if (!isCuratedInternally) {
            return null
        }

        return (
            <span className={this.bem.getElement('logo-wrapper')}>
                {/* tslint:disable-next-line:jsx-use-translation-function */}
                &#xfeff;
                <Tooltip
                    distance={2}
                    message={localize.translate(t => t.Customer.Settings.CustomerFrameworks.curatedByRuler)}
                >
                    <Logo className={this.bem.getElement('logo')} tiny={true} iconOnly={true} />
                </Tooltip>
            </span>
        )
    }

    private handleOnClick = () => {
        const { onClick, alreadySelected } = this.props
        if (onClick && !alreadySelected) {
            onClick()
        }
    }
}
