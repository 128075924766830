import './Form.scss'
import React from 'react'

import set from 'lodash-es/set'
import { ClassValue, BEM } from '~/services/BEMService'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { AvatarUpload } from '~/components/Core/DataEntry/AvatarUpload/AvatarUpload'
import { Select } from '~/components/Core/DataEntry/Form/Select'
import { Radio } from '~/components/Core/DataEntry/Form/Radio'
import { DatePicker } from '~/components/Core/Date/DatePicker'
import { FileInput } from '~/components/Core/DataEntry/FileInput/FileInput'
import { PasswordInput } from '~/components/Core/DataEntry/Form/PasswordInput'
import { CustomerFrameworkRadio } from '~/components/Domain/CustomerFramework/CustomerFrameworkRadio'
import { Stepper } from '~/components/Core/DataEntry/Form/Stepper'
import { TextEditor } from '~/components/Core/Text/TextEditor/TextEditor'
import { Slider } from './Slider'
import { Toggle } from './Toggle'
import { UserProfilePickerList } from '~/components/Core/DataEntry/UserProfilePicker/UserProfilePickerList/UserProfilePickerList'
import { TaskTagsSelect } from '~/components/Domain/Task/TaskTagsSelect'
import { PermissionCheckboxList } from '~/components/Domain/Settings/Permissions/PermissionsCheckboxList'
import { SAMLCertificateInput } from '~/components/Domain/SAML/SAMLCertificateInput'
import { OperationsCheckboxList } from '~/components/Domain/Settings/Operations/OperationsCheckboxList'
import { TimeInput } from './TimeInput'
import { MultiEmailInput } from './MultiEmailInput'

export type Value = any
export type InputChangeHandler = (value: Value | Value[], name: string) => void

const FormContext = React.createContext<FormContext | undefined>(undefined)

type FormValue = any

interface FormContext {
    setFormState: (value: any | null, name: string) => void
}

type InputControlOnChangeHandler = (value: any, name: string) => void

export interface FormConsumerWrappedProps {
    name: string
    onChange?: (value: any | null, name: string) => void
}

type ReactComponent<TProps> = React.ComponentClass<TProps> | React.FunctionComponent<TProps>

type OnChangeHandler = (
    context: FormContext,
    name: string,
    onChange?: InputControlOnChangeHandler
) => InputControlOnChangeHandler

const handleFormComponentOnChange: OnChangeHandler = (context, name, changeHandler) => {
    return value => {
        if (context) {
            context.setFormState(value, name)
        }

        if (changeHandler) {
            changeHandler(value, name)
        }
    }
}

export function withContext<TOriginalProps extends {}>(
    WrappedComponent: ReactComponent<TOriginalProps & FormConsumerWrappedProps>
): ReactComponent<TOriginalProps> {
    const hoc = (props: TOriginalProps & FormConsumerWrappedProps) => (
        <FormContext.Consumer>
            {context => (
                <WrappedComponent
                    {...props} // this props rest should always be first so that onChange is overwritten
                    onChange={handleFormComponentOnChange(context as FormContext, props.name, props.onChange)}
                />
            )}
        </FormContext.Consumer>
    )

    return hoc
}

interface Props {
    id?: string
    className?: ClassValue
    onSubmit?: (state: FormState) => void
    isCompact?: boolean
    defaultState?: FormState
    extraFieldSpacing?: boolean
}

export interface FormState {
    [name: string]: FormValue
}

export class Form extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static Input = withContext(Input)
    public static Checkbox = withContext(Checkbox)
    public static Radio = withContext(Radio)
    public static Select = withContext(Select)
    public static DatePicker = withContext(DatePicker)
    public static AvatarUpload = withContext(AvatarUpload)
    public static FileInput = withContext(FileInput)
    public static PasswordInput = withContext(PasswordInput)
    public static CustomerFrameworkRadio = withContext(CustomerFrameworkRadio)
    public static Stepper = withContext(Stepper)
    public static TextEditor = withContext(TextEditor)
    public static Slider = withContext(Slider)
    public static Toggle = withContext(Toggle)
    public static UserProfilePickerList = withContext(UserProfilePickerList)
    public static TaskTagsSelect = withContext(TaskTagsSelect)
    public static PermissionCheckboxList = withContext(PermissionCheckboxList)
    public static OperationsCheckboxList = withContext(OperationsCheckboxList)
    public static SAMLCertificateInput = withContext(SAMLCertificateInput)
    public static TimeInput = withContext(TimeInput)
    public static MultiEmailInput = withContext(MultiEmailInput)

    private formState: FormState = this.props.defaultState ? this.props.defaultState : {}

    private bem = new BEM('Form', () => ({
        'is-compact': !!this.props.isCompact,
        'has-extra-field-spacing': !!this.props.extraFieldSpacing,
    }))

    private formContext: FormContext = {
        setFormState: (value, name) => {
            set(this.formState, name, value)
        },
    }

    private formRef = React.createRef<HTMLFormElement>()

    public render() {
        const { children, onSubmit, id, className } = this.props

        return (
            <FormContext.Provider value={this.formContext}>
                <form
                    id={id}
                    ref={this.formRef}
                    className={this.bem.getClassName(className)}
                    onSubmit={event => {
                        if (onSubmit) {
                            event.preventDefault()
                            event.stopPropagation()
                            onSubmit(this.formState)
                        }
                    }}
                >
                    {children}
                </form>
            </FormContext.Provider>
        )
    }

    public submit = () => {
        if (this.formRef.current) {
            // IE fix
            let event
            if (typeof Event === 'function') {
                event = new Event('submit')
            } else {
                event = document.createEvent('Event')
                event.initEvent('submit', true, true)
            }

            this.formRef.current.dispatchEvent(event)
        }
    }

    public clear = () => {
        this.formState = this.props.defaultState ? this.props.defaultState : {}
    }

    public triggerSubmit = () => {
        const { onSubmit } = this.props

        if (onSubmit) {
            onSubmit(this.formState)
        }
    }
}
