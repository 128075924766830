import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { ThemesOverviewView } from '~/views/Customer/LegalFramework/Themes/ThemesOverviewView'
import { TopicDetailView } from '~/views/Customer/LegalFramework/Topics/TopicDetailView'
import { ThemeDetailView } from '~/views/Customer/LegalFramework/Themes/ThemeDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ThemeDetailEditView } from './Themes/ThemeDetailEditView'
import { TopicDesignAndEffectivenessView } from './Topics/TopicDesignAndEffectivenessView'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Route, Routes } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class LegalFrameworkViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                {/* Themes */}
                <Route path={''} element={<ThemesOverviewView />} />

                {/* Topics */}
                <Route
                    path={newRoutes.customer(this.context.customer.slug).legalFramework.themes.view()}
                    element={<ThemeDetailView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).legalFramework.themes.viewApplicable()}
                    element={<ThemeDetailEditView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).legalFramework.topic.view()}
                    element={<TopicDetailView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).legalFramework.topic.designAndEffectiveness()}
                    element={<TopicDesignAndEffectivenessView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const LegalFrameworkView = withRouter(LegalFrameworkViewComponent)
