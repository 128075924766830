import React from 'react'
import { NewsSourcesQuery, NewsSourcesQueryType } from '~/views/Customer/Settings/NewsSources/NewsSourcesQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    EditCustomerEnabledNewsSourceMutationResult,
    EditCustomerEnabledNewsSourceMutationVariables,
    EmployeePermissionEnum,
} from '~/generated/graphql'
import { NewsSourcesEditContainer } from '~/views/Customer/Settings/NewsSources/NewsSourcesEditContainer'
import { localize, notification, permissions } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { NewsSourcesMutation } from './NewsSourcesMutation'
import { Column } from '~/components/Core/Layout/Column'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Bold } from '~/components/Core/Typography/Bold'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { MutationFn } from 'react-apollo'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Guard } from '~/components/Core/Guard/Guard'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { CustomerEnabledNewsSource } from './CustomerEnabledNewsSource'
import { Row } from '~/components/Core/Layout/Row'
import { isEqual } from 'lodash-es'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps {}

interface State {
    sourcesToDisable: DisabledGroupSourceMap
}

type MutateFN = MutationFn<EditCustomerEnabledNewsSourceMutationResult, EditCustomerEnabledNewsSourceMutationVariables>
export type DisabledGroupSourceMap = Map<number, Set<number>>

class NewsSourcesEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        sourcesToDisable: new Map(),
    }

    private groupSources: DisabledGroupSourceMap = new Map()
    private initialDisabledSources: DisabledGroupSourceMap = new Map()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.NewsSourcesEditView)

    public render() {
        const title = localize.translate(t => t.Customer.routes[routes.customer().settings.news.index])

        return (
            <NewsSourcesMutation>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.submitForm(mutate)}>
                        <Column>
                            <Column noSpacing={true}>
                                <PageHeader
                                    title={title}
                                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                                    actionComponent={this.renderButtons(loading)}
                                />
                                <Paragraph>
                                    {this.loc(t => t.selectMessagePart1)}
                                    <Bold inline={true}>{this.loc(t => t.signaling)}</Bold>
                                    {this.loc(t => t.selectMessagePart2)}
                                </Paragraph>
                            </Column>
                            {this.renderFormContent()}
                        </Column>
                    </Form>
                )}
            </NewsSourcesMutation>
        )
    }

    private renderFormContent() {
        if (!permissions.isCustomerAdministrator()) {
            return <NoResults />
        }

        return (
            <CustomerEnabledNewsSource>
                {({ data: censData, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    return (
                        <NewsSourcesQuery>
                            {({ data, loading, loadingMore }) => {
                                if (loading) {
                                    return <Spinner delayed={true} />
                                }

                                const newsGroups = data ? data.nodes : []
                                this.initSourcesToDisable(newsGroups)
                                this.updateGroupSources(newsGroups)
                                this.updateInitialDisabledSources(newsGroups)

                                return (
                                    <>
                                        <NewsSourcesEditContainer
                                            newsGroups={newsGroups}
                                            showFrom={censData?.customerEnabledNewsSource?.showFrom}
                                            sourcesToDisable={this.state.sourcesToDisable}
                                            onChangeGroup={this.onChangeGroup.bind(this)}
                                            onChangeSource={this.onChangeSource.bind(this)}
                                        />
                                        {loadingMore && <Spinner />}
                                    </>
                                )
                            }}
                        </NewsSourcesQuery>
                    )
                }}
            </CustomerEnabledNewsSource>
        )
    }

    private renderButtons = (loading: boolean) => {
        const cancelButtonLink = routes.customer(this.context.customer.slug).settings.news.index
        const isInitialState = isEqual(this.state.sourcesToDisable, this.initialDisabledSources)

        return (
            <Row>
                <Button type={ButtonType.tertiary} to={cancelButtonLink} disabled={loading}>
                    {this.loc(t => t.cancel)}
                </Button>
                <Guard condition={permissions.hasPermission(EmployeePermissionEnum.canMonitorSignaling)}>
                    <Button submit disabled={isInitialState} loading={loading}>
                        {this.loc(t => t.save)}
                    </Button>
                </Guard>
            </Row>
        )
    }

    private submitForm = (mutate: MutateFN) => async (formState: FormState) => {
        const { showFrom } = formState
        const { toEnable, toDisable } = this.getNewsSourceIdsToEnableAndDisable()

        const response = await mutate({
            variables: { newsSourceIdsToEnable: toEnable, newsSourceIdsToDisable: toDisable, showFrom },
        })

        if (response && response.data) {
            notification.success(this.loc(t => t.successMessage))
            this.props.history.push(routes.customer(this.context.customer.slug).settings.news.index)
        }
    }

    private initSourcesToDisable(newsGroups: NewsSourcesQueryType[]) {
        newsGroups.forEach(group => {
            if (this.state.sourcesToDisable.has(group.id)) {
                return
            }

            const disabledSources = group.sources.filter(s => !s.isEnabledByCustomer).map(s => s.id)
            this.state.sourcesToDisable.set(group.id, new Set(disabledSources))
        })
    }

    private updateInitialDisabledSources(newsGroups: NewsSourcesQueryType[]) {
        newsGroups.forEach(group => {
            const disabledSources = group.sources.filter(s => !s.isEnabledByCustomer).map(s => s.id)
            this.initialDisabledSources.set(group.id, new Set(disabledSources))
        })
    }

    private updateGroupSources(newsGroups: NewsSourcesQueryType[]) {
        newsGroups.forEach(group => {
            const sources = group.sources.map(s => s.id)
            this.groupSources.set(group.id, new Set(sources))
        })
    }

    private onChangeGroup(checked: boolean, groupId: number) {
        if (checked) {
            this.state.sourcesToDisable.get(groupId)?.clear()
        } else {
            this.groupSources.get(groupId)?.forEach(id => this.state.sourcesToDisable.get(groupId)?.add(id))
        }

        this.forceUpdate()
    }

    private onChangeSource(checked: boolean, sourceId: number, groupId: number) {
        if (checked) {
            this.state.sourcesToDisable.get(groupId)?.delete(sourceId)
        } else {
            this.state.sourcesToDisable.get(groupId)?.add(sourceId)
        }

        this.forceUpdate()
    }

    private getNewsSourceIdsToEnableAndDisable() {
        const toEnable: number[] = []
        const toDisable: number[] = []

        this.state.sourcesToDisable.forEach((sources, groupId) => {
            this.groupSources.get(groupId)?.forEach(sourceId => {
                if (sources.has(sourceId)) {
                    toDisable.push(sourceId)
                } else {
                    toEnable.push(sourceId)
                }
            })
        })

        return { toEnable, toDisable }
    }
}

export const NewsSourcesEditView = withRouter(NewsSourcesEditViewComponent)
