import React from 'react'
import { InfiniteScrollOptions, InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AllFrameworksQueryVariables, AllFrameworksDocument, FrameworkType } from '~/generated/graphql'

interface Props {
    children: (options: InfiniteScrollOptions<FrameworkType, AllFrameworksQueryVariables>) => React.ReactNode
    excludeFrameworksWithoutTopics?: boolean
}

export class AllFrameworksQuery extends React.PureComponent<Props> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { children, excludeFrameworksWithoutTopics } = this.props

        return (
            <InfiniteScrollQuery<FrameworkType, AllFrameworksQueryVariables>
                query={AllFrameworksDocument}
                variables={{ filters: { excludeFrameworksWithoutTopics } }}
            >
                {options => children(options)}
            </InfiniteScrollQuery>
        )
    }
}
