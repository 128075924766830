import React from 'react'

import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import debounce from 'lodash-es/debounce'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'

interface Props {
    name: string
    onChange?: (value: any | any[], name: string) => void
    defaultValue?: SelectOption[] | null
    placeholder?: string
    multi?: boolean
    hasEmptyValue?: boolean
}

interface State {
    search: string | null
}

const QUERY = gql`
    query consultants($take: Int, $skip: Int, $filters: ConsultantsFiltersType) {
        consultants(take: $take, skip: $skip, filters: $filters) {
            hasNextPage
            nodes {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
        }
    }
`

interface QueryResult {
    id: number
    user: {
        id: number
        profile: {
            id: number
            fullName: string
        }
    }
}

export class ConsultantSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        search: null,
    }

    public render() {
        const { name, multi, onChange, defaultValue: selected, placeholder, hasEmptyValue } = this.props

        const { search } = this.state

        return (
            <PaginatableQuery<QueryResult>
                query={QUERY}
                variables={{
                    filters: {
                        search,
                    },
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const editors = data.nodes.map(({ id, user }) => ({
                        label: user.profile.fullName,
                        value: id,
                    }))

                    const options = [
                        ...(hasEmptyValue ? [{ value: null, label: localize.translate(t => t.Generic.none) }] : []),
                        ...editors,
                    ]

                    return (
                        <Form.Select
                            name={name}
                            loading={loading}
                            multi={multi}
                            defaultValue={selected ? selected : undefined}
                            options={options}
                            placeholder={placeholder}
                            clearable={false}
                            onSearch={this.handleOnSearch}
                            onChange={selectedOptions => {
                                if (onChange && selectedOptions) {
                                    onChange(selectedOptions, name)
                                    this.setState({ search: '' })
                                }
                            }}
                            onEndReached={fetchMore}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }

    // tslint:disable-next-line:member-ordering
    private handleOnSearch = debounce((search: string) => {
        this.setState({ search: search || null })
    }, 250)
}
