import React from 'react'

import { ClassValue } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { LawArticleTopicsQuery } from './LawArticleTopicsQuery'
import { LinkedTopicsTableContainer } from '../Topic/LinkedTopicsTableContainer'
import { TopicType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    articleId: number
    abstractLawArticleId?: number
    wide?: boolean
}

export class ArticleLinkedTopicList extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { className, articleId, abstractLawArticleId, wide } = this.props

        return (
            <LawArticleTopicsQuery articleId={articleId} abstractLawArticleId={abstractLawArticleId}>
                {topics => (
                    <LinkedTopicsTableContainer
                        className={className}
                        topics={(topics || []) as TopicType[]}
                        wide={wide}
                    />
                )}
            </LawArticleTopicsQuery>
        )
    }
}
