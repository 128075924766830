import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import { Button, ButtonType } from '../../Button/Button'
import './UserProfileIcon.scss'
import { Tooltip } from '../../Feedback/Tooltip/Tooltip'
import { Row } from '../../Layout/Row'
import { Paragraph } from '../../Typography/Paragraph'
import { Column } from '../../Layout/Column'
import { localize } from '~/bootstrap'
import { isNode } from '~/utils/isNode'

interface Props {
    className?: ClassValue
    uri?: string | null
    showTooltip?: boolean
    small?: boolean
    large?: boolean
    fullName: string
    phone?: string | null
    email?: string
    action?: () => void
    tabIndex?: number
    disabled?: boolean
}

interface State {
    active: boolean
}

export class UserProfileIcon extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        active: false,
    }

    private containerRef = React.createRef<HTMLDivElement>()

    private bem = new BEM('UserProfileIcon', () => ({
        'is-interactive': !this.props.showTooltip,
        'is-small': this.props.small,
        'is-large': this.props.large,
    }))

    public componentDidMount() {
        document.addEventListener('click', this.handleClick)
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    public render() {
        const { className, fullName, showTooltip, phone, email, action } = this.props

        const { active } = this.state

        const card = (
            <Column className={this.bem.getElement('card')}>
                <Row fullWidth={true} stretchChildren={true}>
                    <UserProfileIcon
                        fullName={fullName}
                        large={true}
                        className={this.bem.getElement('icon')}
                        tabIndex={-1}
                    />
                    <div className={this.bem.getElement('column')}>
                        <Paragraph emphasis={true}>{fullName}</Paragraph>
                        <Paragraph>{phone}</Paragraph>
                        <Paragraph>{email}</Paragraph>
                    </div>
                </Row>

                {action && (
                    <Button type={ButtonType.actionLink} danger={true} onClick={action}>
                        {localize.translate(t => t.Customer.Task.userProfile.deleteFromTask)}
                    </Button>
                )}
            </Column>
        )

        return (
            <div className={this.bem.getClassName(className)} ref={this.containerRef}>
                <Tooltip content={fullName} disabled={!showTooltip} className={this.bem.getElement('test')}>
                    {this.renderAvatar()}
                </Tooltip>

                {active && !showTooltip && card}
            </div>
        )
    }

    private handleClick = (e: MouseEvent) => {
        if (!isNode(e.target)) {
            // event target is not a node
            return
        }

        if (this.containerRef.current && this.containerRef.current.contains(e.target)) {
            // inside click
            return
        }
        // outside click
        this.setState({ active: false })
    }

    private renderAvatar() {
        const { fullName, uri, tabIndex, showTooltip } = this.props

        const avatarContent = (
            <>
                {uri && (
                    <img alt={`Image of ${fullName}`} className={this.bem.getElement('avatar-container')} src={uri} />
                )}
                {!uri && fullName && (
                    <div className={this.bem.getElement('avatar-container')} aria-label={fullName}>
                        <span>{this.getInitialsFromFullName()}</span>
                    </div>
                )}
            </>
        )

        if (showTooltip) {
            return <div>{avatarContent}</div>
        }

        return (
            <Button
                type={ButtonType.noStyling}
                className={this.bem.getElement('button')}
                onClick={() =>
                    this.setState(prevState => ({
                        active: !prevState.active,
                    }))
                }
                tabIndex={tabIndex}
                disabled={this.props.disabled}
            >
                {avatarContent}
            </Button>
        )
    }

    private getInitialsFromFullName() {
        const { fullName } = this.props
        const names = fullName && fullName.split(' ')
        const hasMoreThanOneName = names && names.length > 0

        if (names && hasMoreThanOneName) {
            const lastNameIndex = names.length - 1

            const firstInitial = names[0].charAt(0)
            const lastInitial = names[lastNameIndex].charAt(0)
            return firstInitial + lastInitial
        }

        if (fullName) {
            return fullName.charAt(0)
        }

        return null
    }
}
