import './LinkedInboxItemsTable.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { AlertType, DepartmentAlertDataType } from '~/generated/graphql'
import { InboxLabel } from '../../Inbox/InboxLabel'

interface Props {
    linkedAlerts?: AlertType[] | DepartmentAlertDataType[]
    className?: ClassValue
    isForTooltip?: boolean
}

export class LinkedInboxItemsTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('LinkedInboxItemsTable', () => ({
        'is-for-tooltip': this.props.isForTooltip,
        hasDepartmentColumn: !!this.props.linkedAlerts?.length && 'department' in this.props.linkedAlerts[0],
    }))

    public render() {
        const { linkedAlerts, className } = this.props

        if (!linkedAlerts?.length) {
            return null
        }

        const { columns, tableData } =
            'department' in linkedAlerts[0]
                ? this.getTablePropsWithDepartmentColumn(linkedAlerts as DepartmentAlertDataType[])
                : this.getTablePropsWithoutDepartmentColumn(linkedAlerts as AlertType[])

        return <Table className={this.bem.getClassName(className)} columns={columns} data={tableData as RowData[]} />
    }

    private getTablePropsWithDepartmentColumn(alertDatas: DepartmentAlertDataType[]) {
        const columns = [
            {
                field: 'name',
                headerLabel: localize.translate(t => t.Customer.InboxControl.InboxControlTable.name),
            },
            {
                field: 'department',
                headerLabel: localize.translate(t => t.Entities.Department),
            },
        ]

        const tableData = alertDatas.map(alertData => ({
            id: alertData.id,
            columns: {
                name: <InboxLabel inboxItem={alertData.alert} />,
                department: <LabelTag subtle={true} label={alertData.department.name} />,
            },
        }))

        return { columns, tableData }
    }

    private getTablePropsWithoutDepartmentColumn(alerts: AlertType[]) {
        const columns = [
            {
                field: 'name',
                headerLabel: localize.translate(t => t.Customer.InboxControl.InboxControlTable.name),
            },
        ]

        const tableData = alerts.map(alert => ({
            id: alert.id,
            columns: { name: <InboxLabel inboxItem={alert} /> },
        }))

        return { columns, tableData }
    }
}
