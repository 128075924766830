import './MonitoringReportLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { matchPath } from 'react-router'

interface Props {
    className?: ClassValue
    monitoringReport: {
        id: number
        name: string
    }
    hideLink?: boolean
    iconOnly?: boolean
}

interface State {}

export class MonitoringReportLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportLabel')

    public render() {
        const { className, monitoringReport, hideLink, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).monitoring.reports.detail.view(monitoringReport.id)
        const match = matchPath(
            {
                path: link,
                caseSensitive: false,
            },
            window.location.pathname
        )
        const to = hideLink || match ? undefined : link

        return (
            <IconLabel className={this.bem.getClassName(className)} iconType={IconType.monitoringReport} to={to}>
                {iconOnly ? null : monitoringReport.name}
            </IconLabel>
        )
    }
}
