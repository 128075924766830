import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize, notification, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RadarItemType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { CustomerItemType, ItemCustomerInfoSummary } from '../Customer/ItemCustomerInfoSummary'
import { CreateTaskActionButton } from '../Task/CreateTaskActionButton'
import {
    FollowRadarItemMutation,
    FollowRadarItemMutationResponse,
    FollowRadarItemVariables,
} from './FollowRadarItemMutation'
import {
    UnfollowRadarItemMutation,
    UnfollowRadarItemMutationResponse,
    UnfollowRadarItemVariables,
} from './UnfollowRadarItemMutation'

interface Props {
    radar: Radar
}

type Radar = Pick<RadarItemType, 'id' | '__typename' | 'name' | 'following'> & {
    customerFrameworks?: CustomerItemType['customerFrameworks'] | null
}

export class RadarDetailActionButtons extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.RadarView)

    public render() {
        const { radar } = this.props

        return (
            <Row>
                <Guard condition={permissions.canUnFollowRadarItem(this.context.activeDepartmentId)}>
                    {radar.following ? this.renderUnfollowButton() : this.renderFollowButton()}
                </Guard>
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <CreateTaskActionButton linkedItem={radar as LinkedTaskItem} />
                </Guard>
                <ActionButton
                    tooltipContent={
                        <ItemCustomerInfoSummary item={{ customerFrameworks: radar.customerFrameworks || [] }} />
                    }
                    icon={IconType.info}
                />
            </Row>
        )
    }

    private renderUnfollowButton() {
        const { id } = this.props.radar

        return (
            <UnfollowRadarItemMutation radarItemId={id} departmentId={this.context.activeDepartmentId}>
                {(mutate, { loading }) => {
                    return (
                        <ActionButton
                            icon={IconType.unfollowed}
                            tooltipContent={
                                <Paragraph small emphasis>
                                    {this.loc(t => t.unfollow)}
                                </Paragraph>
                            }
                            loading={loading}
                            onClick={this.handleUnfollow(mutate)}
                        />
                    )
                }}
            </UnfollowRadarItemMutation>
        )
    }

    private renderFollowButton() {
        const { id } = this.props.radar

        return (
            <FollowRadarItemMutation radarItemId={id} departmentId={this.context.activeDepartmentId}>
                {(mutate, { loading }) => {
                    return (
                        <ActionButton
                            icon={IconType.following}
                            tooltipContent={
                                <Paragraph small emphasis>
                                    {this.loc(t => t.follow)}
                                </Paragraph>
                            }
                            loading={loading}
                            onClick={this.handleFollow(mutate)}
                        />
                    )
                }}
            </FollowRadarItemMutation>
        )
    }

    private handleUnfollow =
        (mutate: MutationFn<UnfollowRadarItemMutationResponse, UnfollowRadarItemVariables>) => async () => {
            const response = await mutate({
                variables: {
                    radarItemId: this.props.radar.id,
                    departmentId: this.context.activeDepartmentId,
                },
            })

            if (response && response.data && response.data.unfollowRadarItem) {
                notification.success(this.loc(t => t.unfollowSuccess))
            }
        }

    private handleFollow =
        (mutate: MutationFn<FollowRadarItemMutationResponse, FollowRadarItemVariables>) => async () => {
            const response = await mutate({
                variables: {
                    radarItemId: this.props.radar.id,
                    departmentId: this.context.activeDepartmentId,
                },
            })

            if (response && response.data && response.data.followRadarItem) {
                notification.success(this.loc(t => t.followSuccess))
            }
        }
}
