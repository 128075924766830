import './EventLogItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '../Card/Card'
import { Paragraph } from '../../Typography/Paragraph'
import { EventItem } from './EventLog'
import { UserProfileIcon } from '../UserProfileIcon/UserProfileIcon'
import { DateFormat } from '../../Date/DateFormat'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import { FileType } from '~/services/FileService'
import { Button, ButtonType } from '../../Button/Button'
import { Truncate } from '../../Text/Truncate'
import { File } from '~/graphql/types/File'
import { Row } from '~/components/Core/Layout/Row'
import { MonitoringReportLabel } from '~/components/Domain/Monitoring/MonitoringReportLabel'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    className?: ClassValue
    event: EventItem
}

export class EventLogItem extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('EventLogItem')

    public render() {
        const { event, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {event.actor && (
                    <UserProfileIcon small={true} fullName={event.actor.profile.fullName} showTooltip={true} />
                )}
                <Card className={this.bem.getElement('card')}>
                    <Paragraph className={this.bem.getElement('row')}>
                        <strong className={this.bem.getElement('title')}>{event.title}</strong>
                        {event.description && (
                            <span className={this.bem.getElement('description')}>{event.description}</span>
                        )}
                    </Paragraph>
                    {event.files ? this.renderFileList(event.files) : null}
                    {event.reports ? this.renderReportsList(event.reports) : null}
                    <Row smallSpacing={true}>
                        <DateFormat
                            date={new Date(event.date)}
                            readable={true}
                            noWeekday={true}
                            className={this.bem.getElement('date')}
                            includeTime={true}
                        />
                        {event.actor && (
                            <Paragraph className={this.bem.getElement('actor')}>
                                {event.actor.profile.fullName}
                            </Paragraph>
                        )}
                    </Row>
                </Card>
            </div>
        )
    }

    private renderFileList(files: File[]) {
        if (files.length === 0) {
            return null
        }

        const fileLinks = files.map((file, index) => (
            <li key={`${file.id}-${index}`}>
                <FilePreviewModal file={file} fileType={FileType.taskMotivation}>
                    {previewFile => (
                        <Button onClick={previewFile} type={ButtonType.actionLink}>
                            <Truncate truncateMiddle={file.name} />
                        </Button>
                    )}
                </FilePreviewModal>
            </li>
        ))

        return <ul className={this.bem.getElement('file-list')}>{fileLinks}</ul>
    }

    private renderReportsList(reports: { id: number; name: string }[]) {
        if (reports.length === 0) {
            return null
        }

        return (
            <Column smallSpacing={true} className={this.bem.getElement('reports-list')}>
                {reports.map(report => (
                    <Card small={true} className={this.bem.getElement('report-card')} key={`${report.id}`}>
                        <MonitoringReportLabel monitoringReport={report} />
                    </Card>
                ))}
            </Column>
        )
    }
}
