import './AvatarUpload.scss'

import React, { ChangeEventHandler } from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Avatar, AvatarSize } from '~/components/Core/DataDisplay/Avatar/Avatar'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    uri?: string
    label?: string
    name: string
    onChange?: (value: File | null, name: string) => void
    clear?: boolean
}

interface State {
    preview?: string
    dirty: boolean
}

export class AvatarUpload extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        preview: !this.props.clear ? (this.props.uri ? this.props.uri : undefined) : undefined,
        dirty: false,
    }

    private bem = new BEM('AvatarUpload')

    private inputRef = React.createRef<HTMLInputElement>()

    public componentWillReceiveProps(props: Props) {
        if (props.clear && this.state.dirty) {
            this.setState({ preview: undefined })
        }
    }

    public render() {
        const { className, label } = this.props
        const { preview } = this.state

        return (
            <Row className={this.bem.getClassName(className)}>
                <Avatar
                    size={AvatarSize.large}
                    uri={preview}
                    label={label || ''}
                    className={this.bem.getElement('avatar')}
                />
                <input
                    ref={this.inputRef}
                    type="file"
                    className={this.bem.getElement('upload')}
                    onChange={this.handleChange}
                    accept="image/*"
                />
                <div className={this.bem.getElement('action-container')}>
                    <button
                        className={this.bem.getElement('button-upload')}
                        type="button"
                        onClick={() => {
                            if (this.inputRef.current) {
                                this.inputRef.current.click()
                            }
                        }}
                    >
                        {localize.translate(t => t.Core.AvatarUpload.upload)}
                    </button>
                    {preview && (
                        <button
                            onClick={this.removeImage}
                            className={this.bem.getElement('button-remove')}
                            type="button"
                        >
                            {localize.translate(t => t.Core.AvatarUpload.delete)}
                        </button>
                    )}
                </div>
            </Row>
        )
    }

    private removeImage = () => {
        this.setState({ preview: undefined })
        const { onChange, name } = this.props

        if (onChange) {
            onChange(null, name)
        }
    }

    private handleChange: ChangeEventHandler<HTMLInputElement> = event => {
        const { onChange, name } = this.props

        if (onChange && event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]

            this.setState({ preview: URL.createObjectURL(file), dirty: true })

            onChange(file, name)
        }
    }
}
