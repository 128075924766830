import './SearchResults.scss'

import React from 'react'
import { CustomerFramework, CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'
import gql from 'graphql-tag'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { BEM } from '~/services/BEMService'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TextLink } from '~/components/Core/Text/TextLink'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { SearchOverviewFilters } from './SearchOverviewView'
import { isNumber } from 'lodash-es'

interface Props {
    filters: SearchOverviewFilters
}

interface State {}

const SEARCH_QUERY = gql`
    query search($query: String!, $filters: SearchFiltersInputType, $skip: Int, $take: Int, $customerSlug: String) {
        search(query: $query, skip: $skip, take: $take, filters: $filters, customerSlug: $customerSlug) {
            hasNextPage
            nodes {
                id
                entityId
                type
                title
                date
                meta
                highlights {
                    title
                    content
                    keywords
                }
                customerFrameworks {
                    ...CustomerFrameworkFields
                }
            }
        }
    }
    ${CustomerFrameworkFragment}
`

interface SearchResult {
    id: string
    entityId: number
    type: 'alert' | 'consultation' | 'law' | 'lawarticle' | 'newsitem' | 'radar' | 'theme' | 'topic'
    title: string
    date: string
    meta?: Record<string, any>
    highlights: {
        title: string[] | null
        content: string[] | null
        keywords: string[] | null
    }
    customerFrameworks: CustomerFramework[] | null
}

export class SearchResults extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SearchResults')
    private loc = localize.namespaceTranslate(t => t.Customer.Search.SearchOverviewView)
    private filterTagMapping = [
        {
            id: 1,
            values: undefined,
        },
        {
            id: 2,
            values: ['theme', 'topic'],
        },
        {
            id: 3,
            values: ['newsitem'],
        },
        {
            id: 4,
            values: ['consultation'],
        },
        {
            id: 5,
            values: ['alert'],
        },
        {
            id: 6,
            values: ['radar'],
        },
        {
            id: 7,
            values: ['law', 'lawarticle'],
        },
    ]

    public render() {
        const { search, newsGroupId, newsSourceId, startAt, endAt } = this.props.filters

        if (!search) {
            return (
                <div className={this.bem.getClassName()}>
                    <NoResults label={this.loc(t => t.enterSearchQuery)} />
                </div>
            )
        }

        return (
            <InfiniteScrollQuery<SearchResult>
                query={SEARCH_QUERY}
                variables={{
                    query: search,
                    customerSlug: this.context.customer.slug,
                    filters: {
                        frameworkIds: this.context.activeProfiles,
                        searchIn: this.getTagsToSearchIn(),
                        newsGroupId: newsGroupId && isNumber(newsGroupId.value) ? newsGroupId.value : undefined,
                        newsSourceId: newsSourceId && isNumber(newsSourceId.value) ? newsSourceId.value : undefined,
                        startDate: startAt,
                        endDate: endAt,
                    },
                }}
            >
                {({ data, loading, loadingMore }) => {
                    const searchResults = data ? (data.nodes ? data.nodes : null) : null

                    return (
                        <div className={this.bem.getClassName()}>
                            {this.renderSearchResults(searchResults, loading)}
                            {loadingMore && (
                                <Spinner large={true} delayed={true} className={this.bem.getElement('loader')} />
                            )}
                        </div>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private renderSearchResults(results: SearchResult[] | null, loading: boolean) {
        const { search } = this.props.filters
        const hasResults = results && results.length > 0

        if (!hasResults && loading) {
            return <Spinner large={true} delayed={true} className={this.bem.getElement('loader')} />
        }

        if (!hasResults) {
            return (
                <NoResults
                    label={search ? `${this.loc(t => t.noResultsFor)}"${search}"` : this.loc(t => t.noResults)}
                />
            )
        }

        return (
            <>
                {loading && <Spinner large={true} delayed={true} className={this.bem.getElement('fixed-loader')} />}
                <ul>
                    {results!.map(result => {
                        const content = (result.highlights.content || [])
                            .map(str => str.replace(/\.$/gi, ''))
                            .join('... ')

                        return (
                            <li key={result.id} className={this.bem.getElement('item')}>
                                <div className={this.bem.getElement('header')}>
                                    <TextLink
                                        to={this.getRouteForResult(result)}
                                        className={this.bem.getElement('title')}
                                    >
                                        {result.title}
                                    </TextLink>
                                    {content && (
                                        <p
                                            className={this.bem.getElement('content')}
                                            dangerouslySetInnerHTML={{
                                                __html: `${content}...`,
                                            }}
                                        />
                                    )}
                                </div>
                                {this.renderDate(result)}
                                <div>
                                    <div className={this.bem.getElement('type')}>
                                        <Paragraph small={true} bold={true}>
                                            {this.translateResultType(result)}
                                        </Paragraph>
                                    </div>
                                    {result.customerFrameworks && (
                                        <CustomerFrameworkIconList
                                            className={this.bem.getElement('frameworks')}
                                            customerFrameworks={result.customerFrameworks}
                                        />
                                    )}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }

    private renderDate(result: SearchResult) {
        if (!result.date || result.type === 'topic' || result.type === 'theme') {
            return null
        }

        return (
            <Column className={this.bem.getElement('date')}>
                <Paragraph subtle={true} bold={true} small={true}>
                    {this.getDateLabel(result)}
                </Paragraph>
                <DateFormat noWeekday={true} readable={true} date={new Date(result.date)} />
            </Column>
        )
    }

    private getDateLabel(result: SearchResult) {
        if (result.type === 'newsitem') {
            return this.loc(t => t.dateLabel.newsitem)
        }

        if (result.type === 'alert') {
            return this.loc(t => t.dateLabel.alert)
        }

        if (result.type === 'consultation') {
            return this.loc(t => t.dateLabel.consultation)
        }

        if (result.type === 'radar') {
            return this.loc(t => t.dateLabel.radar)
        }

        if (result.type === 'lawarticle') {
            return this.loc(t => t.dateLabel.lawarticle)
        }

        if (result.type === 'law') {
            return this.loc(t => t.dateLabel.law)
        }

        return this.loc(t => t.dateLabel.default)
    }

    private translateResultType(result: SearchResult): string {
        if (result.type === 'newsitem') {
            return this.loc(t => t.typeLabel.newsitem)
        }

        if (result.type === 'alert') {
            return this.loc(t => t.typeLabel.alert)
        }

        if (result.type === 'consultation') {
            return this.loc(t => t.typeLabel.consultation)
        }

        if (result.type === 'radar') {
            return this.loc(t => t.typeLabel.radar)
        }

        if (result.type === 'theme') {
            return this.loc(t => t.typeLabel.theme)
        }

        if (result.type === 'topic') {
            return this.loc(t => t.typeLabel.topic)
        }

        if (result.type === 'law') {
            return this.loc(t => t.typeLabel.law)
        }

        if (result.type === 'lawarticle') {
            return this.loc(t => t.typeLabel.lawarticle)
        }

        return ''
    }

    private getRouteForResult(result: SearchResult): string {
        if (result.type === 'newsitem') {
            return routes.customer(this.context.customer.slug).news.detail.view(result.entityId)
        }

        if (result.type === 'alert') {
            return routes.customer(this.context.customer.slug).inbox.detail.view(result.entityId)
        }

        if (result.type === 'consultation') {
            return routes.customer(this.context.customer.slug).consultations.detail.view(result.entityId)
        }

        if (result.type === 'radar') {
            return routes.customer(this.context.customer.slug).radar.detail.view(result.entityId)
        }

        if (result.type === 'theme') {
            return routes.customer(this.context.customer.slug).legalFramework.themes.view(result.entityId)
        }

        if (result.type === 'topic') {
            return routes.customer(this.context.customer.slug).legalFramework.topic.view(result.entityId)
        }

        if (result.type === 'law') {
            return routes.customer(this.context.customer.slug).lawAndRegulation.detail.view(result.meta!.abstractLawId)
        }

        if (result.type === 'lawarticle') {
            if (result.meta!.abstractLawId) {
                return routes
                    .customer(this.context.customer.slug)
                    .lawAndRegulation.detail.article(result.meta!.abstractLawId, result.meta!.id)
            }

            return routes.customer(this.context.customer.slug).lawAndRegulation.detail.singleArticle(result.meta!.id)
        }

        return routes.index
    }

    private getTagsToSearchIn() {
        const { tags } = this.props.filters

        const tagsToSearch = tags
            ? tags.flatMap(id => this.filterTagMapping.find(mapping => mapping.id === id)!.values)
            : undefined

        if (!tagsToSearch || tagsToSearch.length === 0) {
            return undefined
        }
        return {
            alert: tagsToSearch.includes('alert'),
            consultation: tagsToSearch.includes('consultation'),
            law: tagsToSearch.includes('law'),
            lawarticle: tagsToSearch.includes('lawarticle'),
            newsitem: tagsToSearch.includes('newsitem'),
            radar: tagsToSearch.includes('radar'),
            theme: tagsToSearch.includes('theme'),
            topic: tagsToSearch.includes('topic'),
        }
    }
}
