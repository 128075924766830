import './MonitoringReportDetail.scss'
import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringReportDetailType } from './MonitoringReportQuery'
import { breadcrumbs } from '~/views/breadcrumbs'
import MonitoringDetailActionButtons from './MonitoringDetailActionButtons'
import { SidebarTaskWidgetContainer } from '../../Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { Page } from '~/components/Core/Layout/Page'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { MonitoringReportDetailMeta } from './MonitoringReportDetailMeta'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { BEM } from '~/services/BEMService'
import { MonitoringReportTopicsTable } from './MonitoringReportTopicsTable'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    monitoringReport: MonitoringReportDetailType
    refetch: () => void
}

interface State {
    filePath?: string
}

export class MonitoringReportsDetail extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportDetail')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringDetailView)
    private sidebarRef = React.createRef<SidebarTaskWidgetContainer>()

    public state: State = {
        filePath: undefined,
    }

    public render() {
        const { monitoringReport, refetch } = this.props

        if (!permissions.canViewMonitoringReports()) {
            return null
        }

        const hasUnassessedTopics = monitoringReport.monitoringReportTopics?.some(
            // multiplied by 2 because there are 2 assessments per linked control/note
            t => !t.isRemoved && !t.notApplicable && t.assessments?.length !== (t.controlsAndNotes?.length || 0) * 2
        )

        return (
            <Page className={this.bem.getClassName()}>
                <PageHeader
                    breadCrumbs={[
                        breadcrumbs.customer(this.context.customer.slug).monitoring.index,
                        breadcrumbs.customer(this.context.customer.slug).monitoring.reports.index,
                    ]}
                    title={monitoringReport.name}
                    suffix={hasUnassessedTopics && this.renderUnassessedWarning()}
                    subtle={!!monitoringReport.archivedAt}
                    actionButtons={
                        <MonitoringDetailActionButtons
                            refetch={() => {
                                refetch()
                                this.sidebarRef.current?.refetch?.()
                            }}
                            monitoringReport={monitoringReport}
                        />
                    }
                />
                <PageDetail spaceBetween renderSidebar={() => this.renderSideBar()} smallBottomSpacing>
                    <Column extraBigSpacing={true}>
                        <MonitoringReportDetailMeta monitoringReport={monitoringReport} />
                    </Column>
                </PageDetail>
                {this.renderTopicsSection()}
            </Page>
        )
    }

    private renderUnassessedWarning() {
        return (
            <Tooltip
                direction={ToolTipDirection.rightStart}
                content={
                    <Column className={this.bem.getElement('warning-content')}>
                        <Paragraph>{this.loc(t => t.AssessmentWarning.title)}</Paragraph>
                        <Paragraph small>{this.loc(t => t.AssessmentWarning.content)}</Paragraph>
                    </Column>
                }
            >
                <Icon className={this.bem.getElement('warning')} type={IconType.warning} />
            </Tooltip>
        )
    }

    private renderSideBar() {
        const { monitoringReport, refetch } = this.props

        return (
            <SidebarTaskWidgetContainer
                ref={this.sidebarRef}
                possibleDepartments={monitoringReport.departments || undefined}
                forCustomer
                linkedItem={monitoringReport as LinkedTaskItem}
                refetch={refetch}
            />
        )
    }

    private renderTopicsSection() {
        this.props.monitoringReport.monitoringReportTopics?.sort(
            (a, b) => a.topic.name.localeCompare(b.topic.name) || a.department.name.localeCompare(b.department.name)
        )

        return (
            <Column className={this.bem.getElement('topics-section')}>
                <SectionTitle>{this.loc(t => t.topicsSectionTitle)}</SectionTitle>
                <MonitoringReportTopicsTable
                    monitoringReport={this.props.monitoringReport}
                    monitoringReportTopics={this.props.monitoringReport.monitoringReportTopics || []}
                    onAssess={this.props.refetch}
                />
            </Column>
        )
    }
}
