import React from 'react'
import { ToggleTag } from '../Search/ToggleTag'
import { Row } from '../../Layout/Row'
import { Screensize, MediaQuery } from '../../Layout/MediaQuery'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { animations, animationTiming } from '~/animations'
import { MobileFilterOverlay } from '~/components/Domain/Search/MobileFilterOverlay'
import { Button } from '../../Button/Button'
import { IconType } from '../../Icon/IconType'
import { localize } from '~/bootstrap'

interface FilterItem {
    id: number
    name: string
}

interface Props {
    filters: FilterItem[]
    defaultActive?: number[] | null
    onChange?: (filters: number[]) => void
    getUpdatedFilterIdsOnToggle?: (activeFilterIds: number[], filterId: number, isActive: boolean) => number[]
}

interface State {
    activeFilters: number[]
    showFilterOverlay: boolean
}

export class FilterTags extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        activeFilters: this.props.defaultActive ? this.props.defaultActive : [],
        showFilterOverlay: false,
    }

    public render() {
        const { activeFilters } = this.state

        return (
            <>
                <MediaQuery breakpoint={Screensize.tablet}>
                    <Row wrap={true} smallSpacing={true}>
                        {this.renderFilters()}
                    </Row>
                </MediaQuery>

                <MediaQuery breakpoint={Screensize.mobile}>
                    <Row>
                        <Button onClick={this.toggleFilterOverlay} icon={IconType.filter}>
                            {this.isFiltered()
                                ? localize.translate(t => t.Generic.filterAndAmount, { amount: activeFilters.length })
                                : localize.translate(t => t.Generic.filter)}
                        </Button>
                        {this.renderMobileFilterOverlay()}
                    </Row>
                </MediaQuery>
            </>
        )
    }

    private renderFilters = () => {
        const { filters } = this.props

        return filters.map((filter, index) => (
            <ToggleTag
                key={`${index}-${filter.id}`}
                isActive={this.isFilterActive(filter.id)}
                label={filter.name}
                onToggle={isActive => this.toggleFilterTag(filter.id, isActive)}
            />
        ))
    }

    private isFilterActive(id: number) {
        if (this.state.activeFilters.length === 0) {
            return true
        }

        return this.state.activeFilters.includes(id)
    }

    private toggleFilterTag(filter: number, isActive: boolean) {
        const { activeFilters } = this.state
        const { onChange, getUpdatedFilterIdsOnToggle } = this.props

        const updatedFilters = getUpdatedFilterIdsOnToggle
            ? getUpdatedFilterIdsOnToggle(activeFilters, filter, isActive)
            : this.getUpdatedFilterIds(filter, isActive)

        if (onChange) {
            onChange(updatedFilters)
        }
        this.setState({ activeFilters: updatedFilters })
    }

    private toggleFilterOverlay = () => {
        this.setState(prevstate => ({
            showFilterOverlay: !prevstate.showFilterOverlay,
        }))
    }

    private renderMobileFilterOverlay() {
        const { showFilterOverlay } = this.state

        if (!showFilterOverlay) {
            return null
        }

        return (
            <TransitionGroup component={null}>
                {showFilterOverlay && (
                    <CSSTransition
                        classNames={animations.fadeIn}
                        timeout={animationTiming.defaultTiming}
                        appear={true}
                        in={true}
                    >
                        <MobileFilterOverlay toggle={this.toggleFilterOverlay}>
                            {this.renderFilters()}
                        </MobileFilterOverlay>
                    </CSSTransition>
                )}
            </TransitionGroup>
        )
    }

    private isFiltered() {
        const { activeFilters } = this.state
        const { filters } = this.props

        if (activeFilters.length === filters.length) {
            return false
        }

        return activeFilters.length < filters.length
    }

    private getUpdatedFilterIds(filter: number, isActive: boolean) {
        const { activeFilters } = this.state
        const { filters } = this.props

        if (activeFilters.length === 0 || activeFilters.length === filters.length) {
            return [filter]
        }

        if (!isActive) {
            return activeFilters.filter(existingFilters => existingFilters !== filter)
        }

        return [...activeFilters, filter]
    }
}
