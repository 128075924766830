import React from 'react'
import { LayoutInfo } from '~/components/Core/Layout/ResponsiveGrid'
import { DashboardDetailViewContainer } from '~/components/Domain/Dashboard/DashboardDetailViewContainer'
import { DashboardWidgetContainer } from '~/components/Domain/Dashboard/DashboardWidgetContainer'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DetailViewWidgetInfo } from '~/views/Customer/Dashboard/DashboardView'
import {
    DashboardWidget,
    getWidgetKeyFromDashboardWidgetType,
    WidgetKey,
} from '~/components/Domain/Dashboard/CreateWidgetWidget/widgets'
import {
    DashboardLayoutQueryVariables,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetLayoutType,
    DashboardLayoutDocument,
    DashboardWidgetMetadataFragmentFragment,
    DashboardWidgetContentFragmentFragment,
} from '~/generated/graphql'

interface Props {
    isEditing: boolean
    isOnDetailView: boolean
    widgetIdsToDelete: number[]
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    detailViewWidgetInfo: DetailViewWidgetInfo
    handleWidgetClick: (widgetId: number, type: WidgetKey) => void
    handleDeleteIconClick: (widgetId: number) => void
    handleLayoutChange: (newLayout: LayoutInfo[]) => void
    onNavigateBack: () => void
    onCreateWidget: () => void
}

export class DashboardViewContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private userLayoutRef = React.createRef<HTMLDivElement>()

    public render() {
        const { isOnDetailView } = this.props

        if (isOnDetailView) {
            return this.renderDetailView()
        }

        return this.renderUserLayout()
    }

    private renderDetailView() {
        const { detailViewWidgetInfo, currentBreakpoint, onNavigateBack } = this.props
        const { id, type } = detailViewWidgetInfo

        if (!currentBreakpoint) {
            return null
        }

        return (
            <DashboardDetailViewContainer
                onNavigateBack={onNavigateBack}
                currentBreakpoint={currentBreakpoint}
                widgetId={id}
                type={type}
            />
        )
    }

    private renderUserLayout() {
        const { isEditing, widgetIdsToDelete, currentBreakpoint, handleWidgetClick, handleDeleteIconClick } = this.props
        const { onCreateWidget } = this.props
        const { handleLayoutChange } = this.props

        if (!currentBreakpoint) {
            return null
        }

        return (
            <PageQuery<DashboardWidgetLayoutType[], DashboardLayoutQueryVariables>
                variables={{ layout: currentBreakpoint }}
                query={DashboardLayoutDocument}
            >
                {(data, { refetch }) => (
                    <div ref={this.userLayoutRef}>
                        <DashboardWidgetContainer
                            onWidgetClick={handleWidgetClick}
                            isEditing={isEditing}
                            layout={this.getLayout(data)}
                            currentBreakpoint={currentBreakpoint}
                            widgetIdsToDelete={widgetIdsToDelete}
                            onDeleteIconClick={handleDeleteIconClick}
                            onLayoutChange={handleLayoutChange}
                            onCreateWidget={onCreateWidget}
                            onModalClose={refetch}
                        />
                    </div>
                )}
            </PageQuery>
        )
    }

    private getLayout(queryResult: DashboardWidgetLayoutType[]) {
        const layout: DashboardWidget[] = []

        for (const widget of queryResult) {
            const { widgetType, id, x, y, metadata, content } = widget

            const type = getWidgetKeyFromDashboardWidgetType(widgetType)
            if (!type) {
                continue
            }

            layout.push({
                id,
                // The { lg, md.. } format is for the onLayoutChange handler of ResponsiveGrid.
                // Because the current query will be refetched at each window width change,
                // we don't need to be concerned about passing wrong values to wrong breakpoints
                x: { lg: x, md: x, sm: x, xs: x },
                y: { lg: y, md: y, sm: y, xs: y },
                type,
                metadata: metadata as DashboardWidgetMetadataFragmentFragment,
                content: content as DashboardWidgetContentFragmentFragment,
            })
        }

        return layout
    }
}
