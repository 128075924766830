import { SessionService } from '~/services/SessionService'
import { apolloClient } from '~/services/ApolloService'
import { User, UserStatus, EmployeeWithPassiveDepartmentEmployees } from '~/graphql/types/User'
import { CURRENT_USER_QUERY, CurrentUserQueryResponse } from '~/graphql/queries/CurrentUserQuery'
import { logoutMutation, LogoutMutationResponse } from '~/graphql/mutations/LogoutMutation'
import { localize } from '~/bootstrap'
import {
    AccessGroup,
    EmployeeOperationEnum,
    EmployeePermissionEnum,
    EmployeeRole,
    Language,
    SwitchCustomerDocument,
    UserRole,
} from '~/generated/graphql'
import { UserProvider } from '~/components/Providers/UserProvider'
import { prefixWithAppPrefix } from '~/config'
import { Locale } from '~/i18n/TranslationKeys'
import { LocalStorageKeys, LocalStorageService } from './LocalStorageService'

type UserPromiseResult = Promise<User | undefined>

export type OnUserPromiseCallBackHandler = (userPromise: UserPromiseResult) => void

export const roleGroups = {
    [AccessGroup.editor]: [UserRole.editorAdministrator, UserRole.editor, UserRole],
    [AccessGroup.customer]: [
        EmployeeRole.customer,
        EmployeeRole.customerReader,
        EmployeeRole.customerReaderWithCompliance,
        EmployeeRole.customerAdministrator,
        EmployeeRole.customerPlanner,
    ],
    [AccessGroup.consultant]: [UserRole.consultant, UserRole.consultantAdministrator],
}

export class UserService {
    constructor(private sessionService: SessionService) {}

    /**
     * Retrieves the current user from memory
     */
    public getCurrentUser(): User | undefined {
        try {
            const data = apolloClient.readQuery<CurrentUserQueryResponse>({ query: CURRENT_USER_QUERY })
            const user = data ? data.getAuthenticatedUser : undefined
            return user
        } catch (err) {
            return undefined
        }
    }

    /**
     * Retrieves the current user employee from memory
     */
    public getCurrentUserEmployee(): EmployeeWithPassiveDepartmentEmployees | undefined {
        try {
            const data = apolloClient.readQuery<CurrentUserQueryResponse>({ query: CURRENT_USER_QUERY })
            const employee = data ? data.employee : undefined
            return employee
        } catch (err) {
            return undefined
        }
    }

    public getReadableRole(role?: EmployeeRole): string {
        if (!role) {
            const employee = this.getCurrentUserEmployee()

            if (!employee) {
                return ''
            }

            return localize.translate(t => t.User.roles[employee.role])
        }

        return localize.translate(t => t.User.roles[role])
    }

    public getReadableStatus(status?: UserStatus): string {
        let userStatus = status

        if (!userStatus) {
            const user = this.getCurrentUser()

            if (!user) {
                return ''
            }

            userStatus = user.status
        }

        return localize.translate(t => t.User.status[userStatus as string])
    }

    /**
     * Checks if the current user has a given Role
     */
    public hasRoles(oneOfRoles: EmployeeRole | EmployeeRole[] | UserRole | UserRole[] | undefined) {
        if (!oneOfRoles) {
            return false
        }

        const user = this.getCurrentUser()
        const employee = this.getCurrentUserEmployee()

        if (!user?.role && !employee?.role) {
            return false
        }

        const rolesToCheckAgainst = Array.isArray(oneOfRoles) ? oneOfRoles : [oneOfRoles]
        if (employee?.role) {
            return rolesToCheckAgainst.includes(employee.role)
        }

        return rolesToCheckAgainst.includes(user!.role as UserRole)
    }

    /**
     * Checks if the current user has a given Permission
     */
    public hasPermissions(oneOfPermissions: EmployeePermissionEnum | EmployeePermissionEnum[] | undefined) {
        const employee = this.getCurrentUserEmployee()

        if (!employee || !oneOfPermissions) {
            return false
        }

        const permissionsToCheckAgainst = Array.isArray(oneOfPermissions) ? oneOfPermissions : [oneOfPermissions]
        return !!employee.permissions?.some(permission =>
            permissionsToCheckAgainst.includes(permission as EmployeePermissionEnum)
        )
    }

    /**
     * Checks if the current user has a given Operation
     */
    public hasOperations(oneOfOperations: EmployeeOperationEnum | EmployeeOperationEnum[] | undefined) {
        const employee = this.getCurrentUserEmployee()

        if (!employee || !oneOfOperations) {
            return false
        }

        const operationsToCheckAgainst = Array.isArray(oneOfOperations) ? oneOfOperations : [oneOfOperations]
        return !!employee.operations?.some(operation => operationsToCheckAgainst.includes(operation))
    }

    public isCurrentUser(userId: number | string) {
        const user = this.getCurrentUser()

        if (!user) {
            return false
        }

        return user.id === parseInt(userId as string, 10)
    }
    /**
     * Signs out
     */
    public logout = async (): Promise<void> => {
        LocalStorageService.removeItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)

        const session = this.sessionService.getSession()
        if (!session) {
            return
        }

        const result = await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: logoutMutation,
            variables: {
                token: session.token,
            },
        })

        if (result.data) {
            const data = result.data as LogoutMutationResponse
            const success = data.logoutSession

            if (success) {
                this.sessionService.removeSession()
                await localize.initialize()
            }
        }

        // Reset the apollo client
        await apolloClient.resetStore()
    }

    public async loginWithUserIdAndToken(userId: number, token: string, redirect: (path: string) => void) {
        const currentSession = this.sessionService.getSession()
        if (!currentSession || currentSession.token !== token || currentSession.userId !== userId) {
            this.setUserAndToken(userId, token)
            await UserProvider.refetch()

            const language = this.getCurrentUserLanguage()
            this.setUserAndToken(userId, token, language)
        }

        const intent = window.sessionStorage.getItem(prefixWithAppPrefix('intent'))
        window.sessionStorage.removeItem(prefixWithAppPrefix('intent'))

        if (intent) {
            redirect(intent)
        } else {
            redirect('/')
        }
    }

    public getCurrentUserLanguage(): Locale {
        const user = this.getCurrentUser()
        if (!user || !user.language) {
            return Language.nl
        }

        return user.language
    }

    public isPassiveUserForDepartment(departmentId: number) {
        const user = this.getCurrentUser()
        if (!user) {
            return true // should not have active user access if user isnt found
        }

        const data = apolloClient.readQuery<CurrentUserQueryResponse>({ query: CURRENT_USER_QUERY })

        const employee = data?.employee
        if (!employee) {
            return true // should not have active user access if employee isnt found
        }

        const department = employee.departments.find(({ id }) => id === departmentId)
        if (!department) {
            return true // should not have active user access if department isnt found
        }

        const isUserPassiveInDepartment = department.passiveEmployees.find(({ id }) => id === employee.id)

        return !!isUserPassiveInDepartment
    }

    public async switchUserCustomer(employeeId: number, onSuccess: () => void) {
        const session = this.sessionService.getSession()

        if (!session) {
            return
        }

        const { token } = session

        const response = await apolloClient.mutate({
            mutation: SwitchCustomerDocument,
            variables: { employeeId, token },
        })

        if (response && response.data?.switchEmployee) {
            onSuccess()
        }
    }

    public get hasEditorialAccess() {
        return !!this.getCurrentUserEmployee()?.customer.editorialAccess
    }

    private setUserAndToken(userId: number, token: string, language: Locale = Language.nl) {
        this.sessionService.setSession({
            token,
            userId,
            language,
        })
    }
}
