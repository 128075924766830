import './AllTasksTable.scss'

import React from 'react'
import {
    Table,
    ColumnOptions,
    SortDirectionChangeHandler,
    RowData,
    SortOption,
} from '~/components/Core/DataDisplay/Table/Table'
import { localize } from '~/bootstrap'
import { RevisionDateFormat } from '~/components/Domain/Compliance/Controls/RevisionDateFormat'
import { TaskNameWithCompleteButton } from '~/components/Domain/Task/TaskNameWithCompleteButton'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { addDays } from 'date-fns'
import { LinkedTaskLabel } from '~/components/Domain/Task/LinkedTaskLabel'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { PriorityIndicator } from '~/components/Domain/Task/PriorityIcon/PriorityIndicator'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    tasks: Task[]
    loading: boolean
    loadingMore: boolean
    onSortDirectionChange?: SortDirectionChangeHandler
    onTaskStatusChange?: (task: Task) => void
    excludeColumns?: string[]
    defaultSortDirection?: SortOption
    sortableHeaders: ('name' | 'priority' | 'dueAt' | 'startAt')[]
    refetchTasks?: () => void
    noLinkedItemIcon?: boolean
}

export class AllTasksTable extends React.Component<React.PropsWithChildren<Props>, {}> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public static defaultProps: Partial<Props> = {
        sortableHeaders: ['dueAt', 'name', 'priority'],
    }

    private bem = new BEM('AllTasksTable', () => ({
        noLinkedItemIcon: this.props.noLinkedItemIcon,
        isInEmptyState: !this.props.tasks.length,
    }))
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.AllTasksTable)

    public render() {
        const { defaultSortDirection, onSortDirectionChange, loading, loadingMore } = this.props

        return (
            <Table
                className={this.bem.getClassName('AllTasksTable')}
                loading={loading}
                loadingMore={loadingMore}
                columns={this.getTableColumns()}
                data={this.getTableData()}
                emptyState={this.loc(t => t.empty)}
                defaultSortDirection={defaultSortDirection}
                onSortDirectionChange={onSortDirectionChange}
            />
        )
    }

    private getTableColumns(): ColumnOptions[] {
        const { excludeColumns, sortableHeaders } = this.props
        return [
            {
                field: 'name',
                headerLabel: this.loc(t => t.headers.name),
                sortable: sortableHeaders.includes('name'),
                minWidth: 250,
            },
            {
                field: 'startAt',
                headerLabel: this.loc(t => t.headers.startAt),
                sortable: sortableHeaders.includes('startAt'),
            },
            {
                field: 'dueAt',
                headerLabel: this.loc(t => t.headers.dueAt),
                sortable: sortableHeaders.includes('dueAt'),
            },
            {
                field: 'priority',
                headerLabel: this.loc(t => t.headers.priority),
                sortable: sortableHeaders.includes('priority'),
            },
        ].filter(columns => {
            if (excludeColumns && excludeColumns.includes(columns.field)) {
                return false
            }

            return true
        })
    }

    private getTableData(): RowData[] {
        const { tasks } = this.props

        return tasks.map(task => ({
            id: task.id,
            columns: {
                name: this.renderName(task),
                startAt: this.renderStartAt(task),
                dueAt: this.renderDueAt(task),
                priority: <PriorityIndicator isTooltip={true} priority={task.priority} />,
            },
            inactive: !!task.completedAt,
        }))
    }

    private renderName(task: Task) {
        const { refetchTasks } = this.props

        return (
            <Row className={this.bem.getElement('name-container')}>
                {this.renderLinkedItemIcon(task)}
                <TaskNameWithCompleteButton
                    to={`${routes.customer(this.context.customer.slug).tasks.view(task.id)}${location.search || ''}`}
                    task={task}
                    onChange={() => {
                        this.onChangeTaskIsCompleted(task)

                        if (refetchTasks) {
                            refetchTasks()
                        }
                    }}
                />
            </Row>
        )
    }

    private renderLinkedItemIcon(task: Task) {
        const { noLinkedItemIcon } = this.props

        if (noLinkedItemIcon) {
            return
        }

        const className = this.bem.getElement('linked-item-container', () => ({ isSubTask: task.isSubTask }))

        if (task.isReviewTask) {
            const linkedTasks = task.linkedAlertsToReview.length ? task.linkedAlertsToReview : task.linkedTasksToReview

            return (
                <div className={className}>
                    <LinkedTaskLabel
                        linkedTasks={linkedTasks}
                        onReviewTaskChange={() => this.onChangeTaskIsCompleted(task)}
                        reviewTaskId={task.id}
                        iconOnly={true}
                    />
                </div>
            )
        }

        if (task.linkedItem) {
            return (
                <div className={className}>
                    <LinkedTaskLabel linkedItem={task.linkedItem} iconOnly={true} tooltip={true} />
                </div>
            )
        }

        return (
            <div className={className}>
                <LinkedTaskLabel iconOnly={true} />
            </div>
        )
    }

    private onChangeTaskIsCompleted(task: Task) {
        const { onTaskStatusChange, refetchTasks } = this.props

        if (refetchTasks) {
            refetchTasks()
        }

        if (onTaskStatusChange) {
            onTaskStatusChange(task)
        }
    }

    private renderStartAt(task: Task) {
        if (!task.startAt) {
            return
        }

        return <DateFormat date={new Date(task.startAt)} readable={true} noWeekday={true} />
    }

    private renderDueAt(task: Task) {
        if (!task.dueAt) {
            return
        }

        if (task.completedAt) {
            return <DateFormat date={new Date(task.dueAt)} readable={true} noWeekday={true} />
        }

        return <RevisionDateFormat date={new Date(task.dueAt)} thresholdDate={addDays(new Date(task.dueAt), 1)} />
    }
}
