import './CappedProfileList.scss'

import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import { UserProfileIcon } from '../UserProfileIcon/UserProfileIcon'
import { UserProfileLabel } from '../UserProfileIcon/UserProfileLabel/UserProfileLabel'
import { UserProfileList } from '../UserProfileList/UserProfileList'

interface Props {
    className?: ClassValue
    users?: User[]
    action?: (userId: number) => void
}

interface User {
    id: number
    fullName?: string
    uri?: string | null
}

export class CappedProfileList extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('CappedProfileList')
    public render() {
        const { className } = this.props

        return (
            <UserProfileList className={this.bem.getClassName(className)}>
                {this.renderUserProfileIcons()}
            </UserProfileList>
        )
    }

    private renderUserProfileIcons() {
        const { users, action } = this.props

        if (!users || !users.length) {
            return null
        }

        const maxUserIcons = 3

        if (users && users.length > maxUserIcons) {
            const initialAmountOfUsers = users && users.slice(0, maxUserIcons - 1)
            const remainingUsers = users && users.slice(maxUserIcons - 1)
            const remainingUserNames = remainingUsers.map(user => user.fullName)

            return (
                <>
                    {initialAmountOfUsers.map((user, index) => (
                        <UserProfileIcon
                            small={true}
                            showTooltip={false}
                            key={`${index}-${user.fullName}`}
                            fullName={user.fullName as string}
                            action={() => action && action(user.id)}
                        />
                    ))}
                    <UserProfileLabel small={true} content={remainingUserNames as string[]} />
                </>
            )
        }

        return users.map(user => (
            <UserProfileIcon
                key={user.fullName}
                small={true}
                showTooltip={false}
                fullName={user.fullName as string}
                action={() => action && action(user.id)}
            />
        ))
    }
}
