import './LogOutButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { userClient, localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
}

const bem = new BEM('LogOutButton')

export const LogOutButton: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { className } = props
    return (
        <Button
            icon={IconType.logOut}
            type={ButtonType.noStyling}
            className={bem.getClassName(className)}
            onClick={() => userClient.logout()}
        >
            {localize.translate(t => t.User.CurrentUser.SignOut)}
        </Button>
    )
}
