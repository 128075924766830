import './ViewRisksInControlSidebar.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { LinkedRiskCard } from '../LinkedCompliance/LinkedRiskCard'
import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { RiskControlType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    linkedRisks: RiskControlType[]
    onClickRisk: (riskId: number) => void
}

export class ViewRisksInControlSidebar extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ViewRisksInControlSidebar')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.ViewRisksInControlSidebar)

    public render() {
        const { linkedRisks, className, onClickRisk } = this.props

        if (!linkedRisks.length) {
            return <Paragraph>{this.loc(t => t.noLinkedRisks)}</Paragraph>
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {linkedRisks.map((linkedRisk, index) => {
                    if (!linkedRisk.risk) {
                        return null
                    }

                    return (
                        <LinkedRiskCard
                            cardOptions={{
                                name: linkedRisk.risk.name,
                                linkedDescription: linkedRisk.description || '',
                                severity: linkedRisk.risk.severity || linkedRisk.risk.severityBruto || undefined,
                            }}
                            key={`LinkedRiskCard-${index}`}
                            onClick={() => {
                                onClickRisk(linkedRisk.risk!.id)
                            }}
                        />
                    )
                })}
            </div>
        )
    }
}
