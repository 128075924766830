import './BulkAlertActionContainer.scss'
import React from 'react'
import { ArchiveAlertMutation, ArchiveInboxNewsItemMutationFN } from './ArchiveAlert/ArchiveAlertMutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize, notification, permissions } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { SelectionBox } from '~/components/Core/Feedback/SelectionBox/SelectionBox'
import { EditInboxItemImpactActionButton } from './EditInboxItemImpactLevel/EditInboxItemImpactActionButton'
import { BEM } from '~/services/BEMService'

interface Props {
    alertIds: number[]
    onClearSelection: () => void
}

interface State {
    archivedReason: string | null
}

export class BulkAlertActionContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        archivedReason: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.AlertsView.ArchiveAlertModal)
    private bem = new BEM('BulkAlertActionContainer')

    public render() {
        const { alertIds, onClearSelection } = this.props
        const doesNotHavePermission = !permissions.canArchiveInboxItems(this.context.activeDepartmentId)

        if (doesNotHavePermission) {
            return null
        }

        return (
            <div className={this.bem.getClassName()}>
                <ArchiveAlertMutation>
                    {(mutate, { loading }) => {
                        return (
                            <ConfirmModal
                                title={this.loc(t => t.modalTitle)}
                                confirmButtonLabel={localize.translate(t => t.Generic.archive)}
                                loading={loading}
                                renderContent={() => (
                                    <Column>
                                        <Paragraph>
                                            {this.loc(t => t.archiveMessagePart1)}
                                            <strong>{alertIds.length}</strong>
                                            {this.loc(t => t.archiveMultipleMessagePart2)}
                                        </Paragraph>
                                        <Field label={this.loc(t => t.reasonInputLabel)} compact={true}>
                                            <Input
                                                type="textarea"
                                                placeholder={this.loc(t => t.reasonInputPlaceholder)}
                                                name="reason"
                                                onChange={archivedReason => this.setState({ archivedReason })}
                                            />
                                        </Field>
                                    </Column>
                                )}
                                onConfirm={close => this.handleSubmit(close, mutate)}
                            >
                                {openModal => (
                                    <SelectionBox
                                        selectedAmount={alertIds.length}
                                        onClose={onClearSelection}
                                        className={this.bem.getElement('selection-box')}
                                        buttons={[
                                            {
                                                icon: IconType.archive,
                                                label: localize.translate(t => t.Generic.archive),
                                                onClick: openModal,
                                            },
                                            <EditInboxItemImpactActionButton
                                                alertIds={alertIds}
                                                popperOptions={{
                                                    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
                                                }}
                                            />,
                                        ]}
                                        isOpen={alertIds.length > 0}
                                    />
                                )}
                            </ConfirmModal>
                        )
                    }}
                </ArchiveAlertMutation>
            </div>
        )
    }

    private async handleSubmit(close: () => void, mutate: ArchiveInboxNewsItemMutationFN) {
        const { archivedReason } = this.state
        const { alertIds: toBeArchivedIds, onClearSelection } = this.props

        const response = await mutate({
            variables: {
                alertIds: toBeArchivedIds,
                departmentId: this.context.activeDepartmentId,
                customerSlug: this.context.customer.slug,
                archivedReason,
            },
        })

        if (response && response.data && response.data.archiveAlertsOnInbox) {
            notification.success(localize.translate(t => t.Generic.successfullyArchived))
            close()
            onClearSelection()
        }
    }
}
