import './EditRiskModalContainer.scss'

import React from 'react'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RiskStatus } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { SelectedControl } from '../AddControlToRiskSidebar'
import { EditRiskGeneralFormFields } from './EditRiskGeneralFormFields'
import { Risk, RiskGraphSettings, RiskValues } from './EditRiskModal'
import { EditRiskModalHeaderAction } from './EditRiskModalHeaderAction'
import { EditRiskSidebarContainer } from './EditRiskSidebarContainer'
import { EditRiskValueField, RiskValueKey } from './EditRiskValueField'

interface Props {
    risk: Risk
    riskGraphSettings: RiskGraphSettings
    isNewRisk?: boolean
    assessmentId: number
    assessmentSectionId: number
    isRiskAccepted: boolean
    defaultRiskValues: RiskValues
    currentRiskValues: RiskValues
    riskStatus: RiskStatus | null
    showDistinctBrutoRiskValues: boolean
    isForBruto?: boolean
    requestClose: () => void
    actions: (requestClose?: (() => void) | undefined) => JSX.Element
    onChangeSelectedControls: (selectedControls: SelectedControl[]) => void
    onChangeIsRiskAccepted: (isRiskAccepted: boolean) => void
    onGeneralFieldInputChange: (value: string | null, name: string) => void
    onRiskTypeChange: (option: SelectOption[]) => void
    onRiskValueChange: (key: RiskValueKey, value: number) => void
}

export class EditRiskModalContainer extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('EditRiskModalContainer', () => ({
        isForBruto: this.props.isForBruto,
    }))

    public render() {
        const {
            requestClose,
            risk,
            isRiskAccepted,
            defaultRiskValues,
            riskGraphSettings,
            riskStatus,
            showDistinctBrutoRiskValues,
            isForBruto,
            onGeneralFieldInputChange,
            onRiskTypeChange,
            onRiskValueChange,
            actions,
        } = this.props

        return (
            <DrawerModal
                requestClose={requestClose}
                title={risk.name}
                actions={actions}
                contentHeader={<EditRiskModalHeaderAction risk={risk} />}
                renderSidebar={this.renderSidebar()}
                className={this.bem.getClassName()}
            >
                <EditRiskGeneralFormFields
                    risk={risk}
                    handleOnInputChange={onGeneralFieldInputChange}
                    handleOnRiskTypeChange={onRiskTypeChange}
                />
                <EditRiskValueField
                    showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                    isRiskAccepted={isRiskAccepted}
                    defaultRiskValues={defaultRiskValues}
                    risk={risk}
                    riskGraphSettings={riskGraphSettings}
                    onRiskValueChange={onRiskValueChange}
                    riskStatus={riskStatus}
                    isForBruto={isForBruto}
                />
            </DrawerModal>
        )
    }

    private renderSidebar() {
        const {
            isForBruto,
            showDistinctBrutoRiskValues,
            isNewRisk,
            risk,
            assessmentId,
            assessmentSectionId,
            riskStatus,
            currentRiskValues,
            onChangeIsRiskAccepted,
            onChangeSelectedControls,
        } = this.props

        if (isForBruto && showDistinctBrutoRiskValues) {
            return null
        }

        return (
            <EditRiskSidebarContainer
                isNewRisk={isNewRisk}
                defaultRisk={risk}
                assessmentId={assessmentId}
                assessmentSectionId={assessmentSectionId}
                showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                riskStatus={riskStatus}
                currentRiskValues={currentRiskValues}
                onChangeSelectedControls={onChangeSelectedControls}
                onChangeIsRiskAccepted={onChangeIsRiskAccepted}
            />
        )
    }
}
