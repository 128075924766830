import './TaskMetaData.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title: string
}

interface State {}

export class TaskMetaData extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('TaskMetaData')

    public render() {
        const { children, title, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <p className={this.bem.getElement('title')}>{title}</p>
                {children && <div className={this.bem.getElement('content')}>{children}</div>}
            </div>
        )
    }
}
