import './AssessmentsEditView.scss'
import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import gql from 'graphql-tag'
import { Query, MutationFn } from 'react-apollo'

import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Section } from '~/components/Core/Layout/Section'
import { Field } from '~/components/Core/DataEntry/Form/Field'

import { localize, notification, permissions } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { GQLMutation } from '~/graphql/Mutation'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { Column } from '~/components/Core/Layout/Column'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { InsetCard } from '~/components/Domain/Compliance/Norms/InsetCard'
import { AssessmentLinkedItems } from '~/components/Domain/Compliance/Assessments/AssessmentLinkedItems'
import { EmployeePermissionEnum } from '~/generated/graphql'
import { breadcrumbs } from '~/views/breadcrumbs'
import {
    AssessmentDetailVariables,
    GET_ASSESSMENT_DETAIL,
    AssessmentDetailResponse,
    AssessmentDetail,
} from './AssessmentsDetailView'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { BEM } from '~/services/BEMService'
import { RadioGroup } from '~/components/Core/DataEntry/Form/RadioGroup'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

const EDIT_ASSESSMENT = gql`
    mutation editAssessment($fields: EditAssessmentFieldsType!, $assessmentId: Int!) {
        editAssessment(fields: $fields, assessmentId: $assessmentId) {
            id
            name
            description
            useDistinctBrutoRiskValues
        }
    }
`

interface EditAssessmentVariables {
    assessmentId: number
    fields: {
        name: string
        description: string
        useDistinctBrutoRiskValues: boolean
    }
}

interface EditAssessmentResponse {
    editAssessment: {
        id: number
        name: string
        description: string
        useDistinctBrutoRiskValues: boolean
    }
}

const DELETE_ASSESSMENT_MUTATION = gql`
    mutation deleteAssessment($assessmentId: Int!) {
        deleteAssessment(assessmentId: $assessmentId)
    }
`

interface DeleteAssessmentMutationResponse {
    deleteAssessment: boolean
}

interface DeleteAssessmentMutationVariables {
    assessmentId: number
}

class AssessmentsEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('AssessmentsEditView')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.EditView)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)
    private formRef = React.createRef<Form>()
    private shouldShowWarningModal = false

    public render() {
        const id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : undefined

        if (!id) {
            return null
        }

        return (
            <GQLMutation<EditAssessmentResponse, EditAssessmentVariables> mutation={EDIT_ASSESSMENT}>
                {(mutate, { loading: mutationLoading }) => (
                    <Query<AssessmentDetailResponse, AssessmentDetailVariables>
                        query={GET_ASSESSMENT_DETAIL}
                        variables={{ id }}
                    >
                        {({ data, loading }) => {
                            if (loading) {
                                return <Spinner delayed={true} />
                            }

                            if (!data || !data.assessment) {
                                return <NoResults />
                            }

                            const { name, description, useDistinctBrutoRiskValues } = data.assessment

                            return (
                                <>
                                    <Page className={this.bem.getClassName()}>
                                        <Form
                                            ref={this.formRef}
                                            onSubmit={formState => this.handleSubmit(mutate, formState)}
                                            defaultState={{ name, description, useDistinctBrutoRiskValues }}
                                        >
                                            <PageHeader
                                                title={data.assessment.name}
                                                actionComponent={this.renderAction(mutationLoading)}
                                                breadCrumbs={[
                                                    breadcrumbs.customer(this.context.customer.slug).risks.index,
                                                    breadcrumbs.customer(this.context.customer.slug).risks.assessments
                                                        .index,
                                                    breadcrumbs
                                                        .customer(this.context.customer.slug)
                                                        .risks.assessments.view(data.assessment.name, id),
                                                ]}
                                            />
                                            <Section title={localize.translate(t => t.Generic.general)}>
                                                {this.renderEditFormFields(data.assessment)}
                                                {this.renderDeleteAssessmentMutation(id, name, data)}
                                            </Section>
                                        </Form>
                                    </Page>
                                </>
                            )
                        }}
                    </Query>
                )}
            </GQLMutation>
        )
    }

    private renderAction(loading: boolean) {
        const { id } = this.props.match.params

        return (
            <Row alignRight={true}>
                <Button
                    to={routes.customer(this.context.customer.slug).risks.assessments.properties(id)}
                    type={ButtonType.tertiary}
                >
                    {localize.translate(t => t.Generic.cancel)}
                </Button>
                <ConfirmModal
                    onConfirm={() => this.formRef.current?.triggerSubmit()}
                    title={this.loc(t => t.useDistinctWarningModal.modalTitle)}
                    message={this.loc(t => t.useDistinctWarningModal.warningText)}
                    loading={loading}
                    confirmButtonLabel={localize.translate(t => t.Generic.save)}
                >
                    {openModal => (
                        <Button
                            loading={loading}
                            onClick={() => {
                                if (this.shouldShowWarningModal) {
                                    return openModal()
                                }

                                return this.formRef.current?.triggerSubmit()
                            }}
                        >
                            {localize.translate(t => t.Generic.save)}
                        </Button>
                    )}
                </ConfirmModal>
            </Row>
        )
    }

    private renderEditFormFields(assessment: AssessmentDetail) {
        const { name, description, useDistinctBrutoRiskValues, risks } = assessment
        const shouldOpenWarningModalIfSelectedFalse =
            useDistinctBrutoRiskValues && risks?.some(({ severityBruto }) => severityBruto !== undefined)

        return (
            <Column smallSpacing={true}>
                <Field label={localize.translate(t => t.Generic.title)} forInput={'name'} hasSmallLabelWidth={true}>
                    <Form.Input name={'name'} defaultValue={name} type={'text'} />
                </Field>
                <Field
                    label={localize.translate(t => t.Generic.description)}
                    forInput={'description'}
                    hasSmallLabelWidth={true}
                >
                    <Form.Input name={'description'} defaultValue={description} type={'textarea'} />
                </Field>
                <Field forInput={'useDistinctBrutoRiskValues'} label={this.loc(t => t.label)} hasSmallLabelWidth={true}>
                    <RadioGroup>
                        <Form.Radio
                            name={'useDistinctBrutoRiskValues'}
                            label={this.loc(t => t.distinguish)}
                            defaultChecked={useDistinctBrutoRiskValues}
                            value={true}
                            onChange={() => this.handleFormRadioSelect(true)}
                        />
                        <Form.Radio
                            name={'useDistinctBrutoRiskValues'}
                            label={this.loc(t => t.doNotDistinguish)}
                            defaultChecked={!useDistinctBrutoRiskValues}
                            value={false}
                            onChange={() => this.handleFormRadioSelect(false, shouldOpenWarningModalIfSelectedFalse)}
                        />
                    </RadioGroup>
                </Field>
            </Column>
        )
    }

    private handleFormRadioSelect(checked: boolean, shouldOpenWarningModalIfSelectedFalse?: boolean) {
        if (shouldOpenWarningModalIfSelectedFalse && !checked) {
            this.shouldShowWarningModal = true
            return
        }

        this.shouldShowWarningModal = false
    }

    private async handleSubmit(mutate: MutationFn<EditAssessmentResponse, EditAssessmentVariables>, formState: any) {
        const { id } = this.props.match.params
        const { name, description, useDistinctBrutoRiskValues } = formState

        const response = await mutate({
            variables: {
                assessmentId: parseInt(id!, 10),
                fields: {
                    name,
                    description,
                    useDistinctBrutoRiskValues,
                },
            },
        })
        if (response && response.data && response.data.editAssessment) {
            this.props.history.push(
                routes.customer(this.context.customer.slug).risks.assessments.view(response.data.editAssessment.id)
            )
        }
    }

    private renderDeleteAssessmentMutation(id: number, name: string, data: AssessmentDetailResponse) {
        if (!permissions.hasPermission(EmployeePermissionEnum.canDeleteCompliance)) {
            return null
        }

        return (
            <GQLMutation<DeleteAssessmentMutationResponse, DeleteAssessmentMutationVariables>
                mutation={DELETE_ASSESSMENT_MUTATION}
                variables={{ assessmentId: id }}
            >
                {(mutate, { loading }) => (
                    <ConfirmModal
                        onConfirm={async closeModal => {
                            const response = await mutate()
                            if (response && response.data && response.data.deleteAssessment) {
                                notification.success(localize.translate(t => t.Generic.successfullyDeleted))
                                this.props.history.push(
                                    routes.customer(this.context.customer.slug).risks.assessments.index
                                )
                            }
                        }}
                        loading={loading}
                        title={this.loc(t => t.deleteModalTitle)}
                        confirmButtonLabel={'Definitief verwijderen'}
                        renderContent={() => (
                            <Column bigSpacing={true}>
                                <ErrorMessage path={'deleteControl'} />
                                <Paragraph>
                                    {this.loc(t => t.deleteModalMessage, {
                                        name: name,
                                    })}
                                </Paragraph>
                                <InsetCard>
                                    <AssessmentLinkedItems
                                        assessmentId={data.assessment.id}
                                        renderEmptyState={() => (
                                            <Paragraph>{this.loc(t => t.deleteMessageEmptyState)}</Paragraph>
                                        )}
                                    />
                                </InsetCard>
                            </Column>
                        )}
                    >
                        {openModal => (
                            <Button
                                type={ButtonType.secondary}
                                className={this.bem.getElement('delete-button')}
                                danger={true}
                                onClick={openModal}
                            >
                                {this.genericLoc(t => t.delete)}
                            </Button>
                        )}
                    </ConfirmModal>
                )}
            </GQLMutation>
        )
    }
}

export const AssessmentsEditView = withRouter(AssessmentsEditViewComponent)
