import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    CreateMonitoringReportDocument,
    CreateMonitoringReportMutation,
    CreateMonitoringReportMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

interface Props {
    children: (mutation: CreateMonitoringReportMutationFN, options: MutationResult) => JSX.Element
}

export type CreateMonitoringReportMutationFN = MutationFn<
    CreateMonitoringReportMutation,
    CreateMonitoringReportMutationVariables
>

export const MonitoringReportCreateMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<CreateMonitoringReportMutation, CreateMonitoringReportMutationVariables>
        mutation={CreateMonitoringReportDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
