import './CreateDepartmentModal.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    EmployeeType,
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables,
    AllEmployeesQueryVariables,
    AllEmployeesDocument,
    AllEmployeesQuery,
    ProfileType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { MutationFn, Query } from 'react-apollo'
import { isEmpty } from 'lodash-es'
import { CreateDepartment } from '../DepartmentMutations/CreateDepartment'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { EmployeeStatus } from './DepartmentEmployeesEditModal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props {
    closeModal: () => void
}

export class CreateDepartmentModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private formRef = React.createRef<Form>()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview.createModal)
    private bem = new BEM('CreateDepartmentModal')

    public render() {
        return (
            <CreateDepartment>
                {(mutate, { loading: mutationLoading }) => (
                    <Query<AllEmployeesQuery, AllEmployeesQueryVariables>
                        query={AllEmployeesDocument}
                        variables={{ customerId: this.context.customer.id }}
                    >
                        {({ data, loading: employeesQueryLoading }) => {
                            if (employeesQueryLoading) {
                                return <Spinner delayed={true} />
                            }
                            if (!data) {
                                return null
                            }

                            const allAssignableEmployees: EmployeeType[] = []
                            data.allEmployees?.forEach(employee => {
                                if (employee) {
                                    allAssignableEmployees.push(employee as EmployeeType)
                                }
                            })

                            return this.renderModal(mutationLoading, mutate, allAssignableEmployees)
                        }}
                    </Query>
                )}
            </CreateDepartment>
        )
    }

    private renderModal(
        loading: boolean,
        mutate: MutationFn<CreateDepartmentMutation, CreateDepartmentMutationVariables>,
        allAssignableEmployees: EmployeeType[]
    ) {
        const { closeModal } = this.props

        return (
            <Modal
                className={this.bem.getClassName()}
                requestClose={closeModal}
                title={this.loc(t => t.title)}
                onAction={() => this.formRef.current?.triggerSubmit()}
                confirmButtonLabel={localize.translate(t => t.Generic.save)}
                loading={loading}
            >
                {this.renderForm(mutate, allAssignableEmployees)}
            </Modal>
        )
    }

    private renderForm(
        mutate: MutationFn<CreateDepartmentMutation, CreateDepartmentMutationVariables>,
        allAssignableEmployees: EmployeeType[]
    ) {
        return (
            <Form ref={this.formRef} onSubmit={state => this.handleSubmit(state, mutate)}>
                <Row spaceBetween={true} className={this.bem.getElement('modal-content-container')}>
                    {this.renderDepartmentNameField()}
                    {this.renderEmployeeField(allAssignableEmployees)}
                    {this.renderConsultationAlertsField()}
                </Row>
            </Form>
        )
    }

    private async handleSubmit(
        state: FormState,
        mutate: MutationFn<CreateDepartmentMutation, CreateDepartmentMutationVariables>
    ) {
        if (isEmpty(state)) {
            return
        }

        const employees = this.getEmployeeStatuses(state)
        const { name, consultationAlerts } = state
        const { closeModal } = this.props

        const response = await mutate({
            variables: {
                customerId: this.context.customer.id,
                customerSlug: this.context.customer.slug,
                fields: {
                    name,
                    employees,
                    receiveAlertsForConsultationsEnabled: !!consultationAlerts,
                },
            },
        })

        if (response && response.data?.createDepartment) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            closeModal()
        }
    }

    private renderDepartmentNameField() {
        return (
            <>
                <Paragraph className={this.bem.getElement('name-field')} bold={true}>
                    {this.loc(t => t.name)}
                </Paragraph>
                <Field forInput={'name'}>
                    <Form.Input name={'name'} />
                </Field>
            </>
        )
    }

    private renderEmployeeField(allAssignableEmployees: EmployeeType[]) {
        return (
            <>
                <Paragraph className={this.bem.getElement('table-title')} bold={true}>
                    {this.loc(t => t.users)}
                </Paragraph>
                <Row className={this.bem.getElement('table-header-row')}>
                    <Paragraph className={this.bem.getElement('name')} bold={true}>
                        {this.loc(t => t.name)}
                    </Paragraph>
                    <Row evenSpace={true} smallSpacing={true}>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.active)}
                        </Paragraph>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.passive)}
                        </Paragraph>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.unauthorized)}
                        </Paragraph>
                    </Row>
                </Row>
                <Column className={this.bem.getElement('table-container')}>
                    <Column className={this.bem.getElement('table-row-container')}>
                        {allAssignableEmployees.map(({ id, user: { profile } }) => this.renderTableRow(id, profile))}
                    </Column>
                </Column>
            </>
        )
    }

    private renderConsultationAlertsField() {
        return (
            <>
                <Paragraph className={this.bem.getElement('name-field')} bold={true}>
                    {this.loc(t => t.consultationAlerts)}
                </Paragraph>
                <Field forInput={'consultationAlerts'}>
                    <Row>
                        <Form.Toggle name={'consultationAlerts'} defaultChecked={true} />
                        <Paragraph>{this.loc(t => t.consultationAlertsInfo)}</Paragraph>
                    </Row>
                </Field>
            </>
        )
    }

    private getEmployeeStatuses(state: FormState) {
        const employees = Object.keys(state).filter(key => key !== 'name')

        if (!employees.length) {
            return {
                activeEmployeeIds: [],
                passiveEmployeeIds: [],
            }
        }

        let activeEmployeeIds: number[] = []
        let passiveEmployeeIds: number[] = []

        Object.entries(state).forEach(entry => {
            const key = entry[0]
            const val = entry[1]
            const employeeId = parseInt(key.split('-')[1], 10)

            if (val === EmployeeStatus.active) {
                activeEmployeeIds.push(employeeId)
                passiveEmployeeIds = passiveEmployeeIds.filter(id => id !== employeeId)
                return
            }

            if (val === EmployeeStatus.passive) {
                passiveEmployeeIds.push(employeeId)
                activeEmployeeIds = activeEmployeeIds.filter(id => id !== employeeId)
                return
            }

            if (val === EmployeeStatus.unauthorized) {
                activeEmployeeIds = activeEmployeeIds.filter(id => id !== employeeId)
                passiveEmployeeIds = passiveEmployeeIds.filter(id => id !== employeeId)
            }
        })

        return {
            activeEmployeeIds: Array.from(new Set(activeEmployeeIds)),
            passiveEmployeeIds: Array.from(new Set(passiveEmployeeIds)),
        }
    }

    private renderTableRow(id: number, profile: ProfileType) {
        return (
            <Row key={id} className={this.bem.getElement('table-row')}>
                <Paragraph>{profile.fullName}</Paragraph>
                <Row evenSpace={true} smallSpacing={true}>
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.active}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.passive}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.unauthorized}
                        defaultChecked={true}
                        className={this.bem.getElement('radio')}
                    />
                </Row>
            </Row>
        )
    }
}
