import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Control } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { ControlDetailDocuments } from '~/components/Domain/Compliance/Controls/ControlDetailDocuments'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { getIconTypeForControlType } from '~/utils/controls'
import { ControlDetailGeneralFields } from '~/views/Customer/Compliance/Controls/ControlDetailGeneralFields'
import { ControlDetailLinkedControls } from './ControlDetailLinkedControls'
import { breadcrumbs } from '~/views/breadcrumbs'
import ControlDetailActionButtons from './ControlDetailActionButtons'
import { SidebarTaskWidgetContainer } from '~/components/Domain/Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { Page } from '~/components/Core/Layout/Page'
import { ControlMonitoringReportsTable } from '../ControlMonitoringReportsTable'
import { ControlDetailMeta } from './ControlDetailMeta'
import { Row } from '~/components/Core/Layout/Row'
import { ControlRisksTable } from './ControlRisksTable'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { SelectControlTopicsModal } from '../SelectControlTopicsModal/SelectControlTopicsModal'
import { ControlTopicsTable } from '../ControlTopicsTable/ControlTopicsTable'
import { permissions } from '~/bootstrap'

interface Props {
    control: Control
    refetch: () => void
    isNewlyCreated?: boolean
    clearLocationState: () => void // this is needed to prevent the modal from opening on every page load
}

interface State {}

export class ControlDetail extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private controlTopicsRef = React.createRef<ControlTopicsTable>()

    public render() {
        const { control, isNewlyCreated, refetch, clearLocationState } = this.props

        return (
            <Page>
                <PageHeader
                    subtle={!!control.archivedAt}
                    breadCrumbs={[
                        breadcrumbs.customer(this.context.customer.slug).compliance.index,
                        breadcrumbs.customer(this.context.customer.slug).compliance.controls.index,
                    ]}
                    title={control.name}
                    icon={getIconTypeForControlType(control.type)}
                    actionButtons={<ControlDetailActionButtons control={control} refetch={refetch} />}
                />
                <PageDetail
                    spaceBetween
                    renderSidebar={() => <SidebarTaskWidgetContainer linkedItem={control} refetch={refetch} />}
                >
                    <Column extraBigSpacing bigBottomSpacing>
                        {!control.archivedAt && <ControlDetailLinkedControls control={control} />}
                        <Column>
                            <ControlDetailMeta control={control} />
                            <ControlDetailGeneralFields
                                control={control}
                                activeDepartmentId={this.context.activeDepartmentId}
                            />
                        </Column>
                    </Column>
                </PageDetail>
                <Column extraBigSpacing>
                    {!control.archivedAt && (
                        <>
                            <ControlTopicsTable ref={this.controlTopicsRef} control={control} />
                            <Row evenSpace flexStart>
                                <ControlRisksTable controlId={control.id} />
                                {permissions.canViewMonitoringReports() && (
                                    <ControlMonitoringReportsTable controlId={control.id} />
                                )}
                            </Row>
                        </>
                    )}
                    <ControlDetailDocuments control={control} />
                </Column>
                <ModalManager
                    openOnMount={isNewlyCreated}
                    renderModal={closeModal => (
                        <SelectControlTopicsModal
                            controlId={control.id}
                            requestClose={() => {
                                clearLocationState()
                                closeModal()
                            }}
                            onSubmit={() => this.controlTopicsRef.current?.refetch?.()}
                        />
                    )}
                />
            </Page>
        )
    }
}
