import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CreateTaskActionButton } from '~/components/Domain/Task/CreateTaskActionButton'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlType, ControlTypeType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { getLinkableTypeForType } from '~/utils/controls'
import { routes } from '~/views/routes'
import { LinkControlModal } from '../LinkNestedControl/LinkControlModal'
import { ArchiveControlModal } from '../ArchiveControlModal'
import { UnarchiveControl, UnarchiveControlMutationFN } from '../Mutations/UnarchiveControl'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps {
    control: Control
    refetch: () => void
}

type Control = Pick<ControlType, 'id' | '__typename' | 'name' | 'type' | 'archivedAt'>

class ControlDetailActionButtons extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls)
    private linkableTypes = getLinkableTypeForType(this.props.control.type)

    public render() {
        const { control } = this.props

        if (!permissions.canEditControls(this.context.activeDepartmentId)) {
            return null
        }

        return (
            <Row>
                {!control.archivedAt && (
                    <>
                        {this.renderLinkPolicyButton()}
                        {this.renderLinkProcedureButton()}
                        {this.renderLinkControlButton()}
                    </>
                )}
                <CreateTaskActionButton linkedItem={control as LinkedTaskItem} />
                {!control.archivedAt && this.renderEditButton()}
                {control.archivedAt ? this.renderUnarchiveButton() : this.renderArchiveButton()}
            </Row>
        )
    }

    private renderLinkPolicyButton() {
        const linkableTypes = [ControlTypeType.policy]
        const label = this.loc(t => t.actions.linkControlPolicy)

        return this.renderLinkButton(linkableTypes, IconType.policy, label, 'Policy')
    }

    private renderLinkProcedureButton() {
        const linkableTypes = [ControlTypeType.procedure]
        const label = this.loc(t => t.actions.linkControlProcedure)

        return this.renderLinkButton(linkableTypes, IconType.procedure, label, 'Procedure')
    }

    private renderLinkControlButton() {
        const linkableTypes = [ControlTypeType.control, ControlTypeType.keyControl]
        const label = this.loc(t => t.actions.linkControlControl)

        return this.renderLinkButton(linkableTypes, IconType.keyControl, label, 'Control')
    }

    private renderLinkButton(
        linkableTypes: ControlTypeType[],
        icon: IconType,
        label: string,
        translationNameSpace: 'Policy' | 'Procedure' | 'Control'
    ) {
        const { control, refetch } = this.props

        if (!linkableTypes.some(t => this.linkableTypes.includes(t))) {
            return
        }

        return (
            <ModalManager
                renderModal={closeModal => (
                    <LinkControlModal
                        control={control}
                        requestClose={closeModal}
                        translationNameSpace={translationNameSpace}
                        linkableControlTypes={linkableTypes}
                        onLinkedControlIdsChanged={refetch}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        icon={icon}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {label}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderEditButton() {
        const { id } = this.props.control
        const editPage = routes.customer(this.context.customer.slug).compliance.controls.edit(id)

        return (
            <ActionButton
                icon={IconType.edit}
                onClick={() => this.props.history.push(editPage)}
                tooltipContent={
                    <Paragraph small emphasis>
                        {localize.translate(t => t.Generic.edit)}
                    </Paragraph>
                }
            />
        )
    }

    private renderArchiveButton() {
        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveControlModal control={this.props.control} requestClose={closeModal} />
                )}
                render={openModal => (
                    <ActionButton
                        icon={IconType.archive}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.archive)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private renderUnarchiveButton() {
        return (
            <UnarchiveControl>
                {(mutate, { loading }) => (
                    <ActionButton
                        icon={IconType.unarchive}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.dearchive)}
                            </Paragraph>
                        }
                        loading={loading}
                        onClick={this.handleUnarchive(mutate)}
                    />
                )}
            </UnarchiveControl>
        )
    }

    private handleUnarchive = (mutate: UnarchiveControlMutationFN) => async () => {
        const { id } = this.props.control

        const response = await mutate({ variables: { id } })

        if (response && response.data && response.data.unarchiveControl) {
            notification.success(localize.translate(t => t.Generic.successfullyUnarchived))
        }
    }
}

export default withRouter(ControlDetailActionButtons)
