import './CustomerFeedTypeSelectField.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Column } from '~/components/Core/Layout/Column'
import { CustomerSubscribedToFeedTypeTypesEnumType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { customerFeedSettingKeys, CustomerFeedSettings } from './customerFeedTypeSettingHelpers'

interface Props {
    defaultType?: CustomerSubscribedToFeedTypeTypesEnumType
    defaultSettings?: CustomerFeedSettings
    disabled?: boolean
}

interface State {
    type?: CustomerSubscribedToFeedTypeTypesEnumType
}

export class CustomerFeedTypeSelectField extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        type: this.props.defaultType,
    }

    private bem = new BEM('CustomerFeedTypeSelectField')
    private feedTypesLoc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.feedTypes)
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.CustomerFeedTypeSelect)

    private options = [
        {
            label: this.feedTypesLoc(t => t.AegonLawChanges),
            value: CustomerSubscribedToFeedTypeTypesEnumType.aegonLawChanges,
        },
        {
            label: this.feedTypesLoc(t => t.AegonSignaling),
            value: CustomerSubscribedToFeedTypeTypesEnumType.aegonSignaling,
        },
        {
            label: this.feedTypesLoc(t => t.APGRadarAlerts),
            value: CustomerSubscribedToFeedTypeTypesEnumType.apgRadarAlerts,
        },
        {
            label: this.feedTypesLoc(t => t.APGAllAlerts),
            value: CustomerSubscribedToFeedTypeTypesEnumType.apgAllAlerts,
        },
    ]

    public render() {
        const { disabled } = this.props

        return (
            <div className={this.bem.getClassName()}>
                <Field label={this.loc(t => t.typeFieldName)} forInput="type">
                    <Form.Select
                        name="type"
                        options={this.options}
                        placeholder={this.loc(t => t.typeFieldPlaceholder)}
                        defaultValue={this.getDefaultTypeValue()}
                        onChange={this.handleTypeChange}
                        disabled={disabled}
                    />
                </Field>
                {this.renderSettings()}
            </div>
        )
    }

    private getDefaultTypeValue() {
        const { defaultType } = this.props
        if (!defaultType) {
            return
        }

        const defaultTypeOption = this.options.find(op => op.value === defaultType)

        return defaultTypeOption ? [defaultTypeOption] : undefined
    }

    private handleTypeChange = (selectedOption: SelectOption<CustomerSubscribedToFeedTypeTypesEnumType>) => {
        if (this.state.type === selectedOption.value) {
            return
        }

        this.setState({ type: selectedOption.value })
    }

    private renderSettings() {
        const { type } = this.state
        if (!type) {
            return
        }

        const typeKeys = customerFeedSettingKeys[type]
        if (!typeKeys || !typeKeys.length) {
            return
        }

        return (
            <Field
                className={this.bem.getElement('settings-container')}
                label={this.loc(t => t.settingsFieldName)}
                forInput="settings"
            >
                <Column smallSpacing={true}>{typeKeys.map(this.renderSetting)}</Column>
            </Field>
        )
    }

    private renderSetting = (type: string, index: number) => {
        const { defaultSettings, disabled } = this.props
        const defaultValue = defaultSettings ? defaultSettings[type] : undefined

        return (
            <Field
                key={`setting-${index}`}
                className={this.bem.getElement('setting-label')}
                label={this.loc(t => t.settings[type])}
                forInput={type}
            >
                <Form.Input
                    name={type}
                    placeholder={localize.translate(t => t.Generic.value)}
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
            </Field>
        )
    }
}
