import React from 'react'
import { Query } from 'react-apollo'

import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { GroupedLawArticlesTable } from './GroupedLawArticlesTable'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicType, TopicWithLawArticlesDocument, TopicWithLawArticlesQuery } from '~/generated/graphql'

interface Props {
    topicId?: number
    onChange?: (abstractLawArticleIds: number[]) => void
}

interface State {
    articleIds: number[]
}

interface Variables {
    topicId?: number
    departmentId: number
}

export class AssessmentArticleSelectionContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        articleIds: [],
    }

    public render() {
        const { topicId } = this.props
        return (
            <Query<TopicWithLawArticlesQuery, Variables>
                query={TopicWithLawArticlesDocument}
                variables={{ topicId: topicId ? topicId : undefined, departmentId: this.context.activeDepartmentId }}
            >
                {({ data, loading: loadingQuery }) => {
                    const topic = data ? data.topic : null

                    const defaultChecked = topic
                        ? topic.linkedLawArticles
                              .filter(article => !article.expired)
                              .map(article => article.abstractLawArticleId)
                        : []

                    return loadingQuery ? (
                        <Spinner />
                    ) : (
                        <Field forInput={'abstractLawArticleIds'}>
                            {topic && (
                                <GroupedLawArticlesTable
                                    topic={topic as TopicType}
                                    selectableCheckboxes={true}
                                    onChange={this.handleArticleSelectChange}
                                    defaultAbstractLawArticleIds={defaultChecked}
                                    filterOutExpired={true}
                                />
                            )}
                        </Field>
                    )
                }}
            </Query>
        )
    }

    private handleArticleSelectChange = (allArticleIds: number[]) => {
        const { onChange } = this.props
        this.setState({ articleIds: allArticleIds }, () => {
            if (onChange) {
                onChange(this.state.articleIds)
            }
        })

        return
    }
}
