import './ThemesOverviewTableContainer.scss'

import React from 'react'
import { CardList } from '~/components/Core/DataDisplay/CardList/CardList'
import { InfiniteScrollOptions } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ListThemesQueryVariables } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { ThemesOverviewItemCard } from './ThemesOverviewItemCard'
import { ThemesOverviewThemeType } from './ThemesOverviewTableQuery'
import { permissions } from '~/bootstrap'

interface Props {
    themesOptions: InfiniteScrollOptions<ThemesOverviewThemeType, ListThemesQueryVariables>
    onPin: (themeId: number) => void
}

export class ThemesOverviewTableContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ThemesOverviewTableContainer')

    public render() {
        const { themesOptions, onPin } = this.props
        const { loading, data } = themesOptions

        if (loading || !data?.nodes.length) {
            return null
        }

        const themes = data.nodes
        const notAllowedToPin = !permissions.isCustomerUser()

        return (
            <div className={this.bem.getClassName()}>
                <CardList className={this.bem.getElement('grid-container')} columns={3}>
                    {themes.map(theme => (
                        <ThemesOverviewItemCard
                            key={theme.id}
                            notAllowedToPin={notAllowedToPin}
                            theme={theme}
                            isPinned={false}
                            togglePinned={() => onPin(theme.id)}
                        />
                    ))}
                </CardList>
            </div>
        )
    }
}
