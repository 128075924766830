import './EmployeeDetailView.scss'

import React from 'react'
import { localize, permissions, userClient } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Guard } from '~/components/Core/Guard/Guard'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DeleteEmployeeButton } from '~/components/Domain/Settings/Employees/DeleteEmployee/DeleteEmployeeButton'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { ChangePasswordModal } from '~/components/Domain/Settings/Employees/ChangePasswordModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { EmployeeDocument, EmployeeType, DepartmentType, EmployeePermissionEnum } from '~/generated/graphql'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { OperationsCheckboxList } from '~/components/Domain/Settings/Operations/OperationsCheckboxList'
import { PermissionCheckboxList } from '~/components/Domain/Settings/Permissions/PermissionsCheckboxList'
import { Attention } from '~/components/Core/Feedback/Attention/Attention'
import { BEM } from '~/services/BEMService'
import { RulerEmployeeWarning } from '~/components/Domain/Settings/Employees/RulerEmployeeWarning'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Column } from '~/components/Core/Layout/Column'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

class EmployeeDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.User)
    private bem = new BEM('EmployeeDetailView')

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<EmployeeType>
                query={EmployeeDocument}
                variables={{ id, customerSlug: this.context.customer.slug }}
            >
                {employee => (
                    <Column extraBigSpacing className={this.bem.getClassName()}>
                        <PageHeader
                            documentTitle={'Gebruiker gegevens'}
                            title={this.getPageTitle(employee)}
                            breadCrumbs={[
                                breadcrumbs.customer(this.context.customer.slug).settings.index,
                                breadcrumbs.customer(this.context.customer.slug).settings.employees.index,
                            ]}
                        />
                        {this.renderFields(employee)}
                        {this.renderActions(employee)}
                    </Column>
                )}
            </PageQuery>
        )
    }

    private getPageTitle(employee: EmployeeType) {
        if (!employee || !employee.user || !employee.user.profile || !employee.user.profile.fullName) {
            return localize.translate(t => t.Customer.Settings.SettingsEmployeeDetailView.myDetails)
        }

        if (!permissions.isCurrentUser(employee.user.id)) {
            return employee.user.profile.fullName
        }

        return localize.translate(t => t.Customer.Settings.SettingsEmployeeDetailView.myDetails)
    }

    private renderFields(employee: EmployeeType) {
        const { user, alertDigestFrequency, departments, role, operations, newTaskNotificationEmail } = employee
        const { myCompletedTasksDigestFrequency, upcomingTasksDigestFrequency, standardTasksDigestFrequency } = employee

        const { profile, email, ssoLoginEnabled, hasActiveMergeRequest, isCustomerConsultant } = user
        const { fullName } = profile

        const permissionsToHide = this.context.customer.editorialAccess
            ? undefined
            : [EmployeePermissionEnum.canMonitorSignaling]

        const defaultOperations = permissions.getAvailableOperations(employee.role, employee.permissions || [])

        return (
            <FieldSet noMarginOnLastChild={true}>
                <Field label={this.loc(t => t.attributes.name)}>
                    <Paragraph>{fullName}</Paragraph>
                </Field>
                <Field label={this.loc(t => t.attributes.email)}>
                    <Paragraph>{email}</Paragraph>
                    {hasActiveMergeRequest && (
                        <Row className={this.bem.getElement('attention')}>
                            <Attention attentionText={this.loc(t => t.pendingMergeWarning)} />
                        </Row>
                    )}
                    {isCustomerConsultant && <RulerEmployeeWarning className={this.bem.getElement('attention')} />}
                </Field>
                <Field label={this.loc(t => t.attributes.roles)}>
                    <Paragraph>{userClient.getReadableRole(role)}</Paragraph>
                </Field>
                <Field label={this.loc(t => t.attributes.newTaskNotificationEmailSetting)}>
                    <Paragraph>
                        {this.loc(t => t.newTaskNotificationEmailSetting[newTaskNotificationEmail ? 'true' : 'false'])}
                    </Paragraph>
                </Field>
                <Field label={this.loc(t => t.attributes.departments)}>
                    <Paragraph>{departments.map(this.renderDepartmentItem)}</Paragraph>
                </Field>
                <Field label={this.loc(t => t.attributes.permissions)}>
                    <PermissionCheckboxList
                        name="permissions"
                        defaultPermissions={employee.permissions || []}
                        disabled={true}
                        hidePermissions={permissionsToHide}
                        noUnderline={true}
                    />
                </Field>
                {!!defaultOperations.length && (
                    <Field
                        tooltip={localize.translate(t => t.User.attributes.operationsTooltip)}
                        label={localize.translate(t => t.User.attributes.operations)}
                    >
                        <OperationsCheckboxList
                            name="operations"
                            defaultOperations={defaultOperations}
                            defaultSelectedOperations={operations || []}
                            disabled={true}
                            noUnderline
                        />
                    </Field>
                )}
                {(this.context.customer.ssoLoginEnabled || this.context.customer.customerGroup?.ssoLoginEnabled) && (
                    <Field label={this.loc(t => t.attributes.ssoLoginEnabled)}>
                        {localize.translate(t => (ssoLoginEnabled ? t.Generic.yes : t.Generic.no))}
                    </Field>
                )}
                <SectionTitle>{this.loc(t => t.attributes.emailFrequency)}</SectionTitle>
                <Field label={this.loc(t => t.attributes.alertDigestFrequency)}>
                    <Paragraph>{this.loc(t => t.digestFrequency[alertDigestFrequency])}</Paragraph>
                </Field>
                <Field
                    tooltip={localize.translate(t => t.User.attributes.standardTasksTooltip)}
                    label={this.loc(t => t.attributes.standardTasksDigestFrequency)}
                >
                    <Paragraph>{this.loc(t => t.digestFrequency[standardTasksDigestFrequency])}</Paragraph>
                </Field>
                <Field
                    tooltip={localize.translate(t => t.User.attributes.upcomingTasksTooltip)}
                    label={this.loc(t => t.attributes.upcomingTasksDigestFrequency)}
                >
                    <Paragraph>{this.loc(t => t.digestFrequency[upcomingTasksDigestFrequency])}</Paragraph>
                </Field>
                <Field
                    tooltip={localize.translate(t => t.User.attributes.myCompletedTasksTooltip)}
                    label={this.loc(t => t.attributes.myCompletedTasksDigestFrequency)}
                >
                    <Paragraph>{this.loc(t => t.digestFrequency[myCompletedTasksDigestFrequency])}</Paragraph>
                </Field>
            </FieldSet>
        )
    }

    private renderDepartmentItem = (department: DepartmentType, i: number) => {
        if (!department || !department.id) {
            return null
        }

        return (
            <React.Fragment key={`DepartmentItem-${department.id}`}>
                {i > 0 && ', '}
                {department.name}
            </React.Fragment>
        )
    }

    private renderActions(employee: EmployeeType) {
        const { match, history } = this.props
        const { id } = match.params
        const { user } = employee

        const hasPermissionToDelete =
            permissions.isConsultantUser() ||
            (permissions.isCustomerAdministrator() && !permissions.isCurrentUser(user.id))
        const onDelete = () => history.push(routes.customer(this.context.customer.slug).settings.employees.index)

        const hasPermissionToEdit =
            permissions.isConsultantUser() || permissions.isCurrentUserOrCustomerAdministrator(user.id)

        return (
            <Row wrap={true} smallSpacing={true}>
                <Guard condition={hasPermissionToDelete}>
                    <DeleteEmployeeButton
                        customerSlug={this.context.customer.slug}
                        onDelete={onDelete}
                        employee={employee}
                    />
                </Guard>
                <Guard condition={permissions.isCurrentUser(user.id)}>
                    <ChangePasswordModal>
                        {openModal => (
                            <Button onClick={openModal} type={ButtonType.secondary}>
                                {this.loc(t => t.changePassword)}
                            </Button>
                        )}
                    </ChangePasswordModal>
                </Guard>
                <Guard condition={hasPermissionToEdit}>
                    <Button to={routes.customer(this.context.customer.slug).settings.employees.edit(id)}>
                        {this.loc(t => t.changeUserDetails)}
                    </Button>
                </Guard>
            </Row>
        )
    }
}

export const EmployeeDetailView = withRouter(EmployeeDetailViewComponent)
