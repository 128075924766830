import './AssessmentAddNormModal.scss'
import React from 'react'

import { ClassValue, BEM } from '~/services/BEMService'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { StepContainer } from '~/components/Core/Layout/StepContainer'
import { AssessmentAddNormThemeSelect } from './AssessmentAddNormThemeSelect'
import { localize, notification } from '~/bootstrap'
import { AssessmentAddNormArticlesAndTopicsSelect } from './AssessmentAddNormArticlesAndTopicsSelect'
import { MainProfile } from '~/components/Chrome/MainHeader/Desktop/MainProfile/MainProfile'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { GroupedNorms } from './Matrix/AssessmentMatrixLayout'
import { MutationFn } from 'react-apollo'
import { ExpandableTopicCardRowChanges } from '~/components/Domain/Compliance/Norms/ExpandableTopicCardRow'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { ASSESSMENT_SECTIONS_QUERY } from './AssessmentSections/AssessmentSectionsInfiniteScrollQuery'

interface Props {
    className?: ClassValue
    onClose?: () => void
    assessmentId: number
    assessmentSectionId: number
    selectedNorms?: GroupedNorms[]
}

interface State {
    themeId?: number
    changes: ExpandableTopicCardRowChanges[]
}

const ADD_NORM_MUTATION = gql`
    mutation editAssessmentSectionTopics($assessmentSectionId: Int!, $fields: EditAssessmentTopicsFieldsType!) {
        editAssessmentSectionTopics(assessmentSectionId: $assessmentSectionId, fields: $fields) {
            id
        }
    }
`

interface EditAssessmentForNormsResponse {
    editAssessmentSectionTopics: {
        id: number
    }
}

interface EditAssessmentForNomrsVariables {
    assessmentSectionId: number
    fields: {
        changedTopics?: {
            topicId: number
            addAllArticles?: boolean
            selectedArticleIds?: number[]
        }[]
    }
}

export class AssessmentAddNormModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        changes: [],
    }

    private bem = new BEM('AssessmentAddNormModal')

    public render() {
        const { onClose, className, assessmentId, selectedNorms } = this.props
        const { themeId } = this.state

        return (
            <DrawerModal
                className={this.bem.getClassName(className)}
                title={
                    <>
                        {localize.translate(t => t.Customer.Compliance.Assessments.AssesmentAddNormModal.title)}
                        <MainProfile compact={true} />
                    </>
                }
                requestClose={onClose}
                hideButtons={true}
            >
                <GQLMutation<EditAssessmentForNormsResponse, EditAssessmentForNomrsVariables>
                    mutation={ADD_NORM_MUTATION}
                    refetchQueries={[
                        {
                            query: ASSESSMENT_SECTIONS_QUERY,
                            variables: {
                                assessmentId,
                                departmentId: this.context.activeDepartmentId,
                            },
                        },
                    ]}
                >
                    {(mutate, { loading }) => (
                        <StepContainer
                            steps={[
                                {
                                    content: nextStep => (
                                        <AssessmentAddNormThemeSelect
                                            nextStep={nextStep}
                                            onCardClick={this.selectTheme}
                                            requestClose={onClose}
                                            selectedNorms={selectedNorms}
                                        />
                                    ),
                                },
                                {
                                    content: (nextStep, prevStep) => (
                                        <AssessmentAddNormArticlesAndTopicsSelect
                                            themeId={themeId!}
                                            prevStep={prevStep}
                                            onSaveLoading={loading}
                                            onChange={this.handleOnChange}
                                            onSubmit={this.handleOnSubmit(mutate)}
                                        />
                                    ),
                                },
                            ]}
                        />
                    )}
                </GQLMutation>
            </DrawerModal>
        )
    }

    private selectTheme = (themeId: number, nextStep: () => void) => {
        this.setState({ themeId: themeId }, () => nextStep())
    }

    private handleOnChange = (changes: ExpandableTopicCardRowChanges) => {
        this.setState(prev => {
            const filterdOut = prev.changes.filter(({ topicId }) => changes.topicId !== topicId)

            return { changes: [...filterdOut, changes] }
        })
    }

    private handleOnSubmit =
        (mutate: MutationFn<EditAssessmentForNormsResponse, EditAssessmentForNomrsVariables>) => async () => {
            const { assessmentSectionId, onClose } = this.props
            const { changes } = this.state

            const response = await mutate({
                variables: {
                    assessmentSectionId,
                    fields: {
                        changedTopics: changes.map(change => ({
                            topicId: change.topicId,
                            addAllArticles: change.allTopicsSelected,
                            selectedArticleIds: change.selectedArticleIds,
                        })),
                    },
                },
            })

            if (response && response.data && response.data.editAssessmentSectionTopics) {
                notification.success(localize.translate(t => t.Generic.successfullyCreated))
                if (onClose) {
                    onClose()
                }
            }
        }
}
