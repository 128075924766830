import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { getTypenameFromLinkedItemType, LinkedTaskItem } from '~/graphql/types/Task'
import { permissions } from '~/bootstrap'
import { ContextTasksModal } from '../TasksByContext/ContextTasksModal'
import { InlineTaskWidget } from './InlineTaskWidget'
import { MinimalLinkedTaskItemStatusQuery } from './MinimalLinkedTaskItemStatusQuery'

interface Props {
    linkedItem: LinkedTaskItem
}

interface State {}

export class InlineTaskWidgetContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { linkedItem } = this.props

        const { linkedItemId, linkedItemType } = getTypenameFromLinkedItemType(linkedItem)

        if (!permissions.canViewPlanning()) {
            return null
        }

        return (
            <MinimalLinkedTaskItemStatusQuery
                departmentId={this.context.activeDepartmentId}
                linkedItemId={linkedItemId!}
                linkedItemType={linkedItemType!}
            >
                {({ data, loading, refetch }) => {
                    const amountOfOpenTasks = data && data.linkedTaskItemStatus ? data.linkedTaskItemStatus.open : 0
                    const amountOfCompletedTasks =
                        data && data.linkedTaskItemStatus ? data.linkedTaskItemStatus.closed : 0
                    const overDue = data && data.linkedTaskItemStatus ? data.linkedTaskItemStatus.overDue : 0

                    return (
                        <ModalManager
                            render={openShowTasksModal => (
                                <InlineTaskWidget
                                    loading={loading}
                                    onContainerClick={openShowTasksModal}
                                    openAmount={amountOfOpenTasks}
                                    closedAmount={amountOfCompletedTasks}
                                    overDueAmount={overDue}
                                />
                            )}
                            renderModal={closeModal => (
                                <ContextTasksModal
                                    amountOfOpenTasks={amountOfOpenTasks}
                                    amountOfCompletedTasks={amountOfCompletedTasks}
                                    linkedItem={linkedItem}
                                    requestClose={closeModal}
                                    onCompleteOrReopenTasks={() => {
                                        refetch()
                                    }}
                                />
                            )}
                        />
                    )
                }}
            </MinimalLinkedTaskItemStatusQuery>
        )
    }
}
