import './FieldSet.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    noMarginOnLastChild?: boolean
    className?: ClassValue
}

interface State {}

export class FieldSet extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('FieldSet', () => ({
        'no-margin-on-last-child': !!this.props.noMarginOnLastChild,
    }))

    public render() {
        const { children, className } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
