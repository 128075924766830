import React from 'react'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SearchOverviewView } from './SearchOverviewView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Routes, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

class SearchViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<SearchOverviewView />} />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const SearchView = withRouter(SearchViewComponent)
