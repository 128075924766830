import React from 'react'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM, ClassValue } from '~/services/BEMService'
import './ChatPlaceholder.scss'
import { Link } from 'react-router-dom'

interface Props {
    className?: ClassValue
}

interface State {}

export class ChatPlaceholder extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ChatPlaceholder')
    public render() {
        const { className } = this.props

        return (
            <Link
                to="#"
                onClick={e => {
                    e.preventDefault()
                    window.location.href = 'mailto:info@ruler.nl'
                }}
                id={'Smallchat'}
                className={this.bem.getClassName(className)}
            >
                <Icon large type={IconType.envelope} />
            </Link>
        )
    }
}
