import DOMPurify from 'dompurify'

/**
 * Helper function to read the entire file and sanitize its content.
 * @param {File} file - The file to be read and sanitized.
 * @returns {Promise<boolean>} - Resolves true if content is clean, false otherwise.
 */
export const readAndSanitizeFile = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (!file) {
            resolve(false) // No file provided, return false
            return
        }

        const reader = new FileReader()

        reader.onload = () => {
            const rawContent = reader.result as string // Read file content as a string

            // Sanitize the file content
            DOMPurify.sanitize(rawContent)

            // Check for dangerous elements that DOMPurify would remove
            const unsafePatterns = /<script[\s\S]*?>[\s\S]*?<\/script>|on\w+="[\s\S]*?"/gi

            const isSafe = !unsafePatterns.test(rawContent)
            resolve(isSafe)
        }

        reader.onerror = () => {
            reject(new Error('Failed to read the file'))
        }

        reader.readAsText(file) // Read the entire file content as text
    })
}

export const readAndSanitizeMultipleFiles = async (files: File[]) => {
    return await (async () => {
        for (const file of files) {
            const result = await readAndSanitizeFile(file)
            if (!result) {
                return false
            }
        }
        return true
    })()
}
