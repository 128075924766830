import './TextLink.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Link as RouterLink } from 'react-router-dom'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { LocationDescriptor } from 'history'

export interface TextLinkProps {
    onClick?: () => void
    href?: string
    className?: ClassValue
    to?: string | LocationDescriptor
    style?: React.CSSProperties
    icon?: IconType
    external?: boolean
}

export const TextLink: React.FunctionComponent<React.PropsWithChildren<TextLinkProps>> = props => {
    const { children, external, onClick, className, icon, ...linkProps } = props

    const bem = new BEM('TextLink', () => ({
        'has-icon': !!props.icon,
    }))

    const Component = 'to' in linkProps ? RouterLink : 'a'

    const handleOnClick = (e: React.MouseEvent) => {
        e.stopPropagation()

        if (onClick) {
            e.preventDefault()
            onClick()
        }
    }

    return (
        <Component
            onClick={handleOnClick}
            target={external ? '_blank' : undefined}
            {...(linkProps as any)}
            className={bem.getClassName(className)}
        >
            {icon && <Icon type={icon} className={bem.getElement('icon')} />}
            {children}
        </Component>
    )
}
