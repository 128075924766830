import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'react-apollo'
import { localize } from '~/bootstrap'
import { ColumnOptions, RowData, SortState, Table } from '~/components/Core/DataDisplay/Table/Table'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Assessment, AssessmentLabel } from '~/components/Domain/Compliance/Assessments/AssessmentLabel'
import { RiskIndicator, SeverityLevel } from '~/components/Domain/Compliance/Risks/RiskIndicator'
import { RiskType } from '~/components/Domain/Compliance/Risks/EditRiskModal/EditRiskModal'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Row } from '~/components/Core/Layout/Row'
import { AssessmentSelect } from '~/components/Domain/Compliance/Assessments/AssessmentSelect'
import { RiskTypeSelect } from '~/components/Domain/Compliance/Risks/RiskTypeSelect'
import { RiskTopicSelect } from '~/components/Domain/Compliance/Risks/RiskTopicSelect'
import { RiskFilter, RiskFilterType } from '~/components/Domain/Compliance/Risks/RiskFilter'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import {
    RiskGraph,
    RiskGraphIndicatorType,
    RiskGraphIndicatorPosition,
} from '~/components/Domain/Compliance/Risks/RiskGraph/RiskGraph'
import { RisksOverviewRiskGraphLayout } from '~/components/Domain/Compliance/Risks/RiskGraph/RisksOverviewRiskGraphLayout'
import { RiskGraphIndicatorTooltip } from '~/components/Domain/Compliance/Risks/RiskGraph/RiskGraphIndicatorTooltip'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'
import { RiskStatus } from '~/graphql/types/Risk'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager, NewParamState } from '~/components/Core/ParamManager/ParamManager'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {}

interface Filters extends SortState {
    search: string | null
    assessmentFilterOption: SelectOption<string> | null
    riskType?: SelectOption | null
    topic?: SelectOption | null
    riskFilter?: RiskFilterType
}

export interface AllRiskType {
    id: number
    name: string
    description: string
    types: RiskType[]
    createdAt: string
    assessment?: Assessment
    isAccepted: boolean
    severity: SeverityLevel
    severityBruto: SeverityLevel
    nettoImpact: number
    nettoProbability: number
    brutoImpact: number
    brutoProbability: number
    status: RiskStatus
}

export interface GetAllRisksQueryResponse {
    allRisks?: AllRiskType[]
}

export const GET_ALL_RISKS = gql`
    query risks($sort: RiskSort, $filters: RiskFilters) {
        allRisks(sort: $sort, filters: $filters) {
            id
            name
            description
            createdAt
            severity
            severityBruto
            status
            isAccepted
            nettoImpact
            nettoProbability
            brutoImpact
            brutoProbability
            types {
                id
                name
            }
            assessment {
                id
                name
            }
        }
    }
`

class HeatmapViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)

    public render() {
        const tableHeaders: ColumnOptions[] = [
            {
                field: 'severity',
                headerLabel: this.loc(t => t.severity),
                hideLabelFromDisplay: true,
                noAutoWidth: true,
                sortable: true,
            },
            {
                field: 'name',
                headerLabel: this.loc(t => t.riskName),
                sortable: true,
            },
            {
                field: 'riskType',
                headerLabel: this.loc(t => t.riskType),
            },
            {
                field: 'assessmentName',
                headerLabel: this.loc(t => t.assessment),
                sortable: true,
            },
            {
                field: 'createdAt',
                headerLabel: this.loc(t => t.creationDate),
                noAutoWidth: true,
                sortable: true,
            },
        ]

        return (
            <ParamManager<Filters>
                defaultState={{
                    sortDirection: { field: 'severity', direction: 'DESC' },
                    search: null,
                    assessmentFilterOption: null,
                    riskType: undefined,
                    topic: undefined,
                    riskFilter: RiskFilterType.netto,
                }}
            >
                {({ paramState, setParamState }) => {
                    const { search, assessmentFilterOption, riskType, topic, riskFilter, sortDirection } = paramState

                    const filterByAssessmentId = assessmentFilterOption
                        ? parseInt(assessmentFilterOption.value as string, 10)
                        : undefined

                    return (
                        <Query<GetAllRisksQueryResponse>
                            query={GET_ALL_RISKS}
                            variables={{
                                filters: {
                                    search,
                                    assessmentId: filterByAssessmentId,
                                    riskTypeIds: this.getValueFromOption(riskType),
                                    topicId: this.getValueFromOption(topic),
                                    departmentId: this.context.activeDepartmentId,
                                    brutoOnly: riskFilter === RiskFilterType.bruto,
                                    acceptedOnly: riskFilter === RiskFilterType.nettoAccepted,
                                },
                                sort: {
                                    [sortDirection.field]: sortDirection.direction,
                                },
                            }}
                        >
                            {({ loading, data }) => {
                                const risks = data && data.allRisks ? data.allRisks : []

                                const tableData: RowData[] = risks.map(risk => {
                                    return {
                                        id: risk.id,
                                        columns: {
                                            severity:
                                                riskFilter === RiskFilterType.bruto ? (
                                                    <RiskIndicator severity={risk.severityBruto} />
                                                ) : (
                                                    <RiskIndicator severity={risk.severity} />
                                                ),
                                            name: (
                                                <Row smallSpacing={true}>
                                                    {risk.status && (
                                                        <AttentionIcon
                                                            tooltipContent={localize.translate(
                                                                t => t.Customer.Compliance.Risks.status[risk.status]
                                                            )}
                                                        />
                                                    )}
                                                    <TableLink
                                                        to={routes
                                                            .customer(this.context.customer.slug)
                                                            .risks.assessments.view(risk.assessment?.id)}
                                                    >
                                                        {risk.name}
                                                    </TableLink>
                                                </Row>
                                            ),
                                            riskType: this.formatRiskTypes(risk.types),
                                            assessmentName: risk.assessment && (
                                                <AssessmentLabel assessment={risk.assessment} />
                                            ),
                                            createdAt: (
                                                <DateFormat
                                                    date={new Date(risk.createdAt)}
                                                    readable={true}
                                                    noWeekday={true}
                                                />
                                            ),
                                        },
                                    }
                                })

                                const riskIndicators = this.getRiskIndicators(risks, paramState)

                                return (
                                    <Page contained={true}>
                                        <PageHeader
                                            title={this.loc(t => t.title)}
                                            actionComponent={this.renderSearchAndFilters(paramState, setParamState)}
                                            breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).risks.index]}
                                        />
                                        <RisksOverviewRiskGraphLayout>
                                            <RiskGraph loading={loading} indicators={riskIndicators} />
                                        </RisksOverviewRiskGraphLayout>
                                        <Table
                                            loading={loading}
                                            columns={tableHeaders}
                                            data={tableData}
                                            onSortDirectionChange={(field, newDirection) => {
                                                setParamState({
                                                    sortDirection: {
                                                        field: field,
                                                        direction: newDirection,
                                                    },
                                                })
                                            }}
                                            defaultSortDirection={sortDirection}
                                        />
                                    </Page>
                                )
                            }}
                        </Query>
                    )
                }}
            </ParamManager>
        )
    }

    private renderSearchAndFilters(paramState: Filters, setParamState: (newState: NewParamState<Filters>) => void) {
        const { search, riskType, assessmentFilterOption, riskFilter, topic } = paramState

        return (
            <Row>
                <RiskTopicSelect
                    defaultValue={topic ? [topic] : undefined}
                    placeholder={this.loc(t => t.riskTopicPlaceholder)}
                    isFilter={true}
                    onChange={option => {
                        setParamState({ topic: option })
                    }}
                />
                <RiskFilter
                    onChange={option => {
                        const riskFilter = this.getValueFromOption<RiskFilterType>(option)
                        setParamState({ riskFilter })
                    }}
                    defaultValue={riskFilter}
                />
                <RiskTypeSelect
                    multi={false}
                    clearable={true}
                    onlyUsedInDepartment={this.context.activeDepartmentId}
                    placeholder={this.loc(t => t.riskType)}
                    onChange={option => {
                        setParamState({ riskType: option as any })
                    }}
                    defaultValue={riskType ? [riskType] : undefined}
                />
                <AssessmentSelect
                    isFilter={true}
                    placeholder={this.loc(t => t.allAssessments)}
                    selectedOptions={assessmentFilterOption ? [assessmentFilterOption] : undefined}
                    onChange={option => {
                        setParamState({ assessmentFilterOption: option })
                    }}
                />
                <Search
                    defaultValue={search}
                    onChange={search => setParamState({ search })}
                    placeholder={this.loc(t => t.riskSearchPlaceholder)}
                />
            </Row>
        )
    }

    private getValueFromOption<TValue>(option: SelectOption | null | undefined) {
        return option ? (option.value as TValue | null) || undefined : undefined
    }

    private formatRiskTypes(data: RiskType[] | null) {
        if (data === null || !data.length) {
            return null
        }

        return data.map(entry => entry.name).join(', ')
    }

    private getRiskIndicators(risks: AllRiskType[], paramState: Filters): RiskGraphIndicatorType[] {
        const { riskFilter } = paramState

        let filteredRisks

        if (riskFilter === RiskFilterType.bruto) {
            filteredRisks = risks.filter(risk => risk.brutoImpact !== null || risk.brutoProbability !== null)
        } else {
            filteredRisks = risks.filter(risk => risk.nettoImpact !== null || risk.nettoProbability !== null)
        }

        return filteredRisks.map(risk => {
            let position: RiskGraphIndicatorPosition

            position = {
                x: risk.nettoProbability,
                y: risk.nettoImpact,
            }

            if (riskFilter === RiskFilterType.bruto) {
                position = {
                    x: risk.brutoProbability,
                    y: risk.brutoImpact,
                }
            }

            return {
                position: position,
                content: () => (
                    <RiskIndicator
                        forGraph={true}
                        severity={riskFilter === RiskFilterType.bruto ? risk.severityBruto : risk.severity}
                    />
                ),
                tooltip: (
                    <RiskGraphIndicatorTooltip
                        title={risk.name}
                        description={risk.description}
                        assessment={risk.assessment}
                    />
                ),
            }
        })
    }
}

export const HeatmapView = withRouter(HeatmapViewComponent)
