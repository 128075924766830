import './Grid.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    gridColumns?: number
}

interface State {}

export class Grid extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Grid')

    public render() {
        const { children, className, gridColumns } = this.props

        const gridStyle = {
            gridTemplateColumns: gridColumns ? `repeat(${gridColumns}, 1fr` : undefined,
        }

        return (
            <div className={this.bem.getClassName(className)} style={gridStyle}>
                {children}
            </div>
        )
    }
}
