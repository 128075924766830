import './TaskTemplatesTableContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableHeaderLabel } from '~/components/Core/DataDisplay/Table/TableHeaderLabel'
import { ExpandableTableListItem } from '~/components/Core/DataDisplay/TableList/ExpandableTableListItem'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ConsultantTaskTemplateType, ConsultantTaskTemplateTypeEnum, TaskTemplateType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { TaskTags } from '../TaskTags/TaskTags'
import { Column } from '~/components/Core/Layout/Column'
import { TaskTemplateNameContainer } from './TaskTemplateNameContainer'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Center } from '~/components/Core/Layout/Center'
import { PaginationResult } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { FrameworkAbbreviationList } from '../../Customer/Framework/FrameworkAbbreviationList/FrameworkAbbreviationList'
import { CustomerFrameworkIconList } from '../../CustomerFramework/CustomerFrameworkIconList'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    queryLoading: boolean
    queryLoadingMore?: boolean
    data: PaginationResult<TaskTemplateType> | PaginationResult<ConsultantTaskTemplateType> | null
    refetch?: () => void
    forConsultantTemplate?: boolean
    forSuggestedTemplates?: boolean // consultant templates in customer env
}

export class TaskTemplatesTableContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.TaskTemplatesOverviewView)
    private bem = new BEM('TaskTemplatesTableContainer', () => ({
        noTagColumn: this.props.forSuggestedTemplates,
    }))

    public render() {
        const { queryLoading, data, queryLoadingMore } = this.props

        if (queryLoading) {
            return this.renderSpinner({ delayed: true })
        }

        if (!data || !data.nodes.length) {
            return <Table data={[]} columns={[]} />
        }

        const taskTemplates = data.nodes

        return (
            <Column smallSpacing={true} className={this.bem.getClassName()}>
                {this.renderHeaders()}
                <Column smallSpacing={true}>{this.renderRows(taskTemplates)}</Column>
                {queryLoadingMore && this.renderSpinner({})}
            </Column>
        )
    }

    private renderSpinner({ delayed }: { delayed?: boolean }) {
        return (
            <Center>
                <Spinner delayed={delayed} />
            </Center>
        )
    }

    private renderHeaders() {
        const { forConsultantTemplate, forSuggestedTemplates } = this.props

        if (forConsultantTemplate) {
            return (
                <Row className={this.bem.getElement('header-container')}>
                    <TableHeaderLabel label={this.loc(t => t.TableHeaders.name)} />
                    <TableHeaderLabel label={this.loc(t => t.TableHeaders.type)} />
                    <TableHeaderLabel label={this.loc(t => t.TableHeaders.frameworks)} />
                    <TableHeaderLabel label={this.loc(t => t.TableHeaders.subtaskCount)} />
                </Row>
            )
        }

        return (
            <Row className={this.bem.getElement('header-container')}>
                <TableHeaderLabel label={this.loc(t => t.TableHeaders.name)} />
                {!forSuggestedTemplates && <TableHeaderLabel label={this.loc(t => t.TableHeaders.tags)} />}
                <TableHeaderLabel label={this.loc(t => t.TableHeaders.subtaskCount)} />
                <TableHeaderLabel label={this.loc(t => t.TableHeaders.totalTaskCount)} />
            </Row>
        )
    }

    private renderRows(taskTemplates: TaskTemplateType[] | ConsultantTaskTemplateType[]) {
        return (taskTemplates as Array<TaskTemplateType | ConsultantTaskTemplateType>).map(taskTemplate => {
            const { id, name, subTaskTemplates } = taskTemplate

            return (
                <ExpandableTableListItem
                    key={Math.random() * 1000 * id}
                    renderExpanded={() => this.renderSubTasks(name, id, subTaskTemplates)}
                    className={this.bem.getElement('template-row', () => ({
                        noSubTask: !subTaskTemplates?.length,
                    }))}
                >
                    {this.renderRow(taskTemplate)}
                </ExpandableTableListItem>
            )
        })
    }

    private renderRow(taskTemplate: TaskTemplateType | ConsultantTaskTemplateType) {
        const { refetch, forConsultantTemplate, forSuggestedTemplates } = this.props

        return (
            <div className={this.bem.getElement('template-row-content')}>
                <TaskTemplateNameContainer
                    taskTemplate={taskTemplate}
                    onCreateTaskTemplate={refetch}
                    forConsultantTemplate={forConsultantTemplate}
                    forSuggestedTemplate={forSuggestedTemplates}
                    renderedOnPage={true}
                />
                {forConsultantTemplate
                    ? this.renderConsultantSpecificColumns(taskTemplate as ConsultantTaskTemplateType)
                    : this.renderCustomerSpecificColumns(taskTemplate as TaskTemplateType)}
            </div>
        )
    }

    private renderConsultantSpecificColumns(template: ConsultantTaskTemplateType) {
        const { subTaskTemplates, frameworks, type } = template
        const subTaskTemplateCount = subTaskTemplates ? subTaskTemplates.length : 0

        return (
            <>
                {this.renderTemplateType(type)}
                <FrameworkAbbreviationList frameworks={frameworks} truncateAt={2} />
                <Paragraph>{this.loc(t => t.subtaskCount, { smart_count: subTaskTemplateCount })}</Paragraph>
            </>
        )
    }

    private renderCustomerSpecificColumns(template: TaskTemplateType | ConsultantTaskTemplateType) {
        const { forSuggestedTemplates } = this.props
        const { totalTaskCount, subTaskTemplates } = template
        const tags = 'tags' in template ? template.tags : undefined
        const subTaskTemplateCount = subTaskTemplates ? subTaskTemplates.length : 0

        return (
            <>
                {forSuggestedTemplates ? (
                    this.renderCustomerFrameworkIcons(template)
                ) : (
                    <div className={this.bem.getElement('tag-container')}>
                        <TaskTags showAsTooltip={true} tags={tags || []} />
                    </div>
                )}
                <Paragraph>{this.loc(t => t.subtaskCount, { smart_count: subTaskTemplateCount })}</Paragraph>
                <Paragraph>{totalTaskCount}</Paragraph>
            </>
        )
    }

    private renderSubTasks(
        parentName: string,
        parentId: number,
        subTaskTemplates?: TaskTemplateType[] | ConsultantTaskTemplateType[] | null
    ) {
        if (!subTaskTemplates || !subTaskTemplates.length) {
            return
        }

        return (
            <Table
                columns={this.getSubTaskColumns()}
                data={this.getSubTaskTableData(subTaskTemplates, parentId, parentName)}
                className={this.bem.getElement('subtask-table')}
            />
        )
    }

    private getSubTaskColumns() {
        const { forConsultantTemplate, forSuggestedTemplates } = this.props

        if (forConsultantTemplate || forSuggestedTemplates) {
            return [{ field: 'name', headerLabel: this.loc(t => t.TableHeaders.subTasks), truncateField: true }]
        }

        return [
            { field: 'name', headerLabel: this.loc(t => t.TableHeaders.subTasks), truncateField: true },
            { field: 'tags', headerLabel: this.loc(t => t.TableHeaders.tags) },
        ]
    }

    private getSubTaskTableData(
        subTaskTemplates: TaskTemplateType[] | ConsultantTaskTemplateType[],
        parentId: number,
        parentName: string
    ) {
        const { refetch, forConsultantTemplate, forSuggestedTemplates } = this.props

        return (subTaskTemplates as Array<TaskTemplateType | ConsultantTaskTemplateType>).map(subTaskTemplate => ({
            id: subTaskTemplate.id,
            columns: {
                name: (
                    <TaskTemplateNameContainer
                        taskTemplate={subTaskTemplate}
                        parentTaskTemplateId={parentId}
                        parentTaskTemplateName={parentName}
                        isSubTask={true}
                        onCreateTaskTemplate={refetch}
                        forConsultantTemplate={forConsultantTemplate}
                        forSuggestedTemplate={forSuggestedTemplates}
                        renderedOnPage={true}
                    />
                ),
                tags:
                    'tags' in subTaskTemplate ? (
                        <div className={this.bem.getElement('tag-container')}>
                            <TaskTags showAsTooltip={true} tags={subTaskTemplate.tags || []} />
                        </div>
                    ) : undefined,
            },
        }))
    }

    private renderCustomerFrameworkIcons(template: TaskTemplateType | ConsultantTaskTemplateType) {
        if (!('frameworks' in template)) {
            return <>&nbsp;</> // needed for table styling
        }

        const customerFrameworks = this.context.profiles.filter(p =>
            template.frameworks.some(f => f.id === p.framework.id)
        )

        return (
            <Row alignRight={true} className={this.bem.getElement('icons')}>
                <CustomerFrameworkIconList customerFrameworks={customerFrameworks} />
            </Row>
        )
    }

    private renderTemplateType(type?: ConsultantTaskTemplateTypeEnum | null) {
        return (
            <Paragraph>
                {type && localize.translate(t => t.Customer.Settings.TaskTemplatesOverviewView.TaskTemplateModal[type])}
            </Paragraph>
        )
    }
}
