import './PdfPreviewModal.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { IconType } from '../../Icon/IconType'

interface Props {
    className?: string
    requestClose?: () => void
    style?: React.CSSProperties
    title: string
    titleSuffix?: React.ReactNode
    fileUri: string
    mimetype?: string
    viewOnly?: boolean
}

interface State {
    loading: boolean
}

export class PdfPreviewModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        loading: true,
    }

    private bem = new BEM('PdfPreviewModal')

    private closeButton = React.createRef<Button>()

    public async componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)

        if (this.closeButton.current) {
            this.closeButton.current.focus()
        }

        // Delay a bit so we won't see any stutter from the pdf loading
        setTimeout(() => {
            this.setState({ loading: false })
        }, 400)
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const { className, title, style, requestClose, fileUri, mimetype, viewOnly, titleSuffix } = this.props

        const { loading } = this.state

        return (
            <div style={style} className={this.bem.getClassName(className)}>
                <Row className={this.bem.getElement('header')} spaceBetween={true}>
                    <Row smallSpacing={true}>
                        <h2 className={this.bem.getElement('title')}>{title}</h2>
                        {titleSuffix && titleSuffix}
                    </Row>
                    <Button
                        type={ButtonType.noStyling}
                        onClick={requestClose}
                        ref={this.closeButton}
                        icon={IconType.closeModal}
                        className={this.bem.getElement('close-button')}
                    />
                </Row>
                {loading ? (
                    <Spinner />
                ) : (
                    <object
                        data={fileUri}
                        type={mimetype ? mimetype : 'application/pdf'}
                        className={this.bem.getElement('content')}
                    >
                        <iframe src={fileUri} width="100%" height="100%" style={{ border: 'none' }}>
                            {localize.translate(t => t.Core.PdfPreviewModal.notSupported)}
                            <a href={fileUri}>{localize.translate(t => t.Core.PdfPreviewModal.download)}</a>
                        </iframe>
                    </object>
                )}
                <Row className={this.bem.getElement('header')} spaceBetween={true}>
                    <Row alignRight={true} className={this.bem.getElement('button-container')}>
                        {!viewOnly && (
                            <Button href={fileUri} external={true}>
                                {localize.translate(t => t.Core.PdfPreviewModal.openInNewWindow)}
                            </Button>
                        )}
                    </Row>
                </Row>
            </div>
        )
    }

    private handleKeyUp = (event: KeyboardEvent) => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        if (this.props.requestClose) {
            this.props.requestClose()
        }
    }
}
