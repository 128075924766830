import React from 'react'
import { CustomerFrameworkFragment, CustomerFramework } from '~/graphql/types/CustomerFramework'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import gql from 'graphql-tag'
import { apolloClient } from '~/services/ApolloService'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { LinkedItemType, LinkedItem } from '~/graphql/types/LinkedItem'
import { InboxDetailView } from '~/views/Customer/Inbox/InboxDetailView'
import { AlertImpactLevel, LinkedItemFragmentFragment } from '~/generated/graphql'
import { TaskPriority } from '~/graphql/types/Task'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props {}

interface State {}

export interface Alert {
    __typename: 'AlertType'
    id: number
    name: string
    editorsNote?: string | null
    abstract: string
    publishedAt: Date
    updatedAt: Date
    customerFrameworks: CustomerFramework[]
    isRead: boolean
    item: LinkedItem | null
    impactLevel: AlertImpactLevel | null
    inboxStatus?: {
        id: number
        archivedAt: Date
        archivedReason: string | null
        archivedBy?: {
            id: number
            user: {
                id: number
                profile: {
                    id: number
                    fullName: string
                    avatar?: string
                }
            }
        }
    }
    nextTask?: {
        id: number
        dueAt?: Date
        priority: TaskPriority
    }
    highestPrioTask?: {
        id: number
        priority: TaskPriority
    }
    firstTask?: {
        id: number
        priority: TaskPriority
    }
    allPresentDepartments: {
        id: number
        name: string
    }[]
    publishedFromCustomerNews?: {
        id: number
        news: Extract<LinkedItemFragmentFragment, { __typename: 'NewsItemType' }>
    } | null
}

export const GET_INBOX_DETAIL_QUERY = gql`
    query alert($alertId: Int!, $customerSlug: String, $departmentId: Int!) {
        alert(id: $alertId, customerSlug: $customerSlug, departmentId: $departmentId) {
            id
            editorsNote
            name
            abstract
            isRead
            publishedAt
            updatedAt
            impactLevel(departmentId: $departmentId)
            publishedFromCustomerNews {
                id
                news {
                    id
                    title
                    publicationDate
                    presentationSetting
                    originalSource
                    customSourceUrl
                    attachments {
                        id
                        path
                        filename
                        mimetype
                    }
                    sources {
                        id
                        name
                    }
                }
            }
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
            nextTask(departmentId: $departmentId) {
                id
                dueAt
                priority
            }
            highestPrioTask(departmentId: $departmentId) {
                id
                priority
            }
            item(customerSlug: $customerSlug) {
                ...LinkedItemType
                ... on SingleLinkedItemType {
                    item {
                        ... on RadarItemType {
                            linkedTopics(departmentId: $departmentId) {
                                id
                                name
                            }
                        }
                    }
                }
            }
            firstTask(departmentId: $departmentId) {
                id
                priority
            }
            inboxStatus(departmentId: $departmentId) {
                id
                archivedAt
                archivedReason
                archivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                            avatar
                        }
                    }
                }
                unarchivedAt
                unarchivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
            }
            allPresentDepartments {
                id
                name
            }
        }
    }
    ${CustomerFrameworkFragment}
    ${LinkedItemType}
`

const MARK_ALERT_AS_READ_MUTATION = gql`
    mutation markAlertAsRead($id: Int!, $departmentId: Int!) {
        markAlertAsRead(id: $id, departmentId: $departmentId) {
            id
            name
            isRead
        }
    }
`

class InboxDetailContainerComponent extends React.Component<Props & RouteComponentProps<{ id?: string }>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private timer: number

    public componentWillUnmount() {
        window.clearTimeout(this.timer)
    }

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<Alert>
                query={GET_INBOX_DETAIL_QUERY}
                variables={{
                    alertId: id,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {(alert, { refetch }) => (
                    <InboxDetailView
                        onViewed={this.makeMarkAlertAsRead(alert)}
                        alert={alert}
                        refetch={refetch as () => void}
                        {...this.props}
                    />
                )}
            </PageQuery>
        )
    }

    public makeMarkAlertAsRead = (alert: Alert) => () => {
        this.timer = window.setTimeout(() => {
            // We don't want to mark an alert as read when it's already read.
            if (alert.isRead) {
                return
            }

            apolloClient.mutate({
                mutation: MARK_ALERT_AS_READ_MUTATION,
                variables: { id: alert.id, departmentId: this.context.activeDepartmentId },
            })
        }, 1250)
    }
}

export const InboxDetailContainer = withRouter(InboxDetailContainerComponent)
