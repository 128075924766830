import './TaskFormFields.scss'

import React from 'react'
import { Row } from '~/components/Core/Layout/Row'
import { RowFraction } from '~/components/Core/Layout/RowFraction'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { TaskPrioritySelect } from '~/components/Domain/Task/TaskPrioritySelect'
import { Column } from '~/components/Core/Layout/Column'
import { TaskPriority, TaskEmployeeFields, TaskTagFields } from '~/graphql/types/Task'
import { localize, permissions } from '~/bootstrap'
import { TaskRepeatFrequencySelect } from '~/components/Domain/Task/TaskRepeatFrequencySelect'
import { CustomerTaskTemplateType, TaskRepeatFrequency } from '~/generated/graphql'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { UserProfilePickerListUser } from '~/components/Core/DataEntry/UserProfilePicker/UserProfilePickerList/UserProfilePickerList'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { TaskFormTemplateField } from './TaskFormTemplateField'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'

interface Props {
    name?: string
    description?: string
    dueAt?: Date
    startAt?: Date
    employees?: TaskEmployeeFields[]
    tags?: TaskTagFields[]
    priority?: TaskPriority
    forMonitoring?: boolean
    repeats?: TaskRepeatFrequency | null
    isReviewTask?: boolean
    onChangeEmployees?: (selectedUsers: UserProfilePickerListUser[], name: string) => void
    onTemplateOptionChange?: (taskTemplate?: CustomerTaskTemplateType) => void
    isForSubTask?: boolean
    subTaskParentName?: string
    showTemplateOption?: boolean
}

interface State {
    name?: string | null
    description?: string | null
    tags?: SelectOption[] | null
}

export class TaskFormFields extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    // name, description & tags fields need to be controlled for the optional use of templates
    public state: State = {
        name: this.props.name,
        description: this.props.description,
        tags: this.formatTagsForForm(this.props.tags),
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.TaskFormFields)
    private bem = new BEM('TaskFormFields')

    public render() {
        return (
            <Column className={this.bem.getClassName()} bigSpacing={true}>
                {this.renderTemplateField()}
                <Column smallSpacing={true}>
                    <Row noSpacing={true} className={this.bem.getElement('row-container')}>
                        <RowFraction alignStart={true} amount={3}>
                            {this.renderNameField()}
                        </RowFraction>
                        <RowFraction alignStart={true} amount={1}>
                            {this.renderEmployeesField()}
                        </RowFraction>
                    </Row>
                    <Row noSpacing={true} className={this.bem.getElement('row-container')}>
                        <RowFraction alignStart={true} amount={3}>
                            {this.renderDescriptionField()}
                        </RowFraction>
                        <RowFraction amount={1} />
                    </Row>
                </Column>
                <Row noSpacing={true} className={this.bem.getElement('row-container')}>
                    <RowFraction alignStart={true} amount={1}>
                        {this.renderStartDateField()}
                    </RowFraction>
                    <RowFraction alignStart={true} amount={1}>
                        {this.renderDueAtField()}
                    </RowFraction>
                    <RowFraction alignStart={true} amount={1}>
                        {this.renderRepeatField()}
                    </RowFraction>
                    <RowFraction alignStart={true} amount={1}>
                        {this.renderPriorityField()}
                    </RowFraction>
                </Row>
                <Row noSpacing={true} className={this.bem.getElement('row-container')}>
                    <RowFraction alignStart={true} amount={3}>
                        {this.renderTagsField()}
                    </RowFraction>
                    <RowFraction alignStart={true} amount={1}>
                        {this.renderAgendaField()}
                    </RowFraction>
                </Row>
            </Column>
        )
    }

    private renderTemplateField() {
        const { isForSubTask, subTaskParentName, showTemplateOption } = this.props

        if (isForSubTask) {
            return (
                <Row className={this.bem.getElement('template-container')}>
                    <Paragraph bold={true}>{this.loc(t => t.parentTask)}</Paragraph>
                    <Paragraph>{subTaskParentName}</Paragraph>
                </Row>
            )
        }

        if (showTemplateOption) {
            return (
                <Row className={this.bem.getElement('template-container')}>
                    <TaskFormTemplateField onTemplateOptionChange={this.handleTemplateOptionChange} />
                </Row>
            )
        }

        return
    }

    private renderAgendaField() {
        const { employees, forMonitoring } = this.props
        const employeeIds = employees ? employees.map(({ id }) => id) : []
        const hasPermission = permissions.canAddItemsToMonitoring(this.context.activeDepartmentId, employeeIds)

        return (
            <Guard condition={hasPermission}>
                <Field forInput="forMonitoring" label={this.loc(t => t.monitoringField)}>
                    <Form.Checkbox
                        name="forMonitoring"
                        value={true}
                        label={this.loc(t => t.forMonitoring)}
                        defaultChecked={forMonitoring}
                    />
                </Field>
            </Guard>
        )
    }

    private renderNameField() {
        const { name } = this.state
        const { isForSubTask } = this.props
        const fieldLabel = this.loc(t => (isForSubTask ? t.subtaak : t.taak))

        return (
            <Field forInput="name" label={fieldLabel}>
                <Form.Input
                    name="name"
                    placeholder={this.loc(t => t.taskPlaceholder)}
                    value={name || undefined}
                    onChange={name => this.setState({ name })}
                />
            </Field>
        )
    }

    private renderDescriptionField() {
        const { description } = this.state

        return (
            <Field forInput="description">
                <Form.Input
                    type={'textarea'}
                    name="description"
                    placeholder={this.loc(t => t.descriptionPlaceholder)}
                    value={description || undefined}
                    onChange={description => this.setState({ description })}
                />
            </Field>
        )
    }

    private renderEmployeesField() {
        const { employees, onChangeEmployees } = this.props

        const defaultEmployees = employees
            ? employees.map(({ id, user }) => ({
                  id,
                  fullName: user.profile.fullName,
                  uri: user.profile.avatar,
                  email: user.email,
                  phone: user.profile.phoneNumber,
              }))
            : undefined

        return (
            <Field forInput="employeeIds" label={this.loc(t => t.owner)}>
                <Form.UserProfilePickerList
                    name="employeeIds"
                    defaultUsers={defaultEmployees}
                    onChange={onChangeEmployees}
                    cappedList={true}
                />
            </Field>
        )
    }

    private renderStartDateField() {
        const { startAt } = this.props

        return (
            <Field forInput="startAt" label={this.loc(t => t.startDate)}>
                <Form.DatePicker name="startAt" defaultValue={startAt} />
            </Field>
        )
    }

    private renderDueAtField() {
        const { dueAt } = this.props

        return (
            <Field forInput="dueAt" label={this.loc(t => t.deadline)}>
                <Form.DatePicker name="dueAt" defaultValue={dueAt} />
            </Field>
        )
    }

    private renderRepeatField() {
        const { repeats, isReviewTask } = this.props

        if (isReviewTask) {
            return null
        }

        return (
            <Field
                className={this.bem.getElement('repeat-field')}
                forInput="repeats"
                label={this.loc(t => t.repeats)}
                tooltip={this.loc(t => t.repeatsTooltip)}
            >
                <TaskRepeatFrequencySelect name="repeats" defaultValue={repeats || undefined} />
            </Field>
        )
    }

    private renderTagsField() {
        const { tags } = this.state

        return (
            <Field label={this.loc(t => t.tags)} forInput="tags">
                <Form.TaskTagsSelect name="tags" value={tags || undefined} onChange={tags => this.setState({ tags })} />
            </Field>
        )
    }

    private renderPriorityField() {
        const { priority } = this.props

        return (
            <Field label={this.loc(t => t.priority)} forInput="priority">
                <TaskPrioritySelect name="priority" defaultValue={priority} />
            </Field>
        )
    }

    private handleTemplateOptionChange = (taskTemplate?: CustomerTaskTemplateType) => {
        const { onTemplateOptionChange } = this.props
        if (onTemplateOptionChange) {
            onTemplateOptionChange(taskTemplate)
        }

        if (!taskTemplate) {
            this.setState({ name: undefined, description: undefined, tags: undefined })
            return
        }

        const { name, description } = taskTemplate
        const formattedTags = 'tags' in taskTemplate ? this.formatTagsForForm(taskTemplate.tags) : undefined

        this.setState({ name, description, tags: formattedTags })
    }

    private formatTagsForForm(tags?: TaskTagFields[] | null) {
        if (tags === undefined) {
            return
        }

        if (tags === null) {
            return []
        }

        return tags.map(({ name, id }) => ({ label: name, value: id }))
    }
}
