import './SelectControlTopicsModal.scss'
import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { localize, notification } from '~/bootstrap'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlsTopicSelectionTableContainer } from './ControlsTopicSelectionTableContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { GQLMutation } from '~/graphql/Mutation'
import {
    AddControlToTopicsDocument,
    AddControlToTopicsMutation,
    AddControlToTopicsMutationVariables,
} from '~/generated/graphql'
import { MutationFn } from 'react-apollo'
import { uniq } from 'lodash-es'

interface Props {
    controlId: number
    requestClose: () => void
    onSubmit?: () => void
}

export class SelectControlTopicsModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SelectControlTopicsModal')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.SelectThemesAndTopicsModal)

    // themeId -> topicIds
    private checkedTopicIds: Record<number, number[]> = {}

    public render() {
        const { requestClose } = this.props

        return (
            <GQLMutation<AddControlToTopicsMutation, AddControlToTopicsMutationVariables>
                mutation={AddControlToTopicsDocument}
            >
                {(mutate, { loading }) => (
                    <Modal
                        preventCloseOnESC
                        className={this.bem.getClassName()}
                        requestClose={requestClose}
                        title={this.loc(t => t.modalTitle)}
                        onAction={() => this.handleSubmit(mutate)}
                        confirmButtonLabel={localize.translate(t => t.Generic.save)}
                        cancelButtonLabel={localize.translate(t => t.Generic.skip)}
                        loading={loading}
                    >
                        <ErrorMessage path="addControlToTopics" />
                        <Paragraph>
                            {this.loc(t => t.noticePart)}
                            <strong>{this.context.activeDepartment.name}</strong>.
                        </Paragraph>
                        <ControlsTopicSelectionTableContainer
                            controlId={this.props.controlId}
                            disabled={loading}
                            onCheckedTopics={checkedTopics => {
                                this.checkedTopicIds = checkedTopics
                            }}
                        />
                    </Modal>
                )}
            </GQLMutation>
        )
    }

    private async handleSubmit(mutate: MutationFn<AddControlToTopicsMutation, AddControlToTopicsMutationVariables>) {
        const { controlId, requestClose, onSubmit } = this.props

        const response = await mutate({
            variables: {
                controlId,
                departmentId: this.context.activeDepartmentId,
                topicIds: uniq(Object.values(this.checkedTopicIds).flat()),
            },
        })

        if (response && response.data?.addControlToTopics) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            onSubmit?.()
            requestClose()
        }
    }
}
