import './MarkTopicsAsNonApplicableFormList.scss'

import React from 'react'
import { CardList } from '~/components/Core/DataDisplay/CardList/CardList'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { ThemesDetailCard } from '../ThemesDetailCardList/ThemesDetailCard'
import { ThemesDetailCardTopicType } from '../ThemesDetailCardList/ThemesDetailCardListQuery'
import { BEM } from '~/services/BEMService'

interface Props {
    loading: boolean
    loadingMore: boolean
    topics: ThemesDetailCardTopicType[]
    onTopicApplicableChange?: (
        topic: ThemesDetailCardTopicType,
        applicability: { reason: string | null; isApplicable: boolean }
    ) => void
}

export class MarkTopicsAsNonApplicableFormList extends React.PureComponent<React.PropsWithChildren<Props>, {}> {
    private bem = new BEM('MarkTopicsAsNonApplicableFormList')

    public render() {
        const { loading, loadingMore } = this.props

        return (
            <CardList loading={loading} loadingMore={loadingMore} columns={3} className={this.bem.getClassName()}>
                {this.renderTopics()}
            </CardList>
        )
    }

    private renderTopics() {
        const { topics, onTopicApplicableChange } = this.props

        if (!topics) {
            return <NoResults />
        }

        return topics.map(topic => (
            <ThemesDetailCard
                key={`topic-${topic.id}`}
                topic={topic as ThemesDetailCardTopicType}
                notAllowedToPin={true} // this hides the pin
                togglePinned={() => ({})}
                isPinned={false}
                showMarkNonApplicable={true}
                hidePin={true}
                onTopicApplicableChange={onTopicApplicableChange}
            />
        ))
    }
}
