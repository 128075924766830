import './ThemeDetailView.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { ThemeQuery } from '~/components/Domain/LawAndRegulation/ThemeQuery'
import { PinnedTopicsQuery } from '~/components/Domain/Pinned/PinnedTopicsQuery'
import { ThemesDetailCardListQuery } from '~/components/Domain/Themes/ThemesDetailCardList/ThemesDetailCardListQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import {
    formatThemeDetailViewFilters,
    ThemeDetailViewFilterOptionsButton,
    ThemeDetailViewFilters,
} from '~/components/Domain/Themes/ThemeDetailViewFilterOptionsButton'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ThemesDetailPinnedItemsContainer } from '~/components/Domain/Themes/ThemesDetailCardList/ThemesDetailPinnedItemsContainer'
import { ThemesDetailItemsContainer } from '~/components/Domain/Themes/ThemesDetailCardList/ThemesDetailItemsContainer'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

class ThemeDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.TopicView)
    private onPin: (id: number, themeId: number) => void
    private refetchTopics: () => void
    private bem = new BEM('ThemeDetailView')

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        const pageBreadcrumbs = [
            breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
            breadcrumbs.customer(this.context.customer.slug).legalFramework.themes.index,
        ]

        return (
            <ThemeQuery themeId={parseInt(id, 10)}>
                {theme => (
                    <ParamManager<ThemeDetailViewFilters> defaultState={{}}>
                        {({ paramState, setParamState }) => (
                            <div className={this.bem.getClassName()}>
                                <Page>
                                    <PageHeader
                                        suffix={
                                            <CustomerFrameworkIconList customerFrameworks={theme.customerFrameworks} />
                                        }
                                        title={theme.name}
                                        breadCrumbs={pageBreadcrumbs}
                                        actionComponent={this.renderHeaderAction(paramState, setParamState)}
                                    />
                                </Page>
                                <div className={this.bem.getElement('table-container')}>
                                    <Page>
                                        <Column bigSpacing>
                                            <SectionTitle>{this.loc(t => t.subjects)}</SectionTitle>
                                            {this.renderPinnedTopics(paramState)}
                                            {this.renderTopics(paramState)}
                                        </Column>
                                    </Page>
                                </div>
                            </div>
                        )}
                    </ParamManager>
                )}
            </ThemeQuery>
        )
    }

    private renderHeaderAction(
        paramState: ThemeDetailViewFilters,
        setParamState: SetParamStateFn<ThemeDetailViewFilters>
    ) {
        const { id } = this.props.match.params

        return (
            <Row alignRight={true} alignCenter={false} smallSpacing={true}>
                <Search
                    placeholder={this.loc(t => t.searchPlaceholder)}
                    onChange={search => setParamState({ ...paramState, search: search || '' })}
                    defaultValue={paramState.search}
                />
                <ThemeDetailViewFilterOptionsButton filters={paramState} onChange={setParamState} />
                <Guard condition={permissions.canChangeNonApplicableTopics()}>
                    <Button
                        className={this.bem.getElement('nvt-button')}
                        icon={IconType.ignoreControlTask}
                        to={routes.customer(this.context.customer.slug).legalFramework.themes.viewApplicable(id)}
                        type={ButtonType.inputLike}
                    />
                </Guard>
            </Row>
        )
    }

    private renderPinnedTopics(paramState: ThemeDetailViewFilters) {
        const themeId = parseInt(this.props.match.params.id!, 10)

        return (
            <PinnedTopicsQuery themeId={themeId} topicDnEFilters={formatThemeDetailViewFilters(paramState)}>
                {pinnedTopicsOptions => {
                    if (pinnedTopicsOptions.loading) {
                        return null
                    }

                    this.onPin = pinnedTopicsOptions.pin

                    return (
                        <ThemesDetailPinnedItemsContainer
                            topicsOptions={pinnedTopicsOptions}
                            themeId={themeId}
                            onUnpin={this.refetchTopics}
                        />
                    )
                }}
            </PinnedTopicsQuery>
        )
    }

    private renderTopics(paramState: ThemeDetailViewFilters) {
        const themeId = parseInt(this.props.match.params.id!, 10)

        return (
            <ThemesDetailCardListQuery filters={formatThemeDetailViewFilters(paramState)} themeId={themeId}>
                {topicsOptions => {
                    if (topicsOptions.loading) {
                        return <Spinner delayed={true} />
                    }

                    const topics = topicsOptions.data?.nodes || []
                    this.refetchTopics = topicsOptions.refetch

                    return (
                        <ThemesDetailItemsContainer
                            topics={topics}
                            onUnpin={topicsOptions.refetch}
                            onPin={id => {
                                if (this.onPin) {
                                    this.onPin(id, themeId)
                                }

                                topicsOptions.refetch()
                            }}
                        />
                    )
                }}
            </ThemesDetailCardListQuery>
        )
    }
}

export const ThemeDetailView = withRouter(ThemeDetailViewComponent)
