import './DepartmentEmployeesEditModal.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DepartmentType,
    EmployeeType,
    EditDepartmentNameAndEmployeesMutation,
    EditDepartmentNameAndEmployeesMutationVariables,
    DepartmentEmployeesQueryVariables,
    DepartmentEmployeesDocument,
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables,
    AllEmployeesQueryVariables,
    AllEmployeesDocument,
    AllEmployeesQuery,
    DepartmentEmployeesQuery,
    ProfileType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ButtonType } from '~/components/Core/Button/Button'
import { EditDepartmentNameAndEmployees } from '../DepartmentMutations/EditDepartmentNameAndEmployees'
import { MutationFn, Query } from 'react-apollo'
import { isEmpty } from 'lodash-es'
import { DeleteDepartment } from '../DepartmentMutations/DeleteDepartment'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface DepartmentEmployees {
    activeEmployees: EmployeeType[]
    passiveEmployees: EmployeeType[]
}

export enum EmployeeStatus {
    active = 'ACTIVE',
    passive = 'PASSIVE',
    unauthorized = 'UNAUTHORIZED',
}

interface Props {
    department: DepartmentType
    closeModal: () => void
}

export class DepartmentEmployeesEditModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private formRef = React.createRef<Form>()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview.employeeModal)
    private bem = new BEM('DepartmentEmployeesEditModal')

    public render() {
        const { department } = this.props

        return (
            <DeleteDepartment>
                {(deleteMutationFn, { loading: deleteMutationLoading }) => (
                    <>
                        <ErrorMessage showAsNotification={true} path={'deleteDepartment'} />
                        <EditDepartmentNameAndEmployees>
                            {(mutate, { loading: mutationLoading }) => (
                                <>
                                    <ErrorMessage showAsNotification={true} path={'editDepartmentNameAndEmployees'} />
                                    <Query<DepartmentEmployeesQuery, DepartmentEmployeesQueryVariables>
                                        query={DepartmentEmployeesDocument}
                                        variables={{ id: department.id, customerId: this.context.customer.id }}
                                    >
                                        {({ data: departmentData, loading: departmentQueryLoading }) => {
                                            if (departmentQueryLoading) {
                                                return <Spinner delayed={true} />
                                            }
                                            if (!departmentData) {
                                                return null
                                            }

                                            return (
                                                <Query<AllEmployeesQuery, AllEmployeesQueryVariables>
                                                    query={AllEmployeesDocument}
                                                    variables={{ customerId: this.context.customer.id }}
                                                >
                                                    {({ data, loading: employeesQueryLoading }) => {
                                                        if (employeesQueryLoading) {
                                                            return <Spinner delayed={true} />
                                                        }
                                                        if (!data) {
                                                            return null
                                                        }

                                                        const loading = mutationLoading || deleteMutationLoading
                                                        const departmentEmployees =
                                                            this.getDepartmentEmployees(departmentData)
                                                        const allAssignableEmployees =
                                                            this.getAllAssignableEmployees(data)

                                                        return this.renderModal(
                                                            loading,
                                                            mutate,
                                                            deleteMutationFn,
                                                            departmentEmployees,
                                                            allAssignableEmployees
                                                        )
                                                    }}
                                                </Query>
                                            )
                                        }}
                                    </Query>
                                </>
                            )}
                        </EditDepartmentNameAndEmployees>
                    </>
                )}
            </DeleteDepartment>
        )
    }

    private getDepartmentEmployees(departmentWithEmployees: DepartmentEmployeesQuery) {
        const employees = departmentWithEmployees.getDepartment?.employees.length
            ? departmentWithEmployees.getDepartment?.employees
            : []
        const passiveEmployees = departmentWithEmployees.getDepartment?.passiveEmployees.length
            ? departmentWithEmployees.getDepartment?.passiveEmployees
            : []

        const activeEmployees = employees.filter(({ id }) => !passiveEmployees.some(employee => employee.id === id))

        const departmentEmployees = {
            activeEmployees: activeEmployees as EmployeeType[],
            passiveEmployees: passiveEmployees as EmployeeType[],
        }

        return departmentEmployees
    }

    private getAllAssignableEmployees(queryResult: AllEmployeesQuery) {
        const allAssignableEmployees: EmployeeType[] = []
        queryResult.allEmployees?.forEach(employee => {
            if (employee) {
                allAssignableEmployees.push(employee as EmployeeType)
            }
        })

        return allAssignableEmployees
    }

    private renderModal(
        loading: boolean,
        mutate: MutationFn<EditDepartmentNameAndEmployeesMutation, EditDepartmentNameAndEmployeesMutationVariables>,
        deleteMutationFn: MutationFn<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>,
        departmentEmployees: DepartmentEmployees,
        allAssignableEmployees: EmployeeType[]
    ) {
        const { closeModal, department } = this.props

        return (
            <Modal
                className={this.bem.getClassName()}
                requestClose={closeModal}
                title={department.name}
                onAction={() => this.formRef.current?.triggerSubmit()}
                confirmButtonLabel={localize.translate(t => t.Generic.save)}
                loading={loading}
                onDelete={() => this.handleDelete(deleteMutationFn)}
                renderOnDeleteContent={() => this.renderOnDeleteContent()}
                onDeleteTitle={this.loc(t => t.confirmDeleteModal.title)}
                onDeleteConfirmButtonType={ButtonType.delete}
                onDeleteConfirmButtonLabel={this.loc(t => t.confirmDeleteModal.confirmButtonLabel)}
                onDeleteClassName={this.bem.getElement('delete-confirm-modal')}
                openDeleteConfirmButtonLabel={this.loc(t => t.deleteButtonLabel)}
            >
                {this.renderForm(mutate, departmentEmployees, allAssignableEmployees)}
            </Modal>
        )
    }

    private async handleDelete(mutate: MutationFn<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        const { department, closeModal } = this.props

        const response = await mutate({
            variables: {
                id: department.id,
                customerId: this.context.customer.id,
                customerSlug: this.context.customer.slug,
            },
        })

        if (response && response.data?.deleteDepartment) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            closeModal()
        }
    }

    private renderOnDeleteContent() {
        return <Paragraph>{this.loc(t => t.confirmDeleteModal.content)}</Paragraph>
    }

    private renderForm(
        mutate: MutationFn<EditDepartmentNameAndEmployeesMutation, EditDepartmentNameAndEmployeesMutationVariables>,
        departmentEmployees: DepartmentEmployees,
        allAssignableEmployees: EmployeeType[]
    ) {
        return (
            <Form ref={this.formRef} onSubmit={state => this.handleSubmit(state, mutate, departmentEmployees)}>
                <Row spaceBetween={true} className={this.bem.getElement('modal-content-container')}>
                    {this.renderDepartmentNameField()}
                    {this.renderEmployeeField(allAssignableEmployees, departmentEmployees)}
                    {this.renderConsultationAlertsField()}
                </Row>
            </Form>
        )
    }

    private async handleSubmit(
        state: FormState,
        mutate: MutationFn<EditDepartmentNameAndEmployeesMutation, EditDepartmentNameAndEmployeesMutationVariables>,
        defaultEmployees: DepartmentEmployees
    ) {
        if (isEmpty(state)) {
            return
        }

        const employees = this.getEmployeeStatuses(state, defaultEmployees)
        const { name, consultationAlerts } = state
        const { department, closeModal } = this.props

        const response = await mutate({
            variables: {
                departmentId: department.id,
                customerId: this.context.customer.id,
                name,
                employees,
                receiveAlertsForConsultationsEnabled: consultationAlerts,
            },
        })

        if (response && response.data?.editDepartmentNameAndEmployees) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            closeModal()
        }
    }

    private renderDepartmentNameField() {
        const { department } = this.props

        return (
            <>
                <Paragraph className={this.bem.getElement('name-field')} bold={true}>
                    {this.loc(t => t.name)}
                </Paragraph>
                <Field forInput={'name'}>
                    <Form.Input name={'name'} defaultValue={department.name} />
                </Field>
            </>
        )
    }

    private renderEmployeeField(allEmployees: EmployeeType[], departmentEmployees: DepartmentEmployees) {
        return (
            <>
                <Paragraph className={this.bem.getElement('table-title')} bold={true}>
                    {this.loc(t => t.users)}
                </Paragraph>
                <Row className={this.bem.getElement('table-header-row')}>
                    <Paragraph className={this.bem.getElement('name')} bold={true}>
                        {this.loc(t => t.name)}
                    </Paragraph>
                    <Row evenSpace={true} smallSpacing={true}>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.active)}
                        </Paragraph>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.passive)}
                        </Paragraph>
                        <Paragraph center={true} bold={true}>
                            {this.loc(t => t.unauthorized)}
                        </Paragraph>
                    </Row>
                </Row>
                <Column className={this.bem.getElement('table-container')}>
                    <Column className={this.bem.getElement('table-row-container')}>
                        {allEmployees.map(({ id, user: { profile } }) =>
                            this.renderEmployeeTableRow(id, profile, departmentEmployees)
                        )}
                    </Column>
                </Column>
            </>
        )
    }

    private renderConsultationAlertsField() {
        const { department } = this.props

        return (
            <>
                <Paragraph className={this.bem.getElement('name-field')} bold={true}>
                    {this.loc(t => t.consultationAlerts)}
                </Paragraph>
                <Field forInput={'consultationAlerts'}>
                    <Row>
                        <Form.Toggle
                            name={'consultationAlerts'}
                            defaultChecked={department.receiveAlertsForConsultationsEnabled}
                        />
                        <Paragraph>{this.loc(t => t.consultationAlertsInfo)}</Paragraph>
                    </Row>
                </Field>
            </>
        )
    }

    private getEmployeeStatuses(state: FormState, defaultEmployees: DepartmentEmployees) {
        const changedEmployees = Object.keys(state).filter(key => key !== 'name')

        if (!changedEmployees.length) {
            return
        }

        const { activeEmployees, passiveEmployees } = defaultEmployees

        let activeEmployeeIds: number[] = activeEmployees.map(({ id }) => id)
        let passiveEmployeeIds: number[] = passiveEmployees.map(({ id }) => id)

        Object.entries(state).forEach(entry => {
            const key = entry[0]
            const val = entry[1]
            const employeeId = parseInt(key.split('-')[1], 10)

            if (val === EmployeeStatus.active) {
                activeEmployeeIds.push(employeeId)
                passiveEmployeeIds = passiveEmployeeIds.filter(id => id !== employeeId)
                return
            }

            if (val === EmployeeStatus.passive) {
                passiveEmployeeIds.push(employeeId)
                activeEmployeeIds = activeEmployeeIds.filter(id => id !== employeeId)
                return
            }

            if (val === EmployeeStatus.unauthorized) {
                activeEmployeeIds = activeEmployeeIds.filter(id => id !== employeeId)
                passiveEmployeeIds = passiveEmployeeIds.filter(id => id !== employeeId)
            }
        })

        return {
            activeEmployeeIds: Array.from(new Set(activeEmployeeIds)),
            passiveEmployeeIds: Array.from(new Set(passiveEmployeeIds)),
        }
    }

    private renderEmployeeTableRow(id: number, profile: ProfileType, departmentEmployees: DepartmentEmployees) {
        const { activeEmployees, passiveEmployees } = departmentEmployees

        const isActive = !!activeEmployees.find(employee => employee.id === id)
        const isPassive = !!passiveEmployees.find(employee => employee.id === id)
        const isUnauthorized = !isActive && !isPassive

        return (
            <Row key={id} className={this.bem.getElement('table-row')}>
                <Paragraph>{profile.fullName}</Paragraph>
                <Row evenSpace={true} smallSpacing={true}>
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.active}
                        defaultChecked={isActive}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.passive}
                        defaultChecked={isPassive}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`status-${id}`}
                        value={EmployeeStatus.unauthorized}
                        defaultChecked={isUnauthorized}
                        className={this.bem.getElement('radio')}
                    />
                </Row>
            </Row>
        )
    }
}
