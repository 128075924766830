import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentsView } from './Assessments/AssessmentsView'
import { HeatmapView } from './HeatmapView'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{}> {}

class RisksViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route
                    path="*"
                    element={<Navigate replace to={routes.customer(this.context.customer.slug).risks.heatmap.index} />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.heatmap.index}
                    element={<HeatmapView />}
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).risks.assessments.index}
                    element={<AssessmentsView />}
                />

                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const RisksView = withRouter(RisksViewComponent)
