import './ExpandableNewsGroupSourcesListHeader.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { NewsSourcesGrid } from '~/components/Domain/Settings/NewsSources/NewsSourcesGrid'

interface State {}

interface Props {
    text?: string
    className?: string
    isTwoGrid?: boolean
    gridClassName?: string
    headers?: Header[]
}

interface Header {
    text?: string
    className?: string
}

export class ExpandableNewsGroupSourcesListHeader extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ExpandableNewsGroupSourcesListHeader')

    public render() {
        const { isTwoGrid } = this.props

        if (isTwoGrid) {
            return this.renderMultiple()
        }

        return this.renderSingle()
    }

    public renderMultiple = () => {
        const { isTwoGrid, gridClassName, headers } = this.props

        if (!headers) {
            return
        }

        return (
            <NewsSourcesGrid className={gridClassName} isTwoGrid={isTwoGrid}>
                {headers.map((header, index) => this.renderSingle(header, index))}
            </NewsSourcesGrid>
        )
    }

    public renderSingle = (header?: Header, index?: number) => {
        const { text, className } = header ? header : this.props

        const _className = className ? className : this.bem.getElement('table-header')

        return (
            <Column key={`header-${text}-${index}`} className={_className}>
                {text}
            </Column>
        )
    }
}
