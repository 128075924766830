import './SignalingWidget.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Bold } from '~/components/Core/Typography/Bold'
import { Row } from '~/components/Core/Layout/Row'
import {
    DashboardCustomerSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    DashboardSignalingWidgetFragmentFragment,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

interface ItemCountArgs {
    text: string
    count: number
    link: string
    className: string
}

export class SignalingWidget extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SignalingWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.SignalingWidget)

    public render() {
        const { isEditing, currentBreakpoint, onDeleteIconClick, onModalStateChange } = this.props

        return (
            <WidgetContainer
                isEditing={isEditing}
                className={this.bem.getClassName()}
                blueBackground={true}
                widgetInfo={this.getHeaderInfo()}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                {this.renderContent()}
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id } = this.props
        const customName = this.getCustomName()

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.signaling,
            departments: [],
            hideDepartments: true,
            whiteName: true,
        }
    }

    private renderContent() {
        const { totalCount, openCount, sentCount } = this.getCounts()

        const totalCountText = this.loc(t => t.totalOpen)
        const totalCountLink = routes.customer(this.context.customer.slug).signaling.index
        const infoText = this.loc(t => t.info)

        const openCountArgs = this.getOpenCountArgs(openCount)
        const sentCountArgs = this.getSentCountArgs(sentCount)

        return (
            <>
                <WidgetInfoLink to={totalCountLink}>
                    <Row extraSmallSpacing={true} className={this.bem.getElement('total')}>
                        <Bold className={this.bem.getElement('total-count')}>{totalCount}</Bold>
                        <Paragraph className={this.bem.getElement('total-text')}>{totalCountText}</Paragraph>
                    </Row>
                </WidgetInfoLink>
                <Row className={this.bem.getElement('content-container')}>
                    <Paragraph subtle={true} className={this.bem.getElement('info')}>
                        {infoText}
                    </Paragraph>
                    {this.renderItemCount(openCountArgs)}
                    {this.renderItemCount(sentCountArgs)}
                </Row>
            </>
        )
    }

    private getCustomName() {
        const { metadata } = this.props

        if (!metadata) {
            return
        }

        const { name } = metadata as DashboardCustomerSpecificWidgetMetadataType

        if (!name) {
            return
        }

        return name
    }

    private getCounts() {
        let totalCount: string | number = 0
        let openCount = 0
        let sentCount = 0

        const { content } = this.props

        if (content) {
            const {
                totalCount: total,
                openCount: open,
                sentCount: sent,
            } = content as DashboardSignalingWidgetFragmentFragment

            totalCount = total > 999 ? '999+' : total
            openCount = open
            sentCount = sent
        }

        return { totalCount, openCount, sentCount }
    }

    private getOpenCountArgs(openCount: number) {
        const openCountText = this.loc(t => t.openItems)
        const openCountLink = routes.customer(this.context.customer.slug).signaling.index

        return { text: openCountText, count: openCount, className: 'open-items', link: openCountLink }
    }

    private getSentCountArgs(sentCount: number) {
        const sentCountText = this.loc(t => t.sentItems)
        const sentCountLink = `${routes.customer(this.context.customer.slug).signaling.index}`

        return { text: sentCountText, count: sentCount, className: 'sent-items', link: sentCountLink }
    }

    private renderItemCount({ link, text, count, className }: ItemCountArgs) {
        return (
            <WidgetInfoLink to={link} className={this.bem.getElement('count-link')}>
                <Column extraSmallSpacing={true} className={this.bem.getElement(className)}>
                    <Bold className={this.bem.getElement('count')}>{count}</Bold>
                    <Paragraph bold={true} className={this.bem.getElement('text')}>
                        {text}
                    </Paragraph>
                </Column>
            </WidgetInfoLink>
        )
    }
}
