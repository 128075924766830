import './MonitoringReportTopicAssessmentsTable.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentDeLinkedItemType,
    MonitoringReportTopicAssessmentType,
    MonitoringReportType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { getIconTypeForControlType } from '~/utils/controls'
import { routes } from '~/views/routes'
import { TopicAssessmentIcon } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { MonitoringReportTopicTableData } from './MonitoringReportTopicsTable'
import { AssessMonitoringReportTopicItemButton } from './AssessMonitoringReportTopicItemButton'
import { MonitoringReportTopicAssessmentSummaryTooltip } from './MonitoringReportTopicAssessmentSummaryTooltip'

interface Props {
    monitoringReportTopic: MonitoringReportTopicTableData
    monitoringReport: Pick<MonitoringReportType, 'id' | 'name'>
    topicId: number
    onClick?: () => void
    onAssess?: () => void
}

export type MonitoringReportTopicAssessmentTableData = Pick<
    MonitoringReportTopicAssessmentType,
    | 'id'
    | 'updatedAt'
    | 'grade'
    | 'initialGrade'
    | 'linkedItemId'
    | 'topicAssessmentLinkedItemType'
    | 'assessmentType'
    | 'description'
    | 'documentsCount'
>

type ItemType = NonNullable<MonitoringReportTopicTableData['controlsAndNotes']>[0]

export class MonitoringReportTopicAssessmentsTable extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('MonitoringReportTopicAssessmentsTable')
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringDetailView.topicsTable)
    private childrenColumns: ColumnOptions[] = [
        { field: 'name', headerLabel: this.loc(t => t.name) },
        { field: 'design', headerLabel: this.loc(t => t.design) },
        { field: 'effectiveness', headerLabel: this.loc(t => t.effectiveness) },
    ]

    public render() {
        const { monitoringReportTopic, onClick } = this.props
        const { controlsAndNotes, topic } = monitoringReportTopic
        const to = routes.customer(this.context.customer.slug).legalFramework.topic.designAndEffectiveness(topic.id)

        return (
            <Column smallSpacing className={this.bem.getClassName()}>
                <Table
                    columns={this.childrenColumns}
                    data={this.getChildrenTableData(monitoringReportTopic, controlsAndNotes || [])}
                />
                {!monitoringReportTopic.isRemoved && !monitoringReportTopic.notApplicable && (
                    <Row extraSmallSpacing className={this.bem.getElement('assessment-link')}>
                        <Paragraph>{this.loc(t => t.actualAssessmentLink1)}</Paragraph>
                        <Button external to={!onClick ? to : undefined} onClick={onClick} type={ButtonType.actionLink}>
                            {this.loc(t => t.actualAssessmentLink2)}
                        </Button>
                    </Row>
                )}
            </Column>
        )
    }

    private getChildrenTableData(mrt: MonitoringReportTopicTableData, items: ItemType[]): RowData[] {
        return items.map(item => ({
            id: item.id,
            columns: {
                name: this.renderItemName(item),
                design: this.renderItemAssessment(mrt, item, TopicAssessmentDesignOrEffectiveNessType.design),
                effectiveness: this.renderItemAssessment(
                    mrt,
                    item,
                    TopicAssessmentDesignOrEffectiveNessType.effectiveness
                ),
            },
        }))
    }

    private renderItemAssessment(
        mrt: MonitoringReportTopicTableData,
        item: ItemType,
        type: TopicAssessmentDesignOrEffectiveNessType
    ) {
        const monitoringReportTopicAssessment = this.findMonitoringReportTopicAssessmentForItem(mrt, item, type)
        const itemAssessment = item.assessments?.find(a => a.topicAssessmentDesignOrEffectiveNessType === type)
        const initialGrade = monitoringReportTopicAssessment?.initialGrade || itemAssessment?.grade

        const shouldHideArrowAndAssess = !monitoringReportTopicAssessment && (mrt.isRemoved || mrt.notApplicable)
        if (shouldHideArrowAndAssess) {
            return (
                <Row>
                    <TopicAssessmentIcon status={initialGrade || undefined} />
                </Row>
            )
        }

        if (!!monitoringReportTopicAssessment) {
            return (
                <Row>
                    <TopicAssessmentIcon status={initialGrade || undefined} />
                    <Icon type={IconType.nextArrow2} verySubtle />
                    {this.renderMonitoringReportTopicAssessmentIcon(monitoringReportTopicAssessment)}
                </Row>
            )
        }

        return (
            <Row>
                <TopicAssessmentIcon status={initialGrade || undefined} />
                <Icon type={IconType.nextArrow2} verySubtle />
                {this.renderAssessButton(mrt, item, type, itemAssessment?.id)}
            </Row>
        )
    }

    private renderItemName(item: ItemType) {
        if ('title' in item) {
            return this.renderIconAndName(IconType.assessmentScribble, item.title)
        }

        return this.renderIconAndName(getIconTypeForControlType(item.control.type), item.control.name)
    }

    private renderIconAndName(icon: IconType, name: string) {
        return (
            <Row>
                <Icon type={icon} />
                <Paragraph small emphasis truncateEllipsis>
                    {name}
                </Paragraph>
            </Row>
        )
    }

    private renderMonitoringReportTopicAssessmentIcon(assessment: MonitoringReportTopicAssessmentTableData) {
        const { topicId } = this.props
        const { isRemoved, notApplicable } = this.props.monitoringReportTopic

        return (
            <MonitoringReportTopicAssessmentSummaryTooltip
                topicId={topicId}
                showWarningBanner
                hideLink={isRemoved || notApplicable}
                assessmentType={assessment.assessmentType}
                documentsCount={assessment.documentsCount}
                description={assessment.description}
                grade={assessment.grade}
                assessedAt={assessment.updatedAt}
            >
                <TopicAssessmentIcon status={assessment.grade} />
            </MonitoringReportTopicAssessmentSummaryTooltip>
        )
    }

    private renderAssessButton(
        mrt: MonitoringReportTopicTableData,
        item: ItemType,
        type: TopicAssessmentDesignOrEffectiveNessType,
        topicAssessmentId?: number
    ) {
        return (
            <AssessMonitoringReportTopicItemButton
                monitoringReport={this.props.monitoringReport}
                topicAssessmentId={topicAssessmentId}
                assessmentType={type}
                monitoringReportTopicId={mrt.id}
                linkedItemId={item.id}
                departmentId={mrt.department.id}
                topicId={mrt.topic.id}
                renderLabel={() => this.renderItemName(item)}
                onAssess={this.props.onAssess}
                linkedItemType={
                    item.__typename === 'TopicNoteType'
                        ? TopicAssessmentDeLinkedItemType.note
                        : TopicAssessmentDeLinkedItemType.controlmeasure
                }
            />
        )
    }

    private findMonitoringReportTopicAssessmentForItem(
        mrt: MonitoringReportTopicTableData,
        item: ItemType,
        type: TopicAssessmentDesignOrEffectiveNessType
    ) {
        return mrt.assessments
            ?.filter(a => {
                if (a.linkedItemId !== item.id) {
                    return false
                }

                const type =
                    item.__typename === 'TopicNoteType'
                        ? TopicAssessmentDeLinkedItemType.note
                        : TopicAssessmentDeLinkedItemType.controlmeasure
                return a.topicAssessmentLinkedItemType === type
            })
            .find(a => a.assessmentType === type)
    }
}
