import './SidebarTaskWidgetContainer.scss'
import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { getTypenameFromLinkedItemType, LinkedTaskItem } from '~/graphql/types/Task'
import { permissions } from '~/bootstrap'
import { ContextTasksModal } from '../TasksByContext/ContextTasksModal'
import {
    MinimalLinkedTaskItemStatusQuery,
    LinkedTaskItemStatusQueryResponse,
} from '~/components/Domain/Task/InlineTaskWidget/MinimalLinkedTaskItemStatusQuery'
import { BEM } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { TaskCounter } from './TaskCounter'
import { DepartmentType } from '~/generated/graphql'

interface Props {
    linkedItem: LinkedTaskItem
    forCustomer?: boolean
    refetch?: () => void
    possibleDepartments?: Pick<DepartmentType, 'id' | 'name'>[]
}

interface TaskCounts {
    open: number
    completed: number
    overDue: number
}

export class SidebarTaskWidgetContainer extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('SidebarTaskWidgetContainer')
    public refetch: () => void | undefined

    public render() {
        const { linkedItem, forCustomer, possibleDepartments } = this.props

        const { linkedItemId, linkedItemType } = getTypenameFromLinkedItemType(linkedItem)

        if (!permissions.canViewPlanning()) {
            return null
        }

        const customerOrDepartmentProps = forCustomer
            ? { customerId: this.context.customer.id }
            : { departmentId: this.context.activeDepartmentId }

        return (
            <MinimalLinkedTaskItemStatusQuery
                {...customerOrDepartmentProps}
                linkedItemId={linkedItemId!}
                linkedItemType={linkedItemType!}
            >
                {({ data, loading, refetch }) => {
                    this.refetch = refetch as any
                    const counts = this.getTaskCounts(data)

                    if (!counts.open && !counts.completed) {
                        return <></>
                    }

                    return (
                        <ModalManager
                            render={openModal => this.renderWidget(openModal, loading, counts)}
                            renderModal={closeModal => (
                                <ContextTasksModal
                                    amountOfOpenTasks={counts.open}
                                    amountOfCompletedTasks={counts.completed}
                                    linkedItem={linkedItem}
                                    requestClose={() => {
                                        this.props.refetch?.()
                                        closeModal()
                                    }}
                                    possibleDepartments={possibleDepartments}
                                    forCustomer={forCustomer}
                                    onCompleteOrReopenTasks={() => {
                                        this.props.refetch?.()
                                        refetch()
                                    }}
                                />
                            )}
                        />
                    )
                }}
            </MinimalLinkedTaskItemStatusQuery>
        )
    }

    private getTaskCounts(data?: LinkedTaskItemStatusQueryResponse | undefined) {
        if (!data || !data.linkedTaskItemStatus) {
            return { open: 0, completed: 0, overDue: 0 }
        }

        return {
            open: data.linkedTaskItemStatus.open,
            completed: data.linkedTaskItemStatus.closed,
            overDue: data.linkedTaskItemStatus.overDue,
        }
    }

    private renderWidget(openModal: () => void, loading: boolean, counts: TaskCounts) {
        if (loading) {
            return <Spinner delayed={true} />
        }

        return (
            <div className={this.bem.getClassName()} onClick={openModal}>
                <TaskCounter
                    openAmount={counts.open}
                    closedAmount={counts.completed}
                    overDueAmount={counts.overDue}
                    linkedItem={this.props.linkedItem}
                />
            </div>
        )
    }
}
