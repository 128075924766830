import './CreateNewAssessmentSectionButtonContainer.scss'
import React from 'react'
import { MutationFn } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CreateAssessmentSectionMutation, CreateAssessmentSectionMutationVariables } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { CreateNewAssessmentSectionMutation } from './CreateNewAssessmentSectionMutation'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'

interface Props {
    assessmentId: number
    loading: boolean
    onSuccess: () => void
}

export class CreateNewAssessmentSectionButtonContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CreateNewAssessmentSectionButtonContainer')

    public render() {
        return (
            <CreateNewAssessmentSectionMutation>
                {(mutate, { loading }) => (
                    <Row alignCenter={true} className={this.bem.getClassName()}>
                        <Row fullWidth={true} className={this.bem.getElement('row')} />
                        <Column className={this.bem.getElement('column')}>
                            <Button
                                type={ButtonType.actionLink}
                                loading={loading || this.props.loading}
                                icon={IconType.addCircle}
                                onClick={() => this.handleOnCreate(mutate)}
                            >
                                {localize.translate(
                                    t => t.Customer.Compliance.Assessments.AssessmentSections.createNewSection
                                )}
                            </Button>
                        </Column>
                    </Row>
                )}
            </CreateNewAssessmentSectionMutation>
        )
    }

    private async handleOnCreate(
        mutate: MutationFn<CreateAssessmentSectionMutation, CreateAssessmentSectionMutationVariables>
    ) {
        const { assessmentId, onSuccess } = this.props

        const response = await mutate({ variables: { assessmentId, departmentId: this.context.activeDepartmentId } })

        if (response && response.data && response.data.createAssessmentSection) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            onSuccess()
        }
    }
}
