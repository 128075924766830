import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import gql from 'graphql-tag'
import { MutationFn } from 'react-apollo'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { GQLMutation } from '~/graphql/Mutation'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'

interface Props {
    children: (openModal: () => void) => JSX.Element
}

interface State {
    password: string | null
    passwordConfirm: string | null
    passwordScore: number
}

interface Variables {
    oldPassword?: string | null
    newPassword?: string | null
}

interface Response {
    changePassword: boolean
}

const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword($oldPassword: String, $newPassword: String) {
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }
`

export class ChangePasswordModal extends React.PureComponent<Props, State> {
    public state: State = {
        password: null,
        passwordConfirm: null,
        passwordScore: 0,
    }

    private loc = localize.namespaceTranslate(t => t.User.ChangePasswordModal)

    public render() {
        const { children } = this.props

        return (
            <ModalManager
                render={children}
                renderModal={closeModal => (
                    <GQLMutation<Response, Variables> mutation={CHANGE_PASSWORD_MUTATION}>
                        {(mutate, { loading }) => (
                            <Form onSubmit={this.handleOnSubmit(mutate, closeModal)}>
                                <Modal
                                    requestClose={closeModal}
                                    submitForm={true}
                                    loading={loading}
                                    disabled={!this.canSubmitForm()}
                                    title={this.loc(t => t.title)}
                                    confirmButtonLabel={this.loc(t => t.confirmChange)}
                                >
                                    <ErrorMessage path={`changePassword`} />

                                    <Field label={this.loc(t => t.oldPassword)} forInput={`oldPassword`}>
                                        <Form.Input type={'password'} name={`oldPassword`} />
                                    </Field>
                                    <Field
                                        label={this.loc(t => t.newPassword)}
                                        forInput={`newPassword`}
                                        errorMessage={this.getErrorLabelForPassword() || undefined}
                                    >
                                        <Form.PasswordInput
                                            name={`newPassword`}
                                            onStrengthChange={passwordScore => this.setState({ passwordScore })}
                                            onChange={password => this.setState({ password })}
                                        />
                                    </Field>
                                    <Field
                                        label={this.loc(t => t.newPasswordConfirm)}
                                        forInput={`newPasswordConfirm`}
                                        errorMessage={this.getErrorLabelForPasswordConfirm() || undefined}
                                    >
                                        <Form.Input
                                            type={'password'}
                                            name={`newPasswordConfirm`}
                                            onChange={passwordConfirm => this.setState({ passwordConfirm })}
                                        />
                                    </Field>
                                </Modal>
                            </Form>
                        )}
                    </GQLMutation>
                )}
            />
        )
    }

    private handleOnSubmit =
        (mutate: MutationFn<Response, Variables>, closeModal: Function) => async (formState: FormState) => {
            const response = await mutate({
                variables: {
                    oldPassword: formState.oldPassword,
                    newPassword: formState.newPassword,
                },
            })

            if (response && response.data && response.data.changePassword) {
                notification.success(this.loc(t => t.passwordChangeSuccess))
                closeModal()
            }
        }

    private getErrorLabelForPassword() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 3) {
            return this.loc(t => t.passwordNotStrongEnough)
        }

        return null
    }

    private getErrorLabelForPasswordConfirm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 3) {
            return null
        }

        if (password !== passwordConfirm) {
            return this.loc(t => t.passwordDoesNotMatch)
        }

        return null
    }

    private canSubmitForm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return false
        }

        if (password !== passwordConfirm) {
            return false
        }

        if (passwordScore < 3) {
            return false
        }

        return true
    }
}
