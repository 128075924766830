import './TaskCompleteButton.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'

interface Props {
    isCompleted: boolean
    onClick: () => void
    isDisabled?: boolean
}

export class TaskCompleteButton extends React.Component<React.PropsWithChildren<Props>, {}> {
    private bem = new BEM('TaskCompleteButton', () => ({
        'is-completed': !!this.props.isCompleted,
        'is-disabled': !!this.props.isDisabled,
    }))

    public render() {
        const { isCompleted } = this.props

        return (
            <label className={this.bem.getClassName()}>
                <input type="checkbox" onChange={this.onChangeCheckbox} checked={isCompleted} />
                <Icon type={IconType.taskReopen} />
                <Icon type={IconType.taskCheckmark} />
            </label>
        )
    }

    private onChangeCheckbox = () => {
        const { isDisabled, onClick } = this.props

        if (isDisabled) {
            return
        }

        onClick()
    }
}
