import './ActiveCustomerFrameworks.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerConsumer, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { animations } from '~/animations'
import { CSSTransition } from 'react-transition-group'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    maxVisibleFrameworks: number
}

interface State {}

export class ActiveCustomerFrameworks extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static defaultProps: Partial<Props> = {
        maxVisibleFrameworks: 3,
    }

    private bem = new BEM('ActiveCustomerFrameworks')

    public render() {
        const { className } = this.props

        return (
            <CustomerConsumer>
                {context => {
                    if (!context) {
                        return null
                    }

                    return (
                        <div className={this.bem.getClassName(className)}>
                            {this.renderActiveFrameworks(context)}
                            {this.renderCounter(context)}
                        </div>
                    )
                }}
            </CustomerConsumer>
        )
    }

    private renderActiveFrameworks(context: CustomerContextValue) {
        const { maxVisibleFrameworks } = this.props
        const activeFrameworks = context.profiles.filter(({ isActive }) => isActive)
        const frameworksToShow = activeFrameworks.slice(0, maxVisibleFrameworks)

        return (
            <ol className={this.bem.getElement('active-frameworks')}>
                {frameworksToShow.map((framework, index) => (
                    <CSSTransition
                        in={true}
                        key={framework.id}
                        timeout={225}
                        classNames={animations.popIn}
                        unmountOnExit={false}
                        appear={true}
                    >
                        <li className={this.bem.getElement('framework-icon')}>
                            <Tooltip content={framework.name}>
                                <FrameworkIcon frameworkType={framework.icon} color={framework.color} isDot={true} />
                            </Tooltip>
                        </li>
                    </CSSTransition>
                ))}
            </ol>
        )
    }

    private renderCounter(context: CustomerContextValue) {
        const { maxVisibleFrameworks } = this.props
        const activeFrameworks = context.profiles.filter(({ isActive }) => isActive)
        const counterValue = activeFrameworks.length - maxVisibleFrameworks

        if (counterValue <= 0) {
            return
        }

        return <span className={this.bem.getElement('counter')}>{`+${counterValue}`}</span>
    }
}
