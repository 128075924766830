import './InboxItemActions.scss'
import React from 'react'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { AlertType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CreateTaskModalContainer } from '../Task/CreateTaskModalContainer'
import { InboxItemInfoSummary, InboxSummaryItemType } from './InboxItemInfoSummary'
import { EditInboxItemImpactActionButton } from './EditInboxItemImpactLevel/EditInboxItemImpactActionButton'
import { ArchiveAlertModal } from './ArchiveAlert/ArchiveAlertModal'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize, permissions } from '~/bootstrap'

interface Props {
    alert: InboxItemActionsAlertType
    refetchOverview: () => void
    infoIconOnly?: boolean
}

export type InboxItemActionsAlertType = Alert & InboxSummaryItemType
type Alert = Pick<AlertType, 'id' | '__typename' | 'name' | 'impactLevel'>

export class InboxItemActions extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxItemActions')

    public render() {
        const { infoIconOnly, alert } = this.props

        return (
            <Row alignRight={true} className={this.bem.getClassName()}>
                {!infoIconOnly && (
                    <>
                        {this.renderArchiveButton()}
                        <EditInboxItemImpactActionButton defaultImpact={alert.impactLevel} alertIds={[alert.id]} />
                        {this.renderTaskButton()}
                    </>
                )}
                {this.renderInfoIcon()}
            </Row>
        )
    }

    private renderArchiveButton() {
        const { id, name } = this.props.alert

        if (!permissions.canArchiveInboxItems(this.context.activeDepartmentId)) {
            return null
        }

        return (
            <ModalManager
                renderModal={closeModal => (
                    <ArchiveAlertModal alertId={id} alertName={name} requestClose={closeModal} />
                )}
                render={openModal => (
                    <ActionButton
                        onClick={openModal}
                        icon={IconType.archive}
                        tooltipContent={<Paragraph small>{localize.translate(t => t.Generic.archive)}</Paragraph>}
                        tertiary
                    />
                )}
            />
        )
    }

    private renderTaskButton() {
        const { alert, refetchOverview } = this.props

        if (!permissions.canCreateTasks(this.context.activeDepartmentId)) {
            return null
        }

        return (
            <ModalManager
                renderModal={closeModal => (
                    <CreateTaskModalContainer
                        linkedItem={alert}
                        requestClose={closeModal}
                        onCreated={refetchOverview}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        tooltipContent={<Paragraph small>{localize.translate(t => t.Generic.createTask)}</Paragraph>}
                        onClick={openModal}
                        icon={IconType.taskAdd}
                        tertiary
                    />
                )}
            />
        )
    }

    private renderInfoIcon() {
        return (
            <ActionButton
                tooltipContent={<InboxItemInfoSummary alert={this.props.alert} />}
                icon={IconType.info}
                tertiary
            />
        )
    }
}
