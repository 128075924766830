import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { DepartmentSwitchPopup } from '~/components/Domain/Department/DepartmentSwitchPopup'
import { routes } from '~/views/routes'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { breadcrumbs } from '~/views/breadcrumbs'
import { AssessmentSectionsContainer } from '~/components/Domain/Compliance/Assessments/AssessmentSections/AssessmentSectionsContainer'
import { AssessmentOptionsButton } from '~/components/Domain/Compliance/Assessments/AssessmentOptionsButton'
import { Row } from '~/components/Core/Layout/Row'
import { InlineTaskWidgetContainer } from '~/components/Domain/Task/InlineTaskWidget/InlineTaskWidgetContainer'
import { RiskSeverity } from '~/generated/graphql'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

export const GET_ASSESSMENT_DETAIL = gql`
    query assessment($id: Int!) {
        assessment(id: $id) {
            __typename
            id
            name
            description
            useDistinctBrutoRiskValues
            department {
                id
                name
            }
            risks {
                id
                severityBruto
            }
        }
    }
`

export interface AssessmentDetailVariables {
    id: number
}

export interface AssessmentDetailResponse {
    assessment: AssessmentDetail
}

export interface AssessmentDetail {
    __typename: 'AssessmentType'
    id: number
    name: string
    description: string
    useDistinctBrutoRiskValues: boolean
    department: {
        id: number
        name: string
    }
    risks?: {
        severityBruto?: RiskSeverity
    }[]
}

class AssessmentsDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params

        const parsedId = parseInt(id, 10)

        return (
            <Query<AssessmentDetailResponse, AssessmentDetailVariables>
                query={GET_ASSESSMENT_DETAIL}
                variables={{ id: parsedId }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data || !data.assessment) {
                        return <NoResults />
                    }

                    if (this.context.activeDepartmentId !== data.assessment.department.id) {
                        return (
                            <DepartmentSwitchPopup
                                departmentIdToToggleTo={data.assessment.department.id}
                                departmentNameToToggleTo={data.assessment.department.name}
                                to={routes.customer(this.context.customer.slug).risks.assessments.index}
                            />
                        )
                    }

                    return (
                        <Page>
                            <PageHeader
                                title={data.assessment.name}
                                actionComponent={this.renderAction(data.assessment)}
                                breadCrumbs={[
                                    breadcrumbs.customer(this.context.customer.slug).risks.index,
                                    breadcrumbs.customer(this.context.customer.slug).risks.assessments.index,
                                ]}
                            />
                            <Paragraph preserveLine={true} wrap={true}>
                                {data.assessment.description}
                            </Paragraph>
                            <AssessmentSectionsContainer
                                assessmentId={parsedId}
                                assessmentName={data.assessment.name}
                                showDistinctBrutoRiskValues={data.assessment.useDistinctBrutoRiskValues}
                            />
                        </Page>
                    )
                }}
            </Query>
        )
    }

    private renderAction(assessment: AssessmentDetail) {
        return (
            <Row alignRight={true}>
                <InlineTaskWidgetContainer linkedItem={assessment} />
                <AssessmentOptionsButton assessment={assessment} />
            </Row>
        )
    }
}

export const AssessmentsDetailView = withRouter(AssessmentsDetailViewComponent)
