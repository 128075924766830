import './Attention.scss'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '../../Typography/Paragraph'
import { Row } from '../../Layout/Row'
import { isString } from 'lodash-es'

interface Props {
    className?: ClassValue
    attentionText: string | JSX.Element
    blueBackground?: boolean
    noIcon?: boolean
}

interface State {}

export class Attention extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Attention', () => ({
        blueBackground: this.props.blueBackground,
        noIcon: this.props.noIcon,
    }))

    public render() {
        const { className, attentionText, noIcon } = this.props

        return (
            <Row smallSpacing={true} flexStart={true} className={this.bem.getClassName(className)}>
                {!noIcon && <Icon type={IconType.attention} className={this.bem.getElement('icon')} />}
                {isString(attentionText) ? (
                    <Paragraph className={this.bem.getElement('text')}>{attentionText}</Paragraph>
                ) : (
                    attentionText
                )}
            </Row>
        )
    }
}
