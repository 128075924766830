import './RadarItemContent.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TextLink } from '~/components/Core/Text/TextLink'
import { localize, notification } from '~/bootstrap'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import {
    UnfollowRadarItemMutation,
    UnfollowRadarItemMutationResponse,
    UnfollowRadarItemVariables,
} from '~/components/Domain/Radar/UnfollowRadarItemMutation'
import { MutationFn } from 'react-apollo'
import {
    FollowRadarItemMutation,
    FollowRadarItemMutationResponse,
    FollowRadarItemVariables,
} from '~/components/Domain/Radar/FollowRadarItemMutation'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

export interface RadarItemContentProps {
    className?: ClassValue
    id: number
    to: string
    expectedDate: string
    title: string
    phase?: {
        id: number
        order: number
        name: string
    }
    lastEditorialChangeAt: string
    customerFrameworks: CustomerFramework[]
    unfollowed?: boolean
    userCanUnfollow: boolean
}

export class RadarItemContent extends React.Component<RadarItemContentProps> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RadarItemContent')

    public render() {
        const { to, expectedDate, title, customerFrameworks, lastEditorialChangeAt, phase, userCanUnfollow } =
            this.props

        return (
            <>
                <div className={this.bem.getElement('content')}>
                    <TextLink to={to} className={this.bem.getElement('title')}>
                        {title}
                    </TextLink>
                    <div className={this.bem.getElement('title-icons-wrapper')}>
                        <CustomerFrameworkIconList
                            customerFrameworks={customerFrameworks}
                            className={this.bem.getElement('profile-list')}
                        />
                        {userCanUnfollow && this.renderFollowingButton()}
                    </div>
                </div>
                <div>
                    <div className={this.bem.getElement('meta-data')}>
                        <span>{localize.translate(t => t.Radar.Attributes.expectedDate)} </span>
                        {localize.dateFormat(new Date(expectedDate), { readable: true })}
                    </div>
                    {phase && (
                        <div className={this.bem.getElement('meta-data')}>
                            <span>{localize.translate(t => t.Radar.Attributes.Phase)} </span>
                            {RadarItemContent.translatePhaseByName(phase.name)}
                        </div>
                    )}
                    <Paragraph subtle={true} className={this.bem.getElement('last-change')}>
                        {/* tslint:disable-next-line: jsx-use-translation-function */}
                        {localize.translate(t => t.Radar.Attributes.lastEditorialChange)}{' '}
                        {localize.dateFormat(new Date(lastEditorialChangeAt), { noWeekday: true, readable: true })}
                    </Paragraph>
                </div>
            </>
        )
    }

    public static translatePhaseByName(phaseName: string) {
        switch (phaseName) {
            case 'Voorstel europese regelgeving': {
                return localize.translate(t => t.Customer.RadarView.RadarStages.PROPOSAL_EUROPEAN_REGULATION)
            }
            case 'Definitieve europese regelgeving': {
                return localize.translate(t => t.Customer.RadarView.RadarStages.DEFINITIVE_EUROPEAN_REGULATION)
            }
            case 'Voorstel (implementatie in) NL regelgeving': {
                return localize.translate(t => t.Customer.RadarView.RadarStages.PROPOSAL_DUTCH_REGULATION)
            }
            case 'Definitieve NL regelgeving': {
                return localize.translate(t => t.Customer.RadarView.RadarStages.DEFINITIVE_DUTCH_REGULATION)
            }
            case 'In werking getreden regelgeving': {
                return localize.translate(t => t.Customer.RadarView.RadarStages.REGULATION_IN_FORCE)
            }
            default: {
                return phaseName
            }
        }
    }

    private renderFollowingButton() {
        const { id, unfollowed } = this.props

        if (unfollowed) {
            return (
                <FollowRadarItemMutation radarItemId={id} departmentId={this.context.activeDepartmentId}>
                    {(mutate, { loading }) => {
                        return (
                            <Button
                                type={ButtonType.noStyling}
                                icon={IconType.unfollowed}
                                loading={loading}
                                onClick={this.handleMutate(mutate)}
                                className={this.bem.getElement('following-icon')}
                            />
                        )
                    }}
                </FollowRadarItemMutation>
            )
        }

        return (
            <UnfollowRadarItemMutation radarItemId={id} departmentId={this.context.activeDepartmentId}>
                {(mutate, { loading }) => {
                    return (
                        <Button
                            type={ButtonType.noStyling}
                            icon={IconType.following}
                            loading={loading}
                            onClick={this.handleMutate(mutate)}
                            className={this.bem.getElement('following-icon')}
                        />
                    )
                }}
            </UnfollowRadarItemMutation>
        )
    }

    private handleMutate =
        (
            mutate: MutationFn<
                FollowRadarItemMutationResponse | UnfollowRadarItemMutationResponse,
                FollowRadarItemVariables | UnfollowRadarItemVariables
            >
        ) =>
        async () => {
            const { id, unfollowed } = this.props
            const response = await mutate({
                variables: {
                    radarItemId: id,
                    departmentId: this.context.activeDepartmentId,
                },
            })

            if (response && response.data) {
                notification.success(
                    unfollowed
                        ? localize.translate(t => t.Customer.RadarView.followSuccess)
                        : localize.translate(t => t.Customer.RadarView.unfollowSuccess)
                )
            }
        }
}
