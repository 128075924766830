import './AppLayout.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    hasConsultantHeader?: boolean
    isConsultantLayout?: boolean
}

interface State {}

export class AppLayout extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('AppLayout', () => ({
        'has-consultant-header': this.props.hasConsultantHeader,
        'is-consultant-layout': this.props.isConsultantLayout,
    }))

    public render() {
        const { children, className } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
