import './ControlLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { isPast } from 'date-fns'
import { Icon } from '~/components/Core/Icon/Icon'
import { matchPath } from 'react-router-dom'
import { getIconTypeForControlType } from '~/utils/controls'
import { ControlType, ControlTypeType, ControlVisibility } from '~/generated/graphql'
import { Row } from '~/components/Core/Layout/Row'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Control {
    id: number
    name: string
    type: ControlTypeType
    revisionDate?: string | Date | null
}

interface Props {
    className?: ClassValue
    control: Control | ControlType
    indentLevel?: 1 | 2
    iconOnly?: boolean
    externalLink?: boolean
    noLink?: boolean
}

interface State {}

export class ControlLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ControlLabel')

    public render() {
        const { className } = this.props

        return (
            <Row noSpacing={true} className={this.bem.getClassName(className)}>
                {this.renderChildIcon()}
                {this.renderLabel()}
            </Row>
        )
    }

    private renderChildIcon() {
        const { indentLevel } = this.props

        if (!indentLevel) {
            return
        }

        return <Icon type={IconType.child} className={this.bem.getElement('child-icon')} />
    }

    private renderLabel() {
        const { control, iconOnly, externalLink, noLink } = this.props

        const link = routes.customer(this.context.customer.slug).compliance.controls.view(control.id)
        const match = matchPath(
            {
                path: link,
                caseSensitive: false,
            },
            window.location.pathname
        )

        return (
            <IconLabel
                className={this.bem.getElement('icon-label')}
                iconType={getIconTypeForControlType(control.type)}
                to={match || noLink ? undefined : link}
                externalLink={externalLink}
            >
                <div className={this.bem.getElement('content')}>
                    {this.renderRevisionDateExpiredIcon()}
                    {this.renderSharedIcon()}
                    {iconOnly ? null : control.name}
                </div>
            </IconLabel>
        )
    }

    private renderRevisionDateExpiredIcon() {
        const { revisionDate } = this.props.control

        if (!revisionDate || !isPast(new Date(revisionDate))) {
            return null
        }

        return <Icon className={this.bem.getElement('attention-icon')} type={IconType.attention} />
    }

    private renderSharedIcon() {
        const { control } = this.props

        let text: string | undefined
        const loc = localize.namespaceTranslate(t => t.Control.Shared)

        // Current department is not owner
        if ('department' in control && control.department.id !== this.context.activeDepartmentId) {
            text = loc(t => t.SHARED_BY_OTHER_DEPARTMENT, { afdeling: control.department.name })
        }

        if ('visibility' in control) {
            if (control.visibility === ControlVisibility.allDepartments) {
                text = loc(t => t.SHARED_WITH_ALL_DEPARTMENTS)
            }

            if (
                control.visibility === ControlVisibility.selectionOfDepartments &&
                'visibleToDepartments' in control &&
                control.visibleToDepartments?.length
            ) {
                const sharedWith = control.visibleToDepartments.map(d => d?.name).join(', ')
                text = loc(t => t.SHARED_WITH_SELECTION_OF_DEPARTMENTS, { afdelingen: sharedWith })
            }
        }

        return this.renderShareIconWithTooltip(text)
    }

    private renderShareIconWithTooltip(text?: string): React.ReactNode {
        if (!text) {
            return
        }

        return (
            <Tooltip content={text}>
                <Icon type={IconType.shared} className={this.bem.getElement('shared-icon')} />
            </Tooltip>
        )
    }
}
