import './TableListItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class TableListItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('TableListItem')

    public render() {
        const { children, className } = this.props

        return <li className={this.bem.getClassName(className)}>{children}</li>
    }
}
