import React from 'react'
import { Task } from '../TaskCompleteButtonContainer'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { localize, notification } from '~/bootstrap'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import './TaskCompleteModal.scss'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { CompleteTaskMutation, CompleteTaskMutationResponse, CompleteTaskVariables } from '../CompleteTaskMutation'
import { MutationFn } from 'react-apollo'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { AddLinkedMonitoringReportList } from '../../Monitoring/AddLinkedMonitoringReportList/AddLinkedMonitoringReportList'
import { RepeatableFileInput } from '~/components/Core/DataEntry/RepeatableFileInput/RepeatableFileInput'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { readAndSanitizeMultipleFiles } from '~/utils/fileSanitizer'

interface Props {
    className?: ClassValue
    task: Task
    onComplete?: () => void
    requestClose?: () => void
}

interface State {
    monitoringReportIds: number[]
    files: File[]
    validFileErrorMessage: string | null
}

export class TaskCompleteModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        monitoringReportIds: [],
        files: [],
        validFileErrorMessage: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Task.taskCompleteModal)
    private bem = new BEM('TaskCompleteModal')

    public render() {
        const { task, requestClose } = this.props
        const { validFileErrorMessage } = this.state

        return (
            <CompleteTaskMutation>
                {(completeTask, { loading }) => (
                    <Form onSubmit={this.getSubmitHandler(completeTask)}>
                        <Modal
                            title={this.loc(t => t.title)}
                            submitForm={true}
                            requestClose={requestClose}
                            modalSize={ModalSize.small}
                            actions={requestModalClose => (
                                <Row alignRight={true}>
                                    <Button type={ButtonType.secondary} onClick={requestModalClose}>
                                        {localize.translate(t => t.Generic.cancel)}
                                    </Button>
                                    <Button loading={loading} submit={true}>
                                        {this.loc(t => t.complete)}
                                    </Button>
                                </Row>
                            )}
                        >
                            <Paragraph>
                                {this.loc(t => t.taskPart1)}
                                <span className={this.bem.getElement('bold')}>{task.name}</span>
                                {this.loc(t => t.taskPart2)}
                            </Paragraph>

                            <Field label={this.loc(t => t.motivation)} compact={true}>
                                <Form.Input
                                    type={'textarea'}
                                    name={'description'}
                                    placeholder={this.loc(t => t.motivationPlaceholder)}
                                />
                            </Field>
                            {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}

                            {this.renderAdditionalInputsBasedOnMonitoring()}
                        </Modal>
                    </Form>
                )}
            </CompleteTaskMutation>
        )
    }

    private renderAdditionalInputsBasedOnMonitoring = () => {
        const { task } = this.props

        if (task.forMonitoring) {
            return (
                <Field compact={true} label={this.loc(t => t.monitoringReports)}>
                    <AddLinkedMonitoringReportList
                        onChange={data => {
                            return this.setState({
                                monitoringReportIds: data.map(item => item.id),
                            })
                        }}
                    />
                </Field>
            )
        }

        return <RepeatableFileInput name="files" onChange={files => this.setState({ files })} />
    }

    private getSubmitHandler =
        (mutate: MutationFn<CompleteTaskMutationResponse, CompleteTaskVariables>) => async (formState: FormState) => {
            const { task, onComplete, requestClose } = this.props
            const { monitoringReportIds, files } = this.state
            const extraMotivationFields = task.forMonitoring ? { monitoringReportIds } : { files }
            const isValidFile = files ? await readAndSanitizeMultipleFiles(files) : true

            if (isValidFile) {
                const response = await mutate({
                    variables: {
                        taskId: task.id,
                        motivation: {
                            description: formState.description,
                            ...extraMotivationFields,
                        },
                    },
                })

                if (response && response.data && response.data.completeTask) {
                    notification.success(this.loc(t => t.completed))

                    if (onComplete) {
                        onComplete()
                    }

                    if (requestClose) {
                        requestClose()
                    }
                }
            } else {
                this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
            }
        }
}
