import React from 'react'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { ThemesForSelectDocument } from '~/generated/graphql'

interface Props {
    name?: string
    onChange: (option: SelectOption | null) => void
    selectedOptions?: SelectOption[] | null
    placeholder?: string
    isFilter?: boolean
    externallyCuratedOnly?: boolean
    customerFrameworkIds?: number[]
}

interface State {
    search: string | null
}

interface Theme {
    id: number
    name: string
}

export class ThemeSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: '',
    }

    public render() {
        const { name, onChange, selectedOptions, placeholder, isFilter, externallyCuratedOnly } = this.props
        const { customerFrameworkIds } = this.props
        const { search } = this.state

        return (
            <PaginatableQuery<Theme>
                query={ThemesForSelectDocument}
                variables={{
                    filters: {
                        search: search,
                        departmentId: this.context.activeDepartmentId,
                        externallyCuratedOnly,
                        customerFrameworkIds,
                    },
                    sort: {
                        name: 'ASC',
                    },
                    customerSlug: this.context.customer.slug,
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        // key property is necessary to make sure that
                        // <Form.Select> is fully remounted when loading=false
                        return <Form.Select key="loading" name={'loading'} loading={true} />
                    }

                    const options = data.nodes.map(source => ({ label: source.name, value: source.id }))

                    return (
                        <Form.Select
                            placeholder={placeholder}
                            name={name || 'theme'}
                            defaultValue={selectedOptions || undefined}
                            searchable={true}
                            options={options}
                            onEndReached={fetchMore}
                            clearable={true}
                            loading={loading || loadingMore}
                            onChange={(option: SelectOption) => {
                                onChange(option)
                            }}
                            onSearch={query => this.setState({ search: query })}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PaginatableQuery>
        )
    }
}
