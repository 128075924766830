import './CustomerGroupEditView.scss'

import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerGroupFormFields } from '~/components/Domain/Consultants/CustomerGroup/CustomerGroupFormFields'
import {
    CustomerGroupDocument,
    CustomerGroupQueryVariables,
    CustomerGroupQuery,
    CustomerGroupType,
    DeleteCustomerGroupMutationVariables,
    DeleteCustomerGroupDocument,
    DeleteCustomerGroupMutation,
    EditCustomerGroupMutation,
    EditCustomerGroupMutationVariables,
    EditCustomerGroupDocument,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { breadcrumbs } from '~/views/breadcrumbs'
import { routes } from '~/views/routes'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import slugify from 'slugify'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{ id?: string }> {}

type EditCustomerGroupMutationFN = MutationFn<EditCustomerGroupMutation, EditCustomerGroupMutationVariables>
type DeleteCustomerGroupMutationFN = MutationFn<DeleteCustomerGroupMutation, DeleteCustomerGroupMutationVariables>

class CustomerGroupEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('CustomerGroupEditView')
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CustomerGroupEditView)

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        const pageBreadcrumbs = [breadcrumbs.consultant().customerGroups.index]

        return (
            <PageQuery<CustomerGroupQuery['customerGroup'], CustomerGroupQueryVariables>
                query={CustomerGroupDocument}
                variables={{ id: parseInt(id, 10) }}
            >
                {customerGroup => {
                    if (!customerGroup) {
                        return null
                    }

                    return (
                        <Page className={this.bem.getClassName()}>
                            <PageHeader title={customerGroup.name} breadCrumbs={pageBreadcrumbs} />
                            {this.renderForm(customerGroup)}
                        </Page>
                    )
                }}
            </PageQuery>
        )
    }

    private renderForm(customerGroup: CustomerGroupQuery['customerGroup']) {
        return (
            <GQLMutation<EditCustomerGroupMutation, EditCustomerGroupMutationVariables>
                mutation={EditCustomerGroupDocument}
            >
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleEdit(mutate)}>
                        <Column bigSpacing={true} className={this.bem.getElement('form-container')}>
                            <ErrorMessage path="editCustomerGroup" />
                            <CustomerGroupFormFields customerGroup={customerGroup as CustomerGroupType} />
                            {this.renderAction(customerGroup, loading)}
                        </Column>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private renderAction(customerGroup: CustomerGroupQuery['customerGroup'], loading: boolean) {
        return (
            <Row spaceBetween={true}>
                {this.renderDeleteButton(customerGroup, loading)}
                <Row>
                    <Button type={ButtonType.secondary} disabled={loading} to={routes.consultant.customerGroups.index}>
                        {localize.translate(t => t.Generic.cancel)}
                    </Button>
                    <Button submit={true} loading={loading}>
                        {localize.translate(t => t.Generic.save)}
                    </Button>
                </Row>
            </Row>
        )
    }

    private handleEdit = (mutate: EditCustomerGroupMutationFN) => async (formState: FormState) => {
        const { id } = this.props.match.params
        const { name, sso, createUserOnSSOLogin, ssoLoginURL, ssoLogoutURL, ssoCertificate } = formState

        const response = await mutate({
            variables: {
                customerGroupId: parseInt(id!, 10),
                fields: {
                    name,
                    slug: name ? slugify(name.trim(), { lower: true }) : undefined,
                    ssoLoginEnabled: sso,
                    createUserOnSSOLogin,
                    ssoLoginURL,
                    ssoLogoutURL,
                    ssoCertificate,
                },
            },
        })

        if (response && response.data?.editCustomerGroup) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            this.props.history.push(routes.consultant.customerGroups.detail(id).view)
        }
    }

    private renderDeleteButton(customerGroup: CustomerGroupQuery['customerGroup'], editLoading: boolean) {
        const hasCustomers = !!customerGroup?.customers?.length

        if (hasCustomers) {
            return (
                <Tooltip message={this.loc(t => t.disabledTooltip)}>
                    <Button type={ButtonType.delete} disabled={true}>
                        {this.loc(t => t.deleteButton)}
                    </Button>
                </Tooltip>
            )
        }

        return (
            <GQLMutation<DeleteCustomerGroupMutation, DeleteCustomerGroupMutationVariables>
                mutation={DeleteCustomerGroupDocument}
            >
                {(mutate, { loading }) => (
                    <Button
                        type={ButtonType.delete}
                        loading={loading || editLoading}
                        onClick={this.handleDelete(mutate)}
                    >
                        {this.loc(t => t.deleteButton)}
                    </Button>
                )}
            </GQLMutation>
        )
    }

    private handleDelete = (mutate: DeleteCustomerGroupMutationFN) => async () => {
        const { id } = this.props.match.params

        const response = await mutate({ variables: { customerGroupId: parseInt(id!, 10) } })

        if (response && response.data?.deleteCustomerGroup) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))

            this.props.history.push(routes.consultant.customerGroups.index)
        }
    }
}

export const CustomerGroupEditView = withRouter(CustomerGroupEditViewComponent)
