import React from 'react'
import { ControlsEditFormContainer } from '~/components/Domain/Compliance/Controls/ControlEditForm/ControlEditFormContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { routes } from '~/views/routes'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

class ControlsEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <ControlsEditFormContainer
                controlId={parseInt(id, 10)}
                onEditSuccess={() =>
                    this.props.history.push(routes.customer(this.context.customer.slug).compliance.controls.view(id))
                }
            />
        )
    }
}

export const ControlsEditView = withRouter(ControlsEditViewComponent)
