import './GroupedInboxControlTable.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ColumnOptions, RowData, Table, SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { AlertType, DepartmentAlertDataType, GroupedDepartmentAlertDataType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { InboxControlFilters } from '~/views/Customer/Control/InboxControlOverviewView'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DepartmentAlertsForMonth } from './queries/DepartmentAlertsForMonth'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { InboxControlAlertLabel } from './InboxControlAlertLabel'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { UndoIgnoreInboxControlButtonContainer } from './UndoIgnoreInboxControlButtonContainer'
import { TaskIgnoredForControlTooltip } from '../TaskControl/TaskControlTable/TaskIgnoredForControlTooltip'
import { IgnoreControlAlertModal } from './IgnoreControlAlertModal'
import { CreateControlTaskModal } from '../CreateControlTaskModal/CreateControlTaskModal'
import { TaskOpenClosedCounter } from '../../Task/TaskOpenClosedCounter'
import { TaskControlRowTombstone } from '../TaskControl/TaskControlTable/TaskControlRowTombstone'
import { CSSTransition } from 'react-transition-group'
import { animations } from '~/animations'
import { DepartmentLabelTag } from '../../Department/DepartmentLabelTag/DepartmentLabelTag'
import { InboxGroupTable } from './InboxGroupTable'
import { FetchMoreButton } from '~/components/Core/Pagination/FetchMoreButton'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    year: number
    month: number
    hasNoAlerts: boolean
    paramState: InboxControlFilters
    refetch: () => void
    isCheckboxActive: boolean
    bulkActionIds: string[]
    onCheck: (alertData: DepartmentAlertDataType, checked: boolean) => void
    hideCheckboxAnimation?: boolean
}

interface FetchMoreButtonArgs {
    canFetchMore: boolean
    loadingMore: boolean
    fetchMore: () => void
    totalCount: number
    currentCount: number
}

interface State {
    sortDirection?: { field: string; direction: SortDirection }
}

export class GroupedInboxControlTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        sortDirection: { field: 'publishedAt', direction: 'DESC' },
    }

    private loc = localize.namespaceTranslate(t => t.Customer.InboxControl.InboxControlTable)
    private bem = new BEM('GroupedInboxControlTable', () => ({
        isEmpty: this.props.hasNoAlerts,
    }))

    public render() {
        const { hasNoAlerts } = this.props
        if (hasNoAlerts) {
            return this.renderEmptyTable()
        }

        const { month, year, paramState, hideCheckboxAnimation } = this.props

        return (
            <DepartmentAlertsForMonth month={month} year={year} filters={paramState} sort={this.getSortOption()}>
                {({ data, loading, loadingMore, canFetchMore, fetchMore, refetch }) => {
                    const alerts = data && data.nodes ? data.nodes : []

                    const totalCount = data?.totalCount || 0
                    const currentCount = alerts.length

                    const hasSingleAlert =
                        !!paramState.departmentId || (alerts.length === 1 && alerts[0].departmentDatas?.length === 1)
                    const hasAllSingleDepartment = data?.nodes.every(a => a.departmentDatas?.length === 1)
                    const classNameWithModifier =
                        hasSingleAlert || hasAllSingleDepartment
                            ? new BEM('GroupedInboxControlTable', () => ({
                                  hasSingleAlert,
                                  hasAllSingleDepartment,
                              })).getClassName()
                            : undefined

                    const columns = this.getColumns()
                    const tableData = this.getTableData(alerts)
                    const fetchMoreButtonArgs = { canFetchMore, fetchMore, loadingMore, totalCount, currentCount }

                    return (
                        <>
                            <Table
                                className={this.bem.getClassName(classNameWithModifier)}
                                customLoadingComponent={this.renderTombstones(loading)}
                                hideColumnsWhenEmpty={true}
                                loading={loading}
                                loadingMore={loadingMore}
                                columns={columns}
                                data={tableData}
                                rowAction={a =>
                                    this.renderRowActions(alerts, a.row.id as string, refetch, !!a.row.children)
                                }
                                defaultSortDirection={this.state.sortDirection}
                                onSortDirectionChange={this.handleSortDirectionChange}
                                hideInitialCheckboxAnimation={hideCheckboxAnimation}
                            />
                            {this.renderFetchMoreButton(fetchMoreButtonArgs)}
                        </>
                    )
                }}
            </DepartmentAlertsForMonth>
        )
    }

    private renderEmptyTable() {
        return (
            <Table
                className={this.bem.getClassName()}
                emptyState={<Paragraph subtle={true}>{this.loc(t => t.noAlerts)}</Paragraph>}
                hideColumnsWhenEmpty={true}
                columns={[]}
                data={[]}
            />
        )
    }

    private getSortOption() {
        const { sortDirection } = this.state

        if (!sortDirection) {
            return
        }

        return { [sortDirection.field]: sortDirection.direction }
    }

    private getColumns(): ColumnOptions[] {
        return [
            { field: 'name', headerLabel: this.loc(t => t.name), truncateField: true, sortable: true },
            { field: 'department', headerLabel: localize.translate(t => t.Entities.Department) },
            { field: 'status', headerLabel: this.loc(t => t.status) },
            { field: 'publishedAt', headerLabel: this.loc(t => t.date), sortable: true },
        ]
    }

    private getTableData(data: GroupedDepartmentAlertDataType[]): RowData[] {
        const rowDatas: RowData[] = []
        for (const group of data) {
            const { alert, departmentDatas } = group

            if (!departmentDatas?.length) {
                continue
            }

            if (departmentDatas.length > 1) {
                rowDatas.push(this.getGroupedAlertColumns(alert, departmentDatas))
                continue
            }

            // departmentDatas.length === 1
            rowDatas.push(this.getAlertColumns(alert, departmentDatas[0]))
        }

        return rowDatas
    }

    private renderTombstones(loading: boolean) {
        const { hasNoAlerts } = this.props

        if (hasNoAlerts) {
            return undefined
        }

        return (
            <CSSTransition unmountOnExit={true} in={loading} timeout={150} classNames={animations.fadeIn}>
                <div className={this.bem.getElement('tombstones')}>
                    {[...Array(8)].map((_value, index) => (
                        <TaskControlRowTombstone key={`tombstone-${index}`} isEven={index % 2 === 0} />
                    ))}
                </div>
            </CSSTransition>
        )
    }

    private renderRowActions(
        data: GroupedDepartmentAlertDataType[],
        rowItemId: string,
        refetch: () => void,
        hasChildren: boolean
    ) {
        if (hasChildren) {
            return
        }

        const group = data.find(d => d.departmentDatas?.some(dd => dd.id === rowItemId))
        const alertData = group?.departmentDatas?.find(d => d.id === rowItemId)
        if (!group || !alertData) {
            return
        }

        const { inboxStatus, department } = alertData
        const { id } = group
        const canArchive = permissions.canArchiveInboxItems(department.id)

        if (inboxStatus?.ignoredAt) {
            return (
                <div className={this.bem.getElement('actions')}>
                    <Guard condition={canArchive}>
                        <Tooltip direction={ToolTipDirection.downEnd} message={this.loc(t => t.revertTooltip)}>
                            <UndoIgnoreInboxControlButtonContainer
                                alertId={id}
                                alertDepartmentId={department?.id}
                                onUnarchive={refetch}
                                className={this.bem.getElement('revert-ignore')}
                            />
                        </Tooltip>
                    </Guard>
                    <TaskIgnoredForControlTooltip
                        ignoredAt={inboxStatus.ignoredAt}
                        ignoredBy={inboxStatus.ignoredBy || undefined}
                        ignoredReason={inboxStatus.ignoredReason}
                    />
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('actions')}>
                <div className={this.bem.getElement('hidden-actions')}>
                    <Guard condition={canArchive}>
                        <IgnoreControlAlertModal alertDatas={[alertData]} onIgnore={refetch}>
                            {openModal => (
                                <Button
                                    onClick={openModal}
                                    type={ButtonType.secondary}
                                    icon={IconType.ignoreControlTask}
                                />
                            )}
                        </IgnoreControlAlertModal>
                    </Guard>
                    <Guard condition={permissions.canCreateTasks(department.id)}>
                        <ModalManager
                            renderModal={closeModal => (
                                <CreateControlTaskModal
                                    requestClose={closeModal}
                                    alertDatas={[alertData]}
                                    onCreate={refetch}
                                />
                            )}
                            render={openModal => <Button onClick={openModal} icon={IconType.createControlTask} />}
                        />
                    </Guard>
                </div>
            </div>
        )
    }

    private handleSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({ sortDirection: { field, direction } })
    }

    private renderFetchMoreButton(args: FetchMoreButtonArgs) {
        const { canFetchMore, fetchMore, totalCount, currentCount, loadingMore } = args

        if (!canFetchMore) {
            return
        }

        const nextItemsCount = this.getLoadMoreCount(currentCount, totalCount)
        const label = this.loc(t => t.showMore, { nextItemsCount, totalCount })

        return <FetchMoreButton label={label} fetchMore={fetchMore} loadingMore={loadingMore} />
    }

    private getGroupedAlertColumns(alert: AlertType, departmentAlertDatas: DepartmentAlertDataType[]) {
        const { isCheckboxActive, bulkActionIds, onCheck, hideCheckboxAnimation, refetch } = this.props
        const { id, publishedAt } = alert
        return {
            id,
            columns: {
                name: this.renderName(alert),
                department: (
                    <DepartmentLabelTag
                        departments={departmentAlertDatas.map(d => d.department)}
                        className={this.bem.getElement('department')}
                    />
                ),
                status: this.getGroupStatus(departmentAlertDatas),
                publishedAt: publishedAt && (
                    <DateFormat date={new Date(publishedAt)} readable={true} noWeekday={true} />
                ),
            },
            children: () => (
                <InboxGroupTable
                    isCheckboxActive={isCheckboxActive}
                    bulkActionIds={bulkActionIds}
                    onCheck={onCheck}
                    hideCheckboxAnimation={hideCheckboxAnimation}
                    departmentAlerts={departmentAlertDatas}
                    refetch={refetch}
                    hidePublishedDate={true}
                />
            ),
        }
    }

    private getAlertColumns(alert: AlertType, departmentAlertData: DepartmentAlertDataType): RowData {
        const { onCheck, bulkActionIds, isCheckboxActive } = this.props
        const { id, department, inboxStatus } = departmentAlertData
        const { publishedAt } = alert
        const hideArchive = !permissions.canArchiveInboxItems(department.id)

        return {
            id: id,
            columns: {
                name: this.renderName(alert, departmentAlertData),
                department: department?.name && (
                    <DepartmentLabelTag departments={[department]} className={this.bem.getElement('department')} />
                ),
                status: this.getStatus(departmentAlertData),
                publishedAt: publishedAt && (
                    <DateFormat date={new Date(publishedAt)} readable={true} noWeekday={true} />
                ),
            },
            onCheckChange:
                hideArchive || inboxStatus?.archivedAt
                    ? undefined
                    : (_, checked) => onCheck(departmentAlertData, checked),
            isCheckboxChecked: bulkActionIds.includes(id),
            isCheckboxActive: isCheckboxActive,
        }
    }

    private renderName(alert: AlertType, departmentAlertData?: DepartmentAlertDataType) {
        const { id, name, isPublishedFromCustomerNews } = alert

        return (
            <InboxControlAlertLabel
                className={this.bem.getElement('label')}
                alertId={id}
                alertName={name}
                alertDepartment={departmentAlertData?.department || undefined}
                impactLevel={departmentAlertData?.impactLevel?.impactLevel}
                hideIcon={!departmentAlertData}
                isPublishedFromCustomerNews={isPublishedFromCustomerNews}
            />
        )
    }

    private getGroupStatus(alertDatas: DepartmentAlertDataType[]) {
        const isArchived = alertDatas.every(a => !!a.inboxStatus?.archivedAt)
        if (isArchived) {
            return <Paragraph>{localize.translate(t => t.Generic.archived)}</Paragraph>
        }

        const hasLinkedTask = alertDatas.some(a => a.hasLinkedTask)
        const hasArchived = alertDatas.some(a => !!a.inboxStatus?.archivedAt)
        if (hasLinkedTask || hasArchived) {
            return <Paragraph>{localize.translate(t => t.Generic.multiple)}</Paragraph>
        }

        return <Paragraph>{localize.translate(t => t.Generic.new)}</Paragraph>
    }

    private getStatus(alertData: DepartmentAlertDataType) {
        const { inboxStatus, hasLinkedTask, openTaskCount, overdueTaskCount, completedTaskCount } = alertData

        if (hasLinkedTask) {
            return (
                <TaskOpenClosedCounter
                    openAmount={openTaskCount}
                    overDueAmount={overdueTaskCount}
                    closedAmount={completedTaskCount}
                />
            )
        }

        if (inboxStatus?.archivedAt) {
            return <Paragraph>{localize.translate(t => t.Generic.archived)}</Paragraph>
        }

        return <Paragraph>{localize.translate(t => t.Generic.new)}</Paragraph>
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 8) {
            return 8
        }

        return leftOverNodes
    }
}
