import './AiSummaryContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { BEM, ClassValue } from '~/services/BEMService'
import { AiSummaryVoteContainer } from './AiSummaryVoteContainer'
import { GeneratedSummaryParentTypeEnum, GeneratedSummaryStatusEnum } from '~/generated/graphql'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { GeneratedSummaryQuery, GeneratedSummaryQueryResultType } from './GeneratedSummaryQuery'

interface Props {
    parentId: number
    parentType: GeneratedSummaryParentTypeEnum
    className?: ClassValue
}

interface State {
    collapsed: boolean
}

export class AiSummaryContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        collapsed: false,
    }

    private bem = new BEM('AiSummaryContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.AiSummary)

    public render() {
        const { parentId, parentType, className } = this.props

        return (
            <GeneratedSummaryQuery variables={{ parentId, parentType }}>
                {generatedSummary => (
                    <Column className={this.bem.getClassName(className)}>
                        {generatedSummary.status !== GeneratedSummaryStatusEnum.done
                            ? this.renderLoading()
                            : this.renderContent(generatedSummary)}
                    </Column>
                )}
            </GeneratedSummaryQuery>
        )
    }

    private renderLoading() {
        return (
            <>
                {this.renderTitle(this.loc(t => t.loadingTitle))}
                {this.renderSummary(
                    <Row smallSpacing>
                        <div>
                            <Spinner />
                        </div>
                        <Paragraph emphasis>{this.loc(t => t.loading)}</Paragraph>
                    </Row>
                )}
            </>
        )
    }

    private renderContent(generatedSummary: GeneratedSummaryQueryResultType) {
        const { collapsed } = this.state

        const summaryText = (
            <Paragraph className={this.bem.getElement('summary', () => ({ collapsed }))}>
                {generatedSummary.summary}
            </Paragraph>
        )

        return (
            <>
                {this.renderTitle(this.loc(t => t.title))}
                <Column smallSpacing={true}>
                    <Column>
                        {this.renderSummary(summaryText)}
                        <Row spaceBetween>
                            {this.renderCollapseButton()}
                            <AiSummaryVoteContainer generatedSummary={generatedSummary} />
                        </Row>
                    </Column>
                </Column>
            </>
        )
    }

    private renderSummary(content: React.ReactNode) {
        return (
            <div className={this.bem.getElement('summary-container')}>
                <div className={this.bem.getElement('side-highlight')} />
                {content}
            </div>
        )
    }

    private renderTitle(title: string) {
        return (
            <Row extraSmallSpacing={true}>
                <Paragraph>{title}</Paragraph>
                <Paragraph bold={true}>{this.loc(t => t.rulerAi)}</Paragraph>
                <Icon large={true} type={IconType.ai} />
            </Row>
        )
    }

    private renderCollapseButton() {
        let label: string
        let icon: IconType

        if (this.state.collapsed) {
            label = this.loc(t => t.undoCollapse)
            icon = IconType.arrowDown
        } else {
            label = this.loc(t => t.collapse)
            icon = IconType.arrowUp
        }

        return (
            <Button type={ButtonType.noStyling} onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
                <Row smallSpacing={true}>
                    <Paragraph blue>{label}</Paragraph>
                    <Icon type={icon} className={this.bem.getElement('collapse-icon')} />
                </Row>
            </Button>
        )
    }
}
