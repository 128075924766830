import './ReleaseUpdateButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Row } from '~/components/Core/Layout/Row'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    count: number
    onClick: () => void
    onClose?: () => void
}

interface State {}

export class ReleaseUpdateButton extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ReleaseUpdateButton')

    public render() {
        const { className, count, onClick, onClose } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Button type={ButtonType.noStyling} onClick={onClick}>
                    <Row spaceBetween={true} smallSpacing={true}>
                        <div className={this.bem.getElement('count')}>{count}</div>
                        <p className={this.bem.getElement('text')}>
                            {localize.translate(t => t.Customer.UpdatesModal.buttonTitle)}
                        </p>
                    </Row>
                </Button>
                <Button
                    className={this.bem.getElement('close')}
                    onClick={onClose}
                    icon={IconType.close}
                    type={ButtonType.noStyling}
                />
            </div>
        )
    }
}
