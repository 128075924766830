import './scss/index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'

import { onInitialLoad, userClient } from '~/bootstrap'
import { UserProvider } from '~/components/Providers/UserProvider'
import { RootView } from '~/views/RootView'
import { apolloClient } from '~/services/ApolloService'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

declare global {
    interface Window {
        ENVIRONMENT: {
            NAME: string
            DEPLOY_VERSION: string
            DEPLOY_NAMESPACE: string
            SERVER_URL: string
        }
    }
}

const App: React.FunctionComponent = () => {
    const ApolloProviderComponent = ApolloProvider as unknown as React.FC<any>

    return (
        <ApolloProviderComponent client={apolloClient}>
            <UserProvider userClient={userClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<RootView />} />
                    </Routes>
                </BrowserRouter>
            </UserProvider>
        </ApolloProviderComponent>
    )
}

onInitialLoad(() => {
    const loader = document.getElementById('splash-loader') as HTMLDivElement
    if (loader && loader.parentNode) {
        loader.parentNode.removeChild(loader)
    }
    ReactDOM.render(<App />, document.getElementById('react-root') as HTMLElement)
})
