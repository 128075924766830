import './CustomerFrameworksBlockList.scss'
import React from 'react'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM } from '~/services/BEMService'
import { FrameworkIcon } from '../Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerFrameworkType } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    customerFrameworks: Pick<CustomerFrameworkType, 'id' | 'icon' | 'color' | 'name'>[]
}

export class CustomerFrameworksBlockList extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerFrameworksBlockList')

    public render() {
        const { customerFrameworks } = this.props

        return (
            <Row wrap fullWidth smallSpacing className={this.bem.getClassName()}>
                {customerFrameworks.map(cf => (
                    <Tooltip
                        content={
                            <Paragraph small emphasis>
                                {cf.name}
                            </Paragraph>
                        }
                    >
                        <Row smallSpacing className={this.bem.getElement('framework')}>
                            <FrameworkIcon
                                frameworkType={cf.icon}
                                color={cf.color}
                                isInactive={this.context.activeProfiles.includes(cf.id) === false}
                                isExtraSmall
                            />
                            <Paragraph className={this.bem.getElement('name')}>{cf.name}</Paragraph>
                        </Row>
                    </Tooltip>
                ))}
            </Row>
        )
    }
}
