import './RadarTopicLinkedItemsContainer.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'
import { LinkList, LinkListItem } from '~/components/Core/Layout/LinkList'

interface Props {
    items: LinkListItem[]
    title: string
}

export interface LinkedItemData {
    name: string
    to: string
}

export class RadarTopicLinkedItemsContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('RadarTopicLinkedItemsContainer')

    public render() {
        const { items, title } = this.props

        return <LinkList items={items} className={this.bem.getClassName()} title={title} />
    }
}
