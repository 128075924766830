import './HierarchyListItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    indent: 0 | 1 | 2
}

interface State {}

export class HierarchyListItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('HierarchyListItem', () => ({
        'indent-1': this.props.indent === 1,
        'indent-2': this.props.indent === 2,
    }))

    public render() {
        const { className, children } = this.props

        return <li className={this.bem.getClassName(className)}>{children}</li>
    }
}
