import './ControlTopicSelectionTable.scss'
import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { MultiDepthExpandableTable } from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTable'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { IconType } from '~/components/Core/Icon/IconType'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { TopicPreviewModal } from '~/components/Domain/Topic/TopicPreviewModal'
import { ThemeType, TopicType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'

interface Props {
    themesWithTopics: ThemeWithTopics[]
    expandedIds: number[]
    onChange: (checkedTopics: Record<number, number[]>) => void
    disabled?: boolean
}

export type ThemeWithTopics = Theme & { topics: Topic[] }
type Theme = Pick<ThemeType, 'id' | 'name'>
type Topic = Pick<TopicType, 'id' | 'name'>

interface State {
    // themeId -> topicIds
    checkedTopics: Record<number, number[]>
}

export class ControlTopicSelectionTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ControlTopicSelectionTable')
    public state: State = {
        checkedTopics: {},
    }

    private tableRef = React.createRef<MultiDepthExpandableTable>()

    public render() {
        return (
            <div className={this.bem.getClassName()}>
                <MultiDepthExpandableTable
                    ref={this.tableRef}
                    className={this.bem.getElement('table')}
                    rowItems={this.getRowItems()}
                    maxDepth={1}
                    expandedRowsIds={this.props.expandedIds}
                />
            </div>
        )
    }

    public scrollToRow(id: number) {
        this.tableRef.current?.scrollToRow(id)
    }

    private getRowItems(): any[] {
        const { themesWithTopics } = this.props

        return themesWithTopics.map(theme => {
            return {
                id: theme.id,
                name: theme.name,
                yIndex: 0,
                render: () => this.renderThemeRow(theme),
                children: theme.topics?.map((topic: TopicType) => ({
                    id: topic.id,
                    name: topic.name,
                    yIndex: 1,
                    render: () => this.renderTopicRow(theme.id, topic),
                })),
            }
        })
    }

    private renderThemeRow(theme: ThemeWithTopics) {
        return (
            <Row spaceBetween className={this.bem.getElement('theme-row')}>
                <Tooltip message={theme.name || ''} className={this.bem.getElement('label-container')}>
                    <Paragraph bold truncateEllipsis>
                        {theme.name}
                    </Paragraph>
                </Tooltip>
                {this.renderThemeCheckboxes(theme)}
            </Row>
        )
    }

    private renderTopicRow(themeId: number, topic: Topic) {
        return (
            <Row spaceBetween className={this.bem.getElement('topic-row')}>
                {this.renderTopicName(topic.id, topic.name)}
                {this.renderTopicCheckboxes(themeId, topic)}
            </Row>
        )
    }

    private renderTopicName(id: number, name: string) {
        return (
            <TopicPreviewModal topicId={id}>
                {open => (
                    <Button type={ButtonType.noStyling} onClick={open}>
                        <Paragraph bold truncateEllipsis>
                            {name}
                        </Paragraph>
                        <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                    </Button>
                )}
            </TopicPreviewModal>
        )
    }

    private renderThemeCheckboxes(theme: ThemeWithTopics) {
        const checkedTopicCount = this.state.checkedTopics[theme.id]?.length
        const checked = checkedTopicCount === theme.topics.length
        const indeterminate = !checked && checkedTopicCount > 0

        return (
            <Checkbox
                key={theme.id}
                large
                name={`theme-${theme.id}`}
                checked={checked}
                indeterminate={indeterminate}
                onChange={checked => this.handleThemeCheck(theme, checked)}
                disabled={this.props.disabled}
            />
        )
    }

    private renderTopicCheckboxes(themeId: number, topic: Topic) {
        const checked = this.state.checkedTopics[themeId]?.includes(topic.id)

        return (
            <Checkbox
                key={`${themeId}-${topic.id}-${checked}`}
                large
                name={`${themeId}-${topic.id}`}
                checked={checked}
                onChange={checked => this.handleTopicCheck(topic.id, themeId, checked)}
                disabled={this.props.disabled}
            />
        )
    }

    private handleThemeCheck(themeWithTopics: ThemeWithTopics, checked: boolean) {
        const { onChange } = this.props
        const { checkedTopics } = this.state

        if (checked) {
            checkedTopics[themeWithTopics.id] = themeWithTopics.topics.map(t => t.id)

            return this.setState({ checkedTopics }, () => {
                onChange(checkedTopics)
                this.forceUpdate()
            })
        }

        if (checkedTopics[themeWithTopics.id]) {
            delete checkedTopics[themeWithTopics.id]

            return this.setState({ checkedTopics }, () => {
                onChange(checkedTopics)
                this.forceUpdate()
            })
        }
    }

    private handleTopicCheck(id: number, themeId: number, checked: boolean) {
        const { onChange } = this.props
        const { checkedTopics } = this.state

        if (checked) {
            if (!checkedTopics[themeId]) {
                checkedTopics[themeId] = [id]
            } else if (!checkedTopics[themeId].includes(id)) {
                checkedTopics[themeId].push(id)
            } else {
                return
            }

            return this.setState({ checkedTopics }, () => {
                onChange(checkedTopics)
                this.forceUpdate()
            })
        }

        if (checkedTopics[themeId]?.includes(id)) {
            checkedTopics[themeId] = checkedTopics[themeId].filter(t => t !== id)

            return this.setState({ checkedTopics }, () => {
                onChange(checkedTopics)
                this.forceUpdate()
            })
        }
    }
}
