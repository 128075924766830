import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    ArchiveMonitoringReportsDocument,
    ArchiveMonitoringReportsMutation,
    ArchiveMonitoringReportsMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'

interface Props {
    children: (mutation: ArchiveMonitoringReportsMutationFN, options: MutationResult) => JSX.Element
}

export type ArchiveMonitoringReportsMutationFN = MutationFn<
    ArchiveMonitoringReportsMutation,
    ArchiveMonitoringReportsMutationVariables
>

export const ArchiveMonitoringReports: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<ArchiveMonitoringReportsMutation, ArchiveMonitoringReportsMutationVariables>
        mutation={ArchiveMonitoringReportsDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
