import './AssessmentEventCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Icon } from '~/components/Core/Icon/Icon'

interface Props {
    className?: ClassValue
    icon?: IconType
    description: string
    time: string
    user: string
}

interface State {}

export class AssessmentEventCard extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('AssessmentEventCard')

    public render() {
        const { className, description, time, user, icon } = this.props

        return (
            <Card className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('event')}>
                    {icon && <Icon type={icon} className={this.bem.getElement('icon')} />}
                    <Paragraph>{description}</Paragraph>
                </div>
                <div className={this.bem.getElement('metadata')}>
                    <span className={this.bem.getElement('time')}>{time}</span>
                    <span className={this.bem.getElement('user')}>{user}</span>
                </div>
            </Card>
        )
    }
}
