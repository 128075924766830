import React from 'react'

import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn, Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { LIST_RISK_TYPES } from '~/components/Domain/Compliance/Risks/RiskTypesTable'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'

interface Props {
    requestClose: () => void
    riskTypeId: number
}

const EDIT_RISK_TYPE = gql`
    mutation editRiskType($riskTypeId: Int!, $name: String) {
        editRiskType(riskTypeId: $riskTypeId, name: $name) {
            id
            name
        }
    }
`

const DELETE_RISK_TYPE = gql`
    mutation deleteRiskType($riskTypeId: Int!) {
        deleteRiskType(riskTypeId: $riskTypeId)
    }
`

const RISK_TYPE_QUERY = gql`
    query riskType($riskTypeId: Int!) {
        riskType(riskTypeId: $riskTypeId) {
            id
            name
        }
    }
`

export class EditRiskTypeModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { requestClose, riskTypeId } = this.props

        return (
            <Query<any> query={RISK_TYPE_QUERY} variables={{ riskTypeId }}>
                {({ data, loading: loadingQuery }) => (
                    <GQLMutation
                        mutation={DELETE_RISK_TYPE}
                        variables={{ riskTypeId }}
                        refetchQueries={[
                            {
                                query: LIST_RISK_TYPES,
                            },
                        ]}
                    >
                        {(deleteMutate, { loading: deleteLoading }) => (
                            <GQLMutation
                                mutation={EDIT_RISK_TYPE}
                                refetchQueries={[
                                    {
                                        query: LIST_RISK_TYPES,
                                    },
                                ]}
                            >
                                {(editMutate, { loading }) => (
                                    <Form
                                        isCompact={true}
                                        onSubmit={this.handleSubmit(editMutate)}
                                        defaultState={{ name: data && data.riskType ? data.riskType.name : undefined }}
                                    >
                                        <Modal
                                            title={localize.translate(
                                                t => t.Customer.Compliance.Risks.RiskTypeModal.title
                                            )}
                                            requestClose={requestClose}
                                            loading={loading}
                                            actions={closeModal => (
                                                <Row spaceBetween={true}>
                                                    <ConfirmModal
                                                        onConfirm={this.handleSubmitDelete(deleteMutate)}
                                                        loading={deleteLoading}
                                                    >
                                                        {openConfirm => (
                                                            <Button
                                                                onClick={openConfirm}
                                                                type={ButtonType.secondary}
                                                                danger={true}
                                                            >
                                                                {localize.translate(t => t.Generic.delete)}
                                                            </Button>
                                                        )}
                                                    </ConfirmModal>
                                                    <Row alignRight={true}>
                                                        <Button type={ButtonType.tertiary} onClick={closeModal}>
                                                            {localize.translate(t => t.Generic.cancel)}
                                                        </Button>
                                                        <Button loading={loading} submit={true}>
                                                            {localize.translate(t => t.Generic.save)}
                                                        </Button>
                                                    </Row>
                                                </Row>
                                            )}
                                        >
                                            <ErrorMessage path={'editRiskType'} />
                                            {loadingQuery ? (
                                                <Spinner />
                                            ) : (
                                                <Field
                                                    label={localize.translate(
                                                        t => t.Customer.Compliance.Risks.RiskTypeModal.name
                                                    )}
                                                    forInput="name"
                                                >
                                                    <Form.Input
                                                        name={'name'}
                                                        defaultValue={
                                                            data && data.riskType ? data.riskType.name : undefined
                                                        }
                                                    />
                                                </Field>
                                            )}
                                        </Modal>
                                    </Form>
                                )}
                            </GQLMutation>
                        )}
                    </GQLMutation>
                )}
            </Query>
        )
    }

    private handleSubmit = (mutate: MutationFn) => async (state: FormState) => {
        const { requestClose, riskTypeId } = this.props

        const response = await mutate({
            variables: {
                name: state.name,
                riskTypeId,
            },
        })

        if (response && response.data && response.data.editRiskType) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            requestClose()
        }
    }

    private handleSubmitDelete = (mutate: MutationFn) => async () => {
        const { requestClose } = this.props

        const response = await mutate()

        if (response && response.data && response.data.deleteRiskType) {
            notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            requestClose()
        }
    }
}
