import './Button.scss'

import React from 'react'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { NavLink } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import ClipboardJS from 'clipboard'
import { localize, notification } from '~/bootstrap'

interface Props {
    className?: string
    type?: ButtonType
    icon?: IconType
    iconRight?: IconType
    disabled?: boolean
    loading?: boolean
    onClick?: () => void
    to?: string | LocationDescriptor
    href?: string
    vertical?: boolean
    external?: boolean
    submit?: boolean
    form?: string
    rounded?: boolean
    largeRounded?: boolean
    danger?: boolean
    ariaLabel?: string
    tabIndex?: number
    stopClickPropagation?: boolean
    preventDefault?: boolean
    copyValueOnClick?: string
}

export enum ButtonType {
    secondary = 'secondary',
    tertiary = 'tertiary',
    actionLink = 'actionLink',
    itemLink = 'itemLink',
    tableLink = 'tableLink',
    subtleItemLink = 'subtleItemLink',
    noStyling = 'not-styled',
    subtle = 'subtle',
    inputLike = 'input-like',
    subtleSecondary = 'subtle-secondary',
    delete = 'delete',
    subtleIcon = 'subtleIcon',
}

interface Modifiers {
    clicked: boolean
    disabled: boolean
    loading: boolean
}

export class Button extends React.Component<React.PropsWithChildren<Props>> {
    private button = React.createRef<any>()

    private clipboard: ClipboardJS | undefined

    private bem = new BEM<Modifiers>('Button', () => ({
        [`is-${this.props.type}`]: !!this.props.type,
        'is-disabled': this.props.disabled,
        'is-loading': this.props.loading,
        'is-rounded': this.props.rounded,
        'is-large-rounded': this.props.largeRounded,
        'is-danger': this.props.danger,
        'is-icon-button': !this.props.children,
        'is-vertical': this.props.vertical,
    }))

    public componentDidMount() {
        if (this.props.copyValueOnClick && this.button.current) {
            this.clipboard = new ClipboardJS(this.button.current)
            this.clipboard.on('success', () => {
                notification.success(localize.translate(t => t.Generic.copySuccess))
            })
            this.clipboard.on('error', e => {
                notification.error(localize.translate(t => t.Generic.copyFailed))
            })
        }
    }

    public componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy()
        }
    }

    public render() {
        const { to, href, disabled } = this.props

        // Anchor tags cannot be "disabled". So if disabled is true and the component is a link
        // render a disabled button
        if ((to || href) && disabled) {
            return this.renderButton()
        }

        if (to) {
            return this.renderNavLink()
        }

        if (href) {
            return this.renderLink()
        }

        return this.renderButton()
    }

    public focus() {
        if (!this.button.current) {
            return
        }

        this.button.current.focus()
    }

    private renderButton() {
        const {
            children,
            icon,
            iconRight,
            loading,
            disabled,
            submit,
            className,
            form,
            ariaLabel,
            tabIndex,
            copyValueOnClick,
        } = this.props

        return (
            <button
                type={submit ? 'submit' : form ? 'submit' : 'button'}
                className={this.bem.getClassName(className)}
                disabled={disabled ? disabled : loading}
                onClick={this.handleOnClick}
                ref={this.button}
                form={form}
                aria-label={ariaLabel}
                tabIndex={tabIndex}
                data-clipboard-text={copyValueOnClick}
            >
                <span className={this.bem.getElement('inner')}>
                    {icon && <Icon type={icon} className={this.bem.getElement('icon')} />}
                    {children}
                    {iconRight && <Icon type={iconRight} className={this.bem.getElement('icon-right')} />}
                </span>
                {loading && <Spinner className={this.bem.getElement('spinner')} />}
            </button>
        )
    }

    private renderNavLink() {
        const { children, icon, loading, className, to, external } = this.props

        if (!to) {
            return
        }

        return (
            <NavLink
                className={this.bem.getClassName(className)}
                to={to}
                onClick={this.handleOnClick}
                target={external ? '_blank' : undefined}
            >
                <span className={this.bem.getElement('inner')}>
                    {icon && <Icon type={icon} className={this.bem.getElement('icon')} />}
                    <span className={this.bem.getElement('label')}>{children}</span>
                </span>
                {loading && <Spinner className={this.bem.getElement('spinner')} />}
            </NavLink>
        )
    }

    private renderLink() {
        const { children, icon, loading, className, href, external, copyValueOnClick } = this.props

        if (!href) {
            return
        }

        return (
            <a
                className={this.bem.getClassName(className)}
                href={href}
                onClick={this.handleOnClick}
                ref={this.button}
                target={external ? '_blank' : undefined}
                data-clipboard-text={copyValueOnClick}
            >
                <span className={this.bem.getElement('inner')}>
                    {icon && <Icon type={icon} className={this.bem.getElement('icon')} />}
                    <span className={this.bem.getElement('label')}>{children}</span>
                </span>
                {loading && <Spinner className={this.bem.getElement('spinner')} />}
            </a>
        )
    }

    private handleOnClick = (event: React.MouseEvent) => {
        const { onClick, disabled, stopClickPropagation, preventDefault } = this.props

        if (disabled) {
            return
        }

        if (stopClickPropagation && event.stopPropagation) {
            event.stopPropagation()
        }

        if (preventDefault && event.preventDefault) {
            event.preventDefault()
        }

        if (onClick) {
            onClick()
        }
    }
}
