import './MonitoringReportTopicSelectionHeader.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DepartmentType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    departments: Pick<DepartmentType, 'id' | 'name'>[]
    shownDepartmentCount: number
    currentPage: number
    onClickNext: () => void
    onClickPrevious: () => void
}

export class MonitoringReportTopicSelectionHeader extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('MonitoringReportTopicSelectionHeader')

    public render() {
        const { onClickPrevious, onClickNext, currentPage, shownDepartmentCount, departments } = this.props

        const startIndex = currentPage * shownDepartmentCount
        const endIndex = startIndex + shownDepartmentCount
        const departmentsToShow = departments.slice(startIndex, endIndex)

        return (
            <Row noSpacing fullWidth className={this.bem.getClassName()}>
                <div className={this.bem.getElement('spacer')} />
                <Row noSpacing spaceBetween fullWidth className={this.bem.getElement('header-container')}>
                    <Paragraph bold>{localize.translate(t => t.Settings.Attributes.name)}</Paragraph>
                    <Row noSpacing className={this.bem.getElement('departments-container')}>
                        {currentPage !== 0 && (
                            <Row className={this.bem.getElement('left-button-container')}>
                                {this.renderButton(onClickPrevious, IconType.backArrow)}
                            </Row>
                        )}
                        {departmentsToShow.map(this.renderDepartment)}
                    </Row>
                </Row>
                {endIndex < departments.length && (
                    <Row className={this.bem.getElement('right-button-container')}>
                        {this.renderButton(onClickNext, IconType.nextArrow)}
                    </Row>
                )}
            </Row>
        )
    }

    private renderButton(onClick: () => void, type: IconType) {
        return (
            <Button
                className={this.bem.getElement('button')}
                onClick={onClick}
                icon={type}
                type={ButtonType.secondary}
            />
        )
    }

    private renderDepartment = (department: Props['departments'][0]) => {
        return (
            <Tooltip key={department.id} message={department.name}>
                <Row className={this.bem.getElement('department')}>
                    <Paragraph truncateEllipsis bold>
                        {department.name}
                    </Paragraph>
                </Row>
            </Tooltip>
        )
    }
}
