import './InternalAlertDepartmentLabelTagContainer.scss'

import React from 'react'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Row } from '~/components/Core/Layout/Row'
import { InternalAlertStatusState, InternalAlertStatusType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    internalAlertStatuses: InternalAlertStatus[]
}

type InternalAlertStatus = Pick<InternalAlertStatusType, 'id' | 'state'> & {
    department: {
        id: number
        name: string
    }
}

export class InternalAlertDepartmentLabelTagContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('InternalAlertDepartmentLabelTagContainer')

    public render() {
        return (
            <Row smallSpacing className={this.bem.getClassName()}>
                {this.renderStatuses()}
            </Row>
        )
    }

    private renderStatuses() {
        const sortedStatuses = this.getSortedStatuses()

        if (sortedStatuses.length < 5) {
            return sortedStatuses.map(status => this.renderStatus(status))
        }

        const statusesToShow = sortedStatuses.splice(0, 3)
        const tooltipTitle = localize.translate(t => t.Customer.Inbox.departments, {
            count: sortedStatuses.length,
        })

        return (
            <>
                {statusesToShow.map(status => this.renderStatus(status))}
                <Tooltip
                    className={this.bem.getElement('all-departments-tooltip')}
                    content={
                        <Row className={this.bem.getElement('tooltip-content')}>
                            {sortedStatuses.map(s => this.renderStatus(s))}
                        </Row>
                    }
                >
                    <LabelTag subtle={true} label={tooltipTitle} />
                </Tooltip>
            </>
        )
    }

    private renderStatus(status: InternalAlertStatus) {
        return (
            <LabelTag
                key={status.department.id}
                label={status.department.name}
                className={this.bem.getElement('tag', () => ({
                    [`${status.state}`]: true,
                }))}
            />
        )
    }

    // new/returned -> accepted -> archived
    private getSortedStatuses() {
        return [...this.props.internalAlertStatuses].sort((a, b) => {
            if (a.state === b.state) {
                return a.department.name.localeCompare(b.department.name)
            }

            if (a.state === InternalAlertStatusState.archived) {
                return 1
            }

            if (b.state === InternalAlertStatusState.archived) {
                return -1
            }

            if (a.state === InternalAlertStatusState.accepted) {
                return 1
            }

            if (b.state === InternalAlertStatusState.accepted) {
                return -1
            }

            return a.department.name.localeCompare(b.department.name)
        })
    }
}
