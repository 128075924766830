import './EmployeeItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    role: string | JSX.Element
    status: JSX.Element | string
    email: string
    name: React.ReactNode
}

interface State {}

export class EmployeeItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('EmployeeItem')

    public render() {
        const { email, role, name: to, status, className } = this.props
        const wrappedRole = <span className={this.bem.getElement('role')}>{role}</span>

        return (
            <div className={this.bem.getClassName(className)}>
                <h3 className={this.bem.getElement('title')}>
                    <Paragraph>
                        {to} {wrappedRole}
                    </Paragraph>
                </h3>
                <Row spaceBetween={true}>
                    <Paragraph>{email}</Paragraph>
                    {status}
                </Row>
            </div>
        )
    }
}
