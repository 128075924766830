import React from 'react'

import './AssessmentMatrixLayout.scss'

import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { BEM, ClassValue } from '~/services/BEMService'
import { LinkedNormsCard } from '../../LinkedCompliance/LinkedNormsCard'
import { EditRiskModal } from '../../Risks/EditRiskModal/EditRiskModal'
import { AssessmentAddNormModal } from '../AssessmentAddNormModal'
import { AssessmentEditNormModal } from '../AssessmentEditNormModal'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AssessmentSectionType, ControlType, RiskControlType, RiskStatus, RiskType } from '~/generated/graphql'
import {
    VariableMatrix,
    VariableMatrixColumn,
    VariableMatrixItem,
} from '~/components/Core/DataDisplay/VariableMatrix/VariableMatrix'
import { AssessmentLinkedNormsBlockList } from '../../LinkedCompliance/AssessmentLinkedNormsBlockList'
import { AddThenEditRiskModal } from '../../Risks/AddThenEditRiskModal'
import { LinkedRiskCard } from '../../LinkedCompliance/LinkedRiskCard'
import { AssessmentAddControlModal } from '../AssessmentAddControlModal'
import { LinkedControlCard } from '../../LinkedCompliance/LinkedControlCard'
import { getIconTypeForControlType } from '~/utils/controls'
import { AssessmentViewControlModal } from '../AssessmentViewControlModal'
import { isNumber } from 'lodash-es'

interface Props {
    className?: ClassValue
    assessmentId: number
    assessmentSection: AssessmentSectionType
    showDistinctBrutoRiskValues: boolean
}

interface State {
    hasNorms: boolean
    hasRisks: boolean
    hasControls: boolean
}

export interface GroupedNorms {
    id: number
    name: string
    themeId: number
    topics: {
        normId: number
        topicId: number
        name: string
        hasExpiredLawArticles: boolean
        selectedCount: number
        totalCount: number
    }[]
}

export class AssessmentMatrixLayout extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        hasNorms: false,
        hasRisks: false,
        hasControls: false,
    }

    private bem = new BEM('AssessmentMatrixLayout', () => ({
        'has-four-columns': this.props.showDistinctBrutoRiskValues,
    }))

    public render() {
        const { className, assessmentSection } = this.props
        const groupedNorms: GroupedNorms[] = this.groupByTheme(assessmentSection)
        this.setDefaultState(assessmentSection)

        return (
            <Row spaceBetween={true} stretchChildren={true} className={this.bem.getClassName(className)}>
                <VariableMatrix columns={this.getColumns(groupedNorms, assessmentSection)} linesWidth={24} />
            </Row>
        )
    }

    private setDefaultState(assessmentSection: AssessmentSectionType) {
        if (assessmentSection.norms?.length) {
            this.setState({ hasNorms: true })
        }

        if (assessmentSection.risks && assessmentSection.risks.length) {
            this.setState({
                hasRisks: true,
            })
        }

        if (assessmentSection.controls && assessmentSection.controls.length) {
            this.setState({ hasControls: true })
        }
    }

    private getColumns(groupedNorms: GroupedNorms[], assessmentSection: AssessmentSectionType) {
        const normItem = this.getNormItem(groupedNorms, assessmentSection)

        const NormsColumn: VariableMatrixColumn = {
            name: 'norms',
            title: localize.translate(t => t.Customer.Compliance.Assessments.Overview.norms),
            renderEmptyState: () => this.renderNormsEmptyState(groupedNorms, assessmentSection.id),
            renderAddButton: () => this.renderNormsAddButton(groupedNorms, assessmentSection.id),
            items: normItem ? [normItem] : [],
        }

        const ControlColumn: VariableMatrixColumn = {
            name: 'controls',
            title: localize.translate(t => t.Customer.Compliance.Assessments.Overview.controls),
            renderEmptyState: () => this.renderControlsEmptyState(assessmentSection.id),
            renderAddButton: () => this.renderControlAddButton(assessmentSection.id),
            items: this.getControlItems(assessmentSection),
        }

        if (this.props.showDistinctBrutoRiskValues) {
            const BrutoRiskColumn = this.getBrutoRiskColumn(assessmentSection)
            const NettoRiskColumn = this.getNettoRiskColumn(assessmentSection)

            return [NormsColumn, BrutoRiskColumn, ControlColumn, NettoRiskColumn]
        }

        const RiskColumn = this.getIndistinctRiskColumn(assessmentSection)

        return [NormsColumn, RiskColumn, ControlColumn]
    }

    private getIndistinctRiskColumn(assessmentSection: AssessmentSectionType): VariableMatrixColumn {
        return {
            name: 'risks',
            title: localize.translate(t => t.Customer.Compliance.Assessments.Overview.risks),
            renderEmptyState: () => this.renderRisksEmptyState(assessmentSection.id),
            renderAddButton: () => this.renderRiskAddButton(assessmentSection.id),
            items: this.getRiskItems(assessmentSection),
        }
    }

    private getBrutoRiskColumn(assessmentSection: AssessmentSectionType): VariableMatrixColumn {
        return {
            name: 'brutoRisico',
            title: localize.translate(t => t.Customer.Compliance.Assessments.Overview.brutoRisk),
            renderEmptyState: () => this.renderRisksEmptyState(assessmentSection.id, true),
            renderAddButton: () => this.renderRiskAddButton(assessmentSection.id, true),
            items: this.getRiskItems(assessmentSection, true),
        }
    }

    private getNettoRiskColumn(assessmentSection: AssessmentSectionType): VariableMatrixColumn {
        return {
            name: 'nettoRisico',
            title: localize.translate(t => t.Customer.Compliance.Assessments.Overview.nettoRisk),
            renderEmptyState: () => this.renderRisksEmptyState(assessmentSection.id),
            renderAddButton: () => this.renderRiskAddButton(assessmentSection.id),
            items: this.getRiskItems(assessmentSection),
        }
    }

    private renderNormsAddButton(groupedData: GroupedNorms[], assessmentSectionId: number) {
        const { assessmentId } = this.props

        return (
            <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                <Row alignCenter={true}>
                    <ModalManager
                        forDrawerModal={true}
                        render={openModal => (
                            <Button
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                className={this.bem.getElement('add-button')}
                                onClick={openModal}
                            />
                        )}
                        renderModal={closeModal => (
                            <AssessmentAddNormModal
                                selectedNorms={groupedData}
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                onClose={closeModal}
                            />
                        )}
                    />
                </Row>
            </Guard>
        )
    }

    private renderNormsEmptyState(groupedData: GroupedNorms[], assessmentSectionId: number) {
        const { assessmentId } = this.props

        return (
            <div className={this.bem.getElement('empty')}>
                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <ModalManager
                        forDrawerModal={true}
                        render={openModal => (
                            <Button
                                className={this.bem.getElement('add-button')}
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                onClick={openModal}
                            >
                                {localize.translate(t => t.Customer.Compliance.Assessments.Overview.addNorms)}
                            </Button>
                        )}
                        renderModal={closeModal => (
                            <AssessmentAddNormModal
                                selectedNorms={groupedData}
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                onClose={closeModal}
                            />
                        )}
                    />
                </Guard>
                <Guard condition={!permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <Button className={this.bem.getElement('add-button')} type={ButtonType.actionLink} disabled={true}>
                        {localize.translate(t => t.Customer.Compliance.Assessments.Overview.noNorms)}
                    </Button>
                </Guard>
            </div>
        )
    }

    private getNormItem(
        groupedNorms: GroupedNorms[],
        assessmentSection: AssessmentSectionType
    ): VariableMatrixItem | undefined {
        if (!groupedNorms.length) {
            return
        }

        const { assessmentId } = this.props

        return {
            name: 'themes',
            id: 1,
            renderContent: () => (
                <AssessmentLinkedNormsBlockList className={this.bem.getElement('list')}>
                    {groupedNorms.map(theme => (
                        <LinkedNormsCard
                            key={`${theme.name}-${theme.id}`}
                            theme={theme}
                            renderEdit={() => (
                                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                                    <ModalManager
                                        forDrawerModal={true}
                                        render={openModal => (
                                            <Button onClick={openModal} type={ButtonType.noStyling}>
                                                <Icon type={IconType.edit} />
                                            </Button>
                                        )}
                                        renderModal={closeModal => (
                                            <AssessmentEditNormModal
                                                themeId={theme.themeId}
                                                assessmentId={assessmentId}
                                                assessmentSectionId={assessmentSection.id}
                                                onClose={closeModal}
                                                selectedTopics={theme.topics.map(topic => ({
                                                    normId: topic.normId,
                                                    topicId: topic.topicId,
                                                    isPartialTopic: topic.selectedCount !== topic.totalCount,
                                                }))}
                                            />
                                        )}
                                    />
                                </Guard>
                            )}
                        />
                    ))}
                </AssessmentLinkedNormsBlockList>
            ),
            targetIds: assessmentSection.risks ? assessmentSection.risks.map(risk => risk.id) : [],
        }
    }

    private renderRisksEmptyState = (assessmentSectionId: number, isForBruto?: boolean) => {
        const { assessmentId, showDistinctBrutoRiskValues } = this.props

        return (
            <div className={this.bem.getElement('empty')}>
                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <ModalManager
                        render={openModal => (
                            <Button
                                className={this.bem.getElement('add-button')}
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                onClick={openModal}
                            >
                                {localize.translate(t => t.Customer.Compliance.Assessments.Overview.addRisk)}
                            </Button>
                        )}
                        renderModal={requestClose => {
                            return (
                                <AddThenEditRiskModal
                                    assessmentId={assessmentId}
                                    assessmentSectionId={assessmentSectionId}
                                    showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                    requestClose={requestClose}
                                    isForBruto={isForBruto}
                                />
                            )
                        }}
                    />
                </Guard>
                <Guard condition={!permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <Button className={this.bem.getElement('add-button')} type={ButtonType.actionLink} disabled={true}>
                        {localize.translate(t => t.Customer.Compliance.Assessments.Overview.noRisks)}
                    </Button>
                </Guard>
            </div>
        )
    }

    private renderRiskAddButton = (assessmentSectionId: number, isForBruto?: boolean) => {
        const { assessmentId, showDistinctBrutoRiskValues } = this.props

        return (
            <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                <Row alignCenter={true}>
                    <ModalManager
                        render={openModal => (
                            <Button
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                className={this.bem.getElement('add-button')}
                                onClick={openModal}
                            />
                        )}
                        renderModal={requestClose => (
                            <AddThenEditRiskModal
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                requestClose={requestClose}
                                isForBruto={isForBruto}
                            />
                        )}
                    />
                </Row>
            </Guard>
        )
    }

    private getRiskItems(assessmentSection: AssessmentSectionType, forBrutoRisks?: boolean): VariableMatrixItem[] {
        const risks = assessmentSection.risks

        if (!risks || risks.length === 0) {
            return []
        }

        const { assessmentId, showDistinctBrutoRiskValues } = this.props

        return risks.map((risk, index) => ({
            name: risk.name,
            id: risk.id,
            renderContent: () => {
                const severity = forBrutoRisks && risk.severityBruto ? risk.severityBruto : risk.severity

                return (
                    <ModalManager
                        key={`${risk.id}-${index}`}
                        forDrawerModal={true}
                        render={openModal => (
                            <LinkedRiskCard
                                cardOptions={{
                                    name: risk.name || '',
                                    riskStatus: risk.status || undefined,
                                    severity: severity || undefined,
                                }}
                                textOverflow={true}
                                onClick={openModal}
                                hideAttentionIcon={this.shouldHideAttentionIcon(risk, forBrutoRisks)}
                            />
                        )}
                        renderModal={requestClose => (
                            <EditRiskModal
                                key={`LinkedRiskCard-${index}`}
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSection.id}
                                showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                riskId={risk.id}
                                requestClose={requestClose}
                                isForBruto={forBrutoRisks}
                            />
                        )}
                    />
                )
            },
            targetIds: this.getLinkedControlIds(risk.linkedControls),
        }))
    }

    private shouldHideAttentionIcon(risk: RiskType, forBrutoRisks?: boolean) {
        if (forBrutoRisks) {
            if (risk.status === RiskStatus.brutoSeverityNotSet) {
                return false
            }

            return true
        }

        if (risk.status === RiskStatus.noControlsWhileNettoSeverityIsSet) {
            return false
        }

        return true
    }

    private getLinkedControlIds(linkedControls: RiskControlType[] | null | undefined): number[] {
        if (!linkedControls || !linkedControls.length) {
            return []
        }

        const controlIds: number[] = []
        linkedControls.forEach(({ control }) => {
            if (isNumber(control?.id)) {
                controlIds.push(control?.id!)
            }
        })

        return controlIds
    }

    private getLinkedRisks(control: ControlType, risks: RiskType[]): RiskControlType[] {
        return risks.reduce((linkedRisks, risk) => {
            const linkedControls = risk.linkedControls || []

            return [
                ...linkedRisks,
                ...linkedControls
                    .filter(linkedControl => {
                        return linkedControl.control?.id === control.id
                    })
                    .map(linkedControl => ({
                        ...linkedControl,
                        risk: risk,
                    })),
            ]
        }, [])
    }

    private getLinkedRiskIds(linkedRisks: RiskControlType[]) {
        if (!linkedRisks || !linkedRisks.length) {
            return []
        }

        const riskIds: number[] = []
        linkedRisks.forEach(({ risk }) => {
            if (risk && isNumber(risk.id)) {
                riskIds.push(risk.id)
            }
        })

        return riskIds
    }

    private renderControlsEmptyState = (assessmentSectionId: number) => {
        const { assessmentId } = this.props

        return (
            <div className={this.bem.getElement('empty')}>
                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <ModalManager
                        forDrawerModal={true}
                        render={openModal => (
                            <Button
                                className={this.bem.getElement('add-button')}
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                onClick={openModal}
                            >
                                {localize.translate(t => t.Customer.Compliance.Assessments.Overview.addControl)}
                            </Button>
                        )}
                        renderModal={closeModal => (
                            <AssessmentAddControlModal
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                onClose={closeModal}
                            />
                        )}
                    />
                </Guard>
                <Guard condition={!permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <Button className={this.bem.getElement('add-button')} type={ButtonType.actionLink} disabled={true}>
                        {localize.translate(t => t.Customer.Compliance.Assessments.Overview.noControls)}
                    </Button>
                </Guard>
            </div>
        )
    }

    private renderControlAddButton = (assessmentSectionId: number) => {
        const { assessmentId } = this.props
        return (
            <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                <Row alignCenter={true}>
                    <ModalManager
                        forDrawerModal={true}
                        render={openModal => (
                            <Button
                                icon={IconType.add}
                                type={ButtonType.actionLink}
                                className={this.bem.getElement('add-button')}
                                onClick={openModal}
                            />
                        )}
                        renderModal={closeModal => (
                            <AssessmentAddControlModal
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                onClose={closeModal}
                            />
                        )}
                    />
                </Row>
            </Guard>
        )
    }

    private getControlItems(assessmentSection: AssessmentSectionType): VariableMatrixItem[] {
        const controls: VariableMatrixItem[] = []

        if (!assessmentSection.controls) {
            return []
        }

        const { assessmentId, showDistinctBrutoRiskValues } = this.props

        assessmentSection.controls.forEach(control => {
            if (!control.type) {
                return
            }

            const linkedRisks = this.getLinkedRisks(control, assessmentSection.risks || [])

            controls.push({
                id: control.id,
                name: control.name,
                targetIds: this.getLinkedRiskIds(linkedRisks),
                renderContent: () => (
                    <ModalManager
                        render={openModal => (
                            <LinkedControlCard
                                icon={getIconTypeForControlType(control.type!)}
                                label={control.name}
                                revisionDate={control.revisionDate || null}
                                className={this.bem.getElement('list')}
                                onClick={openModal}
                                textOverflow={true}
                            />
                        )}
                        renderModal={requestCloseControlModal => (
                            <ModalManager<number>
                                forDrawerModal={true}
                                render={openRiskModal => (
                                    <AssessmentViewControlModal
                                        controlId={control.id}
                                        assessmentId={assessmentId}
                                        assessmentSectionId={assessmentSection.id}
                                        requestClose={requestCloseControlModal}
                                        linkedRisks={linkedRisks}
                                        onClickRisk={riskId => {
                                            /**
                                             * TECHNICAL DEBT
                                             * Ideally we would close the control modal first.
                                             * But since the ModalManager does not support opening a modal
                                             * right after closing one, we are not closing the control modal
                                             * yet. Instead, this will be done when the risk modal is closed.
                                             */
                                            // requestCloseControlModal()

                                            openRiskModal(riskId)
                                        }}
                                    />
                                )}
                                renderModal={(requestCloseRiskModal, riskId) => (
                                    <EditRiskModal
                                        assessmentId={assessmentId}
                                        assessmentSectionId={assessmentSection.id}
                                        showDistinctBrutoRiskValues={showDistinctBrutoRiskValues}
                                        riskId={riskId}
                                        requestClose={() => {
                                            requestCloseRiskModal()
                                            requestCloseControlModal()
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                ),
            })
        })

        return controls
    }

    private groupByTheme(assessmentSection: AssessmentSectionType): GroupedNorms[] {
        const groupedData: GroupedNorms[] = []

        if (!assessmentSection.norms || !assessmentSection.norms.length) {
            return groupedData
        }

        assessmentSection.norms.forEach(({ norm, selectedCount, totalCount }) => {
            if (!norm || !norm.topic) {
                return
            }

            let group = groupedData.find(group => group.name === norm.topic?.theme.name)

            if (!group) {
                group = {
                    themeId: norm.topic.theme.id,
                    id: norm.id,
                    name: norm.topic.theme.name ? norm.topic.theme.name : '',
                    topics: [],
                }
                groupedData.push(group)
            }

            group.topics.push({
                normId: norm.id,
                topicId: norm.topic.id,
                name: norm.topic.name,
                hasExpiredLawArticles: norm.topic.expiredLawArticleIds.length > 0,
                selectedCount: selectedCount,
                totalCount: totalCount,
            })
        })

        return groupedData
    }
}
