import React from 'react'
import { MutationFn } from 'react-apollo'
import { fileService, localize, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    CreateDepartmentExportDocument,
    CreateDepartmentExportMutation,
    DepartmentType,
    CreateDepartmentExportMutationVariables,
} from '~/generated/graphql'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'
import { CreateDepartmentModal } from './DepartmentModals/CreateDepartmentModal'

interface Props {
    departments: DepartmentType[]
    customerFrameworks: CustomerFrameworkWithFramework[]
    onEdit: () => void
}

export class DepartmentsOverviewActionButtons extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview)

    public render() {
        const canEdit = permissions.isAllowedToEditDepartments()
        const canExport = permissions.isAllowedToExportDepartments()

        return (
            <Row>
                <Guard condition={canEdit}>{this.renderEditButton()}</Guard>
                <Guard condition={canExport}>{this.renderExportButton()}</Guard>
                <Guard condition={canEdit}>{this.renderCreateButton()}</Guard>
            </Row>
        )
    }

    private renderEditButton() {
        return (
            <ActionButton
                icon={IconType.edit}
                onClick={this.props.onEdit}
                tooltipContent={
                    <Paragraph small emphasis>
                        {localize.translate(t => t.Generic.edit)}
                    </Paragraph>
                }
            />
        )
    }

    private renderExportButton() {
        return (
            <GQLMutation<CreateDepartmentExportMutation, CreateDepartmentExportMutationVariables>
                mutation={CreateDepartmentExportDocument}
            >
                {(mutate, { loading }) => (
                    <ActionButton
                        icon={IconType.export}
                        loading={loading}
                        onClick={this.handleExport(mutate)}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {localize.translate(t => t.Generic.exportToExcel)}
                            </Paragraph>
                        }
                    />
                )}
            </GQLMutation>
        )
    }

    private renderCreateButton() {
        return (
            <ModalManager
                renderModal={closeModal => <CreateDepartmentModal closeModal={closeModal} />}
                render={openModal => (
                    <ActionButton
                        icon={IconType.addSmall}
                        onClick={openModal}
                        tooltipContent={
                            <Paragraph emphasis small>
                                {this.loc(t => t.newDepartment)}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }

    private handleExport = (mutate: MutationFn<CreateDepartmentExportMutation>) => async () => {
        const response = await mutate()

        if (response && response.data && response.data.createDepartmentExport) {
            const { token, filename } = response.data.createDepartmentExport
            const url = fileService.createFileUrlFromToken(token, filename)
            window.open(url, '_blank')
        }
    }
}
