import React from 'react'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { BEM, ClassValue } from '~/services/BEMService'
import './CustomerFrameworkAmountIndicator.scss'
import { CustomerFrameworkList } from './CustomerFrameworkList'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'

interface Props {
    className?: ClassValue
    customerFrameworks: CustomerFramework[]
}

interface State {}

export class CustomerFrameworkAmountIndicator extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('CustomerFrameworkAmountIndicator')

    public render() {
        const { className, customerFrameworks } = this.props

        if (!customerFrameworks) {
            return null
        }

        return (
            <Tooltip
                content={
                    <CustomerFrameworkList
                        className={this.bem.getElement('tooltip-content')}
                        customerFrameworks={customerFrameworks}
                    />
                }
            >
                <div className={this.bem.getClassName(className)}>
                    <LabelTag
                        label={localize.translate(t => t.Generic.profilesAmount, {
                            amount: customerFrameworks.length,
                            smart_count: customerFrameworks.length,
                        })}
                        subtle={true}
                    />
                </div>
            </Tooltip>
        )
    }
}
