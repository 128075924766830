import './SelectableLawArticlesTable.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import {
    ArticleBreadCrumbsArticle,
    ArticleTitleBreadCrumbs,
} from '~/components/Domain/Compliance/Norms/ArticleTitleBreadCrumbs'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { ExpiredArticleInfoBlock } from '~/components/Domain/Assessement/ExpiredArticleInfoBlock'
import { LawArticleType } from '~/generated/graphql'

interface GroupedLawArticle {
    title: string
    rows: LawArticleType[]
    order: number
}

interface Props {
    className?: ClassValue
    linkedLawArticles: LawArticleType[]
    onToggleArticle?: (abstractLawArticleId: number, allIds: number[]) => void
    checkedArticleIds: number[]
    allChecked?: boolean
}

interface State {}

export class SelectableLawArticlesTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static defaultProps: Partial<Props> = {
        checkedArticleIds: [],
    }

    private bem = new BEM('SelectableLawArticlesTable')

    public render() {
        const { className, linkedLawArticles } = this.props
        const groupedByLawGroup = this.groupArticleByLawGroup(linkedLawArticles)
        const tableData = this.transformGroupsToTableStructure(groupedByLawGroup)

        return (
            <table className={this.bem.getClassName(className)}>
                <tbody>{tableData}</tbody>
            </table>
        )
    }

    private transformGroupsToTableStructure(groups: GroupedLawArticle[]) {
        const { checkedArticleIds, allChecked } = this.props

        return groups.map((group, index) => {
            return (
                <React.Fragment key={`${index}-${group.title}`}>
                    <tr className={this.bem.getElement('title')}>
                        <td className={this.bem.getElement('padding-title')}>{group.title}</td>
                    </tr>

                    {group.rows.map((article, i) => {
                        const isChecked = allChecked || checkedArticleIds.includes(article.abstractLawArticleId)

                        return (
                            <tr key={`article-${i}-${article.id}`} className={this.bem.getElement('border-bottom')}>
                                <td className={this.bem.getElement('padding')}>
                                    <Row key={article.id} smallSpacing={true} spaceBetween={true}>
                                        <Row alignCenter={true} smallSpacing={true}>
                                            <ArticleTitleBreadCrumbs article={article as ArticleBreadCrumbsArticle} />
                                            <DateFormat
                                                date={new Date(article.updatedAt)}
                                                readable={true}
                                                noWeekday={true}
                                            />
                                        </Row>
                                        <Row alignRight={true} smallSpacing={true}>
                                            <div className={this.bem.getElement('icon')}>
                                                {this.renderTypeIcon(article)}
                                            </div>
                                            <Checkbox
                                                name={`abstractLawArticleIds-${article.id}`}
                                                value={article.abstractLawArticleId}
                                                checked={isChecked}
                                                onChange={this.toggleSelectedArticle(article.abstractLawArticleId)}
                                            />
                                        </Row>
                                    </Row>
                                    {article.expired && (
                                        <ExpiredArticleInfoBlock expiredAt={new Date(article.expired.expiredAt)} />
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </React.Fragment>
            )
        })
    }

    private groupArticleByLawGroup(articles: LawArticleType[]) {
        const groupedData: GroupedLawArticle[] = []

        for (const article of articles) {
            let group = groupedData.find(group => group.title === article.lawGroup.name)

            if (!group) {
                group = {
                    title: article.lawGroup.name,
                    order: article.lawGroup.order,
                    rows: [],
                }

                groupedData.push(group)
            }

            group.rows.push(article)
        }

        return groupedData.sort((a, b) => a.order - b.order)
    }

    private toggleSelectedArticle = (abstractLawArticleId: number) => () => {
        const { onToggleArticle, linkedLawArticles } = this.props
        const ids = linkedLawArticles.map(({ abstractLawArticleId }) => abstractLawArticleId)

        if (onToggleArticle) {
            onToggleArticle(abstractLawArticleId, ids)
        }
    }

    private renderTypeIcon(article: LawArticleType) {
        const hasAssessment = article.assessments.length

        if (hasAssessment) {
            return <Icon type={IconType.assessment} count={hasAssessment > 1 ? hasAssessment : undefined} />
        }

        return
    }
}
