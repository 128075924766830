import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { CreateFeed, CreateFeedMutationFN } from '../mutations/CreateFeed'
import { CustomerFeedFormFields, CustomerFeedStatusOption } from '../CustomerFeedFormFields'
import { getCustomerFeedSettingsForType } from '../customerFeedTypeSettingHelpers'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    onRequestClose: () => void
}

export class CreateFeedModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds)

    public render() {
        const { onRequestClose } = this.props

        return (
            <CreateFeed>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleOnSubmit(mutate)}>
                        <Modal
                            title={this.loc(t => t.createModalTitle)}
                            requestClose={onRequestClose}
                            submitForm={true}
                            loading={loading}
                            confirmButtonLabel={this.loc(t => t.createModalSave)}
                        >
                            <ErrorMessage path="createCustomerSubscribedToFeed" />
                            <CustomerFeedFormFields />
                        </Modal>
                    </Form>
                )}
            </CreateFeed>
        )
    }

    private handleOnSubmit = (mutate: CreateFeedMutationFN) => async (formState: FormState) => {
        const { onRequestClose } = this.props

        const { customer, frequency, frequencyType, recipientEmails, startDate, time, type, status } = formState

        const isActive = status?.value !== CustomerFeedStatusOption.disabled
        const parsedFrequency = frequency ? parseInt(frequency, 10) : undefined
        if (startDate && time) {
            startDate.setHours(time.hours)
            startDate.setMinutes(time.minutes)
            startDate.setSeconds(0)
            startDate.setMilliseconds(0)
        }

        const settings = type ? getCustomerFeedSettingsForType(type.value, formState) : undefined

        const response = await mutate({
            variables: {
                isActive,
                customerId: customer?.value,
                type: type?.value,
                settings,
                frequency: parsedFrequency,
                frequencyType: frequencyType?.value,
                startDate,
                recipientEmails,
            },
        })

        if (response && response.data && response.data.createCustomerSubscribedToFeed) {
            notification.success(this.loc(t => t.createSuccessToast))
            onRequestClose()
        }
    }
}
