import React from 'react'
import { NotFound } from '~/components/Chrome/NotFound/NotFound'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}
interface State {}

export const CoverNotFoundView = withRouter(
    class CoverNotFoundView extends React.PureComponent<React.PropsWithChildren<Props>, State> {
        public render() {
            return <NotFound />
        }
    }
)
