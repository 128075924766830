import { EventEmitter } from 'events'
import { NotificationSeverity } from '~/components/Core/Feedback/Notifications/Notifications'
import { IconType } from '~/components/Core/Icon/IconType'

export type NotificationEventCallback = (payload: NotificationEventPayload) => void
export interface NotificationEventPayload {
    type: NotificationSeverity
    message: string
    icon?: IconType
    onUndo?: () => void
}

export class NotificationService {
    private eventEmitter = new EventEmitter()

    private event = Symbol('notification')

    public info(message: string, icon?: IconType) {
        this.eventEmitter.emit(this.event, { type: NotificationSeverity.info, message, icon }, [])
    }

    public warning(message: string, icon?: IconType) {
        this.eventEmitter.emit(this.event, { type: NotificationSeverity.warning, message, icon }, [])
    }

    public success(message: string, icon?: IconType) {
        this.eventEmitter.emit(this.event, { type: NotificationSeverity.success, message, icon }, [])
    }

    public successWithUndo(message: string, onUndo: () => void, icon?: IconType) {
        this.eventEmitter.emit(this.event, { type: NotificationSeverity.successWithUndo, message, onUndo, icon }, [])
    }

    public error(message: string, icon?: IconType) {
        this.eventEmitter.emit(this.event, { type: NotificationSeverity.error, message, icon }, [])
    }

    public subscribe(callback: NotificationEventCallback) {
        this.eventEmitter.addListener(this.event, callback)
    }

    public unsubscribe(callback: NotificationEventCallback) {
        this.eventEmitter.removeListener(this.event, callback)
    }
}
