import './ItemCustomerInfoSummary.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerFrameworkType, DepartmentType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { CustomerFrameworkList } from '../CustomerFramework/CustomerFrameworkList'

interface Props {
    item: CustomerItemType
    metadata?: React.ReactNode[]
}

export interface CustomerItemType {
    customerFrameworks: CustomerFramework[]
    departments?: Department[]
}

type Department = Pick<DepartmentType, 'id' | 'name'>
type CustomerFramework = Pick<CustomerFrameworkType, 'id' | 'icon' | 'color' | 'name'>

export class ItemCustomerInfoSummary extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ItemCustomerInfoSummary')

    public render() {
        const { metadata, item } = this.props

        return (
            <Column className={this.bem.getClassName()}>
                {!!item.departments?.length && this.renderDepartments(item.departments)}
                {this.renderFrameworks()}
                {!!metadata?.length && metadata.map(this.renderMetadata)}
            </Column>
        )
    }

    private renderDepartments(departments: Department[]) {
        return (
            <>
                <Row noSpacing={true} className={this.bem.getElement('departments')} flexStart={true}>
                    <Paragraph small={true} className={this.bem.getElement('label')}>
                        {localize.translate(t => t.Settings.Attributes.departments)}:
                    </Paragraph>
                    <Row wrap={true}>
                        {departments.map(d => (
                            <LabelTag key={d.id} label={d.name} className={this.bem.getElement('department')} />
                        ))}
                    </Row>
                </Row>
                <hr />
            </>
        )
    }

    private renderFrameworks() {
        return (
            <Row noSpacing={true} className={this.bem.getElement('frameworks')} flexStart={true}>
                <Paragraph small={true} className={this.bem.getElement('label')}>
                    {localize.translate(t => t.Settings.Attributes.profiles)}:
                </Paragraph>
                <CustomerFrameworkList smallIcons={true} customerFrameworks={this.props.item.customerFrameworks} />
            </Row>
        )
    }

    private renderMetadata(node: React.ReactNode, i: number) {
        return (
            <React.Fragment key={i}>
                <hr />
                {node}
            </React.Fragment>
        )
    }
}
