import './ArticleRenderer.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { TextLink } from '~/components/Core/Text/TextLink'

interface Props {
    className?: ClassValue
    content: string
    regulationId?: string
    lawLabel?: string
    redirectLinksTo?: (link: string) => string
}

interface State {
    loading: boolean
    errored: boolean
    lawJSON?: Content[]
}

interface Content {
    '#name': string // type of element
    $?: Record<string, string> // meta-data
    $$?: Content[] // children
    _?: string // string content
}

export class ArticleRenderer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        loading: true,
        errored: false,
        lawJSON: undefined,
    }

    private bem = new BEM('ArticleRenderer')

    private alClassName = this.bem.getElement('alinea')
    private titleClassName = this.bem.getElement('title')
    private lidClassName = this.bem.getElement('lid')
    private lijstClassName = this.bem.getElement('lijst')

    private tableClassName = this.bem.getElement('table')
    private rowClassName = this.bem.getElement('row')
    private theadClassName = this.bem.getElement('thead')
    private tbodyClassName = this.bem.getElement('tbody')
    private tfootClassName = this.bem.getElement('tfoot')
    private thClassName = this.bem.getElement('th')
    private tdClassName = this.bem.getElement('td')

    private refClassName = this.bem.getElement('ref')
    private cursiefClassName = this.bem.getElement('cursief')
    private vetClassName = this.bem.getElement('vet')

    private imageClassName = this.bem.getElement('afbeelding')

    private usedKeys = new Set<string>()

    public componentDidMount() {
        try {
            const lawJSON = JSON.parse(this.props.content)
            this.setState({
                lawJSON,
                errored: false,
                loading: false,
            })
        } catch (err) {
            this.setState({
                lawJSON: undefined,
                errored: true,
                loading: false,
            })
        }
    }

    public render() {
        const { className } = this.props
        const { loading, errored, lawJSON } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                {errored && <ErrorMessage message={'Failed to render content'} />}
                {loading ? <Spinner /> : lawJSON ? this.renderOutContent(this.removeTopLevelHeading(lawJSON)) : null}
            </div>
        )
    }

    private renderOutContent(content: Content | Content[]): React.ReactNode[] {
        if (!Array.isArray(content)) {
            return this.renderOutContent([content])
        }

        return content.map(this.renderContent)
    }

    private renderDefault = (content: Content) => {
        return (
            <div className={`${content['#name']} part`} key={this.generateKey()}>
                {content._ ? content._ : null}
                {content.$$ ? this.renderOutContent(content.$$) : null}
            </div>
        )
    }

    private renderAl = (content: Content) => {
        return (
            <Paragraph className={this.alClassName} key={this.generateKey()}>
                {content.$$ ? this.renderOutAsText(content.$$) : null}
            </Paragraph>
        )
    }

    private renderTitle = (content: Content) => {
        return (
            <Paragraph className={this.titleClassName} key={this.generateKey()}>
                {content.$$ ? this.renderOutAsText(content.$$) : null}
            </Paragraph>
        )
    }

    private renderLid = (content: Content) => {
        const [lidnr, ...items] = content.$$

        return (
            <ul className={this.lidClassName} key={this.generateKey()}>
                <li>
                    <span>{lidnr.$$ && this.renderOutAsText(lidnr.$$)}</span>
                    {this.renderOutContent(items)}
                </li>
            </ul>
        )
    }

    private renderLijst = (content: Content) => {
        return (
            <ul className={this.lijstClassName} key={this.generateKey()}>
                {content.$$!.map(child => {
                    const children = child.$$!

                    const lidnrIndex = children.findIndex(({ '#name': name }) => name === 'li.nr')
                    let lidnr
                    if (lidnrIndex !== -1) {
                        lidnr = children[lidnrIndex]
                        children.splice(lidnrIndex, 1)
                    }

                    return (
                        <li key={this.generateKey()}>
                            <span>{lidnr && lidnr.$$ && this.renderOutAsText(lidnr.$$)}</span>
                            {this.renderOutContent(children)}
                        </li>
                    )
                })}
            </ul>
        )
    }

    private renderTable = (content: Content) => {
        const titles = content.$$!.filter(el => el['#name'] === 'title')
        const rest = content.$$!.filter(el => el['#name'] !== 'title')

        return (
            <div className={this.tableClassName}>
                {titles.map(this.renderContent)}
                <table key={this.generateKey()}>{rest.map(part => this.renderTableContent(part))}</table>
            </div>
        )
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private renderTableContent = (
        content: Content,
        metadata = { isTableHeader: false, align: 'left', colspan: 1 }
    ): React.ReactNode => {
        switch (content['#name']) {
            case 'colspec': {
                return null
            }
            case 'tgroup': {
                return content.$$!.map(part => this.renderTableContent(part))
            }
            case 'row': {
                return (
                    <tr className={this.rowClassName} key={this.generateKey()}>
                        {content.$$!.map(children => this.renderTableContent(children, metadata))}
                    </tr>
                )
            }
            case 'thead': {
                return (
                    <thead className={this.theadClassName} key={this.generateKey()}>
                        {content.$$!.map(children =>
                            this.renderTableContent(children, { ...metadata, isTableHeader: true })
                        )}
                    </thead>
                )
            }
            case 'tbody': {
                return (
                    <tbody className={this.tbodyClassName} key={this.generateKey()}>
                        {content.$$!.map(children => this.renderTableContent(children, metadata))}
                    </tbody>
                )
            }
            case 'tfoot': {
                return (
                    <tfoot className={this.tfootClassName} key={this.generateKey()}>
                        {content.$$!.map(children => this.renderTableContent(children, metadata))}
                    </tfoot>
                )
            }
            case 'entry': {
                const start = content.$!.namest ? Number(content.$!.namest.replace('col', '')) : undefined
                const end = content.$!.nameend ? Number(content.$!.nameend.replace('col', '')) : undefined

                const cospan = start && end ? end - start + 1 : 1
                const align = content.$!.align ? content.$!.align : 'left'

                if (metadata.isTableHeader) {
                    if (!content.$$) {
                        return (
                            <th
                                className={this.thClassName}
                                key={this.generateKey()}
                                align={align as any}
                                colSpan={cospan}
                            >
                                {/* tslint:disable-next-line:jsx-use-translation-function */}
                                &nbsp;
                            </th>
                        )
                    }

                    return (
                        <th className={this.thClassName} key={this.generateKey()} align={align as any} colSpan={cospan}>
                            {content.$$.map(children => this.renderTableContent(children, metadata))}
                        </th>
                    )
                }
                return (
                    <td className={this.tdClassName} key={this.generateKey()} align={align as any} colSpan={cospan}>
                        {content.$$ ? content.$$.map(children => this.renderTableContent(children, metadata)) : <br />}
                    </td>
                )
            }
            default: {
                return this.renderOutContent(content)
            }
        }
    }

    private removeTopLevelHeading(content: Content | Content[]) {
        const possiblyHeading = !Array.isArray(content) ? content : content[0]

        if (possiblyHeading['#name'] === 'kop' && Array.isArray(content)) {
            return content.slice(1)
        }

        if (possiblyHeading.$$ && possiblyHeading.$$.length > 0 && possiblyHeading.$$[0]['#name'] === 'kop') {
            return possiblyHeading.$$.slice(1)
        }

        return content
    }

    private renderContent = (content: Content) => {
        const renderers = {
            al: this.renderAl,
            // There are two kinds of titles
            title: this.renderTitle,
            titel: this.renderTitle,
            lid: this.renderLid,
            lijst: this.renderLijst,
            table: this.renderTable,
            plaatje: this.renderImage,
        }

        const renderer = renderers[content['#name']]

        if (!renderer) {
            return this.renderDefault(content)
        }

        return renderer(content)
    }

    private renderImage = (content: Content) => {
        return (
            content.$$ &&
            content.$$!.map(image => {
                const { regulationId, lawLabel } = this.props
                const naam = image.$!.naam

                return (
                    <img
                        key={this.generateKey()}
                        className={this.imageClassName}
                        src={`https://wetten.overheid.nl/afbeelding?toestandid=${regulationId}/${lawLabel}&naam=${naam}`}
                        alt={naam}
                    />
                )
            })
        )
    }

    private renderOutAsText = (content: Content[]): React.ReactNode[] => {
        const renderers = {
            extref: this.renderIntOrExtref,
            intref: this.renderIntOrExtref,
            nadruk: this.renderNadruk,
        }

        return content.map(part => {
            const renderer = renderers[part['#name']]

            if (renderer) {
                return <React.Fragment key={this.generateKey()}>{renderer(part)}</React.Fragment>
            }

            // If not found or __text__
            return (
                <React.Fragment key={this.generateKey()}>
                    {part.$$ ? this.renderOutAsText(part.$$) : part._}
                </React.Fragment>
            )
        })
    }

    private renderIntOrExtref = (content: Content) => {
        const { redirectLinksTo } = this.props

        if (redirectLinksTo) {
            return (
                <TextLink
                    className={this.refClassName}
                    to={`${redirectLinksTo(encodeURIComponent(content.$!.doc))}?orginal=${encodeURIComponent(
                        `https://wetten.overheid.nl/${content.$!.doc}`
                    )}`}
                >
                    {content.$$ ? this.renderOutAsText(content.$$) : content._}
                </TextLink>
            )
        }

        return (
            <TextLink
                className={this.refClassName}
                external={true}
                href={`https://wetten.overheid.nl/${content.$!.doc}`}
            >
                {content.$$ ? this.renderOutAsText(content.$$) : content._}
            </TextLink>
        )
    }

    private renderNadruk = (content: Content) => {
        const styles = {
            cur: this.cursiefClassName,
            vet: this.vetClassName,
        }

        const className = content.$ && styles[content.$.type] ? styles[content.$.type] : styles.cur

        return <b className={className}>{content.$$ ? this.renderOutAsText(content.$$) : content._}</b>
    }

    private generateKey(): string {
        const key = Math.random().toString(36).substring(7)
        if (this.usedKeys.has(key)) {
            return this.generateKey()
        }

        this.usedKeys.add(key)

        return key
    }
}
