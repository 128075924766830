import React from 'react'

import { LinkedTaskItem } from '~/graphql/types/Task'
import { ControlLabel } from '~/components/Domain/Compliance/Controls/ControlLabel'
import { InboxLabel } from '~/components/Domain/Inbox/InboxLabel'
import { AssessmentLabel } from '~/components/Domain/Compliance/Assessments/AssessmentLabel'
import { TopicLabel } from '~/components/Domain/Compliance/Norms/TopicLabel'
import { RiskLabel } from '~/components/Domain/Compliance/Risks/RiskLabel'
import { MonitoringReportLabel } from '~/components/Domain/Monitoring/MonitoringReportLabel'
import { RadarLabel } from '~/views/Customer/Radar/RadarLabel'
import { ConsultationLabel } from '../Consultations/ConsultationLabel'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { ReviewTasksLabel } from './ReviewTasksLabel'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { AlertType } from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    linkedItem?: LinkedTaskItem
    linkedTasks?: Task[] | AlertType[]
    onReviewTaskChange?: () => void
    reviewTaskId?: number
    iconOnly?: boolean
    tooltip?: boolean
}

interface State {}

export class LinkedTaskLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public render() {
        const { linkedItem, linkedTasks, iconOnly, tooltip } = this.props

        if (linkedTasks) {
            return this.getLinkedTasksLabel(linkedTasks)
        }

        if (!linkedItem) {
            if (iconOnly) {
                return <Icon type={IconType.dot} />
            }

            return null
        }

        if (tooltip) {
            return <Tooltip {...this.getTooltipProps(linkedItem)}>{this.getSingleItemLabel(linkedItem)}</Tooltip>
        }

        return this.getSingleItemLabel(linkedItem)
    }

    private getSingleItemLabel(linkedItem: LinkedTaskItem) {
        const { iconOnly } = this.props

        if (linkedItem.__typename === 'ControlType') {
            return <ControlLabel control={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'AlertType') {
            return <InboxLabel inboxItem={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'AssessmentType') {
            return <AssessmentLabel assessment={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'MonitoringReportType') {
            return <MonitoringReportLabel monitoringReport={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'RiskType') {
            return <RiskLabel risk={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'TopicType') {
            return <TopicLabel topic={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'RadarItemType') {
            return <RadarLabel radarItem={linkedItem} iconOnly={iconOnly} />
        }

        if (linkedItem.__typename === 'ConsultationType') {
            return <ConsultationLabel consultation={linkedItem} iconOnly={iconOnly} />
        }

        return <></>
    }

    private getLinkedTasksLabel(linkedTasks: Task[] | AlertType[]) {
        const { onReviewTaskChange, reviewTaskId, iconOnly } = this.props

        if (onReviewTaskChange && reviewTaskId) {
            return (
                <ReviewTasksLabel
                    linkedItems={linkedTasks}
                    onChange={onReviewTaskChange}
                    taskId={reviewTaskId}
                    iconOnly={iconOnly}
                />
            )
        }

        return null
    }

    private getTooltipProps(linkedItem: LinkedTaskItem) {
        if ('isDeleted' in linkedItem && linkedItem.isDeleted) {
            return {
                content: (
                    <Paragraph small={true} strikethrough={true}>
                        {linkedItem.name}
                    </Paragraph>
                ),
            }
        }

        return { message: linkedItem.name }
    }
}
