import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentSwitchPopup } from '~/components/Domain/Department/DepartmentSwitchPopup'
import { routes } from '~/views/routes'
import { LawArticleRedirectView } from '~/views/Customer/LawArticleRedirectView'
import { DashboardView } from '~/views/Customer/Dashboard/DashboardView'
import { SettingsView } from '~/views/Customer/Settings/SettingsView'
import { LegalFrameworkView } from '~/views/Customer/LegalFramework/LegalFrameworkView'
import { RadarView } from '~/views/Customer/Radar/RadarView'
import { ConsultationsView } from '~/views/Customer/Consultations/ConsultationsView'
import { ComplianceView } from './Compliance/ComplianceView'
import { InboxView } from '~/views/Customer/Inbox/InboxView'
import { NewsView } from '~/views/Customer/News/NewsView'
import { LawAndRegulationView } from '~/views/Customer/lawAndRegulation/LawAndRegulationView'
import { SearchView } from '~/views/Customer/Search/SearchView'
import { KitchensinkView } from '~/views/Customer/Kitchensink/KitchensinkView'
import { StyleguideView } from '~/views/Customer/Styleguide/StyleguideView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { SignalingView } from './Signaling/SignalingView'
import { ControlView } from './Control/ControlView'
import { RisksView } from './Risks/RisksView'
import { TasksView } from './Tasks/TasksView'
import { MonitoringView } from './Monitoring/MonitoringView'
import { Navigate, Route, Routes } from 'react-router-dom'
import { newRoutes } from '../newRoutes'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps {
    customer: string
}

class CustomerRoutesComponent extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { customer } = this.props
        const params = new URLSearchParams(this.props.location.search)

        const departmentId = params.get('departmentId')
        const department = this.context.departments.find(({ id }) => id === Number(departmentId))

        if (department && this.context.activeDepartmentId !== department.id) {
            return (
                <DepartmentSwitchPopup
                    departmentIdToToggleTo={department.id}
                    departmentNameToToggleTo={department.name}
                />
            )
        }

        return (
            <Routes>
                <Route
                    path={routes.customer(customer).lawAndRegulationRedirect()}
                    element={<LawArticleRedirectView />}
                />
                <Route path={newRoutes.customer(customer).dashboard.index} element={<DashboardView />} />
                <Route path={newRoutes.customer(customer).settings.index} element={<SettingsView />} />
                <Route path={newRoutes.customer(customer).legalFramework.index} element={<LegalFrameworkView />} />
                <Route path={newRoutes.customer(customer).radar.index} element={<RadarView />} />
                <Route path={newRoutes.customer(customer).monitoring.index} element={<MonitoringView />} />
                <Route path={newRoutes.customer(customer).consultations.index} element={<ConsultationsView />} />
                <Route path={newRoutes.customer(customer).compliance.index} element={<ComplianceView />} />
                <Route path={newRoutes.customer(customer).risks.index} element={<RisksView />} />
                <Route path={newRoutes.customer(customer).inbox.index} element={<InboxView />} />
                <Route path={newRoutes.customer(customer).tasks.index} element={<TasksView />} />
                <Route path={newRoutes.customer(customer).news.index} element={<NewsView />} />
                <Route path={newRoutes.customer(customer).lawAndRegulation.index} element={<LawAndRegulationView />} />
                <Route path={newRoutes.customer(customer).search.index} element={<SearchView />} />
                <Route path={newRoutes.customer(customer).signaling.index} element={<SignalingView />} />
                <Route path={newRoutes.customer(customer).kitchensink.index} element={<KitchensinkView />} />
                <Route path={newRoutes.customer(customer).styleguide.index} element={<StyleguideView />} />
                <Route path={newRoutes.customer(customer).control.index} element={<ControlView />} />
                <Route path="*" element={<Navigate replace to={routes.customer(customer).dashboard.index} />} />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const CustomerRoutes = withRouter(CustomerRoutesComponent)
