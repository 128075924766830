import React from 'react'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'

interface Props {
    onChange: (option: SelectOption) => void
    defaultValue?: string
    name: string
}

export enum InboxDepartmentFilterOption {
    activeDepartment = 'true',
    allDepartments = 'false',
}

export class InboxDepartmentFilter extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxDepartmentFilter)

    private options: SelectOption[] = [
        { value: InboxDepartmentFilterOption.activeDepartment, label: this.loc(t => t.activeDepartmentOnly) },
        { value: InboxDepartmentFilterOption.allDepartments, label: this.loc(t => t.allDepartments) },
    ]

    public render() {
        const { onChange, name } = this.props

        return (
            <Select
                name={name}
                isFilter={true}
                searchable={false}
                onChange={(option: SelectOption) => onChange(option)}
                defaultValue={this.getDefaultValue()}
                options={this.options}
            />
        )
    }

    private getDefaultValue() {
        const { defaultValue } = this.props

        if (defaultValue) {
            const defaultOption = this.options.find(o => o.value === defaultValue)

            return defaultOption ? [defaultOption] : undefined
        }

        return [this.options[1]]
    }
}
