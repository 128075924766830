import React from 'react'

import { CustomerConsumer, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerFrameworkToggle } from './CustomerFrameworkToggle'

interface Props {
    className?: ClassValue
    forMobile?: boolean
    requestClose?: () => void
}

interface State {}

export class CustomerFrameworkContextSwitch extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('CustomerFrameworkContextSwitch')

    public render() {
        const { className } = this.props

        return (
            <CustomerConsumer>
                {context => {
                    if (!context) {
                        return null
                    }

                    return (
                        <ol className={this.bem.getClassName(className)}>{this.renderCustomerFrameworks(context)}</ol>
                    )
                }}
            </CustomerConsumer>
        )
    }

    private renderCustomerFrameworks(context: CustomerContextValue) {
        const { forMobile } = this.props

        const customerFrameworks = context.profiles
        const activeFrameworks = context.profiles.filter(({ isActive }) => isActive)

        return customerFrameworks.map((framework, index) => {
            const isLastActiveFramework = activeFrameworks.length === 1 && framework.isActive
            return (
                <li key={`${framework.id}-${index}`}>
                    <CustomerFrameworkToggle
                        framework={framework}
                        defaultChecked={!!framework.isActive}
                        onChange={() => context.toggleProfile(framework.id)}
                        disabled={isLastActiveFramework}
                        forMobile={forMobile}
                    />
                </li>
            )
        })
    }
}
