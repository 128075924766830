import './AllTasksView.scss'

import React from 'react'

import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { NewParamState, ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { CreateTaskModalContainer } from '~/components/Domain/Task/CreateTaskModalContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { breadcrumbs } from '~/views/breadcrumbs'
import {
    AllTasksFilters,
    AllTasksViewFilterButtonContainer,
} from '~/components/Domain/Task/AllTasksViewFilterButtonContainer'
import { Column } from '~/components/Core/Layout/Column'
import { OptionsDropdown } from '~/components/Core/OptionsDropdown/OptionsDropdown'
import { OptionsDropdownOption } from '~/components/Core/OptionsDropdown/OptionsDropdownOption'
import { Page } from '~/components/Core/Layout/Page'
import { BEM } from '~/services/BEMService'
import { AllTasksListViewContainer } from '~/components/Domain/Task/AllTasksListViewContainer'
import { AllTasksGroupViewContainer } from '~/components/Domain/Task/AllTasksGroupViewContainer'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { ViewTaskModalContainer } from '~/components/Domain/Task/ViewTaskModalContainer'
import { routes } from '~/views/routes'

interface RouteParams {
    id?: string
}
export interface AllTasksParams extends AllTasksFilters {
    search?: string
    tableView: 'listView' | 'groupView'
    taskId?: number
}

interface Props extends RouteComponentProps<RouteParams> {}

class AllTasksViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private bem = new BEM('AllTasksView')
    private refetchFunctionOnCreate: (() => void)[] = []
    private modalManagerRef = React.createRef<ModalManager>()

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.modalManagerRef.current?.toggleModal()
        }
    }

    public render() {
        const { id: taskId } = this.props.match.params
        const defaultState: AllTasksParams = {
            tableView: 'listView',
            forEmployeeIds: this.context.employee ? [this.context.employee.id] : undefined,
            activeDepartmentOnly: true,
            completedOnly: false,
            taskId: taskId ? parseInt(taskId, 10) : undefined,
        }
        return (
            <>
                <ParamManager<AllTasksParams> defaultState={defaultState}>
                    {({ paramState, setParamState }) => {
                        return (
                            <Page className={this.bem.getClassName()}>
                                <Column className={this.bem.getElement('content-container')}>
                                    {this.renderHeader(paramState, setParamState)}
                                    {this.renderTable(paramState)}
                                </Column>
                            </Page>
                        )
                    }}
                </ParamManager>
                <ModalManager
                    ref={this.modalManagerRef}
                    openOnMount={this.props.match.params.id !== undefined}
                    renderModal={this.renderTaskModal.bind(this)}
                    hasClosed={() =>
                        this.props.history.replace({
                            ...this.props.location,
                            pathname: routes.customer(this.context.customer.slug).tasks.overview,
                        })
                    }
                />
            </>
        )
    }

    private renderHeader(paramState: AllTasksParams, setParamState: (newState: NewParamState<AllTasksParams>) => void) {
        return (
            <Column bigSpacing={true} className={this.bem.getElement('header-container')}>
                <PageHeader
                    title={this.loc(t => t.AllTasksTable.title)}
                    actionComponent={this.renderActions(paramState, setParamState)}
                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).tasks.index]}
                />
                {this.renderViewOptionsButton(paramState, setParamState)}
            </Column>
        )
    }

    private renderTaskModal(closeModal: () => void) {
        if (!this.props.match.params.id) {
            closeModal()
        }

        const parsedId = parseInt(this.props.match.params.id!, 10)
        if (isNaN(parsedId)) {
            return closeModal()
        }

        return (
            <ViewTaskModalContainer
                taskId={parsedId}
                onChangeTask={() => closeModal()}
                onArchiveTask={() => closeModal()}
                onCompleteOrReopen={() => closeModal()}
                requestClose={closeModal}
            />
        )
    }

    private renderTable(paramState: AllTasksParams) {
        const { tableView } = paramState

        if (tableView === 'listView') {
            return this.renderListView(paramState)
        }

        return this.renderGroupView(paramState)
    }

    private renderActions(paramState: AllTasksParams, setParamState: SetParamStateFn<AllTasksParams>) {
        return (
            <Row smallSpacing={true} alignRight={true}>
                <Search
                    onChange={search => setParamState({ ...paramState, search: search || undefined })}
                    placeholder={this.loc(t => t.taskSearchPlaceholder)}
                    defaultValue={paramState.search}
                />
                <AllTasksViewFilterButtonContainer
                    defaultState={paramState}
                    onChange={newState => setParamState({ ...paramState, ...newState })}
                />
                {this.renderCreateTaskButton()}
            </Row>
        )
    }

    private renderViewOptionsButton(
        paramState: AllTasksParams,
        setParamState: (newState: NewParamState<AllTasksParams>) => void
    ) {
        const { tableView } = paramState

        // This fixes the button when a user nagivates here from MonitoringAgendaView, in that case the
        // tableView state is undefined, which causes the button to be broken. A fallback is needed.
        // See renderTable(), when tableView is undefined, it will default to renderGroupView().
        const fallbackTableView = 'groupView'

        return (
            <OptionsDropdown
                className={this.bem.getElement('option-dropdown')}
                renderButton={onClick => (
                    <Button
                        type={ButtonType.secondary}
                        onClick={onClick}
                        icon={IconType[tableView ?? fallbackTableView]}
                        className={this.bem.getElement('option-dropdown-button')}
                    >
                        {this.loc(t => t[tableView ?? fallbackTableView])}
                    </Button>
                )}
            >
                <OptionsDropdownOption
                    icon={IconType.listView}
                    label={this.loc(t => t.listView)}
                    onClick={() => this.handleTableViewOptionChange('listView', paramState, setParamState)}
                />
                <OptionsDropdownOption
                    icon={IconType.groupView}
                    label={this.loc(t => t.groupView)}
                    onClick={() => this.handleTableViewOptionChange('groupView', paramState, setParamState)}
                />
            </OptionsDropdown>
        )
    }

    private renderListView(paramState: AllTasksParams) {
        const formattedParamState = this.formatParamState(paramState)

        return (
            <div className={this.bem.getElement('table-container')}>
                <AllTasksListViewContainer
                    className={this.bem.getElement('table')}
                    paramState={formattedParamState}
                    setRefetchFunction={this.setRefetchFunction}
                    refetch={this.onCreateNewTask}
                />
            </div>
        )
    }

    private renderGroupView(paramState: AllTasksParams) {
        const formattedParamState = this.formatParamState(paramState)

        return (
            <div className={this.bem.getElement('table-container')}>
                <AllTasksGroupViewContainer
                    className={this.bem.getElement('table')}
                    paramState={formattedParamState}
                    setRefetchFunction={this.setRefetchFunction}
                    refetch={this.onCreateNewTask}
                />
            </div>
        )
    }

    private renderCreateTaskButton() {
        const hasPermission = permissions.canCreateTasks(this.context.activeDepartmentId)

        return (
            <Guard condition={hasPermission}>
                <ModalManager
                    render={openModal => (
                        <Button icon={IconType.taskAdd} onClick={openModal} rounded={true}>
                            {this.loc(t => t.newGenericTaskButton)}
                        </Button>
                    )}
                    renderModal={closeModal => (
                        <CreateTaskModalContainer onCreated={this.onCreateNewTask} requestClose={closeModal} />
                    )}
                />
            </Guard>
        )
    }

    private handleTableViewOptionChange(
        view: 'listView' | 'groupView',
        paramState: AllTasksParams,
        setParamState: (newState: NewParamState<AllTasksParams>) => void
    ) {
        const { tableView } = paramState
        if (tableView === view) {
            return
        }

        setParamState({ ...paramState, tableView: view })
    }

    private onCreateNewTask = () => {
        this.refetchFunctionOnCreate.forEach(refetch => refetch())
    }

    private setRefetchFunction = (refetch: () => void) => {
        this.refetchFunctionOnCreate = [...this.refetchFunctionOnCreate, refetch]
    }

    private formatParamState(paramState: AllTasksParams) {
        return {
            ...paramState,
            tableView: undefined,
        }
    }
}

export const AllTasksView = withRouter(AllTasksViewComponent)
