import React from 'react'

import { Column } from '~/components/Core/Layout/Column'
import { LinkedControlCard } from './LinkedControlCard'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { InsetCard } from '../Norms/InsetCard'
import { localize } from '~/bootstrap'
import { Section } from '~/components/Core/Layout/Section'
import { ComplianceCardList } from '../ComplianceCardList'
import { ControlTypeType } from '~/generated/graphql'
import { getIconTypeForControlType } from '~/utils/controls'

interface Props {
    name: string
    controls?: {
        id: number
        name: string
        type: ControlTypeType
        revisionDate?: string | null
    }[]
}

interface State {}

export class LinkedControls extends React.Component<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks.editRisk)
    public render() {
        const { name, controls } = this.props
        const hasControls = controls && controls.length ? true : false
        const deleteControlMessage = hasControls
            ? this.loc(t => t.deleteConfirm, {
                  name,
              })
            : this.loc(t => t.deleteHasNoSideEffects, { name })

        return (
            <Column bigSpacing={true}>
                <ErrorMessage path={'deleteRisk'} />
                <Paragraph>{deleteControlMessage}</Paragraph>
                {controls && controls.length > 0 && (
                    <InsetCard>
                        <Section key={name} title={name}>
                            <ComplianceCardList
                                title={localize.translate(t => t.Customer.Compliance.LinkedAssessments.controls)}
                            >
                                <Column smallSpacing={true}>
                                    {controls.map((control, index) => {
                                        return (
                                            <LinkedControlCard
                                                label={control.name}
                                                icon={getIconTypeForControlType(control.type)}
                                                revisionDate={control.revisionDate}
                                                key={`LinkedControlCard-${index}`}
                                            />
                                        )
                                    })}
                                </Column>
                            </ComplianceCardList>
                        </Section>
                    </InsetCard>
                )}
            </Column>
        )
    }
}
