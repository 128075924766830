import './TopicDetailView.scss'
import React from 'react'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { Markdown } from '~/components/Core/Text/Markdown'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { GroupedItem } from '~/graphql/types/LinkedItem'
import { GroupedLinkedItemsLists } from '~/components/Domain/LinkedList/GroupedLinkedItemsLists'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { localize } from '~/bootstrap'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { breadcrumbs } from '~/views/breadcrumbs'
import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { TopicDetailDocument, TopicDetailQueryVariables, TopicType } from '~/generated/graphql'
import { TopicDetailViewInboxTableContainer } from '~/components/Domain/Topic/TopicDetailViewInboxTableContainer'
import { routes } from '~/views/routes'
import { TopicLinkedItems } from '~/components/Domain/Topic/TopicLinkedItems'
import { TopicDetailViewAssessmentStatus } from '~/components/Domain/Topic/TopicDetailViewAssessmentStatus'
import { TopicDetailActionButtons } from '~/components/Domain/Topic/TopicDetailActionButtons'
import { SidebarTaskWidgetContainer } from '~/components/Domain/Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { RadarTopicLinkedItemsContainer } from '~/components/Domain/Radar/RadarTopicLinkedItemsContainer'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {
    expanded: boolean
    shouldRenderExpandButton: boolean
}

class TopicDetailViewComponent extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        expanded: false,
        shouldRenderExpandButton: false,
    }

    private bem = new BEM('TopicDetailView')
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailView)

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <PageQuery<TopicType, TopicDetailQueryVariables>
                query={TopicDetailDocument}
                variables={{
                    id: parseInt(id, 10),
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {(topic, { refetch }) => this.renderPage(topic, refetch)}
            </PageQuery>
        )
    }

    private renderPage(topic: TopicType, refetch: Function) {
        return (
            <Page>
                <PageHeader
                    title={topic.name}
                    breadCrumbs={this.getBreadCrumbs(topic)}
                    actionButtons={<TopicDetailActionButtons topic={topic} refetch={() => refetch()} />}
                />
                <PageDetail
                    renderSidebar={this.renderSidebar(topic, refetch)}
                    className={this.bem.getClassName()}
                    smallBottomSpacing
                >
                    {this.renderPageDetailContent(topic, refetch)}
                </PageDetail>
            </Page>
        )
    }

    private getBreadCrumbs(topic: TopicType) {
        return [
            breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
            breadcrumbs.customer(this.context.customer.slug).legalFramework.themes.index,
            breadcrumbs
                .customer(this.context.customer.slug)
                .legalFramework.themes.view(topic.theme.name!, topic.theme.id),
        ]
    }

    private renderSidebar = (topic: TopicType, refetch: Function) => () => {
        const id = parseInt(this.props.match.params.id!, 10)
        const dneRoute = routes
            .customer(this.context.customer.slug)
            .legalFramework.topic.designAndEffectiveness(topic.id)

        return (
            <>
                <TopicDetailViewAssessmentStatus
                    onAssessmentClick={() => this.props.history.push(dneRoute)}
                    topic={topic}
                    onChange={() => refetch()}
                />
                <SidebarTaskWidgetContainer linkedItem={topic as LinkedTaskItem} refetch={() => refetch()} />
                {!!topic.linkedRadarItems?.length && (
                    <RadarTopicLinkedItemsContainer
                        title={localize.translate(t => t.Generic.radarTopics)}
                        items={topic.linkedRadarItems.map(r => ({
                            label: r.name,
                            route: routes.customer(this.context.customer.slug).radar.detail.view(r.id),
                            icon: IconType.radar,
                        }))}
                    />
                )}
                <TopicLinkedItems topicId={id} getExpiredStatus={true} filterDeleted={true}>
                    {({ data, loading, refetch }) => {
                        const topic = data && data.topic ? data.topic : null

                        if (loading) {
                            return <Spinner />
                        }

                        if (!topic || !topic.linkedItems) {
                            return null
                        }

                        if (topic.linkedItems.length === 0) {
                            return <Paragraph subtle={true}>{this.loc(t => t.noItemsLinked)}</Paragraph>
                        }

                        return (
                            <GroupedLinkedItemsLists
                                topicId={id}
                                showExpiredStatus={true}
                                linkedItems={topic.linkedItems as GroupedItem[]}
                                onDeleteExpired={refetch}
                            />
                        )
                    }}
                </TopicLinkedItems>
            </>
        )
    }

    private renderPageDetailContent(topic: TopicType, refetch: Function) {
        const { expanded } = this.state
        const noContent = !topic.abstract
        const containerClassName = this.bem.getElement('content-container', () => ({ expanded, noContent }))

        return (
            <Column extraBigSpacing>
                <div className={containerClassName}>
                    <PageDetailContent>
                        <div ref={this.handleMarkdownContainerRefChange}>
                            <Markdown source={topic.abstract} className={this.bem.getElement('markdown')} />
                        </div>
                    </PageDetailContent>
                </div>
                {this.renderExpandButton()}
                <TopicDetailViewInboxTableContainer
                    alerts={topic.linkedAlerts}
                    onCompleteOrReopenTasks={() => refetch()}
                />
            </Column>
        )
    }

    private handleMarkdownContainerRefChange = (instance: HTMLDivElement | null) => {
        if (!instance) {
            return
        }

        const shouldRenderExpandButton = instance.scrollHeight > 360
        if (shouldRenderExpandButton) {
            this.setState({ shouldRenderExpandButton })
        }
    }

    private renderExpandButton() {
        const { expanded, shouldRenderExpandButton } = this.state

        if (!shouldRenderExpandButton) {
            return
        }

        const { buttonIcon, buttonText } = this.getButtonTextAndIcon()
        const onClick = () => this.setState({ expanded: !expanded })

        return (
            <Button type={ButtonType.actionLink} icon={buttonIcon} onClick={onClick}>
                {buttonText}
            </Button>
        )
    }

    private getButtonTextAndIcon() {
        const { expanded } = this.state

        if (expanded) {
            const buttonIcon = IconType.arrowUp
            const buttonText = this.loc(t => t.collapseButton)

            return { buttonIcon, buttonText }
        }

        const buttonIcon = IconType.arrowDown
        const buttonText = this.loc(t => t.expandButton)

        return { buttonIcon, buttonText }
    }
}

export const TopicDetailView = withRouter(TopicDetailViewComponent)
