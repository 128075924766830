import './OnboardingIntroModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'
import OnboardingIllustration from './OnboardingIllustration.png'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    onNext: () => void
    onSkip: () => void
    onSkipLoading?: boolean
}

interface State {}

export class OnboardingIntroModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('OnboardingIntroModal')

    public render() {
        const { className, onNext, onSkip, onSkipLoading } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('hero')}>
                    <img
                        src={OnboardingIllustration}
                        alt={localize.translate(t => t.Customer.Onboarding.onboardingIllustrationAlt)}
                    />
                </div>
                <div className={this.bem.getElement('content')}>
                    <div className={this.bem.getElement('intro')}>
                        <ComponentTitle title={localize.translate(t => t.Customer.Onboarding.title)} />
                        <Paragraph>{localize.translate(t => t.Customer.Onboarding.introduction)}</Paragraph>
                    </div>
                    <Row spaceBetween={true} className={this.bem.getElement('buttons')}>
                        <Button
                            type={ButtonType.secondary}
                            onClick={onSkip}
                            className={this.bem.getElement('skip-intro-button')}
                            loading={onSkipLoading}
                        >
                            {localize.translate(t => t.Generic.skip)}
                        </Button>
                        <Button onClick={onNext} className={this.bem.getElement('next-button')}>
                            {localize.translate(t => t.Generic.next)}
                        </Button>
                    </Row>
                </div>
            </div>
        )
    }
}
