import './RiskGraphBackground.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { RiskGraphGrid } from './RiskGraphGrid'
import { RiskGraphTrendLine } from './RiskGraphTrendLine'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    xAxisLabel?: string
    yAxisLabel?: string
}

export class RiskGraphBackground extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('RiskGraphBackground')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Risks)

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <RiskGraphGrid />
                <RiskGraphTrendLine />
                {this.renderDescriptions()}
            </div>
        )
    }

    private renderDescriptions() {
        const { xAxisLabel, yAxisLabel } = this.props

        return (
            <>
                <div className={this.bem.getElement('x-axis-description')}>
                    <span className={this.bem.getElement('axis-label')}>
                        {xAxisLabel ? xAxisLabel : this.loc(t => t.chanceDefaultLabel)}
                    </span>
                </div>
                <div className={this.bem.getElement('y-axis-description')}>
                    <span className={this.bem.getElement('axis-label')}>
                        {yAxisLabel ? yAxisLabel : this.loc(t => t.severityDefaultLabel)}
                    </span>
                </div>
            </>
        )
    }
}
