import React from 'react'
import { GroupedTasksQuery, GroupedTasksQueryFilters } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksQuery'
import { GroupedTasksTable } from '~/views/Customer/Tasks/GroupedTasks/GroupedTasksTable'
import { InfiniteScroll } from '~/components/Core/Pagination/InfiniteScroll'
import { SortDirection } from '~/components/Core/DataDisplay/Table/Table'

interface Props {
    filters?: Omit<GroupedTasksQueryFilters, 'includingOverdueTasks'>
}

interface State {
    sort?: {
        [field: string]: SortDirection
    }
}

export class GroupedTasksContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        sort: {
            firstDueAt: 'DESC',
        },
    }

    public render() {
        const { filters } = this.props
        const { sort } = this.state

        return (
            <GroupedTasksQuery sort={sort} filters={{ includingOverdueTasks: false, ...filters }}>
                {({ data, refetch, loading, loadingMore, fetchMore, canFetchMore }) => {
                    const groupedTasks = data && data.nodes ? data.nodes : []

                    return (
                        <InfiniteScroll onReachedEnd={fetchMore} hasMore={canFetchMore}>
                            <GroupedTasksTable
                                completedOnly={filters?.completedOnly}
                                refetch={refetch}
                                loading={loading}
                                loadingMore={loadingMore}
                                groupedTasks={groupedTasks}
                                onSortDirectionChange={this.handleOnSortDirectionChange}
                                defaultSortDirection={{ field: 'firstDueAt', direction: 'DESC' }}
                            />
                        </InfiniteScroll>
                    )
                }}
            </GroupedTasksQuery>
        )
    }

    private handleOnSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({
            sort: {
                [field]: direction,
            },
        })
    }
}
