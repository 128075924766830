import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { roleGroups } from '~/services/UserService'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { EmployeeRole } from '~/generated/graphql'

interface Props {
    defaultValue?: EmployeeRole
    disabled?: boolean
    name?: string
    onChange?: (selectedOptions: SelectOption | SelectOption[] | null, name: string) => void
}

interface State {}

export class CustomerRoleSelect extends React.Component<React.PropsWithChildren<Props>, State> {
    public render() {
        const { disabled, name, defaultValue, onChange } = this.props

        return (
            <Form.Select
                onChange={onChange}
                name={name || 'role'}
                searchable={false}
                disabled={disabled}
                clearable={false}
                defaultValue={
                    defaultValue
                        ? [
                              {
                                  label: localize.translate(t => t.User.roles[defaultValue]),
                                  value: defaultValue,
                              },
                          ]
                        : undefined
                }
                options={roleGroups.customer.map(role => ({
                    label: localize.translate(t => t.User.roles[role]),
                    value: role,
                }))}
            />
        )
    }
}
