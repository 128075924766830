import './ReleaseNotesModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ReleaseNote, ReleaseNoteAttachment } from '~/components/Domain/ReleaseNotes/ReleaseNotesManager'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    releaseNotes: ReleaseNote[]
    requestClose?: () => void
    startingIndex: number
}

interface State {
    index: number
}

export class ReleaseNotesModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        index: this.props.startingIndex,
    }

    private bem = new BEM('ReleaseNotesModal')

    public componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp)
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp)
    }

    public render() {
        const { releaseNotes, className, requestClose } = this.props
        const { index } = this.state
        const notes = releaseNotes[index]

        const previousVersion = releaseNotes[index - 1]
        const nextVersion = releaseNotes[index + 1]

        return (
            <div className={this.bem.getClassName(className)}>
                <Row spaceBetween={true}>
                    <ComponentTitle
                        title={localize.translate(t => t.Customer.ReleaseNotesModal.title, { version: notes.version })}
                    />
                    <Button
                        type={ButtonType.noStyling}
                        onClick={requestClose}
                        icon={IconType.closeModal}
                        className={this.bem.getElement('close-button')}
                    />
                </Row>
                <DateFormat
                    readable={true}
                    noWeekday={true}
                    date={new Date(notes.date)}
                    className={this.bem.getElement('date')}
                />
                <ul className={this.bem.getElement('changes')}>
                    {notes.changes!.map((change, index, array) => {
                        return (
                            <li key={`change-${array.length}-${index}`} className={this.bem.getElement('change')}>
                                <h4 className={this.bem.getElement('change-title')}>{change.title}</h4>
                                {change.description && (
                                    <p className={this.bem.getElement('change-description')}>{change.description}</p>
                                )}
                                {change.attachments && change.attachments.length > 0 && (
                                    <ul className={this.bem.getElement('attachments')}>
                                        {change.attachments.map((attachment, index, array) => {
                                            return (
                                                <li key={`attachment-${array.length}-${index}`}>
                                                    {this.renderAttachment(attachment)}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </li>
                        )
                    })}
                </ul>
                <Row spaceBetween={true} className={this.bem.getElement('buttons')}>
                    {previousVersion && (
                        <Button
                            type={ButtonType.noStyling}
                            className={this.bem.getElement('button-prev')}
                            onClick={this.goToPrevious}
                        >
                            <Icon type={IconType.arrowLeft} className={this.bem.getElement('navigation-icon')} />
                            {localize.translate(t => t.Generic.version)} {previousVersion.version}
                        </Button>
                    )}
                    {nextVersion && (
                        <Button
                            type={ButtonType.noStyling}
                            className={this.bem.getElement('button-next')}
                            onClick={this.goToNext}
                        >
                            {localize.translate(t => t.Generic.version)} {nextVersion.version}
                            <Icon type={IconType.arrowRight} className={this.bem.getElement('navigation-icon')} />
                        </Button>
                    )}
                </Row>
            </div>
        )
    }

    private renderAttachment(attachment: ReleaseNoteAttachment) {
        if (attachment.type === 'image') {
            return (
                <img
                    // tslint:disable-next-line:jsx-use-translation-function
                    alt=""
                    src={this.getUrl(attachment.url)}
                    className={this.bem.getElement('attachment-image')}
                />
            )
        }

        if (attachment.type === 'video') {
            return (
                <video
                    autoPlay={true}
                    playsInline={true}
                    loop={true}
                    controls={false}
                    muted={true}
                    className={this.bem.getElement('attachment-video')}
                >
                    <source src={this.getUrl(attachment.url)} type="video/mp4" />
                </video>
            )
        }

        return null
    }

    private getUrl(url: string) {
        if (url.startsWith('http')) {
            return url
        }

        return `/release-notes/${url}`
    }

    private goToNext = () => {
        const { index } = this.state
        const { releaseNotes } = this.props

        if (index === releaseNotes.length) {
            return
        }

        this.setState({ index: index + 1 })
    }

    private goToPrevious = () => {
        const { index } = this.state

        if (index === 0) {
            return
        }

        this.setState({ index: index - 1 })
    }

    private handleKeyUp = (event: KeyboardEvent): void => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        if (this.props.requestClose) {
            this.props.requestClose()
        }
    }
}
