import './Subtle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export const Subtle: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { children, className } = props
    const bem = new BEM('Subtle')

    return <p className={bem.getClassName(className)}>{children}</p>
}
