import './HierarchyControlGroupTile.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class HierarchyControlGroupTile extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('HierarchyControlGroupTile')

    public render() {
        const { className } = this.props
        const children = React.Children.toArray(this.props.children)

        // TODO group controls
        // if (children.length > 1) {
        //     return this.renderGroupedControls(children)
        // }

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }

    // private renderGroupedControls(children: React.ReactChild[]) {
    //     return <span>{children.length}</span>
    // }
}
