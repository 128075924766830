import './PermissionLabels.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { EmployeePermissionEnum } from '~/generated/graphql'

interface Props {
    permissions: EmployeePermissionEnum[]
}

interface State {
    permissionsConfig: PermissionsConfigType[]
}

export interface PermissionsConfigType {
    name: EmployeePermissionEnum
    active: boolean
}

export class PermissionLabels extends React.Component<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.User.PermissionsInfo)
    private bem = new BEM('PermissionLabels')

    public render() {
        const { permissions } = this.props
        const count = permissions.length

        return (
            <Tooltip content={this.renderTooltipContent()} className={this.bem.getClassName()}>
                <LabelTag subtle={true} label={this.loc(t => t.labelTag, { count, smart_count: count })} />
            </Tooltip>
        )
    }

    private renderTooltipContent = () => {
        const { permissions } = this.props

        return (
            <Column extraSmallSpacing={true} className={this.bem.getElement('content')}>
                <Row>
                    <Paragraph bold={true}>{localize.translate(t => t.Settings.Attributes.permissions)}</Paragraph>
                </Row>
                {permissions.map(permission => (
                    <Checkbox
                        className={this.bem.getElement('checkbox')}
                        key={permission}
                        name="permission"
                        disabled={true}
                        checked={true}
                        label={this.loc(t => t[permission])}
                    />
                ))}
            </Column>
        )
    }
}
