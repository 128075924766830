import React from 'react'
import gql from 'graphql-tag'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'

const ARCHIVE_INBOX_NEWS_ITEMS = gql`
    mutation archiveAlertsOnInbox(
        $departmentId: Int!
        $alertIds: [Int!]!
        $archivedReason: String
        $customerSlug: String
    ) {
        archiveAlertsOnInbox(departmentId: $departmentId, alertIds: $alertIds, archivedReason: $archivedReason) {
            id
            name
            publishedAt
            firstTask(departmentId: $departmentId) {
                id
                name
                createdAt
                createdBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                            avatar
                        }
                    }
                }
            }
            inboxStatus(departmentId: $departmentId) {
                id
                archivedAt
                archivedReason
                archivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
                unarchivedAt
                unarchivedBy {
                    id
                    user {
                        id
                        profile {
                            id
                            fullName
                        }
                    }
                }
            }
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
        }
    }
    ${CustomerFrameworkFragment}
`

export interface ArchiveInboxNewsItemMutationResponse {
    archiveAlertsOnInbox: {
        id: string
        name: string
    }
}

export interface ArchiveInboxNewsItemVariables {
    departmentId: number
    alertIds: number[]
    customerSlug: string
    archivedReason: string | null
}

export type ArchiveInboxNewsItemMutationFN = MutationFn<
    ArchiveInboxNewsItemMutationResponse,
    ArchiveInboxNewsItemVariables
>

interface Props {
    children: (
        mutation: MutationFn<ArchiveInboxNewsItemMutationResponse, ArchiveInboxNewsItemVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const ArchiveAlertMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<ArchiveInboxNewsItemMutationResponse, ArchiveInboxNewsItemVariables>
        mutation={ARCHIVE_INBOX_NEWS_ITEMS}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
