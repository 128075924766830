import React from 'react'
import { localize } from '~/bootstrap'
import { DateTime } from '~/components/Core/Date/DateTime'
import { PageDetailMetaItem, PageDetailMeta } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { Alert } from '~/views/Customer/Inbox/InboxDetailContainer'

interface Props {
    alert: Alert
}

export class InboxDetailMeta extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxDetailMeta')

    public render() {
        const items = [this.getPublishedMetaItem()].filter(i => !!i) as PageDetailMetaItem[]

        return <PageDetailMeta className={this.bem.getClassName()} items={items} />
    }

    private getPublishedMetaItem(): PageDetailMetaItem {
        return {
            label: localize.translate(t => t.Generic.sentAt),
            value: (
                <Paragraph>
                    <DateTime date={new Date(this.props.alert.publishedAt)} />
                </Paragraph>
            ),
        }
    }
}
