import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { localize, notification } from '~/bootstrap'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import {
    EditMonitoringReportType,
    EditMonitoringReportVariables,
    MonitoringRapportEditMutation as MonitoringReportEditMutation,
} from '~/components/Domain/Monitoring/MonitoringReportEditForm/MonitoringReportEditMutation'
import { MonitoringReportDetailType } from '../MonitoringReportsDetail/MonitoringReportQuery'
import { MonitoringReportEditForm } from './MonitoringReportEditForm'
import { readAndSanitizeFile } from '~/utils/fileSanitizer'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'

interface Props {
    monitoringReport: MonitoringReportDetailType
    onSubmitSuccess: () => void
}

interface State {
    validFileErrorMessage: string | null
}

export class MonitoringReportEditFormContainer extends React.PureComponent<React.PropsWithChildren<Props>, {}> {
    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringEditView)

    state: State = {
        validFileErrorMessage: null,
    }
    public render() {
        const { monitoringReport } = this.props
        const { validFileErrorMessage } = this.state

        return (
            <MonitoringReportEditMutation>
                {(editMonitoringRapport, editOptions) => {
                    return (
                        <>
                            <MonitoringReportEditForm
                                onSubmit={formState => this.handleSubmit(formState, editMonitoringRapport, editOptions)}
                                monitoringReport={monitoringReport}
                                editOptions={editOptions}
                            />
                            {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}
                        </>
                    )
                }}
            </MonitoringReportEditMutation>
        )
    }

    private handleSubmit = async (
        formState: FormState,
        editMonitoringReport: MutationFn<EditMonitoringReportType, EditMonitoringReportVariables>,
        editOptions: MutationResult<EditMonitoringReportType>
    ) => {
        const { onSubmitSuccess, monitoringReport } = this.props

        const isValidFile = formState?.file ? await readAndSanitizeFile(formState?.file) : true

        if (isValidFile) {
            const variables = {
                variables: {
                    monitoringReportId: monitoringReport.id,
                    fields: {
                        file: formState.file,
                        name: formState.name,
                    },
                },
            }

            await editMonitoringReport(variables)

            if (!editOptions.error) {
                notification.success(this.loc(t => t.successEdit))
                onSubmitSuccess()
            }
        } else {
            this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
        }
    }
}
