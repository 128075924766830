import './ContextTasksTable.scss'

import React from 'react'
import { Table, ColumnOptions, RowData } from '~/components/Core/DataDisplay/Table/Table'
import { localize } from '~/bootstrap'
import { RevisionDateFormat } from '~/components/Domain/Compliance/Controls/RevisionDateFormat'
import { TaskNameWithCompleteButton } from '~/components/Domain/Task/TaskNameWithCompleteButton'
import { CappedProfileList } from '~/components/Core/DataDisplay/CappedUserList/CappedProfileList'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { addDays } from 'date-fns'
import { PriorityIndicator } from '../PriorityIcon/PriorityIndicator'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { BEM } from '~/services/BEMService'
import { DepartmentLabelTag } from '../../Department/DepartmentLabelTag/DepartmentLabelTag'

interface Props {
    tasks: Task[]
    loading: boolean
    loadingMore: boolean
    endReached: boolean
    emptyState?: string | JSX.Element
    onChange: () => void
    showDepartment?: boolean
    showHeaders?: boolean
}

export class ContextTasksTable extends React.Component<React.PropsWithChildren<Props>, {}> {
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.AllTasksTable)
    private bem = new BEM('ContextTasksTable', () => ({
        hasDepartment: this.props.showDepartment,
    }))

    public render() {
        const { loading, loadingMore, endReached, emptyState, showHeaders } = this.props

        return (
            <Table
                className={this.bem.getClassName()}
                loading={loading}
                loadingMore={loadingMore}
                endReached={endReached}
                columns={this.getTableColumns()}
                data={this.getTableData()}
                emptyState={emptyState}
                hideEmptyState={!emptyState}
                hideHeaders={!showHeaders}
                hideEndReachedMark={true}
            />
        )
    }

    private getTableColumns(): ColumnOptions[] {
        const { showDepartment } = this.props

        const columns = [
            { field: 'name', headerLabel: this.loc(t => t.headers.name) },
            { field: 'employees', headerLabel: this.loc(t => t.headers.employees) },
            { field: 'dueAt', headerLabel: this.loc(t => t.headers.dueAt) },
            { field: 'priority', headerLabel: this.loc(t => t.headers.priority) },
        ]

        if (showDepartment) {
            const departmentColumn = { field: 'department', headerLabel: this.loc(t => t.headers.department) }
            columns.splice(2, 0, departmentColumn)
        }

        return columns
    }

    private getTableData(): RowData[] {
        const { tasks, onChange, showDepartment } = this.props

        return tasks.map(task => {
            return {
                id: task.id,
                columns: {
                    name: <TaskNameWithCompleteButton task={task} onChange={onChange} hideMetadata={true} />,
                    employees: (
                        <CappedProfileList
                            users={task.employees.map(({ user }) => ({
                                id: user.id,
                                fullName: user.profile.fullName,
                                avatar: user.profile.avatar,
                            }))}
                        />
                    ),
                    department: showDepartment && (
                        <DepartmentLabelTag
                            departments={[task.department]}
                            className={this.bem.getElement('department', () => ({ subtle: !!task.completedAt }))}
                        />
                    ),
                    dueAt:
                        task.dueAt &&
                        (task.completedAt ? (
                            <DateFormat date={new Date(task.dueAt)} readable={true} noWeekday={true} />
                        ) : (
                            <RevisionDateFormat
                                date={new Date(task.dueAt)}
                                thresholdDate={addDays(new Date(task.dueAt), 1)}
                            />
                        )),
                    priority: <PriorityIndicator isTooltip={true} priority={task.priority} />,
                },
                inactive: !!task.completedAt,
            }
        })
    }
}
