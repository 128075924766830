import './WidgetTitle.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { WidgetKey, widgets } from '../../CreateWidgetWidget/widgets'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    widgetKey: WidgetKey
    customName?: string
    white?: boolean
    className?: ClassValue
}

export class WidgetTitle extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('WidgetTitle', () => ({
        'is-white': this.props.white,
    }))

    public render() {
        const widgetType = widgets.find(({ widgetKey }) => widgetKey === this.props.widgetKey)

        // should not be possible
        if (!widgetType) {
            return null
        }

        const { iconType, widgetKey } = widgetType
        const { className } = this.props

        return (
            <Row smallSpacing={true} className={this.bem.getClassName(className)}>
                <Icon className={this.bem.getElement('icon')} type={IconType[iconType]} />
                {this.renderTitle(widgetKey)}
            </Row>
        )
    }

    private renderTitle(translationKey: string) {
        const { customName } = this.props
        const defaultName = localize.translate(t => t.Customer.DashboardView.Widgets.DefaultNames[translationKey])

        if (customName) {
            return (
                <Row extraSmallSpacing={true} className={this.bem.getElement('title-container')}>
                    <SectionTitle className={this.bem.getElement('main-name')}>{customName}</SectionTitle>
                    <Paragraph className={this.bem.getElement('sub-name')}>{defaultName}</Paragraph>
                </Row>
            )
        }

        return <SectionTitle className={this.bem.getElement('main-name')}>{defaultName}</SectionTitle>
    }
}
