import './ProminentIconButton.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '../Icon/IconType'
import { Button } from './Button'

interface Props {
    className?: ClassValue
    icon: IconType
    to?: string
    onClick?: () => void
}

interface State {}

export class ProminentIconButton extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ProminentIconButton')

    public render() {
        const { className, onClick, to } = this.props

        return <Button className={this.bem.getClassName(className)} icon={IconType.trash} onClick={onClick} to={to} />
    }
}
