import './MainSearch.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { routes } from '~/views/routes'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
    onVisibiltyChange?: (active: boolean) => void
    onClose?: (value: boolean) => void
    customerSlug?: string
}

interface State {
    isActive: boolean
    searchQuery: string
}

export const MainSearch = withRouter(
    class MainSearch extends React.PureComponent<React.PropsWithChildren<Props>, State> {
        public state: State = {
            isActive: false,
            searchQuery: '',
        }

        private bem = new BEM('MainSearch', () => ({
            'is-active': !!this.state.isActive,
        }))

        public componentDidMount() {
            document.addEventListener('keyup', this.handleKeyUp)
        }

        public componentWillUnmount() {
            document.removeEventListener('keyup', this.handleKeyUp)
        }

        public render() {
            const { className } = this.props

            const searchInput = (
                <>
                    <form onSubmit={this.handleFormSubmit} className={this.bem.getElement('form')}>
                        <label className={this.bem.getElement('form-label')} htmlFor={'search'}>
                            <Icon className={this.bem.getElement('search-icon')} type={IconType.search} />
                        </label>
                        <input
                            type={'text'}
                            id={'search'}
                            name={'search'}
                            role={'search'}
                            onChange={this.handleInputChange}
                            autoFocus={true}
                            className={this.bem.getElement('form-input')}
                        />
                        <Button
                            className={this.bem.getElement('clear-button')}
                            icon={IconType.close}
                            type={ButtonType.noStyling}
                            onClick={this.handleButtonClick}
                        />
                    </form>
                </>
            )

            return <div className={this.bem.getClassName(className)}>{searchInput}</div>
        }

        private handleButtonClick = () => {
            const { onVisibiltyChange, onClose } = this.props

            this.setState(
                prevState => ({
                    isActive: !prevState.isActive,
                }),
                () => {
                    if (onVisibiltyChange) {
                        onVisibiltyChange(this.state.isActive)
                    }

                    if (onClose) {
                        onClose(false)
                    }
                }
            )
        }

        private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                searchQuery: event.target.value.trim(),
            })
        }

        private handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const { history, customerSlug } = this.props

            history.push(routes.customer(customerSlug).search.query(this.state.searchQuery))
            this.handleButtonClick()
        }

        private handleKeyUp = (event: KeyboardEvent): void => {
            // 27 = ESC
            if (event.keyCode !== 27) {
                return
            }

            this.setState({ isActive: false })
        }
    }
)
