import React from 'react'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { localize, notification, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import gql from 'graphql-tag'
import { breadcrumbs } from '~/views/breadcrumbs'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Bold } from '~/components/Core/Typography/Bold'
import {
    CustomerFrameworksTableContainer,
    ColumnItemCheckedStateEnum,
} from '~/components/Domain/CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableContainer'
import {
    CustomerFrameworkColorEnumType,
    CustomerFrameworkIconEnumType,
    CustomerFrameworkOverviewDocument,
    CustomerFrameworkOverviewQueryVariables,
    CustomerFrameworkOverviewQuery,
    EditCustomerFrameworksTopicsMutation,
    EditCustomerFrameworksTopicsMutationVariables,
} from '~/generated/graphql'
import { CustomerFrameworksOptionsButton } from '~/components/Domain/CustomerFramework/CustomerFrameworksOptionsButton'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { EditCustomerFrameworksTopics } from '~/components/Domain/CustomerFramework/CustomerFrameworkMutations/EditCustomerFrameworksTopics'
import { MutationFn, Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {
    editable: boolean
    isPristine: boolean
}

// TODO: delete when cleaning up (at the end of the sprint)
export const ALL_CUSTOMER_FRAMEWORKS_QUERY = gql`
    query allCustomerFrameworks($customerSlug: String) {
        customerFrameworks(customerSlug: $customerSlug, all: true) {
            id
            name
            color
            icon
        }
    }
`

export interface CustomerFrameworkWithFramework {
    id: number
    name: string
    color: CustomerFrameworkColorEnumType
    icon: CustomerFrameworkIconEnumType
    framework: {
        id: number
        name: string
        themes?: {
            id: string
            idn: number
            name: string
            topics?: {
                id: string
                idn: number
                name?: string
                isChecked: boolean
                isNew?: boolean
                addedAt?: Date | null
            }[]
        }[]
    }
}

class CustomerFrameworkOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        editable: false,
        isPristine: true,
    }

    private containerRef = React.createRef<CustomerFrameworksTableContainer>()
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks)

    public render() {
        return (
            <Query<CustomerFrameworkOverviewQuery, CustomerFrameworkOverviewQueryVariables>
                query={CustomerFrameworkOverviewDocument}
                variables={{ customerSlug: this.context.customer.slug }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data) {
                        return null
                    }

                    const customerFrameworks = data.customerFrameworks as CustomerFrameworkWithFramework[]

                    return (
                        <EditCustomerFrameworksTopics>
                            {(mutate, { loading }) => (
                                <>
                                    <PageHeader
                                        title={this.loc(t => t.title)}
                                        breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                                        actionComponent={this.renderActionComponent(
                                            mutate,
                                            loading,
                                            customerFrameworks
                                        )}
                                    />
                                    <Paragraph>
                                        {this.loc(t => t.subTitle)}
                                        <Bold inline={true}>{this.loc(t => t.legalFramework)}</Bold>
                                    </Paragraph>
                                    <CustomerFrameworksTableContainer
                                        customerFrameworks={customerFrameworks}
                                        editable={this.state.editable}
                                        onCheckboxChange={this.handleOnCheckboxChange}
                                        ref={this.containerRef}
                                    />
                                </>
                            )}
                        </EditCustomerFrameworksTopics>
                    )
                }}
            </Query>
        )
    }

    private renderActionComponent(
        mutate: MutationFn<EditCustomerFrameworksTopicsMutation, EditCustomerFrameworksTopicsMutationVariables>,
        loading: boolean,
        customerFrameworks: CustomerFrameworkWithFramework[]
    ) {
        const isConsultant = permissions.isConsultantUser()
        if (!isConsultant) {
            return null
        }

        const { editable } = this.state

        if (editable) {
            return (
                <Row alignRight={true}>
                    <Button
                        type={ButtonType.tertiary}
                        onClick={() => this.setState({ editable: false })}
                        disabled={loading}
                    >
                        {localize.translate(t => t.Core.DrawerModal.cancel)}
                    </Button>
                    <Button
                        onClick={() => this.handleSubmit(mutate, customerFrameworks)}
                        disabled={this.state.isPristine}
                        loading={loading}
                    >
                        {localize.translate(t => t.Core.DrawerModal.confirm)}
                    </Button>
                </Row>
            )
        }

        return (
            <Row alignRight={true}>
                <CustomerFrameworksOptionsButton
                    customerFrameworks={customerFrameworks}
                    handleOnEditClick={this.handleOnEditClick}
                />
            </Row>
        )
    }

    private handleOnCheckboxChange = (isPristine: boolean) => {
        if (!this.containerRef.current) {
            return
        }

        this.setState({ isPristine })
    }

    private async handleSubmit(
        mutate: MutationFn<EditCustomerFrameworksTopicsMutation, EditCustomerFrameworksTopicsMutationVariables>,
        customerFrameworks: CustomerFrameworkWithFramework[]
    ) {
        if (!this.containerRef.current?.state) {
            return
        }

        const fields = this.getSubmitFields(customerFrameworks)
        const response = await mutate({
            variables: { fields },
        })

        if (response && response.data?.editCustomerFrameworksTopics) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.setState({ editable: false })
        }
    }

    private handleOnEditClick = () => {
        this.setState({ editable: true })
    }

    private getSubmitFields(customerFrameworks: CustomerFrameworkWithFramework[]) {
        const {
            maxDepth,
            state: { columnItems },
        } = this.containerRef.current!

        interface CustomerFrameworkFields {
            customerFrameworkId: number
            topicIds: number[]
            themeIds: number[]
        }
        const fields: CustomerFrameworkFields[] = customerFrameworks.map(({ id }) => ({
            customerFrameworkId: id,
            themeIds: [],
            topicIds: [],
        }))
        columnItems.forEach(({ rowItemId, parentId, yIndex, xIndex, checkedState }) => {
            if (checkedState !== ColumnItemCheckedStateEnum.checked) {
                return
            }

            if (yIndex !== maxDepth) {
                return
            }

            fields[xIndex].topicIds.push(rowItemId)
            fields[xIndex].themeIds.push(parentId)
        })

        return fields
    }
}

export const CustomerFrameworkOverviewView = withRouter(CustomerFrameworkOverviewViewComponent)
