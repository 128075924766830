import './TopicDesignAndEffectivenessUnassessedArticlesTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ExpandableList } from '~/components/Core/DataDisplay/ExpandableList/ExpandableList'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { LawArticleType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { getRouteForType, getIconForItem, getExpirationForType } from '~/utils/linkedItemNaming'
import { LinkList } from '~/components/Core/Layout/LinkList'
import { Row } from '~/components/Core/Layout/Row'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { LinkedItemsTypeMap } from '~/graphql/types/Item'
import { ExpiredArticleWarning } from '../../LawAndRegulation/ExpiredArticleWarning'

interface Props {
    unassessedArticles: LawArticleType[]
}

export class TopicDesignAndEffectivenessUnassessedArticlesTable extends React.PureComponent<
    React.PropsWithChildren<Props>
> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicDesignAndEffectivenessUnassessedArticlesTable')

    public render() {
        const { unassessedArticles } = this.props

        return (
            <ExpandableList
                className={this.bem.getClassName()}
                label={this.renderListLabel(unassessedArticles)}
                renderList={() => (
                    <Column smallSpacing={true}>
                        <LinkList
                            preventShowMoreBehaviour={true}
                            items={unassessedArticles.map(this.transformArticleForLinkList)}
                            renderExpiredStatus={(_, expired) => (
                                <ExpiredArticleWarning expired={expired} className={this.bem.getElement('warning')} />
                            )}
                        />
                    </Column>
                )}
            />
        )
    }

    private renderListLabel(unassessedArticles: LawArticleType[]) {
        const label = localize.translate(
            t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessView.unassessedArticles,
            { smart_count: unassessedArticles.length }
        )

        const hasExpiredArticles = unassessedArticles.some(a => a.expired?.expiredAt)
        if (hasExpiredArticles) {
            return (
                <Row smallSpacing={true}>
                    <Icon type={IconType.attention} className={this.bem.getElement('expired-icon')} />
                    <Paragraph bold={true}>{label}</Paragraph>
                </Row>
            )
        }

        return <Paragraph bold={true}>{label}</Paragraph>
    }

    private transformArticleForLinkList = (article: LawArticleType) => {
        const customerSlug = this.context.customer.slug

        if (article.attachmentIsLeading && article.attachments && article.attachments[0]) {
            const routeOrHrefOrPdf = article.attachments[0].mimetype === 'application/pdf' ? 'pdf' : 'href'
            return {
                label: article.title,
                icon: getIconForItem(article),
                [routeOrHrefOrPdf]: getRouteForType(customerSlug, 'LawArticle', article),
                expired: getExpirationForType(LinkedItemsTypeMap.abstractLawArticle, article),
            }
        }

        return {
            label: article.title,
            icon: getIconForItem(article),
            route: getRouteForType(customerSlug, 'LawArticle', article),
            expired: getExpirationForType(LinkedItemsTypeMap.abstractLawArticle, article),
        }
    }
}
