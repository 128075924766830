import './ThemeDetailEditView.scss'
import React from 'react'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import {
    MarkTopicAsNonApplicableFormMutation,
    MarkTopicAsNonApplicableFormMutationResponse,
    MarkTopicAsNonApplicableFormMutationVariables,
} from '~/components/Domain/Themes/TopicNonApplicableForm/MarkTopicAsNonApplicableFormMutation'
import { ThemeQuery } from '~/components/Domain/LawAndRegulation/ThemeQuery'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'
import { MarkTopicsAsNonApplicableFormListQuery } from '~/components/Domain/Themes/TopicNonApplicableFormList/MarkTopicsAsNonApplicableFormListQuery'
import { MarkTopicsAsNonApplicableFormList } from '~/components/Domain/Themes/TopicNonApplicableFormList/MarkTopicsAsNonApplicableFormList'
import { MutationFn } from 'react-apollo'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize, notification } from '~/bootstrap'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ThemesDetailCardTopicType } from '~/components/Domain/Themes/ThemesDetailCardList/ThemesDetailCardListQuery'
import { BEM } from '~/services/BEMService'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

type MarkTopicNonApplicableFN = MutationFn<
    MarkTopicAsNonApplicableFormMutationResponse,
    MarkTopicAsNonApplicableFormMutationVariables
>

interface State {
    markedAsNonApplicable: { reason: string | null; topicId: number }[]
    markedAsApplicable: number[]
}

class ThemeDetailEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        markedAsNonApplicable: [],
        markedAsApplicable: [],
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDetailEditView)
    private bem = new BEM('ThemeDetailEditView')

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        const pageBreadcrumbs = [
            breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
            breadcrumbs.customer(this.context.customer.slug).legalFramework.themes.index,
        ]

        return (
            <MarkTopicAsNonApplicableFormMutation>
                {(markTopicsAsNonApplicable, { loading }) => (
                    <ThemeQuery themeId={parseInt(id, 10)}>
                        {theme => (
                            <>
                                <Page className={this.bem.getClassName()}>
                                    <PageHeader
                                        suffix={
                                            <CustomerFrameworkIconList customerFrameworks={theme.customerFrameworks} />
                                        }
                                        title={theme.name}
                                        breadCrumbs={pageBreadcrumbs}
                                    />
                                </Page>
                                <div className={this.bem.getElement('table-container')}>
                                    <Page>
                                        <Row spaceBetween>
                                            <SectionTitle>{this.loc(t => t.MarkTopicNonApplicable)}</SectionTitle>
                                            {this.renderAction(loading, markTopicsAsNonApplicable)}
                                        </Row>
                                        <MarkTopicsAsNonApplicableFormListQuery themeId={theme.id}>
                                            {({ loading, loadingMore, data }) => (
                                                <MarkTopicsAsNonApplicableFormList
                                                    loading={loading}
                                                    loadingMore={loadingMore}
                                                    topics={data?.nodes || []}
                                                    onTopicApplicableChange={this.handleOnTopicApplicableChange}
                                                />
                                            )}
                                        </MarkTopicsAsNonApplicableFormListQuery>
                                    </Page>
                                </div>
                            </>
                        )}
                    </ThemeQuery>
                )}
            </MarkTopicAsNonApplicableFormMutation>
        )
    }

    private renderAction(loading: boolean, mutate: MarkTopicNonApplicableFN) {
        return (
            <Column>
                <Row alignRight={true} alignCenter={false} smallSpacing={true}>
                    <Button type={ButtonType.tertiary} onClick={this.onClose} className={this.bem.getElement('close')}>
                        {this.loc(t => t.Actions.Cancel)}
                    </Button>
                    <Button loading={loading} onClick={this.handleOnSubmit(mutate)}>
                        {this.loc(t => t.Actions.Save)}
                    </Button>
                </Row>
            </Column>
        )
    }

    private handleOnTopicApplicableChange = (
        topic: ThemesDetailCardTopicType,
        applicability: { reason: string | null; isApplicable: boolean }
    ) => {
        if (applicability.isApplicable) {
            const markedAsApplicable = [...this.state.markedAsApplicable, topic.id]
            const markedAsNonApplicable = this.state.markedAsNonApplicable.filter(t => t.topicId !== topic.id)

            this.setState({ markedAsNonApplicable, markedAsApplicable })

            return
        }

        const markedAsApplicable = this.state.markedAsApplicable.filter(id => id !== topic.id)
        const markedAsNonApplicable = [
            ...this.state.markedAsNonApplicable.filter(t => t.topicId !== topic.id),
            { reason: applicability.reason, topicId: topic.id },
        ]

        this.setState({ markedAsApplicable, markedAsNonApplicable })
    }

    private handleOnSubmit = (markTopicsAsNonApplicable: MarkTopicNonApplicableFN) => async () => {
        const { markedAsApplicable, markedAsNonApplicable } = this.state

        const response = await markTopicsAsNonApplicable({
            variables: {
                markedAsApplicable,
                markedAsNonApplicable,
            },
        })

        if (response && !response.errors?.length) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.onClose()
        }
    }

    private onClose = () => {
        const { id } = this.props.match.params

        this.props.history.push(routes.customer(this.context.customer.slug).legalFramework.themes.view(id))
    }
}

export const ThemeDetailEditView = withRouter(ThemeDetailEditViewComponent)
