import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { ActionBar } from '~/components/Core/Layout/ActionBar'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'

import { RiskSettingsFieldsContainer } from '~/components/Domain/Settings/Control/ControlRiskFields/RiskSettingsFieldsContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Column } from '~/components/Core/Layout/Column'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import {
    EditRiskSettingsMutation,
    EditRiskSettingsMutationFN,
    EditRiskSettingsMutationVariables,
} from '~/components/Domain/Settings/Control/EditRiskSettingsMutation'
import { routes } from '~/views/routes'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {}

class ComplianceSettingsEditViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Settings.Attributes)
    public render() {
        return (
            <EditRiskSettingsMutation>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleOnSubmit(mutate)}>
                        <Column>
                            <PageHeader
                                title={this.loc(t => t.ControlSettings.RiskHeatmap.control)}
                                breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).settings.index]}
                            />
                            <Column smallSpacing={true}>
                                <ComponentTitle
                                    title={this.loc(t => t.ControlSettings.RiskHeatmap.title)}
                                    bold={true}
                                />
                                <Paragraph wrap={true}>
                                    {this.loc(t => t.ControlSettings.RiskHeatmap.description)}
                                </Paragraph>
                            </Column>
                            <FieldSet>
                                <Column>
                                    <RiskSettingsFieldsContainer isEditable={true} />
                                </Column>
                            </FieldSet>
                            <ActionBar>
                                <Row alignRight={true}>
                                    <Button type={ButtonType.tertiary} onClick={() => this.props.history.goBack()}>
                                        {localize.translate(t => t.Generic.cancel)}
                                    </Button>
                                    <Button submit={true} loading={loading}>
                                        {localize.translate(t => t.Generic.save)}
                                    </Button>
                                </Row>
                            </ActionBar>
                        </Column>
                    </Form>
                )}
            </EditRiskSettingsMutation>
        )
    }

    private handleOnSubmit = (mutate: EditRiskSettingsMutationFN) => async (formState: FormState) => {
        const variables: EditRiskSettingsMutationVariables = {
            fields: {
                highImpactDescription: formState.highImpactDescription,
                lowImpactDescription: formState.lowImpactDescription,
                highPropbabiltyDescription: formState.highPropbabiltyDescription,
                lowPropbabiltyDescription: formState.lowPropbabiltyDescription,
            },
        }

        const response = await mutate({ variables })

        if (response && response.data && response.data.editCustomerSettings) {
            notification.success(localize.translate(t => t.Generic.successfullyEdited))
            this.props.history.push(routes.customer(this.context.customer.slug).settings.compliance.index)
        }
    }
}

export const ComplianceSettingsEditView = withRouter(ComplianceSettingsEditViewComponent)
