import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentDocumentType,
    TopicAssessmentGradeType,
    TopicAssessmentType,
} from '~/generated/graphql'
import { EditTopicAssessment, EditTopicAssessmentMutationFN } from '../../mutations/EditTopicAssessment'
import { TopicAssessmentFields } from './TopicAssessmentFields'
import { readAndSanitizeMultipleFiles } from '~/utils/fileSanitizer'

interface Props {
    topicId: number
    selectedArticleIds: number[]
    allArticles?: boolean
    parentId: number
    type: TopicAssessmentDesignOrEffectiveNessType
    label: React.ReactNode
    closeModal: () => void
    onSubmit?: (grade?: TopicAssessmentGradeType) => void
    defaultValues?: TopicAssessmentType
    loading: boolean
}

interface State {
    isEditing: boolean
    files?: File[]
    validFileErrorMessage: string | null
}

export class TopicAssessmentModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isEditing: false,
        validFileErrorMessage: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicAssessmentModal)

    public render() {
        const { closeModal, type } = this.props

        const title =
            type === TopicAssessmentDesignOrEffectiveNessType.design
                ? this.loc(t => t.designTitle)
                : this.loc(t => t.effectivenessTitle)

        return (
            <EditTopicAssessment>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal requestClose={closeModal} title={title} actions={() => this.renderActions(loading)}>
                            {this.renderContent()}
                        </Modal>
                    </Form>
                )}
            </EditTopicAssessment>
        )
    }

    private handleSubmit = (mutate: EditTopicAssessmentMutationFN) => async (formState: FormState) => {
        const { onSubmit, closeModal, defaultValues, parentId } = this.props
        if (!defaultValues?.id) {
            return
        }

        const { activeDepartmentId } = this.context
        const { description, grade } = formState
        const { newFiles, topicAssessmentDocumentIdsToRemove } = this.getDocumentsToUpdate()

        const isValidFile = newFiles ? await readAndSanitizeMultipleFiles(newFiles) : true
        if (isValidFile) {
            const response = await mutate({
                variables: {
                    topicAssessmentId: defaultValues.id,
                    departmentId: activeDepartmentId,
                    linkedItemId: parentId,
                    fields: {
                        description,
                        grade: grade?.value,
                        documents: newFiles,
                        topicAssessmentDocumentIdsToRemove,
                    },
                },
            })

            if (response && response.data?.editTopicAssessment) {
                notification.success(localize.translate(t => t.Generic.successfullyEdited))

                if (onSubmit) {
                    onSubmit(grade?.value)
                }

                closeModal()
            }
        } else {
            this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
        }
    }

    private renderActions = (loading: boolean) => {
        const { isEditing } = this.state

        const notAllowed = !permissions.canEditTopicDnE(this.context.activeDepartmentId)

        if (isEditing) {
            return (
                <Row alignRight={true}>
                    <Button
                        type={ButtonType.tertiary}
                        onClick={() => this.setState({ isEditing: false, files: [] })}
                        disabled={loading}
                    >
                        {localize.translate(t => t.Generic.cancel)}
                    </Button>
                    <Button submit={true} loading={loading} disabled={notAllowed}>
                        {this.loc(t => t.confirmLabel)}
                    </Button>
                </Row>
            )
        }

        return (
            <Row alignRight={true}>
                <Button onClick={() => this.setState({ isEditing: true })} loading={loading} disabled={notAllowed}>
                    {localize.translate(t => t.Generic.edit)}
                </Button>
            </Row>
        )
    }

    private renderContent() {
        const { loading, topicId, selectedArticleIds, allArticles, label, defaultValues } = this.props
        const { isEditing } = this.state
        const { validFileErrorMessage } = this.state

        if (loading) {
            return <Spinner delayed={true} />
        }

        return (
            <>
                <ErrorMessage path="editTopicAssessment" />
                {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}

                <TopicAssessmentFields
                    topicId={topicId}
                    selectedArticleIds={selectedArticleIds}
                    allArticles={allArticles}
                    label={label}
                    onChangeFiles={files => this.setState({ ...this.state, files })}
                    isEditing={isEditing}
                    defaultValues={defaultValues}
                />
            </>
        )
    }

    private getDocumentsToUpdate() {
        const topicAssessmentDocuments = this.props.defaultValues?.topicAssessmentDocuments || []
        const files = this.state.files ? this.state.files : this.getDefaultFiles(topicAssessmentDocuments)

        const topicAssessmentDocumentIdsToRemove: number[] = []
        for (const { id, file } of topicAssessmentDocuments) {
            // Known edge case/bug:
            //      If a file with the same name exists in the list & is removed,
            //      all instances of the topicNoteDocument will be removed
            //
            // Because this is unlikely & because they can readd
            // this case will not be handled at the moment (mvp)
            const isRemoved = !files.some(f => f.name === file?.name)
            if (isRemoved) {
                topicAssessmentDocumentIdsToRemove.push(id)
            }
        }

        const newFiles: File[] = []
        for (const file of files) {
            const isNew = !topicAssessmentDocuments.some(t => t.file?.name === file.name)
            if (isNew) {
                newFiles.push(file)
            }
        }

        return { newFiles, topicAssessmentDocumentIdsToRemove }
    }

    private getDefaultFiles(topicAssessmentDocuments?: TopicAssessmentDocumentType[] | null) {
        if (!topicAssessmentDocuments || !topicAssessmentDocuments.length) {
            return []
        }

        const defaultFiles: File[] = []
        for (const { file } of topicAssessmentDocuments) {
            if (file) {
                defaultFiles.push({ name: file.name } as File)
            }
        }

        return defaultFiles
    }
}
