import './InboxControlAlertLabel.scss'

import React from 'react'
import { IconType } from '~/components/Core/Icon/IconType'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AlertImpactLevel, DepartmentType } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { DepartmentSwitchPopup } from '../../Department/DepartmentSwitchPopup'
import { Icon } from '~/components/Core/Icon/Icon'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    alertId: number
    alertName: string
    alertDepartment?: DepartmentType
    impactLevel?: AlertImpactLevel
    className?: ClassValue
    hideIcon?: boolean
    isPublishedFromCustomerNews?: boolean | null
}

export class InboxControlAlertLabel extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxControlAlertLabel', () => ({
        'is-high-impact': this.props.impactLevel === AlertImpactLevel.high,
        'is-medium-impact': this.props.impactLevel === AlertImpactLevel.medium,
        'is-low-impact': this.props.impactLevel === AlertImpactLevel.low,
        isIconHidden: this.props.hideIcon,
    }))

    public render() {
        const { alertDepartment } = this.props
        const { activeDepartmentId } = this.context

        const isInDifferentDepartment = alertDepartment ? alertDepartment.id !== activeDepartmentId : false
        if (isInDifferentDepartment) {
            return (
                <DepartmentSwitchPopup
                    departmentIdToToggleTo={alertDepartment!.id}
                    departmentNameToToggleTo={alertDepartment!.name}
                    onToggle={this.proceedToAlert}
                    render={this.renderLabel}
                />
            )
        }

        return this.renderLabel()
    }

    private renderLabel = (openPopup?: () => void) => {
        const { alertName, className, isPublishedFromCustomerNews } = this.props

        return (
            <Row noSpacing className={this.bem.getClassName(className)}>
                <IconLabel
                    className={this.bem.getElement('label')}
                    iconType={IconType.inbox}
                    onClick={openPopup || this.proceedToAlert}
                >
                    {alertName}
                </IconLabel>
                {isPublishedFromCustomerNews && <Icon large subtler type={IconType.signaling} />}
            </Row>
        )
    }

    private proceedToAlert = () => {
        const { alertId } = this.props

        const alertPath = routes.customer(this.context.customer.slug).inbox.detail.view(alertId)
        const newWindow = window.open(alertPath, '_blank', 'noopener, noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }
}
