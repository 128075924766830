import './ComplianceCardList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title: string
}

interface State {}

export class ComplianceCardList extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('ComplianceCardList')

    public render() {
        const { children, className, title } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <h4 className={this.bem.getElement('title')}>{title}</h4>
                {children}
            </div>
        )
    }
}
