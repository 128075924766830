import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { ColumnOptions, Table } from '~/components/Core/DataDisplay/Table/Table'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Guard } from '~/components/Core/Guard/Guard'
import { IconType } from '~/components/Core/Icon/IconType'
import { Section } from '~/components/Core/Layout/Section'
import { Truncate } from '~/components/Core/Text/Truncate'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { FileType } from '~/services/FileService'
import { ControlDocumentModal } from '../../Compliance/Controls/ControlDocumentModal'
import { EditControlDocumentButton } from '../../Compliance/Controls/EditControlDocumentButton'
import { UploadControlDocumentModal } from '../../Compliance/Controls/UploadControlDocumentModal'
import { Control } from './ControlDetail/ControlQuery'

interface Props {
    control: Control
    viewOnly?: boolean
}

interface State {}

export class ControlDetailDocuments extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.DetailView)

    public render() {
        const { control, viewOnly = false } = this.props

        const controlDocuments = control.controlDocuments || []

        const nonArchivedDocuments = controlDocuments.filter(document => !document.archivedAt)
        const archivedDocuments = controlDocuments.filter(document => !!document.archivedAt)

        const allDocuments = [...nonArchivedDocuments, ...archivedDocuments].map(document => {
            const rowItem = {
                id: document.id,
                inactive: !!document.archivedAt,
                columns: {
                    document: document.file?.mimetype && (
                        <FilePreviewModal
                            file={document.file}
                            fileType={FileType.controlDocument}
                            modalTitleSuffix={
                                document.archivedAt ? (
                                    <LabelTag subtle={true} label={this.loc(t => t.archived)} />
                                ) : null
                            }
                        >
                            {previewFile => (
                                <Button
                                    onClick={previewFile}
                                    type={document.archivedAt ? ButtonType.subtle : ButtonType.actionLink}
                                >
                                    <Truncate truncateMiddle={document.file?.name} />
                                </Button>
                            )}
                        </FilePreviewModal>
                    ),
                },
            }

            if (!viewOnly) {
                return {
                    ...rowItem,
                    columns: {
                        ...rowItem.columns,
                        action: (
                            <Guard condition={permissions.canEditControlDocuments(this.context.activeDepartmentId)}>
                                <ModalManager
                                    render={openModal => <EditControlDocumentButton onClick={openModal} />}
                                    renderModal={requestClose => (
                                        <ControlDocumentModal
                                            control={control}
                                            controlDocument={document}
                                            requestClose={requestClose}
                                        />
                                    )}
                                />
                            </Guard>
                        ),
                    },
                }
            }

            return rowItem
        })

        const columns: ColumnOptions[] = [{ field: 'document', headerLabel: this.loc(t => t.document) }]

        if (!viewOnly) {
            columns.push({ field: 'action', headerLabel: '' })
        }

        return (
            <Section
                title={this.loc(t => t.documents)}
                renderAction={
                    !viewOnly && permissions.canUpdateComplianceAndDocuments(this.context.activeDepartmentId)
                        ? () => (
                              <ModalManager
                                  render={openModal => (
                                      <Button
                                          type={ButtonType.actionLink}
                                          icon={IconType.addCircle}
                                          onClick={openModal}
                                      >
                                          {this.loc(t => t.uploadADocument)}
                                      </Button>
                                  )}
                                  renderModal={closeModal => (
                                      <UploadControlDocumentModal requestClose={closeModal} controlId={control.id} />
                                  )}
                              />
                          )
                        : undefined
                }
            >
                <Table columns={columns} data={allDocuments} />
            </Section>
        )
    }
}
