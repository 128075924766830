import './TaskControlOverviewView.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { ParamManager, NewParamState } from '~/components/Core/ParamManager/ParamManager'
import { Row } from '~/components/Core/Layout/Row'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { TaskControlLinkedTypeSelect } from '~/components/Domain/ControlViews/TaskControl/TaskControlLinkedTypeSelect'
import { ReviewTaskStatus, LinkedTaskItem } from '~/generated/graphql'
import { TaskSummaryList } from '~/components/Domain/ControlViews/TaskControl/TaskSummaryList'
import { TaskDepartmentFilter } from '~/components/Domain/Task/TaskDepartmentFilter'
import { BEM } from '~/services/BEMService'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ViewTaskModalContainer } from '~/components/Domain/Task/ViewTaskModalContainer'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{ id?: string }> {}

interface State {}

export interface TaskControlFilters {
    status?: ReviewTaskStatus
    linkType?: SelectOption
    isGenericType?: boolean
    activeDepartmentOnly?: boolean
}

class TaskControlOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.TaskControlOverview)
    private bem = new BEM('TaskControlOverviewView')

    private statusSelectOptions = [
        {
            label: this.loc(t => t.statusSelect.new),
            value: ReviewTaskStatus.new,
        },
        {
            label: this.loc(t => t.statusSelect.ignored),
            value: ReviewTaskStatus.ignored,
        },
        {
            label: this.loc(t => t.statusSelect.tobereviewed),
            value: ReviewTaskStatus.tobereviewed,
        },
        {
            label: this.loc(t => t.statusSelect.any),
            value: null,
        },
    ]

    private linkTypeSelectOptions = [
        {
            label: this.loc(t => t.linkTypeSelect.all),
            value: 'all',
        },
        {
            label: this.loc(t => t.linkTypeSelect.generic),
            value: 'generic',
        },
        {
            label: localize.translate(t => t.Entities.Alert),
            value: LinkedTaskItem.alert,
        },
        {
            label: localize.translate(t => t.Entities.Control),
            value: LinkedTaskItem.control,
        },
        {
            label: localize.translate(t => t.Entities.Risk),
            value: LinkedTaskItem.risk,
        },
        {
            label: localize.translate(t => t.Entities.Assessment),
            value: LinkedTaskItem.assessment,
        },
        {
            label: localize.translate(t => t.Entities.Monitoring),
            value: LinkedTaskItem.monitoringRapport,
        },
        {
            label: localize.translate(t => t.Entities.Topic),
            value: LinkedTaskItem.topic,
        },
        {
            label: localize.translate(t => t.Entities.Radar),
            value: LinkedTaskItem.radar,
        },
        {
            label: localize.translate(t => t.Entities.Consultation),
            value: LinkedTaskItem.consultation,
        },
    ]

    private modalManagerRef = React.createRef<ModalManager>()

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.modalManagerRef.current?.toggleModal()
        }
    }

    public render() {
        return (
            <>
                <ParamManager<TaskControlFilters>
                    defaultState={{
                        status: ReviewTaskStatus.new,
                        linkType: this.linkTypeSelectOptions[0],
                        isGenericType: false,
                        activeDepartmentOnly: true,
                    }}
                >
                    {({ paramState, setParamState }) => (
                        <Page className={this.bem.getClassName()}>
                            <PageHeader
                                className={this.bem.getElement('header')}
                                title={this.loc(t => t.title)}
                                actionComponent={this.renderFilters(paramState, setParamState)}
                            />
                            <div className={this.bem.getElement('table-container')}>
                                <TaskSummaryList
                                    className={this.bem.getElement('table')}
                                    paramState={paramState}
                                    isFilterActive={this.isFilterActive(paramState)}
                                />
                            </div>
                        </Page>
                    )}
                </ParamManager>
                <ModalManager
                    ref={this.modalManagerRef}
                    openOnMount={this.props.match.params.id !== undefined}
                    renderModal={this.renderTaskModal.bind(this)}
                    hasClosed={() =>
                        this.props.history.replace({
                            ...this.props.location,
                            pathname: routes.customer(this.context.customer.slug).control.taskControl.index,
                        })
                    }
                />
            </>
        )
    }

    private isFilterActive(paramState: TaskControlFilters) {
        if (paramState.linkType && paramState.linkType !== this.linkTypeSelectOptions[0]) {
            return true
        }

        // If paramState does not exist it means any is active
        if (paramState.status !== ReviewTaskStatus.new) {
            return true
        }

        if (!paramState.activeDepartmentOnly) {
            return true
        }

        return false
    }

    private renderFilters(
        paramState: TaskControlFilters,
        setParamState: (newsState: NewParamState<TaskControlFilters>) => void
    ) {
        return (
            <Row>
                <FilterDropdown hasActiveFilter={this.isFilterActive(paramState)}>
                    <FilterOption label={this.loc(t => t.statusSelect.label)} forInputName="status">
                        <Form.Select
                            name={'status'}
                            isFilter={true}
                            options={this.statusSelectOptions}
                            defaultValue={this.getStatusFilterDefaultValue(paramState)}
                            onChange={(option: SelectOption) =>
                                setParamState({ status: option.value as ReviewTaskStatus })
                            }
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.linkTypeSelect.label)} forInputName="linkType">
                        <TaskControlLinkedTypeSelect
                            defaultValue={this.getLinkTypeFilterDefaultValue(paramState)}
                            options={this.linkTypeSelectOptions}
                            onChange={option => this.onLinkTypeFilterChange(option, setParamState)}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.departments)} forInputName="taskDepartmentFilter">
                        <TaskDepartmentFilter
                            defaultValue={paramState.activeDepartmentOnly ? 'true' : 'false'}
                            onChange={option => setParamState({ activeDepartmentOnly: option?.value === 'true' })}
                        />
                    </FilterOption>
                </FilterDropdown>
            </Row>
        )
    }

    private renderTaskModal(closeModal: () => void) {
        if (!this.props.match.params.id) {
            closeModal()
        }

        const parsedId = parseInt(this.props.match.params.id!, 10)
        if (isNaN(parsedId)) {
            return closeModal()
        }

        return (
            <ViewTaskModalContainer
                taskId={parsedId}
                onChangeTask={() => closeModal()}
                onArchiveTask={() => closeModal()}
                onCompleteOrReopen={() => closeModal()}
                requestClose={closeModal}
            />
        )
    }

    private getLinkTypeFilterDefaultValue(paramState: TaskControlFilters) {
        let selectedOption

        if (paramState.linkType) {
            selectedOption = this.linkTypeSelectOptions.find(option => option.value === paramState.linkType?.value)
        }

        if (!selectedOption) {
            return undefined
        }

        return [selectedOption]
    }

    private getStatusFilterDefaultValue(paramState: TaskControlFilters) {
        let selectedOption

        if (paramState.status) {
            selectedOption = this.statusSelectOptions.find(option => option.value === paramState.status)
        }

        if (!selectedOption) {
            return undefined
        }

        return [selectedOption]
    }

    private onLinkTypeFilterChange(
        option: SelectOption,
        setParamState: (newsState: NewParamState<TaskControlFilters>) => void
    ) {
        if (option.value === 'generic') {
            setParamState({
                linkType: option,
                isGenericType: true,
            })

            return
        }

        setParamState({
            linkType: option,
            isGenericType: false,
        })
    }
}

export const TaskControlOverviewView = withRouter(TaskControlOverviewViewComponent)
