import React from 'react'
import { localize, notification } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { RepeatableFileInput } from '~/components/Core/DataEntry/RepeatableFileInput/RepeatableFileInput'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CreateTopicNote, CreateTopicNoteMutationFN } from './mutations/CreateTopicNote'
import { readAndSanitizeMultipleFiles } from '~/utils/fileSanitizer'

interface Props {
    topicId: number
    onCreate?: () => void
}

interface State {
    files: File[]
    validFileErrorMessage: string | null
}

export class AddNoteToTopicButtonContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        files: [],
        validFileErrorMessage: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.CreateTopicAssessment)

    public render() {
        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.actionLink} icon={IconType.addCircle} onClick={openModal}>
                        {this.loc(t => t.button)}
                    </Button>
                )}
                renderModal={this.renderModal}
            />
        )
    }

    private renderModal = (closeModal: () => void) => {
        const { topicId } = this.props
        const { validFileErrorMessage } = this.state

        return (
            <CreateTopicNote topicId={topicId}>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate, closeModal)}>
                        <Modal
                            requestClose={closeModal}
                            submitForm={true}
                            title={this.loc(t => t.title)}
                            confirmButtonLabel={this.loc(t => t.confirmLabel)}
                            loading={loading}
                            disabled={loading}
                        >
                            <ErrorMessage path="createTopicNote" />
                            {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}

                            {this.renderTitleField()}
                            {this.renderDocumentsField()}
                            {this.renderDescriptionField()}
                        </Modal>
                    </Form>
                )}
            </CreateTopicNote>
        )
    }

    private handleSubmit =
        (mutate: CreateTopicNoteMutationFN, closeModal: () => void) => async (formState: FormState) => {
            const { topicId, onCreate } = this.props
            const { files } = this.state
            const { title, description } = formState

            const isValidFile = files ? await readAndSanitizeMultipleFiles(files) : true

            if (isValidFile) {
                const response = await mutate({
                    variables: {
                        topicId,
                        departmentId: this.context.activeDepartmentId,
                        fields: {
                            title,
                            description,
                            documents: files,
                        },
                    },
                })

                if (response && response.data?.createTopicNote) {
                    notification.success(localize.translate(t => t.Generic.successfullyCreated))
                    closeModal()
                    this.setState({ files: [] })

                    if (onCreate) {
                        onCreate()
                    }
                }
            } else {
                this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
            }
        }

    private renderTitleField() {
        return (
            <Column smallSpacing={true}>
                <Paragraph bold={true}>{localize.translate(t => t.Generic.title)}</Paragraph>
                <Field forInput="title">
                    <Form.Input name="title" placeholder={this.loc(t => t.titlePlaceholder)} />
                </Field>
            </Column>
        )
    }

    private renderDocumentsField() {
        return <RepeatableFileInput name="documents" onChange={files => this.setState({ ...this.state, files })} />
    }

    private renderDescriptionField() {
        return (
            <Column smallSpacing={true}>
                <Field forInput="description" label={localize.translate(t => t.Generic.description)} />
                <Form.TextEditor name="description" />
            </Column>
        )
    }
}
