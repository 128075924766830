import './HierarchyList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {}

export class HierarchyList extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('HierarchyList')

    public render() {
        const { className, children } = this.props

        return <ul className={this.bem.getClassName(className)}>{children}</ul>
    }
}
