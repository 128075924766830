import React from 'react'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import gql from 'graphql-tag'
import { localize } from '~/bootstrap'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { Query } from 'react-apollo'
import { Content } from '~/components/Core/Layout/Content'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { LawAndRegulationArticleView } from './LawAndRegulationArticleView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Link, Routes, Route, matchPath } from 'react-router-dom'
import { LawSections } from '~/components/Domain/LawAndRegulation/LawSections'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'
import { routes } from '~/views/routes'

const GET_LAW_QUERY = gql`
    query law($abstractId: Int!) {
        law(abstractLawId: $abstractId) {
            id
            abstractLawId
            name
        }
    }
`

const LIST_LAW_ATTACHMENTS = gql`
    query lawAttachments($lawId: Int!) {
        lawAttachments(lawId: $lawId) {
            id
            title
        }
    }
`

interface Law {
    id: number
    abstractLawId: number
    name: string
}

interface LawAttachment {
    lawAttachments: {
        id: number
        title: string
    }[]
}

interface RouteParams {
    abstractId?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

class LawAndRegulationDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const abstractId = parseInt(this.props.match.params.abstractId!, 10)

        return (
            <PageQuery<Law>
                query={GET_LAW_QUERY}
                variables={{
                    abstractId,
                }}
            >
                {law => {
                    const { name } = law
                    const matched = matchPath(
                        {
                            path: routes.customer(this.context.customer.slug).lawAndRegulation.detail.article(),
                        },
                        window.location.pathname
                    )

                    const defaultActiveArticle = matched ? parseInt(matched.params.articleId!, 10) : undefined

                    // if (defaultActiveArticle) {
                    //     this.props.history.replace(
                    //         routes
                    //             .customer(this.context.customer.slug)
                    //             .lawAndRegulation.detail.article(abstractId, defaultActiveArticle)
                    //     )
                    // }

                    return (
                        <PageDetail
                            breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).lawAndRegulation.index]}
                            renderTitle={() => <ArticleTitle title={name} />}
                            sidebarLeftOrientation={true}
                            renderSidebar={() => (
                                <>
                                    <LawSections
                                        abstractLawId={law.abstractLawId}
                                        defaultActiveArticle={defaultActiveArticle}
                                        onLoadFirstArticle={section => {
                                            this.props.history.replace(
                                                newRoutes
                                                    .customer(this.context.customer.slug)
                                                    .lawAndRegulation.detail.article(section.articleId as number)
                                            )
                                        }}
                                    />
                                    {this.renderLawAttachments(law.id)}
                                </>
                            )}
                        >
                            <PageDetailContent>
                                <Routes>
                                    <Route
                                        path={newRoutes
                                            .customer(this.context.customer.slug)
                                            .lawAndRegulation.detail.article()}
                                        element={<LawAndRegulationArticleView />}
                                    />
                                </Routes>
                            </PageDetailContent>
                        </PageDetail>
                    )
                }}
            </PageQuery>
        )
    }

    private renderLawAttachments(lawId: number) {
        return (
            <Query<LawAttachment>
                query={LIST_LAW_ATTACHMENTS}
                variables={{
                    lawId,
                }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return (
                            <Content>
                                <Spinner delayed={true} />
                            </Content>
                        )
                    }

                    if (!data || !data.lawAttachments || data.lawAttachments.length === 0) {
                        return null
                    }

                    return (
                        <nav>
                            <ComponentTitle title={localize.translate(t => t.Generic.attachments)} />
                            <ul className="rf-SectionList">
                                {data.lawAttachments.map(attachment => (
                                    <li key={`attachment-${attachment.id}`}>
                                        <Link
                                            className={'rf-SectionList__text-link'}
                                            to={newRoutes
                                                .customer(this.context.customer.slug)
                                                .lawAndRegulation.detail.article(attachment.id)}
                                        >
                                            {attachment.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    )
                }}
            </Query>
        )
    }
}

export const LawAndRegulationDetailView = withRouter(LawAndRegulationDetailViewComponent)
