import './Row.scss'

import React, { CSSProperties } from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    evenSpace?: boolean
    spaceBetween?: boolean
    alignRight?: boolean
    flexStart?: boolean
    stretchChildren?: boolean
    alignCenter?: boolean
    style?: CSSProperties
    smallSpacing?: boolean
    extraSmallSpacing?: boolean
    noSpacing?: boolean
    wrap?: boolean
    fullWidth?: boolean
}

interface State {}

export class Row extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Row', () => ({
        'even-space': !!this.props.evenSpace,
        'flex-start': !!this.props.flexStart,
        'space-between': !!this.props.spaceBetween,
        'align-right': !!this.props.alignRight,
        'stretch-children': !!this.props.stretchChildren,
        'align-center': !!this.props.alignCenter,
        'small-spacing': !!this.props.smallSpacing,
        'extra-small-spacing': !!this.props.extraSmallSpacing,
        'no-spacing': !!this.props.noSpacing,
        'full-width': !!this.props.fullWidth,
        wrap: !!this.props.wrap,
    }))

    public render() {
        const { children, className, style } = this.props

        return (
            <div className={this.bem.getClassName(className)} style={style}>
                {children}
            </div>
        )
    }
}
