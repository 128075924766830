import './AttachmentList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Attachment } from '~/graphql/types/Attachment'
import { LinkList } from '~/components/Core/Layout/LinkList'
import { localize } from '~/bootstrap'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    className?: ClassValue
    attachments: Attachment[]
}

interface State {}

export class AttachmentList extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('AttachmentList')

    public render() {
        const { attachments, className } = this.props
        return (
            <LinkList
                className={this.bem.getClassName(className)}
                title={localize.translate(t => t.Generic.attachments)}
                items={attachments.map(attachment => ({
                    label: attachment.filename,
                    icon: IconType.attachment,
                    [attachment.mimetype === 'application/pdf' ? 'pdf' : 'href']: attachment.path,
                }))}
            />
        )
    }
}
