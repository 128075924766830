import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export interface RouteComponentProps<
    Params extends { [K in keyof Params]?: string } = {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    C = unknown,
    S = unknown
> {
    history: History
    location: Location<S>
    match: Match<Params>
}

export interface History {
    push(location: string | Location, options?: { state?: unknown }): void
    replace(to: string | Location, options?: { state?: unknown }): void
    goBack(): void
    canGoBack: boolean
}

export interface Location<S = unknown> {
    pathname: string
    search?: string
    state?: S
    hash?: string
    key?: string
}

interface Match<P = {}> {
    params: P
}

type WithRouterReturnType<
    P extends RouteComponentProps<any>,
    C extends React.ComponentType<P>
> = React.FunctionComponent<Omit<P, keyof RouteComponentProps<any>> & WithRouterProps<C>>

type WithRouterProps<C extends React.ComponentType<any>> = C extends React.ComponentClass
    ? { wrappedComponentRef?: React.Ref<InstanceType<C>> | undefined }
    : {}

export function withRouter<P extends RouteComponentProps<any>, C extends React.ComponentType<P>>(
    Component: React.ComponentType<P>
): WithRouterReturnType<P, C> {
    return (props: any) => {
        const location: Location = useLocation()
        const match: Match = { params: useParams() }

        const navigate = useNavigate()
        const history: History = {
            push: navigate,
            replace: (to, state) => navigate(to, { replace: true, state }),
            goBack: () => navigate(-1),
            canGoBack: location.key !== 'default',
        }

        return <Component {...props} location={location} history={history} match={match} />
    }
}
