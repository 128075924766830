import React from 'react'
import { Popper as PopperUnstyled } from '@mui/base/Popper'
import Fade from '@mui/material/Fade'
import { Paragraph } from '../Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import './Popper.scss'
import { Row } from '../Layout/Row'
import { ClickAwayListener } from '@mui/base'
import { PopperProps, PopperPlacementType } from '@mui/material'

interface Props {
    item: React.ReactNode
    position: PopperPlacementType
    contents: PopperContentItem[]
    popperOptions?: PopperProps['popperOptions']
    title?: string
    onClose?: () => void
    className?: ClassValue
    disabled?: boolean
}

export type PopperContentItem = PopperItemWithContent | CoreItem

export interface PopperItemWithContent extends CoreItem {
    contents: PopperContentItem[]
    position: PopperPlacementType
}

type CoreItem = Pick<Props, 'item' | 'title' | 'popperOptions'>

interface State {
    open: boolean
    hoveringMain: boolean
    hoveringContent: boolean
    forceClose: boolean
    anchorEl: HTMLElement | null
}

export class Popper extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        open: false,
        hoveringMain: false,
        hoveringContent: false,
        anchorEl: null,
        forceClose: false,
    }

    private divRef = React.createRef<HTMLDivElement>()
    private mouseEnterHandler = () => !this.state.hoveringMain && this.setState({ hoveringMain: true })
    private mouseLeaveHandler = () => this.state.hoveringMain && this.setState({ hoveringMain: false })

    // https://github.com/facebook/react/issues/6807#issuecomment-1240312500
    public componentDidMount() {
        if (!this.props.disabled) {
            this.divRef.current?.addEventListener('mouseenter', this.mouseEnterHandler)
            this.divRef.current?.addEventListener('mouseleave', this.mouseLeaveHandler)
        }
    }

    public componentWillUnmount(): void {
        this.divRef.current?.removeEventListener('mouseenter', this.mouseEnterHandler)
        this.divRef.current?.removeEventListener('mouseleave', this.mouseLeaveHandler)
    }

    public componentDidUpdate() {
        const { open, hoveringMain, hoveringContent, forceClose } = this.state
        if (!open && (hoveringMain || hoveringContent)) {
            this.setState({ open: true })
            return
        }

        if (open && !hoveringMain && !hoveringContent && !this.hasOpenSub()) {
            this.setState({ open: false }, () => this.props.onClose?.())
            return
        }

        if (forceClose) {
            this.setState({ open: false, hoveringContent: false, hoveringMain: false, forceClose: false })
        }
    }

    private subPopperRefs = new Array<React.RefObject<Popper> | null>(this.props.contents.length)
    private bem = new BEM('Popper')
    // we need a second bem, because the actual Popper isnt rendered as a descendant of the PopperContainer
    private containerBem = new BEM('PopperContainer')

    public render() {
        const { className, item, title, position, contents, popperOptions } = this.props

        return (
            <div className={this.containerBem.getClassName()} ref={this.divRef}>
                {item}
                <PopperUnstyled
                    anchorEl={this.divRef.current}
                    open={this.state.open}
                    placement={position}
                    transition={true}
                    onMouseEnter={() => !this.state.hoveringContent && this.setState({ hoveringContent: true })}
                    onMouseLeave={() => this.state.hoveringContent && this.setState({ hoveringContent: false })}
                    className={this.bem.getClassName(className)}
                    popperOptions={popperOptions}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={200}>
                            <div>
                                <ClickAwayListener
                                    onClickAway={() => !this.state.hoveringMain && this.setState({ forceClose: true })}
                                >
                                    <div
                                        className={this.bem.getElement('content', () => ({
                                            hasSingleContent: contents.length === 1,
                                        }))}
                                    >
                                        {title && this.renderTitle(title)}
                                        {contents.map(this.renderContentItem)}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </Fade>
                    )}
                </PopperUnstyled>
            </div>
        )
    }

    private renderTitle(title: string) {
        return (
            <Row className={this.bem.getElement('title-container')}>
                <Paragraph bold={true} className={this.bem.getElement('title')}>
                    {title}
                </Paragraph>
            </Row>
        )
    }

    private renderContentItem = (contentItem: PopperContentItem, n: number) => {
        if (!('contents' in contentItem) || !contentItem.contents?.length) {
            return this.renderItem(contentItem.item, n)
        }

        const ref = React.createRef<Popper>()
        this.subPopperRefs[n] = ref

        return (
            <Popper
                key={n}
                className={this.bem.getElement('nested-popper')}
                ref={ref}
                item={this.renderItem(contentItem.item, n)}
                title={contentItem.title}
                contents={contentItem.contents}
                position={contentItem.position}
                onClose={() => this.forceUpdate()}
                popperOptions={contentItem.popperOptions}
            />
        )
    }

    private renderItem(item: React.ReactNode, n: number) {
        return (
            <div key={n} className={this.bem.getElement('item-container')}>
                {item}
            </div>
        )
    }

    private hasOpenSub() {
        return this.subPopperRefs.some(r => r?.current?.state.open)
    }
}
