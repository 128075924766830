import './VisualRadar.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { RadarItemsGroupedByPeriod, RadarItemNode, Impact } from '~/views/Customer/Radar/RadarOverviewView'
import { RadarDot } from './RadarDot'
import { LocalStorageService, LocalStorageKeys } from '~/services/LocalStorageService'
import { permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    radarItemsGroupedByPeriod: RadarItemsGroupedByPeriod
    labelPrefix: string | undefined
}

interface Position {
    angle: number
    radius: number
}

interface RadarSpot {
    ring: number
    spotClassNames: {
        background: string
    }
    pendulumStyle: {
        transform: string
        top: string
    }
    position: Position
    meta: RadarItemNode
    style: {
        transform: string
    }
}

export class VisualRadar extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('VisualRadar')

    /**
     * Configuration variables to tweak radar
     * collision mechanism
     */
    private RADAR_SPOT_MINIMUM_DIFFERENCE_OF_ANGLES = 15
    private RADAR_SPOT_MINIMUM_DIFFERENCE_OF_RADIUSES = 15

    public render() {
        const { className, radarItemsGroupedByPeriod, labelPrefix } = this.props

        const periods = Object.keys(radarItemsGroupedByPeriod).reverse()

        return (
            <div className={this.bem.getClassName(className)}>
                <figure className={this.bem.getElement('radar-container')}>
                    <div className={this.bem.getElement('background-container')}>
                        <div className={this.bem.getElement('circle', () => ({ first: true }))}>
                            <div className={this.bem.getElement('circle', () => ({ second: true }))}>
                                <div className={this.bem.getElement('circle', () => ({ third: true }))}>
                                    <div className={this.bem.getElement('circle', () => ({ fourth: true }))} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={this.bem.getElement('circle-container')}>{this.renderCircles()}</div>
                </figure>
                <div className={this.bem.getElement('period-container')}>
                    {periods.map((period, index) => (
                        <div key={period} className={this.bem.getElement('period-wrapper', () => ({ [index]: true }))}>
                            <p>
                                {labelPrefix}
                                {period}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    private renderCircles() {
        const radarSpots = this.getRadarSpots()
        const canUnfollowRadarItem = permissions.canUnFollowRadarItem(this.context.activeDepartmentId)

        return radarSpots.map((radarSpot, i) => {
            return (
                <div
                    style={radarSpot.pendulumStyle}
                    key={`${radarSpot.meta.id}-${radarSpot.ring}`}
                    className={this.bem.getElement('pendelum')}
                >
                    <RadarDot
                        className={`${radarSpot.spotClassNames.background}`}
                        radarItem={radarSpot.meta}
                        style={radarSpot.style}
                        userCanUnfollow={canUnfollowRadarItem}
                    />
                </div>
            )
        })
    }

    private getBackgroundColorByPhase(phase: number, following?: boolean): string {
        const backgroundColorByPhase = {
            1: this.bem.getElement('phase-5', () => ({ isUnfollowed: !following })),
            2: this.bem.getElement('phase-4', () => ({ isUnfollowed: !following })),
            3: this.bem.getElement('phase-3', () => ({ isUnfollowed: !following })),
            4: this.bem.getElement('phase-2', () => ({ isUnfollowed: !following })),
            5: this.bem.getElement('phase-1', () => ({ isUnfollowed: !following })),
        }

        return backgroundColorByPhase[phase] || backgroundColorByPhase[1]
    }

    private getScaleByImpact(impact: Impact) {
        const scaleByImpact = {
            [Impact.Low]: 0.5,
            [Impact.Average]: 0.75,
            [Impact.High]: 1,
        }

        return scaleByImpact[impact] || scaleByImpact[Impact.Low]
    }

    private getRadiusRangeByRing(ring: number): [number, number] {
        const radiusRangeByRing = {
            0: [5, 15],
            1: [30, 36],
            2: [46, 60],
            3: [75, 85],
        }

        return radiusRangeByRing[ring]
    }

    private getRadarSpots(): RadarSpot[] {
        const { radarItemsGroupedByPeriod } = this.props

        const radarSpots = Object.entries(radarItemsGroupedByPeriod)
            .reverse()
            .reduce<RadarSpot[]>((radarSpots, [periode, radarItems], ring) => {
                radarItems.forEach(radarItem => {
                    const radarSpot = this.getRadarSpot(radarItem, ring, radarSpots)
                    radarSpots.push(radarSpot)
                })

                return radarSpots
            }, [])

        this.cacheRadarPositionDate(radarSpots)

        return radarSpots
    }

    private getRadarSpot(radarItem: RadarItemNode, ring: number, radarSpots: RadarSpot[]): RadarSpot {
        const [radiusFrom, radiusTo] = this.getRadiusRangeByRing(ring)
        const { angle, radius } = this.getAngleAndRadius(radarItem, radiusFrom, radiusTo, radarSpots, ring)
        const inverseAngle = this.getInverseAngle(angle)
        const circleImpact = this.getScaleByImpact(radarItem.impact)
        const circlePhase = this.getBackgroundColorByPhase(radarItem.phase.order, radarItem.following)
        // TODO: integrate unfollowed state of radar

        return {
            ring,
            pendulumStyle: {
                transform: `rotate(${angle}deg)`,
                top: `${radius}%`,
            },
            spotClassNames: {
                background: circlePhase,
            },
            position: { angle, radius },
            meta: radarItem,
            style: {
                transform: `rotate(${inverseAngle}deg) scale(${circleImpact})`,
            },
        }
    }

    /**
     * Tries to get the angle and radius from a cached position or calculate a new one
     */
    private getAngleAndRadius(
        radarItem: RadarItemNode,
        radiusFrom: number,
        radiusTo: number,
        radarSpots: RadarSpot[],
        ring: number
    ): { angle: number; radius: number } {
        const persistedData = LocalStorageService.getItem(LocalStorageKeys.RadarDotPosition)

        if (persistedData) {
            const radarData = JSON.parse(persistedData)
            const key = `${radarItem.id}:${ring}`
            const positionFromCache = radarData[key]

            if (positionFromCache) {
                return positionFromCache
            }
        }

        return this.findPosition(-82, 82, radiusFrom, radiusTo, radarSpots)
    }

    // Inverse a given angle number to rotate the radar dot back to 0 degrees
    private getInverseAngle(angle: number) {
        if (angle < 0) {
            return Math.abs(angle)
        }

        return -Math.abs(angle)
    }

    private getRandomNumberBetweenTwo(min: number, max: number) {
        return Math.floor(min + Math.random() * (max + 1 - min))
    }

    /**
     * Find a position for the radar
     */
    private findPosition(
        angleFrom: number,
        angleTo: number,
        radiusFrom: number,
        radiusTo: number,
        listOfCoordinates: RadarSpot[]
    ): Position {
        let angle
        let radius
        const iterationLimit = 100 // to prevent application from crashing when no more spots are available
        let currentIteration = 0

        while (!angle || !radius) {
            currentIteration++

            const hasReachedIterationLimit = currentIteration >= iterationLimit
            const pickedAngle = this.getRandomNumberBetweenTwo(angleFrom, angleTo)
            const pickedRadius = this.getRandomNumberBetweenTwo(radiusFrom, radiusTo)

            if (hasReachedIterationLimit || this.isPositionAvailable(pickedAngle, pickedRadius, listOfCoordinates)) {
                angle = pickedAngle
                radius = pickedRadius
            }
        }

        return { angle, radius }
    }

    /**
     * Whether a position is available occording to a given list of coordinates
     */
    private isPositionAvailable(angle: number, radius: number, listOfCoordinates: RadarSpot[]): boolean {
        return !listOfCoordinates.find(coordinates => {
            return this.isNearPosition(coordinates.position.angle, coordinates.position.radius, angle, radius)
        })
    }

    /**
     * Whether position A is near position B
     */
    private isNearPosition(angleA: number, radiusA: number, angleB: number, radiusB: number): boolean {
        const isAngleNear = Math.abs(angleA - angleB) < this.RADAR_SPOT_MINIMUM_DIFFERENCE_OF_ANGLES
        const isRadiusNear = Math.abs(radiusA - radiusB) < this.RADAR_SPOT_MINIMUM_DIFFERENCE_OF_RADIUSES

        return isAngleNear && isRadiusNear
    }

    private cacheRadarPositionDate(radarSpots: RadarSpot[]) {
        const dataToCache = radarSpots.reduce((object, spot) => {
            const key = `${spot.meta.id}:${spot.ring}`
            const { angle, radius } = spot.position

            object[key] = { angle, radius }
            return object
        }, {})

        LocalStorageService.setItem(LocalStorageKeys.RadarDotPosition, JSON.stringify(dataToCache))
    }
}
