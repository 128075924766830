import './Select.scss'

import { default as ReactSelect } from 'react-select'
import { default as CreatableReactSelect } from 'react-select/creatable'

import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { ValueType, ActionMeta, InputActionMeta } from 'react-select/src/types'
import { isString } from 'lodash-es'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    name: string
    multi?: boolean
    placeholder?: string
    options?: SelectOption[] | GroupedSelectOption[]
    defaultValue?: SelectOption[]
    value?: SelectOption[]
    onEndReached?: () => void
    onChange?: (selectedOptions: SelectOption | SelectOption[] | null, name: string) => void
    onSearch?: (query: string) => void
    loading?: boolean
    clearable?: boolean
    searchable?: boolean
    creatable?: boolean
    disabled?: boolean
    isFilter?: boolean
    openMenuOnFocus?: false
    openMenuOnClick?: false
}

export interface SelectOption<TValue = string | number | null | object> {
    value: TValue
    label: string | JSX.Element
    isDisabled?: boolean
    __isNew__?: boolean
}

export interface GroupedSelectOption<TValue = string | number | null | object> {
    label: string | JSX.Element
    options: {
        value: TValue
        label: string
        isDisabled?: boolean
    }[]
}

export class Select extends React.PureComponent<React.PropsWithChildren<Props>> {
    public state = {
        selectedOption: this.props.defaultValue
            ? this.props.multi
                ? this.props.defaultValue
                : this.props.defaultValue[0]
            : undefined,
    }

    public loc = localize.namespaceTranslate(t => t.Core.Select)

    public bem = new BEM('Select', () => ({
        'is-multi': this.props.multi,
        'is-filter': this.props.isFilter,
    }))

    public render() {
        const { creatable } = this.props
        const props = this.getProps()

        if (creatable) {
            return (
                <CreatableReactSelect
                    {...props}
                    formatCreateLabel={this.getCreateMessage}
                    createOptionPosition="first"
                />
            )
        }

        return <ReactSelect {...props} />
    }

    private getProps() {
        const { options, multi, loading, placeholder, searchable, defaultValue, clearable, disabled } = this.props
        const { onEndReached, value, openMenuOnFocus, openMenuOnClick } = this.props

        const shouldReplaceDropdownWithSearchIcon = openMenuOnClick === false && openMenuOnFocus === false

        const props = {
            noOptionsMessage: this.getNoOptionsMessage,
            placeholder: placeholder ? placeholder : this.loc(t => t.placeholder),
            isDisabled: disabled,
            className: this.bem.getElement('container', () => ({ hasSearchIcon: shouldReplaceDropdownWithSearchIcon })),
            classNamePrefix: this.bem.getClassName(),
            onMenuScrollToBottom: onEndReached,
            onMenuScrollToTop: onEndReached,
            onMenuOpen: onEndReached,
            isSearchable: searchable,
            isMulti: multi,
            defaultValue: defaultValue,
            value,
            isClearable: clearable,
            onChange: this.handleOnChange,
            isLoading: loading,
            onInputChange: this.handleOnInputChange,
            loadingMessage: this.getLoadingMessage,
            components: this.getPropComponents(shouldReplaceDropdownWithSearchIcon),
            options: options,
            openMenuOnClick,
            openMenuOnFocus,
        }

        return props
    }

    private handleOnInputChange: (newValue: string, actionMeta: InputActionMeta) => void = (value, action) => {
        const { onSearch } = this.props

        if (onSearch) {
            onSearch(value)
        }
    }

    private handleOnChange: (value: ValueType<any, any>, action: ActionMeta<any>) => void = (value, action) => {
        const { onChange, name } = this.props

        if (!onChange) {
            return
        }

        if (!value) {
            onChange(null, name)
            return
        }

        // Ensure empty strings are treated as "null"
        if (!Array.isArray(value) && isString(value.value)) {
            value.value = value.value.trim()
            onChange(value.value ? value : null, name)
            return
        }

        onChange(value, name)
    }

    private getNoOptionsMessage = ({ inputValue }: { inputValue: string }) => {
        if (!inputValue.trim()) {
            return this.loc(t => t.noResults)
        }

        return this.loc(t => t.noResultsFound, { value: inputValue })
    }

    private getCreateMessage = (inputValue: string) => {
        const messageTextPart1 = this.loc(t => t.createMessagePart1)
        const messageTextPart2 = this.loc(t => t.createMessagePart2)

        return (
            <Row className={this.bem.getElement('create')}>
                <Icon type={IconType.addCircle} className={this.bem.getElement('create-icon')} />
                <span>
                    {messageTextPart1}
                    <strong>{inputValue}</strong>
                    {messageTextPart2}
                </span>
            </Row>
        )
    }

    private getLoadingMessage = ({ inputValue }: { inputValue: string }) => {
        if (!inputValue.trim()) {
            return this.loc(t => t.loading)
        }

        return this.loc(t => t.loadingResults, { value: inputValue })
    }

    private getPropComponents(shouldReplaceDropdownWithSearchIcon: boolean) {
        const baseComponents = {
            IndicatorSeparator: null,
            ClearIndicator: (props: any) => (
                <div {...props.innerProps} className={this.bem.getElement('indicator')}>
                    <Icon className={this.bem.getElement('icon')} type={IconType.close} />
                </div>
            ),
        }

        if (shouldReplaceDropdownWithSearchIcon) {
            return {
                ...baseComponents,
                DropdownIndicator: (props: any) => (
                    <div {...props.innerProps} className={this.bem.getElement('search')}>
                        <Icon className={this.bem.getElement('icon')} type={IconType.search} />
                    </div>
                ),
            }
        }

        return {
            ...baseComponents,
            DropdownIndicator: (props: any) => (
                <div {...props.innerProps} className={this.bem.getElement('indicator', () => ({ dropdown: true }))}>
                    <Icon className={this.bem.getElement('icon')} type={IconType.arrowDown} />
                </div>
            ),
        }
    }
}
