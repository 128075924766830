import './SubMenu.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { NavLink, matchPath } from 'react-router-dom'
import { localize } from '~/bootstrap'
import { NotificationBubble } from '~/components/Core/DataDisplay/NotificationBubble/NotificationBubble'

interface NavigationItem {
    route: string
    label: string
    notificationAmount?: number
    disabled?: boolean
    hidden?: boolean
}

interface Props {
    title: string | JSX.Element
    className?: ClassValue
    navigation: NavigationItem[]
    onNavigateBack?: () => void
    style?: React.CSSProperties
    onLinkClick?: () => void
}

interface State {}

export class SubMenu extends React.Component<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('SubMenu')

    public render() {
        const { className, onNavigateBack, navigation, style, title } = this.props

        return (
            <div style={style} className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('back-button')}>
                    <button type="button" onClick={onNavigateBack} className={this.bem.getElement('back')}>
                        <Icon type={IconType.arrowLeft} className={this.bem.getElement('back-icon')} />
                        {localize.translate(t => t.Generic.close)}
                    </button>
                </div>

                <div className={this.bem.getElement('title')}>{title}</div>

                <ul className={this.bem.getElement('navigation')}>
                    {navigation.map((item, index) => {
                        if (item.hidden) {
                            return null
                        }

                        return (
                            <li key={`${item.route}-${index}`} className={this.bem.getElement('navigation-item')}>
                                {this.renderLink(item)}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    private renderNotification(item: NavigationItem) {
        if (!item.notificationAmount) {
            return null
        }

        return <NotificationBubble amount={item.notificationAmount} className={this.bem.getElement('notification')} />
    }

    private renderLink(item: NavigationItem) {
        const { onLinkClick } = this.props

        if (item.disabled) {
            return (
                <div className={this.bem.getElement('navigation-link', () => ({ disabled: true }))}>{item.label}</div>
            )
        }

        return (
            <NavLink
                className={this.bem.getElement('navigation-link', () => {
                    const matches = matchPath(
                        {
                            path: item.route,
                            caseSensitive: false,
                            end: false,
                        },
                        window.location.pathname
                    )

                    return {
                        active: !!matches,
                    }
                })}
                to={item.route}
                onClick={onLinkClick}
            >
                {item.label}
                {this.renderNotification(item)}
            </NavLink>
        )
    }
}
