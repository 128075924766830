import React from 'react'

import { Page } from '~/components/Core/Layout/Page'
import { fileService, localize } from '~/bootstrap'
import { PageActions } from '~/components/Core/Layout/PageActions'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { MediaQuery, Screensize } from '~/components/Core/Layout/MediaQuery'
import { MobileItemList } from '~/components/Core/Layout/MobileItemList'
import { MobileListItem } from '~/components/Core/Layout/MobileListItem'
import { PageOffsetPadding } from '~/components/Core/Layout/PageOffsetPadding'
import { routes } from '~/views/routes'
import { TextLink } from '~/components/Core/Text/TextLink'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { FrameworkAbbreviationList } from '~/components/Domain/Customer/Framework/FrameworkAbbreviationList/FrameworkAbbreviationList'
import { ParamManager, SetParamStateFn } from '~/components/Core/ParamManager/ParamManager'
import { Row } from '~/components/Core/Layout/Row'
import {
    CreateCustomerUsersExport,
    CreateCustomerUsersExportMutationFN,
} from '~/components/Domain/Customer/CreateCustomerUsersExport'
import {
    CreateCustomersExport,
    CreateCustomersExportMutationFN,
} from '~/components/Domain/Consultants/Customer/mutations/CreateCustomersExport'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {}

interface Filters {
    search: string | null
}

interface Customer {
    id: number
    name: string
    slug: string
    filledSeats: number
}

interface CustomerData {
    customers: Customer[]
    loading: boolean
    loadingMore: boolean
    fetchMore?: () => void
    canFetchMore?: boolean
}

const ORGANIZATION_CUSTOMERS_QUERY = gql`
    query customers($take: Int, $skip: Int, $filters: CustomerFiltersType) {
        customers(take: $take, skip: $skip, filters: $filters) {
            hasNextPage
            nodes {
                id
                name
                slug
                filledSeats
                frameworks {
                    id
                    abbreviation
                }
            }
        }
    }
`

class CustomersOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = UserContext
    public context: UserContextValue

    private loc = localize.namespaceTranslate(t => t.Consultant.CustomersOverviewView)
    private assignedCustomerTitle = (<ComponentTitle title={this.loc(t => t.myCustomers)} />)
    private otherCustomerTitle = (<ComponentTitle title={this.loc(t => t.otherCustomers)} />)

    public render() {
        return (
            <ParamManager<Filters>
                defaultState={{
                    search: null,
                }}
            >
                {({ paramState, setParamState }) => {
                    const { search } = paramState

                    return (
                        <Page>
                            <PageHeader title={this.loc(t => t.title)} />
                            {this.renderPageActions(setParamState, paramState)}
                            <PaginatableQuery<Customer>
                                query={ORGANIZATION_CUSTOMERS_QUERY}
                                variables={{
                                    filters: { name: search, linkedConsultantIds: [this.context!.consultant!.id] },
                                }}
                            >
                                {({
                                    data: linkedCustomers,
                                    loading: linkedLoading,
                                    loadingMore: loadingMoreLoading,
                                    fetchMore,
                                    canFetchMore,
                                }) => (
                                    <InfiniteScrollQuery<Customer>
                                        query={ORGANIZATION_CUSTOMERS_QUERY}
                                        variables={{
                                            filters: {
                                                name: search,
                                                excludedLinkedConsultantIds: [this.context!.consultant!.id],
                                            },
                                        }}
                                    >
                                        {({
                                            data: notLinkedCustomers,
                                            loading: notLinkedLoading,
                                            loadingMore: notLinkedLoadingMore,
                                        }) => {
                                            const myCustomersData = linkedCustomers ? linkedCustomers.nodes : []
                                            const otherCustomersData = notLinkedCustomers
                                                ? notLinkedCustomers.nodes
                                                : []
                                            return (
                                                <>
                                                    <MediaQuery breakpoint={Screensize.mobile}>
                                                        {this.renderMobileList(
                                                            {
                                                                customers: myCustomersData,
                                                                loading: linkedLoading,
                                                                loadingMore: loadingMoreLoading,
                                                                fetchMore,
                                                                canFetchMore,
                                                            },
                                                            {
                                                                customers: otherCustomersData,
                                                                loading: notLinkedLoading,
                                                                loadingMore: notLinkedLoadingMore,
                                                            }
                                                        )}
                                                    </MediaQuery>

                                                    <MediaQuery breakpoint={Screensize.tablet}>
                                                        {this.renderTables(
                                                            {
                                                                customers: myCustomersData,
                                                                loading: linkedLoading,
                                                                loadingMore: loadingMoreLoading,
                                                                fetchMore,
                                                                canFetchMore,
                                                            },
                                                            {
                                                                customers: otherCustomersData,
                                                                loading: notLinkedLoading,
                                                                loadingMore: notLinkedLoadingMore,
                                                            }
                                                        )}
                                                    </MediaQuery>
                                                </>
                                            )
                                        }}
                                    </InfiniteScrollQuery>
                                )}
                            </PaginatableQuery>
                        </Page>
                    )
                }}
            </ParamManager>
        )
    }

    private renderPageActions(setParamState: SetParamStateFn<Filters>, paramState: Filters) {
        return (
            <CreateCustomerUsersExport>
                {(mutateUsersExport, { loading: usersExportLoading }) => (
                    <CreateCustomersExport>
                        {(mutateCustomersExport, { loading: customersExportLoading }) => {
                            const loading = usersExportLoading || customersExportLoading

                            return (
                                <PageActions
                                    primaryComponent={
                                        <Search
                                            placeholder={this.loc(t => t.byCustomerName)}
                                            onChange={searchField => setParamState({ search: searchField })}
                                            disabled={loading}
                                        />
                                    }
                                    secondaryComponent={
                                        <Row smallSpacing>
                                            <Button
                                                onClick={this.handleCustomersExport(
                                                    mutateCustomersExport,
                                                    paramState.search
                                                )}
                                                loading={customersExportLoading}
                                                type={ButtonType.secondary}
                                            >
                                                {this.loc(t => t.exportCustomersLabel)}
                                            </Button>
                                            <Button
                                                onClick={this.handleUsersExport(mutateUsersExport)}
                                                loading={usersExportLoading}
                                                type={ButtonType.secondary}
                                            >
                                                {this.loc(t => t.exportButtonLabel)}
                                            </Button>
                                            <Button
                                                to={routes.consultant.customers.create}
                                                icon={IconType.plus}
                                                disabled={loading}
                                            >
                                                {this.loc(t => t.linkNewCustomer)}
                                            </Button>
                                        </Row>
                                    }
                                />
                            )
                        }}
                    </CreateCustomersExport>
                )}
            </CreateCustomerUsersExport>
        )
    }

    private renderMobileList(customers: CustomerData, otherCustomers: CustomerData) {
        return (
            <>
                {this.assignedCustomerTitle}
                <PageOffsetPadding>
                    {customers.loading && <Spinner />}
                    {this.renderMobileCustomerData(customers.customers, true)}
                    {customers.loadingMore && <Spinner />}
                </PageOffsetPadding>
                {customers.canFetchMore && (
                    <Button type={ButtonType.actionLink} onClick={customers.fetchMore}>
                        {localize.translate(t => t.Generic.loadMore)}
                    </Button>
                )}
                {this.otherCustomerTitle}
                <PageOffsetPadding>
                    {otherCustomers.loading && <Spinner />}
                    {this.renderMobileCustomerData(otherCustomers.customers)}
                    {otherCustomers.loadingMore && <Spinner />}
                </PageOffsetPadding>
            </>
        )
    }

    private renderMobileCustomerData(customers: Customer[], renderEnvironmentButton: boolean = false) {
        return (
            <MobileItemList>
                {customers.map(customer => (
                    <MobileListItem
                        key={customer.id}
                        title={
                            <TextLink to={routes.consultant.customers.profile(customer.id)}>{customer.name}</TextLink>
                        }
                        meta={[
                            [
                                <>
                                    {this.renderAmountOfEmployees(customer)}
                                    {this.renderFrameworks(customer)}
                                </>,
                                renderEnvironmentButton
                                    ? this.renderCustomerEnvironmentButton(customer.slug)
                                    : undefined,
                            ],
                        ]}
                    />
                ))}
            </MobileItemList>
        )
    }

    private renderTables(customers: CustomerData, otherCustomers: CustomerData) {
        const assignedCustomersTableData = customers.customers.map(this.transformCustomerDataToTableData)
        const otherCustomersTableData = otherCustomers.customers.map(this.transformCustomerDataToTableData)

        return (
            <>
                {this.assignedCustomerTitle}
                <Table
                    loading={customers.loading}
                    loadingMore={customers.loadingMore}
                    columns={[
                        { field: 'name', headerLabel: localize.translate(t => t.Customer.Attributes.name) },
                        {
                            field: 'amountOfEmployees',
                            headerLabel: localize.translate(t => t.Customer.Attributes.amountOfEmployees),
                        },
                        {
                            field: 'frameworks',
                            headerLabel: localize.translate(t => t.Customer.Attributes.frameworks),
                        },
                    ]}
                    rowAction={({ row: { id } }) => {
                        const customer = customers.customers.find(({ id: toMatch }) => toMatch === id)
                        return this.renderCustomerEnvironmentButton(customer!.slug)
                    }}
                    data={assignedCustomersTableData}
                />
                {customers.canFetchMore && (
                    <Button type={ButtonType.actionLink} onClick={customers.fetchMore}>
                        {localize.translate(t => t.Generic.loadMore)}
                    </Button>
                )}
                {this.otherCustomerTitle}
                <Table
                    loading={otherCustomers.loading}
                    loadingMore={otherCustomers.loadingMore}
                    columns={[
                        { field: 'name', headerLabel: localize.translate(t => t.Customer.Attributes.name) },
                        {
                            field: 'amountOfEmployees',
                            headerLabel: localize.translate(t => t.Customer.Attributes.amountOfEmployees),
                        },
                        {
                            field: 'frameworks',
                            headerLabel: localize.translate(t => t.Customer.Attributes.frameworks),
                        },
                    ]}
                    data={otherCustomersTableData}
                />
            </>
        )
    }

    private transformCustomerDataToTableData = (customer: Customer) => {
        return {
            id: customer.id,
            columns: {
                name: this.renderCustomerLink(customer),
                amountOfEmployees: this.renderAmountOfEmployees(customer),
                frameworks: this.renderFrameworks(customer),
            },
        }
    }

    private renderCustomerLink(customer: Customer) {
        return <TableLink to={routes.consultant.customers.profile(customer.id)}>{customer.name}</TableLink>
    }

    private renderAmountOfEmployees(customer: Customer) {
        return this.loc(t => t.amountOfEmployees, {
            smart_count: customer.filledSeats,
            count: localize.numberFormat(customer.filledSeats),
        })
    }

    private renderCustomerEnvironmentButton(slug: string) {
        return (
            <Button
                type={ButtonType.secondary}
                to={{
                    pathname: `/${slug}/legal-framework`,
                    state: { lastConsultantRoute: window.location.pathname },
                }}
            >
                {this.loc(t => t.toEnvironment)}
            </Button>
        )
    }

    // TODO: fix customer typing
    private renderFrameworks(customer: any) {
        const usedFrameworks = customer.frameworks || []

        return <FrameworkAbbreviationList frameworks={usedFrameworks} />
    }

    private handleCustomersExport = (mutate: CreateCustomersExportMutationFN, search: string | null) => async () => {
        const response = await mutate({ variables: { search } })

        if (response && response.data?.createCustomersExport) {
            const { token, filename } = response.data.createCustomersExport
            const url = fileService.createFileUrlFromToken(token, filename)
            window.open(url, '_blank')
        }
    }

    private handleUsersExport = (mutate: CreateCustomerUsersExportMutationFN) => async () => {
        const response = await mutate()

        if (response && response.data?.createCustomerUsersExport) {
            const { token, filename } = response.data.createCustomerUsersExport
            const url = fileService.createFileUrlFromToken(token, filename)
            window.open(url, '_blank')
        }
    }
}

export const CustomersOverviewView = withRouter(CustomersOverviewViewComponent)
