import React from 'react'
import { NormsGrid } from '../Norms/NormsGrid'
import { NormThemeCard } from '../Norms/NormThemeCard'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import gql from 'graphql-tag'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { localize } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Row } from '~/components/Core/Layout/Row'
import { NormsSort } from '../Norms/NormsSort'
import { Step } from '~/components/Core/Layout/Step'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { GroupedNorms } from './Matrix/AssessmentMatrixLayout'
import { AssessmentCoverageType } from '~/generated/graphql'

interface Props {
    requestClose?: () => void
    onCardClick?: (themeId: number, nextStep: () => void) => void
    nextStep?: () => void
    selectedNorms?: GroupedNorms[]
}

interface State {
    search?: string | null
    sort?: {
        [key: string]: string
    }
}

interface Theme {
    id: number
    name: string
    customerFrameworks: CustomerFramework[]
    assessmentCoverage: AssessmentCoverageType
}

const LIST_NORM_THEMES = gql`
    query norms(
        $take: Int
        $skip: Int
        $filters: ThemeFilters
        $customerSlug: String
        $departmentId: Int!
        $sort: ThemeSort
    ) {
        themes(take: $take, skip: $skip, filters: $filters, customerSlug: $customerSlug, sort: $sort) {
            count
            totalCount
            hasNextPage
            nodes {
                id
                name
                customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                    id
                    name
                    color
                    icon
                }
                assessmentCoverage(departmentId: $departmentId) {
                    noted
                    assessed
                    total
                }
            }
        }
    }
`

export class AssessmentAddNormThemeSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        search: null,
        sort: undefined,
    }

    public render() {
        const { search, sort } = this.state
        const { requestClose, selectedNorms = [] } = this.props

        return (
            <Step
                title={localize.translate(
                    t => t.Customer.Compliance.Assessments.AssesmentAddNormModal.steps.themeSelect
                )}
                titleActionComponent={
                    <Row>
                        <Search onChange={search => this.setState({ search })} />
                        <NormsSort onChange={sort => this.setState({ sort })} />
                    </Row>
                }
                actionBar={
                    <Button type={ButtonType.tertiary} onClick={requestClose}>
                        {localize.translate(t => t.Core.DrawerModal.cancel)}
                    </Button>
                }
            >
                <InfiniteScrollQuery<Theme>
                    query={LIST_NORM_THEMES}
                    useWindow={false}
                    variables={{
                        filters: {
                            search,
                            customerFrameworkIds: this.context.activeProfiles,
                            departmentId: this.context.activeDepartmentId,
                        },
                        sort,
                        departmentId: this.context.activeDepartmentId,
                        customerSlug: this.context.customer.slug,
                    }}
                >
                    {({ data, loading, loadingMore, canFetchMore, hasPaginated }) => {
                        const themesData = data ? data.nodes : []

                        if (loading) {
                            return <Spinner delayed={true} />
                        }

                        if (!loading && !themesData.length) {
                            return (
                                <NoResults
                                    label={
                                        search
                                            ? localize.translate(t => t.Generic.search.noResultsFor, {
                                                  searchQuery: search,
                                              })
                                            : localize.translate(t => t.Customer.Compliance.Norms.NormsOverView.empty)
                                    }
                                />
                            )
                        }

                        return (
                            <NormsGrid>
                                {themesData.map(theme => (
                                    <NormThemeCard
                                        key={theme.id}
                                        title={theme.name}
                                        assessmentCoverage={theme.assessmentCoverage}
                                        icons={theme.customerFrameworks}
                                        onClick={() => this.onThemeCardClick(theme.id)}
                                        disabled={!!selectedNorms.find(norm => norm.themeId === theme.id)}
                                    />
                                ))}
                            </NormsGrid>
                        )
                    }}
                </InfiniteScrollQuery>
            </Step>
        )
    }

    private onThemeCardClick = (themeId: number) => {
        const { onCardClick, nextStep } = this.props

        if (!onCardClick || !nextStep) {
            return
        }

        onCardClick(themeId, nextStep)
    }
}
