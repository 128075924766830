import React from 'react'

import { Step } from '~/components/Core/Layout/Step'
import { localize, permissions } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ControlQuery } from '../Controls/ControlDetail/ControlQuery'
import { ControlDetailGeneralFields } from '~/views/Customer/Compliance/Controls/ControlDetailGeneralFields'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { ControlDetailDocuments } from '../Controls/ControlDetailDocuments'
import { Row } from '~/components/Core/Layout/Row'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { ViewRisksInControlSidebar } from '../Controls/ViewRisksInControlSidebar'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Guard } from '~/components/Core/Guard/Guard'
import { RiskControlType } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    selectedControlId?: number
    onDelete?: () => void
    linkedRisks?: RiskControlType[]
    onClickRisk: (riskId: number) => void
    activeDepartmentId: number
}

interface State {}

export class AssessmentControlView extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.AssessmentControlView)

    public render() {
        const { selectedControlId, onDelete, linkedRisks, onClickRisk, activeDepartmentId } = this.props

        if (!selectedControlId) {
            return <NoResults />
        }

        const hasLinkedRisks = linkedRisks && linkedRisks.length > 0
        const canUnlinkControl = !hasLinkedRisks

        return (
            <ControlQuery controlId={selectedControlId}>
                {control => {
                    return (
                        <Step
                            title={control.name}
                            sidebarTitle={localize.translate(
                                t => t.Customer.Compliance.Assessments.ViewRisksInControlSidebar.title
                            )}
                            sidebar={
                                <ViewRisksInControlSidebar linkedRisks={linkedRisks || []} onClickRisk={onClickRisk} />
                            }
                            actionBar={
                                <Guard
                                    condition={
                                        !permissions.canUpdateComplianceAndDocuments(this.context.activeDepartmentId)
                                    }
                                >
                                    <Row spaceBetween={!!onDelete} fullWidth={!!onDelete}>
                                        {onDelete && (
                                            <ConfirmModal
                                                onConfirm={onDelete}
                                                title={this.loc(t => t.delete)}
                                                message={this.loc(t => t.deleteConfirmMessage)}
                                            >
                                                {openModal => (
                                                    <Tooltip
                                                        content={this.loc(t => t.deleteDisabledTooltip)}
                                                        disabled={canUnlinkControl}
                                                    >
                                                        <Button
                                                            danger={true}
                                                            type={ButtonType.secondary}
                                                            onClick={openModal}
                                                            disabled={!canUnlinkControl}
                                                        >
                                                            {this.loc(t => t.delete)}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </ConfirmModal>
                                        )}
                                    </Row>
                                </Guard>
                            }
                        >
                            <ControlDetailGeneralFields control={control} activeDepartmentId={activeDepartmentId} />
                            <ControlDetailDocuments control={control} viewOnly={true} />
                        </Step>
                    )
                }}
            </ControlQuery>
        )
    }
}
