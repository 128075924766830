import React from 'react'
import { matchPath } from 'react-router-dom'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Popper, PopperContentItem } from '~/components/Core/Popper/Popper'
import { PopperButtonItem } from '~/components/Core/Popper/PopperButtonItem'
import { OnboardingStep, OnboardingStepProps } from '~/components/Domain/Onboarding/OnboardingStep'
import { BEM, ClassValue } from '~/services/BEMService'
import './CustomerNavigationItem.scss'

export interface CustomerNavigationItemProps {
    icon: IconType
    subMenuItems: MainNavigationMenuItem[]
    inversePopperPlacement?: boolean
    subMenuTitle?: string
    className?: ClassValue
    // TODO: handle onboarding
    onboardingStep?: OnboardingStepProps
    popperOptions?: any
    noHover?: boolean
}

type MainNavigationMenuItem = MenuItemWithContent | MenuLinkItem | MenuButtonItem

interface MenuItemWithContent extends CoreItem {
    items: MainNavigationMenuItem[]
    popperOptions?: any
}

interface MenuLinkItem extends CoreItem {
    to: string
}

interface MenuButtonItem extends CoreItem {
    onClick: () => void
}

interface CoreItem {
    label: string
    meta?: JSX.Element
}

export class CustomerNavigationItem extends React.PureComponent<CustomerNavigationItemProps> {
    private bem = new BEM('CustomerNavigationItem', () => ({
        inverse: this.props.inversePopperPlacement,
    }))

    public render() {
        const { className, subMenuTitle, subMenuItems, popperOptions } = this.props

        return (
            <Popper
                className={this.bem.getClassName(className)}
                item={this.renderMenuItem()}
                title={subMenuTitle}
                contents={this.getMenuContents(subMenuItems)}
                position="right-start"
                popperOptions={popperOptions}
            />
        )
    }

    private renderMenuItem() {
        const { onboardingStep } = this.props

        if (onboardingStep) {
            return <OnboardingStep {...onboardingStep}>{ref => this.renderButton(ref)}</OnboardingStep>
        }

        return this.renderButton()
    }

    private renderButton(ref?: React.RefObject<any>) {
        const { icon, subMenuItems, noHover } = this.props
        const active = this.props.subMenuItems.some(this.isActive)
        const to = subMenuItems.length && 'to' in subMenuItems[0] ? subMenuItems[0].to : undefined

        return (
            <Button
                className={this.bem.getElement('icon', () => ({
                    active,
                    noHover,
                    notNavButton: !to,
                }))}
                icon={icon}
                type={ButtonType.subtleIcon}
                to={to}
                ref={ref}
            />
        )
    }

    private getMenuContents(subMenuItems: MainNavigationMenuItem[]): PopperContentItem[] {
        const { subMenuTitle } = this.props
        const singleChild = subMenuItems.length === 1

        return subMenuItems.map(s => {
            if ('to' in s) {
                return {
                    item: (
                        <PopperButtonItem
                            label={s.label}
                            singleChild={singleChild}
                            to={s.to}
                            meta={s.meta}
                            active={this.doesPathMatch(s.to)}
                        />
                    ),
                    position: 'right-start',
                }
            }

            if ('onClick' in s) {
                return {
                    item: (
                        <PopperButtonItem meta={s.meta} singleChild={singleChild} label={s.label} onClick={s.onClick} />
                    ),
                    position: 'right-start',
                }
            }

            return {
                item: (
                    <PopperButtonItem
                        hasNestedItems={true}
                        singleChild={singleChild}
                        label={s.label}
                        active={this.isActive(s)}
                    />
                ),
                title: s.label || subMenuTitle,
                contents: this.getMenuContents(s.items),
                position: 'right-start',
                popperOptions: s.popperOptions,
            }
        })
    }

    private isActive = (menuItem: MainNavigationMenuItem) => {
        if ('onClick' in menuItem) {
            return false
        }

        if ('to' in menuItem) {
            return this.doesPathMatch(menuItem.to)
        }

        return menuItem.items.some(this.isActive)
    }

    private doesPathMatch(path: string) {
        return !!matchPath({ path, caseSensitive: false, end: false }, window.location.pathname)
    }
}
