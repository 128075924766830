import './InboxItemImpactWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { localize } from '~/bootstrap'
import {
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
    DashboardInboxItemImpactWidgetFragmentFragment,
    AlertImpactLevelFilter,
} from '~/generated/graphql'
import { WidgetInfoLink } from '../components/WidgetInfoLink'
import { routes } from '~/views/routes'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

type ImpactType = keyof Pick<
    DashboardInboxItemImpactWidgetFragmentFragment,
    'lowImpactCount' | 'noImpactCount' | 'mediumImpactCount' | 'highImpactCount'
>

export class InboxItemImpactWidget extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxItemImpactWidget')
    private loc = localize.namespaceTranslate(t => t.Customer.DashboardView.Widgets.InboxItemImpactWidget)

    public render() {
        const { isEditing, onWidgetClick, onDeleteIconClick, isDetailWidget, currentBreakpoint } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.inboxItemImpact,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.inboxItemImpact,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const { lowImpactCount, noImpactCount, mediumImpactCount, highImpactCount } = this.getCounts()

        return (
            <Column smallSpacing={true}>
                {this.renderImpactRow(noImpactCount, 'noImpactCount')}
                {this.renderImpactRow(highImpactCount, 'highImpactCount')}
                {this.renderImpactRow(mediumImpactCount, 'mediumImpactCount')}
                {this.renderImpactRow(lowImpactCount, 'lowImpactCount')}
            </Column>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getCounts() {
        const { content } = this.props
        if (content) {
            return content as DashboardInboxItemImpactWidgetFragmentFragment
        }

        return {
            lowImpactCount: 0,
            noImpactCount: 0,
            mediumImpactCount: 0,
            highImpactCount: 0,
        }
    }

    private renderImpactRow(count: number, type: ImpactType) {
        const metadata = this.props.metadata as DashboardDepartmentSpecificWidgetMetadataType | undefined
        const label = this.loc(t => t[type])

        return (
            <WidgetInfoLink departmentSpecificMetadata={metadata} to={this.getLinkForImpact(type)}>
                <Row>
                    <div className={this.bem.getElement('count-container')}>
                        <Paragraph center={true} className={this.bem.getElement('count')}>
                            {count}
                        </Paragraph>
                    </div>
                    <Row smallSpacing={true}>
                        <Icon
                            type={IconType.inbox}
                            className={this.bem.getElement('icon', () => ({ [`${type}`]: true }))}
                        />
                        <Paragraph subtle={true}>{label}</Paragraph>
                    </Row>
                </Row>
            </WidgetInfoLink>
        )
    }

    private getLinkForImpact(type: ImpactType) {
        const baseInboxLink = `${routes.customer(this.context.customer.slug).inbox.index}?impactLevel=`

        switch (type) {
            case 'highImpactCount':
                return `${baseInboxLink}${AlertImpactLevelFilter.high}`
            case 'lowImpactCount':
                return `${baseInboxLink}${AlertImpactLevelFilter.medium}`
            case 'mediumImpactCount':
                return `${baseInboxLink}${AlertImpactLevelFilter.low}`
            case 'noImpactCount':
                return `${baseInboxLink}${AlertImpactLevelFilter.none}`
            default:
                throw new Error('type not implemented')
        }
    }
}
