import React from 'react'
import { localize } from '~/bootstrap'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { uniqBy } from 'lodash-es'
import { PageQuery } from '../PageQuery/PageQuery'
import { NewsSourceSelectDocument, NewsGroupType } from '~/generated/graphql'

interface Props {
    onChange: (option: SelectOption) => void
    placeholder?: string
    defaultValue?: SelectOption[]
    isFilter?: boolean
    name?: string
    newsGroupId: number
    showAllSources?: boolean
}

export class NewsSourceSelect extends React.Component<React.PropsWithChildren<Props>> {
    public render() {
        const { onChange, placeholder, defaultValue, isFilter, name, newsGroupId } = this.props

        return (
            <PageQuery<NewsGroupType> query={NewsSourceSelectDocument} variables={{ id: newsGroupId }}>
                {newsGroup => {
                    if (!newsGroup) {
                        return null
                    }

                    const options = this.getSourceOptions(newsGroup)

                    return (
                        <Form.Select
                            name={name || 'source'}
                            defaultValue={defaultValue || [options[0]]}
                            searchable={false}
                            options={options}
                            placeholder={placeholder}
                            clearable={false}
                            onChange={onChange}
                            isFilter={isFilter}
                        />
                    )
                }}
            </PageQuery>
        )
    }

    private getSourceOptions(newsGroup: NewsGroupType) {
        const { defaultValue, showAllSources } = this.props
        const { sources } = newsGroup

        const filteredSources = showAllSources ? sources : sources.filter(source => source.isEnabledByCustomer)
        const newsGroupOptions = filteredSources.map(source => ({
            label: source.name ? source.name : '',
            value: source.id,
        }))

        const sourceOptions: SelectOption[] = [
            {
                label: localize.translate(t => t.Customer.News.NewsOverviewView.showAll),
                value: null,
            },
            ...newsGroupOptions,
        ]

        if (defaultValue) {
            return uniqBy([...sourceOptions, ...defaultValue], 'value')
        }

        return sourceOptions
    }
}
