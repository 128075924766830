import './ThemesOverviewItemCard.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { PinnableCard } from '~/components/Chrome/Pinnable/PinnableCard/PinnableCard'
import { Column } from '~/components/Core/Layout/Column'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { AssessmentScoreBar } from '../../Compliance/Assessments/AssessmentScoreBar/AssessmentScoreBar'
import { ThemesOverviewThemeType } from './ThemesOverviewTableQuery'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ThemeOverviewSummaryModal } from '../ThemeOverviewSummaryModal'

interface Props {
    theme: ThemesOverviewThemeType
    isPinned: boolean
    togglePinned: () => void
    notAllowedToPin: boolean
}

export class ThemesOverviewItemCard extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable)
    private bem = new BEM('ThemesOverviewItemCard')

    public render() {
        const { theme, isPinned, togglePinned, notAllowedToPin } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <PinnableCard
                        to={routes.customer(this.context.customer.slug).legalFramework.themes.view(theme.id)}
                        title={theme.name}
                        isPinned={isPinned}
                        togglePinned={togglePinned}
                        notPermittedToPin={notAllowedToPin}
                        className={this.bem.getClassName()}
                        sidebar={this.renderSidebar()}
                        onInfoButtonClick={permissions.canViewCompliances() ? openModal : undefined}
                    />
                )}
                renderModal={closeModal => <ThemeOverviewSummaryModal requestClose={closeModal} theme={theme} />}
            />
        )
    }

    private renderSidebar() {
        if (!permissions.canViewTopicDnE()) {
            return
        }

        return (
            <>
                <div className={this.bem.getElement('border')} />
                <Column className={this.bem.getElement('sidebar')}>
                    <AssessmentScoreBar
                        assessments={this.props.theme.topicAssessmentCoverage?.design}
                        title={this.loc(t => t.design)}
                    />
                    <AssessmentScoreBar
                        assessments={this.props.theme.topicAssessmentCoverage?.effectiveness}
                        title={this.loc(t => t.effectiveness)}
                    />
                </Column>
            </>
        )
    }
}
