import './TabletProfileDropdown.scss'

import React, { RefObject } from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { CustomerConsumer } from '~/components/Providers/CustomerProvider'
import { FrameworkIcon } from '~/components/Domain/Customer/Framework/FrameworkIcon/FrameworkIcon'
import debounce from 'lodash-es/debounce'

interface Props {
    className?: ClassValue
    toggleOpen: () => void
    parentRef?: RefObject<HTMLElement>
}

interface State {}

export class TabletProfileDropdown extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('TabletProfileDropdown')

    private containerRef = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        document.addEventListener('click', this.handleClick)
        document.addEventListener('touchend', this.handleClick)
        document.addEventListener('keyup', this.closeOnEsc)
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
        document.removeEventListener('touchend', this.handleClick)
        document.removeEventListener('keyup', this.closeOnEsc)
    }

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getClassName(className)} ref={this.containerRef}>
                <CustomerConsumer>
                    {context => {
                        if (!context) {
                            return null
                        }

                        return (
                            <ol>
                                {context.profiles.map(profile => (
                                    <li className={this.bem.getElement('profile-row')} key={profile.id}>
                                        <Checkbox
                                            name={profile.icon}
                                            label={
                                                <>
                                                    <FrameworkIcon
                                                        frameworkType={profile.icon}
                                                        isInactive={!profile.isActive}
                                                        className={'margin-right'}
                                                        color={profile.color}
                                                    />
                                                    {profile.name}
                                                </>
                                            }
                                            defaultChecked={profile.isActive}
                                            onChange={() => context.toggleProfile(profile.id)}
                                        />
                                    </li>
                                ))}
                            </ol>
                        )
                    }}
                </CustomerConsumer>
            </div>
        )
    }

    // tslint:disable-next-line:member-ordering
    private handleClick = debounce((event: MouseEvent) => {
        const target = event.target as HTMLElement | null
        const { parentRef, toggleOpen } = this.props

        if (!this.containerRef.current || !target) {
            return
        }

        if (this.containerRef.current.contains(target)) {
            return
        }

        if (parentRef && parentRef.current && parentRef.current.contains(target)) {
            return
        }

        toggleOpen()
    })

    private closeOnEsc = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
            this.props.toggleOpen()
        }
    }
}
