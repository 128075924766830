import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { AllTasksView } from '../Tasks/AllTasksView'
import { Navigate, Route, Routes } from 'react-router-dom'
import { withRouter, RouteComponentProps } from '~/utils/withRouter'

import { newRoutes } from '~/views/newRoutes'
import { AppNotFoundView } from '~/views/AppNotFoundView'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class TasksViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const basePath = newRoutes.customer(this.context.customer.slug).tasks

        return (
            <Routes>
                <Route path="*" element={<Navigate to={basePath.overview} replace />} />
                <Route path={basePath.view()} element={<AllTasksView />} />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const TasksView = withRouter(TasksViewComponent)
