import { format } from 'date-fns'
import React from 'react'
import { localize } from '~/bootstrap'
import { Button } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerFeedExportsTable } from '~/components/Domain/Customer/CustomerFeed/CustomerFeedExportsTable'
import { CustomerFeedSettings } from '~/components/Domain/Customer/CustomerFeed/CustomerFeedSettings'
import { CustomerFeedStatus } from '~/components/Domain/Customer/CustomerFeed/CustomerFeedStatus'
import { EditFeedModal } from '~/components/Domain/Customer/CustomerFeed/modals/EditFeedModal'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import {
    CustomerFeedDocument,
    CustomerFeedQuery,
    CustomerFeedQueryVariables,
    CustomerSubscribedToFeedType,
} from '~/generated/graphql'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { breadcrumbs } from '~/views/breadcrumbs'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{ id?: string }> {}

type QueryData = CustomerFeedQuery['customerSubscribedToFeed']

class CustomerFeedDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.CustomerFeedDetailView)
    private propertiesLoc = localize.namespaceTranslate(t => t.Consultant.CustomerFeeds.properties)

    public render() {
        const id = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : undefined

        if (!id) {
            return null
        }

        return (
            <Page>
                <PageQuery<QueryData, CustomerFeedQueryVariables> query={CustomerFeedDocument} variables={{ id }}>
                    {customerFeed => {
                        if (!customerFeed) {
                            return null
                        }

                        const { customer, type } = customerFeed
                        const title = `${customer.name}: ${this.loc(t => t[type])}`

                        return (
                            <Column bigSpacing={true} bigBottomSpacing={true}>
                                <PageHeader
                                    title={title}
                                    breadCrumbs={[breadcrumbs.consultant().customerfeeds.index]}
                                    actionComponent={this.renderEditButton(customerFeed)}
                                />
                                {this.renderGeneralInfo(customerFeed)}
                                <Column>
                                    <SectionTitle>{this.loc(t => t.exports)}</SectionTitle>
                                    <CustomerFeedExportsTable customerFeedId={id} />
                                </Column>
                            </Column>
                        )
                    }}
                </PageQuery>
            </Page>
        )
    }

    private renderEditButton(customerFeed: QueryData) {
        const { history } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Row alignRight={true}>
                        <Button onClick={openModal}>{this.loc(t => t.changeDetails)}</Button>
                    </Row>
                )}
                renderModal={closeModal => (
                    <EditFeedModal
                        onRequestClose={closeModal}
                        onDelete={() => history.push(routes.consultant.customerfeeds.index)}
                        customerFeed={customerFeed as CustomerSubscribedToFeedType}
                    />
                )}
            />
        )
    }

    private renderGeneralInfo(customerFeed: QueryData) {
        const { customer, type, isActive, recipientEmails, startDate, settings } = customerFeed!
        const time = format(new Date(startDate), 'HH:mm')
        const parsedSettings = settings ? JSON.parse(settings) : undefined

        return (
            <Column bigSpacing={true}>
                <Field label={this.propertiesLoc(t => t.status)}>
                    <CustomerFeedStatus isActive={isActive} />
                </Field>
                <Field label={this.propertiesLoc(t => t.customer)}>
                    <Row extraSmallSpacing={true}>
                        <Paragraph>{customer.name}</Paragraph>
                        {customer.deletedAt && (
                            <Paragraph subtle={true}>{localize.translate(t => t.Generic.deleted)}</Paragraph>
                        )}
                    </Row>
                </Field>
                <Field label={this.propertiesLoc(t => t.feedType)}>
                    <Paragraph>{this.loc(t => t[type])}</Paragraph>
                </Field>
                {parsedSettings && (
                    <Field label={this.propertiesLoc(t => t.settings)}>
                        <CustomerFeedSettings settings={parsedSettings} />
                    </Field>
                )}
                <Field label={this.propertiesLoc(t => t.time)}>
                    <Paragraph>{time}</Paragraph>
                </Field>
                <Field label={this.propertiesLoc(t => t.recipients)}>
                    <Column>
                        {recipientEmails.map((r, i) => (
                            <Paragraph key={`recipient-${i}`} blue={true}>
                                {r}
                            </Paragraph>
                        ))}
                    </Column>
                </Field>
            </Column>
        )
    }
}

export const CustomerFeedDetailView = withRouter(CustomerFeedDetailViewComponent)
