import React from 'react'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerFrameworkOverviewView } from './CustomerFramework/CustomerFrameworkOverviewView'
import { EmployeeOverviewView } from './Employees/EmployeeOverviewView'
import { EmployeeDetailView } from './Employees/EmployeeDetailView'
import { EmployeeDetailEditView } from './Employees/EmployeeDetailEditView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentsOverview } from './Departments/DepartmentsOverview'
import { ComplianceSettingsView } from './ComplianceSettings/ComplianceSettingsView'
import { ComplianceSettingsEditView } from './ComplianceSettings/ComplianceSettingsEditView'
import { SAMLSettingsDetailView } from '~/views/Customer/Settings/SAML/SAMLSettingsDetailView'
import { NewsSourcesOverviewView } from './NewsSources/NewsSourcesOverviewView'
import { NewsSourcesEditView } from './NewsSources/NewsSourcesEditView'
import { TaskTemplatesOverviewView } from './TaskTemplates/TaskTemplatesOverviewView'
import { Routes, Route, Navigate } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'

interface RouteParams {
    customer: string
}

interface Props extends RouteComponentProps<RouteParams> {}

class SettingsViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const slug = this.context.customer.slug

        return (
            <Page>
                <Routes>
                    <Route path={newRoutes.customer(slug).settings.news.index} element={<NewsSourcesOverviewView />} />
                    <Route path={newRoutes.customer(slug).settings.news.edit} element={<NewsSourcesEditView />} />
                    <Route
                        path={newRoutes.customer(slug).settings.employees.index}
                        element={<EmployeeOverviewView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.compliance.index}
                        element={<ComplianceSettingsView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.compliance.edit}
                        element={<ComplianceSettingsEditView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.employees.detail()}
                        element={<EmployeeDetailView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.employees.edit()}
                        element={<EmployeeDetailEditView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.frameworks.index}
                        element={<CustomerFrameworkOverviewView />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.departments.index}
                        element={<DepartmentsOverview />}
                    />
                    <Route
                        path={newRoutes.customer(slug).settings.taskTemplates.index}
                        element={<TaskTemplatesOverviewView />}
                    />
                    <Route path={routes.customer(slug).settings.saml.index} element={<SAMLSettingsDetailView />} />
                    <Route
                        path="*"
                        element={<Navigate replace to={routes.customer(slug).settings.employees.index} />}
                    />
                    <Route element={<AppNotFoundView />} />
                </Routes>
            </Page>
        )
    }
}

export const SettingsView = withRouter(SettingsViewComponent)
