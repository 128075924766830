import './ConsultationsWidget.scss'

import React from 'react'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { WidgetKey } from '../../CreateWidgetWidget/widgets'
import { WidgetContainer } from '../components/WidgetContainer'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import {
    DashboardConsultationWidgetFragmentFragment,
    DashboardDepartmentSpecificWidgetMetadataType,
    DashboardWidgetContentFragmentFragment,
    DashboardWidgetLayoutTypeEnum,
    DashboardWidgetMetadataFragmentFragment,
} from '~/generated/graphql'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    isEditing: boolean
    currentBreakpoint: DashboardWidgetLayoutTypeEnum | undefined
    onWidgetClick?: (widgetId: number, type: WidgetKey) => void
    onDeleteIconClick?: (widgetId: number) => void
    onModalStateChange?: ({ isOpening }: { isOpening: boolean }) => void
    isDetailWidget?: boolean
    id: number
    metadata?: DashboardWidgetMetadataFragmentFragment
    content?: DashboardWidgetContentFragmentFragment
}

export class ConsultationsWidget extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('ConsultationsWidget')

    public render() {
        const { isEditing, currentBreakpoint, isDetailWidget, onDeleteIconClick, onWidgetClick } = this.props
        const { onModalStateChange } = this.props

        return (
            <WidgetContainer
                widgetInfo={this.getHeaderInfo()}
                isEditing={isEditing}
                className={this.bem.getClassName()}
                onWidgetClick={onWidgetClick}
                isDetailWidget={isDetailWidget}
                currentBreakpoint={currentBreakpoint}
                onDeleteIconClick={onDeleteIconClick}
                onModalStateChange={onModalStateChange}
            >
                <Column bigSpacing={true}>{this.renderContent()}</Column>
            </WidgetContainer>
        )
    }

    private getHeaderInfo() {
        const { id, isDetailWidget } = this.props
        const { customName, departments, allDepartments } = this.getMetadata()

        if (isDetailWidget) {
            return {
                widgetId: id,
                widgetKey: WidgetKey.consultations,
                departments,
                allDepartments: false,
            }
        }

        return {
            widgetId: id,
            customName,
            widgetKey: WidgetKey.consultations,
            departments,
            allDepartments,
        }
    }

    private renderContent() {
        const consultations = this.getConsultationData()

        return (
            <Column smallSpacing={true}>
                {consultations.map(consultation => this.renderConsultation(consultation))}
            </Column>
        )
    }

    private getMetadata() {
        const { metadata } = this.props

        if (!metadata) {
            return {
                departments: [],
                allDepartments: false,
            }
        }

        const { name, departments, allDepartments } = metadata as DashboardDepartmentSpecificWidgetMetadataType

        return {
            customName: name ? name : undefined,
            departments: departments?.length ? departments.map(({ id, name }) => ({ id, name })) : [],
            allDepartments,
        }
    }

    private getConsultationData() {
        const { content } = this.props

        if (!content) {
            return []
        }

        const { consultations } = content as DashboardConsultationWidgetFragmentFragment
        if (!consultations || !consultations.length) {
            return []
        }

        // cannot handle more than 3 rows (more of a sanity check as the BE wont/shouldnt return more than 3)
        if (consultations.length > 3) {
            return consultations.slice(0, 3)
        }

        return consultations
    }

    private renderConsultation(consultation: { id: number; source: string; name: string }) {
        const { id, source, name } = consultation

        return (
            <Row smallSpacing={true} key={`consultation-row-${id}`}>
                <LabelTag subtle={true} label={source} />
                <Tooltip message={name} className={this.bem.getElement('tooltip')}>
                    <Paragraph className={this.bem.getElement('title')}>{name}</Paragraph>
                </Tooltip>
            </Row>
        )
    }
}
