import React from 'react'
import './RiskEditSidebar.scss'

import { BEM, ClassValue } from '~/services/BEMService'
import { AddControlToRiskSidebar, SelectedControl } from './AddControlToRiskSidebar'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { AcceptBlock } from './AcceptBlock'
import { localize, permissions } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    className?: ClassValue
    assessmentId: number
    assessmentSectionId: number
    defaultControls: SelectedControl[]
    isRiskAccepted: boolean
    showDistinctBrutoRiskValues: boolean
    onChangeSelectedControls: (values: SelectedControl[]) => void
    onChangeIsRiskAccepted: (accept: boolean) => void
    showHintToEvaluateNettoRisk: boolean
}

interface State {
    isRiskAccepted: boolean
    selectedControls: SelectedControl[]
}

export class RiskEditSidebar extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isRiskAccepted: !!this.props.isRiskAccepted,
        selectedControls: this.props.defaultControls,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.RiskEditSidebar)
    private bem = new BEM('RiskEditSidebar')

    public render() {
        const { className, assessmentId, assessmentSectionId, showHintToEvaluateNettoRisk } = this.props
        const { isRiskAccepted, selectedControls } = this.state

        const acceptingTheRiskLoc = this.getAcceptRiskLoc()

        return (
            <div className={this.bem.getClassName(className)}>
                <Column bigSpacing={true}>
                    <AcceptBlock
                        title={acceptingTheRiskLoc(t => t.acceptRisk)}
                        description={
                            isRiskAccepted
                                ? acceptingTheRiskLoc(t => t.cantacceptrisk)
                                : acceptingTheRiskLoc(t => t.acceptRiskContent)
                        }
                        onChange={this.onChangeIsRiskAccepted}
                        checked={isRiskAccepted}
                        isDisabled={!permissions.canEditAssessment(this.context.activeDepartmentId)}
                    />
                    <Column smallSpacing={true}>
                        <SectionTitle>{this.loc(t => t.controlTitle)}</SectionTitle>
                        {!isRiskAccepted ? (
                            <AddControlToRiskSidebar
                                assessmentId={assessmentId}
                                assessmentSectionId={assessmentSectionId}
                                defaultControls={selectedControls}
                                onChange={this.onChangeSelectedControls}
                            />
                        ) : (
                            <Paragraph>{acceptingTheRiskLoc(t => t.acceptedRisk)}</Paragraph>
                        )}
                    </Column>
                    {showHintToEvaluateNettoRisk && (
                        <Paragraph className={this.bem.getElement('hint-to-evaluate-netto-risk')}>
                            {this.loc(t => t.hintToEvaluateNettoRisk)}
                        </Paragraph>
                    )}
                </Column>
            </div>
        )
    }

    private getAcceptRiskLoc() {
        const { showDistinctBrutoRiskValues } = this.props

        if (showDistinctBrutoRiskValues) {
            return localize.namespaceTranslate(
                t => t.Customer.Compliance.RiskEditSidebar.acceptingTheRisk.useBothNettoAndBrutto
            )
        }

        return localize.namespaceTranslate(t => t.Customer.Compliance.RiskEditSidebar.acceptingTheRisk.onlyUseNetto)
    }

    private onChangeSelectedControls = (selectedControls: SelectedControl[]) => {
        const { onChangeSelectedControls } = this.props

        this.setState(
            {
                selectedControls,
            },
            () => {
                onChangeSelectedControls(this.state.selectedControls)
            }
        )
    }

    private onChangeIsRiskAccepted = (isRiskAccepted: boolean) => {
        const { onChangeIsRiskAccepted } = this.props

        this.setState(
            prevState => {
                if (prevState.isRiskAccepted === true && isRiskAccepted === false) {
                    return {
                        isRiskAccepted: false,
                    }
                } else if (prevState.isRiskAccepted === false && isRiskAccepted === true) {
                    return {
                        isRiskAccepted: true,
                    }
                }

                return null
            },
            () => {
                onChangeIsRiskAccepted(this.state.isRiskAccepted)
            }
        )
    }
}
