import './LinkControlModal.scss'
import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { GQLMutation } from '~/graphql/Mutation'
import gql from 'graphql-tag'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { SelectLinkableControlsTable } from './SelectLinkableControlsTable'
import { DrawerModal } from '~/components/Core/Feedback/Modal/DrawerModal'
import { ControlTypeType } from '~/generated/graphql'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { CONTROL_DETAIL_QUERY } from '~/components/Domain/Compliance/Controls/ControlDetail/ControlQuery'
import { IconType } from '~/components/Core/Icon/IconType'
import { ArticleTitle } from '~/components/Core/Text/ArticleTitle'
import { Column } from '~/components/Core/Layout/Column'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { ControlTypeCheckboxes } from '../../../Compliance/Controls/ControlTypeCheckboxes'

export interface Props {
    className?: ClassValue
    requestClose: () => void
    onLinkedControlIdsChanged?: () => void
    control: { id: number; type: ControlTypeType }
    translationNameSpace: 'Policy' | 'Procedure' | 'Control'
    linkableControlTypes: ControlTypeType[]
}

interface State {
    selectedControlIds: number[]
    search: string | null
    controlTypesFilter: ControlTypeType[]
}

const LINK_NESTED_CONTROLS_MUTATION = gql`
    mutation linkNestedControls($controlId: Int!, $linkControlIds: [Int!]!) {
        linkNestedControls(controlId: $controlId, linkControlIds: $linkControlIds) {
            id
        }
    }
`

export class LinkControlModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedControlIds: [],
        search: null,
        controlTypesFilter: [],
    }

    private bem = new BEM('LinkControlModal')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.LinkControlModal)
    private typeLoc = localize.namespaceTranslate(
        t => t.Customer.Compliance.Controls.LinkControlModal[this.props.translationNameSpace]
    )

    public render() {
        const { requestClose, className, control } = this.props

        return (
            <GQLMutation
                mutation={LINK_NESTED_CONTROLS_MUTATION}
                refetchQueries={[
                    {
                        query: CONTROL_DETAIL_QUERY,
                        variables: { id: control.id, departmentId: this.context.activeDepartmentId },
                    },
                ]}
            >
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmitForm(mutate)} className={this.bem.getClassName(className)}>
                        <DrawerModal requestClose={requestClose} actions={close => this.renderActions(loading, close)}>
                            {this.renderModalContent()}
                        </DrawerModal>
                    </Form>
                )}
            </GQLMutation>
        )
    }

    private renderTypeFilter = () => {
        const { linkableControlTypes } = this.props

        return (
            <FilterDropdown hasActiveFilter={this.isFilterActive()}>
                <FilterOption label={this.loc(t => t.type)} forInputName="controlType">
                    <ControlTypeCheckboxes
                        filterableControlTypes={linkableControlTypes}
                        onChange={types => {
                            this.setState({ controlTypesFilter: types })
                        }}
                    />
                </FilterOption>
            </FilterDropdown>
        )
    }

    private isFilterActive = () => {
        const { controlTypesFilter } = this.state

        return controlTypesFilter.length > 0
    }

    private getControlTypesForTable = () => {
        const { linkableControlTypes } = this.props
        const { controlTypesFilter } = this.state

        if (controlTypesFilter.length > 0) {
            return controlTypesFilter
        }

        return linkableControlTypes
    }

    private handleSubmitForm = (mutate: MutationFn) => async (formState: FormState) => {
        const { onLinkedControlIdsChanged } = this.props

        const response = await mutate({
            variables: {
                controlId: this.props.control.id,
                linkControlIds: this.state.selectedControlIds,
            },
        })

        if (response && response.data && response.data.linkNestedControls) {
            this.props.requestClose()

            if (onLinkedControlIdsChanged) {
                onLinkedControlIdsChanged()
            }
        }
    }

    private renderActions(loading: boolean, close?: () => void) {
        return (
            <Row alignRight={true}>
                <Button onClick={close} type={ButtonType.tertiary}>
                    {this.typeLoc(t => t.cancelButton)}
                </Button>
                <Button loading={loading} submit={true} icon={IconType.link}>
                    {this.typeLoc(t => t.saveButton)}
                </Button>
            </Row>
        )
    }

    private renderModalContent() {
        const { linkableControlTypes, control } = this.props
        const { search } = this.state

        return (
            <Column bigSpacing={true}>
                <Row spaceBetween={true}>
                    <ArticleTitle title={this.typeLoc(t => t.title)} />
                    <Row>
                        {linkableControlTypes.length > 1 && this.renderTypeFilter()}
                        <div>
                            <Search onChange={this.onSearch} />
                        </div>
                    </Row>
                </Row>
                <SelectLinkableControlsTable
                    search={search}
                    linkableToControl={control}
                    filterByControlTypes={this.getControlTypesForTable()}
                    onChangeSelectedControlIds={this.onChangeSelectedControlIds}
                    linkingParents={this.checkIfLinkingParents()}
                />
            </Column>
        )
    }

    private onChangeSelectedControlIds = (newSelectedControlIds: number[]) => {
        this.setState({
            selectedControlIds: newSelectedControlIds,
        })
    }

    private onSearch = (searchString: string | null) => {
        this.setState({
            search: searchString,
        })
    }

    private checkIfLinkingParents() {
        const { control, linkableControlTypes } = this.props

        if (
            linkableControlTypes.includes(ControlTypeType.keyControl) ||
            linkableControlTypes.includes(ControlTypeType.control)
        ) {
            return false
        }

        if (control.type === ControlTypeType.policy) {
            return false
        }

        return true
    }
}
