import './Section.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Column } from './Column'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    className?: ClassValue
    title: React.ReactNode
    style?: React.CSSProperties
    renderAction?: () => React.ReactNode
}

interface State {}

export class Section extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('Section')

    public render() {
        const { style, className, title, children, renderAction } = this.props

        return (
            <Column style={style} className={this.bem.getClassName(className)} bigSpacing={true}>
                <SectionTitle>
                    <Row spaceBetween={true}>
                        <span>{title}</span>
                        {renderAction ? renderAction() : null}
                    </Row>
                </SectionTitle>

                {children}
            </Column>
        )
    }
}
