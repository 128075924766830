import './Toggle.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '../../Typography/Paragraph'
import { Row } from '../../Layout/Row'

interface Props {
    className?: ClassValue
    defaultChecked?: boolean
    disabled?: boolean
    name: string
    onChange?: (value: boolean, name: string) => void
    label?: string
    checked?: boolean
    onChangeOnMount?: boolean
    big?: boolean
}

interface State {
    checked: boolean
}

export class Toggle extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static getDerivedStateFromProps(props: Props) {
        if (props.checked !== undefined) {
            return {
                checked: props.checked,
            }
        }

        return null
    }

    public state: State = {
        checked: this.props.checked
            ? this.props.checked
            : this.props.defaultChecked
            ? this.props.defaultChecked
            : false,
    }

    private bem = new BEM('Toggle', () => ({
        'is-checked': this.state.checked,
        'is-disabled': this.props.disabled,
        'is-big': this.props.big,
    }))

    public componentDidMount() {
        const { onChange, onChangeOnMount, defaultChecked, name } = this.props

        if (onChangeOnMount && onChange && defaultChecked) {
            onChange(defaultChecked, name)
        }
    }

    public render() {
        const { label } = this.props
        const slider = this.renderToggle()

        if (label) {
            return (
                <div
                    className={this.bem.getElement('wrapper', () => ({
                        'has-label': !!label,
                    }))}
                >
                    <Row alignCenter={true} smallSpacing={true}>
                        {this.renderLabel()}
                        {slider}
                    </Row>
                </div>
            )
        }

        return slider
    }

    private renderToggle(): JSX.Element {
        const { className, defaultChecked, checked } = this.props
        return (
            <span className={this.bem.getClassName(className)}>
                <input
                    type={'checkbox'}
                    defaultChecked={defaultChecked}
                    id={`input-${name}`}
                    className={this.bem.getElement('checkbox')}
                    onChange={this.handleOnChange}
                    checked={checked}
                />
                <span className={this.bem.getElement('switch')}>
                    <span className={this.bem.getElement('track')} />
                    <span className={this.bem.getElement('slider')} />
                </span>
            </span>
        )
    }

    private renderLabel(): React.ReactNode {
        const { label } = this.props
        return (
            <Paragraph subtle={true} small={true}>
                {label}
            </Paragraph>
        )
    }

    private handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { onChange, name, disabled, checked } = this.props
        const checkedStatus = event.currentTarget.checked

        if (disabled) {
            return
        }

        if (checked === undefined) {
            this.setState({ checked: checkedStatus })
        }

        if (onChange) {
            onChange(checkedStatus, name)
        }
    }
}
