import './DepartmentSelect.scss'

import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Row } from '~/components/Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Column } from '~/components/Core/Layout/Column'
import { localize } from '~/bootstrap'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import {
    AllCustomerDepartmentsDocument,
    AllCustomerDepartmentsQuery,
    AllCustomerDepartmentsQueryVariables,
} from '~/generated/graphql'

export enum DepartmentStatus {
    active = 'ACTIVE',
    passive = 'PASSIVE',
    unauthorized = 'UNAUTHORIZED',
}

interface Props {}

interface Department {
    id: number
    name: string
}

export class DepartmentSelect extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview.createModal)
    private bem = new BEM('DepartmentSelect')

    public render() {
        return (
            <Query<AllCustomerDepartmentsQuery, AllCustomerDepartmentsQueryVariables>
                query={AllCustomerDepartmentsDocument}
                variables={{ customerSlug: this.context.customer.slug }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data?.allDepartments) {
                        return null
                    }

                    return (
                        <div className={this.bem.getClassName()}>
                            {this.renderTableHeaderRow()}
                            {this.renderTable(data.allDepartments as Department[])}
                        </div>
                    )
                }}
            </Query>
        )
    }
    private renderTableHeaderRow() {
        return (
            <Row className={this.bem.getElement('table-header-row')}>
                <Paragraph className={this.bem.getElement('name')} bold={true}>
                    {localize.translate(t => t.Entities.Department)}
                </Paragraph>
                <Row evenSpace={true} smallSpacing={true}>
                    <Paragraph center={true} bold={true}>
                        {this.loc(t => t.active)}
                    </Paragraph>
                    <Paragraph center={true} bold={true}>
                        {this.loc(t => t.passive)}
                    </Paragraph>
                    <Paragraph center={true} bold={true}>
                        {this.loc(t => t.unauthorized)}
                    </Paragraph>
                </Row>
            </Row>
        )
    }

    private renderTable(allDepartments: Department[]) {
        return (
            <Column className={this.bem.getElement('table-container')}>
                <Column className={this.bem.getElement('table-row-container')}>
                    {allDepartments.map(d => this.renderTableRow(d!.id, d!.name))}
                </Column>
            </Column>
        )
    }

    private renderTableRow(id: number, name: string) {
        return (
            <Row key={`department-${id}`} className={this.bem.getElement('table-row')}>
                <Paragraph>{name}</Paragraph>
                <Row evenSpace={true} smallSpacing={true}>
                    <Form.Radio
                        name={`department-status-${id}`}
                        value={DepartmentStatus.active}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`department-status-${id}`}
                        value={DepartmentStatus.passive}
                        className={this.bem.getElement('radio')}
                    />
                    <Form.Radio
                        name={`department-status-${id}`}
                        value={DepartmentStatus.unauthorized}
                        defaultChecked={true}
                        className={this.bem.getElement('radio')}
                    />
                </Row>
            </Row>
        )
    }
}
