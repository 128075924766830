import './SectionList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { NavLink } from 'react-router-dom'

interface Props {
    className?: ClassValue
    sections: Section[]
    getRouteTo: (id: number) => string
    expandTo?: number
    onLoadFirstArticle?: (section: Section) => void
}

interface Section {
    id: number
    name: string
    articleId: number | null
    parentId: number | null
    order: number
}

interface State {
    activeSections: number[]
}

export class SectionList extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        activeSections: this.getDefaultExpandedSections(),
    }

    private bem = new BEM('SectionList')

    public render() {
        const { className } = this.props

        const topLevel = this.getChildren(null)

        return <nav className={this.bem.getClassName(className)}>{this.renderSections(topLevel)}</nav>
    }

    private getDefaultExpandedSections() {
        if (this.props.expandTo) {
            return this.getPathTo(this.props.expandTo)
        }

        return this.getPathToFirstArticle()
    }

    private getPathTo(parentId: number): number[] {
        const { sections } = this.props
        const path: number[] = []

        const getPath = (parentId: number) => {
            path.push(parentId)

            const section = sections.find(section => section.id === parentId)

            if (!section) {
                return
            }

            if (section.parentId !== null) {
                getPath(section.parentId)
            }
        }

        getPath(parentId)
        return path
    }

    private getPathToFirstArticle(): number[] {
        const { sections, onLoadFirstArticle } = this.props
        const path: number[] = []

        const getPath = (parentId: number | null = null) => {
            const section = sections.find(section => section.parentId === parentId && section.order === 0)

            if (!section) {
                return
            }

            path.push(section.id)

            if (section.articleId === null) {
                getPath(section.id)
            }
        }

        getPath()
        if (onLoadFirstArticle) {
            const section = sections.find(({ id }) => id === path[path.length - 1])
            if (section) {
                onLoadFirstArticle(section)
            }
        }

        return path
    }

    private renderSections(sections: Section[]): React.ReactNode {
        const { activeSections } = this.state
        return (
            <ol key={`section-decendents`}>
                {sections.map(section => {
                    const decendents = this.getChildren(section.id)
                    const isExpanded = activeSections.includes(section.id)

                    return (
                        <li key={`section-${section.id}`}>
                            {this.renderSectionItem(section, decendents.length > 0)}
                            {decendents.length > 0 && isExpanded && this.renderSections(decendents)}
                        </li>
                    )
                })}
            </ol>
        )
    }

    private getChildren(parentId: number | null) {
        return this.props.sections.filter(section => section.parentId === parentId).sort((a, b) => a.order - b.order)
    }

    private handleButtonClick = (id: number) => {
        this.setState(state => {
            let { activeSections } = state

            if (!activeSections.includes(id)) {
                activeSections = [...activeSections, id]
            } else {
                activeSections = activeSections.filter(activeId => activeId !== id)
            }

            return {
                activeSections,
            }
        })
    }

    private renderSectionItem(section: Section, hasChildren: boolean) {
        const { getRouteTo } = this.props
        const { activeSections } = this.state

        if (hasChildren) {
            const isExpanded = activeSections.includes(section.id)
            return (
                <Button
                    onClick={() => this.handleButtonClick(section.id)}
                    type={ButtonType.noStyling}
                    className={this.bem.getElement('toggle-button', () => ({
                        'is-active': isExpanded,
                    }))}
                    icon={isExpanded ? IconType.remove : IconType.add}
                >
                    {section.name}
                </Button>
            )
        }

        if (section.articleId) {
            return (
                <NavLink className={this.bem.getElement('text-link')} to={getRouteTo(section.articleId)} replace={true}>
                    {section.name}
                </NavLink>
            )
        }

        return section.name
    }
}
