import React from 'react'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import gql from 'graphql-tag'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn, Query } from 'react-apollo'
import { notification, localize } from '~/bootstrap'
import { Control, CONTROL_DETAIL_QUERY } from '../ControlDetail/ControlQuery'
import { EditControlDocumentModal } from './EditControlDocumentModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    requestClose: () => void
    controlName: string
    controlId: number
    documentId: number
}

interface State {}

const GET_CONTROL_QUERY = gql`
    query control($id: Int!, $controlDocumentId: Int) {
        control(id: $id) {
            id
            name
            type
            description
            startDate
            revisionDate
            createdAt
            updatedAt
            controlDocuments(controlDocumentId: $controlDocumentId) {
                id
                archivedAt
                file {
                    id
                    name
                    path
                    mimetype
                }
            }
            responsibleEmployees {
                id
                user {
                    id
                    profile {
                        id
                        fullName
                    }
                }
            }
            responsibleShadowEmployees {
                id
                name
            }
        }
    }
`

const ARCHIVE_MUTATION = gql`
    mutation archiveControlDocument($documentId: Int!) {
        archiveControlDocument(documentId: $documentId)
    }
`

interface ArchiveMutationVariables {
    documentId: number
}

interface ArchiveMutationResponse {
    archiveControlDocument: boolean
}

const EDIT_MUTATION = gql`
    mutation editControlDocument($documentId: Int!, $fields: EditControlDocumentFields!) {
        editControlDocument(documentId: $documentId, fields: $fields)
    }
`

interface EditMutationResponse {
    editControlDocument: {
        id: number
        name: string
    }
}

interface EditMutationVariables {
    documentId: number
    fields: {
        file?: File | null
    }
}

const DELETE_MUTATION = gql`
    mutation deleteControlDocument($documentId: Int!) {
        deleteControlDocument(documentId: $documentId)
    }
`

interface DeleteControlDocumentVariables {
    documentId: number
}

interface DeleteControlDocumentResponse {
    deleteControlDocument: boolean
}

interface Response {
    control: Control
}

export class EditControlDocumentModalContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        repeats: false,
    }

    public render() {
        const { controlId, documentId, requestClose } = this.props

        return (
            <GQLMutation<DeleteControlDocumentResponse, DeleteControlDocumentVariables>
                mutation={DELETE_MUTATION}
                refetchQueries={[
                    {
                        query: CONTROL_DETAIL_QUERY,
                        variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                    },
                ]}
                variables={{ documentId }}
            >
                {(deleteMutate, { loading: deleteLoading }) => (
                    <GQLMutation<ArchiveMutationResponse, ArchiveMutationVariables>
                        mutation={ARCHIVE_MUTATION}
                        refetchQueries={[
                            {
                                query: CONTROL_DETAIL_QUERY,
                                variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                            },
                        ]}
                    >
                        {(archiveMutate, { loading: archiveLoading }) => (
                            <GQLMutation<EditMutationResponse, EditMutationVariables>
                                mutation={EDIT_MUTATION}
                                refetchQueries={[
                                    {
                                        query: CONTROL_DETAIL_QUERY,
                                        variables: { id: controlId, departmentId: this.context.activeDepartmentId },
                                    },
                                ]}
                            >
                                {(editMutate, { loading: editLoading }) => (
                                    <Query<Response>
                                        query={GET_CONTROL_QUERY}
                                        variables={{ id: controlId, controlDocumentId: documentId }}
                                    >
                                        {({ data }) => {
                                            if (!data || !data.control || !data.control.controlDocuments?.length) {
                                                return null
                                            }

                                            return (
                                                <EditControlDocumentModal
                                                    requestClose={requestClose}
                                                    existingDocumentData={data.control.controlDocuments[0]}
                                                    controlName={data.control.name}
                                                    controlId={data.control.id}
                                                    archiveLoading={archiveLoading}
                                                    deleteLoading={deleteLoading}
                                                    editLoading={editLoading}
                                                    onArchive={this.handleOnArchive(archiveMutate)}
                                                    onDelete={this.handleOnDelete(deleteMutate)}
                                                    onEdit={this.handleOnEdit(editMutate)}
                                                />
                                            )
                                        }}
                                    </Query>
                                )}
                            </GQLMutation>
                        )}
                    </GQLMutation>
                )}
            </GQLMutation>
        )
    }

    private handleOnEdit =
        (mutate: MutationFn<EditMutationResponse, EditMutationVariables>) => async (formState: FormState) => {
            const { requestClose, documentId } = this.props

            const response = await mutate({
                variables: {
                    documentId,
                    fields: {
                        file: formState.file,
                    },
                },
            })

            if (response && response.data && response.data.editControlDocument) {
                notification.success(localize.translate(t => t.Generic.successfullyEdited))
                requestClose()
            }
        }

    private handleOnDelete =
        (mutate: MutationFn<DeleteControlDocumentResponse, DeleteControlDocumentVariables>) =>
        async (closeModal: Function) => {
            const response = await mutate()

            if (response && response.data && response.data.deleteControlDocument) {
                closeModal()
                notification.success(localize.translate(t => t.Generic.successfullyDeleted))
            }
        }

    private handleOnArchive = (mutate: MutationFn<ArchiveMutationResponse, ArchiveMutationVariables>) => async () => {
        const { requestClose, documentId } = this.props
        const response = await mutate({
            variables: {
                documentId,
            },
        })

        if (response && response.data && response.data.archiveControlDocument) {
            notification.success(localize.translate(t => t.Generic.successfullyCreated))
            requestClose()
        }
    }
}
