import './TopicAssessmentLinkedArticlesTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { ExpandableList } from '~/components/Core/DataDisplay/ExpandableList/ExpandableList'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { LawArticleType, TopicLinkedItemsQuery } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { TopicLinkedItems } from '../../../TopicLinkedItems'

interface Props {
    topicId: number
    linkedArticleIds: number[]
    allArticles?: boolean
}

export class TopicAssessmentLinkedArticlesTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('TopicAssessmentLinkedArticlesTable')
    public render() {
        const { topicId, linkedArticleIds, allArticles } = this.props

        return (
            <TopicLinkedItems topicId={topicId}>
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    if (!data) {
                        return <NoResults />
                    }

                    const topicArticles = this.getLinkedArticles(data)
                    const selectedArticles = allArticles
                        ? topicArticles
                        : topicArticles.filter(a => linkedArticleIds.includes(a.id))
                    const label = this.getLabel(topicArticles.length)

                    return (
                        <ExpandableList
                            className={this.bem.getClassName()}
                            label={<Paragraph>{label}</Paragraph>}
                            renderList={this.renderList(selectedArticles)}
                        />
                    )
                }}
            </TopicLinkedItems>
        )
    }

    private getLinkedArticles(data: TopicLinkedItemsQuery) {
        const articles: LawArticleType[] = []
        data.topic?.linkedItems?.forEach(li => li.items?.forEach(i => articles.push(i as LawArticleType)))

        return articles
    }

    private getLabel(totalCount: number) {
        const { linkedArticleIds } = this.props
        const hasAllArticles = totalCount === linkedArticleIds.length

        if (hasAllArticles) {
            return localize.translate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable.wholeTopic)
        }

        return localize.translate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessTable.articles, {
            smart_count: linkedArticleIds.length,
        })
    }

    private renderList = (selectedArticles: LawArticleType[]) => () => {
        return (
            <div className={this.bem.getElement('list-container')}>
                <Column smallSpacing={true} className={this.bem.getElement('scroll-container')}>
                    {selectedArticles.map(a => (
                        <Row key={`article-${a.id}`}>
                            <Paragraph className={this.bem.getElement('article')}>{a.title}</Paragraph>
                        </Row>
                    ))}
                </Column>
            </div>
        )
    }
}
