import './SignalingItemCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { RelevantModal } from '~/views/Customer/Signaling/RelevantModal/RelevantModal'
import { SignalingTitle } from './SignalingTitle'
import { NewsItemType } from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    className?: ClassValue
    newsItem: NewsItemType
    checked?: boolean
    handleOnCardCheck?: (checked: boolean) => void
    handleOnArchive?: () => void
    hasPermissionToArchive: boolean
    hasPermissionToMarkRelevant: boolean
    isFirstItem?: boolean
    onMarkRelevant?: () => void
    isCheckboxActive?: boolean
}

interface State {}

export class SignalingItemCard extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private isArchived = !!this.props.newsItem.archivedStatus?.archivedAt
    private isPublished = !!this.props.newsItem.publishedStatus?.publishedAt

    private bem = new BEM('SignalingItemCard', () => ({
        'is-archived': this.isArchived,
        // TODO: replace this with new published status when available
        'is-published': this.isPublished,
    }))

    public render() {
        const { className, newsItem, checked, isFirstItem, isCheckboxActive } = this.props

        return (
            <Card
                isCheckboxActive={isCheckboxActive}
                checked={checked}
                onCheckChange={this.getOnCheckChange()}
                isFirstCardInList={isFirstItem}
                className={this.bem.getClassName(className)}
            >
                <SignalingTitle className={this.bem.getElement('content')} showTooltip={true} newsItem={newsItem} />

                <div className={this.bem.getElement('action')}>{this.renderAction()}</div>
            </Card>
        )
    }

    private getOnCheckChange() {
        const { handleOnCardCheck, newsItem, hasPermissionToArchive } = this.props

        if (!hasPermissionToArchive || newsItem.publishedStatus) {
            return undefined
        }

        return handleOnCardCheck
    }

    private renderDate(date: Date) {
        return (
            <Paragraph className={this.bem.getElement('date')} subtle={true}>
                <DateFormat date={new Date(date)} readable={true} includeTime={true} noWeekday={true} />
            </Paragraph>
        )
    }

    // if isArchived -> render archive info
    // if published by customer -> render nothing
    // else -> render action
    private renderAction() {
        const { handleOnArchive, onMarkRelevant, newsItem, hasPermissionToArchive, hasPermissionToMarkRelevant } =
            this.props

        if (this.isArchived) {
            return this.renderArchivedInfo()
        }

        if (this.isPublished) {
            return this.renderPublishedInfo()
        }

        return (
            <>
                <Guard condition={hasPermissionToArchive}>
                    <Button
                        className={this.bem.getElement('archive-button')}
                        type={ButtonType.secondary}
                        onClick={handleOnArchive}
                        icon={IconType.archive}
                    />
                </Guard>
                <Guard condition={hasPermissionToMarkRelevant}>
                    <ModalManager
                        key={newsItem.id}
                        render={openModal => (
                            <Button onClick={openModal}>{localize.translate(t => t.Generic.relevant)}</Button>
                        )}
                        renderModal={closeModal => (
                            <RelevantModal
                                closeModal={closeModal}
                                newsItem={newsItem}
                                onMarkRelevant={onMarkRelevant}
                            />
                        )}
                    />
                </Guard>
            </>
        )
    }

    private renderArchivedInfo() {
        const { newsItem } = this.props
        const loc = localize.namespaceTranslate(t => t.Customer.Signaling.SignalingOverview.archiveInfo)

        // this is quite unnecessary, but asserting the typechecker in 5 places just doesn't seem right
        if (!newsItem.archivedStatus || !newsItem.archivedStatus.archivedAt) {
            return null
        }

        const archivedBy = newsItem.archivedStatus.archivedBy

        return (
            <Column>
                {archivedBy && (
                    <Row smallSpacing={true}>
                        <Tooltip
                            title={loc(t => t.archivedReason)}
                            message={
                                newsItem.archivedStatus.archivedReason
                                    ? newsItem.archivedStatus.archivedReason
                                    : loc(t => t.noArchivedReason)
                            }
                        >
                            <Paragraph>{loc(t => t.archivedBy)}</Paragraph>
                        </Tooltip>
                        <UserProfileIcon
                            small={true}
                            fullName={archivedBy.user.profile.fullName}
                            uri={archivedBy.user.profile.avatar}
                            showTooltip={true}
                        />
                    </Row>
                )}

                <Row alignRight={true}>{this.renderDate(newsItem.archivedStatus.archivedAt)}</Row>
            </Column>
        )
    }

    private renderPublishedInfo() {
        const { publishedBy, publishedAt } = this.props.newsItem.publishedStatus!

        return (
            <Column>
                {publishedBy && (
                    <Row smallSpacing={true}>
                        <Paragraph>
                            {localize.translate(t => t.Customer.Signaling.SignalingOverview.markedRelevantBy)}
                        </Paragraph>
                        <UserProfileIcon
                            small={true}
                            fullName={publishedBy.user.profile.fullName}
                            uri={publishedBy.user.profile.avatar}
                            showTooltip={true}
                        />
                    </Row>
                )}

                <Row alignRight={true}>{this.renderDate(publishedAt)}</Row>
            </Column>
        )
    }
}
