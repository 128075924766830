import React from 'react'
import { localize } from '~/bootstrap'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'

interface Props {
    name: string
    isFilter?: boolean
    defaultValue?: SelectOption[]
    onChange?: (option: SelectOption) => void
    placeholder?: string
    forInboxControl?: boolean
}

export enum InboxStatus {
    new = '',
    isArchived = 'isArchivedFromInboxForDepartment',
    hasTasks = 'hasTasksForDepartment',
    isIgnored = 'isIgnored',
    hasControls = 'hasControls',
    allItems = 'allItems',
}

export class InboxStatusSelect extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)

    private statusOptions = [
        { label: this.loc(t => t.newItems), value: InboxStatus.new },
        { label: this.loc(t => t.archived), value: InboxStatus.isArchived },
        { label: this.loc(t => t.itemsWithTasks), value: InboxStatus.hasTasks },
    ]

    private inboxControlOptions = [
        { label: this.loc(t => t.newItems), value: InboxStatus.new },
        { label: this.loc(t => t.ignored), value: InboxStatus.isIgnored },
        { label: this.loc(t => t.hasControls), value: InboxStatus.hasControls },
        { label: this.loc(t => t.allItems), value: InboxStatus.allItems },
    ]

    public render() {
        const { name, isFilter, defaultValue, onChange, placeholder, forInboxControl } = this.props

        const options = forInboxControl ? this.inboxControlOptions : this.statusOptions

        return (
            <Form.Select
                isFilter={isFilter}
                searchable={false}
                name={name}
                options={options}
                placeholder={placeholder || this.loc(t => t.newItems)}
                defaultValue={defaultValue}
                onChange={onChange}
            />
        )
    }
}
