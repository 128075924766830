import './ToolbarItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'

interface Props {
    className?: ClassValue
    icon: IconType
    onClick: () => void
}

export class ToolbarItem extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ToolbarItem')

    public render() {
        const { className, icon, onClick } = this.props

        return (
            <Button type={ButtonType.noStyling} className={this.bem.getClassName(className)} onClick={() => onClick()}>
                <Icon type={icon} />
            </Button>
        )
    }
}
