import './PageDetailContentTombstones.scss'

import React from 'react'
import { Tombstone } from '../../Feedback/Tombstone/Tombstone'
import { Column } from '../Column'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export class PageDetailContentTombstones extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('PageDetailContentTombstones')

    public render() {
        const { className } = this.props

        return (
            <Column className={this.bem.getClassName(className)}>
                <Column doubleSpacing={true} className={this.bem.getElement('paragraph')}>
                    <Tombstone width={600} height={8} />
                    <Tombstone width={465} height={8} />
                </Column>
                <Column doubleSpacing={true} className={this.bem.getElement('paragraph')}>
                    <Tombstone width={600} height={8} />
                    <Tombstone width={546} height={8} />
                    <Tombstone width={575} height={8} />
                    <Tombstone width={202} height={8} />
                </Column>
                <Column doubleSpacing={true} className={this.bem.getElement('paragraph')}>
                    <Tombstone width={264} height={16} />
                </Column>
                <Column doubleSpacing={true} className={this.bem.getElement('paragraph')}>
                    <Tombstone width={600} height={8} />
                    <Tombstone width={600} height={8} />
                    <Tombstone width={565} height={8} />
                    <Tombstone width={600} height={8} />
                    <Tombstone width={600} height={8} />
                    <Tombstone width={565} height={8} />
                    <Tombstone width={600} height={8} />
                    <Tombstone width={508} height={8} />
                    <Tombstone width={168} height={8} />
                </Column>
                <Column doubleSpacing={true}>
                    <Tombstone width={600} height={8} />
                    <Tombstone width={573} height={8} />
                    <Tombstone width={600} height={8} />
                    <Tombstone width={350} height={8} />
                </Column>
            </Column>
        )
    }
}
