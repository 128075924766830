import './TaskIgnoredForControlTooltip.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { localize } from '~/bootstrap'
import { EmployeeType } from '~/generated/graphql'

interface Props {
    className?: ClassValue
    ignoredBy?: EmployeeType
    ignoredAt: Date
    ignoredReason?: string | null
}

interface State {}

export class TaskIgnoredForControlTooltip extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('TaskIgnoredForControlTooltip')

    public render() {
        const { className } = this.props

        return (
            <Tooltip
                distance={1}
                direction={ToolTipDirection.upEnd}
                className={this.bem.getClassName(className)}
                content={this.renderContent()}
            >
                <Button
                    className={this.bem.getElement('icon')}
                    type={ButtonType.noStyling}
                    icon={IconType.ignoreControlTask}
                />
            </Tooltip>
        )
    }

    private renderContent() {
        const { ignoredBy, ignoredAt, ignoredReason } = this.props

        const fullName = ignoredBy?.user.profile.fullName

        return (
            <div className={this.bem.getElement('content')}>
                {fullName && (
                    <div className={this.bem.getElement('created-by')}>
                        {localize.translate(t => t.Customer.TaskControl.TaskIgnoredForControlTooltip.ignoredBy)}
                        <UserProfileIcon
                            small={true}
                            showTooltip={true}
                            fullName={fullName}
                            className={this.bem.getElement('user')}
                        />
                    </div>
                )}
                <DateFormat
                    readable={true}
                    includeTime={true}
                    noWeekday={true}
                    className={this.bem.getElement('date')}
                    date={new Date(ignoredAt)}
                />

                {ignoredReason && <div className={this.bem.getElement('reason')}>{ignoredReason}</div>}
            </div>
        )
    }
}
