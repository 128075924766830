import './TopicDesignAndEffectivenessView.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { AddNoteToTopicButtonContainer } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/AddNoteToTopicButtonContainer'
import { AddControlToTopicButtonContainer } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/AddControlToTopicButtonContainer'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    LawArticleType,
    TopicControlMeasureType,
    TopicDesignAndEffectivenessDocument,
    TopicDesignAndEffectivenessQuery,
    TopicDesignAndEffectivenessQueryVariables,
    TopicNoteType,
    TopicType,
    TopicUnassessedArticlesDocument,
    TopicUnassessedArticlesQuery,
    TopicUnassessedArticlesQueryVariables,
} from '~/generated/graphql'
import { breadcrumbs } from '~/views/breadcrumbs'
import { TopicDesignAndEffectivenessUnassessedArticlesTable } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/TopicDesignAndEffectivenessUnassessedArticlesTable'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { Page } from '~/components/Core/Layout/Page'
import { Query, QueryResult } from 'react-apollo'
import { BEM } from '~/services/BEMService'
import { TopicNotesAndControlsTableContainer } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/TopicNotesAndControlsTableContainer'
import { TopicRisksTable } from '~/components/Domain/Topic/TopicRisksTable'
import { TopicMonitoringReportsTable } from '~/components/Domain/Topic/TopicMonitoringReportsTable'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

type QueryData = TopicDesignAndEffectivenessQuery['topic']

class TopicDesignAndEffectivenessViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicDesignAndEffectivenessView')
    private loc = localize.namespaceTranslate(t => t.Customer.LegalFrameworkView.TopicDesignAndEffectivenessView)
    private refetchMonitoringReports: () => void | undefined

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return (
            <Query<TopicUnassessedArticlesQuery, TopicUnassessedArticlesQueryVariables>
                query={TopicUnassessedArticlesDocument}
                notifyOnNetworkStatusChange={true}
                variables={{
                    id: parseInt(id, 10),
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {queryResult => {
                    return this.renderPageQuery(queryResult)
                }}
            </Query>
        )
    }

    private renderPageQuery(
        unassessedArticlesQueryResult: QueryResult<TopicUnassessedArticlesQuery, TopicUnassessedArticlesQueryVariables>
    ) {
        const id = parseInt(this.props.match.params.id!, 10)
        const customerSlug = this.context.customer.slug

        return (
            <PageQuery<QueryData, TopicDesignAndEffectivenessQueryVariables>
                query={TopicDesignAndEffectivenessDocument}
                variables={{ id, customerSlug, departmentId: this.context.activeDepartmentId }}
            >
                {(topic, { refetch }) => {
                    return this.renderPage(topic, refetch, unassessedArticlesQueryResult)
                }}
            </PageQuery>
        )
    }

    private renderPage(
        topic: QueryData,
        refetchTopic: Function,
        unassessedArticlesQueryResult: QueryResult<TopicUnassessedArticlesQuery, TopicUnassessedArticlesQueryVariables>
    ) {
        if (!topic) {
            return <Spinner delayed={true} />
        }

        const customerSlug = this.context.customer.slug
        const headerBreadcrumbs = [
            breadcrumbs.customer(customerSlug).legalFramework.index,
            breadcrumbs.customer(customerSlug).legalFramework.themes.view(topic.theme.name!, topic.theme.id),
            breadcrumbs.customer(customerSlug).legalFramework.themes.topic.view(topic.name, topic.id),
        ]

        const unassessedArticles = (unassessedArticlesQueryResult.data?.topic?.unassessedArticles ||
            []) as LawArticleType[]

        return (
            <Page>
                <PageHeader
                    title={this.loc(t => t.title)}
                    breadCrumbs={headerBreadcrumbs}
                    suffix={unassessedArticlesQueryResult.loading ? <Spinner /> : undefined}
                />
                <Column extraBigSpacing={true} className={this.bem.getElement('container')}>
                    <Column>
                        {unassessedArticles.length > 0 && (
                            <TopicDesignAndEffectivenessUnassessedArticlesTable
                                unassessedArticles={unassessedArticles}
                            />
                        )}
                        {this.renderControlAndAssessmentsSection(
                            topic,
                            refetchTopic,
                            unassessedArticlesQueryResult.refetch
                        )}
                    </Column>
                </Column>
            </Page>
        )
    }

    private renderControlAndAssessmentsSection(
        topic: QueryData,
        refetchTopic: Function,
        refetchUnassessedArticles: Function
    ) {
        if (!topic) {
            return
        }

        const canEditTopicDnE = permissions.canEditTopicDnE(this.context.activeDepartmentId)
        const canViewMonitoringReports = permissions.canViewMonitoringReports()

        return (
            <Column>
                <Row spaceBetween={true} className={this.bem.getElement('title')}>
                    <Column>
                        <SectionTitle>{this.loc(t => t.controlsAndAssessments)}</SectionTitle>
                        <Paragraph bold={true} subtle={true}>
                            {this.loc(t => t.controlsDescription)}
                        </Paragraph>
                    </Column>
                    <Column>
                        {canEditTopicDnE && (
                            <>
                                <AddNoteToTopicButtonContainer
                                    onCreate={this.handleChange(refetchTopic, refetchUnassessedArticles)}
                                    topicId={topic.id}
                                />
                                <AddControlToTopicButtonContainer
                                    onCreate={this.handleChange(refetchTopic, refetchUnassessedArticles)}
                                    topic={topic as TopicType}
                                />
                            </>
                        )}
                    </Column>
                </Row>
                <TopicNotesAndControlsTableContainer
                    onChange={this.handleChange(refetchTopic, refetchUnassessedArticles)}
                    topicControls={topic.topicControlMeasures as TopicControlMeasureType[]}
                    topicNotes={topic.topicNotes as TopicNoteType[]}
                    topicId={topic.id}
                />
                <Row
                    evenSpace={canViewMonitoringReports}
                    flexStart
                    className={this.bem.getElement('risks-reports-container')}
                >
                    <TopicRisksTable topicId={topic.id} />
                    {canViewMonitoringReports && (
                        <TopicMonitoringReportsTable
                            topicId={topic.id}
                            setRefetch={refetch => this.handleSetRefetch(refetch)}
                        />
                    )}
                </Row>
            </Column>
        )
    }

    private handleSetRefetch(refetch: () => void) {
        this.refetchMonitoringReports = refetch
    }

    private handleChange = (refetchTopic: Function, refetchUnassessedArticles: Function) => () => {
        refetchUnassessedArticles()
        refetchTopic()
        this.refetchMonitoringReports?.()
    }
}

export const TopicDesignAndEffectivenessView = withRouter(TopicDesignAndEffectivenessViewComponent)
