import './ThemesOverviewPinnedThemesContainer.scss'

import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { animations } from '~/animations'
import { permissions } from '~/bootstrap'
import { CardList } from '~/components/Core/DataDisplay/CardList/CardList'
import { BEM } from '~/services/BEMService'
import { PinnedThemeOptionsType } from '../../Pinned/PinnedThemesQuery'
import { ThemesOverviewItemCard } from './ThemesOverviewItemCard'
import { ThemesOverviewThemeType } from './ThemesOverviewTableQuery'

interface Props {
    pinnedThemesOptions: PinnedThemeOptionsType
    onUnpin?: () => void
}

export class ThemesOverviewPinnedThemesContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ThemesOverviewPinnedThemesContainer')
    public render() {
        const { pinnedThemesOptions, onUnpin } = this.props
        const { unpin } = pinnedThemesOptions

        if (pinnedThemesOptions.loading || !pinnedThemesOptions.themes.length) {
            return null
        }

        const notAllowedToPin = !permissions.isCustomerUser()

        return (
            <CardList className={this.bem.getClassName()} columns={3}>
                <TransitionGroup component={null}>
                    {pinnedThemesOptions.themes.map(theme => (
                        <CSSTransition key={theme.id} classNames={animations.popIn} timeout={225} in={true}>
                            <ThemesOverviewItemCard
                                notAllowedToPin={notAllowedToPin}
                                theme={theme as ThemesOverviewThemeType}
                                isPinned={true}
                                togglePinned={() => {
                                    unpin(theme.id)

                                    if (onUnpin) {
                                        onUnpin()
                                    }
                                }}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </CardList>
        )
    }
}
