import './MultiStepModal.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ModalProps, Modal } from './Modal'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Row } from '../../Layout/Row'
import { Button, ButtonType } from '../../Button/Button'
import { localize } from '~/bootstrap'
import { IconType } from '../../Icon/IconType'

interface Props extends Omit<ModalProps, 'onAction'> {
    className?: ClassValue
    defaultIndex?: number
    steps: ModalStep[]
    onAction?: () => void
    hideCloseButton?: boolean
}

interface State {
    activeIndex: number
    isAnimating: boolean
}

enum AnimationTypes {
    slideIn = 'rf-modal-slide-in',
    slideOut = 'rf-modal-slide-out',
}

export interface ModalStep {
    name: string
    content: (setActiveIndex?: (index: number) => void) => JSX.Element
    title?: string
    actions?: (setActiveIndex?: (index: number) => void) => (requestClose?: () => void) => JSX.Element
}

export class MultiStepModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        activeIndex: this.props.defaultIndex || 0,
        isAnimating: false,
    }

    private bem = new BEM('MultiStepModal', () => ({
        isAnimating: this.state.isAnimating,
    }))

    public render() {
        const { className, defaultIndex, steps, title, onAction, hideCloseButton, ...modalProps } = this.props
        const { activeIndex } = this.state

        const activeStep = steps[activeIndex]

        const modalActions = this.getModalActions()

        return (
            <Modal
                className={this.bem.getClassName(className)}
                actions={modalActions}
                title={activeStep.title || title}
                onAction={this.handleOnAction}
                {...modalProps}
                hideCloseButton={hideCloseButton && this.state.activeIndex !== 0}
            >
                <TransitionGroup>
                    <CSSTransition
                        classNames={this.getAnimationClassName()}
                        timeout={{ enter: 400, exit: 400 }}
                        key={activeIndex}
                        onEnter={() => this.setState({ isAnimating: true })}
                        onEntered={() => this.setState({ isAnimating: false })}
                        onExit={() => this.setState({ isAnimating: true })}
                        onExited={() => this.setState({ isAnimating: false })}
                    >
                        {activeStep && activeStep.content(this.setActiveIndex)}
                    </CSSTransition>
                </TransitionGroup>
            </Modal>
        )
    }

    private handleOnAction = () => {
        const { onAction } = this.props

        if (onAction) {
            onAction()
        }
    }

    private getAnimationClassName() {
        const { activeIndex } = this.state

        if (activeIndex === 0) {
            return AnimationTypes.slideOut
        }

        return AnimationTypes.slideIn
    }

    private setActiveIndex = (newActiveIndex: number) => {
        this.setState({
            activeIndex: newActiveIndex,
        })
    }

    private getModalActions() {
        const { activeIndex } = this.state
        const { actions, steps } = this.props

        if (actions) {
            // Do nothing special, just show the actions that where passed
            return actions
        }

        const activeStep = steps[activeIndex]

        if (activeStep && activeStep.actions) {
            return activeStep.actions(this.setActiveIndex)
        }

        // If the active step is the first and it has no actions just render nothing
        if (activeIndex === 0) {
            return undefined
        }

        // If it's anything else render a return button as action
        return () => (
            <Row>
                <Button
                    type={ButtonType.secondary}
                    onClick={() => {
                        this.setActiveIndex(0)
                    }}
                    icon={IconType.back}
                >
                    {localize.translate(t => t.Generic.previous)}
                </Button>
            </Row>
        )
    }
}
