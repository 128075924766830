import './TaskOpenClosedCounter.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '~/components/Core/Typography/Paragraph'

interface Props {
    className?: ClassValue
    openAmount: number
    closedAmount: number
    overDueAmount?: number
}

interface State {}

export class TaskOpenClosedCounter extends React.Component<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Task)

    private bem = new BEM('TaskOpenClosedCounter')

    public render() {
        const { openAmount, closedAmount, overDueAmount } = this.props

        const hasAnyTasks = openAmount > 0 || closedAmount > 0
        const hasOverDueTask = overDueAmount ? overDueAmount > 0 : false

        if (!hasAnyTasks) {
            return <p className={this.bem.getElement('task-empty-title')}>{this.loc(t => t.TaskWidget.noTasks)}</p>
        }

        if (hasOverDueTask) {
            return (
                <Tooltip content={this.loc(t => t.TaskWidget.overDueTasks, { smart_count: overDueAmount })}>
                    <div className={this.bem.getElement('tasks-container')}>
                        <Icon className={this.bem.getElement('attention-icon')} type={IconType.attention} />
                        {this.renderTaskCounts()}
                    </div>
                </Tooltip>
            )
        }

        return <div className={this.bem.getElement('tasks-container')}>{this.renderTaskCounts()}</div>
    }

    private renderTaskCounts() {
        const { openAmount, closedAmount } = this.props

        return (
            <>
                <Paragraph className={this.bem.getElement('open-count')}>
                    {this.loc(t => t.TaskWidget.openTasks, { openAmount })}
                </Paragraph>
                <Paragraph className={this.bem.getElement('closed-count')}>
                    {this.loc(t => t.TaskWidget.closedTasks, { closedAmount })}
                </Paragraph>
            </>
        )
    }
}
