import './TabletConsultationItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { TextLink } from '~/components/Core/Text/TextLink'
import { Row } from '~/components/Core/Layout/Row'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'

interface Props {
    className?: ClassValue
    title: string
    source: string
    startsAt: string | null
    endsAt: string | null
    profiles: CustomerFramework[]
    to: string
}

interface State {}

export class TabletConsultationItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('TabletConsultationItem')

    public render() {
        const { title, source, startsAt, endsAt, profiles, to, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Row spaceBetween={true}>
                    <h3 className={this.bem.getElement('title')}>
                        <TextLink to={to}>{title}</TextLink>
                    </h3>
                    <p>{source}</p>
                </Row>
                <Row spaceBetween={true}>
                    <Row>
                        {startsAt && (
                            <time className={this.bem.getElement('start-date')}>
                                {localize.translate(t => t.Generic.beginDateAt, {
                                    date: localize.dateFormat(new Date(startsAt), { noWeekday: true, readable: true }),
                                })}
                            </time>
                        )}
                        {endsAt && (
                            <time className={this.bem.getElement('end-date')}>
                                {localize.translate(t => t.Generic.endDateAt, {
                                    date: localize.dateFormat(new Date(endsAt), { noWeekday: true, readable: true }),
                                })}
                            </time>
                        )}
                    </Row>
                    {profiles && (
                        <CustomerFrameworkIconList
                            customerFrameworks={profiles}
                            className={this.bem.getElement('profile-list')}
                        />
                    )}
                </Row>
            </div>
        )
    }
}
