import './HierarchyControlTile.scss'

import React from 'react'

import { Link } from 'react-router-dom'
import { BEM, ClassValue } from '~/services/BEMService'
import {
    UnlinkNestedControlsDocument,
    UnlinkNestedControlsMutationVariables,
    UnlinkNestedControlsMutation,
    ControlType,
} from '~/generated/graphql'
import { CONTROL_DETAIL_QUERY } from '../ControlDetail/ControlQuery'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize, notification, permissions } from '~/bootstrap'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn } from 'react-apollo'
import { Icon } from '~/components/Core/Icon/Icon'
import { getIconTypeForControlType } from '~/utils/controls'
import { isPast } from 'date-fns'
import { AttentionIcon } from '~/components/Core/Icon/AttentionIcon/AttentionIcon'

interface Props {
    className?: ClassValue
    linkedToControl?: Pick<ControlType, 'id' | 'type' | 'name' | 'revisionDate'>
    control: Pick<ControlType, 'id' | 'type' | 'name' | 'revisionDate'>
    isCurrentControl?: boolean
}

interface State {}

export class HierarchyControlTile extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.DetailView)

    private bem = new BEM('HierarchyControlTile', () => ({
        'is-current-control': this.props.isCurrentControl,
    }))

    public render() {
        const { isCurrentControl } = this.props

        if (isCurrentControl) {
            return this.renderCurrentControl()
        }

        return this.renderLinkedControl()
    }

    private renderLinkedControl(): React.ReactNode {
        const { className, control, linkedToControl } = this.props
        const { customer } = this.context

        const controlRoute = routes.customer(customer.slug).compliance.controls.view(control.id)
        const showAction = permissions.canUpdateComplianceAndDocuments(this.context.activeDepartmentId)

        return (
            <Link className={this.bem.getClassName(className)} to={controlRoute}>
                {this.renderControlIcon()}
                {control.name}
                {showAction && linkedToControl && (
                    <GQLMutation<UnlinkNestedControlsMutation, UnlinkNestedControlsMutationVariables>
                        mutation={UnlinkNestedControlsDocument}
                        refetchQueries={[
                            {
                                query: CONTROL_DETAIL_QUERY,
                                variables: { id: linkedToControl.id, departmentId: this.context.activeDepartmentId },
                            },
                        ]}
                    >
                        {(mutate, { loading }) => (
                            <ConfirmModal
                                onConfirm={closeModal => this.onConfirmUnlink(closeModal, mutate)}
                                title={this.loc(t => t.unlinkControlConfirmTitle)}
                                message={this.loc(t => t.unlinkControlConfirmDescription)}
                            >
                                {openModal => (
                                    <Button
                                        icon={IconType.unlink}
                                        type={ButtonType.noStyling}
                                        className={this.bem.getElement('unlink-button')}
                                        onClick={openModal}
                                        stopClickPropagation={true}
                                        preventDefault={true}
                                        loading={loading}
                                    >
                                        {this.loc(t => t.unlinkControl)}
                                    </Button>
                                )}
                            </ConfirmModal>
                        )}
                    </GQLMutation>
                )}
            </Link>
        )
    }

    private renderCurrentControl(): React.ReactNode {
        const { className, control } = this.props

        const isExpired = control.revisionDate ? isPast(control.revisionDate) : false

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderControlIcon()}
                {control.name}
                {isExpired ? (
                    <AttentionIcon
                        className={this.bem.getElement('expired-warning')}
                        label={localize.translate(t => t.Control.expiredRevisionDateWarning)}
                    />
                ) : null}
            </div>
        )
    }

    private renderControlIcon() {
        const { control } = this.props

        return <Icon type={getIconTypeForControlType(control.type)} className={this.bem.getElement('control-icon')} />
    }

    private onConfirmUnlink = async (
        closeConfirmationModal: () => void,
        mutate: MutationFn<UnlinkNestedControlsMutation, UnlinkNestedControlsMutationVariables>
    ): Promise<void> => {
        const { linkedToControl, control } = this.props
        const { activeDepartmentId } = this.context

        if (!linkedToControl) {
            return
        }

        const response = await mutate({
            variables: {
                controlId: linkedToControl.id,
                unlinkControlIds: [control.id],
                departmentId: activeDepartmentId,
            },
        })

        if (response && response.data && response.data.unlinkNestedControls) {
            notification.success(this.loc(t => t.unlinkControlSucces))

            closeConfirmationModal()
        }
    }
}
