import React from 'react'
import { permissions } from '~/bootstrap'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { Table } from '~/components/Core/DataDisplay/Table/Table'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { BulkMonitoringReportsActionContainer } from './BulkMonitoringReportsActionContainer'
import { MonitoringReportItem } from './MonitoringReportItem'
import { MonitoringReportsQueryType } from './MonitoringReportsQuery'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import './MonitoringReportsContainer.scss'

interface Props {
    className?: ClassValue
    monitoringReports: MonitoringReportsQueryType[]
    loadingMore?: boolean
    refetch: () => void
    hideBulkAction?: boolean
}

interface State {
    toBeArchivedIds: number[]
    isCheckboxActive: boolean
}

export class MonitoringReportsContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        toBeArchivedIds: [],
        isCheckboxActive: false,
    }

    private bem = new BEM('MonitoringReportsContainer')

    private showActions = permissions.canEditMonitoringReport() && !this.props.hideBulkAction

    public render() {
        const { className, loadingMore, refetch, monitoringReports } = this.props

        const { toBeArchivedIds } = this.state

        if (!monitoringReports.length) {
            return (
                <div className={this.bem.getClassName(className)}>
                    <Table data={[]} columns={[]} whiteBackground />
                </div>
            )
        }

        let currentYearGroup = 0

        return (
            <div className={this.bem.getClassName(className)}>
                <BulkMonitoringReportsActionContainer
                    onSubmit={refetch}
                    monitoringReportIds={toBeArchivedIds}
                    onClearSelection={() => this.setState({ toBeArchivedIds: [], isCheckboxActive: false })}
                />

                <ItemListSpacer>
                    {monitoringReports.map((monitoringReport, index) => {
                        let showYearTitle = false
                        if (new Date(monitoringReport.assessedAt).getFullYear() !== currentYearGroup) {
                            currentYearGroup = new Date(monitoringReport.assessedAt).getFullYear()
                            showYearTitle = true
                        }

                        return (
                            <React.Fragment key={monitoringReport.id}>
                                {showYearTitle && (
                                    <SectionTitle className={this.bem.getElement('year')} bold>
                                        {currentYearGroup}
                                    </SectionTitle>
                                )}
                                <li>
                                    <MonitoringReportItem
                                        isCheckboxActive={this.state.isCheckboxActive}
                                        isFirstItem={index === 0}
                                        handleOnCardCheck={
                                            this.showActions
                                                ? this.handleOnMonitoringReportItemCheck(monitoringReport)
                                                : undefined
                                        }
                                        checked={toBeArchivedIds.includes(monitoringReport.id)}
                                        monitorReportItem={monitoringReport}
                                        refetch={refetch}
                                    />
                                </li>
                            </React.Fragment>
                        )
                    })}
                    {loadingMore && (
                        <li>
                            <Spinner />
                        </li>
                    )}
                </ItemListSpacer>
            </div>
        )
    }

    private handleOnMonitoringReportItemCheck = (item: any) => (checked: boolean) => {
        const { toBeArchivedIds } = this.state

        if (checked) {
            this.setState({
                toBeArchivedIds: [...toBeArchivedIds, item.id],
                isCheckboxActive: true,
            })
            return
        }

        const filteredArchivedIds = toBeArchivedIds.filter(id => id !== item.id)
        const isCheckboxActive = filteredArchivedIds.length > 0

        this.setState({
            toBeArchivedIds: toBeArchivedIds.filter(id => id !== item.id),
            isCheckboxActive,
        })
    }
}
