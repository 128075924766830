import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { ConsultationsOverviewView } from './ConsultationsOverviewView'
import { ConsultationDetailView } from './ConsultationDetailView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}

class ConsultationsViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route path={''} element={<ConsultationsOverviewView />} />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).consultations.detail.view()}
                    element={<ConsultationDetailView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const ConsultationsView = withRouter(ConsultationsViewComponent)
