import React from 'react'
import { default as ReactInfiniteScroll } from 'react-infinite-scroller'
import { BEM } from '~/services/BEMService'

export interface InfiniteScrollProps {
    onReachedEnd?: () => void
    hideLoadMore?: boolean
    scrollParent?: React.RefObject<HTMLElement>
    useWindow?: boolean
    hasMore?: boolean
}

export class InfiniteScroll extends React.Component<React.PropsWithChildren<InfiniteScrollProps>> {
    private bem = new BEM('InfiniteScroll')

    public render() {
        const { children, hasMore, useWindow, scrollParent } = this.props

        return (
            <ReactInfiniteScroll
                className={this.bem.getClassName()}
                loadMore={this.handleLoadMore}
                hasMore={hasMore}
                useWindow={useWindow}
                threshold={useWindow !== false ? window.innerHeight / 0.75 : undefined}
                getScrollParent={scrollParent ? () => scrollParent.current : undefined}
            >
                {children}
            </ReactInfiniteScroll>
        )
    }

    private handleLoadMore = () => {
        const { onReachedEnd } = this.props

        if (onReachedEnd) {
            onReachedEnd()
        }
    }
}
