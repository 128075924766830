import './TaskControlTable.scss'

import React from 'react'
import { Table, RowData, SortDirection } from '~/components/Core/DataDisplay/Table/Table'
import { TasksForMonthDocument, TaskType } from '~/generated/graphql'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { PriorityIndicator } from '../../../Task/PriorityIcon/PriorityIndicator'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { BEM, ClassValue } from '~/services/BEMService'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize, permissions } from '~/bootstrap'
import { TaskControlRowTombstone } from './TaskControlRowTombstone'
import { CSSTransition } from 'react-transition-group'
import { animations } from '~/animations'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { TaskControlFilters } from '~/views/Customer/Control/TaskControlOverviewView'
import { IgnoreTaskControlModal } from '../IgnoreTaskControlModal/IgnoreTaskControlModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { CreateControlTaskModal } from '../../CreateControlTaskModal/CreateControlTaskModal'
import { ControlTaskTooltip } from './ControlTaskTooltip'
import { TaskIgnoredForControlTooltip } from './TaskIgnoredForControlTooltip'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { RevertIgnoreTaskControlButton } from '../RevertIgnoreTaskControlButton/RevertIgnoreTaskControlButton'
import { TaskNameWithCompleteButton } from '../../../Task/TaskNameWithCompleteButton'
import { Row } from '~/components/Core/Layout/Row'
import { LinkedTaskLabel } from '../../../Task/LinkedTaskLabel'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { routes } from '~/views/routes'
import { Guard } from '~/components/Core/Guard/Guard'

interface Props {
    className?: ClassValue
    year: number
    month: number
    totalTaskCount: number
    paramState: TaskControlFilters
    refetch: Function
    isCheckboxActive: boolean
    bulkActionIds: number[]
    onCheck: (task: TaskType, checked: boolean) => void
    hideCheckboxAnimation?: boolean
}

interface State {
    sortDirection: { field: string; direction: SortDirection }
    isInitialLoad: boolean
}

interface FetchMoreButtonArgs {
    canFetchMore: boolean
    loadingMore: boolean
    fetchMore: () => void
    totalCount: number
    currentCount: number
}

const defaultSortDirection = { field: 'createdAt', direction: 'DESC' as SortDirection }

export class TaskControlTable extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        sortDirection: defaultSortDirection,
        isInitialLoad: true,
    }

    private bem = new BEM('TaskControlTable')
    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.TaskControlOverview.table)

    public render() {
        const { year, month, className, paramState, totalTaskCount, hideCheckboxAnimation } = this.props
        const { sortDirection, isInitialLoad } = this.state

        if (totalTaskCount === 0) {
            // we already know that the query will return 0 items
            return this.renderEmptyTable()
        }

        return (
            <PaginatableQuery<TaskType>
                take={8}
                query={TasksForMonthDocument}
                variables={{
                    year,
                    month: month,
                    departmentId: this.context.activeDepartmentId,
                    filters: {
                        status: paramState.status || undefined,
                        linkType: this.getLinkTypeValue(),
                        isGenericType: paramState.isGenericType,
                    },
                    sort: { [sortDirection.field]: sortDirection.direction },
                }}
            >
                {({ data, loading, loadingMore, canFetchMore, fetchMore }) => {
                    const tasks = data && data.nodes ? data.nodes : []

                    const totalCount = data?.totalCount || 0
                    const currentCount = tasks.length

                    const initialLoading = loading && isInitialLoad
                    const regularLoading = loading && !isInitialLoad

                    const columns = this.getColumns()
                    const tableData = this.getTableData(tasks)
                    const fetchMoreButtonArgs = { canFetchMore, fetchMore, loadingMore, totalCount, currentCount }

                    return (
                        <>
                            <Table
                                className={this.bem.getClassName(className)}
                                customLoadingComponent={this.renderTombstones(loading)}
                                emptyState={this.getTableEmptyState()}
                                hideColumnsWhenEmpty={true}
                                loading={initialLoading}
                                columns={columns}
                                data={tableData}
                                rowAction={({ row: { id } }) =>
                                    this.renderRowActions(data && data.nodes.find(task => task.id === id))
                                }
                                onSortDirectionChange={this.handleSortDirectionChange}
                                hideInitialCheckboxAnimation={hideCheckboxAnimation}
                            />
                            {(regularLoading || loadingMore) && <Spinner delayed={true} />}
                            {this.renderFetchMoreButton(fetchMoreButtonArgs)}
                        </>
                    )
                }}
            </PaginatableQuery>
        )
    }

    private renderEmptyTable() {
        const { className } = this.props

        return (
            <Table
                className={this.bem.getClassName(className)}
                emptyState={this.getTableEmptyState()}
                hideColumnsWhenEmpty={true}
                columns={[]}
                data={[]}
            />
        )
    }

    private getColumns() {
        return [
            { field: 'name', headerLabel: this.loc(t => t.name), truncateField: true },
            { field: 'department', headerLabel: localize.translate(t => t.Entities.Department) },
            { field: 'createdAt', headerLabel: this.loc(t => t.createdAt) },
            { field: 'priority', headerLabel: this.loc(t => t.priority) },
        ]
    }

    private getTableData(tasks: TaskType[]): RowData[] {
        const { onCheck, bulkActionIds, isCheckboxActive } = this.props

        return tasks.map(task => {
            const { id, ignoredAt, ignoredBy, reviewTask, createdAt, priority, department } = task
            const hideActions = !permissions.canCreateTasks(department.id) || ignoredAt || ignoredBy || reviewTask

            return {
                id: id,
                columns: {
                    name: this.renderName(task),
                    department: <LabelTag subtle={true} label={task.department.name} />,
                    createdAt: <DateFormat date={new Date(createdAt)} readable={true} noWeekday={true} />,
                    priority: <PriorityIndicator isTooltip={true} priority={priority} />,
                },
                onCheckChange: hideActions ? undefined : (_, checked) => onCheck(task, checked),
                isCheckboxChecked: bulkActionIds.includes(id),
                isCheckboxActive: isCheckboxActive,
            }
        })
    }

    private renderFetchMoreButton(args: FetchMoreButtonArgs) {
        const { canFetchMore, fetchMore, loadingMore, totalCount, currentCount } = args

        if (!canFetchMore || loadingMore) {
            return
        }

        const nextItemsCount = this.getLoadMoreCount(currentCount, totalCount)

        return (
            <div className={this.bem.getElement('fetch-more-wrapper')}>
                <Button
                    icon={IconType.arrowDown}
                    onClick={() => fetchMore()}
                    type={ButtonType.actionLink}
                    className={this.bem.getElement('fetch-more')}
                >
                    {this.loc(t => t.showMore, { nextItemsCount, totalCount })}
                </Button>
            </div>
        )
    }

    private getTableEmptyState() {
        return <span className={this.bem.getElement('empty-state')}>{this.loc(t => t.emptyState)}</span>
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 8) {
            return 8
        }

        return leftOverNodes
    }

    private renderRowActions = (task?: TaskType | null) => {
        const { refetch } = this.props

        if (!task) {
            return null
        }

        const canEditTasks = permissions.canEditTasks(
            task.department.id,
            task.employees.map(e => e.id)
        )

        if (task.ignoredAt && task.ignoredBy) {
            return (
                <div className={this.bem.getElement('is-ignored')}>
                    <Guard condition={canEditTasks}>
                        <Tooltip direction={ToolTipDirection.downEnd} message={this.loc(t => t.revertTooltip)}>
                            <RevertIgnoreTaskControlButton
                                refetch={refetch}
                                taskId={task.id}
                                className={this.bem.getElement('revert-ignore')}
                            />
                        </Tooltip>
                    </Guard>
                    <TaskIgnoredForControlTooltip
                        ignoredAt={task.ignoredAt}
                        ignoredBy={task.ignoredBy}
                        ignoredReason={task.ignoredReason}
                    />
                </div>
            )
        }

        if (task.reviewTask) {
            return (
                <div className={this.bem.getElement('has-control-task')}>
                    <ControlTaskTooltip reviewTask={task.reviewTask} />
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('actions')}>
                <div className={this.bem.getElement('hidden-actions')}>
                    <Guard condition={canEditTasks}>
                        <IgnoreTaskControlModal
                            tasks={task ? [task] : []}
                            refetch={refetch ? () => refetch() : undefined}
                        >
                            {openModal => (
                                <Button
                                    onClick={openModal}
                                    type={ButtonType.secondary}
                                    icon={IconType.ignoreControlTask}
                                />
                            )}
                        </IgnoreTaskControlModal>
                    </Guard>
                    <Guard condition={permissions.canCreateTasks(task.department.id)}>
                        <ModalManager
                            render={requestOpen => <Button onClick={requestOpen} icon={IconType.createControlTask} />}
                            renderModal={requestClose => (
                                <CreateControlTaskModal
                                    onCreate={refetch ? () => refetch() : undefined}
                                    requestClose={requestClose}
                                    tasks={[task]}
                                />
                            )}
                        />
                    </Guard>
                </div>
            </div>
        )
    }

    private handleSortDirectionChange = (field: string, direction: SortDirection) => {
        this.setState({ sortDirection: { field, direction }, isInitialLoad: false })
    }

    private renderTombstones(loading: boolean) {
        const { totalTaskCount } = this.props
        const { isInitialLoad } = this.state

        if (!totalTaskCount || !loading || !isInitialLoad) {
            return undefined
        }

        const amountOfTasks = totalTaskCount >= 8 ? 8 : totalTaskCount

        return (
            <CSSTransition unmountOnExit={true} in={loading} timeout={150} classNames={animations.fadeIn}>
                <div className={this.bem.getElement('tombstones')}>
                    {[...Array(amountOfTasks)].map((_value, index) => (
                        <TaskControlRowTombstone key={`tombstone-${index}`} isEven={index % 2 === 0} />
                    ))}
                </div>
            </CSSTransition>
        )
    }

    private getLinkTypeValue() {
        const { paramState } = this.props
        const linkTypeValue = paramState.linkType?.value

        if (!linkTypeValue || linkTypeValue === 'all' || linkTypeValue === 'generic') {
            return undefined
        }

        return linkTypeValue as LinkedTaskItem
    }

    private renderName(task: TaskType) {
        const { isSubTask } = task

        return (
            <Row noSpacing={true} className={this.bem.getElement('name-row')}>
                <div className={this.bem.getElement('linked-item-container', () => ({ isSubTask }))}>
                    <LinkedTaskLabel linkedItem={task.linkedItem as LinkedTaskItem} iconOnly={true} />
                </div>
                <TaskNameWithCompleteButton
                    to={`${routes.customer(this.context.customer.slug).control.taskControl.view(task.id)}${
                        location.search || ''
                    }`}
                    task={task as any}
                    className={this.bem.getElement('name')}
                />
            </Row>
        )
    }
}
