import './WidgetDepartmentsTooltip.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { LabelTag } from '~/components/Core/DataDisplay/LabelTag/LabelTag'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    departments: {
        id: number
        name: string
    }[]
    allDepartments?: boolean
    whiteBackground?: boolean
    className?: ClassValue
}

export class WidgetDepartmentsTooltip extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('WidgetDepartmentsTooltip')

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)}>{this.renderDepartments()}</div>
    }

    private renderDepartments() {
        const { departments, allDepartments } = this.props

        if (allDepartments) {
            return this.renderAllDepartments()
        }

        if (!departments.length) {
            return null
        }

        if (departments.length === 1) {
            return this.renderSingleDepartment(departments[0])
        }

        return this.renderMultipleDepartments()
    }

    private renderAllDepartments() {
        const label = localize.translate(t => t.Customer.DashboardView.Widgets.allDepartments)

        return (
            <LabelTag
                subtle={true}
                label={label}
                className={this.bem.getElement('label-tag', () => ({ 'has-white-bg': this.props.whiteBackground }))}
            />
        )
    }

    private renderSingleDepartment({ id, name }: { id: number; name: string }) {
        return (
            <LabelTag
                subtle={true}
                label={name}
                className={this.bem.getElement('label-tag', () => ({
                    'is-current-department': this.context.activeDepartment.id === id,
                    'has-white-bg': this.props.whiteBackground,
                }))}
            />
        )
    }

    private renderMultipleDepartments() {
        const { departments } = this.props

        const tooltipTitle = localize.translate(t => t.Customer.Inbox.departments, {
            count: departments.length,
        })

        const tooltipContent = this.getTooltipContent()

        return (
            <Tooltip className={this.bem.getElement('tooltip')} content={<>{tooltipContent}</>}>
                <LabelTag
                    subtle={true}
                    label={tooltipTitle}
                    className={this.bem.getElement('label-tag', () => ({ 'has-white-bg': this.props.whiteBackground }))}
                />
            </Tooltip>
        )
    }

    private getTooltipContent() {
        const { departments } = this.props

        const tooltipContent = departments.map(({ id, name }) => {
            const className = this.bem.getElement('label-tag', () => ({
                'is-current-department': this.context.activeDepartment.id === id,
            }))
            return <LabelTag key={`${id}-department`} subtle={true} label={name} className={className} />
        })

        return tooltipContent
    }
}
