import './Search.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Input, InputProps } from '../Form/Input'
import { IconType } from '../../Icon/IconType'
import debounce from 'lodash-es/debounce'
import { localize } from '~/bootstrap'

interface Props extends Partial<InputProps> {
    onChange: (searchString: string | null) => void
    icon?: IconType | null
}

export const Search: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const bem = new BEM('Search')

    const {
        className,
        onChange,
        placeholder = localize.translate(t => t.Core.Search.placeholder),
        name = 'search',
        icon = IconType.search,
        // tslint:disable-next-line:trailing-comma
        ...restProps
    } = props

    return (
        <Input
            className={bem.getClassName(className)}
            placeholder={placeholder}
            name={name}
            icon={icon}
            onChange={debounce(value => onChange(value), 250)}
            {...restProps}
        />
    )
}
