import React from 'react'
import { ContextClosedTasksTableQuery } from './ContextClosedTasksTableQuery'
import { ContextTasksTable } from './ContextTasksTable'
import { TasksQueryFilters } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'

interface Props {
    filters?: TasksQueryFilters
    onChange?: () => void
    registerRefetchFunction?: (refetch: any) => void
    forCustomer?: boolean
    forDepartment?: number
}

export class ContextClosedTasksTableContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public render() {
        const { filters, onChange, registerRefetchFunction, forCustomer, forDepartment } = this.props

        return (
            <ContextClosedTasksTableQuery forCustomer={forCustomer} forDepartment={forDepartment} filters={filters}>
                {({ data, loading, loadingMore, canFetchMore, refetch, hasPaginated }) => {
                    const tasks = data ? data.nodes : []

                    /**
                     * This function 'passes' the refetch function to the parent.
                     * The way the components are tied together requires us to do this.
                     */
                    if (registerRefetchFunction) {
                        // TODO refactor
                        registerRefetchFunction(refetch)
                    }

                    return (
                        <ContextTasksTable
                            showDepartment={forCustomer}
                            tasks={tasks}
                            loading={loading}
                            loadingMore={loadingMore}
                            endReached={!canFetchMore && hasPaginated}
                            onChange={() => {
                                if (onChange) {
                                    onChange()
                                }

                                refetch()
                            }}
                        />
                    )
                }}
            </ContextClosedTasksTableQuery>
        )
    }
}
