import './NotFound.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Center } from '~/components/Core/Layout/Center'
import illustration from './not-found.svg'
import { localize } from '~/bootstrap'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'
import { Button } from '~/components/Core/Button/Button'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
}

export const NotFound = withRouter(
    class NotFound extends React.PureComponent<React.PropsWithChildren<Props>> {
        private bem = new BEM('NotFound')

        private loc = localize.namespaceTranslate(t => t.Customer.PageQuery)

        public render() {
            const { className } = this.props

            return (
                <Center>
                    <div className={this.bem.getClassName(className)}>
                        <img src={illustration} alt={this.loc(t => t.notFoundImgAlt)} />
                        <Column smallSpacing={true}>
                            <Paragraph className={this.bem.getElement('text')}>
                                {this.loc(t => t.notFoundDescription)}
                            </Paragraph>
                            {this.props.history.canGoBack && (
                                <Row alignCenter={true}>
                                    <Button onClick={() => this.props.history.goBack()}>
                                        {this.loc(t => t.goBack)}
                                    </Button>
                                </Row>
                            )}
                        </Column>
                    </div>
                </Center>
            )
        }
    }
)
