import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TextAttention } from '~/components/Core/Text/TextAttention'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { isPast } from 'date-fns'

interface Props {
    className?: ClassValue
    date: Date
    thresholdDate?: Date
}

export const RevisionDateFormat: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
    const { className, thresholdDate } = props
    const bem = new BEM('RevisionDateFormat')

    const dateToCompare = thresholdDate || props.date
    const isExpired = isPast(dateToCompare)

    return (
        <TextAttention show={isExpired} className={bem.getClassName(className)}>
            <DateFormat date={props.date} readable={true} noWeekday={true} />
        </TextAttention>
    )
}
