import './AssessmentLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { matchPath } from 'react-router'

export interface Assessment {
    id: number
    name: string
}

interface Props {
    className?: ClassValue
    assessment: Assessment
    alignRight?: boolean
    iconOnly?: boolean
}

interface State {}

export class AssessmentLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('AssessmentLabel')

    public render() {
        const { className, alignRight, assessment, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).risks.assessments.view(assessment.id)
        const match = matchPath(
            {
                path: link,
                caseSensitive: false,
            },
            window.location.pathname
        )

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                iconType={IconType.assessment}
                alignRight={alignRight}
                to={match ? undefined : link}
            >
                {iconOnly ? null : assessment.name}
            </IconLabel>
        )
    }
}
