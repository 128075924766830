import React from 'react'
import './AcceptBlock.scss'

import { BEM, ClassValue } from '~/services/BEMService'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Form } from '~/components/Core/DataEntry/Form/Form'

interface Props {
    className?: ClassValue
    title: string
    description: string
    name?: string
    defaultChecked?: boolean
    checked?: boolean
    onChange?: (value: boolean) => void
    isDisabled?: boolean
}

interface State {}

export class AcceptBlock extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('AcceptBlock')

    public render() {
        const { className, name, title, description, defaultChecked, checked, onChange, isDisabled } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <Form.Toggle
                    name={name || 'isAccepted'}
                    label={title}
                    defaultChecked={defaultChecked}
                    checked={checked}
                    onChange={onChange}
                    disabled={isDisabled}
                    className={this.bem.getElement('toggle')}
                />
                <Paragraph className={this.bem.getElement('paragraph')}>{description}</Paragraph>
            </div>
        )
    }
}
