import './OptionsDropdownOption.scss'

import React from 'react'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '../Button/Button'
import { OptionsDropdownContext, OptionsDropdownContextValue } from './OptionsDropdown'

type OnClickFn = (closeDropdown: () => void) => void

export enum OptionsDropdownOptionStyle {
    default = 'default',
    primary = 'primary',
}

interface Props {
    className?: string
    label: string
    icon?: IconType
    onClick?: OnClickFn
    to?: string
    loading?: boolean
    preventCloseOnClick?: boolean
    style?: OptionsDropdownOptionStyle
    newSection?: boolean
}

export class OptionsDropdownOption extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = OptionsDropdownContext
    public context: OptionsDropdownContextValue

    private bem = new BEM('OptionsDropdownOption', () => ({
        'style-primary': this.props.style === OptionsDropdownOptionStyle.primary,
    }))

    public render() {
        const { label, icon, to, loading, onClick, className, newSection } = this.props

        return (
            <>
                {newSection === true && <span className={this.bem.getElement('new-section')} />}
                <Button
                    className={this.bem.getClassName(className)}
                    type={ButtonType.noStyling}
                    to={to}
                    onClick={onClick ? () => this.handleClick(onClick) : undefined}
                    loading={loading}
                    icon={icon}
                >
                    {label}
                </Button>
            </>
        )
    }

    private handleClick = (onClick: OnClickFn) => {
        const { preventCloseOnClick } = this.props

        if (!preventCloseOnClick) {
            this.context.closeDropdown()
        }

        onClick(this.context.closeDropdown)
    }
}
