import React from 'react'
import { localize } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerAlertSummaryPerMonthType, DepartmentAlertDataType } from '~/generated/graphql'
import { ClassValue } from '~/services/BEMService'
import { InboxControlFilters } from '~/views/Customer/Control/InboxControlOverviewView'
import { ControlSummaryList } from '../ControlSummaryList'
import { GroupedInboxControlTable } from './GroupedInboxControlTable'
import { AlertsSummaryPerMonth } from './queries/AlertsSummaryPerMonth'

interface Props {
    className?: ClassValue
    paramState: InboxControlFilters
    isFilterActive: boolean
}

interface State {
    selectedAlertDatas: DepartmentAlertDataType[]
}

export class InboxControlSummaryList extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {
        selectedAlertDatas: [],
    }

    public render() {
        const { paramState, isFilterActive, className } = this.props

        return (
            <AlertsSummaryPerMonth filters={paramState}>
                {({ data, loading, loadingMore, refetch }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }

                    const monthSummary = data ? data.nodes : []

                    if (!monthSummary.length && isFilterActive) {
                        return <NoResults />
                    }

                    const { selectedAlertDatas } = this.state

                    return (
                        <ControlSummaryList
                            className={className}
                            summaries={this.getSummaryList(monthSummary, refetch)}
                            controlSummaryType="inbox"
                            loadingMore={loadingMore}
                            selectedItems={selectedAlertDatas}
                            refetch={refetch}
                            onClearSelection={() => this.setState({ selectedAlertDatas: [] })}
                        />
                    )
                }}
            </AlertsSummaryPerMonth>
        )
    }

    private getSummaryList(monthSummary: CustomerAlertSummaryPerMonthType[], refetch: () => void) {
        const { paramState } = this.props
        const { selectedAlertDatas } = this.state

        const firstSummaryWithAlerts = monthSummary.find(m => !!m.alertCount)

        return monthSummary.map((summary, i) => ({
            sectionTitle: this.getSectionTitle(summary),
            children: (
                <GroupedInboxControlTable
                    year={summary.year}
                    month={summary.month}
                    hasNoAlerts={!summary.alertCount}
                    paramState={paramState}
                    refetch={refetch}
                    bulkActionIds={selectedAlertDatas.map(alert => alert.id)}
                    isCheckboxActive={!!selectedAlertDatas.length}
                    onCheck={this.onCheck}
                    hideCheckboxAnimation={summary.id !== firstSummaryWithAlerts?.id}
                />
            ),
        }))
    }

    private getSectionTitle(summary: CustomerAlertSummaryPerMonthType) {
        // Summary months start from 1
        const summaryDate = new Date(summary.year, summary.month - 1)
        const localDate = localize.dateFormat(summaryDate, { readable: true, noDay: true, noWeekday: true })

        return <SectionTitle capitalize={true}>{localDate}</SectionTitle>
    }

    private onCheck = (alertData: DepartmentAlertDataType, checked: boolean) => {
        const { selectedAlertDatas } = this.state

        if (checked) {
            this.setState({ selectedAlertDatas: [...selectedAlertDatas, alertData] })
            return
        }

        const filteredAlerts = selectedAlertDatas.filter(a => a.id !== alertData.id)
        this.setState({ selectedAlertDatas: filteredAlerts })
    }
}
