import './MobileFilterOverlay.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    toggle: () => void
}

interface State {}

export class MobileFilterOverlay extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('MobileFilterOverlay')

    public render() {
        const { className, toggle, children } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('title-container')}>
                    <h2 className={this.bem.getElement('title')}>
                        {localize.translate(t => t.Generic.filterSelection)}
                    </h2>
                    <Icon className={this.bem.getElement('close')} onClick={toggle} type={IconType.close} />
                </div>
                <div className={this.bem.getElement('container')}>{children}</div>
                <Button className={this.bem.getElement('button')} onClick={toggle}>
                    {localize.translate(t => t.Generic.showResults)}
                </Button>
            </div>
        )
    }
}
