import './MonitoringReportTopicsTable.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { BEM } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    ControlType,
    DepartmentTopicDataGradeTypeEnum,
    DepartmentTopicDataMetadataFragmentFragment,
    DepartmentTopicDataType,
    DepartmentType,
    MonitoringReportTopicAssessmentGrade,
    MonitoringReportTopicType,
    MonitoringReportType,
    TaskType,
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
    TopicAssessmentGradeType,
    TopicAssessmentType,
    TopicControlMeasureType,
    TopicNoteType,
    TopicType,
} from '~/generated/graphql'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { IconType } from '~/components/Core/Icon/IconType'
import { TopicAssessmentIcon } from '../../Topic/TopicDesignAndEffectiveness/modals/TopicAssessment/TopicAssessmentIcon'
import { TopicPreviewModal } from '../../Topic/TopicPreviewModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { routes } from '~/views/routes'
import { DepartmentSwitchPopup } from '../../Department/DepartmentSwitchPopup'
import {
    MonitoringReportTopicAssessmentsTable,
    MonitoringReportTopicAssessmentTableData,
} from './MonitoringReportTopicAssessmentsTable'
import { Row } from '~/components/Core/Layout/Row'
import { Icon } from '~/components/Core/Icon/Icon'
import { MonitoringReportTopicAssessmentSummaryTooltip } from './MonitoringReportTopicAssessmentSummaryTooltip'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { ContextTasksModal } from '../../Task/TasksByContext/ContextTasksModal'
import { MinimalLinkedTaskItemStatusQuery } from '../../Task/InlineTaskWidget/MinimalLinkedTaskItemStatusQuery'
import { LinkedTaskItemType } from '~/graphql/types/Task'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'

interface Props {
    monitoringReport: Pick<MonitoringReportType, 'id' | 'name'>
    monitoringReportTopics: MonitoringReportTopicTableData[]
    onAssess?: () => void
}

export type MonitoringReportTopicTableData = Pick<MonitoringReportTopicType, 'id' | 'isRemoved' | 'notApplicable'> & {
    department: Department
    topic: Topic
    controlsAndNotes?: ItemType[] | null
    departmentTopicData?: DepartmentTopicData | null
    assessments?: MonitoringReportTopicAssessmentTableData[] | null
    tasks?: Task[] | null
}
type Department = Pick<DepartmentType, 'id' | 'name'>
type Topic = Pick<TopicType, 'id' | 'name'>
type Task = Pick<TaskType, 'id'>
type ItemType = TopicNote | TopicControlMeasure
type TopicNote = Pick<TopicNoteType, '__typename' | 'id' | 'title'> & {
    assessments?: Assessment[] | null
}
type TopicControlMeasure = Pick<TopicControlMeasureType, 'id' | '__typename'> & {
    control: TopicControl
    assessments?: Assessment[] | null
}
type TopicControl = Pick<ControlType, 'id' | 'name' | 'type'>
type Assessment = Pick<
    TopicAssessmentType,
    'id' | 'grade' | 'topicAssessmentDesignOrEffectiveNessType' | 'documentsCount' | 'updatedAt'
>
type DepartmentTopicData = Pick<DepartmentTopicDataType, 'id'> & {
    topicAssessmentDesignMetadata?: DepartmentTopicDataMetadataFragmentFragment | null
    topicAssessmentEffectivenessMetadata?: DepartmentTopicDataMetadataFragmentFragment | null
}

interface Grade {
    initialGrade: TopicAssessmentGradeType // assessment.initialGrade || cn.grade
    grade: TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade // assessment.grade
}

export class MonitoringReportTopicsTable extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringDetailView.topicsTable)
    private tableRef = React.createRef<Table>()
    private bem = new BEM('MonitoringReportTopicsTable', () => ({
        noChildren: !this.props.monitoringReportTopics.some(mrt => mrt.controlsAndNotes?.length),
    }))

    public render() {
        return (
            <Table
                ref={this.tableRef}
                className={this.bem.getClassName()}
                columns={this.getTableColumns()}
                data={this.getTableData()}
            />
        )
    }

    private getTableColumns(): ColumnOptions[] {
        return [
            { field: 'name', headerLabel: this.loc(t => t.name) },
            { field: 'department', headerLabel: this.loc(t => t.department) },
            { field: 'design', headerLabel: this.loc(t => t.design) },
            { field: 'effectiveness', headerLabel: this.loc(t => t.effectiveness) },
            { field: 'tasks', headerLabel: this.loc(t => t.tasks) },
        ]
    }

    private getTableData(): RowData[] {
        return this.props.monitoringReportTopics.map(mrt => ({
            id: mrt.id,
            columns: {
                name: this.renderTopicName(mrt.topic, mrt.isRemoved),
                department: mrt.department.name,
                design: this.renderDepartmentTopicAssessment(mrt, TopicAssessmentDesignOrEffectiveNessType.design),
                effectiveness: this.renderDepartmentTopicAssessment(
                    mrt,
                    TopicAssessmentDesignOrEffectiveNessType.effectiveness
                ),
                tasks: this.renderTasks(mrt),
            },
            children: mrt.controlsAndNotes?.length ? () => this.renderChildren(mrt) : undefined,
        }))
    }

    private renderTopicName({ id, name }: Topic, isRemoved: boolean) {
        return (
            <TopicPreviewModal topicId={id}>
                {open => (
                    <Button type={ButtonType.noStyling} onClick={open}>
                        <Tooltip message={name} className={this.bem.getElement('label-container')}>
                            <Paragraph bold truncateEllipsis strikethrough={isRemoved} subtle={isRemoved}>
                                {name}
                            </Paragraph>
                        </Tooltip>
                        <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                    </Button>
                )}
            </TopicPreviewModal>
        )
    }

    private renderDepartmentTopicAssessment(
        mrt: MonitoringReportTopicTableData,
        type: TopicAssessmentDesignOrEffectiveNessType
    ) {
        if (!mrt.controlsAndNotes?.length) {
            return null
        }

        const { initialGrade, grade } = this.getTopicGrade(mrt, type)
        const assessmentSummaryProps = this.getAssessmentSummaryProps(mrt, type)

        const { isRemoved, notApplicable } = mrt
        const assessedCount = mrt.assessments?.filter(a => a.assessmentType === type)?.length || 0
        const hasUnassesedItem = mrt.controlsAndNotes.length !== assessedCount

        if (hasUnassesedItem) {
            if (isRemoved || notApplicable) {
                return <Row>{this.renderLeftDepartmentTopicAssessment(mrt, initialGrade)}</Row>
            }

            return (
                <Row>
                    {this.renderLeftDepartmentTopicAssessment(mrt, initialGrade)}
                    <Icon type={IconType.nextArrow2} verySubtle />
                    <Button
                        type={ButtonType.noStyling}
                        onClick={() => this.tableRef.current?.handleToggleRowExpansion(mrt.id)()}
                    >
                        <Tooltip message={this.loc(t => t.unassessedWarning)}>
                            <Icon subtle type={IconType.questionmark} />
                        </Tooltip>
                    </Button>
                </Row>
            )
        }

        return (
            <Row>
                {this.renderLeftDepartmentTopicAssessment(mrt, initialGrade)}
                <Icon type={IconType.nextArrow2} verySubtle />
                <MonitoringReportTopicAssessmentSummaryTooltip {...assessmentSummaryProps}>
                    <TopicAssessmentIcon status={grade} />
                </MonitoringReportTopicAssessmentSummaryTooltip>
            </Row>
        )
    }

    private renderLeftDepartmentTopicAssessment(
        mrt: MonitoringReportTopicTableData,
        initialGrade: TopicAssessmentGradeType
    ) {
        const { isRemoved, notApplicable } = mrt

        if (isRemoved) {
            return (
                <Tooltip message={this.loc(t => t.removedWarning)}>
                    <TopicAssessmentIcon status={DepartmentTopicDataGradeTypeEnum.notApplicable} />
                </Tooltip>
            )
        }

        if (notApplicable) {
            return (
                <Tooltip message={this.loc(t => t.notApplicableWarning)}>
                    <TopicAssessmentIcon status={DepartmentTopicDataGradeTypeEnum.notApplicable} />
                </Tooltip>
            )
        }

        return <TopicAssessmentIcon status={initialGrade} />
    }

    private renderChildren(mrt: MonitoringReportTopicTableData) {
        const inDifferentDepartment = mrt.department.id !== this.context.activeDepartmentId

        if (inDifferentDepartment) {
            return (
                <DepartmentSwitchPopup
                    departmentIdToToggleTo={mrt.department.id}
                    departmentNameToToggleTo={mrt.department.name}
                    onToggle={() => this.proceedToDesignAndEffectiveness(mrt.topic.id)}
                    render={openModal => this.renderAssessmentsTable(mrt, openModal)}
                />
            )
        }

        return this.renderAssessmentsTable(mrt)
    }

    private renderAssessmentsTable(monitoringReportTopic: MonitoringReportTopicTableData, openModal?: () => void) {
        return (
            <MonitoringReportTopicAssessmentsTable
                monitoringReport={this.props.monitoringReport}
                monitoringReportTopic={monitoringReportTopic}
                onClick={openModal}
                onAssess={this.props.onAssess}
                topicId={monitoringReportTopic.topic.id}
            />
        )
    }

    private getAssessmentSummaryProps(
        mrt: MonitoringReportTopicTableData,
        type: TopicAssessmentDesignOrEffectiveNessType
    ) {
        const hideLink = mrt.notApplicable || mrt.isRemoved
        const baseProps = { topicId: mrt.topic.id, assessmentType: type, hideLink }

        const isAssessed = !!mrt.assessments?.some(a => a.assessmentType === type)
        const assessments = this.getAssessmentsForSummary(mrt, type, isAssessed)

        if (!assessments?.length) {
            return {
                ...baseProps,
                documentsCount: 0,
                grade: TopicAssessmentGradeType.assessmentNotGiven,
            }
        }

        type TypeToSatifyTS = MonitoringReportTopicAssessmentTableData | Assessment
        assessments.sort(
            (a: TypeToSatifyTS, b: TypeToSatifyTS) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        )

        const grades: (MonitoringReportTopicAssessmentGrade | TopicAssessmentGradeType)[] = []
        assessments.forEach((a: TypeToSatifyTS) => a.grade && grades.push(a.grade))

        let documentsCount = 0
        assessments.forEach((a: TypeToSatifyTS) => (documentsCount += a.documentsCount))

        return {
            ...baseProps,
            showWarningBanner: isAssessed,
            documentsCount,
            grade: this.calculateGradeFromItemGrades(grades),
            assessedAt: assessments[0].updatedAt,
        }
    }

    private proceedToDesignAndEffectiveness(topicId: number) {
        const path = routes.customer(this.context.customer.slug).legalFramework.topic.designAndEffectiveness(topicId)

        const newWindow = window.open(path, '_blank', 'noopener, noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }

    private getAssessmentsForSummary(
        mrt: MonitoringReportTopicTableData,
        type: TopicAssessmentDesignOrEffectiveNessType,
        isAssessed: boolean
    ) {
        if (isAssessed) {
            return mrt.assessments?.filter(a => a.assessmentType === type)
        }

        const assessments: Assessment[] = []
        mrt.controlsAndNotes?.forEach(item => {
            if (!item.assessments?.length) {
                return
            }

            const filteredAssessments = item.assessments.filter(
                a => a.topicAssessmentDesignOrEffectiveNessType === type
            )

            return assessments.push(...filteredAssessments)
        })

        return assessments
    }

    private getTopicGrade(mrt: MonitoringReportTopicTableData, type: TopicAssessmentDesignOrEffectiveNessType) {
        if (!mrt.controlsAndNotes?.length) {
            return {
                initialGrade: TopicAssessmentGradeType.assessmentNotGiven,
                grade: TopicAssessmentGradeType.assessmentNotGiven,
            }
        }

        const grades: Grade[] = []
        mrt.controlsAndNotes.forEach(item => {
            const linkedItemType =
                item.__typename === 'TopicNoteType'
                    ? TopicAssessmentDeLinkedItemType.note
                    : TopicAssessmentDeLinkedItemType.controlmeasure

            const linkedItemId = item.id

            const itemAssessment = item.assessments?.find(a => a.topicAssessmentDesignOrEffectiveNessType === type)
            const mrtAssessment = mrt.assessments?.find(
                a =>
                    a.linkedItemId === linkedItemId &&
                    a.topicAssessmentLinkedItemType === linkedItemType &&
                    a.assessmentType === type
            )

            const grade = mrtAssessment?.grade || TopicAssessmentGradeType.assessmentNotGiven
            const initialGrade =
                mrtAssessment?.initialGrade || itemAssessment?.grade || TopicAssessmentGradeType.assessmentNotGiven

            grades.push({ initialGrade, grade })
        })

        return {
            initialGrade: this.calculateGradeFromItemGrades(grades.map(g => g.initialGrade)),
            grade: this.calculateGradeFromItemGrades(grades.map(g => g.grade)),
        }
    }

    private calculateGradeFromItemGrades(grades: (TopicAssessmentGradeType | MonitoringReportTopicAssessmentGrade)[]) {
        if (!grades?.length) {
            return TopicAssessmentGradeType.assessmentNotGiven
        }

        if (
            TopicAssessmentGradeType.assessmentNotSatisfies.valueOf() !==
                MonitoringReportTopicAssessmentGrade.assessmentNotSatisfies.valueOf() ||
            TopicAssessmentGradeType.assessmentAlmostSatisfies.valueOf() !==
                MonitoringReportTopicAssessmentGrade.assessmentAlmostSatisfies.valueOf() ||
            TopicAssessmentGradeType.assessmentSatisfies.valueOf() !==
                MonitoringReportTopicAssessmentGrade.assessmentSatisfies.valueOf()
        ) {
            throw new Error('enums no longer match, method needs to be updated to handle changed values')
        }

        if (grades.includes(TopicAssessmentGradeType.assessmentNotSatisfies)) {
            return TopicAssessmentGradeType.assessmentNotSatisfies
        }

        if (grades.includes(TopicAssessmentGradeType.assessmentAlmostSatisfies)) {
            return TopicAssessmentGradeType.assessmentAlmostSatisfies
        }

        if (grades.includes(TopicAssessmentGradeType.assessmentSatisfies)) {
            return TopicAssessmentGradeType.assessmentSatisfies
        }

        return TopicAssessmentGradeType.assessmentNotGiven
    }

    private renderTasks(mrt: MonitoringReportTopicTableData) {
        const buttonLabel = localize.translate(t => t.Customer.Task.TaskWidget.task, {
            smart_count: mrt.tasks?.length || 0,
        })

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} type={ButtonType.noStyling}>
                        {buttonLabel}
                    </Button>
                )}
                renderModal={closeModal => (
                    <MinimalLinkedTaskItemStatusQuery
                        filterForDepartment={mrt.department.id}
                        customerId={this.context.customer.id}
                        linkedItemId={mrt.topic.id}
                        linkedItemType={LinkedTaskItemType.topic}
                    >
                        {({ loading, data, refetch }) => {
                            if (loading) {
                                return <Spinner />
                            }

                            if (!data?.linkedTaskItemStatus) {
                                closeModal()
                                return <></>
                            }

                            return (
                                <ContextTasksModal
                                    amountOfOpenTasks={data.linkedTaskItemStatus.open || 0}
                                    amountOfCompletedTasks={data.linkedTaskItemStatus.closed || 0}
                                    linkedItem={{ ...mrt.topic, department: mrt.department }}
                                    requestClose={() => {
                                        this.props.onAssess?.()
                                        closeModal()
                                    }}
                                    possibleDepartments={[mrt.department]}
                                    onCompleteOrReopenTasks={() => refetch()}
                                />
                            )
                        }}
                    </MinimalLinkedTaskItemStatusQuery>
                )}
            />
        )
    }
}
