import React from 'react'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { localize } from '~/bootstrap'
import { Row } from '~/components/Core/Layout/Row'

interface Props {
    defaultValue?: boolean
    disabled?: boolean
    name?: string
}

export class EmployeeNewTaskNotificationEmail extends React.Component<React.PropsWithChildren<Props>> {
    public render() {
        const { disabled, name, defaultValue } = this.props

        return (
            <Row>
                <Form.Radio
                    name={name || 'newTaskNotificationEmail'}
                    label={localize.translate(t => t.User.newTaskNotificationEmailSetting['true'])}
                    defaultChecked={defaultValue === true}
                    value={true}
                    disabled={disabled}
                />
                <Form.Radio
                    name={name || 'newTaskNotificationEmail'}
                    label={localize.translate(t => t.User.newTaskNotificationEmailSetting['false'])}
                    value={false}
                    disabled={disabled}
                    defaultChecked={defaultValue === false}
                />
            </Row>
        )
    }
}
