import React from 'react'
import './RiskTopicSelect.scss'

import { BEM, ClassValue } from '~/services/BEMService'
import gql from 'graphql-tag'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    placeholder?: string
    isFilter?: boolean
    onChange: (option: SelectOption | null) => void
    defaultValue?: SelectOption[]
}

interface State {}

const LIST_TOPICS_QUERY = gql`
    query topics($skip: Int, $take: Int, $filters: TopicFilters, $sort: TopicSort) {
        topics(filters: $filters, skip: $skip, take: $take, sort: $sort) {
            hasNextPage
            nodes {
                id
                name
                theme {
                    id
                    name
                }
            }
        }
    }
`

interface Response {
    id: number
    name: string
    theme: {
        id: number
        name: string
    }
}

interface Grouped {
    label: string
    options: RiskTopicSelectOption[]
}

interface RiskTopicSelectOption {
    value: number
    label: string
    group: string
}

export class RiskTopicSelect extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('RiskTopicSelect')

    public render() {
        const { className, placeholder, isFilter, onChange, defaultValue } = this.props

        return (
            <PaginatableQuery<Response>
                query={LIST_TOPICS_QUERY}
                variables={{
                    filters: {
                        departmentId: this.context.activeDepartmentId,
                        onlyUsedInAssessments: true,
                    },
                    sort: {
                        theme: 'ASC',
                    },
                }}
            >
                {({ data, loading, loadingMore, fetchMore }) => {
                    if (!data) {
                        return null
                    }

                    const groupedByTheme = this.groupTopicsByTheme(data.nodes)

                    return (
                        <div className={this.bem.getClassName(className)}>
                            <Form.Select
                                name={name || 'topic'}
                                placeholder={placeholder}
                                searchable={true}
                                options={groupedByTheme}
                                onEndReached={fetchMore}
                                clearable={true}
                                loading={loading || loadingMore}
                                isFilter={isFilter}
                                onChange={(option: SelectOption) => {
                                    onChange(option)
                                }}
                                defaultValue={defaultValue}
                            />
                        </div>
                    )
                }}
            </PaginatableQuery>
        )
    }

    private groupTopicsByTheme(data: Response[]) {
        const { defaultValue } = this.props

        const grouped: Grouped[] = []

        for (const topic of data) {
            let group = grouped.find(group => group.label === topic.theme.name)

            if (!group) {
                group = {
                    label: topic.theme.name,
                    options: [],
                }
                grouped.push(group)
            }

            group.options.push({ value: topic.id, label: topic.name, group: topic.theme.name })
        }

        if (!defaultValue || !defaultValue[0]) {
            return this.translateLabels(grouped)
        }

        const defaultRiskValue = defaultValue[0] as RiskTopicSelectOption

        const defaultGroupData = grouped.find(group => group.label === defaultRiskValue.group)

        // If the group isn't in the data the option shouldn't be there either, so add a new group with the default option to the data
        if (!defaultGroupData) {
            return this.translateLabels([
                ...grouped,
                {
                    label: defaultRiskValue.group,
                    options: [defaultRiskValue],
                },
            ])
        }

        const defaultOptionInDefaultGroup = defaultGroupData.options.find(
            option => option.value === defaultRiskValue.value
        )

        // If the default value is already in the group options just return the existing options
        if (defaultOptionInDefaultGroup) {
            return this.translateLabels(grouped)
        }

        // If not add the default value to the correct
        const defaultGroupIndex = grouped.findIndex(group => group.label === defaultGroupData.label)
        grouped[defaultGroupIndex].options = [...grouped[defaultGroupIndex].options, defaultRiskValue]

        return this.translateLabels(grouped)
    }

    private translateLabels(object: Grouped[]) {
        if (!object || !object.length) {
            return object
        }

        return object.map(o => {
            return {
                label: this.translateRiskTypeLabelByName(o.label),
                options: o.options,
            }
        })
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private translateRiskTypeLabelByName(label: string) {
        switch (label) {
            case 'Continuiteitsrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Continuiteitsrisico)
            }
            case 'Integriteit personeel': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Integriteitpersoneel)
            }
            case 'IT risico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.ITrisico)
            }
            case 'Juridisch risico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Juridischrisico)
            }
            case 'Klantintegriteit': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Klantintegriteit)
            }
            case 'Kredietrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Kredietrisico)
            }
            case 'Liquiditeitsrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Liquiditeitsrisico)
            }
            case 'Marktintegriteit': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Marktintegriteit)
            }
            case 'Marktrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Marktrisico)
            }
            case 'Operationeel risico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Operationeelrisico)
            }
            case 'Solvabiliteitsrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Solvabiliteitsrisico)
            }
            case 'Strategisch risico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Strategischrisico)
            }
            case 'Uitbestedingsrisico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Uitbestedingsrisico)
            }
            case 'Verzekeringstechnisch risico': {
                return localize.translate(t => t.Customer.Compliance.Risks.riskTypesEntries.Verzekeringstechnischrisico)
            }
            default: {
                return label
            }
        }
    }
}
