import React from 'react'
import { MonitoringReportsDetailContainer } from '~/components/Domain/Monitoring/MonitoringReportsDetail/MonitoringReportDetailContainer'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface State {}

class MonitoringReportDetailViewComponent extends React.Component<React.PropsWithChildren<Props>, State> {
    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        return <MonitoringReportsDetailContainer monitoringReportId={parseInt(id, 10)} />
    }
}

export const MonitoringReportDetailView = withRouter(MonitoringReportDetailViewComponent)
