import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TaskControlOverviewView } from './TaskControlOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { permissions } from '~/bootstrap'
import { EmployeePermissionEnum } from '~/generated/graphql'
import { InboxControlOverviewView } from './InboxControlOverviewView'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route, Navigate } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}
interface State {}

class ControlViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const hasNoPermission = !permissions.hasPermission(EmployeePermissionEnum.canSetControlOperations)
        if (hasNoPermission) {
            return (
                <Routes>
                    <Route element={<AppNotFoundView />} />
                </Routes>
            )
        }

        const basePath = newRoutes.customer(this.context.customer.slug).control
        const hasTaskControlAccess = permissions.canAccessTaskControlPage()
        const hasInboxControlAccess = permissions.canAccessInboxControlPage()

        return (
            <Routes>
                <Route path="*" element={<Navigate replace to={basePath.taskControl.index} />} />
                <Route
                    path={basePath.taskControl.view()}
                    element={hasTaskControlAccess ? <TaskControlOverviewView /> : <AppNotFoundView />}
                />
                <Route
                    path={basePath.inboxControl}
                    element={hasInboxControlAccess ? <InboxControlOverviewView /> : <AppNotFoundView />}
                />
                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const ControlView = withRouter(ControlViewComponent)
