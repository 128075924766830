import './ResponsiveGrid.scss'

import React from 'react'
import { Layout, Layouts, Responsive, ResponsiveProps, WidthProvider } from 'react-grid-layout'
import { BEM, ClassValue } from '~/services/BEMService'

const ResponsiveGridLayout = WidthProvider<ResponsiveProps>(Responsive)

interface Props {
    items: ResponsiveGridLayoutItem[]
    rowHeight: number
    breakpoints: Record<BreakpointEnum, number> // i.e. { lg: 1600, md: 1024, sm: 768, xs: 480 }
    columns: Record<BreakpointEnum, number> // i.e. { lg: 4, md: 3, sm: 2, xs: 1 }
    maxRowCount?: number
    isDraggable?: boolean
    className?: ClassValue
    onLayoutChange?: (currentLayout: LayoutInfo[], currentBreakpoint: BreakpointEnum | null) => void
    onDrag?: ({ starting }: { starting: boolean }) => void
}

export interface ResponsiveGridLayoutItem {
    id: number
    x: Record<BreakpointEnum, number>
    y: Record<BreakpointEnum, number>
    w: number
    h: number
    render: () => React.ReactNode
}

export enum BreakpointEnum {
    lg = 'lg',
    md = 'md',
    sm = 'sm',
    xs = 'xs',
}

export interface LayoutInfo {
    id: number
    x: number
    y: number
}

interface State {
    layouts: Layouts
    currentBreakpoint: BreakpointEnum | null
}

export class ResponsiveGrid extends React.Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        layouts: this.generateLayout(),
        currentBreakpoint: null,
    }

    private bem = new BEM('ResponsiveGrid')
    private gridContainerRef = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        this.setState({ currentBreakpoint: this.getCurrentBreakpoint() })
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const dragStateHasChanged = prevProps.isDraggable !== this.props.isDraggable
        const itemCountsHaveChanged = prevProps.items.length !== this.props.items.length

        const currentBreakpoint = this.getCurrentBreakpoint()
        const breakpointHasChanged = currentBreakpoint !== prevState.currentBreakpoint

        if (dragStateHasChanged || itemCountsHaveChanged || breakpointHasChanged) {
            this.setState({ layouts: this.generateLayout() })
        }
    }

    public render() {
        const { className, rowHeight, breakpoints, columns, isDraggable, onDrag } = this.props

        return (
            <div ref={this.gridContainerRef} className={this.bem.getClassName(className)}>
                <ResponsiveGridLayout
                    rowHeight={rowHeight}
                    breakpoints={breakpoints}
                    cols={columns}
                    layouts={this.state.layouts}
                    useCSSTransforms={true}
                    compactType="vertical"
                    onLayoutChange={this.handleLayoutChange}
                    onBreakpointChange={this.handleBreakpointChange}
                    isDraggable={!!isDraggable}
                    margin={[0, 0]} // do not change, breaks rowHeight calculation ((height - 1) * margin)
                    onDragStart={() => (onDrag ? onDrag({ starting: true }) : undefined)}
                    onDragStop={() => (onDrag ? onDrag({ starting: false }) : undefined)}
                >
                    {this.generateDOM()}
                </ResponsiveGridLayout>
            </div>
        )
    }

    private generateLayout() {
        const { items, breakpoints } = this.props

        const breakpointKeys = Object.keys(breakpoints)
        const layouts: Layouts = {}

        for (const breakpointKey of breakpointKeys) {
            layouts[breakpointKey] = items.map(({ x, y, w, h, id }) => ({
                x: x[breakpointKey],
                y: y[breakpointKey],
                w,
                h: h + 24, // to compensate for the 24px container padding
                i: id.toString(), // package uses string ids
            }))
        }

        return layouts
    }

    private getCurrentBreakpoint(): BreakpointEnum | null {
        const containerWidth = this.gridContainerRef.current?.clientWidth

        if (containerWidth === undefined) {
            return null
        }

        const { breakpoints } = this.props

        const sortedBreakpoints = Object.entries(breakpoints).sort(([_, v1], [__, v2]) => v1 - v2)
        if (!sortedBreakpoints.length) {
            return null
        }

        const currentBreakpoint = this.getHighestBreakpoint(sortedBreakpoints, containerWidth)

        return currentBreakpoint
    }

    private getHighestBreakpoint(sortedBreakpoints: [string, number][], containerWidth: number) {
        let currentBreakpoint = sortedBreakpoints[0][0]

        for (const [breakpointKey, breakpointValue] of sortedBreakpoints) {
            const containerIsSmallerOrEqual = containerWidth <= breakpointValue
            if (containerIsSmallerOrEqual) {
                break
            }

            currentBreakpoint = breakpointKey
        }

        return currentBreakpoint as BreakpointEnum
    }

    private handleLayoutChange = (currentLayout: Layout[]) => {
        const { onLayoutChange } = this.props
        const { currentBreakpoint } = this.state

        const formattedLayout: LayoutInfo[] = this.formatLayoutForOnChange(currentLayout)

        if (onLayoutChange) {
            onLayoutChange(formattedLayout, currentBreakpoint)
        }
    }

    private handleBreakpointChange = (newBreakpoint: string) => {
        this.setState({ currentBreakpoint: newBreakpoint as BreakpointEnum })
    }

    private generateDOM() {
        const { items } = this.props

        return items.map(({ id, render }) => (
            <div key={id}>
                <div className={this.bem.getElement('animation-container')}>{render()}</div>
            </div>
        ))
    }

    private formatLayoutForOnChange(layout: Layout[]) {
        return layout.map(({ i, x, y }) => ({
            id: parseInt(i, 10), // package returns string ids
            x,
            y,
        }))
    }
}
