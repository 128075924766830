import React from 'react'
import { Section } from '~/components/Core/Layout/Section'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { AddRiskTypeModal } from '~/components/Domain/Compliance/Risks/AddRiskTypeModal'
import gql from 'graphql-tag'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { Table, ColumnOptions } from '~/components/Core/DataDisplay/Table/Table'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { EditRiskTypeModal } from '~/components/Domain/Compliance/Risks/EditRiskTypeModal'
import { Guard } from '~/components/Core/Guard/Guard'
import { localize, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

export const LIST_RISK_TYPES = gql`
    query riskTypes($skip: Int, $take: Int) @connection(key: "risktypes") {
        riskTypes(skip: $skip, take: $take) {
            hasNextPage
            nodes {
                id
                name
            }
        }
    }
`

export class RiskTypesTable extends React.Component {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <InfiniteScrollQuery query={LIST_RISK_TYPES}>
                {({ data, loading, loadingMore, refetch }) => {
                    const columns: ColumnOptions[] = [{ field: 'name', headerLabel: 'Name' }]
                    const rows = data
                        ? data.nodes.map(node => ({
                              id: node.id,
                              columns: {
                                  name: <Paragraph>{node.name}</Paragraph>,
                              },
                          }))
                        : []

                    return (
                        <Section
                            style={{ maxWidth: 744 }}
                            title={localize.translate(t => t.Customer.Compliance.Risks.riskTypes)}
                            renderAction={() => {
                                return (
                                    <Guard
                                        condition={permissions.canChangeComplianceSettings(
                                            this.context.activeDepartmentId
                                        )}
                                    >
                                        <ModalManager
                                            render={openModal => (
                                                <Button
                                                    type={ButtonType.actionLink}
                                                    icon={IconType.addCircle}
                                                    onClick={openModal}
                                                >
                                                    {localize.translate(t => t.Customer.Compliance.Risks.newRiskType)}
                                                </Button>
                                            )}
                                            renderModal={closeModal => (
                                                <AddRiskTypeModal refetch={refetch} requestClose={closeModal} />
                                            )}
                                        />
                                    </Guard>
                                )
                            }}
                        >
                            <Table
                                loading={loading}
                                loadingMore={loadingMore}
                                columns={columns}
                                data={rows}
                                rowAction={row => {
                                    return (
                                        <Guard
                                            condition={permissions.canChangeComplianceSettings(
                                                this.context.activeDepartmentId
                                            )}
                                        >
                                            <ModalManager
                                                render={openModal => (
                                                    <Button
                                                        type={ButtonType.subtle}
                                                        icon={IconType.edit}
                                                        onClick={openModal}
                                                    />
                                                )}
                                                renderModal={closeModal => (
                                                    <EditRiskTypeModal
                                                        riskTypeId={row.row.id}
                                                        requestClose={closeModal}
                                                    />
                                                )}
                                            />
                                        </Guard>
                                    )
                                }}
                            />
                        </Section>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }
}
