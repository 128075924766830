import React from 'react'
import { ClassValue } from '~/services/BEMService'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { TaskSummaryPerMonthType, TasksSummaryPerMonthDocument, LinkedTaskItem, TaskType } from '~/generated/graphql'
import { TaskControlFilters } from '~/views/Customer/Control/TaskControlOverviewView'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { TaskControlSectionTitle } from './TaskControlSectionTitle/TaskControlSectionTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { ZeroInbox } from '~/components/Chrome/ZeroInbox/ZeroInbox'
import { localize } from '~/bootstrap'
import { TaskControlTable } from './TaskControlTable/TaskControlTable'
import { ControlSummaryList } from '../ControlSummaryList'

interface Props {
    className?: ClassValue
    paramState: TaskControlFilters
    isFilterActive?: boolean
}

interface State {
    selectedTasks: TaskType[]
}

export class TaskSummaryList extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        selectedTasks: [],
    }

    private loc = localize.namespaceTranslate(t => t.Customer.TaskControl.TaskControlOverview.summary)

    public render() {
        const { className, isFilterActive, paramState } = this.props

        return (
            <InfiniteScrollQuery<TaskSummaryPerMonthType>
                variables={{
                    filters: {
                        status: paramState.status || undefined,
                        linkType: this.getLinkTypeValue(),
                        isGenericType: paramState.isGenericType || undefined,
                    },
                }}
                query={TasksSummaryPerMonthDocument}
            >
                {({ data, loading, loadingMore, refetch }) => {
                    if (loading) {
                        return <Spinner />
                    }

                    const monthSummary = data ? data.nodes : []

                    if (!monthSummary.length && isFilterActive) {
                        return <NoResults />
                    }

                    if (!monthSummary.length && !isFilterActive) {
                        return (
                            <ZeroInbox
                                title={this.loc(t => t.zeroState.title)}
                                label={this.loc(t => t.zeroState.label)}
                            />
                        )
                    }

                    const { selectedTasks } = this.state

                    return (
                        <ControlSummaryList
                            className={className}
                            summaries={this.getSummaryList(monthSummary, refetch)}
                            controlSummaryType="task"
                            loadingMore={loadingMore}
                            selectedItems={selectedTasks}
                            refetch={refetch}
                            onClearSelection={() => this.setState({ selectedTasks: [] })}
                        />
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private getSummaryList(monthSummary: TaskSummaryPerMonthType[], refetch: () => void) {
        const { paramState } = this.props
        const { selectedTasks } = this.state

        const firstSummaryWithTasks = monthSummary.find(m => !!m.openTaskCount || !!m.completedTaskCount)

        return monthSummary.map((summary, i) => ({
            sectionTitle: <TaskControlSectionTitle taskSummary={summary} />,
            children: (
                <TaskControlTable
                    totalTaskCount={summary.openTaskCount + summary.completedTaskCount}
                    year={summary.year}
                    month={summary.month}
                    paramState={paramState}
                    refetch={refetch}
                    bulkActionIds={selectedTasks.map(task => task.id)}
                    isCheckboxActive={!!selectedTasks.length}
                    onCheck={this.onCheck}
                    hideCheckboxAnimation={summary.id !== firstSummaryWithTasks?.id}
                />
            ),
        }))
    }

    private getLinkTypeValue() {
        const { paramState } = this.props
        const linkTypeValue = paramState.linkType?.value

        if (!linkTypeValue || linkTypeValue === 'all' || linkTypeValue === 'generic') {
            return undefined
        }

        return linkTypeValue as LinkedTaskItem
    }

    private onCheck = (task: TaskType, checked: boolean) => {
        const { selectedTasks } = this.state

        if (checked) {
            this.setState({ selectedTasks: [...selectedTasks, task] })
            return
        }

        const filteredTasks = selectedTasks.filter(selectedTask => selectedTask.id !== task.id)
        this.setState({ selectedTasks: filteredTasks })
    }
}
