import './EnvironmentBanner.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

interface State {
    version: boolean
}

export class EnvironmentBanner extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        version: false,
    }

    private bem = new BEM('EnvironmentBanner')

    public render() {
        const { className } = this.props
        const { version } = this.state

        return (
            <div
                className={this.bem.getClassName(className)}
                onClick={() => this.setState(state => ({ version: !state.version }))}
            >
                {!version && `${window.ENVIRONMENT.NAME} environment`}
                {version && `${window.ENVIRONMENT.DEPLOY_VERSION}`}
            </div>
        )
    }
}
