import './ReviewTasksLabel.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Task } from '~/views/Customer/Tasks/TasksQuery/TaskQuery'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { BEM } from '~/services/BEMService'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ViewTaskModalContainer } from './ViewTaskModalContainer'
import { ReviewSubTasksTable } from './ReviewSubTasksTable'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { AlertType } from '~/generated/graphql'
import { LinkedInboxItemsTable } from '../ControlViews/InboxControl/LinkedInboxItemsTable'

interface Props {
    linkedItems: Task[] | AlertType[]
    onChange: () => void
    taskId: number
    iconOnly?: boolean
}

export class ReviewTasksLabel extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('ReviewTasksLabel')
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.ReviewTasksLabel)

    public render() {
        const { taskId, onChange } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip
                        className={this.bem.getElement('tooltip')}
                        content={this.renderTooltipContent()}
                        distance={7}
                    >
                        <Button
                            type={ButtonType.noStyling}
                            className={this.bem.getElement('task-name')}
                            onClick={openModal}
                        >
                            {this.renderIconLabel()}
                        </Button>
                    </Tooltip>
                )}
                renderModal={closeModal => (
                    <ViewTaskModalContainer
                        taskId={taskId}
                        requestClose={closeModal}
                        onCompleteOrReopen={onChange}
                        onChangeTask={onChange}
                        onArchiveTask={onChange}
                    />
                )}
            />
        )
    }

    private renderTooltipContent() {
        const { linkedItems } = this.props

        if (linkedItems.length && 'impactLevel' in linkedItems[0]) {
            return <LinkedInboxItemsTable linkedAlerts={linkedItems as AlertType[]} isForTooltip={true} />
        }

        return <ReviewSubTasksTable linkedTasks={linkedItems as Task[]} isForTooltip={true} />
    }

    private renderIconLabel() {
        const { iconOnly, linkedItems } = this.props

        if (iconOnly) {
            const linkedTaskCount = linkedItems.length
            const label =
                linkedItems.length && 'impactLevel' in linkedItems[0]
                    ? localize.translate(t => t.Entities.Inbox, linkedTaskCount)
                    : localize.translate(t => t.Entities.Task, linkedTaskCount)

            return (
                <Column noSpacing={true}>
                    <Paragraph center={true} small={true} bold={true}>
                        {linkedTaskCount}
                    </Paragraph>
                    <Paragraph extraSmall={true} bold={true}>
                        {label}
                    </Paragraph>
                </Column>
            )
        }

        let iconType = IconType.taskCheckmark
        let labelText =
            linkedItems.length && 'impactLevel' in linkedItems[0]
                ? this.loc(t => t.linkedTasks, { linkedItemCount: linkedItems.length })
                : this.loc(t => t.linkedAlerts, { linkedItemCount: linkedItems.length })

        if (linkedItems.length === 1) {
            labelText = linkedItems[0].name

            if ('completedAt' in linkedItems[0] && !linkedItems[0].completedAt) {
                iconType = IconType.taskOpen
            }
        }

        return (
            <IconLabel className={this.bem.getElement('task-icon')} iconType={iconType}>
                {labelText}
            </IconLabel>
        )
    }
}
