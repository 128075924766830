import './ControlItem.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { BEM, ClassValue } from '~/services/BEMService'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { IconType } from '~/components/Core/Icon/IconType'
import { UserProfileIcon } from '~/components/Core/DataDisplay/UserProfileIcon/UserProfileIcon'
import { GetControlQueryType } from './ControlsContainer'
import { ControlLabel } from './ControlLabel'

interface Props {
    className?: ClassValue
    control: GetControlQueryType
    isFirstItem?: boolean
    isCheckboxActive?: boolean
    checked?: boolean
    handleOnCardCheck?: (checked: boolean) => void
    indent?: 1 | 2
    isOpen?: boolean
}

export class ControlItem extends React.Component<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ControlItem)
    private bem = new BEM('ControlItem', () => ({
        [`hasIndent-${this.props.indent}`]: !!this.props.indent,
    }))

    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        const { control, className, handleOnCardCheck, isCheckboxActive, checked, isFirstItem, indent, isOpen } =
            this.props
        const { id, createdAt, archivedAt, archivedBy, openTasks, amountOfLinkedTopics } = control

        const columnClassName = this.bem.getElement('column-container')

        return (
            <Card
                isCheckboxActive={isCheckboxActive}
                isFirstCardInList={isFirstItem}
                className={this.bem.getClassName(className)}
                checked={checked}
                onCheckChange={isOpen ? handleOnCardCheck : undefined}
            >
                <Row>
                    <Column className={this.bem.getElement('title-container')}>
                        <Row smallSpacing>
                            <ComponentTitle
                                title={<ControlLabel noLink={true} control={control} indentLevel={indent} />}
                                to={id.toString()}
                                className={this.bem.getElement('title')}
                            />
                        </Row>
                        {this.renderDate(createdAt)}
                    </Column>
                    <Column className={columnClassName}>
                        <Paragraph emphasis>
                            {this.loc(t => t.topic, { smart_count: amountOfLinkedTopics || 0 })}
                        </Paragraph>
                    </Column>
                    <Column className={columnClassName}>
                        <Paragraph>{this.loc(t => t.task, { smart_count: openTasks?.length || 0 })}</Paragraph>
                    </Column>
                    {!!archivedAt && (
                        <Column className={this.bem.getElement('info-icon')}>
                            {this.renderArchivedInfoIcon(archivedAt, archivedBy)}
                        </Column>
                    )}
                </Row>
            </Card>
        )
    }

    private renderDate(date: Date) {
        return (
            <Paragraph subtle className={this.bem.getElement('date-container')}>
                <DateFormat date={new Date(date)} readable includeTime={false} noWeekday />
            </Paragraph>
        )
    }

    private renderArchivedInfoIcon(archivedAt: Date, archivedBy?: GetControlQueryType['archivedBy']) {
        return (
            <Row alignRight fullWidth>
                <ActionButton
                    icon={IconType.info}
                    tertiary
                    tooltipContent={
                        <Row smallSpacing>
                            {archivedBy && (
                                <>
                                    <Paragraph>{this.loc(t => t.archivedBy)}</Paragraph>
                                    <UserProfileIcon
                                        small
                                        fullName={archivedBy.user.profile.fullName}
                                        uri={archivedBy.user.profile.avatar}
                                    />
                                </>
                            )}
                            <Paragraph subtle>
                                <DateFormat date={new Date(archivedAt)} readable includeTime noWeekday />
                            </Paragraph>
                        </Row>
                    }
                />
            </Row>
        )
    }
}
