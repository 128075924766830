import React from 'react'

import { AssessmentRisksAndControlsQuery } from './AssessmentRisksAndControlsQuery'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { LinkedRisksAndControls } from '../LinkedCompliance/LinkedRisksAndControls'

interface Props {
    assessmentId: number
    renderEmptyState: () => React.ReactNode
}

interface State {}

export class AssessmentLinkedItems extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public render() {
        const { assessmentId, renderEmptyState } = this.props

        return (
            <AssessmentRisksAndControlsQuery assessmentId={assessmentId}>
                {(assessment, loading) => {
                    if (loading || !assessment) {
                        return <Spinner delayed={true} />
                    }

                    if (
                        assessment.risks &&
                        assessment.risks.length === 0 &&
                        assessment.controls &&
                        assessment.controls.length === 0
                    ) {
                        return renderEmptyState()
                    }

                    const assessments = {
                        name: assessment.name,
                        risks: assessment.risks,
                        controls: assessment.controls,
                    }

                    return <LinkedRisksAndControls assessment={assessments} />
                }}
            </AssessmentRisksAndControlsQuery>
        )
    }
}
