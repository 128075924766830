import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import './UserProfileLabel.scss'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    content: string[]
    small?: boolean
}

export class UserProfileLabel extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('UserProfileLabel', () => ({
        'is-small': this.props.small,
    }))

    public render() {
        const { className, content } = this.props
        const formattedNames = this.getFormattedNames()
        return (
            <div className={this.bem.getClassName(className)}>
                <Tooltip content={formattedNames}>
                    <div className={this.bem.getElement('avatar-container')}>
                        {/* tslint:disable-next-line: jsx-use-translation-function */}
                        <span>+{content.length}</span>
                    </div>
                </Tooltip>
            </div>
        )
    }

    private getFormattedNames() {
        const { content } = this.props

        const allNamesExceptLast = content.slice(0, -1)
        const initialNamesCommaSeparated = allNamesExceptLast.map(name => `${name}, `)
        const lastName = content.slice(content.length - 1)
        const allNames = initialNamesCommaSeparated.concat(lastName)

        return allNames
    }
}
