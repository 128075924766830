import './MobileConsultationItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { localize } from '~/bootstrap'
import { TextLink } from '~/components/Core/Text/TextLink'
import { CustomerFramework } from '~/graphql/types/CustomerFramework'
import { CustomerFrameworkIconList } from '~/components/Domain/CustomerFramework/CustomerFrameworkIconList'

interface Props {
    className?: ClassValue
    title: string
    source: string
    startsAt: string | null
    endsAt: string | null
    profiles: CustomerFramework[]
    to: string
}

interface State {}

export class MobileConsultationItem extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('MobileConsultationItem')

    public render() {
        const { title, source, startsAt, endsAt, profiles, to, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <h3 className={this.bem.getElement('title')}>
                    <TextLink to={to}>{title}</TextLink>
                </h3>
                <p>{source}</p>
                <div>
                    {startsAt && (
                        <time className={this.bem.getElement('start-date')}>
                            {localize.translate(t => t.Generic.beginDateAt, {
                                date: localize.dateFormat(new Date(startsAt), { noWeekday: true, readable: true }),
                            })}
                        </time>
                    )}
                    {endsAt && (
                        <time className={this.bem.getElement('end-date')}>
                            {localize.translate(t => t.Generic.endDateAt, {
                                date: localize.dateFormat(new Date(endsAt), { noWeekday: true, readable: true }),
                            })}
                        </time>
                    )}
                </div>
                {profiles && (
                    <CustomerFrameworkIconList
                        customerFrameworks={profiles}
                        className={this.bem.getElement('profile-list')}
                    />
                )}
            </div>
        )
    }
}
