import './ControlTopicsTable.scss'
import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ColumnOptions, RowData, Table } from '~/components/Core/DataDisplay/Table/Table'
import { Column } from '~/components/Core/Layout/Column'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { ControlTopicType, ControlTopics } from './ControlTopics'
import { FetchMoreButton } from '~/components/Core/Pagination/FetchMoreButton'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicPreviewModal } from '~/components/Domain/Topic/TopicPreviewModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Row } from '~/components/Core/Layout/Row'
import {
    ControlType,
    TopicAssessmentDeLinkedItemType,
    TopicAssessmentDesignOrEffectiveNessType,
} from '~/generated/graphql'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { TopicDesignAndEffectivenessLogsModal } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/modals/TopicDesignAndEffectivenessLogsModal'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { RemoveTopicControlModal } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/modals/RemoveTopicControlModal'
import { BEM } from '~/services/BEMService'
import { Guard } from '~/components/Core/Guard/Guard'
import { SelectControlTopicsModal } from '../SelectControlTopicsModal/SelectControlTopicsModal'
import { TopicAssessmentIconContainer } from '~/components/Domain/Topic/TopicDesignAndEffectiveness/TopicAssessmentIconContainer'
import { ControlLabel } from '../ControlLabel'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { routes } from '~/views/routes'

interface Props {
    control: Pick<ControlType, 'id' | 'name' | 'type'>
}

export class ControlTopicsTable extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public refetch?: () => void

    private bem = new BEM('ControlTopicsTable')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.ControlTopicsTable)
    private columns: ColumnOptions[] = [
        { field: 'name', headerLabel: this.loc(t => t.headers.topic) },
        { field: 'articles', headerLabel: this.loc(t => t.headers.articles) },
        { field: 'design', headerLabel: this.loc(t => t.headers.design) },
        { field: 'effectiveness', headerLabel: this.loc(t => t.headers.effectiveness) },
        { field: 'log', headerLabel: this.loc(t => t.headers.log) },
        { field: 'remove', headerLabel: '' },
    ]

    public render() {
        const { id } = this.props.control

        return (
            <Column className={this.bem.getClassName()}>
                <Row spaceBetween>
                    <SectionTitle>{this.loc(t => t.title)}</SectionTitle>
                    <Guard condition={permissions.canEditTopicDnE(this.context.activeDepartmentId)}>
                        {this.renderSelectTopicsButton()}
                    </Guard>
                </Row>
                <ControlTopics controlId={id}>
                    {({ data, loading, loadingMore, canFetchMore, fetchMore, refetch }) => {
                        this.refetch = refetch

                        return (
                            <Column smallSpacing>
                                <Table
                                    columns={this.columns}
                                    hideColumnsWhenEmpty
                                    loading={loading}
                                    loadingMore={loadingMore}
                                    data={this.getTableData(data?.nodes || [])}
                                />
                                {!loading &&
                                    !!data?.nodes.length &&
                                    canFetchMore &&
                                    this.renderFetchMoreButton(
                                        fetchMore,
                                        data.totalCount,
                                        data.nodes.length,
                                        loadingMore
                                    )}
                            </Column>
                        )
                    }}
                </ControlTopics>
            </Column>
        )
    }

    private renderSelectTopicsButton() {
        return (
            <ModalManager
                render={openModal => (
                    <Button type={ButtonType.actionLink} icon={IconType.addCircle} onClick={openModal}>
                        {this.loc(t => t.selectTopics)}
                    </Button>
                )}
                renderModal={closeModal => (
                    <SelectControlTopicsModal
                        onSubmit={this.refetch}
                        requestClose={closeModal}
                        controlId={this.props.control.id}
                    />
                )}
            />
        )
    }

    private getTableData(controlTopics: ControlTopicType[]): RowData[] {
        const notAllowedToRemove = !permissions.canEditTopicDnE(this.context.activeDepartmentId)

        return controlTopics.map(c => {
            return {
                id: c.id,
                columns: {
                    name: this.renderTopicName(c.topic),
                    articles: this.renderArticleCount(c),
                    design: this.renderAssessmentIcon(c, TopicAssessmentDesignOrEffectiveNessType.design),
                    effectiveness: this.renderAssessmentIcon(c, TopicAssessmentDesignOrEffectiveNessType.effectiveness),
                    log: this.renderLogIcon(c.id),
                    remove: notAllowedToRemove ? null : this.renderRemoveButton(c.topic.id),
                },
            }
        })
    }

    private renderFetchMoreButton(fetchMore: () => void, totalCount: number, count: number, loadingMore: boolean) {
        const nextItemsCount = this.getLoadMoreCount(count, totalCount)
        const label = this.loc(t => t.showMore, { nextItemsCount, totalCount })

        return <FetchMoreButton label={label} fetchMore={fetchMore} loadingMore={loadingMore} />
    }

    private renderTopicName({ id, name }: ControlTopicType['topic']) {
        return (
            <Row noSpacing>
                <TableLink to={routes.customer(this.context.customer.slug).legalFramework.topic.view(id)} external>
                    {name}
                </TableLink>
                <TopicPreviewModal topicId={id}>
                    {open => (
                        <Button type={ButtonType.noStyling} onClick={open}>
                            <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                        </Button>
                    )}
                </TopicPreviewModal>
            </Row>
        )
    }

    private renderArticleCount(controlTopic: ControlTopicType) {
        const totalArticleCount = controlTopic.topic.linkedLawArticles.length
        const linkedArticleCount = controlTopic.articles.length

        return (
            <Paragraph emphasis>
                {linkedArticleCount}/{totalArticleCount}
            </Paragraph>
        )
    }

    private renderAssessmentIcon(controlTopic: ControlTopicType, dneType: TopicAssessmentDesignOrEffectiveNessType) {
        const assessment = controlTopic.assessments?.find(a => a.topicAssessmentDesignOrEffectiveNessType === dneType)
        const hasLinkedControls = !!(
            controlTopic.linkedChildTopicControlMeasures?.length ||
            controlTopic.linkedParentTopicControlMeasures?.length ||
            controlTopic.linkedNestedChildTopicControlMeasures?.length ||
            controlTopic.linkedNestedParentTopicControlMeasures?.length
        )

        return (
            <Row>
                <TopicAssessmentIconContainer
                    linkedItem={{ ...controlTopic, control: this.props.control }}
                    linkedItemLabel={<ControlLabel control={this.props.control} />}
                    linkedItemType={TopicAssessmentDeLinkedItemType.controlmeasure}
                    topicId={controlTopic.topic.id}
                    assessmentType={dneType}
                    assessment={assessment}
                    hasLinkedControls={hasLinkedControls}
                    onChange={this.refetch}
                />
            </Row>
        )
    }

    private renderLogIcon(controlTopicId: number) {
        return (
            <ModalManager
                render={openModal => (
                    <Button
                        icon={IconType.clock}
                        onClick={openModal}
                        type={ButtonType.subtle}
                        className={this.bem.getElement('log-icon')}
                    />
                )}
                renderModal={closeModal => (
                    <TopicDesignAndEffectivenessLogsModal
                        linkedItemId={controlTopicId}
                        linkedItemType={TopicAssessmentDeLinkedItemType.controlmeasure}
                        requestClose={closeModal}
                    />
                )}
            />
        )
    }

    private renderRemoveButton(topicId: number) {
        const { id } = this.props.control

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip message={this.loc(t => t.removeControl)}>
                        <Button
                            type={ButtonType.actionLink}
                            icon={IconType.close}
                            onClick={openModal}
                            className={this.bem.getElement('remove-button')}
                        />
                    </Tooltip>
                )}
                renderModal={closeModal => (
                    <RemoveTopicControlModal
                        onRemove={this.refetch}
                        requestClose={closeModal}
                        topicId={topicId}
                        controlId={id}
                    />
                )}
            />
        )
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 8) {
            return 8
        }

        return leftOverNodes
    }
}
