import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import { ClassValue } from '~/services/BEMService'
import { ControlDocument } from '../Controls/ControlDetail/ControlQuery'

interface Props {
    className?: ClassValue
    document?: ControlDocument
    onChange?: (repeats: boolean) => void
    disabled?: boolean
}

export class DocumentFields extends React.Component<React.PropsWithChildren<Props>> {
    public render() {
        const { document, disabled } = this.props

        return (
            <>
                <Field label={localize.translate(t => t.Customer.Compliance.ControlDocument.file)} forInput={'file'}>
                    <Form.FileInput
                        name={'file'}
                        disabled={disabled}
                        defaultFile={
                            document && document.file && { filename: document.file.name, id: document.file.id }
                        }
                    />
                </Field>
            </>
        )
    }
}
