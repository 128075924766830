import './TopicNotesAndControlsTableContainer.scss'

import React from 'react'
import {
    TopicControlMeasureType,
    TopicNoteDocument,
    TopicNoteQuery,
    TopicNoteQueryVariables,
    TopicNoteType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { TopicDesignAndEffectivenessTable } from './TopicDesignAndEffectivenessTable'
import { Query } from 'react-apollo'
import { TopicNoteModal } from './modals/TopicNoteModal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Tooltip, ToolTipDirection } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Icon } from '~/components/Core/Icon/Icon'
import { IconType } from '~/components/Core/Icon/IconType'
import { ControlLabel } from '../../Compliance/Controls/ControlLabel'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    topicId: number
    topicControls?: TopicControlMeasureType[]
    topicNotes?: TopicNoteType[]
    onChange: () => void
}

export class TopicNotesAndControlsTableContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('TopicNotesAndControlsTableContainer')

    public render() {
        const { topicId, onChange } = this.props

        return (
            <TopicDesignAndEffectivenessTable
                rowItems={[...this.getControlRowItems(), ...this.getNotesRowItems()]}
                topicId={topicId}
                onChange={onChange}
            />
        )
    }

    private getControlRowItems() {
        const { topicControls } = this.props

        if (!topicControls?.length) {
            return []
        }

        const allChildTopicControlIds = this.getAllChildTopicControlIds()

        const topLevelTopicControls = topicControls
            .filter(t => !allChildTopicControlIds.includes(t.id))
            .sort((a, b) => (a.control.name > b.control.name ? 0 : 1))

        const rowItems: { nameLabel: React.ReactNode; linkedItem: TopicControlMeasureType }[] = []

        for (const topicControl of topLevelTopicControls) {
            rowItems.push(this.getControlRowItem(topicControl))

            if (topicControl.linkedChildTopicControlMeasures?.length) {
                const childTopicControlIds = topicControl.linkedChildTopicControlMeasures.map(t => t.id)
                const childTopicControls = topicControls.filter(t => childTopicControlIds.includes(t.id))

                for (const topicControl of childTopicControls) {
                    rowItems.push(this.getControlRowItem(topicControl, 1))

                    if (topicControl.linkedChildTopicControlMeasures?.length) {
                        const items = this.getControlChildRowItems(
                            topicControls,
                            topicControl.linkedChildTopicControlMeasures,
                            2
                        )

                        rowItems.push(...items)
                    }
                }

                continue
            }

            if (topicControl.linkedNestedChildTopicControlMeasures?.length) {
                const items = this.getControlChildRowItems(
                    topicControls,
                    topicControl.linkedNestedChildTopicControlMeasures,
                    1
                )

                rowItems.push(...items)
            }
        }

        return rowItems
    }

    private getAllChildTopicControlIds() {
        const { topicControls } = this.props

        if (!topicControls?.length) {
            return []
        }

        const allChildTopicControlIds = topicControls.reduce((acc, c) => {
            acc.push(...(c.linkedChildTopicControlMeasures?.map(t => t.id) || []))
            acc.push(...(c.linkedNestedChildTopicControlMeasures?.map(t => t.id) || []))

            return acc
        }, [] as number[])

        return Array.from(new Set(allChildTopicControlIds))
    }

    private getControlChildRowItems(
        topicControls: TopicControlMeasureType[],
        linkedChildTopicControlMeasures: TopicControlMeasureType[],
        indentLevel: 1 | 2
    ) {
        const childTopicControlIds = linkedChildTopicControlMeasures.map(t => t.id)
        const childTopicControls = topicControls.filter(t => childTopicControlIds.includes(t.id))

        return childTopicControls.map(topicControl => this.getControlRowItem(topicControl, indentLevel))
    }

    private getControlRowItem(topicControl: TopicControlMeasureType, indentLevel?: 1 | 2) {
        return {
            linkedItem: topicControl,
            nameLabel: (
                <Tooltip
                    direction={ToolTipDirection.upStart}
                    message={topicControl.control.name}
                    className={this.bem.getElement('name-container')}
                >
                    <ControlLabel
                        className={this.bem.getElement('name')}
                        indentLevel={indentLevel}
                        control={topicControl.control}
                    />
                </Tooltip>
            ),
        }
    }

    private getNotesRowItems() {
        const { topicNotes } = this.props

        if (!topicNotes?.length) {
            return []
        }

        topicNotes.sort((a, b) => (a.title > b.title ? 0 : 1))

        return topicNotes.map(topicNote => ({
            nameLabel: this.renderNotesNameLabel(topicNote.id, topicNote.title),
            linkedItem: topicNote,
        }))
    }

    private renderNotesNameLabel(id: number, title: string) {
        return (
            <ModalManager
                render={openModal => (
                    <Tooltip
                        direction={ToolTipDirection.upStart}
                        message={title}
                        className={this.bem.getElement('name-tooltip')}
                    >
                        <Button
                            type={ButtonType.itemLink}
                            onClick={openModal}
                            className={this.bem.getElement('name-button')}
                        >
                            <Icon type={IconType.assessmentScribble} className={this.bem.getElement('name-icon')} />
                            {title}
                        </Button>
                    </Tooltip>
                )}
                renderModal={closeModal => this.renderAssessmentModal(id, closeModal)}
            />
        )
    }

    private renderAssessmentModal(id: number, closeModal: () => void) {
        return (
            <Query<TopicNoteQuery, TopicNoteQueryVariables>
                query={TopicNoteDocument}
                variables={{ id, departmentId: this.context.activeDepartmentId }}
            >
                {({ data, loading }) => (
                    <TopicNoteModal
                        requestClose={closeModal}
                        loading={loading}
                        topicAssessment={data?.topicNote as TopicNoteType}
                    />
                )}
            </Query>
        )
    }
}
