import React from 'react'
import { localize, notification, permissions } from '~/bootstrap'
import { NoResults } from '~/components/Chrome/NoResults/NoResults'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { RepeatableFileInput } from '~/components/Core/DataEntry/RepeatableFileInput/RepeatableFileInput'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { IconType } from '~/components/Core/Icon/IconType'
import { Column } from '~/components/Core/Layout/Column'
import { Row } from '~/components/Core/Layout/Row'
import { Markdown } from '~/components/Core/Text/Markdown'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { FilePreviewModal } from '~/components/Domain/FilePreview/FilePreviewModal'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { TopicNoteDocumentType, TopicNoteType } from '~/generated/graphql'
import { File as FileType } from '~/graphql/types/File'
import { FileType as FileTypes } from '~/services/FileService'
import { EditTopicNote, EditTopicNoteMutationFN } from '../mutations/EditTopicNote'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { readAndSanitizeFile } from '~/utils/fileSanitizer'

interface Props {
    topicAssessment?: TopicNoteType
    requestClose: () => void
    loading: boolean
}

interface State {
    isEditing: boolean
    files?: File[]
    validFileErrorMessage: string | null
}

export class TopicNoteModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        isEditing: false,
        validFileErrorMessage: null,
    }

    public render() {
        const { requestClose } = this.props

        return (
            <EditTopicNote>
                {(mutate, { loading }) => (
                    <Form onSubmit={this.handleSubmit(mutate)}>
                        <Modal
                            requestClose={requestClose}
                            title={localize.translate(t => t.Entities.Note)}
                            actions={this.renderActions(loading)}
                        >
                            {this.renderContent()}
                        </Modal>
                    </Form>
                )}
            </EditTopicNote>
        )
    }

    private renderActions = (mutateLoading: boolean) => () => {
        const { loading } = this.props
        const { isEditing } = this.state
        const isLoading = loading || mutateLoading
        const notAllowed = !permissions.canEditTopicDnE(this.context.activeDepartmentId)

        if (isEditing) {
            return (
                <Row alignRight={true}>
                    <Button
                        type={ButtonType.tertiary}
                        onClick={() => this.setState({ isEditing: false, files: [] })}
                        loading={isLoading}
                    >
                        {localize.translate(t => t.Generic.cancel)}
                    </Button>
                    <Button submit={true} disabled={notAllowed || isLoading}>
                        {localize.translate(t => t.Generic.save)}
                    </Button>
                </Row>
            )
        }

        return (
            <Row alignRight={true}>
                <Button onClick={() => this.setState({ isEditing: true })} loading={loading} disabled={notAllowed}>
                    {localize.translate(t => t.Generic.edit)}
                </Button>
            </Row>
        )
    }

    private renderContent() {
        const { loading, topicAssessment } = this.props
        const { validFileErrorMessage } = this.state

        if (loading) {
            return <Spinner delayed={true} />
        }

        if (!topicAssessment) {
            return <NoResults />
        }

        const { title, topicNoteDocuments, description } = topicAssessment

        return (
            <Column bigSpacing={true}>
                {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}

                {this.renderTitleField(title)}
                {this.renderDocumentsField(topicNoteDocuments || [])}
                {this.renderDescriptionField(description)}
            </Column>
        )
    }

    private handleSubmit = (mutate: EditTopicNoteMutationFN) => async (formState: FormState) => {
        const { topicAssessment, requestClose } = this.props
        const { title, description } = formState

        if (!topicAssessment) {
            return
        }

        const { newFiles, topicNoteDocumentIdsToRemove } = this.getDocumentsToUpdate()

        const isValidFile = await (async () => {
            for (const file of newFiles) {
                const result = file ? await readAndSanitizeFile(file) : true
                if (!result) {
                    return false
                }
            }
            return true
        })()

        if (isValidFile) {
            const response = await mutate({
                variables: {
                    topicNoteId: topicAssessment.id,
                    departmentId: this.context.activeDepartmentId,
                    fields: {
                        title,
                        description,
                        documents: newFiles,
                        topicNoteDocumentIdsToRemove,
                    },
                },
            })

            if (response && response.data?.editTopicNote) {
                notification.success(localize.translate(t => t.Generic.successfullyCreated))
                requestClose()
            }
        } else {
            this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
        }
    }

    private renderTitleField(title: string) {
        const { isEditing } = this.state

        return (
            <Column smallSpacing={true}>
                <Paragraph bold={true}>{localize.translate(t => t.Generic.title)}</Paragraph>
                <Field forInput="title">
                    {isEditing ? <Form.Input name="title" defaultValue={title} /> : <Paragraph>{title}</Paragraph>}
                </Field>
            </Column>
        )
    }

    private renderDocumentsField(documents: TopicNoteDocumentType[]) {
        const { isEditing } = this.state
        const defaultFiles = this.getDefaultFiles(this.props.topicAssessment?.topicNoteDocuments)

        if (isEditing) {
            return (
                <RepeatableFileInput
                    name="documents"
                    defaultFiles={defaultFiles}
                    onChange={files => this.setState({ ...this.state, files })}
                />
            )
        }

        if (!documents?.length) {
            return
        }

        const files: FileType[] = []
        for (const { file } of documents) {
            if (file) {
                files.push(file as FileType)
            }
        }

        return (
            <Column smallSpacing={true}>
                <Field forInput="documents" label={localize.translate(t => t.Generic.documents)} />
                {files.map(file => (
                    <FilePreviewModal key={file.id} file={file} fileType={FileTypes.topicNoteFile}>
                        {previewFile => (
                            <Row>
                                <Button
                                    onClick={() => previewFile()}
                                    icon={IconType.attachment}
                                    type={ButtonType.actionLink}
                                >
                                    {file.name}
                                </Button>
                            </Row>
                        )}
                    </FilePreviewModal>
                ))}
            </Column>
        )
    }

    private renderDescriptionField(description?: string | null) {
        const { isEditing } = this.state

        if (!isEditing && !description) {
            return
        }

        return (
            <Column smallSpacing={true}>
                <Field forInput="description" label={localize.translate(t => t.Generic.description)} />
                {isEditing ? (
                    <Form.TextEditor name="description" defaultValue={description} />
                ) : (
                    <Markdown source={description} paragraphLike={true} />
                )}
            </Column>
        )
    }

    private getDocumentsToUpdate() {
        const topicNoteDocuments = this.props.topicAssessment?.topicNoteDocuments || []
        const files = this.state.files ? this.state.files : this.getDefaultFiles(topicNoteDocuments)

        const topicNoteDocumentIdsToRemove: number[] = []
        for (const { id, file } of topicNoteDocuments) {
            // Known edge case/bug:
            //      If a file with the same name exists in the list & is removed,
            //      all instances of the topicNoteDocument will be removed
            //
            // Because this is unlikely & because they can readd
            // this case will not be handled at the moment (mvp)
            const isRemoved = !files.some(f => f.name === file?.name)
            if (isRemoved) {
                topicNoteDocumentIdsToRemove.push(id)
            }
        }

        const newFiles: File[] = []
        for (const file of files) {
            const isNew = !topicNoteDocuments.some(t => t.file?.name === file.name)
            if (isNew) {
                newFiles.push(file)
            }
        }

        return { newFiles, topicNoteDocumentIdsToRemove }
    }

    private getDefaultFiles(topicNoteDocuments?: TopicNoteDocumentType[] | null) {
        if (!topicNoteDocuments || !topicNoteDocuments.length) {
            return []
        }

        const defaultFiles: File[] = []
        for (const { file } of topicNoteDocuments) {
            if (file) {
                defaultFiles.push({ name: file.name } as File)
            }
        }

        return defaultFiles
    }
}
