import './RelevantMatrixCard.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Card } from '~/components/Core/DataDisplay/Card/Card'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { localize } from '~/bootstrap'

interface Props {
    className?: ClassValue
    name: string
    isSuggested?: boolean
    onClick?: () => void
    onDelete?: () => void
}

interface State {}

export class RelevantMatrixCard extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('RelevantMatrixCard', () => ({
        'is-suggested': this.props.isSuggested,
    }))

    public render() {
        const { className, onClick, name, onDelete, isSuggested } = this.props

        return (
            <Card className={this.bem.getClassName(className)} onClick={onClick}>
                <Row spaceBetween={true}>
                    <h5 className={this.bem.getElement('title')}>{name}</h5>
                    <Row spaceBetween={true} smallSpacing={true}>
                        {isSuggested && (
                            <span className={this.bem.getElement('suggestie')}>
                                {localize.translate(t => t.Customer.Signaling.RelevantModal.suggestedCardNote)}
                            </span>
                        )}
                        {onDelete && (
                            <Button
                                className={this.bem.getElement('remove')}
                                type={ButtonType.noStyling}
                                icon={IconType.close}
                                onClick={onDelete}
                            />
                        )}
                    </Row>
                </Row>
            </Card>
        )
    }
}
