import './CreateCustomerFrameworkProfileContainer.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Column } from '~/components/Core/Layout/Column'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerFrameworkColorEnumType, CustomerFrameworkIconEnumType } from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { frameworkIconColorOrder } from '../../Customer/Framework/FrameworkIcon/FrameworkIcon'
import { CustomerFrameworkRadio, RadioItem } from '../CustomerFrameworkRadio'

interface Props {
    selectedColor?: CustomerFrameworkColorEnumType
    handleOnRadioChange: (option: RadioItem, name: string) => void
    handleOnNameChange: (value: string | null) => void
}

export class CreateCustomerFrameworkModalProfileContainer extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('CreateCustomerFrameworkProfileContainer')
    private loc = localize.namespaceTranslate(t => t.Customer.Settings.CustomerFrameworks.createMultiModal)

    public render() {
        const { handleOnRadioChange, handleOnNameChange } = this.props

        const availableColors = this.getAvailableColors()
        const availableIcons = this.getAvailableIcons()

        return (
            <>
                <ErrorMessage path={'createCustomerFramework'} />
                <Column bigSpacing={true} className={this.bem.getElement('field-container')}>
                    <Column smallSpacing={true}>
                        <Paragraph subtle={true} bold={true}>
                            {this.loc(t => t.stepIndex, { currentStep: 2, totalSteps: 2 })}
                        </Paragraph>
                        <Paragraph>{this.loc(t => t.profileModal.subTitle)}</Paragraph>
                    </Column>
                    <Field label={localize.translate(t => t.Settings.Attributes.name)} forInput={'name'}>
                        <Input
                            name={'name'}
                            placeholder={this.loc(t => t.profileModal.namePlaceholder)}
                            autoComplete={false}
                            onChange={(value, name) => handleOnNameChange(value)}
                        />
                    </Field>
                    <Field label={localize.translate(t => t.Settings.Attributes.color)} forInput={'color'}>
                        <CustomerFrameworkRadio
                            name={'color'}
                            onChange={handleOnRadioChange}
                            optionType={'color'}
                            options={availableColors}
                        />
                    </Field>
                    <Field label={localize.translate(t => t.Settings.Attributes.icon)} forInput={'icon'}>
                        <CustomerFrameworkRadio
                            name={'icon'}
                            onChange={handleOnRadioChange}
                            optionType={'icon'}
                            options={availableIcons}
                        />
                    </Field>
                </Column>
            </>
        )
    }

    private getAvailableColors() {
        return frameworkIconColorOrder.map(color => ({ color, icon: undefined }))
    }

    private getAvailableIcons() {
        const { selectedColor } = this.props
        const icons = Object.values(CustomerFrameworkIconEnumType)

        return icons.map(icon => ({
            color: selectedColor,
            icon: icon,
        }))
    }
}
