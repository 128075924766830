import React from 'react'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import {
    TaskPriority,
    NewOrExistingTag,
    LinkedTaskItem,
    TaskDetailFields,
    TaskEmployeeFields,
} from '~/graphql/types/Task'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { TaskFormFields } from '~/components/Domain/Task/TaskFormFields'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { LinkedTaskCard } from './LinkedTaskCard'
import { CustomerTaskTemplateType, DepartmentType, TaskRepeatFrequency } from '~/generated/graphql'
import { ReviewSubTasksTable } from './ReviewSubTasksTable'
import { localize, permissions } from '~/bootstrap'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { UserProfilePickerListUser } from '~/components/Core/DataEntry/UserProfilePicker/UserProfilePickerList/UserProfilePickerList'
import { ConfirmModal } from '~/components/Core/Feedback/Modal/ConfirmModal'
import { TaskModalSubTaskSelection } from './TaskModalSubTaskSelection'
import { SubTaskVariable } from './CreateTaskMutation'
import { TaskFormTemplateFieldOptionValue } from './TaskFormTemplateField'

export interface CreateTaskFormValues {
    name?: string
    description?: string
    startAt?: Date
    dueAt?: Date
    employeeIds: number[]
    priority: TaskPriority
    tags?: NewOrExistingTag[]
    forMonitoring?: boolean
    repeats?: TaskRepeatFrequency
    taskTemplateId?: number
    consultantTaskTemplateId?: number
    consultantTaskTemplateCustomerFrameworkId?: number
    subTasks?: SubTaskVariable[]
    linkedItemDepartmentId?: number
}

interface Props {
    submitLoading?: boolean
    loading?: boolean
    title: string
    errorPath?: string
    requestClose?: () => void
    onSubmit?: (values: CreateTaskFormValues, requestClose?: () => void) => void
    linkedItem?: LinkedTaskItem
    task?: TaskDetailFields | null
    defaultSelectedEmployee?: TaskEmployeeFields
    defaultForMonitoring?: boolean
    isForSubTask?: boolean
    subTaskParentName?: string
    isNew?: boolean
    possibleDepartments?: Pick<DepartmentType, 'id' | 'name'>[]
}

interface State {
    taskTemplate?: CustomerTaskTemplateType
    selectedSubTaskIds?: number[]
}

export class TaskModal extends React.Component<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    public state: State = {}

    public shouldConfirmEmployeeSelection = false
    private formRef = React.createRef<Form>()
    private loc = localize.namespaceTranslate(t => t.Customer.Task.TaskModal)

    public render() {
        const { title, requestClose, submitLoading, defaultSelectedEmployee, defaultForMonitoring } = this.props

        const defaultState = {
            ...(defaultForMonitoring !== undefined ? { forMonitoring: defaultForMonitoring } : {}),
            ...(defaultSelectedEmployee ? { employeeIds: [defaultSelectedEmployee] } : {}),
        }

        return (
            <Form onSubmit={this.handleOnSubmit} ref={this.formRef} isCompact={true} defaultState={defaultState}>
                <ConfirmModal
                    onConfirm={this.formRef.current ? () => this.formRef.current!.triggerSubmit() : undefined}
                    title={this.loc(t => t.title)}
                    message={this.loc(t => t.message)}
                    loading={submitLoading}
                    confirmButtonLabel={this.loc(t => t.confirmButtonLabel)}
                >
                    {openModal => (
                        <Modal
                            title={title}
                            requestClose={requestClose}
                            confirmButtonLabel={localize.translate(t => t.Generic.save)}
                            submitForm={this.shouldConfirmEmployeeSelection ? undefined : true}
                            onAction={this.shouldConfirmEmployeeSelection ? openModal : undefined}
                            loading={submitLoading}
                        >
                            {this.renderContent()}
                        </Modal>
                    )}
                </ConfirmModal>
            </Form>
        )
    }

    private renderContent() {
        const { loading, errorPath } = this.props

        if (loading) {
            return <Spinner delayed={true} />
        }

        return (
            <>
                {errorPath && <ErrorMessage path={errorPath} />}
                {this.renderLinkedItemOrTasksToReview()}
                {this.renderFormFields()}
                {this.renderSubTaskSelection()}
            </>
        )
    }

    private renderLinkedItemOrTasksToReview() {
        const { task, linkedItem, possibleDepartments } = this.props

        if (task?.isReviewTask) {
            return <ReviewSubTasksTable linkedTasks={task.linkedTasksToReview} />
        }

        return <LinkedTaskCard linkedItem={linkedItem} possibleDepartments={possibleDepartments} />
    }

    private renderFormFields() {
        const { task, isForSubTask, subTaskParentName, isNew } = this.props

        const showTemplateOption = isNew && !isForSubTask
        const startAt = task && task.startAt ? new Date(task.startAt) : undefined
        const dueAt = task && task.dueAt ? new Date(task.dueAt) : undefined
        const employees = this.getEmployees()
        const forMonitoring = this.getForMonitoring()

        return (
            <TaskFormFields
                name={task?.name}
                description={task?.description}
                startAt={startAt}
                dueAt={dueAt}
                employees={employees}
                tags={task?.tags}
                priority={task?.priority}
                forMonitoring={forMonitoring}
                repeats={task?.repeats}
                isReviewTask={task?.isReviewTask}
                onChangeEmployees={this.onChangeEmployees}
                isForSubTask={isForSubTask}
                subTaskParentName={subTaskParentName}
                showTemplateOption={showTemplateOption}
                onTemplateOptionChange={this.handleTemplateOptionChange}
            />
        )
    }

    private renderSubTaskSelection() {
        const { isNew, isForSubTask } = this.props
        const { taskTemplate } = this.state

        const shouldRenderFormFields = isNew && !isForSubTask && taskTemplate?.subTaskTemplates?.length
        if (!shouldRenderFormFields) {
            return
        }

        return (
            <TaskModalSubTaskSelection
                onSelectionChange={selectedSubTaskIds => this.setState({ selectedSubTaskIds })}
                subTasks={taskTemplate!.subTaskTemplates!}
            />
        )
    }

    private handleOnSubmit = (formState: FormState) => {
        const { onSubmit, requestClose } = this.props
        const values = this.getFormValues(formState)

        if (onSubmit) {
            onSubmit(values, requestClose)
        }
    }

    private getEmployees() {
        const { task, defaultSelectedEmployee } = this.props

        if (task && task.employees) {
            return task.employees
        }

        if (defaultSelectedEmployee) {
            return [defaultSelectedEmployee]
        }

        return undefined
    }

    private getForMonitoring() {
        const { task, defaultForMonitoring } = this.props

        if ((task && task.forMonitoring) || defaultForMonitoring) {
            return true
        }

        return false
    }

    private onChangeEmployees = (selectedUsers: UserProfilePickerListUser[], name: string) => {
        const isUserPassive = permissions.isPassiveUserForDepartment(this.context.activeDepartmentId)
        const currentUserEmployeeId = this.context.employee?.id

        if (!isUserPassive || currentUserEmployeeId === undefined) {
            return false
        }

        const employees = this.getEmployees()
        const isUserAssignedToTask = !!employees?.find(({ id }) => id === currentUserEmployeeId)

        // more for sanity-check --- if user is passive, this shouldnt be possible
        if (!isUserAssignedToTask) {
            return false
        }

        const didUnassignSelf = !selectedUsers.find(({ id }) => id === currentUserEmployeeId)
        this.shouldConfirmEmployeeSelection = didUnassignSelf

        // this is kinda hacky to allow the shouldConfirmEmployeeSelection boolean to be picked up in the rerender
        this.forceUpdate()

        return
    }

    private handleTemplateOptionChange = (taskTemplate?: CustomerTaskTemplateType) => {
        const shouldResetState = !taskTemplate && this.state.taskTemplate
        if (shouldResetState) {
            this.setState({ taskTemplate: undefined, selectedSubTaskIds: undefined })
            return
        }

        const shouldDoNothing = !taskTemplate || taskTemplate.id === this.state.taskTemplate?.id
        if (shouldDoNothing) {
            return
        }

        const selectedSubTaskIds = (taskTemplate!.subTaskTemplates as CustomerTaskTemplateType[])?.map(st => st.id)
        this.setState({ taskTemplate, selectedSubTaskIds })
    }

    private getFormValues(formState: FormState): CreateTaskFormValues {
        const { name, description, tags } = this.getVariablesSharedWithTaskTemplate(formState)
        const startAt = formState.startAt ? formState.startAt : undefined
        const dueAt = formState.dueAt ? formState.dueAt : undefined
        const repeats = formState.repeats ? formState.repeats.value : formState.repeats
        const subTasks = this.getSubTaskVariables()
        const linkedItemDepartmentId = this.getLinkedItemDepartmentId(formState)

        let taskTemplateId: number | undefined
        let consultantTaskTemplateId: number | undefined
        let consultantTaskTemplateCustomerFrameworkId: number | undefined
        if (formState.forTemplate?.value) {
            const value: TaskFormTemplateFieldOptionValue = formState.forTemplate.value
            if (value.type === 'TaskTemplateType') {
                taskTemplateId = value.id
            } else {
                consultantTaskTemplateId = value.id
                consultantTaskTemplateCustomerFrameworkId = value.customerFrameworkId
            }
        }

        const employeeIds = formState.employeeIds
            ? formState.employeeIds.map((employee: any) => employee.id)
            : undefined
        const priority = formState.priority ? formState.priority.value : undefined

        const forMonitoring = formState.forMonitoring === undefined ? undefined : !!formState.forMonitoring

        return {
            name,
            description,
            startAt,
            dueAt,
            employeeIds,
            priority,
            tags,
            forMonitoring,
            repeats,
            taskTemplateId,
            consultantTaskTemplateId,
            consultantTaskTemplateCustomerFrameworkId,
            subTasks,
            linkedItemDepartmentId,
        }
    }

    private getVariablesSharedWithTaskTemplate(formState: FormState) {
        const { taskTemplate } = this.state

        let name: string | undefined = undefined
        let description: string | undefined = undefined
        let tags: NewOrExistingTag[] | undefined = undefined

        if (formState.name !== undefined) {
            name = formState.name
        } else if (taskTemplate?.name) {
            name = taskTemplate.name
        }

        if (formState.description !== undefined) {
            description = formState.description
        } else if (taskTemplate?.description) {
            description = taskTemplate.description
        }

        if (formState.tags !== undefined) {
            tags = this.transformTagOptions(formState.tags)
        } else if (taskTemplate && 'tags' in taskTemplate && taskTemplate.tags?.length) {
            tags = taskTemplate.tags.map(({ id }) => ({ tagId: id }))
        }

        return { name, description, tags }
    }

    private getSubTaskVariables() {
        const { taskTemplate, selectedSubTaskIds } = this.state

        if (!taskTemplate || !taskTemplate.subTaskTemplates?.length || !selectedSubTaskIds?.length) {
            return
        }

        const subTasks: SubTaskVariable[] = []

        for (const id of selectedSubTaskIds) {
            const subTaskTemplate = (taskTemplate.subTaskTemplates as CustomerTaskTemplateType[]).find(
                st => st.id === id
            )
            if (!subTaskTemplate) {
                continue
            }

            const name = subTaskTemplate.name
            const description = subTaskTemplate.description || undefined
            const tagIds = ('tags' in subTaskTemplate && subTaskTemplate.tags?.map(({ id }) => id)) || undefined

            subTasks.push({ name, description, tagIds: tagIds || undefined })
        }

        return subTasks
    }

    private transformTagOptions(tags: SelectOption[] | null): NewOrExistingTag[] {
        if (tags === null) {
            return []
        }

        return tags
            .filter(tag => tag.value)
            .map(tag => {
                if (tag.__isNew__ && typeof tag.value === 'string') {
                    return { tagName: tag.value }
                }

                return { tagId: tag.value as number }
            })
    }

    private getLinkedItemDepartmentId(formState: FormState) {
        if (this.props.possibleDepartments?.length) {
            return formState.linkedItemDepartmentId?.value
        }

        if (this.props.linkedItem?.__typename === 'MonitoringReportType') {
            return this.context.activeDepartmentId
        }
    }
}
