import React from 'react'
import queryString from 'query-string'
import { localize, notification, userClient } from '~/bootstrap'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {
    loading: boolean
    url?: string
}

class CoverSamlViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        loading: true,
        url: '/',
    }

    private params = this.props.location.search ? queryString.parse(this.props.location.search) : null

    public componentDidMount() {
        this.login()
    }

    public render() {
        const { loading, url } = this.state

        if (loading) {
            return <span>{localize.translate(t => t.Loading)}</span>
        }

        return (
            <span>
                <a href={url}>{localize.translate(t => t.Cover.Login.clickToLogin)}</a>
            </span>
        )
    }

    private async login() {
        if (
            typeof this.params?.user_id !== 'string' ||
            typeof this.params?.token !== 'string' ||
            typeof this.params?.access_group !== 'string'
        ) {
            this.props.history.push('/')
            notification.error(localize.translate(t => t.Cover.Login.SAMLLoginFailed))
            return
        }

        const userId = parseInt(this.params.user_id, 10)
        const redirectToApp = this.params.app_redirect === 'true'

        if (redirectToApp) {
            const url = `rulerapp://sso?user_id=${this.params.user_id}&token=${this.params.token}&access_group=${this.params.access_group}`

            this.setState(
                {
                    loading: false,
                    url,
                },
                () => {
                    window.location.href = url
                }
            )
        } else {
            await userClient.loginWithUserIdAndToken(userId, this.params.token, path => {
                this.props.history.push(path)
            })
        }
    }
}

export const CoverSamlView = withRouter(CoverSamlViewComponent)
