import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { localize } from '~/bootstrap'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { GQLMutation } from '~/graphql/Mutation'
import gql from 'graphql-tag'
import { MutationFn } from 'react-apollo'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'
import { ControlFormFields } from './ControlFormFields'
import { ControlVisibility } from '~/generated/graphql'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { getResponsibleEmployeeData } from '~/utils/controls'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ControlDetailLocationState } from '~/views/Customer/Compliance/Controls/ControlsDetailView'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {
    className?: ClassValue
    requestClose: () => void
}
interface State {
    openSecondModal: boolean
}

const CREATE_CONTROL_MUTATION = gql`
    mutation createControl($fields: CreateControlFieldsType!, $departmentId: Int!) {
        createControl(fields: $fields, departmentId: $departmentId) {
            id
        }
    }
`

export const AddControlModal = withRouter(
    class AddControlModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
        public state: State = {
            openSecondModal: false,
        }

        public static contextType = CustomerContext
        public context: CustomerContextValue

        private bem = new BEM('AddControlModal')
        private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Controls.AddControlModal)

        public render() {
            const { requestClose, className } = this.props

            return (
                <GQLMutation mutation={CREATE_CONTROL_MUTATION}>
                    {(mutate, { loading }) => (
                        <Form
                            isCompact={true}
                            onSubmit={this.handleSubmitForm(mutate)}
                            className={this.bem.getClassName(className)}
                        >
                            <Modal
                                requestClose={requestClose}
                                title={this.loc(t => t.modalTitle)}
                                submitForm={true}
                                confirmButtonLabel={localize.translate(t => t.Generic.save)}
                                loading={loading}
                                actions={closeModal => (
                                    <Row alignRight={true}>
                                        <Button type={ButtonType.tertiary} disabled={loading} onClick={closeModal}>
                                            {localize.translate(t => t.Generic.cancel)}
                                        </Button>
                                        <Button
                                            type={ButtonType.secondary}
                                            loading={loading}
                                            submit={true}
                                            onClick={() => this.setState({ openSecondModal: true })}
                                        >
                                            {this.loc(t => t.selectSubject)}
                                        </Button>
                                        <Button
                                            loading={loading}
                                            submit={true}
                                            onClick={() => this.setState({ openSecondModal: false })}
                                        >
                                            {localize.translate(t => t.Generic.save)}
                                        </Button>
                                    </Row>
                                )}
                            >
                                {this.renderForm()}
                            </Modal>
                        </Form>
                    )}
                </GQLMutation>
            )
        }

        private handleSubmitForm = (mutate: MutationFn) => async (formState: FormState) => {
            let visibility: ControlVisibility = ControlVisibility.ownerDepartment
            let visibleToDepartmentIds: number[] | undefined = undefined

            if (formState.visibility) {
                visibility = formState.visibility.value
            }

            if (visibility === ControlVisibility.selectionOfDepartments) {
                visibleToDepartmentIds = formState.visibleToDepartments
                    ? formState.visibleToDepartments.map((option: SelectOption) => option.value)
                    : []
            }

            const { responsibleEmployeeIds, responsibleShadowEmployeeIds, responsibleEmployees } =
                getResponsibleEmployeeData(formState.responsibleEmployees)

            const fields = {
                name: formState.name,
                type: formState.type && formState.type.value,
                description: formState.description,
                startDate: formState.startDate,
                revisionDate: formState.revisionDate,
                responsibleEmployeeIds: responsibleEmployeeIds,
                responsibleShadowEmployeeIds: responsibleShadowEmployeeIds,
                responsibleEmployees: responsibleEmployees,
                visibility,
                visibleToDepartmentIds,
            }

            const response = await mutate({
                variables: {
                    fields,
                    departmentId: this.context.activeDepartmentId,
                },
            })

            this.props.requestClose()

            if (response && response.data && response.data.createControl) {
                const stateParams: ControlDetailLocationState = this.state.openSecondModal
                    ? { isNewlyCreated: true }
                    : {}

                this.props.history.push(
                    routes
                        .customer(this.context.customer.slug)
                        .compliance.controls.view(response.data.createControl.id),
                    { state: stateParams }
                )
            }
        }

        private renderForm(): React.ReactNode {
            return (
                <>
                    <ErrorMessage path={'createControl'} />
                    <ControlFormFields />
                </>
            )
        }
    }
)
