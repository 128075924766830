import './NewsItemTag.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    label: string
    type?: NewsItemTagEnum
    tooltipContent?: React.ReactNode
}

interface State {}

export enum NewsItemTagEnum {
    suggestedTopic = 'suggested-topic',
    topic = 'topic',
    department = 'department',
    internalAlert = 'internal-alert',
}

export class NewsItemTag extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('NewsItemTag', () => ({
        [`is-${this.props.type}`]: !!this.props.type,
    }))

    public render() {
        const { label, className, tooltipContent } = this.props

        if (tooltipContent) {
            return (
                <Tooltip content={tooltipContent}>
                    <div className={this.bem.getClassName(className)}>{label}</div>
                </Tooltip>
            )
        }

        return <div className={this.bem.getClassName(className)}>{label}</div>
    }
}
