import React from 'react'
import { GQLMutation } from '~/graphql/Mutation'
import { MutationFn, MutationResult } from 'react-apollo'
import {
    EditCustomerEnabledNewsSourceDocument,
    EditCustomerEnabledNewsSourceMutationVariables,
    EditCustomerEnabledNewsSourceMutationResult,
} from '~/generated/graphql'

interface Props {
    children: (
        mutation: MutationFn<
            EditCustomerEnabledNewsSourceMutationResult,
            EditCustomerEnabledNewsSourceMutationVariables
        >,
        options: MutationResult
    ) => JSX.Element
}

export const NewsSourcesMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<EditCustomerEnabledNewsSourceMutationResult, EditCustomerEnabledNewsSourceMutationVariables>
        mutation={EditCustomerEnabledNewsSourceDocument}
    >
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
