import './DepartmentEmployeesOverviewModal.scss'

import React from 'react'
import { localize } from '~/bootstrap'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    DepartmentEmployeesDocument,
    DepartmentEmployeesQueryVariables,
    DepartmentEmployeesQuery,
    DepartmentType,
    EmployeeType,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { Column } from '~/components/Core/Layout/Column'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { sortBy } from 'lodash-es'
import { Toggle } from '~/components/Core/DataEntry/Form/Toggle'

interface Props {
    department: DepartmentType
    closeModal: () => void
}

export class DepartmentEmployeesOverviewModal extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Settings.DepartmentsOverview.employeeModal)
    private bem = new BEM('DepartmentEmployeesOverviewModal')

    public render() {
        const { closeModal, department } = this.props

        return (
            <Query<DepartmentEmployeesQuery, DepartmentEmployeesQueryVariables>
                query={DepartmentEmployeesDocument}
                variables={{ id: department.id, customerId: this.context.customer.id }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return <Spinner delayed={true} />
                    }
                    if (!data) {
                        return null
                    }

                    return (
                        <Modal
                            className={this.bem.getClassName()}
                            hideButtons={true}
                            requestClose={closeModal}
                            title={department.name}
                        >
                            {this.renderModalContent(data)}
                        </Modal>
                    )
                }}
            </Query>
        )
    }

    private renderModalContent(queryResult: DepartmentEmployeesQuery) {
        const { department } = this.props

        return (
            <Row spaceBetween={true} className={this.bem.getElement('modal-content-container')}>
                <Paragraph className={this.bem.getElement('table-title')} bold={true}>
                    {this.loc(t => t.users)}
                </Paragraph>
                <Row className={this.bem.getElement('table-header-row')}>
                    <Paragraph className={this.bem.getElement('name')} bold={true}>
                        {this.loc(t => t.name)}
                    </Paragraph>
                    <Paragraph bold={true}>{this.loc(t => t.status)}</Paragraph>
                </Row>
                <Column className={this.bem.getElement('table-container')}>
                    <Column className={this.bem.getElement('table-row-container')}>
                        {this.renderEmployeeRows(queryResult)}
                    </Column>
                </Column>
                <Paragraph bold={true} className={this.bem.getElement('table-title')}>
                    {this.loc(t => t.consultationAlerts)}
                </Paragraph>
                <Row>
                    <Toggle
                        name={'consultationAlerts'}
                        disabled={true}
                        defaultChecked={department.receiveAlertsForConsultationsEnabled}
                    />
                    <Paragraph>{this.loc(t => t.consultationAlertsInfo)}</Paragraph>
                </Row>
            </Row>
        )
    }

    private renderEmployeeRows(queryResult: DepartmentEmployeesQuery) {
        const { sortedEmployees, activeEmployees } = this.getSortedAndActiveEmployees(queryResult)

        return (
            <>
                {sortedEmployees.map(({ id, fullName }) => {
                    const isActive = activeEmployees.find(employee => employee.id === id)

                    return (
                        <Row key={id} className={this.bem.getElement('table-row')}>
                            <Paragraph>{fullName}</Paragraph>
                            <Paragraph subtle={true}>
                                {isActive ? this.loc(t => t.active) : this.loc(t => t.passive)}
                            </Paragraph>
                        </Row>
                    )
                })}
            </>
        )
    }

    private getSortedAndActiveEmployees(queryResult: DepartmentEmployeesQuery) {
        const employees = queryResult.getDepartment?.employees.length ? queryResult.getDepartment?.employees : []
        const passiveEmployees = queryResult.getDepartment?.passiveEmployees.length
            ? queryResult.getDepartment?.passiveEmployees
            : []
        const activeEmployees = employees.filter(({ id }) => !passiveEmployees.some(employee => employee.id === id))

        const allEmployeeNamesAndIds: { id: number; fullName: string }[] = []
        if (activeEmployees.length) {
            allEmployeeNamesAndIds.push(...activeEmployees.map(this.getNameAndEmployeeId))
        }
        if (passiveEmployees.length) {
            allEmployeeNamesAndIds.push(...passiveEmployees.map(this.getNameAndEmployeeId))
        }

        const sortedEmployees = sortBy(allEmployeeNamesAndIds, 'fullName')
        return { sortedEmployees, activeEmployees }
    }

    private getNameAndEmployeeId(employee: EmployeeType) {
        return {
            id: employee.id,
            fullName: employee.user.profile.fullName,
        }
    }
}
