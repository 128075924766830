import React from 'react'

import { Column } from '~/components/Core/Layout/Column'
import { Section } from '~/components/Core/Layout/Section'
import { LinkedRiskCard } from '~/components/Domain/Compliance/LinkedCompliance/LinkedRiskCard'
import { ComplianceCardList } from '~/components/Domain/Compliance/ComplianceCardList'
import { localize } from '~/bootstrap'
import { LinkedControlCard } from './LinkedControlCard'
import { SeverityLevel } from '../Risks/RiskIndicator'
import { getIconTypeForControlType } from '~/utils/controls'
import { ControlTypeType } from '~/generated/graphql'

interface Props {
    assessment: {
        name: string
        controls:
            | {
                  id: number
                  name: string
                  type: ControlTypeType
                  revisionDate?: string | null
              }[]
            | null
        risks:
            | {
                  id: number
                  name: string
                  severity: SeverityLevel
                  severityBruto: SeverityLevel
                  isAccepted: boolean
              }[]
            | null
    }
}

interface State {}

export class LinkedRisksAndControls extends React.Component<React.PropsWithChildren<Props>, State> {
    public render() {
        const { assessment } = this.props

        return (
            <Column bigSpacing={true}>
                <Section key={assessment.name} title={assessment.name}>
                    <ComplianceCardList title={localize.translate(t => t.Customer.Compliance.LinkedAssessments.risks)}>
                        <Column smallSpacing={true}>
                            {assessment.risks &&
                                assessment.risks.map((risk, index) => {
                                    return (
                                        <LinkedRiskCard
                                            cardOptions={{
                                                name: risk.name,
                                                severity: risk.severity || risk.severityBruto,
                                            }}
                                            key={`LinkedRiskCard-${index}`}
                                        />
                                    )
                                })}
                        </Column>
                    </ComplianceCardList>
                    <ComplianceCardList
                        title={localize.translate(t => t.Customer.Compliance.LinkedAssessments.controls)}
                    >
                        <Column smallSpacing={true}>
                            {assessment.controls &&
                                assessment.controls.map((control, index) => {
                                    return (
                                        <LinkedControlCard
                                            label={control.name}
                                            icon={getIconTypeForControlType(control.type)}
                                            revisionDate={control.revisionDate}
                                            key={`LinkedControlCard-${index}`}
                                        />
                                    )
                                })}
                        </Column>
                    </ComplianceCardList>
                </Section>
            </Column>
        )
    }
}
