import './InboxLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconLabel } from '~/components/Core/Text/IconLabel'
import { IconType } from '~/components/Core/Icon/IconType'
import { routes } from '~/views/routes'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { matchPath } from 'react-router'
import { AlertImpactLevel } from '~/generated/graphql'

export interface InboxItem {
    id: number
    name: string
    impactLevel?: AlertImpactLevel | null
}

interface Props {
    className?: ClassValue
    inboxItem: InboxItem
    iconOnly?: boolean
}

interface State {}

export class InboxLabel extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('InboxLabel', () => ({
        'is-high-impact': this.props.inboxItem.impactLevel === AlertImpactLevel.high,
        'is-medium-impact': this.props.inboxItem.impactLevel === AlertImpactLevel.medium,
        'is-low-impact': this.props.inboxItem.impactLevel === AlertImpactLevel.low,
    }))

    public render() {
        const { className, inboxItem, iconOnly } = this.props

        const link = routes.customer(this.context.customer.slug).inbox.detail.view(inboxItem.id)
        const match = matchPath(
            {
                path: link,
                caseSensitive: false,
            },
            window.location.pathname
        )

        return (
            <IconLabel
                className={this.bem.getClassName(className)}
                iconType={IconType.inbox}
                to={match ? undefined : link}
            >
                {iconOnly ? null : inboxItem.name}
            </IconLabel>
        )
    }
}
