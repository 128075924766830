import './AssessmentSectionItemCard.scss'
import React from 'react'
import { BEM } from '~/services/BEMService'
import { Row } from '~/components/Core/Layout/Row'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { localize, permissions } from '~/bootstrap'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Modal } from '~/components/Core/Feedback/Modal/Modal'
import { AssessmentSectionType } from '~/generated/graphql'
import { LinkedRiskCard } from '../../LinkedCompliance/LinkedRiskCard'
import { LinkedControlCard } from '../../LinkedCompliance/LinkedControlCard'
import { getIconTypeForControlType } from '~/utils/controls'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Section } from '~/components/Core/Layout/Section'
import { Column } from '~/components/Core/Layout/Column'
import { Guard } from '~/components/Core/Guard/Guard'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'

interface Props {
    handleOnClose: () => void
    onCloseLoading: boolean
    assessmentSection: AssessmentSectionType
    assessmentName: string
}

export class AssessmentSectionItemCard extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private bem = new BEM('AssessmentSectionItemCard')
    private loc = localize.namespaceTranslate(t => t.Customer.Compliance.Assessments.AssessmentSections)

    public render() {
        const { children, assessmentSection } = this.props
        const shouldRenderButtonWithConfirmationModal =
            assessmentSection.controls?.length || assessmentSection.risks?.length

        return (
            <div className={this.bem.getClassName()}>
                <Guard condition={permissions.canEditAssessment(this.context.activeDepartmentId)}>
                    <Row alignRight={true} className={this.bem.getElement('row')}>
                        {shouldRenderButtonWithConfirmationModal
                            ? this.renderButtonWithConfirmationModal()
                            : this.renderDeleteButton()}
                    </Row>
                </Guard>
                {children}
            </div>
        )
    }

    private renderButtonWithConfirmationModal() {
        const { onCloseLoading, assessmentName } = this.props

        const deleteText = this.loc(t => t.deleteSectionTooltipMessage)

        return (
            <ModalManager
                render={openModal => (
                    <Tooltip message={deleteText}>
                        <Button
                            type={ButtonType.noStyling}
                            loading={onCloseLoading}
                            icon={IconType.close}
                            onClick={openModal}
                            className={this.bem.getElement('button')}
                        />
                    </Tooltip>
                )}
                renderModal={closeModal => (
                    <Modal
                        title={deleteText}
                        requestClose={closeModal}
                        actions={() => (
                            <Row alignRight={true}>
                                <Button danger={true} onClick={() => this.handleDelete(closeModal)}>
                                    {localize.translate(
                                        t => t.Customer.Compliance.Assessments.AssessmentSections.confirmDeleteLabel
                                    )}
                                </Button>
                            </Row>
                        )}
                    >
                        {this.renderDeleteText()}
                        <Section title={assessmentName} className={this.bem.getElement('section')}>
                            <Column smallSpacing={true}>
                                <Paragraph subtle={true} bold={true}>
                                    {localize.translate(t => t.Customer.Compliance.Assessments.Overview.risks)}
                                </Paragraph>
                                {this.getRiskItems()}
                            </Column>
                            <Column smallSpacing={true}>
                                <Paragraph subtle={true} bold={true}>
                                    {localize.translate(t => t.Customer.Compliance.Assessments.Overview.controls)}
                                </Paragraph>
                                {this.getControlItems()}
                            </Column>
                        </Section>
                    </Modal>
                )}
            />
        )
    }

    private renderDeleteButton() {
        const { onCloseLoading, handleOnClose } = this.props

        const deleteText = localize.translate(
            t => t.Customer.Compliance.Assessments.AssessmentSections.deleteSectionTooltipMessage
        )

        return (
            <Tooltip message={deleteText}>
                <Button
                    type={ButtonType.noStyling}
                    loading={onCloseLoading}
                    icon={IconType.close}
                    onClick={() => handleOnClose()}
                    className={this.bem.getElement('button')}
                />
            </Tooltip>
        )
    }

    private renderDeleteText() {
        const { assessmentName } = this.props

        const deleteMessagePart1 = this.loc(t => t.deleteSectionMessagePart1)
        const deleteMessagePart2 = this.loc(t => t.deleteSectionMessagePart2)
        const deleteMessagePart3 = this.loc(t => t.deleteSectionMessagePart3)
        const deleteMessageSection = this.loc(t => t.section)

        return (
            <Paragraph>
                {deleteMessagePart1}
                <strong>{deleteMessageSection}</strong>
                {deleteMessagePart2}
                <strong>{assessmentName}</strong>
                {deleteMessagePart3}
            </Paragraph>
        )
    }

    private handleDelete(closeModal: () => void) {
        const { handleOnClose } = this.props

        handleOnClose()
        closeModal()
    }

    private getRiskItems() {
        const {
            assessmentSection: { risks },
        } = this.props

        if (!risks || !risks.length) {
            return null
        }

        return risks.map(risk => (
            <LinkedRiskCard
                key={`risk-card-${risk.id}`}
                cardOptions={{
                    name: risk.name,
                    riskStatus: risk.status || undefined,
                    severity: risk.severity || risk.severityBruto || undefined,
                    linkedDescription: risk.description || undefined,
                }}
                textOverflow={true}
            />
        ))
    }

    private getControlItems() {
        const {
            assessmentSection: { controls },
        } = this.props

        if (!controls || !controls.length) {
            return null
        }

        return controls.map(control => (
            <LinkedControlCard
                key={`control-card-${control.id}`}
                icon={getIconTypeForControlType(control.type!)}
                label={control.name}
                revisionDate={control.revisionDate || null}
                textOverflow={true}
                description={control.description || undefined}
            />
        ))
    }
}
