import React from 'react'
import { routes } from '~/views/routes'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { Page } from '~/components/Core/Layout/Page'
import { CustomerDetailView } from './CustomerDetailView'
import { CustomerDetailEditView } from './CustomerDetailEditView'
import { ConsultantOverviewView } from '../Consultant/ConsultantOverviewView'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import gql from 'graphql-tag'
import { LoginToEnvironmentButton } from '~/components/Chrome/LoginToEnvironmentButton/LoginToEnvironmentButton'
import { PageNavigation } from '~/components/Core/Layout/PageNavigation'
import { breadcrumbs } from '~/views/breadcrumbs'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Routes, Route, Navigate } from 'react-router-dom'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{ id?: string }> {}

const CUSTOMER_QUERY = gql`
    query customer($id: Int!) {
        customer(id: $id) {
            id
            name
            slug
        }
    }
`

class CustomerDetailOverviewViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery query={CUSTOMER_QUERY} variables={{ id }}>
                {customer => {
                    const navlinks = [
                        {
                            label: 'Gegevens',
                            route: routes.consultant.customers.profile(customer.id),
                        },
                        {
                            label: 'Consultants',
                            route: routes.consultant.customers.consultants(customer.id),
                        },
                    ]

                    return (
                        <Page>
                            <PageNavigation
                                links={navlinks}
                                renderAction={() => <LoginToEnvironmentButton slug={customer.slug} />}
                                breadCrumbs={[breadcrumbs.consultant().customers.index]}
                            />
                            <Routes>
                                <Route
                                    path={newRoutes.consultant.customers.profile()}
                                    element={<CustomerDetailView />}
                                />
                                <Route
                                    path={newRoutes.consultant.customers.edit()}
                                    element={<CustomerDetailEditView />}
                                />
                                <Route
                                    path={newRoutes.consultant.customers.consultants()}
                                    element={<ConsultantOverviewView />}
                                />
                                <Route
                                    path={routes.consultant.customers.view()}
                                    element={<Navigate replace to={routes.consultant.customers.profile()} />}
                                />
                                <Route element={<AppNotFoundView />} />
                            </Routes>
                        </Page>
                    )
                }}
            </PageQuery>
        )
    }
}

export const CustomerDetailOverviewView = withRouter(CustomerDetailOverviewViewComponent)
