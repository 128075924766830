import './CustomerSwitch.scss'

import React from 'react'
import { Footer } from '~/components/Chrome/Footer/Footer'
import { Column } from '~/components/Core/Layout/Column'
import { BEM } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { localize, sessionClient, userClient } from '~/bootstrap'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { GQLMutation } from '~/graphql/Mutation'
import { SwitchCustomerDocument, SwitchCustomerMutationVariables, SwitchCustomerMutation } from '~/generated/graphql'
import { MutationFn } from 'react-apollo'
import { sortBy } from 'lodash-es'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { UserEmployeeWithCustomer } from '~/graphql/queries/CurrentUserQuery'
import { CSSTransition } from 'react-transition-group'
import { animations } from '~/animations'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface CustomerGroupWithEmployees {
    employees: UserEmployeeWithCustomer[]
    customerGroup: {
        id: number
        name: string
    } | null
}

interface Props extends RouteComponentProps<{ customer: string }> {}

type SwitchCustomerMutationFN = MutationFn<SwitchCustomerMutation, SwitchCustomerMutationVariables>

class CustomerSwitchComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = UserContext
    public context: UserContextValue

    private bem = new BEM('CustomerSwitch')

    private loc = localize.namespaceTranslate(t => t.Customer.CustomerSwitch)

    public render() {
        return (
            <GQLMutation<SwitchCustomerMutation, SwitchCustomerMutationVariables> mutation={SwitchCustomerDocument}>
                {(mutate, { loading }) => (
                    <div className={this.bem.getClassName()}>
                        <CSSTransition appear={true} in={true} timeout={500} classNames={animations.fadeIn}>
                            <Column extraBigSpacing={true} className={this.bem.getElement('container')}>
                                {this.renderHeader()}
                                {this.renderCustomers(mutate, loading)}
                                {this.renderLogoutButton(loading)}
                            </Column>
                        </CSSTransition>
                        <Footer />
                    </div>
                )}
            </GQLMutation>
        )
    }

    private renderHeader() {
        const fullName = this.context.user?.profile.fullName || ''

        return (
            <Column noSpacing={true}>
                <SectionTitle emphasis={true} larger={true} subtle={true}>
                    {this.loc(t => t.welcome)}
                </SectionTitle>
                <PageTitle title={fullName} largerTitle={true} className={this.bem.getElement('name')} />
            </Column>
        )
    }

    private renderCustomers(mutate: SwitchCustomerMutationFN, loading: boolean) {
        const userEmployees = this.context?.userEmployees || []

        const sortedCustomerGroups = this.getSortedCustomerGroups(userEmployees)
        const hasMultiCustomerGroup = sortedCustomerGroups.length > 1

        const customers = sortedCustomerGroups.flatMap(g =>
            g.employees.map(this.renderCustomerButton(mutate, loading, hasMultiCustomerGroup))
        )

        return (
            <Column>
                <Paragraph bold={true}>{this.loc(t => t.selectCustomer)}</Paragraph>
                <Column smallSpacing={true} className={this.bem.getElement('customers')}>
                    {customers}
                </Column>
            </Column>
        )
    }

    private renderLogoutButton(loading: boolean) {
        return (
            <Button type={ButtonType.subtle} disabled={loading} onClick={userClient.logout} icon={IconType.logOut}>
                {localize.translate(t => t.User.CurrentUser.SignOut)}
            </Button>
        )
    }

    private getSortedCustomerGroups(userEmployees: UserEmployeeWithCustomer[]) {
        const customerGroupsWithEmployees: CustomerGroupWithEmployees[] = []

        for (const employee of userEmployees) {
            const group = customerGroupsWithEmployees.find(
                g => g.customerGroup?.id === employee.customer.customerGroup?.id
            )
            if (!group) {
                customerGroupsWithEmployees.push({
                    customerGroup: employee.customer.customerGroup || null,
                    employees: [employee],
                })

                continue
            }

            group.employees.push(employee)
        }

        const sortedGroups = sortBy(customerGroupsWithEmployees, g => g.customerGroup?.name)
        sortedGroups.forEach(group => {
            group.employees = sortBy(group.employees, e => e.customer.name)
        })

        return sortedGroups
    }

    private renderCustomerButton =
        (mutate: SwitchCustomerMutationFN, loading: boolean, hasMultiCustomerGroup: boolean) =>
        (employee: UserEmployeeWithCustomer) => {
            const { id, name, slug } = employee.customer

            const buttonText =
                hasMultiCustomerGroup && employee.customer.customerGroup?.name
                    ? `${employee.customer.customerGroup.name} - ${employee.customer.name}`
                    : name

            return (
                <Button
                    key={`customer-${id}`}
                    iconRight={IconType.nextArrow}
                    type={ButtonType.noStyling}
                    onClick={this.handleSwitch(mutate, employee.id, slug)}
                    className={this.bem.getElement('button')}
                    disabled={loading}
                >
                    <Paragraph>{buttonText}</Paragraph>
                </Button>
            )
        }

    private handleSwitch = (mutate: SwitchCustomerMutationFN, employeeId: number, customerSlug: string) => async () => {
        const session = sessionClient.getSession()

        if (!session) {
            return
        }

        const { token } = session

        const response = await mutate({ variables: { employeeId, token } })

        if (response && response.data?.switchEmployee) {
            this.context.refetch()
            this.props.history.push(routes.customer(customerSlug).dashboard.index)
        }
    }
}

export const CustomerSwitch = withRouter(CustomerSwitchComponent)
