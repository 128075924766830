import './RiskGraphTrendLine.scss'

import React from 'react'
import { debounce } from 'lodash-es'

import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    spacingX: number
    spacingY: number
    curve: number
}

interface State {
    boundingBox: ClientRect | DOMRect | undefined
}

export class RiskGraphTrendLine extends React.Component<React.PropsWithChildren<Props>, State> {
    public static defaultProps: Partial<Props> = {
        spacingX: 24,
        spacingY: 20,
        curve: 0.2,
    }

    public state: State = {
        boundingBox: undefined,
    }

    private bem = new BEM('RiskGraphTrendLine')

    private ref = React.createRef<SVGSVGElement>()

    public componentDidMount() {
        this.setBoundingbox()
        window.addEventListener('resize', debounce(this.onResize, 150))
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    public render() {
        const { className } = this.props

        return (
            <svg ref={this.ref} className={this.bem.getClassName(className)}>
                <path d={this.getSVGCommands()} stroke="#BFBFC2" strokeDasharray="2" fill="transparent" />
            </svg>
        )
    }

    private setBoundingbox() {
        if (!this.ref || !this.ref.current) {
            return
        }

        this.setState({
            boundingBox: this.ref.current.getBoundingClientRect(),
        })
    }

    private getSVGCommands() {
        const { spacingX, spacingY, curve } = this.props
        const { boundingBox } = this.state

        if (!boundingBox) {
            return
        }

        const moveX = spacingX
        const moveY = spacingY
        const startX = spacingX
        const startY = spacingY
        const endX = boundingBox.width - spacingX
        const endY = boundingBox.height - spacingY
        const curveEndX = endX * curve
        const curveEndY = endY

        const command = `M ${moveX}, ${moveY} C ${startX}, ${startY} ${curveEndX}, ${curveEndY} ${endX}, ${endY}`

        return command
    }

    private onResize = () => {
        this.setBoundingbox()
    }
}
