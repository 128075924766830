import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { MonitoringReportsQueryType } from '../MonitoringReportsTable/MonitoringReportsQuery'
import {
    CreateMonitoringReportMutationFN,
    MonitoringReportCreateMutation,
} from '../MonitoringReportsCreateForm/MonitoringReportsCreateMutation'
import {
    CheckedMonitoringReportTopics,
    MonitoringReportsCreateFormModal,
} from '../MonitoringReportsCreateForm/MonitoringReportsCreateFormModal'
import { FormState } from '~/components/Core/DataEntry/Form/Form'
import { localize, notification } from '~/bootstrap'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { readAndSanitizeFile } from '~/utils/fileSanitizer'

interface Props {
    requestClose?: () => void
    onSubmit?: (monitoringReport: MonitoringReportsQueryType) => void
}

interface State {
    validFileErrorMessage: string | null
}

export class AddLinkedMonitoringReportModal extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue
    state: State = {
        validFileErrorMessage: null,
    }
    public render() {
        const { requestClose } = this.props
        const { validFileErrorMessage } = this.state

        return (
            <MonitoringReportCreateMutation>
                {(createMonitoringReport, { loading }) => (
                    <>
                        <MonitoringReportsCreateFormModal
                            loading={loading}
                            onSubmit={this.handleOnSubmit(createMonitoringReport)}
                            onRequestClose={requestClose}
                        />
                        {validFileErrorMessage && <ErrorMessage message={validFileErrorMessage} />}
                    </>
                )}
            </MonitoringReportCreateMutation>
        )
    }

    private handleOnSubmit =
        (createMonitoringReport: CreateMonitoringReportMutationFN) =>
        async (state: FormState, checkedDepartmentTopics: CheckedMonitoringReportTopics[]) => {
            const { requestClose, onSubmit } = this.props
            const { name, file, assessedAt } = state

            const isValidFile = file ? await readAndSanitizeFile(file) : true

            if (isValidFile) {
                const response = await createMonitoringReport({
                    variables: {
                        fields: {
                            name,
                            file,
                            assessedAt: assessedAt || new Date(),
                            checkedDepartmentTopics,
                        },
                    },
                })

                if (requestClose && !state.error) {
                    notification.success(
                        localize.translate(t => t.Customer.MonitoringView.AddMonitoringRapportModal.successMessage)
                    )
                    requestClose()
                }

                if (response && response.data && response.data.createMonitoringReport) {
                    if (onSubmit) {
                        onSubmit({ id: response.data.createMonitoringReport.id, name } as any)
                    }
                }
            } else {
                this.setState({ validFileErrorMessage: localize.translate(t => t.Errors.invalidFileError) })
            }
        }
}
