import React from 'react'
import { MutationResult } from 'react-apollo'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { FieldSet } from '~/components/Core/DataEntry/Form/FieldSet'
import { Form, FormState } from '~/components/Core/DataEntry/Form/Form'
import { ActionBar } from '~/components/Core/Layout/ActionBar'
import { Row } from '~/components/Core/Layout/Row'
import { Section } from '~/components/Core/Layout/Section'
import { MonitoringReportDetailType } from '~/components/Domain/Monitoring/MonitoringReportsDetail/MonitoringReportQuery'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { routes } from '~/views/routes'

interface Props {
    onSubmit: (formState: FormState) => void
    monitoringReport: MonitoringReportDetailType
    editOptions: MutationResult
}

interface State {
    title: string | null
}

export class MonitoringReportEditForm extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        title: null,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.MonitoringView.MonitoringEditView)
    private genericLoc = localize.namespaceTranslate(t => t.Generic)

    public render() {
        const { onSubmit, monitoringReport } = this.props
        const { file } = monitoringReport

        return (
            <Form onSubmit={onSubmit}>
                <Section title={this.loc(t => t.general)}>
                    <FieldSet>
                        <Field
                            labelString={this.loc(t => t.Fields.title)}
                            label={this.loc(t => t.Fields.title)}
                            forInput={'name'}
                        >
                            <Form.Input name={'name'} defaultValue={monitoringReport.name} />
                        </Field>
                        <Field
                            labelString={this.loc(t => t.Fields.document)}
                            label={this.loc(t => t.Fields.document)}
                            forInput={'file'}
                        >
                            <Form.FileInput
                                name={'file'}
                                defaultFile={
                                    (file && {
                                        filename: file.name,
                                        id: file.id,
                                    }) ||
                                    undefined
                                }
                            />
                        </Field>
                    </FieldSet>
                </Section>
                {this.renderActionBar()}
            </Form>
        )
    }

    private renderActionBar() {
        const { monitoringReport, editOptions } = this.props

        return (
            <ActionBar>
                <Row alignRight spaceBetween={true}>
                    <Row>
                        <Button
                            to={routes
                                .customer(this.context.customer.slug)
                                .monitoring.reports.detail.view(monitoringReport.id)}
                            type={ButtonType.tertiary}
                        >
                            {this.genericLoc(t => t.cancel)}
                        </Button>
                        <Button loading={editOptions.loading} submit={true}>
                            {this.genericLoc(t => t.save)}
                        </Button>
                    </Row>
                </Row>
            </ActionBar>
        )
    }
}
