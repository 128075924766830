import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Column } from '~/components/Core/Layout/Column'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { SectionTitle } from '~/components/Core/Text/SectionTitle'
import { CustomerGroupCustomersTableContainer } from '~/components/Domain/Consultants/CustomerGroup/CustomerGroupCustomersTableContainer'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { CustomerGroupDocument, CustomerGroupQueryVariables, CustomerGroupQuery } from '~/generated/graphql'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { breadcrumbs } from '~/views/breadcrumbs'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{ id?: string }> {}

class CustomerGroupDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    private loc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CustomerGroupDetailView)
    private overviewLoc = localize.namespaceTranslate(t => t.Consultant.CustomerGroupsView.CustomerGroupsOverviewView)

    public render() {
        const { id } = this.props.match.params

        if (!id) {
            return null
        }

        const pageBreadcrumbs = [breadcrumbs.consultant().customerGroups.index]

        return (
            <PageQuery<CustomerGroupQuery['customerGroup'], CustomerGroupQueryVariables>
                query={CustomerGroupDocument}
                variables={{ id: parseInt(id, 10) }}
            >
                {customerGroup => {
                    if (!customerGroup) {
                        return null
                    }

                    return (
                        <Page>
                            <PageHeader title={customerGroup.name} breadCrumbs={pageBreadcrumbs} />
                            <Column extraBigSpacing={true}>
                                {this.renderDetails(customerGroup)}
                                <CustomerGroupCustomersTableContainer
                                    customerGroupId={customerGroup.id}
                                    customerGroupName={customerGroup.name}
                                />
                            </Column>
                        </Page>
                    )
                }}
            </PageQuery>
        )
    }

    private renderDetails(customerGroup: CustomerGroupQuery['customerGroup']) {
        const { id, name, ssoLoginEnabled, totalUserCount, createUserOnSSOLogin } = customerGroup!

        const ssoFieldValue = localize.translate(t => (ssoLoginEnabled ? t.Generic.enabled : t.Generic.disabled))

        return (
            <Column bigSpacing={true}>
                <Column>
                    <SectionTitle>{this.loc(t => t.data)}</SectionTitle>
                    <Field label={this.overviewLoc(t => t.columns.name)}>{name}</Field>
                    <Field label={localize.translate(t => t.User.attributes.ssoLoginEnabled)}>{ssoFieldValue}</Field>
                    {ssoLoginEnabled && (
                        <Field label={localize.translate(t => t.Customer.Attributes.createUserOnSSOLogin)}>
                            {localize.translate(t => (createUserOnSSOLogin ? t.Generic.yes : t.Generic.no))}
                        </Field>
                    )}
                    <Field label={this.overviewLoc(t => t.columns.employeeCount)}>{totalUserCount}</Field>
                </Column>
                <Button type={ButtonType.secondary} to={routes.consultant.customerGroups.detail(id).edit}>
                    {this.loc(t => t.editData)}
                </Button>
            </Column>
        )
    }
}

export const CustomerGroupDetailView = withRouter(CustomerGroupDetailViewComponent)
