import './MonitoringAgendaView.scss'

import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { Search } from '~/components/Core/DataEntry/Search/Search'
import { Row } from '~/components/Core/Layout/Row'
import { Page } from '~/components/Core/Layout/Page'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { TaskStatusFilter } from '~/components/Domain/Task/TaskStatusFilter'
import { TaskAssigneesFilter } from '~/components/Domain/Task/TaskAssigneesFilter'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Button } from '~/components/Core/Button/Button'
import { CustomerContextValue, CustomerContext } from '~/components/Providers/CustomerProvider'
import { CreateTaskModalContainer } from '~/components/Domain/Task/CreateTaskModalContainer'
import { MonitoringTasksTableContainer } from './MonitoringTasksTableContainer'
import { TaskTagsSelect } from '~/components/Domain/Task/TaskTagsSelect'
import { FilterOption } from '~/components/Core/DataDisplay/FilterButton/FilterOption'
import { FilterDropdown } from '~/components/Core/DataDisplay/FilterButton/FilterDropdown'
import { breadcrumbs } from '~/views/breadcrumbs'
import { ParamManager } from '~/components/Core/ParamManager/ParamManager'
import { SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { Guard } from '~/components/Core/Guard/Guard'
import { TaskDepartmentFilter } from '~/components/Domain/Task/TaskDepartmentFilter'
import { BEM } from '~/services/BEMService'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface Props extends RouteComponentProps<{}> {}

interface State {}

interface Filters {
    search?: string
    forEmployeeIds?: number[]
    completedOnly?: boolean
    tagIds?: SelectOption<number>[]
    activeDepartmentOnly?: boolean
}

class MonitoringAgendaViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        filters: {
            completedOnly: false,
            forEmployeeIds: this.context.employee ? [this.context.employee.id] : [],
        },
    }

    private bem = new BEM('MonitoringAgendaView')
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks)
    private refetchFunctionOnCreate: (() => void)[] = []

    public render() {
        return (
            <Page contained={true} className={this.bem.getClassName()}>
                <ParamManager<Filters>
                    defaultState={{
                        tagIds: [],
                        completedOnly: false,
                        forEmployeeIds: this.context.employee ? [this.context.employee.id] : [],
                        activeDepartmentOnly: true,
                    }}
                >
                    {({ paramState, setParamState }) => {
                        const mappedFilters = {
                            ...paramState,
                            tagIds: paramState.tagIds?.map(tag => tag.value),
                        }

                        return (
                            <>
                                <PageHeader
                                    title={this.loc(t => t.monitoringAgenda)}
                                    actionComponent={this.renderSearchAndFilters(paramState, setParamState)}
                                    breadCrumbs={[breadcrumbs.customer(this.context.customer.slug).monitoring.index]}
                                />
                                <MonitoringTasksTableContainer
                                    filters={mappedFilters}
                                    setRefetchFunction={refetch => this.setRefetchFunction(refetch)}
                                    className={this.bem.getElement('table-container')}
                                />
                            </>
                        )
                    }}
                </ParamManager>
            </Page>
        )
    }

    private isFilterActive(paramState: Filters) {
        if (!paramState.forEmployeeIds || paramState.forEmployeeIds.length === 0) {
            return true
        }

        if (paramState.completedOnly !== false) {
            return true
        }

        if (paramState.tagIds && paramState.tagIds.length > 0) {
            return true
        }

        if (!paramState.activeDepartmentOnly) {
            return true
        }

        return false
    }

    private renderSearchAndFilters(paramState: Filters, setParamState: (newState: Filters) => void) {
        return (
            <Row>
                <Search
                    onChange={search =>
                        setParamState({
                            search: search || undefined,
                        })
                    }
                    placeholder={this.loc(t => t.taskSearchPlaceholder)}
                    defaultValue={paramState.search}
                />
                <FilterDropdown hasActiveFilter={this.isFilterActive(paramState)}>
                    <FilterOption label={this.loc(t => t.filters.taskEmployees)} forInputName="taskEmployeesFilter">
                        <TaskAssigneesFilter
                            defaultValue={paramState.forEmployeeIds ? 'ownTasks' : 'everyonesTasks'}
                            onChange={option => {
                                if (!option) {
                                    setParamState({
                                        forEmployeeIds: undefined,
                                    })
                                    return
                                }

                                if (option.value === 'ownTasks') {
                                    setParamState({
                                        forEmployeeIds: this.context.employee ? [this.context.employee.id] : undefined,
                                    })
                                }

                                if (option.value === 'everyonesTasks') {
                                    setParamState({
                                        forEmployeeIds: undefined,
                                    })
                                }
                            }}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.taskStatus)} forInputName="taskStatusFilter">
                        <TaskStatusFilter
                            defaultValue={paramState.completedOnly ? 'isCompleted' : 'isOpen'}
                            onChange={option => {
                                const filterByIsCompleted = (option && option.value === 'isCompleted') || false

                                setParamState({
                                    completedOnly: filterByIsCompleted,
                                })
                            }}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.taskTags)} forInputName="taskTags">
                        <TaskTagsSelect
                            isFilter={true}
                            name={'taskTags'}
                            defaultValue={paramState.tagIds}
                            onChange={options => {
                                setParamState({
                                    tagIds: options || [],
                                })
                            }}
                        />
                    </FilterOption>
                    <FilterOption label={this.loc(t => t.filters.taskDepartments)} forInputName="taskDepartmentFilter">
                        <TaskDepartmentFilter
                            defaultValue={paramState.activeDepartmentOnly ? 'true' : 'false'}
                            onChange={option => setParamState({ activeDepartmentOnly: option?.value === 'true' })}
                        />
                    </FilterOption>
                </FilterDropdown>
                <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                    <ModalManager
                        render={openModal => (
                            <Button icon={IconType.taskAdd} onClick={openModal} rounded={true}>
                                {this.loc(t => t.newGenericTaskButton)}
                            </Button>
                        )}
                        renderModal={closeModal => (
                            <CreateTaskModalContainer
                                forMonitoring={true}
                                onCreated={() => this.onCreateNewTask()}
                                requestClose={closeModal}
                            />
                        )}
                    />
                </Guard>
            </Row>
        )
    }

    private onCreateNewTask() {
        this.refetchFunctionOnCreate.forEach(refetch => refetch())
    }

    private setRefetchFunction(refetch: () => void) {
        this.refetchFunctionOnCreate = [...this.refetchFunctionOnCreate, refetch]
    }
}

export const MonitoringAgendaView = withRouter(MonitoringAgendaViewComponent)
