import './TextEditor.scss'
import React, { Component } from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import { Bold } from '@ckeditor/ckeditor5-basic-styles'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Italic } from '@ckeditor/ckeditor5-basic-styles'
import { List } from '@ckeditor/ckeditor5-list'
import { ListProperties } from '@ckeditor/ckeditor5-list'
import { Link } from '@ckeditor/ckeditor5-link'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { Subscript } from '@ckeditor/ckeditor5-basic-styles'
import { Superscript } from '@ckeditor/ckeditor5-basic-styles'
import { Underline } from '@ckeditor/ckeditor5-basic-styles'
import { localize } from '~/bootstrap'
import { Table } from '@ckeditor/ckeditor5-table'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { marked } from 'marked'

interface Props {
    className?: ClassValue
    onChange?: (value: string | null, name: string) => void
    name: string
    defaultValue?: string | null
    placeholder?: string
}

interface State {
    editorState: string
}

export class TextEditor extends Component<React.PropsWithChildren<Props>, State> {
    public state: State = {
        editorState: marked(this.props.defaultValue || ''),
    }

    private bem = new BEM('TextEditor')
    private builtinPlugins = [
        Bold,
        Essentials,
        Italic,
        List,
        Paragraph,
        Subscript,
        Superscript,
        Underline,
        Table,
        ListProperties,
        Autoformat,
        Link,
    ]
    private toolbarItems = [
        'bold',
        'italic',
        'underline',
        'subscript',
        'superscript',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'link',
    ]

    public render() {
        const { className, placeholder } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.editorState}
                    onBlur={(_event: unknown, editor: any) => {
                        this.handleChange(editor.getData())
                    }}
                    config={{
                        toolbar: { items: this.toolbarItems },
                        // to change plugin labels, use window.CKEDITOR_TRANSLATIONS.<language>.dictionary.<plugin>
                        // i.e. window.CKEDITOR_TRANSLATIONS.nl.dictionary.Superscript = 'Some label'
                        language: localize.getCurrentLocale(),
                        link: {
                            // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                            addTargetToExternalLinks: true,
                        },
                        placeholder,
                        plugins: this.builtinPlugins as any,
                    }}
                />
            </div>
        )
    }

    private handleChange = (editorState: string) => {
        const { name, onChange } = this.props

        this.setState({ editorState })

        if (onChange) {
            return onChange(editorState !== '' ? editorState : null, name)
        }
    }
}
