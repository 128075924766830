import './MonitoringReportTopicSelectionTable.scss'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import {
    MultiDepthExpandableTable,
    MultiDepthTableRowItem,
} from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTable'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { IconType } from '~/components/Core/Icon/IconType'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { TopicPreviewModal } from '~/components/Domain/Topic/TopicPreviewModal'
import { BEM } from '~/services/BEMService'
import { MonitoringReportTopicsQueryType } from './MonitoringReportTopicsQuery'

interface Props {
    shownDepartmentCount: number
    currentPage: number
    departments: MonitoringReportTopicsQueryType
    onChange: (checkedDepartmentTopics: CheckedDepartmentTopics) => void
    allDepartmentTopics: Record<number, Record<number, Topic[]>>
    expandedIds?: number[]
}

interface State {
    checkedDepartmentTopics: CheckedDepartmentTopics
}

// [departmentId]: { [themeId]: topicId[] }
export type CheckedDepartmentTopics = Record<number, Record<number, number[]>>
type ThemeWithTopics = NonNullable<MonitoringReportTopicsQueryType[0]['themesWithTopics']>[0]
type Topic = NonNullable<ThemeWithTopics['topics']>[0]

interface GroupedAndSortedThemeTopics {
    id: number
    name: string
    topics: {
        id: number
        name: string
    }[]
}

type GroupedThemeTopic = GroupedAndSortedThemeTopics['topics'][0]

export class MonitoringReportTopicSelectionTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        checkedDepartmentTopics: {},
    }

    private bem = new BEM('MonitoringReportTopicSelectionTable')
    private tableRef = React.createRef<MultiDepthExpandableTable>()

    public render() {
        return (
            <div className={this.bem.getClassName()}>
                <MultiDepthExpandableTable
                    ref={this.tableRef}
                    className={this.bem.getElement('table')}
                    rowItems={this.getRowItems()}
                    maxDepth={1}
                    expandedRowsIds={this.props.expandedIds}
                />
            </div>
        )
    }

    public scrollToRow(id: number) {
        this.tableRef.current?.scrollToRow(id)
    }

    private getRowItems(): MultiDepthTableRowItem[] {
        const currentDepartments = this.getCurrentDepartments()
        const sortedThemes = this.getSortedThemes()

        return sortedThemes.map(theme => {
            return {
                id: theme.id,
                name: theme.name,
                yIndex: 0,
                render: () => this.renderThemeRow(theme, currentDepartments),
                children: theme.topics?.map(topic => ({
                    id: topic.id,
                    name: topic.name,
                    yIndex: 1,
                    render: () => this.renderTopicRow(theme.id, topic, currentDepartments),
                })),
            }
        })
    }

    private getSortedThemes(): GroupedAndSortedThemeTopics[] {
        const themes: GroupedAndSortedThemeTopics[] = []

        this.props.departments.forEach(d =>
            d.themesWithTopics?.forEach(
                t => !themes.some(tt => tt.id === t.id) && themes.push({ id: t.id, name: t.name, topics: [] })
            )
        )

        this.props.departments.forEach(d =>
            d.themesWithTopics?.forEach(theme => {
                if (!theme.topics?.length) {
                    return
                }

                const groupedTheme = themes.find(t => t.id === theme.id)
                if (!groupedTheme) {
                    // sanity check
                    throw new Error('Theme not found')
                }

                groupedTheme.topics.push(...theme.topics.map(t => ({ id: t.id, name: t.name })))
            })
        )

        themes.forEach(t => (t.topics = uniqBy(t.topics, 'id')))
        themes.forEach(t => t.topics.sort((a, b) => a.name.localeCompare(b.name)))
        themes.sort((a, b) => a.name.localeCompare(b.name))

        return themes
    }

    private getCurrentDepartments() {
        const { currentPage, shownDepartmentCount, departments } = this.props
        const startIndex = currentPage * shownDepartmentCount
        const endIndex = startIndex + shownDepartmentCount

        return departments.slice(startIndex, endIndex)
    }

    private renderThemeRow(theme: GroupedAndSortedThemeTopics, currentDepartments: Props['departments']) {
        return (
            <Row spaceBetween noSpacing className={this.bem.getElement('theme-row')}>
                <Tooltip message={theme.name} className={this.bem.getElement('label-container')}>
                    <Paragraph bold truncateEllipsis>
                        {theme.name}
                    </Paragraph>
                </Tooltip>
                <Row noSpacing className={this.bem.getElement('checkbox-list')}>
                    {this.renderThemeCheckboxes(theme, currentDepartments)}
                </Row>
            </Row>
        )
    }

    private renderTopicRow(themeId: number, topic: GroupedThemeTopic, currentDepartments: Props['departments']) {
        return (
            <Row spaceBetween noSpacing className={this.bem.getElement('topic-row')}>
                {this.renderTopicName(topic.id, topic.name)}
                <Row noSpacing className={this.bem.getElement('checkbox-list')}>
                    {this.renderTopicCheckboxes(themeId, topic, currentDepartments)}
                </Row>
            </Row>
        )
    }

    private renderThemeCheckboxes(theme: GroupedAndSortedThemeTopics, currentDepartments: Props['departments']) {
        const { allDepartmentTopics } = this.props

        return currentDepartments.map(d => {
            const departmentTheme = allDepartmentTopics[d.id]?.[theme.id]
            if (!departmentTheme?.length) {
                return (
                    <Tooltip disabled key={`${d.id}-${theme.id}`}>
                        <div />
                    </Tooltip>
                )
            }

            const checkedTopicCount = this.state.checkedDepartmentTopics[d.id]?.[theme.id]?.length
            const checked = checkedTopicCount === departmentTheme.length
            const indeterminate = !checked && checkedTopicCount > 0

            return (
                <Checkbox
                    key={`${d.id}-${theme.id}`}
                    large
                    name={`${d.id}-${theme.id}`}
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={checked => this.handleThemeCheck(theme.id, d.id, checked)}
                />
            )
        })
    }

    private renderTopicName(id: number, name: string) {
        return (
            <TopicPreviewModal topicId={id}>
                {open => (
                    <Button type={ButtonType.noStyling} onClick={open}>
                        <Paragraph bold truncateEllipsis>
                            {name}
                        </Paragraph>
                        <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                    </Button>
                )}
            </TopicPreviewModal>
        )
    }

    private renderTopicCheckboxes(themeId: number, topic: GroupedThemeTopic, currentDepartments: Props['departments']) {
        const { allDepartmentTopics } = this.props

        return currentDepartments.map(d => {
            const departmentTopic = allDepartmentTopics[d.id]?.[themeId]?.find(t => t.id === topic.id)
            if (!departmentTopic) {
                return (
                    <Tooltip disabled key={`${d.id}-${themeId}-${topic.id}`}>
                        <div />
                    </Tooltip>
                )
            }

            const checked = this.state.checkedDepartmentTopics[d.id]?.[themeId]?.includes(topic.id)

            return (
                <Checkbox
                    key={`${d.id}-${themeId}-${topic.id}-${checked}`}
                    large
                    name={`${d.id}-${themeId}-${topic.id}`}
                    checked={checked}
                    onChange={checked => this.handleTopicCheck(topic.id, themeId, d.id, checked)}
                />
            )
        })
    }

    private handleThemeCheck(id: number, departmentId: number, checked: boolean) {
        const { allDepartmentTopics, onChange } = this.props
        const { checkedDepartmentTopics } = this.state

        if (checked) {
            const topics = allDepartmentTopics[departmentId][id].map(t => t.id)

            if (!checkedDepartmentTopics[departmentId]) {
                checkedDepartmentTopics[departmentId] = { [id]: topics }
            } else {
                checkedDepartmentTopics[departmentId][id] = topics
            }

            return this.setState({ checkedDepartmentTopics }, () => {
                onChange(checkedDepartmentTopics)
                this.forceUpdate()
            })
        }

        if (checkedDepartmentTopics[departmentId]?.[id]) {
            delete checkedDepartmentTopics[departmentId][id]

            return this.setState({ checkedDepartmentTopics }, () => {
                onChange(checkedDepartmentTopics)
                this.forceUpdate()
            })
        }
    }

    private handleTopicCheck(id: number, themeId: number, departmentId: number, checked: boolean) {
        const { onChange } = this.props
        const { checkedDepartmentTopics } = this.state

        if (checked) {
            if (!checkedDepartmentTopics[departmentId]) {
                checkedDepartmentTopics[departmentId] = { [themeId]: [id] }
            } else if (!checkedDepartmentTopics[departmentId][themeId]) {
                checkedDepartmentTopics[departmentId][themeId] = [id]
            } else if (!checkedDepartmentTopics[departmentId][themeId].includes(id)) {
                checkedDepartmentTopics[departmentId][themeId].push(id)
            } else {
                return
            }

            return this.setState({ checkedDepartmentTopics }, () => {
                onChange(checkedDepartmentTopics)
                this.forceUpdate()
            })
        }

        if (checkedDepartmentTopics[departmentId]?.[themeId]?.includes(id)) {
            checkedDepartmentTopics[departmentId][themeId] = checkedDepartmentTopics[departmentId][themeId].filter(
                t => t !== id
            )

            return this.setState({ checkedDepartmentTopics }, () => {
                onChange(checkedDepartmentTopics)
                this.forceUpdate()
            })
        }
    }
}
