import './PriorityIndicator.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { TaskPriority } from '~/generated/graphql'
import { localize } from '~/bootstrap'
import { Icon } from '~/components/Core/Icon/Icon'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

interface Props {
    className?: ClassValue
    priority: TaskPriority
    isTooltip?: boolean
}

interface State {}

enum PriorityIconName {
    LOW = 'prioLow',
    MEDIUM = 'prioMid',
    HIGH = 'prioHigh',
}

export class PriorityIndicator extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('PriorityIndicator', () => ({
        'is-low': this.props.priority === TaskPriority.low,
        'is-medium': this.props.priority === TaskPriority.medium,
        'is-high': this.props.priority === TaskPriority.high,
        'is-tooltip': !!this.props.isTooltip,
    }))

    private loc = localize.namespaceTranslate(t => t.Customer.Task.priority)

    public render() {
        const { priority, className, isTooltip } = this.props

        if (isTooltip) {
            return (
                <Tooltip distance={7} content={this.loc(t => t[priority])} className={this.bem.getClassName(className)}>
                    <Icon className={this.bem.getElement('icon')} type={IconType[PriorityIconName[priority]]} />
                </Tooltip>
            )
        }

        return (
            <div className={this.bem.getClassName(className)}>
                <Icon className={this.bem.getElement('icon')} type={IconType[PriorityIconName[priority]]} />
                <span className={this.bem.getElement('label')}>{this.loc(t => t[priority])}</span>
            </div>
        )
    }
}
