import './NormsGrid.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Grid } from '~/components/Core/Layout/Grid'

interface Props {
    className?: ClassValue
}

export class NormsGrid extends React.PureComponent<React.PropsWithChildren<Props>> {
    private bem = new BEM('NormsGrid')

    public render() {
        const { children, className } = this.props

        return <Grid className={this.bem.getClassName(className)}>{children}</Grid>
    }
}
