import React from 'react'

import { errorClient, sessionClient, notification, localize } from '~/bootstrap'
import { ErrorType } from '~/services/ErrorService'
import { apolloClient } from '~/services/ApolloService'
import { routes } from './routes'
import gql from 'graphql-tag'
import throttle from 'lodash-es/throttle'
import { LocalStorageKeys, LocalStorageService } from '~/services/LocalStorageService'
import { SessionCustomerDocument, SessionCustomerQuery, SessionCustomerQueryVariables } from '~/generated/graphql'
import { Modal, ModalSize } from '~/components/Core/Feedback/Modal/Modal'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Row } from '~/components/Core/Layout/Row'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { withRouter, RouteComponentProps } from '~/utils/withRouter'

interface RouteParams {
    customer: string
}

interface Props extends RouteComponentProps<RouteParams> {
    isConsultant?: boolean
}

interface State {
    showCustomerSwitchModal: boolean
}

export const SessionManager = withRouter(
    class SessionManager extends React.PureComponent<React.PropsWithChildren<Props>, State> {
        public static contextType = UserContext
        public context: UserContextValue
        public state: State = {
            showCustomerSwitchModal: false,
        }

        private loc = localize.namespaceTranslate(t => t.Customer.SessionManager)
        private refreshSession = throttle(
            () => {
                const session = sessionClient.getSession()

                if (!session) {
                    return
                }

                apolloClient.mutate({
                    mutation: gql`
                        mutation refreshSession($token: String!) {
                            refreshSession(token: $token)
                        }
                    `,
                    variables: {
                        token: session.token,
                    },
                })

                this.trackCustomerSession()
            },
            20000,
            { leading: true, trailing: false }
        ) // ensure we don't spam the endpoint

        public componentDidMount() {
            this.refreshSession()

            document.addEventListener('mousemove', this.refreshSession)
            document.addEventListener('mousedown', this.refreshSession)
            document.addEventListener('keydown', this.refreshSession)

            errorClient.subscribeToType(ErrorType.authentication, this.handleAuthenticationError)

            document.addEventListener('popstate', this.refreshSession) // ¯\_(ツ)_/¯
        }

        public componentWillUnmount() {
            errorClient.unsubscribeToType(ErrorType.authentication, this.handleAuthenticationError)

            document.removeEventListener('mousemove', this.refreshSession)
            document.removeEventListener('mousedown', this.refreshSession)
            document.removeEventListener('keydown', this.refreshSession)

            document.removeEventListener('popstate', this.refreshSession) // ¯\_(ツ)_/¯
        }

        public render() {
            const { children } = this.props

            return (
                <>
                    {this.state.showCustomerSwitchModal && this.renderCustomerSwitchModal()}
                    {children}
                </>
            )
        }

        private trackCustomerSession() {
            if (this.props.isConsultant) {
                return
            }

            const slug = this.context.consultant?.id ? this.props.match.params.customer : undefined
            apolloClient
                .query<SessionCustomerQuery, SessionCustomerQueryVariables>({
                    query: SessionCustomerDocument,
                    variables: { slug },
                })
                .then(r => {
                    if (r.loading) {
                        return
                    }

                    const currentSlug = this.props.match.params.customer
                    const receivedSlug = r.data.customer?.slug

                    if (receivedSlug !== currentSlug && !this.state.showCustomerSwitchModal) {
                        this.setState({ showCustomerSwitchModal: true })
                    } else if (this.state.showCustomerSwitchModal) {
                        this.setState({ showCustomerSwitchModal: false })
                    }
                })
        }

        private handleAuthenticationError = () => {
            LocalStorageService.removeItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)
            sessionClient.removeSession()

            apolloClient.resetStore().then(() => {
                notification.info('Sessie verlopen')
                this.props.history.push(routes.cover.login)
            })
        }

        private renderCustomerSwitchModal() {
            const sessionCustomer = apolloClient.readQuery<SessionCustomerQuery>({
                query: SessionCustomerDocument,
            })?.customer

            if (!sessionCustomer) {
                return null
            }

            return (
                <ModalManager
                    openOnMount={true}
                    renderModal={() => (
                        <Modal
                            modalSize={ModalSize.small}
                            title={this.loc(t => t.switchTitle)}
                            onAction={() => this.props.history.push(routes.customer(sessionCustomer.slug).index)}
                        >
                            <Row>
                                <Paragraph>
                                    {this.loc(t => t.switchDescription1)}
                                    <Paragraph inline bold>
                                        {this.props.match.params.customer}
                                    </Paragraph>
                                    {this.loc(t => t.switchDescription2)}
                                    <Paragraph inline bold>
                                        {sessionCustomer.name}
                                    </Paragraph>
                                    {this.loc(t => t.switchDescription3)}
                                </Paragraph>
                            </Row>
                        </Modal>
                    )}
                />
            )
        }
    }
)
