import React from 'react'
import { DateFormat } from '~/components/Core/Date/DateFormat'
import { localize } from '~/bootstrap'
import { PageDetailContent } from '~/components/Core/Layout/PageDetail/PageDetailContent'
import { PageDetailMeta, PageDetailMetaItem } from '~/components/Core/Layout/PageDetail/PageDetailMeta'
import gql from 'graphql-tag'
import { PageDetail } from '~/components/Core/Layout/PageDetail/PageDetail'
import { Markdown } from '~/components/Core/Text/Markdown'
import { CustomerFramework, CustomerFrameworkFragment } from '~/graphql/types/CustomerFramework'
import { PageQuery } from '~/components/Domain/PageQuery/PageQuery'
import { GroupedLinkedItemsLists } from '~/components/Domain/LinkedList/GroupedLinkedItemsLists'
import { GroupedItem, LinkedItemsFields } from '~/graphql/types/LinkedItem'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { Query } from 'react-apollo'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { breadcrumbs } from '~/views/breadcrumbs'
import { PageHeader } from '~/components/Core/Layout/PageHeader'
import { ConsultationDetailActionButtons } from '~/components/Domain/Consultations/ConsultationDetailActionButtons'
import { Column } from '~/components/Core/Layout/Column'
import { SidebarTaskWidgetContainer } from '~/components/Domain/Task/SidebarTaskWidget/SidebarTaskWidgetContainer'
import { TaskPriority } from '~/graphql/types/Task'
import { Page } from '~/components/Core/Layout/Page'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'

interface RouteParams {
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {}

interface Consultation {
    __typename: 'ConsultationType'
    id: number
    name: string
    abstract: string
    startsAt: string | null
    endsAt: string | null
    updatedAt: string | null
    source: string
    customerFrameworks: CustomerFramework[]
    nextTask?: {
        id: number
        dueAt?: Date
        priority: TaskPriority
    }
    highestPrioTask?: {
        id: number
        priority: TaskPriority
    }
}

const QUERY = gql`
    query consultation($id: Int!, $customerSlug: String, $departmentId: Int!) {
        consultation(id: $id, customerSlug: $customerSlug) {
            __typename
            id
            name
            abstract
            startsAt
            endsAt
            updatedAt
            source
            customerFrameworks(customerSlug: $customerSlug, departmentId: $departmentId) {
                ...CustomerFrameworkFields
            }
            nextTask(departmentId: $departmentId) {
                id
                dueAt
                priority
            }
            highestPrioTask(departmentId: $departmentId) {
                id
                priority
            }
        }
    }
    ${CustomerFrameworkFragment}
`

const LINKED_ITEMS_QUERY = gql`
    query consultation($id: Int!, $customerSlug: String) {
        consultation(id: $id, customerSlug: $customerSlug) {
            __typename
            id
            linkedItems(customerSlug: $customerSlug) {
                ...LinkedItemsFields
            }
        }
    }
    ${LinkedItemsFields}
`

interface ConsultationLinkedItem {
    consultation?: {
        id: number
        linkedItems: GroupedItem[]
    }
}

class ConsultationDetailViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Consultations)

    public render() {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <PageQuery<Consultation>
                query={QUERY}
                variables={{
                    id: id,
                    customerSlug: this.context.customer.slug,
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {(consultation, { refetch }) => {
                    const { name, abstract } = consultation

                    return (
                        <Page>
                            <PageHeader
                                breadCrumbs={[
                                    breadcrumbs.customer(this.context.customer.slug).legalFramework.index,
                                    breadcrumbs.customer(this.context.customer.slug).legalFramework.consultations.index,
                                ]}
                                title={name}
                                actionButtons={<ConsultationDetailActionButtons consultation={consultation} />}
                            />
                            <PageDetail
                                renderSidebar={() => this.renderSidebar(consultation, () => refetch())}
                                smallBottomSpacing
                            >
                                <Column extraBigSpacing>
                                    <PageDetailMeta items={this.getMetaItems(consultation)} />
                                    <PageDetailContent>
                                        <Markdown source={abstract} />
                                    </PageDetailContent>
                                </Column>
                            </PageDetail>
                        </Page>
                    )
                }}
            </PageQuery>
        )
    }

    private renderSidebar(consultation: Consultation, refetch: () => void) {
        const id = parseInt(this.props.match.params.id!, 10)

        return (
            <>
                <SidebarTaskWidgetContainer linkedItem={consultation} refetch={refetch} />
                <Query<ConsultationLinkedItem>
                    query={LINKED_ITEMS_QUERY}
                    variables={{
                        id,
                        customerSlug: this.context.customer.slug,
                    }}
                >
                    {({ data, loading }) => {
                        const consultation = data && data.consultation ? data.consultation : null

                        if (loading) {
                            return <Spinner />
                        }

                        if (!consultation || consultation.linkedItems.length === 0) {
                            return <Paragraph subtle={true}>{this.loc(t => t.noItemsLinked)}</Paragraph>
                        }

                        return <GroupedLinkedItemsLists linkedItems={consultation.linkedItems} />
                    }}
                </Query>
            </>
        )
    }

    private getMetaItems(consultation: Consultation) {
        const { startsAt, endsAt, source, updatedAt } = consultation
        const items: PageDetailMetaItem[] = []

        if (startsAt) {
            items.push({
                label: localize.translate(t => t.Consultations.Attributes.effectiveFrom),
                value: (
                    <Paragraph>
                        <DateFormat readable={true} noWeekday={true} date={new Date(startsAt)} />
                    </Paragraph>
                ),
            })
        }

        if (endsAt) {
            items.push({
                label: localize.translate(t => t.Consultations.Attributes.endDate),
                value: (
                    <Paragraph>
                        <DateFormat readable={true} noWeekday={true} date={new Date(endsAt)} />
                    </Paragraph>
                ),
            })
        }

        if (updatedAt) {
            items.push({
                label: localize.translate(t => t.Consultations.Attributes.updatedAt),
                value: (
                    <Paragraph>
                        <DateFormat readable={true} noWeekday={true} date={new Date(updatedAt)} />
                    </Paragraph>
                ),
            })
        }

        items.push({
            label: localize.translate(t => t.Consultations.Attributes.source),
            value: <Paragraph>{localize.translate(t => t.Consultation.source[source] ?? source)}</Paragraph>,
        })

        return items
    }
}

export const ConsultationDetailView = withRouter(ConsultationDetailViewComponent)
