import React from 'react'
import { CustomersOverviewView } from './CustomersOverviewView'
import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerDetailOverviewView } from './Customer/CustomerDetailOverviewView'
import { CreateCustomerView } from './Customer/CreateCustomerView'
import { Routes, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'

interface Props extends RouteComponentProps<{}> {}

export const CustomersView = withRouter(
    class CustomersView extends React.PureComponent<React.PropsWithChildren<Props>> {
        public render() {
            return (
                <Routes>
                    <Route path={newRoutes.consultant.customers.create} element={<CreateCustomerView />} />
                    <Route path={''} element={<CustomersOverviewView />} />
                    <Route path={newRoutes.consultant.customers.view()} element={<CustomerDetailOverviewView />} />
                    <Route element={<AppNotFoundView />} />
                </Routes>
            )
        }
    }
)
