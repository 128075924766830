import './InboxInternalAlertsContainer.scss'
import React from 'react'
import { localize } from '~/bootstrap'
import { ItemListSpacer } from '~/components/Core/DataDisplay/ItemListSpacer/ItemListSpacer'
import { PaginatableQuery } from '~/components/Core/Pagination/PaginatableQuery'
import { ComponentTitle } from '~/components/Core/Text/ComponentTitle'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import {
    InboxInternalAlertsDocument,
    InboxInternalAlertsQuery,
    InboxInternalAlertsQueryVariables,
    InternalAlertStatusState,
} from '~/generated/graphql'
import { BEM } from '~/services/BEMService'
import { InboxInternalAlertItem } from './InboxInternalAlertItem'
import { FetchMoreButton } from '~/components/Core/Pagination/FetchMoreButton'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { Table } from '~/components/Core/DataDisplay/Table/Table'

interface Props {
    groupIds?: number[]
    search?: string | null
    refetchInbox: () => void
}

interface FetchMoreButtonArgs {
    canFetchMore: boolean
    loadingMore: boolean
    fetchMore: () => void
    totalCount: number
    currentCount: number
}

export type InboxInternalAlertQueryType = NonNullable<
    NonNullable<InboxInternalAlertsQuery['internalAlerts']>['nodes']
>[0]
export class InboxInternalAlertsContainer extends React.Component<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    private loc = localize.namespaceTranslate(t => t.Customer.Inbox.InboxOverview)
    private bem = new BEM('InboxInternalAlertsContainer')
    public render() {
        const { search, refetchInbox, groupIds } = this.props

        return (
            <div className={this.bem.getElement('container')}>
                <ComponentTitle
                    title={this.loc(t => t.internalAlertTitle)}
                    bold={true}
                    className={this.bem.getElement('title')}
                />
                <PaginatableQuery<InboxInternalAlertQueryType, InboxInternalAlertsQueryVariables>
                    path="internalAlerts"
                    take={3}
                    variables={{
                        departmentId: this.context.activeDepartmentId,
                        filters: {
                            status: InternalAlertStatusState.new,
                            customerFrameworkIds: this.context.activeProfiles,
                            search: search,
                            groupIds,
                        },
                    }}
                    query={InboxInternalAlertsDocument}
                >
                    {({ data, loadingMore, loading, canFetchMore, fetchMore, refetch }) => {
                        const internalAlerts = data && data.nodes ? data.nodes : []

                        if (loading) {
                            return <Spinner />
                        }

                        if (internalAlerts.length === 0) {
                            return <Table data={[]} columns={[]} hideHeaders whiteBackground />
                        }

                        const totalCount = data?.totalCount || 0
                        const currentCount = internalAlerts.length

                        return (
                            <>
                                <ItemListSpacer>
                                    {internalAlerts.map((internalAlert, index) => {
                                        return (
                                            <li key={`${index}-${internalAlert.id}`}>
                                                <InboxInternalAlertItem
                                                    refetch={refetch}
                                                    onAccept={refetchInbox}
                                                    internalAlert={internalAlert}
                                                />
                                            </li>
                                        )
                                    })}
                                </ItemListSpacer>
                                <div className={this.bem.getElement('fetch-more-button-container')}>
                                    {this.renderFetchMoreButton({
                                        canFetchMore,
                                        fetchMore,
                                        loadingMore,
                                        totalCount,
                                        currentCount,
                                    })}
                                </div>
                            </>
                        )
                    }}
                </PaginatableQuery>
            </div>
        )
    }

    private renderFetchMoreButton(args: FetchMoreButtonArgs) {
        const { canFetchMore, fetchMore, totalCount, currentCount, loadingMore } = args

        if (!canFetchMore) {
            return
        }

        const nextItemsCount = this.getLoadMoreCount(currentCount, totalCount)

        const label = this.loc(t => t.showMore, { nextItemsCount, totalCount })

        return <FetchMoreButton label={label} fetchMore={fetchMore} loadingMore={loadingMore} />
    }

    private getLoadMoreCount(nodeCount: number, totalCount: number = 0) {
        const leftOverNodes = totalCount - nodeCount

        if (leftOverNodes <= 0) {
            return 0
        }

        if (leftOverNodes > 3) {
            return 3
        }

        return leftOverNodes
    }
}
