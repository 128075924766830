import './Cover.scss'
import React from 'react'
import { ApolloError } from 'apollo-client'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/Core/Button/Button'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { localize } from '~/bootstrap'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { Mutation, MutationFn } from 'react-apollo'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { PasswordInput } from '~/components/Core/DataEntry/Form/PasswordInput'
import { routes } from '~/views/routes'
import gql from 'graphql-tag'
import { Locale } from '~/i18n/TranslationKeys'
import { Content } from '~/components/Core/Layout/Content'
import { Spinner } from '~/components/Core/Feedback/Spinner/Spinner'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Link, NavLink } from 'react-router-dom'

interface Props extends RouteComponentProps<{ token: string; email: string; language: string }> {}

interface State {
    email: string | null
    password: string | null
    passwordConfirm: string | null
    loading: boolean
    failed: boolean
    success: boolean
    passwordScore: number
}

const REGISTER_USER_MUTATION = gql`
    mutation registerUser($password: String, $email: String, $token: String) {
        registerUser(password: $password, email: $email, token: $token)
    }
`

class RegisterUserComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        email: this.props.match.params.email ? Buffer.from(this.props.match.params.email, 'base64').toString() : null,
        password: null,
        passwordConfirm: null,
        loading: true,
        success: false,
        failed: false,
        passwordScore: 0,
    }

    private loc = localize.namespaceTranslate(t => t.Cover.RegisterUser)

    private bem = new BEM('Cover')

    public async componentDidMount() {
        const locale = (this.props.match.params.language === 'nl' ? 'nl' : 'en') as Locale
        await localize.changeLocale(locale)
        await localize.initialize()
        this.setState({ loading: false })
    }

    public render() {
        return (
            <Mutation<any> mutation={REGISTER_USER_MUTATION}>
                {(mutate, { error }) => (
                    <div className={this.bem.getClassName()}>
                        <form onSubmit={this.submitHandler(mutate)}>
                            <div className={this.bem.getElement('brand')}>
                                <NavLink to={routes.cover.index}>
                                    <Logo />
                                </NavLink>
                            </div>

                            <div className={this.bem.getElement('line')} />

                            {this.renderForm(error)}
                        </form>
                    </div>
                )}
            </Mutation>
        )
    }

    private renderForm(error: ApolloError | undefined) {
        const { loading, success, failed } = this.state
        const { email, token } = this.props.match.params

        if (success) {
            return (
                <>
                    <p className={this.bem.getElement('info')}>{this.loc(t => t.success)}</p>
                    <Link to={routes.cover.login} className={this.bem.getElement('link')}>
                        {this.loc(t => t.link)}
                    </Link>
                </>
            )
        }
        if (loading) {
            return (
                <Content>
                    <Spinner delayed={true} />
                </Content>
            )
        }

        return (
            <>
                <PageTitle title={this.loc(t => t.title)} />

                {(failed || error) && (
                    <>
                        <ErrorMessage message={this.loc(t => t.error)} />
                        <Link to={routes.cover.registerUser(token, email)} className={this.bem.getElement('link')}>
                            {this.loc(t => t.tryAgain)}
                        </Link>
                    </>
                )}

                <Field compact={true} forInput={'email'} label={this.loc(t => t.EmailField.label)}>
                    <Input
                        type="email"
                        name="email"
                        placeholder={this.loc(t => t.EmailField.placeholder)}
                        onChange={email => this.setState({ email })}
                        defaultValue={this.state.email}
                    />
                </Field>

                <Field compact={true} forInput={'password'} label={this.loc(t => t.PasswordField.label)}>
                    <PasswordInput
                        type="password"
                        onStrengthChange={passwordScore => this.setState({ passwordScore })}
                        name="password"
                        placeholder={this.loc(t => t.PasswordField.placeholder)}
                        onChange={password => this.setState({ password })}
                    />
                    <p className={this.bem.getElement('error')}>{this.getErrorLabelForPassword()}</p>
                </Field>

                <Field compact={true} forInput={'password-confirm'} label={this.loc(t => t.PasswordConfirmField.label)}>
                    <Input
                        type="password"
                        name="password-confirm"
                        placeholder={this.loc(t => t.PasswordConfirmField.placeholder)}
                        onChange={passwordConfirm => this.setState({ passwordConfirm })}
                    />
                    <p className={this.bem.getElement('error')}>{this.getErrorLabelForPasswordConfirm()}</p>
                </Field>

                <Button
                    submit={true}
                    className={this.bem.getElement('submit-button')}
                    loading={loading}
                    disabled={!this.canSubmitForm()}
                >
                    {this.loc(t => t.button)}
                </Button>

                <Link to={routes.cover.forgotPassword} className={this.bem.getElement('link')}>
                    {this.loc(t => t.link)}
                </Link>
            </>
        )
    }

    private getErrorLabelForPassword() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 3) {
            return this.loc(t => t.passwordNotStrongEnough)
        }

        return null
    }

    private getErrorLabelForPasswordConfirm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 3) {
            return null
        }

        if (password !== passwordConfirm) {
            return this.loc(t => t.passwordDoesNotMatch)
        }

        return null
    }

    private canSubmitForm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return false
        }

        if (password !== passwordConfirm) {
            return false
        }

        if (passwordScore < 3) {
            return false
        }

        return true
    }

    private submitHandler = (mutate: MutationFn<{ registerUser: boolean }>) => {
        return async (event: React.FormEvent) => {
            event.preventDefault()
            this.setState({ loading: true })

            const { password, passwordConfirm, email } = this.state
            const { token } = this.props.match.params

            if (password !== passwordConfirm) {
                return
            }

            try {
                const result = await mutate({ variables: { password, token, email } })

                if (!result || !result.data || !result.data.registerUser) {
                    this.setState({ failed: true, loading: false })
                    return
                }

                this.setState({ success: true, failed: false, loading: false })
            } catch (err) {
                this.setState({ loading: false, failed: true })
            }
        }
    }
}

export const RegisterUser = withRouter(RegisterUserComponent)
