import './RiskIndicator.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { IconType } from '~/components/Core/Icon/IconType'
import { Icon } from '~/components/Core/Icon/Icon'
import { RiskSeverity } from '~/generated/graphql'

export const enum SeverityLevel {
    low = 'LOW',
    medium = 'MEDIUM',
    high = 'HIGH',
}

interface Props {
    className?: ClassValue
    severity?: SeverityLevel | RiskSeverity | null
    label?: string
    isInactive?: boolean
    forGraph?: boolean
    showAsUnmoved?: boolean
    larger?: boolean
}

interface State {}
export class RiskIndicator extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private bem = new BEM('RiskIndicator', () => ({
        'for-graph': !!this.props.forGraph,
        'show-as-unmoved': !!this.props.showAsUnmoved,
        larger: this.props.larger,
    }))

    public render() {
        const { className, severity, isInactive, label } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div
                    className={this.bem.getElement('indicator', () => ({
                        'is-inactive': !!isInactive,
                        'is-low': severity === SeverityLevel.low,
                        'is-warning': severity === SeverityLevel.medium,
                        'is-critical': severity === SeverityLevel.high,
                    }))}
                >
                    <Icon type={IconType.risk} />
                </div>
                {!!label && <span className={this.bem.getElement('label')}>{label}</span>}
            </div>
        )
    }
}
