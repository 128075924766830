import React from 'react'

import { AppNotFoundView } from '~/views/AppNotFoundView'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlsView } from './Controls/ControlsView'
import { Routes, Route, Navigate } from 'react-router-dom'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from '~/views/newRoutes'
import { routes } from '~/views/routes'

interface Props extends RouteComponentProps<{}> {}

class ComplianceViewComponent extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        return (
            <Routes>
                <Route
                    path="*"
                    element={
                        <Navigate replace to={routes.customer(this.context.customer.slug).compliance.controls.index} />
                    }
                />
                <Route
                    path={newRoutes.customer(this.context.customer.slug).compliance.controls.index}
                    element={<ControlsView />}
                />

                <Route element={<AppNotFoundView />} />
            </Routes>
        )
    }
}

export const ComplianceView = withRouter(ComplianceViewComponent)
