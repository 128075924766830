import './Toolbar.scss'

import React from 'react'

import { localize, permissions } from '~/bootstrap'
import { CreateGenericTaskToolBarItem } from '~/components/Chrome/Navigation/Tool/CreateGenericTaskToolBarItem'
import { Guard } from '~/components/Core/Guard/Guard'
import { Popper, PopperContentItem } from '~/components/Core/Popper/Popper'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM, ClassValue } from '~/services/BEMService'
import { ActiveDepartment } from '../../MainHeader/CustomerDepartments/ActiveDepartment'
import { CustomerDepartmentContextSwitch } from '../../MainHeader/CustomerDepartments/CustomerDepartmentContextSwitch'
import { ActiveCustomerFrameworks } from '../../MainHeader/CustomerFrameworks/ActiveCustomerFrameworks'
import { CustomerFrameworkContextSwitch } from '../../MainHeader/CustomerFrameworks/CustomerFrameworkContextSwitch'
import { NavigationTab } from '../../MainHeader/NavigationTabs/NavigationTab'
import { SearchToolItem } from '../Tool/SearchToolItem'
import { ReleaseUpdateModalManager } from '~/components/Domain/ReleaseUpdate/ReleaseUpdateModalManager'

interface Props {
    className?: ClassValue
    hideContextSwitch?: boolean
    hasBanner?: boolean
}

interface State {
    searchActive: boolean
}

export class Toolbar extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public state: State = {
        searchActive: false,
    }

    private loc = localize.namespaceTranslate(t => t.Customer.CustomerNavigationContextSwitch)

    private bem = new BEM('Toolbar', () => ({
        'no-context-switch': this.props.hideContextSwitch,
        hasBanner: this.props.hasBanner,
    }))

    public render() {
        const { className } = this.props

        return (
            <div className={this.bem.getElement('container')}>
                <ReleaseUpdateModalManager />
                <div className={this.bem.getClassName(className)}>
                    <Guard condition={permissions.canCreateTasks(this.context.activeDepartmentId)}>
                        <CreateGenericTaskToolBarItem className={this.bem.getElement('task-tool')} />
                    </Guard>
                    <SearchToolItem
                        className={this.bem.getElement('search')}
                        onToggle={show => this.setState({ searchActive: show })}
                    />
                    {!this.state.searchActive && !this.props.hideContextSwitch && (
                        <>
                            <Popper
                                className={this.bem.getElement('sub-menu')}
                                item={
                                    <NavigationTab
                                        className={this.bem.getElement('navigation-tab')}
                                        isOpen={false}
                                        label={this.loc(t => t.department)}
                                        switchComponent={<ActiveDepartment />}
                                    />
                                }
                                title={this.loc(t => t.departments)}
                                popperOptions={{
                                    modifiers: [{ name: 'offset', options: { offset: [44, 24] } }],
                                }}
                                contents={this.renderActiveDepartmentContent()}
                                position="bottom"
                            />
                            <Popper
                                className={this.bem.getElement('sub-menu')}
                                item={
                                    <NavigationTab
                                        className={this.bem.getElement('navigation-tab')}
                                        isOpen={false}
                                        label={this.loc(t => t.customerFrameworks)}
                                        switchComponent={<ActiveCustomerFrameworks />}
                                    />
                                }
                                popperOptions={{
                                    modifiers: [{ name: 'offset', options: { offset: [13, 24] } }],
                                }}
                                title={this.loc(t => t.customerFrameworks)}
                                contents={this.rendeActiveCustomerFrameworksContent()}
                                position={'bottom-start'}
                            />
                        </>
                    )}
                </div>
            </div>
        )
    }

    private renderActiveDepartmentContent(): PopperContentItem[] {
        return [
            {
                item: <CustomerDepartmentContextSwitch />,
                position: 'bottom-start',
            },
        ]
    }

    private rendeActiveCustomerFrameworksContent(): PopperContentItem[] {
        return [
            {
                item: <CustomerFrameworkContextSwitch />,
                position: 'bottom-start',
            },
        ]
    }
}
