import './CustomerFrameworksTableContainer.scss'

import React from 'react'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'
import { CustomerFrameworksTableHeader, DirectionEnum } from './CustomerFrameworksTableHeader'
import {
    MultiDepthExpandableTable,
    MultiDepthTableRowItem,
} from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTable'
import { Row } from '~/components/Core/Layout/Row'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { isEqual, sortBy } from 'lodash-es'
import { BEM } from '~/services/BEMService'
import { TopicPreviewModal } from '../../Topic/TopicPreviewModal'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { InlineTextIcon } from '~/components/Core/Icon/InlineTextIcon/InlineTextIcon'
import { IconType } from '~/components/Core/Icon/IconType'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'

/**
 * note:    rowItems-with-children's checkedState will be derived from their children,
 *          only the deepest columnItems should be mapped
 *
 * xIndex: column index         0 -> n
 * yIndex: indent/depth index   0 -> n
 */
interface ColumnItem {
    rowItemId: number
    parentId: number
    xIndex: number
    yIndex: number
    checkedState: ColumnItemCheckedStateEnum
}

export enum ColumnItemCheckedStateEnum {
    checked = 'CHECKED',
    unChecked = 'UNCHECKED',
    indeterminate = 'INDETERMINATE',
    notApplicable = 'NOTAPPLICABLE',
}

interface Props {
    customerFrameworks: CustomerFrameworkWithFramework[]
    editable: boolean
    onCheckboxChange: (isPristine: boolean) => void
}

interface State {
    currentPage: number
    columnItems: ColumnItem[]
}

export class CustomerFrameworksTableContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public maxDepth = 1
    public pageItemCount = window.innerWidth < 1500 ? (window.innerWidth < 1200 ? 2 : 4) : 6
    public defaultColumnItems = this.getColumns()
    public state: State = {
        currentPage: 1,
        columnItems: this.defaultColumnItems,
    }

    private bem = new BEM('CustomerFrameworksTableContainer')

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.editable && !this.props.editable) {
            const columnItems = this.getColumns()
            this.setState({ columnItems })
            this.defaultColumnItems = columnItems
        }

        if (prevProps.customerFrameworks.length !== this.props.customerFrameworks.length) {
            const columnItems = this.getColumns()
            this.setState({ columnItems })
            this.defaultColumnItems = columnItems
        }
    }

    public render() {
        const { customerFrameworks } = this.props

        return (
            <>
                <CustomerFrameworksTableHeader
                    customerFrameworks={customerFrameworks}
                    currentPage={this.state.currentPage}
                    pageItemCount={this.pageItemCount}
                    onPageChange={this.handlePageChange}
                />
                <MultiDepthExpandableTable
                    className={this.bem.getClassName()}
                    maxDepth={this.maxDepth}
                    rowItems={this.getRowItems()}
                />
            </>
        )
    }

    private getColumns() {
        const { customerFrameworks } = this.props

        const columnItems: ColumnItem[] = []
        customerFrameworks.forEach((customerFramework, index) => {
            const {
                framework: { themes },
            } = customerFramework

            if (themes?.length) {
                themes.forEach(theme => {
                    if (theme.topics?.length) {
                        theme.topics.forEach(({ idn, isChecked }) => {
                            if (!idn) {
                                return
                            }

                            const columnAlreadyPushed = columnItems.find(
                                ({ rowItemId, parentId, xIndex }) =>
                                    rowItemId === idn && parentId === theme.idn && xIndex === index
                            )

                            if (columnAlreadyPushed) {
                                return
                            }

                            columnItems.push({
                                rowItemId: idn,
                                parentId: theme.idn,
                                xIndex: index,
                                yIndex: 1,
                                checkedState: isChecked
                                    ? ColumnItemCheckedStateEnum.checked
                                    : ColumnItemCheckedStateEnum.unChecked,
                            })
                        })
                    }
                })
            }
        })

        return columnItems
    }

    private handlePageChange = (direction: DirectionEnum) => {
        const { customerFrameworks } = this.props
        const { currentPage } = this.state
        const totalCount = customerFrameworks.length

        if (direction === DirectionEnum.right) {
            const hasNextPage = totalCount > currentPage * this.pageItemCount
            if (hasNextPage) {
                this.setState({ currentPage: currentPage + 1 })
            }

            return
        }

        const hasPrevPage = currentPage > 1
        if (hasPrevPage) {
            this.setState({ currentPage: currentPage - 1 })
        }

        return
    }

    private getRowItems() {
        const { customerFrameworks } = this.props

        const themeRows: MultiDepthTableRowItem[] = []
        const sortedCustomerFrameworks = sortBy(customerFrameworks, ['name'])

        sortedCustomerFrameworks.forEach(({ framework }) => {
            if (framework.themes?.length) {
                const frameworkThemes: MultiDepthTableRowItem[] = []

                framework.themes.forEach(({ idn, name, topics }) => {
                    const themeAlreadyPushed = themeRows.find(theme => theme.id === idn)

                    // When the theme has already been push to the rows. We only to update it's children
                    // with any new topics that the current theme does not have already.
                    if (themeAlreadyPushed) {
                        if (themeAlreadyPushed.children) {
                            topics?.forEach(this.addTopicsToThemeChildren(themeAlreadyPushed.children))
                        }
                        return
                    }

                    const childrenTopics: MultiDepthTableRowItem[] = []
                    topics?.forEach(this.addTopicsToThemeChildren(childrenTopics))

                    frameworkThemes.push({
                        id: idn,
                        name,
                        children: childrenTopics,
                        yIndex: 0,
                        render: () => this.renderTableRow({ id: idn, name, children: childrenTopics, yIndex: 0 }),
                    })
                })

                themeRows.push(...frameworkThemes)
            }
        })

        return themeRows
    }

    private addTopicsToThemeChildren =
        (topics: MultiDepthTableRowItem[]) =>
        ({ idn, name }: { id: string; idn: number; name?: string | undefined; isChecked: boolean }) => {
            if (!idn) {
                return
            }

            const topicAlreadyPushed = topics.find(topic => topic.id === idn)

            if (topicAlreadyPushed) {
                return
            }

            topics.push({
                id: idn,
                name: name!,
                yIndex: 1,
                render: () => this.renderTableRow({ id: idn, name: name!, yIndex: 1 }),
            })
        }

    private renderTableRow(rowItem: MultiDepthTableRowItem) {
        const { editable } = this.props

        const isDisabled = !editable
        const isEmptyParent = rowItem.yIndex === 0 && !rowItem.children?.length
        const checkboxStates = isEmptyParent ? [] : this.getRowCheckboxStates(rowItem)

        return (
            <Row spaceBetween={true}>
                {rowItem.yIndex === 1 ? (
                    <TopicPreviewModal topicId={rowItem.id}>
                        {openModal => (
                            <Button type={ButtonType.noStyling} onClick={openModal}>
                                <Tooltip message={rowItem.name}>
                                    <div className={this.bem.getElement('label-container')}>
                                        <Paragraph className={this.bem.getElement('label')}>{rowItem.name}</Paragraph>
                                    </div>
                                </Tooltip>
                                <InlineTextIcon type={IconType.eye} className={this.bem.getElement('eye-icon')} />
                            </Button>
                        )}
                    </TopicPreviewModal>
                ) : (
                    <Tooltip message={rowItem.name}>
                        <div className={this.bem.getElement('label-container')}>
                            <Paragraph className={this.bem.getElement('label')} bold={true}>
                                {rowItem.name}
                            </Paragraph>
                        </div>
                    </Tooltip>
                )}
                <div className={this.bem.getElement('checkbox-list', () => ({ [`is-level-${rowItem.yIndex}`]: true }))}>
                    {checkboxStates.map((checkboxState, index) => {
                        const isChecked = checkboxState === ColumnItemCheckedStateEnum.checked
                        const isIndeterminate = checkboxState === ColumnItemCheckedStateEnum.indeterminate
                        const isNotApplicable = checkboxState === ColumnItemCheckedStateEnum.notApplicable
                        const isUnchecked = checkboxState === ColumnItemCheckedStateEnum.unChecked

                        const rowItemId = `${rowItem.id}-${index}-${rowItem.yIndex}`

                        return (
                            <div
                                key={`${rowItem.name}-column-${index}`}
                                id={rowItemId}
                                className={this.bem.getElement('checkbox-container')}
                                onMouseEnter={!isNotApplicable ? () => this.handleOnMouseEvent(rowItemId) : undefined}
                                onMouseLeave={
                                    !isNotApplicable ? () => this.handleOnMouseEvent(rowItemId, true) : undefined
                                }
                            >
                                <Checkbox
                                    className={this.bem.getElement('checkbox', () => ({
                                        'non-applicable': isNotApplicable,
                                        'is-unchecked': isUnchecked,
                                        [`is-level-${rowItem.yIndex}`]: true,
                                    }))}
                                    name={rowItem.name}
                                    checked={isChecked}
                                    indeterminate={isIndeterminate}
                                    disabled={isDisabled || isNotApplicable}
                                    large={true}
                                    onChange={(checked, name) =>
                                        this.onCheckboxChange(rowItem.id, index, rowItem.yIndex, checked)
                                    }
                                    animationDisabled={true}
                                />
                            </div>
                        )
                    })}
                </div>
            </Row>
        )
    }

    private getRowCheckboxStates(rowItem: MultiDepthTableRowItem): ColumnItemCheckedStateEnum[] {
        const { currentPage } = this.state

        const range = this.getCurrentRange()
        let index = (currentPage - 1) * this.pageItemCount
        const checkboxStates: ColumnItemCheckedStateEnum[] = []

        for (; index < range; index++) {
            let columns: ColumnItem[]

            if (rowItem.children) {
                columns = this.getCellColumns(index, [rowItem.id], rowItem.yIndex + 1)
            } else {
                columns = this.getColumnState(index, rowItem.id)
            }

            let checkedCount: number = 0
            let uncheckedCount: number = 0
            let indeterminateCount: number = 0

            columns.forEach(({ checkedState }) => {
                switch (checkedState) {
                    case ColumnItemCheckedStateEnum.checked:
                        checkedCount++
                        break
                    case ColumnItemCheckedStateEnum.unChecked:
                        uncheckedCount++
                        break
                    case ColumnItemCheckedStateEnum.indeterminate:
                        indeterminateCount++
                        break
                    default:
                        break
                }
            })

            if (checkedCount && !uncheckedCount && !indeterminateCount) {
                checkboxStates.push(ColumnItemCheckedStateEnum.checked)
                continue
            }

            if (!checkedCount && uncheckedCount && !indeterminateCount) {
                checkboxStates.push(ColumnItemCheckedStateEnum.unChecked)
                continue
            }

            if ((checkedCount && uncheckedCount) || indeterminateCount) {
                checkboxStates.push(ColumnItemCheckedStateEnum.indeterminate)
                continue
            }

            checkboxStates.push(ColumnItemCheckedStateEnum.notApplicable)
        }

        return checkboxStates
    }

    private handleOnMouseEvent(rowItemId: string, isLeaving?: boolean) {
        const elementIds = this.getElementIds(rowItemId)

        const elements: HTMLElement[] = []
        elementIds.forEach(id => {
            const element = document.getElementById(id)
            if (element) {
                elements.push(element)
            }
        })

        if (!elements.length) {
            return
        }

        if (isLeaving) {
            elements.forEach(element => (element.className = this.bem.getElement('checkbox-container')))
            return
        }

        elements.forEach(element => {
            element.className = this.bem.getElement('checkbox-container--hover')
        })
    }

    private onCheckboxChange = (rowItemId: number, xIndex: number, yIndex: number, checked: boolean) => {
        const { columnItems, currentPage } = this.state

        const checkedState = checked ? ColumnItemCheckedStateEnum.checked : ColumnItemCheckedStateEnum.unChecked

        // get correct index for page
        xIndex = xIndex + this.pageItemCount * (currentPage - 1)

        if (yIndex < this.maxDepth) {
            const newColumnItems = columnItems.map(item => {
                const isChild = rowItemId === item.parentId && item.xIndex === xIndex && item.yIndex > yIndex

                if (isChild) {
                    return {
                        ...item,
                        checkedState,
                    }
                }

                return item
            })

            this.setState({ columnItems: newColumnItems })
            this.props.onCheckboxChange(isEqual(newColumnItems, this.defaultColumnItems))
            return
        }

        const newColumnItems = columnItems.map(item => {
            if (item.rowItemId === rowItemId && item.xIndex === xIndex) {
                return {
                    ...item,
                    checkedState,
                }
            }

            return item
        })

        this.setState({ columnItems: newColumnItems })
        this.props.onCheckboxChange(isEqual(newColumnItems, this.defaultColumnItems))
        return
    }

    private getCellColumns(xIndex: number, parentIds: number[], yIndex: number, acc: ColumnItem[] = []): ColumnItem[] {
        const { columnItems } = this.state

        const columns = columnItems.filter(
            item => parentIds.includes(item.parentId) && item.xIndex === xIndex && item.yIndex === yIndex
        )

        if (!columns.length) {
            return acc
        }

        const newAcc = [...acc, ...columns]
        const newParentIds = columns.map(column => column.rowItemId)

        return this.getCellColumns(xIndex, newParentIds, yIndex + 1, newAcc)
    }

    private getColumnState(xIndex: number, rowItemId: number) {
        const { columnItems } = this.state

        const column = columnItems.find(item => item.xIndex === xIndex && item.rowItemId === rowItemId)

        if (!column) {
            return []
        }

        return [column]
    }

    // id: "rowItemId-xIndex-yIndex"
    private getElementIds(rowItemId: string) {
        const currentIdInfo = rowItemId.split('-')
        const rowId = parseInt(currentIdInfo[0], 10)
        const xIndex = parseInt(currentIdInfo[1], 10)
        const yIndex = parseInt(currentIdInfo[2], 10)

        const { columnItems } = this.state

        if (yIndex > 0) {
            const currentItem = columnItems.find(
                item => item.rowItemId === rowId && item.xIndex === xIndex && item.yIndex === yIndex
            )
            if (!currentItem) {
                return []
            }

            const siblingItems = columnItems.filter(
                item =>
                    item.parentId === currentItem.parentId &&
                    item.xIndex === currentItem.xIndex &&
                    item.yIndex === currentItem.yIndex
            )

            const siblingItemIds = this.parseColumnItemsToIds([...siblingItems])
            return [...siblingItemIds, `${currentItem.parentId}-${currentItem.xIndex}-${currentItem.yIndex - 1}`]
        }

        const childrenItems = columnItems.filter(item => item.parentId === rowId && item.xIndex === xIndex)
        const childrenIds = this.parseColumnItemsToIds(childrenItems)

        return [...childrenIds, rowItemId]
    }

    private getCurrentRange() {
        const { customerFrameworks } = this.props
        const { currentPage } = this.state

        const totalRange = customerFrameworks.length
        const range = currentPage * this.pageItemCount

        return range > totalRange ? totalRange : range
    }

    private parseColumnItemsToIds(columnItems: ColumnItem[]) {
        return columnItems.map(({ rowItemId, xIndex, yIndex }) => `${rowItemId}-${xIndex}-${yIndex}`)
    }
}
