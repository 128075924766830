import gql from 'graphql-tag'
import React from 'react'
import { MutationFn, MutationResult } from 'react-apollo'
import { GQLMutation } from '~/graphql/Mutation'
import { File } from '~/graphql/types/File'

const REOPEN_TASK = gql`
    mutation reopenTask($taskId: Int!, $motivation: TaskMotivationFieldsInputType!) {
        reopenTask(taskId: $taskId, fields: $motivation) {
            id
            completedAt
            reopenedAt
        }
    }
`

export interface ReopenTaskMutationResponse {
    reopenTask: {
        id: string
        completedAt: string
        reopenedAt: string
    }
}

export interface ReopenTaskVariables {
    taskId: number
    motivation?: {
        description?: string
        files?: File[]
    }
}

interface Props {
    children: (
        mutation: MutationFn<ReopenTaskMutationResponse, ReopenTaskVariables>,
        options: MutationResult
    ) => JSX.Element
}

export const ReopenTaskMutation: React.FunctionComponent<Props> = ({ children }) => (
    <GQLMutation<ReopenTaskMutationResponse, ReopenTaskVariables> mutation={REOPEN_TASK}>
        {(mutate, options) => children(mutate, options)}
    </GQLMutation>
)
