import React from 'react'
import { localize, permissions } from '~/bootstrap'
import { ActionButton } from '~/components/Core/ActionButton/ActionButton'
import { ModalManager } from '~/components/Core/Feedback/Modal/ModalManager'
import { IconType } from '~/components/Core/Icon/IconType'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { DepartmentType } from '~/generated/graphql'
import { LinkedTaskItem } from '~/graphql/types/Task'
import { ClassValue } from '~/services/BEMService'
import { CreateTaskModalContainer } from './CreateTaskModalContainer'

interface Props {
    linkedItem: LinkedTaskItem
    possibleDepartments?: Pick<DepartmentType, 'id' | 'name'>[]
    refetch?: () => void
    buttonClassName?: ClassValue
}

export class CreateTaskActionButton extends React.PureComponent<React.PropsWithChildren<Props>> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public render() {
        if (!permissions.canCreateTasks(this.context.activeDepartmentId)) {
            return null
        }

        const { linkedItem, refetch, buttonClassName, possibleDepartments } = this.props
        const tooltipLabel = localize.translate(t => t.Customer.Planning.Tasks.createTask)

        return (
            <ModalManager
                renderModal={closeModal => (
                    <CreateTaskModalContainer
                        linkedItem={linkedItem}
                        requestClose={closeModal}
                        onCreated={refetch}
                        possibleDepartments={possibleDepartments}
                    />
                )}
                render={openModal => (
                    <ActionButton
                        onClick={openModal}
                        className={buttonClassName}
                        icon={IconType.taskAdd}
                        tooltipContent={
                            <Paragraph small emphasis>
                                {tooltipLabel}
                            </Paragraph>
                        }
                    />
                )}
            />
        )
    }
}
