import './Spinner.scss'

import React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    delayed?: boolean
    small?: boolean
    large?: boolean
    slow?: boolean
}

interface State {
    hidden: boolean
}

export class Spinner extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        hidden: this.props.delayed ? true : false,
    }

    private bem = new BEM('Spinner', () => ({
        'is-small': this.props.small,
        'is-large': this.props.large,
        'is-slow': this.props.slow,
    }))

    private timer: number

    public componentDidMount() {
        if (this.props.delayed) {
            this.timer = window.setTimeout(() => {
                this.setState({
                    hidden: false,
                })
            }, 300)
        }
    }

    public componentWillUnmount() {
        if (this.props.delayed) {
            window.clearTimeout(this.timer)
        }
    }

    public render() {
        const { hidden } = this.state
        const { className } = this.props

        if (hidden) {
            return null
        }

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('container')}>
                    <svg className={this.bem.getElement('circle')} viewBox="25 25 50 50">
                        <circle
                            className={this.bem.getElement('path')}
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="4"
                        />
                    </svg>
                </div>
            </div>
        )
    }
}
