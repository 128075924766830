import './SelectionBox.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BEM, ClassValue } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { IconType } from '~/components/Core/Icon/IconType'
import { CSSTransition } from 'react-transition-group'
import { localize } from '~/bootstrap'
import { Tooltip } from '~/components/Core/Feedback/Tooltip/Tooltip'
import { Paragraph } from '../../Typography/Paragraph'
import { Row } from '../../Layout/Row'

const portalNode = document.querySelector('#selectionbox-root')

interface Props {
    selectedAmount: number
    onClose?: () => void
    buttons: (SelectionBoxButton | JSX.Element)[]
    isOpen: boolean
    className?: ClassValue
}

interface SelectionBoxButton {
    loading?: boolean
    label: string
    onClick?: () => void
    icon: IconType
}

export class SelectionBox extends React.Component<React.PropsWithChildren<Props>> {
    private bem = new BEM('SelectionBox')

    public render() {
        const { isOpen, className } = this.props

        return ReactDOM.createPortal(
            <CSSTransition
                classNames={'rf-selection-box-slide-in'}
                in={isOpen}
                timeout={1000}
                appear={true}
                unmountOnExit={true}
            >
                <div className={this.bem.getClassName(className)}>{this.renderWidget()}</div>
            </CSSTransition>,
            portalNode as Element
        )
    }

    private renderWidget() {
        const { selectedAmount, onClose } = this.props
        const selectedItemsText = localize.translate(t => t.Core.SelectionBox.selectedItems)

        return (
            <Row className={this.bem.getElement('container')}>
                <Row smallSpacing className={this.bem.getElement('title-container')}>
                    <span className={this.bem.getElement('selected-amount')}>{selectedAmount}</span>
                    <Paragraph emphasis white>
                        {selectedItemsText}
                    </Paragraph>
                </Row>
                {this.renderButtons()}
                <Button
                    onClick={onClose}
                    icon={IconType.close}
                    type={ButtonType.noStyling}
                    className={this.bem.getElement('close')}
                    vertical={true}
                />
            </Row>
        )
    }

    private renderButtons() {
        const { buttons } = this.props

        return (
            <Row smallSpacing className={this.bem.getElement('buttons')}>
                {buttons.map((button, i) => {
                    if (!('label' in button)) {
                        return <React.Fragment key={i}>{button}</React.Fragment>
                    }

                    return (
                        <Tooltip
                            className={this.bem.getElement('tooltip')}
                            distance={16}
                            content={
                                <Paragraph small white emphasis className={this.bem.getElement('tooltip-content')}>
                                    {button.label}
                                </Paragraph>
                            }
                            key={i}
                        >
                            <Button
                                vertical={true}
                                type={ButtonType.noStyling}
                                icon={button.icon}
                                loading={button.loading}
                                onClick={button.onClick}
                                className={this.bem.getElement('button')}
                            />
                        </Tooltip>
                    )
                })}
            </Row>
        )
    }
}
