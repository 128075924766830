import React from 'react'
import { localize } from '~/bootstrap'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ColumnOptions, SortOption, Table } from '~/components/Core/DataDisplay/Table/Table'
import { TableLink } from '~/components/Core/DataDisplay/Table/TableLink'
import { IconType } from '~/components/Core/Icon/IconType'
import { InfiniteScrollQuery } from '~/components/Core/Pagination/InfiniteScrollQuery'
import { Paragraph } from '~/components/Core/Typography/Paragraph'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { ControlsDocument, ControlTypeType } from '~/generated/graphql'
import { BEM, ClassValue } from '~/services/BEMService'
import { routes } from '~/views/routes'
import { AssessmentLabel } from '../Assessments/AssessmentLabel'
import { AssessmentsList } from '../Assessments/AssessmentsList'
import './ControlSelectTable.scss'
import { Icon } from '~/components/Core/Icon/Icon'
import { getIconTypeForControlType } from '~/utils/controls'

interface Props {
    className?: ClassValue
    search?: string | null
    onControlSelect: (control: Control) => void
    isControlAdded: (control: Control) => boolean
    onCreateControl?: (control: Control) => void
}

interface State {
    sort: SortOption
}

export interface Control {
    id: number
    name: string
    type: ControlTypeType
    createdAt: string
    assessments: {
        id: number
        name: string
    }[]
    assessmentSections: {
        id: number
    }[]
}

export class ControlSelectTable extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public bem = new BEM('ControlSelectTable')

    public state: State = {
        sort: { field: 'name', direction: 'DESC' },
    }

    public render() {
        const { search } = this.props
        const { sort } = this.state

        return (
            <InfiniteScrollQuery<Control>
                query={ControlsDocument}
                variables={{
                    filters: {
                        name: search,
                        departmentId: this.context.activeDepartmentId,
                    },
                    sort: {
                        [sort.field]: sort.direction,
                    },
                    departmentId: this.context.activeDepartmentId,
                }}
            >
                {({ data, loading, loadingMore, canFetchMore, hasPaginated }) => {
                    const tableData = data
                        ? data.nodes.map(node => ({
                              id: node.id,
                              columns: {
                                  name: (
                                      <span className={this.bem.getElement('control-title')}>
                                          <Icon
                                              type={getIconTypeForControlType(node.type)}
                                              className={this.bem.getElement('control-icon')}
                                          />
                                          <TableLink
                                              to={routes
                                                  .customer(this.context.customer.slug)
                                                  .compliance.controls.view(node.id)}
                                          >
                                              {node.name}
                                          </TableLink>
                                      </span>
                                  ),
                                  assessments: this.renderAssessments(node.assessments),
                              },
                          }))
                        : []

                    const headers: ColumnOptions[] = [
                        {
                            field: 'name',
                            headerLabel: localize.translate(t => t.Control.attributes.name),
                            sortable: true,
                        },
                        {
                            field: 'assessments',
                            headerLabel: localize.translate(t => t.Control.attributes.assessments),
                            noAutoWidth: true,
                        },
                    ]

                    return (
                        <>
                            <Table
                                loading={loading}
                                loadingMore={loadingMore}
                                endReached={!canFetchMore && hasPaginated}
                                columns={headers}
                                data={tableData}
                                onSortDirectionChange={(field, direction) =>
                                    this.setState({ sort: { field, direction } })
                                }
                                rowAction={({ row: { id } }) =>
                                    this.renderSelectButton(data && data.nodes.find(control => control.id === id))
                                }
                                className={this.bem.getClassName()}
                            />
                        </>
                    )
                }}
            </InfiniteScrollQuery>
        )
    }

    private renderAssessments(assessments: { id: number; name: string }[]) {
        if (!assessments.length) {
            return
        }

        if (assessments.length === 1) {
            return <AssessmentLabel assessment={assessments[0]} />
        }

        return <AssessmentsList assessments={assessments} />
    }

    private renderSelectButton(control?: Control | null) {
        const { onControlSelect, isControlAdded } = this.props

        if (!control) {
            return null
        }

        if (isControlAdded(control)) {
            return (
                <Paragraph subtle={true}>
                    {localize.translate(t => t.Customer.Compliance.Controls.ControlSelectTable.controlAlreadyAdded)}
                </Paragraph>
            )
        }

        return (
            <Button
                type={ButtonType.actionLink}
                icon={IconType.addCircle}
                className={this.bem.getElement('select-control-button')}
                onClick={() => onControlSelect(control)}
            >
                {localize.translate(t => t.Customer.Compliance.Controls.ControlSelectTable.selectControl)}
            </Button>
        )
    }
}
