import './Cover.scss'

import React from 'react'

import { BEM } from '~/services/BEMService'
import { Button, ButtonType } from '~/components/Core/Button/Button'
import { ErrorMessage } from '~/components/Core/Feedback/Error/ErrorMessage'
import { Field } from '~/components/Core/DataEntry/Form/Field'
import { Input } from '~/components/Core/DataEntry/Form/Input'
import { LoginMutation, LoginMutationFn } from '~/graphql/mutations/LoginMutation'
import { Logo } from '~/components/Chrome/Logo/Logo'
import { PageTitle } from '~/components/Core/Text/PageTitle'
import { routes } from '~/views/routes'
import { userClient, localize } from '~/bootstrap'
import { Checkbox } from '~/components/Core/DataEntry/Form/Checkbox'
import { Column } from '~/components/Core/Layout/Column'
import { AccessGroup } from '~/generated/graphql'
import { LocalStorageKeys, LocalStorageService } from '~/services/LocalStorageService'
import { Form } from '~/components/Core/DataEntry/Form/Form'
import {
    RequestSAMLRedirectURLForEmail,
    RequestSAMLRedirectURLForEmailMutationFN,
} from '~/graphql/mutations/RequestSAMLRedirectURLForEmail'
import { RouteComponentProps, withRouter } from '~/utils/withRouter'
import { Link, NavLink } from 'react-router-dom'

interface State {
    email: string
    password: string | null
    rememberMe: boolean
}

interface Props extends RouteComponentProps<{}> {}

class CoverLoginViewComponent extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public state: State = {
        email: LocalStorageService.getItem(LocalStorageKeys.UserLoginEmail) || '',
        rememberMe: !!LocalStorageService.getItem(LocalStorageKeys.UserLoginEmail),
        password: null,
    }

    private loc = localize.namespaceTranslate(t => t.Cover.Login)
    private bem = new BEM('Cover')
    private formRef = React.createRef<Form>()

    public componentDidMount() {
        const { email, rememberMe } = this.state
        const lastSSOEmail = LocalStorageService.getItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)

        if (rememberMe && email === lastSSOEmail && this.formRef.current) {
            this.formRef.current.triggerSubmit()

            // This is to prevent infitite loops. The key will be set again after login in the UserProvider
            LocalStorageService.removeItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)
        }
    }

    public render() {
        const errorMessage = this.getErrorMessageFromUrl()

        return (
            <RequestSAMLRedirectURLForEmail>
                {(requestSAMLRedirectURL, { loading: loadingSaml }) => (
                    <LoginMutation>
                        {(login, { loading: loadingCredentials }) => {
                            const loading = loadingSaml || loadingCredentials

                            return (
                                <div className={this.bem.getClassName()}>
                                    <div className={this.bem.getElement('brand')}>
                                        <NavLink to={routes.index}>
                                            <Logo />
                                        </NavLink>
                                    </div>
                                    <Form ref={this.formRef} onSubmit={this.onSignIn(login, requestSAMLRedirectURL)}>
                                        <div className={this.bem.getElement('content')}>
                                            <PageTitle title={this.loc(t => t.title)} />
                                            {errorMessage && <ErrorMessage message={errorMessage} />}
                                            <ErrorMessage path={'loginWithCredentials'} />
                                            <Field
                                                compact={true}
                                                forInput={'email'}
                                                label={this.loc(t => t.EmailField.label)}
                                                suffix={
                                                    <Button type={ButtonType.actionLink} to={routes.cover.loginWithSSO}>
                                                        {this.loc(t => t.loginWithSSO)}
                                                    </Button>
                                                }
                                            >
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    placeholder={this.loc(t => t.EmailField.placeholder)}
                                                    onChange={email => this.setState({ email: email || '' })}
                                                    value={this.state.email}
                                                    ariaLabel={'email'}
                                                    required={true}
                                                />
                                            </Field>
                                            <Column smallSpacing={true}>
                                                <Field
                                                    compact={true}
                                                    forInput={'password'}
                                                    label={this.loc(t => t.PasswordField.label)}
                                                >
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        placeholder={this.loc(t => t.PasswordField.placeholder)}
                                                        onChange={password => this.setState({ password })}
                                                        ariaLabel={'password'}
                                                    />
                                                </Field>
                                                <Field forInput="rememberMe">
                                                    <Checkbox
                                                        name="rememberMe"
                                                        large={true}
                                                        label={this.loc(t => t.rememberMe)}
                                                        onChange={rememberMe => this.setState({ rememberMe })}
                                                        defaultChecked={this.state.rememberMe}
                                                    />
                                                </Field>
                                            </Column>
                                        </div>

                                        <Button
                                            submit={true}
                                            className={this.bem.getElement('submit-button')}
                                            loading={loading}
                                        >
                                            {this.loc(t => t.signInButton)}
                                        </Button>

                                        <Link to={routes.cover.forgotPassword} className={this.bem.getElement('link')}>
                                            {this.loc(t => t.link)}
                                        </Link>
                                    </Form>
                                </div>
                            )
                        }}
                    </LoginMutation>
                )}
            </RequestSAMLRedirectURLForEmail>
        )
    }

    private onSignIn =
        (loginWithCredentials: LoginMutationFn, requestSAMLRedirectURL: RequestSAMLRedirectURLForEmailMutationFN) =>
        async () => {
            const { email, password, rememberMe } = this.state

            // Always check first if the user can login with SAML.
            // otherwise the user will get an error message saying logging in failed
            if (email) {
                try {
                    await this.performSAMLLogin(requestSAMLRedirectURL, email)
                    return
                } catch {
                    LocalStorageService.removeItem(LocalStorageKeys.LastSuccessfulSSOLoginEmail)
                }
            }

            // Retry with normal password login
            await this.performLoginWithCredentials(loginWithCredentials, email, password, rememberMe)
        }

    private async performLoginWithCredentials(
        login: LoginMutationFn,
        email: string | null,
        password: string | null,
        rememberMe?: boolean
    ) {
        const response = await login({ variables: { email, password, rememberMe } })

        if (response && response.data && response.data.loginWithCredentials) {
            this.updateRememberedEmailKey()

            const { token, user, accessGroup } = response.data.loginWithCredentials
            if (accessGroup !== AccessGroup.customer && accessGroup !== AccessGroup.consultant) {
                this.props.history.push(routes.cover.redirect, { state: { token, user, accessGroup } })
                return
            }

            await userClient.loginWithUserIdAndToken(user.id, token, path => {
                this.props.history.push(path)
            })
        }
    }

    private async performSAMLLogin(requestSAMLRedirectURL: RequestSAMLRedirectURLForEmailMutationFN, email: string) {
        const response = await requestSAMLRedirectURL({ variables: { email } })

        if (response && response.data && response.data.requestSAMLRedirectURLForEmail) {
            const { redirectURL } = response.data.requestSAMLRedirectURLForEmail

            if (redirectURL) {
                // lastSSO key will be set to true after saml login is successful
                this.updateRememberedEmailKey()

                window.location.href = redirectURL
                return
            }
        }

        throw new Error('Failed to request SAML redirect url')
    }

    private updateRememberedEmailKey() {
        if (!this.state.rememberMe) {
            LocalStorageService.removeItem(LocalStorageKeys.UserLoginEmail)
            return
        }

        const rememberedEmail = LocalStorageService.getItem(LocalStorageKeys.UserLoginEmail)
        if (this.state.email === rememberedEmail) {
            return
        }

        // sanity check
        if (!this.state.email) {
            throw new Error('email not found in state')
        }

        LocalStorageService.setItem(LocalStorageKeys.UserLoginEmail, this.state.email)
    }

    private getErrorMessageFromUrl() {
        const searchParams = new URLSearchParams(this.props.location.search)
        const type = searchParams.get('error')

        if (!type) {
            return
        }

        if (type === 'assert') {
            return 'Er iets iets fout gegaan tijdens het verifiëren van het Ruler account, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        if (type === 'assert-insufficientData') {
            return 'Het is niet gelukt om een nieuwe Ruler gebruiker klaar te zetten, omdat niet alle verplichte gegevens kunnen worden uitgelezen. De SSO-instellingen dienen waarschijnlijk te worden herzien.'
        }

        if (type === 'assert-invalidData') {
            return 'De persoonsgegevens konden niet correct worden uitgelezen. De SSO-instellingen dienen waarschijnlijk te worden herzien.'
        }

        if (type === 'assert-noSeatsLeft') {
            return 'Er zijn geen plaatsen meer beschikbaar. Verwijder gebruikers of neem eventueel contact met ons op om extra plaatsen aan te vragen.'
        }

        if (type === 'login') {
            return 'Het inloggen via de SSO provider is mislukt, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        if (type === 'logout') {
            return 'Het uitloggen via de SSO provider is mislukt, probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
        }

        return 'Er iets iets fout gegaan probeer het opnieuw. Blijft dit probleem voorkomen neem dan contact op.'
    }
}

export const CoverLoginView = withRouter(CoverLoginViewComponent)
