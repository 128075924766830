import React from 'react'

interface Props {
    condition: boolean
}

export const Guard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ condition, children }) => {
    if (condition) {
        return <>{children}</>
    }

    return null
}
