import './DepartmentsTableContainer.scss'

import React from 'react'
import { MultiDepthExpandableTable } from '~/components/Core/DataDisplay/Table/MultiDepthExpandableTable'
import { CustomerContext, CustomerContextValue } from '~/components/Providers/CustomerProvider'
import { BEM } from '~/services/BEMService'
import { ColumnItemCheckedStateEnum } from '../../CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableContainer'
import { DirectionEnum } from '../../CustomerFramework/CustomerFrameworksTable/CustomerFrameworksTableHeader'
import { DepartmentsTableHeader } from './../DepartmentsTableHeader'
import { isEqual } from 'lodash-es'
import { CustomerFrameworkWithFramework } from '~/views/Customer/Settings/CustomerFramework/CustomerFrameworkOverviewView'
import {
    CustomerFrameworkColorEnumType,
    CustomerFrameworkIconEnumType,
    DepartmentFrameworkType,
    DepartmentType,
} from '~/generated/graphql'
import {
    DepartmentFrameworkRowItem,
    DepartmentsTableRowContainer,
    RowItemWithNewWarning,
} from './DepartmentsTableRowContainer'
import { DepartmentsTableWidget } from './DepartmentsTableWidget'

interface Props {
    departments: DepartmentType[]
    editable: boolean
    onCheckboxChange: (isPristine: boolean) => void
    customerFrameworks: CustomerFrameworkWithFramework[]
}

interface State {
    currentPage: number
    columnItems: DepartmentsTableColumnItem[]
    rowItemsWithNewWarning: RowItemWithNewWarning[]
    rowItemsWithNewWarningInOrAboveViewport: RowItemWithNewWarning[]
}

/**
 * note:    rowItems-with-children's checkedState will be derived from their children,
 *          only the deepest columnItems should be mapped
 *
 * xIndex: column index         0 -> n
 * yIndex: indent/depth index   0 -> n
 */
export interface DepartmentsTableColumnItem {
    rowItemId: number
    parentId: number
    xIndex: number
    yIndex: number
    checkedState: ColumnItemCheckedStateEnum
    customerFrameworkId: number
}

export class DepartmentsTableContainer extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    public static contextType = CustomerContext
    public context: CustomerContextValue

    public maxDepth = 2
    public pageItemCount = window.innerWidth < 1500 ? (window.innerWidth < 1200 ? 2 : 4) : 6
    public rowItems = this.getRowItems()
    public defaultColumnItems = this.getColumns()
    public state: State = {
        currentPage: 1,
        columnItems: this.defaultColumnItems,
        rowItemsWithNewWarning: [],
        rowItemsWithNewWarningInOrAboveViewport: [],
    }

    private bem = new BEM('DepartmentsTableContainer')

    public componentDidMount() {
        document.addEventListener('scroll', this.updateNewRowItemsInViewPort)
        this.updateNewRowItemsInViewPort() // initialize
    }

    public componentWillUnmount() {
        document.removeEventListener('scroll', this.updateNewRowItemsInViewPort)
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const prevIds = prevProps.departments.flatMap(d => d.departmentFrameworks.map(({ id }) => id))
        const newIds = this.props.departments.flatMap(d => d.departmentFrameworks.map(({ id }) => id))

        const departmentFrameworksHaveChanged = !isEqual(prevIds, newIds)
        const departmentsHaveChanged = prevProps.departments.length !== this.props.departments.length
        const hasChangedToViewMode = prevProps.editable && !this.props.editable
        const hasChangedEditable = prevProps.editable !== this.props.editable
        const hasChangedPage = prevState.currentPage !== this.state.currentPage

        const shouldUpdate =
            hasChangedToViewMode ||
            departmentsHaveChanged ||
            hasChangedPage ||
            departmentFrameworksHaveChanged ||
            hasChangedEditable

        if (shouldUpdate) {
            const rowItems = this.getRowItems()
            this.rowItems = rowItems

            const columnItems = this.getColumns()
            this.defaultColumnItems = columnItems
            this.setState({ columnItems })

            this.updateNewRowItemsInViewPort()
        }
    }

    public render() {
        const { departments } = this.props
        const { rowItemsWithNewWarning, rowItemsWithNewWarningInOrAboveViewport } = this.state

        return (
            <>
                <DepartmentsTableHeader
                    departments={departments}
                    currentPage={this.state.currentPage}
                    pageItemCount={this.pageItemCount}
                    onPageChange={this.handlePageChange}
                />
                <MultiDepthExpandableTable
                    className={this.bem.getClassName()}
                    maxDepth={this.maxDepth}
                    rowItems={this.rowItems}
                />
                <DepartmentsTableWidget
                    rowItemsWithNewWarningInOrAboveViewport={rowItemsWithNewWarningInOrAboveViewport}
                    rowItemsWithNewWarning={rowItemsWithNewWarning}
                />
            </>
        )
    }

    private getRowItems() {
        const { customerFrameworks } = this.props

        const rows: DepartmentFrameworkRowItem[] = []

        if (!customerFrameworks || !customerFrameworks.length) {
            return []
        }

        customerFrameworks.forEach((customerFramework, index) => {
            const isAlreadyPushed = rows.find(row => row.id === customerFramework.id)
            if (isAlreadyPushed) {
                return
            }

            const { themes } = customerFramework.framework

            if (themes && themes.length) {
                const frameworkThemeRows: DepartmentFrameworkRowItem[] = []

                themes.forEach(theme => {
                    if (!theme) {
                        return
                    }

                    const themeAlreadyPushed = frameworkThemeRows.find(row => row.id === theme.idn)
                    if (themeAlreadyPushed) {
                        return
                    }

                    const topicRows: DepartmentFrameworkRowItem[] | undefined = []
                    theme.topics?.forEach(topic => {
                        if (!topic) {
                            return
                        }

                        if (topic.isChecked !== true) {
                            return
                        }

                        const topicAlreadyPushed = topicRows.find(row => row.id === topic.idn)
                        if (topicAlreadyPushed) {
                            return
                        }

                        const topicRow = {
                            id: topic.idn,
                            uniqId: topic.id,
                            name: topic.name!,
                            yIndex: 2,
                            parentUniqId: theme.id,
                            isNew: !!topic.isNew,
                            addedAt: topic.addedAt ? topic.addedAt : null,
                            customerFrameworkId: customerFramework.id,
                        }

                        topicRows.push({
                            ...topicRow,
                            render: () => this.renderTableRow(topicRow),
                        })
                    })

                    if (!topicRows.length) {
                        return
                    }

                    const themeRow = {
                        id: theme.idn,
                        uniqId: theme.id,
                        name: theme.name,
                        children: topicRows,
                        yIndex: 1,
                        parentUniqId: `${customerFramework.id}`,
                        customerFrameworkId: customerFramework.id,
                    }

                    frameworkThemeRows.push({
                        ...themeRow,
                        render: () => this.renderTableRow(themeRow),
                        onExpandedStateChange: isExpanded => this.handleOnExpandedStateChange(themeRow, isExpanded),
                    })
                })

                if (!frameworkThemeRows.length) {
                    return
                }

                const customerFrameworkRow = {
                    id: customerFramework.id,
                    uniqId: `${customerFramework.id}`,
                    name: customerFramework.name,
                    children: frameworkThemeRows,
                    parentUniqId: `i-${index}`,
                    yIndex: 0,
                    customerFrameworkId: customerFramework.id,
                }

                rows.push({
                    ...customerFrameworkRow,
                    render: () =>
                        this.renderTableRow(customerFrameworkRow, customerFramework.icon, customerFramework.color),
                    onExpandedStateChange: isExpanded =>
                        this.handleOnExpandedStateChange(customerFrameworkRow, isExpanded),
                })
            }
        })

        return rows
    }

    private getColumns() {
        const { departments, customerFrameworks } = this.props

        const columnItems: DepartmentsTableColumnItem[] = []

        departments.forEach(({ departmentFrameworks }, index) => {
            customerFrameworks.forEach(customerFramework => {
                const { themes } = customerFramework.framework

                if (themes?.length) {
                    themes.forEach(theme => {
                        if (theme?.topics?.length) {
                            theme.topics.forEach(topic => {
                                if (topic) {
                                    if (topic.isChecked !== true) {
                                        return
                                    }

                                    const columnAlreadyPushed = columnItems.find(
                                        ({ rowItemId, parentId, xIndex, customerFrameworkId }) =>
                                            rowItemId === topic.idn &&
                                            parentId === theme.idn &&
                                            xIndex === index &&
                                            customerFrameworkId === customerFramework.id
                                    )

                                    if (columnAlreadyPushed) {
                                        return
                                    }

                                    const topicCheckedState = this.getTopicCheckedStateInDepartmentFramework(
                                        departmentFrameworks,
                                        customerFramework.id,
                                        theme.idn,
                                        topic.idn
                                    )

                                    columnItems.push({
                                        rowItemId: topic.idn,
                                        parentId: theme.idn,
                                        xIndex: index,
                                        yIndex: 2,
                                        checkedState: topicCheckedState,
                                        customerFrameworkId: customerFramework.id,
                                    })
                                }
                            })
                        }
                    })
                }
            })
        })

        return columnItems
    }

    private updateNewRowItemsInViewPort = () => {
        const { rowItemsWithNewWarning } = this.state

        if (!rowItemsWithNewWarning || !rowItemsWithNewWarning.length) {
            return
        }

        const innerHeight = window.innerHeight
        const newItems: RowItemWithNewWarning[] = []

        for (const rowItem of rowItemsWithNewWarning) {
            if (rowItem.isExpanded) {
                continue
            }

            const { elementRef } = rowItem
            const bottom = elementRef.current?.getBoundingClientRect().bottom ?? innerHeight

            const isInOrAboveViewport = bottom < innerHeight

            if (isInOrAboveViewport) {
                newItems.push(rowItem)
            }
        }

        this.setState({ rowItemsWithNewWarningInOrAboveViewport: newItems })
    }

    private getTopicCheckedStateInDepartmentFramework(
        departmentFrameworks: DepartmentFrameworkType[],
        customerFrameworkId: number,
        themeId: number,
        topicId: number
    ): ColumnItemCheckedStateEnum {
        const departmentFramework = departmentFrameworks.find(
            ({ customerFramework }) => customerFramework.idn === customerFrameworkId
        )

        if (!departmentFramework) {
            return ColumnItemCheckedStateEnum.unChecked
        }

        const themeInDepartmentFramework = departmentFramework.customerFramework.framework.themes?.find(
            ({ idn }) => idn === themeId
        )

        if (!themeInDepartmentFramework) {
            return ColumnItemCheckedStateEnum.unChecked
        }

        const topicInDepartmentFramework = themeInDepartmentFramework.topics?.find(topic => topic?.idn === topicId)

        if (topicInDepartmentFramework) {
            return topicInDepartmentFramework.isChecked === true
                ? ColumnItemCheckedStateEnum.checked
                : ColumnItemCheckedStateEnum.unChecked
        }

        return ColumnItemCheckedStateEnum.unChecked
    }

    private handlePageChange = (direction: DirectionEnum) => {
        const { departments } = this.props
        const { currentPage } = this.state
        const totalCount = departments.length

        if (direction === DirectionEnum.right) {
            const hasNextPage = totalCount > currentPage * this.pageItemCount
            if (hasNextPage) {
                this.setState({ currentPage: currentPage + 1 })
            }

            return
        }

        const hasPrevPage = currentPage > 1
        if (hasPrevPage) {
            this.setState({ currentPage: currentPage - 1 })
        }

        return
    }

    private renderTableRow(
        rowItem: DepartmentFrameworkRowItem,
        icon?: CustomerFrameworkIconEnumType,
        color?: CustomerFrameworkColorEnumType
    ) {
        const { editable, departments } = this.props
        const { currentPage, columnItems } = this.state

        return (
            <DepartmentsTableRowContainer
                rowItem={rowItem}
                maxDepth={this.maxDepth}
                editable={editable}
                icon={icon}
                color={color}
                currentPage={currentPage}
                pageItemCount={this.pageItemCount}
                rowItemsWithNewWarnings={this.state.rowItemsWithNewWarning}
                onCheckboxChange={this.handleCheckboxChange}
                onMouseEvent={this.handleOnMouseEvent}
                onIgnoreSubmit={() => {
                    const rowItems = this.getRowItems()
                    this.rowItems = rowItems
                    this.updateNewRowItemsInViewPort()
                }}
                departments={departments}
                allRowItems={this.rowItems}
                columnItems={columnItems}
            />
        )
    }

    private handleOnExpandedStateChange = (rowItem: DepartmentFrameworkRowItem, isExpanded: boolean) => {
        let { rowItemsWithNewWarning } = this.state
        const { uniqId, yIndex } = rowItem

        if (!isExpanded) {
            const getChildrenIds = (id: string) =>
                rowItemsWithNewWarning.filter(item => item.parentUniqId === id).map(({ uniqId }) => uniqId)
            const itemIdsToRemove: string[] = []

            if (yIndex === 1) {
                const childrenIds = getChildrenIds(uniqId)
                if (childrenIds.length) {
                    itemIdsToRemove.push(...getChildrenIds(uniqId))
                }
            }

            if (yIndex === 0) {
                const firstLevelChildrenIds = getChildrenIds(uniqId)

                if (firstLevelChildrenIds.length) {
                    itemIdsToRemove.push(...firstLevelChildrenIds)

                    const bottomLevelChildrenIds = firstLevelChildrenIds.flatMap(getChildrenIds)

                    if (bottomLevelChildrenIds) {
                        itemIdsToRemove.push(...bottomLevelChildrenIds)
                    }
                }
            }

            rowItemsWithNewWarning = rowItemsWithNewWarning.filter(item => !itemIdsToRemove.includes(item.uniqId))
        }

        const newRowItemsWithNewWarning = rowItemsWithNewWarning.map(item => {
            const shouldUpdate = item.uniqId === uniqId

            if (shouldUpdate) {
                return {
                    ...item,
                    isExpanded,
                }
            }

            return item
        })

        this.setState({ rowItemsWithNewWarning: newRowItemsWithNewWarning }, () => this.updateNewRowItemsInViewPort())
    }

    private handleOnMouseEvent = (rowItemId: string, isHidden: boolean, bem: BEM<object>, isLeaving?: boolean) => {
        if (isHidden) {
            return
        }

        const elementIds = this.getElementIds(rowItemId)

        const elements: HTMLElement[] = []
        elementIds.forEach(id => {
            const element = document.getElementById(id)
            if (element) {
                elements.push(element)
            }
        })

        if (!elements.length) {
            return
        }

        if (isLeaving) {
            elements.forEach(element => (element.className = bem.getElement('checkbox-container')))
            return
        }

        elements.forEach(element => (element.className = bem.getElement('checkbox-container--hover')))
    }

    private handleCheckboxChange = (rowItem: DepartmentFrameworkRowItem, xIndex: number, checked: boolean) => {
        const { columnItems, currentPage } = this.state
        const { yIndex, customerFrameworkId, id } = rowItem
        const { onCheckboxChange } = this.props

        const checkedState = checked ? ColumnItemCheckedStateEnum.checked : ColumnItemCheckedStateEnum.unChecked

        // get correct index for page
        xIndex = xIndex + this.pageItemCount * (currentPage - 1)

        if (yIndex === 0) {
            const rowItem = this.rowItems.find(item => item.id === customerFrameworkId && item.yIndex === 0)
            const childrenRowItems: DepartmentFrameworkRowItem[] = []
            rowItem?.children?.forEach(childRow => childrenRowItems.push(childRow))

            if (childrenRowItems.length) {
                const childrenRowItemIds = childrenRowItems.map(({ id }) => id)
                const newColumnItems = columnItems.map(item => {
                    const isChild =
                        childrenRowItemIds.includes(item.parentId) &&
                        item.xIndex === xIndex &&
                        item.customerFrameworkId === customerFrameworkId

                    if (isChild) {
                        return {
                            ...item,
                            checkedState,
                        }
                    }

                    return item
                })

                this.setState({ columnItems: newColumnItems })
                onCheckboxChange(isEqual(newColumnItems, this.defaultColumnItems))
                this.rowItems = this.getRowItems()
            }

            return
        }

        if (yIndex < this.maxDepth) {
            const newColumnItems = columnItems.map(item => {
                const isChild =
                    item.parentId === id && item.xIndex === xIndex && item.customerFrameworkId === customerFrameworkId

                if (isChild) {
                    return {
                        ...item,
                        checkedState,
                    }
                }

                return item
            })

            this.setState({ columnItems: newColumnItems })
            onCheckboxChange(isEqual(newColumnItems, this.defaultColumnItems))
            this.rowItems = this.getRowItems()
            return
        }

        const newColumnItems = columnItems.map(item => {
            if (item.rowItemId === id && item.xIndex === xIndex && item.customerFrameworkId === customerFrameworkId) {
                return {
                    ...item,
                    checkedState,
                }
            }

            return item
        })

        this.setState({ columnItems: newColumnItems })
        onCheckboxChange(isEqual(newColumnItems, this.defaultColumnItems))
        this.rowItems = this.getRowItems()
        return
    }

    // id: "customerFrameworkId-rowItemId-xIndex-yIndex"
    private getElementIds(rowItemId: string) {
        const currentIdInfo = rowItemId.split('-')
        const customerFrameworkId = parseInt(currentIdInfo[0], 10)
        const rowId = parseInt(currentIdInfo[1], 10)
        const xIndex = parseInt(currentIdInfo[2], 10)
        const yIndex = parseInt(currentIdInfo[3], 10)

        if (yIndex === 2) {
            return this.getElementIdsFromBottomLevelItem(rowId, xIndex, yIndex, customerFrameworkId)
        }

        if (yIndex === 1) {
            return this.getElementIdsFromMidLevelItem(rowId, xIndex, yIndex, customerFrameworkId, rowItemId)
        }

        return this.getElementIdsFromTopLevelItem(rowId, rowItemId, xIndex, customerFrameworkId)
    }

    private getElementIdsFromBottomLevelItem(
        rowId: number,
        xIndex: number,
        yIndex: number,
        customerFrameworkId: number
    ) {
        const { columnItems } = this.state

        const currentItem = columnItems.find(
            item =>
                item.rowItemId === rowId &&
                item.xIndex === xIndex &&
                item.yIndex === yIndex &&
                item.customerFrameworkId === customerFrameworkId
        )
        if (!currentItem) {
            return []
        }

        const siblingItems = columnItems.filter(
            item =>
                item.parentId === currentItem.parentId &&
                item.xIndex === currentItem.xIndex &&
                item.yIndex === currentItem.yIndex &&
                item.customerFrameworkId === customerFrameworkId
        )

        const siblingItemIds = this.parseColumnItemsToIds(siblingItems, customerFrameworkId)
        return [
            ...siblingItemIds,
            `${customerFrameworkId}-${currentItem.parentId}-${currentItem.xIndex}-${currentItem.yIndex - 1}`,
            `${customerFrameworkId}-${customerFrameworkId}-${xIndex}-0`,
        ]
    }

    private getElementIdsFromMidLevelItem(
        rowId: number,
        xIndex: number,
        yIndex: number,
        customerFrameworkId: number,
        rowItemId: string
    ) {
        const { columnItems } = this.state

        const childrenItems = columnItems.filter(
            item =>
                item.parentId === rowId && item.xIndex === xIndex && item.customerFrameworkId === customerFrameworkId
        )
        const childrenIds = this.parseColumnItemsToIds(childrenItems, customerFrameworkId)

        const parentItemId = `${customerFrameworkId}-${customerFrameworkId}-${xIndex}-0`

        const siblingRowItem = this.rowItems.find(rowItem => rowItem.id === customerFrameworkId && rowItem.yIndex === 0)
        if (siblingRowItem) {
            const siblingItemIds = this.parseRowItemsToIds([siblingRowItem], xIndex, customerFrameworkId)
            return [...childrenIds, ...siblingItemIds, rowItemId, parentItemId]
        }

        return [...childrenIds, rowItemId, parentItemId]
    }

    private getElementIdsFromTopLevelItem(
        rowId: number,
        rowItemId: string,
        xIndex: number,
        customerFrameworkId: number
    ) {
        const { columnItems } = this.state
        const rowItem = this.rowItems.find(rowItem => rowId === rowItem.id)
        const elementIds: string[] = [rowItemId]

        if (rowItem?.children?.length) {
            rowItem.children.forEach(item => {
                const childrenItems = columnItems.filter(
                    columnItem => columnItem.parentId === item.id && columnItem.xIndex === xIndex
                )
                elementIds.push(...this.parseColumnItemsToIds(childrenItems, customerFrameworkId))
            })

            const siblingItemIds = this.parseRowItemsToIds(rowItem.children, xIndex, customerFrameworkId)
            elementIds.push(...siblingItemIds)
        }

        return elementIds
    }

    private parseColumnItemsToIds(columnItems: DepartmentsTableColumnItem[], customerFrameworkId: number) {
        return columnItems.map(
            ({ rowItemId, xIndex, yIndex }) => `${customerFrameworkId}-${rowItemId}-${xIndex}-${yIndex}`
        )
    }

    private parseRowItemsToIds(rowItems: DepartmentFrameworkRowItem[], xIndex: number, customerFrameworkId: number) {
        return rowItems.map(({ id, yIndex }) => `${customerFrameworkId}-${id}-${xIndex}-${yIndex}`)
    }
}
