import React from 'react'
import { Select, SelectOption } from '~/components/Core/DataEntry/Form/Select'
import { localize } from '~/bootstrap'

interface Props {
    onChange: (option: SelectOption | null) => void
    defaultValue?: string
}

interface State {}

export class TaskDepartmentFilter extends React.PureComponent<React.PropsWithChildren<Props>, State> {
    private loc = localize.namespaceTranslate(t => t.Customer.Planning.Tasks.TaskDepartmentFilter)

    private options: SelectOption[] = [
        { value: 'true', label: this.loc(t => t.activeDepartmentOnly) },
        { value: 'false', label: this.loc(t => t.allDepartments) },
    ]

    public render() {
        const { onChange } = this.props

        return (
            <Select
                name={'taskDepartmentFilter'}
                isFilter={true}
                searchable={false}
                onChange={(option: SelectOption) => {
                    onChange(option)
                }}
                defaultValue={this.getDefaultValue()}
                options={this.options}
            />
        )
    }

    private getDefaultValue() {
        const { defaultValue } = this.props

        if (defaultValue) {
            return [this.options.find(o => o.value === defaultValue) as SelectOption]
        }

        return [this.options.find(o => o.value === 'true') as SelectOption]
    }
}
