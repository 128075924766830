import React from 'react'
import { permissions, userClient } from '~/bootstrap'
import { EnvironmentBanner } from '~/components/Chrome/EnvironmentBanner/EnvironmentBanner'
import { Notifications } from '~/components/Core/Feedback/Notifications/Notifications'
import { OnboardingManager } from '~/components/Domain/Onboarding/OnboardingManager'
import { UserContext, UserContextValue } from '~/components/Providers/UserProvider'
import { prefixWithAppPrefix } from '~/config'
import { BEM } from '~/services/BEMService'
import { roleGroups } from '~/services/UserService'
import { CoverView } from '~/views/Cover/CoverView'
import { ConsultantView } from './Consultant/ConsultantView'
import { CustomerView } from './Customer/CustomerView'
import { LegacyRedirectView } from './Customer/LegacyRedirectView'
import './RootView.scss'
import { LawAndRegulationRedirectView } from './Customer/lawAndRegulation/LawAndRegulationRedirectView'
import { CustomerSwitch } from './Customer/CustomerSwitch/CustomerSwitch'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Location, RouteComponentProps, withRouter } from '~/utils/withRouter'
import { newRoutes } from './newRoutes'
import { routes } from './routes'
import { ChatPlaceholder } from '~/components/Chrome/ChatPlaceholder/ChatPlaceholder'

interface Props extends RouteComponentProps<{ customer: string }> {}

class RootViewComponent extends React.Component<React.PropsWithChildren<Props>, {}> {
    public static contextType = UserContext
    public context: UserContextValue

    private bem = new BEM('RootView', () => ({
        'is-logged-in': this.props.location.pathname.startsWith('/app'),
    }))

    public async componentDidMount() {
        const page = this.getPageFromLocation(this.props.location)

        if (!page.includes('auth')) {
            window.sessionStorage.setItem(prefixWithAppPrefix('intent'), page)
        }
    }

    public async componentDidUpdate() {
        if (this.context.loading) {
            return
        }

        await this.updateIfDifferentCustomerSlug()
    }

    public render() {
        return (
            <div className={this.bem.getClassName()}>
                {this.renderRoutes()}
                <Notifications />
                {window.ENVIRONMENT.NAME !== 'production' && <ChatPlaceholder />}
                {window.ENVIRONMENT.NAME !== 'production' && <EnvironmentBanner />}
            </div>
        )
    }

    // tslint:disable-next-line cyclomatic-complexity
    private renderRoutes() {
        const { loading, user, employee, userEmployees } = this.context

        if (loading) {
            return null
        }

        if ((!user || !user.role) && (!employee || !employee.role) && !userEmployees?.length) {
            return (
                <Routes>
                    <Route path={newRoutes.cover.index} element={<CoverView />} />
                    <Route path={newRoutes.index} element={<Navigate replace to={routes.cover.login} />} />
                </Routes>
            )
        }

        if (employee?.role && roleGroups.customer.includes(employee.role)) {
            return (
                <OnboardingManager history={this.props.history}>
                    <Routes>
                        <Route path={newRoutes.lawAndRegulationRedirect()} element={<LawAndRegulationRedirectView />} />
                        <Route path={newRoutes.cover.index} element={<CoverView />} />
                        <Route path={routes.legacyRedirect()} element={<LegacyRedirectView />} />
                        <Route path={newRoutes.customer().switch} element={<CustomerSwitch />} />
                        <Route path={newRoutes.customer().index} element={<CustomerView />} />
                        <Route
                            path={routes.index}
                            element={<Navigate replace to={routes.customer(employee.customer.slug).index} />}
                        />
                    </Routes>
                </OnboardingManager>
            )
        }

        if (userEmployees?.length && userEmployees.length > 1) {
            return (
                <Routes>
                    <Route
                        path={newRoutes.index}
                        element={<Navigate replace to={newRoutes.customer('customer').switch} />}
                    />
                    <Route path={newRoutes.cover.index} element={<CoverView />} />
                    <Route path={newRoutes.customer().switch} element={<CustomerSwitch />} />
                </Routes>
            )
        }

        if (user?.role && roleGroups.consultant.includes(user.role)) {
            return (
                <OnboardingManager history={this.props.history}>
                    <Routes>
                        <Route path={newRoutes.lawAndRegulationRedirect()} element={<LawAndRegulationRedirectView />} />
                        <Route path={newRoutes.cover.index} element={<CoverView />} />
                        <Route path={newRoutes.consultant.index} element={<ConsultantView />} />
                        <Route path={newRoutes.customer().index} element={<CustomerView />} />
                        <Route
                            path={routes.index}
                            element={<Navigate replace to={routes.consultant.dashboard.index} />}
                        />
                    </Routes>
                </OnboardingManager>
            )
        }
        return (
            <Routes>
                <Route path={newRoutes.cover.index} element={<CoverView />} />
                <Route path={routes.index} element={<Navigate replace to={routes.cover.login} />} />
            </Routes>
        )
    }

    private getPageFromLocation(location: Location) {
        return location.pathname + location.search
    }

    // tslint:disable-next-line cyclomatic-complexity
    private async updateIfDifferentCustomerSlug() {
        if (permissions.isConsultantUser() || this.props.location.pathname === routes.customer('customer').switch) {
            return
        }

        const subPaths = this.props.location.pathname.split('/')
        if (subPaths.length < 2 || !subPaths[1]) {
            return
        }

        const pathCustomerSlug = subPaths[1]

        if (this.context.employee?.customer.slug === pathCustomerSlug || pathCustomerSlug === 'auth') {
            return
        }

        const isTryingToAccessDifferentCustomer = pathCustomerSlug !== this.context.employee?.customer.slug
        const hasSingleEmployee = this.context.userEmployees?.length === 1
        const userEmployeeForPathSlug = this.context.userEmployees?.find(e => e.customer.slug === pathCustomerSlug)

        if ((hasSingleEmployee && isTryingToAccessDifferentCustomer) || !userEmployeeForPathSlug) {
            await userClient.logout()
            return
        }

        const hasNotSetEmployeeForCustomer =
            !this.context.employee || this.context.employee?.customer.slug !== pathCustomerSlug

        if (userEmployeeForPathSlug && hasNotSetEmployeeForCustomer) {
            await userClient.switchUserCustomer(userEmployeeForPathSlug.id, () => {
                this.context.refetch()
                window.location.reload()
            })
        }
    }
}

export const RootView = withRouter(RootViewComponent)
